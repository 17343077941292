import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { IChannelConfig } from '_Types/IIconButtonConfig';
import {
  // deleteChannel,
  getUserChannels,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { PlxLoader } from '_Components/PlxLoader';

interface IProps {
  channelTypeConfig: IChannelConfig[];
}

const ChannelWrapper = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 1.7rem 0 1.7rem 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  > div {
    flex: 1;
  }
`;
const ChannelDetails = styled.div`
  width: 100%;
`;
const TableValue = styled.div`
  font-size: 1.1rem;
  padding-top: 1rem;
`;
const Title = styled.div`
  font-size: 0.8rem;
  color: #a9b3bb;
`;
const StatusCircle = styled.div`
  height: 16px;
  width: 16px;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  padding: 1px;
  margin-top: 0.7rem;
  margin-right: 0.7rem;
`;
const StatusCircleInner = styled.div`
  width: 10px;
  height: 10px;
  background: #e58e2e;
  border-radius: 50%;
`;
const StatusWrapper = styled.div`
  display: flex;
`;

export const XemDataContainer: FC<IProps> = ({ channelTypeConfig }) => {
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState<IDistributionPartnerChannelDTO[]>(
    []
  );

  const fetchChannels = () => {
    return getUserChannels().then((v) => {
      setChannels(v);
      return v;
    });
  };
  useEffect(() => {
    fetchChannels().then(() => setLoading(false));
  }, [channelTypeConfig]);
  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <ChannelDetails>
          {channels.map(({ id, status, channelName, channelType }) => {
            return (
              <>
                {status === 'PENDING' && (
                  <ChannelWrapper key={id}>
                    <div>
                      <Title>Channel Name</Title>
                      <TableValue>{channelName}</TableValue>
                    </div>
                    <div>
                      <Title>Channel Type</Title>
                      <TableValue>{channelType}</TableValue>
                    </div>
                    <div>
                      <Title>Earning Opportunity</Title>
                      <TableValue>Wait for an offer</TableValue>
                    </div>
                    <div>
                      <Title>Status</Title>
                      <StatusWrapper>
                        <StatusCircle>
                          <StatusCircleInner></StatusCircleInner>
                        </StatusCircle>
                        <TableValue>Waiting for approval</TableValue>
                      </StatusWrapper>
                    </div>
                  </ChannelWrapper>
                )}
              </>
            );
          })}
          {/* <OptionWrapper>
        <span>Reject</span> <span>Accept</span>
      </OptionWrapper> */}
        </ChannelDetails>
      )}
    </>
  );
};
