import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import React, { ReactElement } from 'react';
import { PlxText } from '_Components/Text/PlxText';
import styled from '@emotion/styled';
import { CampaignStatus, ITag } from '_Types/campaigns';
import { IThriveCampaign } from '_State/Thrive/types';
import { TagTableCell } from '../../../../../../_Components/CampaignsTableCells/TagTableCell';
import { ButtonTableCell } from '../../../../../../_Components/CampaignsTableCells/ButtonTableCell';
import moment from 'moment';

const StyledText = styled(PlxText)`
  white-space: nowrap;
`;

export const createThriveCampaignsTableConfig = (
  t: any,
  tags: ITag[],
  openDialog: (campaignId: number) => void
): CellConfig<IThriveCampaign>[] => {
  return [
    {
      name: 'Campaign ID',
      propKey: 'id',
    },
    {
      name: 'Campaign Date',
      renderCell({ createdDate }: IThriveCampaign): ReactElement {
        return (
          <StyledText> {moment(createdDate).format('MM/DD/yyyy')}</StyledText>
        );
      },
    },
    {
      name: 'Number of leads',
      propKey: 'totalNumberOfLeads',
    },
    {
      name: 'Status',
      renderCell({ status }: IThriveCampaign): ReactElement {
        const statusString = t(
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          `campaignStatus.${CampaignStatus[CampaignStatus[status]]}`
        );
        return <StyledText>{statusString}</StyledText>;
      },
    },
    {
      name: 'Tag',
      renderCell(value: IThriveCampaign): ReactElement {
        return (
          <TagTableCell
            onDialogOpenDialog={openDialog}
            tagName={value.tag?.name}
            campaignId={value.id}
            tag={tags.find(({ id }) => id === value.tag?.id)}
          />
        );
      },
    },
    {
      name: '',
      renderCell(values: IThriveCampaign): ReactElement {
        return (
          <ButtonTableCell
            isDisabled={values.status === 'PAUSED'}
            id={values.id}
            path={'/home/thrive-dashboard/campaign/'}
          />
        );
      },
    },
  ];
};
