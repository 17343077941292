import { spectrumApi } from '_Api/api';
import {
  IAddAdminOrEditorForm,
  IAdminAgenciesQueryParams,
  IAdminUsersQueryParams,
  IEditClientForm,
  IEditDistributionPartnerForm,
  IOrderDTO,
  IOrderEditForm,
} from '_State/User/user.types';
import { IPostSample, ISpectrumCampaign } from '_State/Spectrum/types';
import { ICampaigns, ICampaignsQueryParams, ITag } from '_Types/campaigns';

export const getClients = (params: IAdminUsersQueryParams): Promise<any> => {
  let url = 'users/clients';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.searchedPhrase) {
    urlParams.append('searchedPhrase', params.searchedPhrase);
  }
  if (params?.id) {
    urlParams.append('searchedPhrase', params.id.toString());
  }
  urlParams.append('pageSize', '10000');

  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const getAgencies = (
  params?: IAdminAgenciesQueryParams
): Promise<any> => {
  let url = 'users/agencies';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.searchedPhrase) {
    urlParams.append('searchedPhrase', params.searchedPhrase);
  }
  if (params?.id) {
    urlParams.append('searchedPhrase', params.id.toString());
  }
  urlParams.append('pageSize', '10000');

  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const getAgenciesClients = (agencyId: string): Promise<any> => {
  return spectrumApi
    .get(`agencies/clients?agencyId=${agencyId}&pageSize=1000`)
    .json();
};

export const getAdminsAndEditors = (
  params: IAdminUsersQueryParams
): Promise<any> => {
  let url = 'users/editors-admins';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.searchedPhrase) {
    urlParams.append('searchedPhrase', params.searchedPhrase);
  }
  urlParams.append('pageSize', '10000');

  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const getDistributionPartners = (
  params: IAdminUsersQueryParams
): Promise<any> => {
  let url = 'users/distribution-partners';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.searchedPhrase) {
    urlParams.append('searchedPhrase', params.searchedPhrase);
  }
  urlParams.append('pageSize', '10000');

  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const updateClient = (
  form: IEditClientForm,
  userId?: number
): Promise<any> => {
  const url = `users/client/${userId}`;

  const [firstName, lastName] = form.fullName
    ? form.fullName.replace(/\s+/, '\x01').split('\x01')
    : [];

  const dto = {
    firstName: firstName,
    lastName: lastName,
    email: form.email,
    companyName: form.companyName,
    activated: form.isActivated,
  };

  return spectrumApi.put(url, { json: dto }).json();
};

export const updateDistributionPartner = (
  form: IEditDistributionPartnerForm,
  userId?: number
): Promise<any> => {
  const url = `users/distribution-partners/${userId}`;

  const [firstName, lastName] = form.fullName
    ? form.fullName.replace(/\s+/, '\x01').split('\x01')
    : [];

  const dto = {
    firstName: firstName,
    lastName: lastName,
    email: form.email,
    phoneNumber: form.phoneNumber,
    businessName: form.businessName,
    mailingAddress: form.mailingAddress,
    isActivated: form.isActivated,
  };

  return spectrumApi.put(url, { json: dto }).json();
};

export const updateAdminOrEditor = (
  form: any,
  userId?: number
): Promise<any> => {
  const url = `users/editor-admin/${userId}`;

  return spectrumApi.put(url, { json: form }).json();
};

export const addAdminOrEditor = (form: IAddAdminOrEditorForm): Promise<any> => {
  const url = `users/editor-admin`;

  const [firstName, lastName] = form.fullName
    ? form.fullName.replace(/\s+/, '\x01').split('\x01')
    : [];

  const dto = {
    firstName: firstName,
    lastName: lastName,
    email: form.email,
    privilege: form.privilege,
  };

  return spectrumApi.post(url, { json: dto }).json();
};

export const deleteUser = (id?: number): Promise<any> => {
  const url = `users/editor-admin/${id}`;

  return spectrumApi.delete(url);
};
export const deleteFinfluencer = (id?: number): Promise<any> => {
  const url = `users/distribution-partners/${id}`;
  return spectrumApi.delete(url);
};
export const participation = (id?: number): Promise<any> => {
  const url = `spectrum-campaigns/finfluencer/active-opportunities/${id}`;
  return spectrumApi.get(url).json();
};
export const getOrders = (
  id?: string,
  agencyClientId?: string
): Promise<IOrderDTO[]> => {
  let url = `spectrum-campaigns/programs?userId=${id}&statusList=COMPLETED,EXPIRED,ACTIVE`;
  if (agencyClientId) {
    url += `&agencyClientId=${agencyClientId}`;
  }
  return spectrumApi.get(url).json();
};

export const updateOrder = (
  form: IOrderEditForm,
  id?: number
): Promise<any> => {
  const url = `spectrum-campaigns/program/${id}`;

  const orderDTO = {
    remainingCampaigns: form.remainingCampaigns,
    expiryDate: form.expiryDate,
    invoiceId: form.invoiceId,
    userId: form.userId,
    agencyClientId: form.agencyClientId,
  };
  return spectrumApi.put(url, { json: orderDTO }).json();
};
export const createOrder = (form: IOrderEditForm): Promise<any> => {
  const url = `spectrum-campaigns/program`;

  const orderDTO = {
    userId: form.userId,
    agencyClientId: form.agencyClientId,
    remainingCampaigns: form.remainingCampaigns,
    totalCampaigns: form.totalCampaigns,
    expiryDate: form.expiryDate,
    estimatedReach: form.followers,
    invoiceId: form.invoiceId,
  };
  return spectrumApi.post(url, { json: orderDTO }).json();
};

export const getUsersCSV = (): Promise<any> => {
  return spectrumApi.get(`users/admin/export-csv`).blob();
};

// export const getWallets = (): Promise<any> => {
//   return spectrumApi.get(`wallets?userId=123`).json();
// };

// export const approveSingleTransaction = (userId: number): Promise<any> => {
//   return spectrumApi.post(`transactions/${userId}/approve`).json();
// };

export const approveCompletedTransactions = (
  opportunitiesForPayout: string[],
  campaignId: string
): Promise<any> => {
  const dto = {
    opportunities: opportunitiesForPayout,
  };

  const url = `spectrum-campaigns/${campaignId}/approve-payout`;
  return spectrumApi.put(url, { json: dto });
};

export const getCampaignTrans = (id: string | undefined) => {
  return spectrumApi.get(`transactions/campaign/${id}`).json();
};
export const getCampaignDataForAdmin = (
  start: string,
  end: string,
  userId: number,
  agencyId?: number
): Promise<any> => {
  const url = agencyId
    ? `engagements/campaigns?from=${start}&to=${end}&userId=${agencyId}&agencyClientId=${userId}`
    : `engagements/campaigns?from=${start}&to=${end}&userId=${userId}`;
  return spectrumApi.get(url).json();
};

export const getChannelDataForAdmin = (
  start: string,
  end: string,
  userId: number,
  agencyId?: number
): Promise<any> => {
  const url = agencyId
    ? `engagements/channels?from=${start}&to=${end}&userId=${agencyId}&agencyClientId=${userId}`
    : `engagements/channels?from=${start}&to=${end}&userId=${userId}`;
  return spectrumApi.get(url).json();
};
export const getTopFinfluencerDataForAdmin = (
  start: string,
  end: string,
  userId: number,
  agencyId?: number
): Promise<any> => {
  const url = agencyId
    ? `engagements/finfluencers?from=${start}&to=${end}&userId=${agencyId}&agencyClientId=${userId}`
    : `engagements/finfluencers?from=${start}&to=${end}&userId=${userId}`;
  return spectrumApi.get(url).json();
};
export const getTopPostDataForAdmin = (
  start: string,
  end: string,
  userId: number,
  agencyId?: number
): Promise<any> => {
  const url = agencyId
    ? `engagements/posts?from=${start}&to=${end}&userId=${agencyId}&agencyClientId=${userId}`
    : `engagements/posts?from=${start}&to=${end}&userId=${userId}`;
  return spectrumApi.get(url).json();
};
export const getSpectrumCampaignsForAdmin = (
  params?: ICampaignsQueryParams,
  isAdmin?: number
): Promise<ICampaigns<ISpectrumCampaign>> => {
  let url = `users/self/spectrum-campaigns`;
  if (params) {
    const urlParams = new URLSearchParams({
      sortDirection: params.sortDirection,
      sortByField: params.sortByField,
    });
    if (params.tagIdList.length > 0) {
      urlParams.append('tagIdList', params.tagIdList.join(','));
    }
    if (params.campaignStatusList.length > 0) {
      urlParams.append(
        'campaignStatusList',
        params.campaignStatusList.join(',')
      );
    }
    urlParams.append('pageSize', '10000');
    url += `?${urlParams}&userId=${isAdmin}`;
  }

  return spectrumApi.get(url).json();
};

export const getCampaignForAdmin = (
  id: number | string | undefined,
  userId: string
): Promise<any> => {
  return spectrumApi
    .get(`users/self/spectrum-campaigns/${id}?userId=${userId}`)
    .json();
};

export const getPostSamplesForAdmin = (
  id: number | string | undefined,
  userId: string
): Promise<IPostSample[]> => {
  return spectrumApi
    .get(`users/self/spectrum-campaigns/${id}/post-samples?userId=${userId}`)
    .json();
};

export const getSpectrumTagsForAdmin = (userId: number): Promise<ITag[]> => {
  return spectrumApi.get(`spectrum-campaigns/tags?userId=${userId}`).json();
};

export const deleteSpectrumTagForAdmin = (tagId: number): Promise<any> => {
  return spectrumApi.delete(`spectrum-campaigns/tags/${tagId}`);
};
