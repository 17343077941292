import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Input } from '@material-ui/core';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { useFormik } from 'formik';
import { sendMessage } from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { useTranslation } from 'react-i18next';

const MessageContainer = styled.form`
  width: 100%;
  max-width: 600px;
  margin: 10rem auto 0;
  > div {
    width: 100%;
    margin: 1rem 0;
  }
`;
const MassageContainer = styled.div`
  font-size: 2rem;
  margin: 2rem 3rem 6rem;
`;
export interface IFinfluencerContactUs {
  message: string;
}
const INITIAL_VALUES: IFinfluencerContactUs = {
  message: '',
};

export const FinfluencerContactUsForm = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form, { resetForm }) => {
      sendMessage(form);
      setOpenDialog(true);
      resetForm({});
    },
    validate: (form) => {
      let errors: any = {};
      const message = form.message;
      if (!message) {
        errors.message = 'Required';
      }
      if (message.length > 1000) {
        errors.message = 'Maximum 999 Letter';
      }
      return errors;
    },
  });

  return (
    <MessageContainer>
      <p>{t('contactUs.messageUs')}</p>
      <Input
        name="message"
        multiline
        rows={6}
        placeholder="Type your message here..."
        onChange={formik.handleChange}
        value={formik.values.message}
      />
      <PlxButtonX onClick={formik.handleSubmit}>
        {t('contactUs.send')}
      </PlxButtonX>
      <FieldErrorMessage message={formik.errors.message} />
      <PlxDialog
        title=""
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="50"
            viewBox="0 0 24 24"
            width="50"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
              fill="green"
            />
          </svg>
          <MassageContainer>{t('contactUs.messageSent')}</MassageContainer>
        </div>
      </PlxDialog>
    </MessageContainer>
  );
};
