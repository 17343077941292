import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';

export const ScrollContainer = styled.div<IThemed>`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f8f8f8;
`;

export const HomePageWrapper: FC<ICommonProps> = ({
  newDesign,
  children,
  className,
}) => {
  return (
    <ScrollContainer
      style={{ padding: newDesign ? '20px 40px 0 0' : '4rem' }}
      className={className}
    >
      {children}
    </ScrollContainer>
  );
};
