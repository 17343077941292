export interface IFilterItem {
  id: string;
  title: string;
  isChecked: boolean;
  subtitle?: string;
}

export const channelTypeFilters: IFilterItem[] = [
  { id: '1', title: 'Facebook', isChecked: false, subtitle: '' },
  { id: '2', title: 'Instagram', isChecked: false, subtitle: '' },
  { id: '3', title: 'TikTok', isChecked: false, subtitle: '' },
  { id: '4', title: 'YouTube', isChecked: false, subtitle: '' },
  { id: '5', title: 'Twitch', isChecked: false, subtitle: '' },
  { id: '6', title: 'Reddit', isChecked: false, subtitle: '' },
  { id: '7', title: 'Discord', isChecked: false, subtitle: '' },
  { id: '8', title: 'Pinterest', isChecked: false, subtitle: '' },
  { id: '9', title: 'Twitter', isChecked: false, subtitle: '' },
  { id: '10', title: 'Linkedin', isChecked: false, subtitle: '' },
  { id: '11', title: 'WhatsApp', isChecked: false, subtitle: '' },
  { id: '12', title: 'Telegram', isChecked: false, subtitle: '' },
];
export const campaignTagFilters: IFilterItem[] = [
  {
    id: '1',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '2',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '3',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '4',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '5',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '6',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '7',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '8',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
  {
    id: '9',
    title: 'AnimationClub',
    isChecked: false,
    subtitle: '153 Campaigns',
  },
];

export const initialCalendarDate = {
  selection: {
    startDate: null,
    endDate: new Date(''),
    key: 'selection',
  },
  compare: {
    startDate: null,
    endDate: new Date(''),
    key: 'compare',
  },
};
export interface IContent {
  budget: {
    programName: string;
    numberOfCampaigns: number;
    programPrice: number;
    estimatedReach: number;
  };
  comments: number;
  companyName: string;
  contentCategories: string[];
  contentType: string;
  createdDate: string;
  engagement: number;
  geolocation: string[];
  hashtags: string[];
  id: number;
  industry: string;
  payment: {
    paymentId: number;
    campaignType: string;
    programName: string;
    paymentStatus: string;
    pricingType: string;
    promotionalCodeUsed: boolean;
  };
  programId: number;
  reactions: number;
  shares: number;
  startDate: string;
  status: string;
  stockTickerList: string[];
  url: string;
  userId: number;
  tag?: {
    id: number;
    name: string;
  };
  trend?: number;
}
export interface IEngagementGraphData {
  content: IContent[];
  hasNext?: boolean;
  hasPrevious?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  pageNumber?: number;
  pageSize?: number;
  totalElements?: number;
  totalPages?: number;
}

export interface IBasicWidgetData {
  averagePotential?: number;
  comparisonToAverage?: number;
}
export interface IBasicWidgetState {
  impressions: IBasicWidgetData;
  reach: IBasicWidgetData;
  followers: IBasicWidgetData;
  engagement: IBasicWidgetData;
  clicks: IBasicWidgetData;
  totalClicks: number;
}
