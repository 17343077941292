import React from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { ClientsTab } from './ClientsTab';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

export const EndClients = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledHeader
          title={'Clients'}
          backLink={'/home/admin/users'}
          backLabel={'Back to users'}
        />
      </HeaderWrapper>
      <Body>
        <ClientsTab />
      </Body>
    </Wrapper>
  );
};
