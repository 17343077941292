import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.3rem;
  text-align: center;
  color: #767676;
  margin-bottom: 5rem;
`;
export const Label = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 4.1rem;
  text-align: center;
  color: #1b1b1b;
`;

export const Body = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-color: #fff;
`;
export const HeaderWrapper = styled.div`
  height: 7rem;
`;

export const Wrapper = styled.div`
  && {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
