import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import { IThriveLead } from '_State/Thrive/types';
import React, { ReactElement } from 'react';
import { PlxText } from '_Components/Text/PlxText';
import moment from 'moment';

export const createThriveLeadsTableConfig = (): CellConfig<IThriveLead>[] => {
  return [
    {
      name: 'Lead ID',
      propKey: 'id',
    },
    {
      name: 'First Name',
      propKey: 'firstName',
    },
    {
      name: 'Last Name',
      propKey: 'lastName',
    },
    {
      name: 'Email',
      propKey: 'email',
    },
    {
      name: 'Phone',
      propKey: 'phone',
    },
    {
      name: 'Geo-location',
      renderCell(value: IThriveLead): ReactElement {
        const text = `${
          value.geolocation
            ? value.geolocation.country
              ? value.geolocation.country
              : '-'
            : '-'
        }${
          value.geolocationState
            ? value.geolocationState.region
              ? ', ' + value.geolocationState.region
              : ''
            : ''
        }${value.city ? ', ' + value.city : ''}
          `;
        return <PlxText tooltipText={text}>{text}</PlxText>;
      },
    },
    {
      name: 'Opt-in Date',
      renderCell({ optInDate }: IThriveLead): ReactElement {
        return <div>{moment(optInDate).format('MM/DD/yyyy')}</div>;
      },
    },
  ];
};
