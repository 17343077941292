import { createContext } from 'react';
import { IManageTagsContext } from '_State/Spectrum/types';
import { ITag } from '_Types/campaigns';

const emptyTag: ITag = {
  name: '',
  id: 0,
  campaigns: [],
};

const emptyCallback = <T>(result: T) => () => Promise.resolve(result);

export const defaultContext = {
  tags: [],
  updateTag: emptyCallback(undefined),
  addTag: emptyCallback(emptyTag),
  removeTag: emptyCallback(undefined),
  updateCampaignTag: emptyCallback(undefined),
};

export const ManageTagsContext = createContext<IManageTagsContext>(
  defaultContext
);
