import styled from '@emotion/styled';
import { PlxText } from '_Components/Text/PlxText';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps {
  status: any;
  rejectionReason?: string;
}

interface IText {
  color: any;
}

const StyledText = styled(PlxText)<IText>`
  color: ${({ color }) => color};
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1rem;
`;

export const ChannelStatusCell: FC<IProps> = ({ status, rejectionReason }) => {
  const { t } = useTranslation();

  return (
    <StyledText color={status?.color}>
      {t(status?.label)}
      {status?.label === 'channelStatus.rejected' && rejectionReason && (
        <Tooltip title={rejectionReason}>
          <HelpOutlineIcon />
        </Tooltip>
      )}
    </StyledText>
  );
};
