import { FC } from 'react';
import styled from '@emotion/styled';
import React from 'react';
import ChannelCreatedImg from '_Styles/images/_Icons/ChannelCreatedImg.png';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { useHistory } from 'react-router-dom';
import { XemDataContainer } from '../../../XemDataContainer';
import { channelStatus } from './config';
import _ from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vp; */
`;
const Title = styled.div`
  color: #1b1b1b;
  text-align: center;
  font-style: normal;
  font-size: 2.1rem;
  margin: 2rem 0;
  line-height: 3rem;
  padding: 2rem 15rem;
`;
const Description = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin: 4rem 0 1.5rem 0;
  line-height: 2rem;
  font-weight: 100;
`;
const Button = styled.div`
  margin: 1rem;
  span {
    // font-size: 3rem;
  }
`;
const ImageContainer = styled.img`
  width: 10rem;
`;
const channelTypeOptions = _.cloneDeep(channelStatus);

export const ReviewingMessage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper>
      <ImageContainer src={ChannelCreatedImg} alt="ChannelCreatedImg" />
      <Title>{t('finfluencer.addChannel.reviewing.title')}</Title>
      <XemDataContainer channelTypeConfig={channelTypeOptions} />
      <Description>
        {t('finfluencer.addChannel.reviewing.description')}
      </Description>
      <Button>
        <PlxButtonX
          label={t('finfluencer.addChannel.reviewing.button')}
          onClick={() => history.push('/home/finfluencer/channel/add-channel')}
          testId="add-channel-button"
        />
      </Button>
      <Description>{t('finfluencer.addChannel.reviewing.paypal')}</Description>
      <Button>
        <PlxButtonX
          label={t('finfluencer.addChannel.reviewing.paypalButton')}
          onClick={() => history.push('/home/finfluencer/wallet')}
          testId="add-channel-button"
        />
      </Button>
    </Wrapper>
  );
};
