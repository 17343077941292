import React from 'react';
import { IContent } from '../../utils';
import { IDataTypeCheckbox } from './CheckboxFooter';
import { capitalize, IVisibility } from './EngagementGraph';
import { StyledTooltip, TooltipElement } from './EngagementGraph.styles';

interface ICustomizedTooltip {
  dataTypesCheckbox: IDataTypeCheckbox[];
  visibility: IVisibility;
  getDailyData: (arg: string) => IContent | undefined;
  label?: string;
}

export const CustomizedTooltip = ({
  label,
  getDailyData,
  dataTypesCheckbox,
  visibility,
}: ICustomizedTooltip) => {
  if (!label) return null;
  const dailyData: IContent | undefined = getDailyData(label);
  return (
    <>
      {dailyData && (
        <StyledTooltip>
          {dataTypesCheckbox
            .filter(
              (el: { name: string; color: string }) => el.name !== 'trend'
            )
            .map((el: { name: string; color: string }, index: number) => {
              return (
                <TooltipElement key={index}>
                  {visibility[el.name] && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ marginRight: '5px' }}>
                        <span
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: el.color,
                            borderRadius: '2px',
                            display: 'inline-block',
                            marginRight: '3px',
                          }}
                        ></span>
                        {capitalize(el.name.replace('_', ' '))}:&nbsp;
                      </div>
                      <div>
                        {(dailyData as any)[el.name]}
                        {el.name === 'engagement' && '%'}
                      </div>
                    </div>
                  )}
                </TooltipElement>
              );
            })}
        </StyledTooltip>
      )}
    </>
  );
};
