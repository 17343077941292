import { ISpectrumState } from '_State/Spectrum/spectrum.types';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ISpectrumState = {
  step: '0',
};

type SpectrumReducer<PayloadType> = CaseReducer<
  ISpectrumState,
  PayloadAction<PayloadType>
>;

const changeStep: SpectrumReducer<ISpectrumState> = (
  state,
  { payload: spectrum }
) => {
  const { step } = spectrum;
  state.step = step;
};

export const spectrumSlice = createSlice({
  name: 'spectrum',
  initialState,
  reducers: {
    changeStep,
  },
});

export const spectrumActions = spectrumSlice.actions;
export const spectrumReducer = spectrumSlice.reducer;
