import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import Background from '_Styles/images/spectrum-welcome-page-background.svg';
import { ExampleFeature } from '../../../../_Components/ExampleFeature/ExampleFeature';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { useHistory } from 'react-router-dom';
import { IWelcomePageFeature } from '../../../../types';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
const Info = styled.div`
  position: absolute;
  top: 60px;
  left: 310px;
`;
const Label = styled.div`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 4.2rem;
  color: #1b1b1b;
  margin-bottom: 3rem;
`;

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 100px;
`;
const BackgroundColor = styled.div`
  width: 100%;
  height: 22rem;
  background-color: #edf0f8;
`;

const StyledButton = styled(PlxButton)`
  .MuiButton-label {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const spectrumWelcomePageFeaturesConfig: IWelcomePageFeature[] = [
  {
    number: '01',
    label: 'spectrum.welcomePage.feature.label_01',
    description: 'spectrum.welcomePage.feature.description_01',
  },
  {
    number: '02',
    label: 'spectrum.welcomePage.feature.label_02',
    description: 'spectrum.welcomePage.feature.description_02',
  },
  {
    number: '03',
    label: 'spectrum.welcomePage.feature.label_03',
    description: 'spectrum.welcomePage.feature.description_03',
  },
];

export const SpectrumWelcomePage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAgencyType } = useContext(NavigationContext);
  const launchCampaign = () => {
    history.replace(
      isAgencyType
        ? '/home/agency/spectrum-campaign'
        : '/home/client/spectrum-campaign'
    );
  };
  return (
    <Wrapper>
      <BackgroundColor />
      <BackgroundImage src={Background} />
      <Info>
        <Label>
          <p>{t('spectrum.welcomePage.label1')}</p>
          <p>{t('spectrum.welcomePage.label2')}</p>
        </Label>
        {spectrumWelcomePageFeaturesConfig.map((feature, key) => {
          return (
            <ExampleFeature
              label={feature.label}
              description={feature.description}
              number={feature.number}
              key={key}
            />
          );
        })}
        <StyledButton
          variant={ButtonVariant.Contained}
          onClick={launchCampaign}
          label={t('spectrum.welcomePage.buttonLabel')}
        />
      </Info>
    </Wrapper>
  );
};
