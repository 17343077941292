import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { useTranslation } from 'react-i18next';
// import { AdminChannelsTable } from '_Routes/Home/_routes/Admin/_Routes/Channels/AdminChannelsTable';
// import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
// import { getChannelsCSV } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { ReferralsTable } from './ReferralsTable';

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;
const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

// const CSVButton = styled(PlxButton)`
//   && {
//     margin-right: 3rem;
//   }
// `;

export const Referrals: FC = () => {
  const { t } = useTranslation();

  // const [csvButtonState, setCsvButtonState] = useState<boolean>(false);

  // const createDownloadCSV = (data: Blob, fileName: string) => {
  //   const link = document.createElement('a');
  //   const url = window.URL.createObjectURL(data);
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', fileName + '.csv');
  //   link.innerHTML = 'Download CSV';
  //   document.body.appendChild(link);
  //   link.click();
  // };

  // const handleChannelsCSV = () => {
  //   setCsvButtonState(true);
  //   getChannelsCSV().then((data) => {
  //     createDownloadCSV(data, 'channels');
  //   });
  //   setCsvButtonState(false);
  // };

  return (
    <>
      <HeaderWrapper>
        <StyledHeader title={t('referrals.label')}>
          {/* <CSVButton
            size={'small'}
            isDisabled={csvButtonState}
            label={'Download CSV'}
            onClick={handleChannelsCSV}
          /> */}
        </StyledHeader>
      </HeaderWrapper>
      <ReferralsTable />
    </>
  );
};
