import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDistributionPartnerAddChannelForm } from '_State/DistributionPartner/distributionPartner.types';
import { useHistory } from 'react-router-dom';
import { createChannel } from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxLoader } from '_Components/PlxLoader';
import { ChannelForm } from '_Routes/Home/_Components/ChannelForm';
import { FormMassage } from '../../../../../../../_Components/Forms/FormMassage';
import { TabPageHeader } from '../../../utils';

export const DistributionPartnerAddChannel: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const handleFormSubmit = (form: IDistributionPartnerAddChannelForm) => {
    setLoading(true);
    createChannel(form).then(
      () => {
        history.push('/home/finfluencer/channel/reviewing');
        setLoading(false);
      },
      (error) => {
        error.response.json().then((error: any) => {
          setApiErrorMessage(error.memssage);
          setLoading(false);
        });
      }
    );
  };

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <>
          <TabPageHeader
            name={t('finfluencer.dashboard.welcomePage.addChannel')}
          />
          <ChannelForm
            onSubmit={handleFormSubmit}
            footerButtons
            apiError={apiErrorMessage}
          />
          {apiErrorMessage && <FormMassage message={apiErrorMessage} />}
        </>
      )}
    </>
  );
};
