import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';

import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { INavigationItem } from '_Routes/Home/types';
import { useHistory, useLocation } from 'react-router-dom';
import { IThemed } from '_Styles/types';
import { MenuIconButtonX } from '_Routes/Home/NavigationPanel/MenuIconButton/MenuIconButtonX';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '_Routes/Home/NavigationContext';
interface IProps {
  open: boolean;
  items: INavigationItem[];
  isExtended: boolean;
}

const StyledList = styled(List)<IThemed>`
  margin-bottom: 1rem;
  margin-left: 3rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  padding-left: 30px;
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 0.3rem;
`;

const StyledCollapse = styled(Collapse)<IThemed>`
  width: 100%;
`;

const StyledMenuIconButton = styled(MenuIconButtonX)<IThemed>`
  && {
    margin-top: 0;
    margin-left: ${({ theme }): number => theme.spacing(0.25)};
    padding-left: 2rem !important;
    border-radius: 5px;
    text-transform: capitalize;
  }
`;
// const StyledMenuIconButtonDisabled = styled(MenuIconButtonX)<IThemed>`
//   && {
//     margin-top: 0;
//     margin-left: ${({ theme }): number => theme.spacing(0.25)};
//     border-radius: 5px;
//     text-transform: capitalize;
//     opacity: 0.5;
//   }
// `;

export const SubNavigation: FC<IProps> = ({ items, isExtended, open }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { isAgencyType } = useContext(NavigationContext);

  const isActive = (path: string): boolean => location.pathname === path;

  return (
    <StyledCollapse in={open} timeout="auto" unmountOnExit>
      <StyledList>
        {items?.map(
          (item: INavigationItem) =>
            !item.disabled &&
            item.label !== 'engagement' && (
              <StyledMenuIconButton
                key={item.link}
                isExtended={isExtended}
                isFocused={isActive(isAgencyType ? item.agencyLink : item.link)}
                label={t(item.label)}
                onClick={(): void => {
                  history.push(`${isAgencyType ? item.agencyLink : item.link}`);
                }}
              />
            )
        )}
      </StyledList>
    </StyledCollapse>
  );
};
