import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxLinkButton } from '../Utils/PlxLinkButton';

const DashboardEmptyCampaignBody = styled.div`
  background: #e5e5e5;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  height: 12rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  max-height: 10rem;
`;

const Title = styled.div`
  color: #231f20;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`;
const InfoLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
  max-width: 420px;
`;

interface IProps {
  title: string;
  infoLabel: string;
  pathLabel: string;
  path: string;
}

export const DashboardEmptyCampaign: FC<IProps> = ({
  title,
  infoLabel,
  pathLabel,
  path,
}) => {
  return (
    <DashboardEmptyCampaignBody>
      <InfoWrapper>
        <Title>{title}</Title>
        <InfoLabel>{infoLabel}</InfoLabel>
      </InfoWrapper>
      <PlxLinkButton text={pathLabel} linkTo={path} />
    </DashboardEmptyCampaignBody>
  );
};
