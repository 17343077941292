import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { getProgramActive } from '_State/Spectrum/Api/spectrum.api';
import { ISpectrumProgram } from '_State/Spectrum/types';
import { CompleteValidation } from './CompleteValidation';
import { PendingValidation } from './PendingValidation';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 220px;
`;

export const SpectrumValidation: FC = () => {
  const [program, setProgram] = useState<ISpectrumProgram>();
  const [loading, setLoading] = useState(true);
  const { clientSelected } = useContext(NavigationContext);

  useEffect(() => {
    getProgramActive(clientSelected)
      .then((program) => {
        setProgram(program);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [clientSelected]);

  return loading ? (
    <StyledCircularProgress color="secondary" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {program ? <CompleteValidation data={program} /> : <PendingValidation />}
    </div>
  );
};
