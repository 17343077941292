import React from 'react';
import { Processing } from '_Routes/Home/_Components/Processing/Processing';
import { ThriveDashboardHeader } from '_Routes/Home/_routes/Thrive/_Components/ThriveDashboardHeader';
import { useHistory } from 'react-router-dom';

export const ThriveDashboardProcessing = () => {
  const history = useHistory();
  const handleProgramLaunch = () => {
    history.push('/home/thrive-campaign/program');
  };
  return (
    <Processing>
      <ThriveDashboardHeader onProgramLaunch={handleProgramLaunch} />
    </Processing>
  );
};
