export const SORTING_OPTIONS = [
  {
    id: 'ASC',
    name: 'Oldest to newest',
  },
  {
    id: 'DESC',
    name: 'Newest to oldest',
  },
];

export const PAYMENT_STATUSES = [
  {
    id: 'IN_PROGRESS',
    name: 'In progress',
  },
  {
    id: 'SUCCESS',
    name: 'Success',
  },
  {
    id: 'FAILED',
    name: 'Failed',
  },
];

export const STATUS_OPTIONS = [
  {
    id: 'DRAFT',
    name: 'Draft',
  },
  {
    id: 'NEW',
    name: 'New campaign',
  },
  {
    id: 'REVIEW_NEEDED',
    name: 'Review needed',
  },
  {
    id: 'COMPLETED',
    name: 'Completed',
  },
  {
    id: 'LAUNCHED',
    name: 'Campaign launched',
  },
  {
    id: 'PAUSED',
    name: 'Paused',
  },
];
