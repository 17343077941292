import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IPackageInfoProperty } from './PackageInfo.types';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  border-top: 1px solid #d5dde3;
  padding-top: 1.5rem;
  line-height: 2rem;
`;
const PropertyWrap = styled.div`
  padding-bottom: 1rem;
`;

interface IProps {
  properties: IPackageInfoProperty[];
}

export const PackageInfoContent: FC<IProps> = ({ properties }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {properties.map((property: IPackageInfoProperty, key) => {
        return (
          <PropertyWrap key={key}>
            <span>{property.name ? t(property.name) : undefined}</span>
            <span> {property.value}</span>
          </PropertyWrap>
        );
      })}
    </Wrapper>
  );
};
