import styled from '@emotion/styled';
import React, { FC } from 'react';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  height: 8rem;
  background: #ffffff;
  border-bottom: 1px solid #eaeef1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
`;
const Label = styled.div`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.3rem;
  color: #000000;
  margin-left: 3rem;
`;
const LaunchButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

interface IProps {
  onProgramLaunch?: () => void;
}

export const SpectrumDashboardHeader: FC<IProps> = ({ onProgramLaunch }) => {
  const { t } = useTranslation();
  const handleLaunchProgram = () => {
    onProgramLaunch && onProgramLaunch();
  };
  return (
    <Wrapper>
      <Label>{t('spectrum.dashboard.label')}</Label>
      <LaunchButton
        size={'small'}
        label={t('spectrum.dashboard.button')}
        onClick={handleLaunchProgram}
      />
    </Wrapper>
  );
};
