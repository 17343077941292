import { makeStyles, Button, Menu, MenuItem } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  button: {
    padding: '18.5px 16px',
    margin: '0 1rem',
    backgroundColor: '#E5E5E5',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#1B192C',
    '&:hover': {
      backgroundColor: '#808285',
      color: '#fff',
    },
  },
});

interface IProps {
  onSort: (value: string) => void;
}

export const PlxButtonFilter: FC<IProps> = ({ onSort }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortACS = () => {
    onSort('ASC');
    setAnchorEl(null);
  };
  const handleSortDESC = () => {
    onSort('DESC');
    setAnchorEl(null);
  };
  const classes = useStyles();

  return (
    <div>
      <Button
        style={{ background: open ? '#808285' : '' }}
        className={classes.button}
        size={'small'}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        {t('campaignsTable.filter')}
      </Button>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            left: '50%',
            transform: 'translateX(0%) translateY(65%)',
          },
        }}
      >
        <MenuItem onClick={handleSortACS} disableRipple>
          {t('campaignsTable.oldToNew')}
        </MenuItem>
        <MenuItem onClick={handleSortDESC} disableRipple>
          {t('campaignsTable.newToOld')}
        </MenuItem>
      </Menu>
    </div>
  );
};
