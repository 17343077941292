import {
  ChangeEvent,
  MouseEvent,
  createRef,
  FC,
  RefObject,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { EditableLabel } from '_Components/EditableLabel/EditableLabel';
import React from 'react';
import { PlxExpansionPanel } from '_Components/Panels/PlxExpansionPanel/PlxExpansionPanel';
import { EditableTagControlIcons } from './EditableTagControlIcons';
import { CompaniesList } from './CompaniesList';
import { ITag } from '_Types/campaigns';
interface IProps {
  tag?: ITag;
  isChosen?: boolean;
  onClick?: (tagId: number | undefined) => void;
  onRemove?: (tagId: number | undefined) => void;
  onTagUpdate: (newTagName: string) => void;
  editOnInit?: boolean;
  onTagUpdateCancel?: () => void;
  enableDeleting?: boolean;
  showCampaigns?: boolean;
}

const StyledInput = styled.input`
  font-family: Work Sansf, sans-serif;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;
`;

export const EditableTag: FC<IProps> = ({
  tag,
  isChosen,
  onClick,
  onTagUpdate,
  editOnInit = false,
  onTagUpdateCancel,
  enableDeleting,
  showCampaigns,
  onRemove,
}) => {
  const [tagName, setTagName] = useState(tag?.name);
  const [newTagName, setNewTagName] = useState('');
  const [isEditing, setIsEditing] = useState(editOnInit);
  const changeNewTagName = (event: ChangeEvent<HTMLInputElement>) => {
    setNewTagName(event.target.value);
  };

  const inputRef: RefObject<HTMLInputElement> = createRef();
  const onSetEditing = (isEditing: boolean) => {
    setIsEditing(isEditing);
  };
  const enableEditing = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsEditing(!isEditing);
  };
  const updateTagName = () => {
    if (tagName !== newTagName && newTagName.trim() !== '') {
      onTagUpdate && onTagUpdate(newTagName);
      setTagName(newTagName);
    } else {
      onTagUpdateCancel && onTagUpdateCancel();
    }
  };
  const handleClick = () => {
    onClick && onClick(tag?.id);
  };
  const [expanded, setExpanded] = useState(false);
  const expand = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setExpanded(!expanded);
  };
  const handleRemove = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onRemove && onRemove(tag?.id);
  };

  return (
    <PlxExpansionPanel
      expanded={expanded}
      selected={isChosen}
      onClick={handleClick}
      controlComponent={() => {
        return (
          <>
            <EditableTagControlIcons
              expanded={expanded}
              editable={true}
              removable={enableDeleting}
              expandable={showCampaigns}
              onEditClick={enableEditing}
              onRemoveClick={handleRemove}
              onExpandClick={expand}
            />
          </>
        );
      }}
      headerComponent={() => {
        return (
          <EditableLabel
            text={tagName}
            type={'input'}
            placeholder={'New Tag'}
            childRef={inputRef}
            isEditing={isEditing}
            onSetEditing={onSetEditing}
            onKeyConfirm={updateTagName}
          >
            <StyledInput
              ref={inputRef}
              value={newTagName}
              onBlur={updateTagName}
              onChange={changeNewTagName}
            />
          </EditableLabel>
        );
      }}
      detailsComponent={() => {
        return <CompaniesList campaigns={tag?.campaigns} />;
      }}
    />
  );
};
