import React, { FC, useState } from 'react';
import {
  Title,
  TitleWrap,
  Td2,
  Th2,
  XemTableHeaderWrap,
  ShowToggleWrap,
  TogglesWrap,
  VerticalLine,
  ToggleTitle,
  Row,
  Row2,
  ButtonsWrapper,
  Th22,
  Td22,
  StyledTable,
  StyledTrHeader,
  StyledTr,
  Bold,
  StyledTr2,
  ColumnWrap,
  BorderLine,
  SelectorWrap,
  LabelSelect,
  Amount,
  PlaceholderText,
  SelectorRow,
} from './Styles';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { useTranslation } from 'react-i18next';
import { IDistributionPartnerChannelSelectableRow } from '_State/DistributionPartner/distributionPartner.types';
import { PlxSwitch } from '_Components/PlxCheckbox/PlxSwitch';
import { getEligibleChannelsCSV } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { ISelectOption } from '_Components/PlxSelect/types';
import { PlxTableHeaderSelect } from '_Components/PlxSelect/PlxTableHeaderSelect';
import { PlxTableHeaderButton } from '_Components/Buttons/PlxButton/PlxTableHeaderButton';
import { GridRowsProp } from '@material-ui/data-grid';
import { ActionsInput } from '_Components/Panels/PlxTilesPanel/ActionsInput';
import { InputWrapper } from '_Components/PlxAutoComplete/PlxAutoComplete';

interface IProps {
  channelTypeList: string[];
  options?: ISelectOption[];
  onDomainChange: (value: string) => void;
  onChannelTypeChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  data: GridRowsProp;
  totals: any;
  campaignStatus: string;
  completedOpportunity: IDistributionPartnerChannelSelectableRow[];
  invitedChannels: IDistributionPartnerChannelSelectableRow[];
  isNewCampaign?: boolean;
  totalPayout: number;
  openClosePayoutDialog: () => void;
  rowSelected: IDistributionPartnerChannelSelectableRow[];
  handleMultipleInvite: () => void;
  handleShowRejected: (e: React.ChangeEvent<{ value: unknown }>) => void;
  showRejectedValue: boolean;
  handleRebrandlyUrl: (e: React.ChangeEvent<{ value: unknown }>) => void;
  rebrandlyUrlEnabled: boolean | undefined;
  maxSpend: number | undefined;
  id?: string;
  isCSVButton?: boolean;
  handleBoostConfirm: (value: number) => void;
  handleBoostCancel: () => void;
  handleMaxValConfirm: (value: number) => void;
  handleMaxValCancel: () => void;
  focusedBoost: boolean;
  setFocusedBoost: React.Dispatch<React.SetStateAction<boolean>>;
  boostSpend: number | undefined;
  focusedMaxVal: boolean;
  setFocusedMaxVal: React.Dispatch<React.SetStateAction<boolean>>;
  maxSpendValue: number | undefined;
}

export const XemTableHeader: FC<IProps> = ({
  channelTypeList,
  options,
  onDomainChange,
  onChannelTypeChange,
  data,
  totals,
  campaignStatus,
  completedOpportunity,
  invitedChannels,
  isNewCampaign,
  totalPayout,
  openClosePayoutDialog,
  rowSelected,
  handleMultipleInvite,
  handleShowRejected,
  showRejectedValue,
  handleRebrandlyUrl,
  rebrandlyUrlEnabled,
  maxSpend,
  id,
  isCSVButton,
  handleBoostConfirm,
  handleBoostCancel,
  handleMaxValConfirm,
  handleMaxValCancel,
  focusedMaxVal,
  setFocusedMaxVal,
  maxSpendValue,
  boostSpend,
  focusedBoost,
  setFocusedBoost,
}) => {
  const { t } = useTranslation();
  const [csvButtonState, setCsvButtonState] = useState<boolean>(false);

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleSpectrumCampaignsCSV = () => {
    setCsvButtonState(true);
    getEligibleChannelsCSV(id).then((data) => {
      createDownloadCSV(data, 'finfluencer-channels');
    });
    setCsvButtonState(false);
  };

  return (
    <XemTableHeaderWrap>
      <Row>
        <ColumnWrap>
          <TitleWrap>
            <Title>
              {t('channels.table.label')} ({data.length})
            </Title>
          </TitleWrap>
          <TogglesWrap>
            <ShowToggleWrap>
              <PlxSwitch
                isDisabled={!isNewCampaign}
                value={isNewCampaign ? rebrandlyUrlEnabled : false}
                name={'rebrandlyUrlEnabled'}
                onChange={handleRebrandlyUrl}
              />
              <ToggleTitle
                style={
                  rebrandlyUrlEnabled && isNewCampaign
                    ? { color: '#AD41BD' }
                    : {}
                }
              >
                {t('channels.table.shortUrl')}
              </ToggleTitle>
            </ShowToggleWrap>
            <VerticalLine />
            <ShowToggleWrap>
              <PlxSwitch
                value={showRejectedValue}
                name={'showRejected'}
                onChange={handleShowRejected}
              />
              <ToggleTitle
                style={showRejectedValue ? { color: '#AD41BD' } : {}}
              >
                {t('channels.table.showRejected')}
              </ToggleTitle>
            </ShowToggleWrap>
          </TogglesWrap>
        </ColumnWrap>
        <ColumnWrap>
          <ButtonsWrapper>
            {campaignStatus === 'NEW' && rowSelected.length > 0 && (
              <PlxTableHeaderButton
                isDisabled={maxSpend === 0}
                label={'Invite Selected'}
                onClick={handleMultipleInvite}
              />
            )}
            {campaignStatus === 'COMPLETED' &&
              completedOpportunity.length !== 0 &&
              totalPayout > 0 && (
                <PlxTableHeaderButton
                  label={`Approve Payout $${totalPayout}`}
                  onClick={openClosePayoutDialog}
                />
              )}
            {isCSVButton && (
              <PlxTableHeaderButton
                isDisabled={csvButtonState}
                label={'Download CSV'}
                onClick={handleSpectrumCampaignsCSV}
                testId="download-csv-button"
              />
            )}
          </ButtonsWrapper>
        </ColumnWrap>
      </Row>

      <BorderLine />

      <Row2
        style={{
          paddingBottom: focusedBoost || focusedMaxVal ? '4rem' : '1.5rem',
        }}
      >
        <SelectorRow>
          <SelectorWrap>
            <>
              <LabelSelect>{t('channels.table.domain')}</LabelSelect>
              <PlxTableHeaderSelect
                rebrandlyUrlEnabled={rebrandlyUrlEnabled}
                isNewCampaign={isNewCampaign}
                initialValue={options && options[0].value}
                domain
                onChange={onDomainChange}
                options={options}
                channelTypeList={channelTypeList}
              />
            </>
          </SelectorWrap>
          <SelectorWrap>
            <LabelSelect>{t('channels.table.filterBy')}</LabelSelect>
            <PlxTableHeaderSelect
              filter
              onChannelTypeChange={onChannelTypeChange}
              channelTypeList={channelTypeList}
            />
          </SelectorWrap>
          <SelectorWrap>
            <LabelSelect>{t('channels.table.boostedSpend')}</LabelSelect>
            <InputWrapper>
              {focusedBoost ? (
                <ActionsInput
                  amount={boostSpend}
                  onConfirm={handleBoostConfirm}
                  onCancel={handleBoostCancel}
                  maxSpend
                />
              ) : (
                <Amount
                  editable
                  onClick={() => setFocusedBoost((prev) => !prev)}
                >
                  $ {boostSpend}
                  {boostSpend === 0 && (
                    <PlaceholderText>
                      {t('channels.table.validationError')}
                    </PlaceholderText>
                  )}
                </Amount>
              )}
            </InputWrapper>
          </SelectorWrap>
          <SelectorWrap>
            <LabelSelect>{t('channels.table.channelSpend')}</LabelSelect>
            <InputWrapper>
              {focusedMaxVal ? (
                <ActionsInput
                  amount={maxSpendValue}
                  onConfirm={handleMaxValConfirm}
                  onCancel={handleMaxValCancel}
                  maxSpend
                />
              ) : (
                <Amount
                  style={
                    !isNewCampaign
                      ? {
                          cursor: 'default',
                          pointerEvents: 'none',
                          opacity: '0.6',
                        }
                      : {}
                  }
                  editable
                  onClick={() => setFocusedMaxVal((prev) => !prev)}
                >
                  $ {maxSpendValue}
                  {isNewCampaign && maxSpendValue === 0 && (
                    <PlaceholderText>
                      {t('channels.table.validationError')}
                    </PlaceholderText>
                  )}
                </Amount>
              )}
            </InputWrapper>
          </SelectorWrap>
        </SelectorRow>
      </Row2>

      {totals.invitedTotalOffers > 0 && (
        <StyledTable>
          <thead>
            <StyledTrHeader>
              <Th2>{t('channels.table.channelName')}</Th2>
              <Th2>{t('channels.table.offer')}</Th2>
              <Th22>{t('channels.table.followers')}</Th22>
            </StyledTrHeader>
          </thead>
          <tbody>
            <StyledTr>
              <Td2>
                {t('channels.table.invitedPreviously')}{' '}
                <Bold>({invitedChannels.length})</Bold>
              </Td2>
              <Td2>
                $
                <StandardizeAmount
                  amount={totals.invitedTotalOffers}
                  decimal={0}
                />
              </Td2>
              <Td22>
                <StandardizeAmount
                  amount={totals.invitedTotalFollowers}
                  decimal={0}
                />
              </Td22>
            </StyledTr>
          </tbody>
          <tbody>
            <StyledTr2>
              <Td2>
                {t('channels.table.acceptedOffer')}{' '}
                <Bold>({totals.acceptedTotalChannel})</Bold>
              </Td2>
              <Td2>
                $
                <StandardizeAmount
                  amount={totals.acceptedTotalOffers}
                  decimal={0}
                />
              </Td2>
              <Td22>
                <StandardizeAmount
                  amount={totals.acceptedTotalFollowers}
                  decimal={0}
                />
              </Td22>
            </StyledTr2>
          </tbody>
        </StyledTable>
      )}
    </XemTableHeaderWrap>
  );
};
