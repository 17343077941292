export interface ICampaigns<T> {
  content: T[];
  pageable: IPageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: ISort;
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export interface IPageable {
  sort: ISort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface IPayment {
  paymentId: number;
  paymentStatus: string;
  paymentMethod: string;
  amount: number;
}

export interface ISort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface ITagCampaignInfo {
  id: number;
  startDate: string;
}

export interface ICampaignsQueryParams {
  sortDirection: 'ASC' | 'DESC' | string;
  sortByField: string;
  tagIdList: string[];
  campaignStatusList: string[];
  agencyClientId?: string;
}

export interface IAdminCampaignsQueryParams {
  sortDirection?: 'ASC' | 'DESC' | string;
  sortByField?: string;
  paymentStatusList?: string[];
  statusList?: string[];
  companyNameList?: string[];
  campaignId?: string;
  companyName?: string;
  pageNumber?: number;
  pageSize?: number;
}

export enum CampaignStatus {
  DRAFT,
  NEW,
  PAID,
  REVIEW_NEEDED,
  COMPLETED,
  LAUNCHED,
  PAUSED,
}

export enum PaymentStatus {
  SUCCESS,
  FAILED,
  IN_PROGRESS,
}

export interface ITag {
  id: number;
  name: string;
  campaigns: ITagCampaignInfo[];
}

export interface ITagUpdate {
  id: number;
  newName: string;
}

export interface ICompanyNameQueryParams {
  type: string;
}

export interface TagObject {
  name: string;
  id: number;
}
export interface IStatistics {
  id?: number;
  reactions: number;
  shares: number;
  comments: number;
  createdDate: string;
  tag?: TagObject;
}
export interface IStockCompany {
  id: number;
  exchangeSymbol: string;
  companySymbol: string;
  companyName: string;
}

export interface IStockTickerSearchDTO {
  searchString: string;
  inStockExchanges: string[];
  notInStockExchanges: string[];
  pageNumber: number;
  pageSize: number;
}

export interface IStockTickersData {
  content: IStockCompany[];
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export interface IPaymentHistory {
  paymentId: number;
  campaignId: number;
  campaignType: string;
  paymentMethod: string;
  paymentStatus: string;
  createdDate: string;
  paymentDate: string;
  amount: number;
}

export interface IPaymentDTO {
  amount: number;
  paymentMethod: string;
  pricingType: string;
  programName?: string;
  campaignPackage?: string;
  promotionalCode?: string;
  cardholder?: string;
  creditCardNumber?: string;
  expirationDate?: string;
  cvc?: string;
  country?: string;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  email?: string;
  taxRate?: number;
  tier?: string;
}
