import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import { css } from '@emotion/core';

const PlxPanelContent = styled.div``;

const PlxPanelBody = styled.div<IProps>`
  padding: 3.5rem;
  background: white;
  border: 1px solid #d5dde3;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  transition: background-color 0.5s;

  ${({ enableActions }) =>
    enableActions &&
    css`
      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
        transition: background-color 0.5s;
      }
    `}
`;

interface IProps extends ICommonProps {
  content?: any;
  enableActions?: boolean;
  newDesign?: boolean;
}
export const PlxPanel: FC<IProps> = ({ content, enableActions, newDesign }) => (
  <PlxPanelBody
    style={{
      padding: newDesign ? 0 : undefined,
    }}
    enableActions={enableActions}
    className={'PlxPanel-body'}
  >
    <PlxPanelContent className={'PlxPanel-content'}>{content}</PlxPanelContent>
  </PlxPanelBody>
);
