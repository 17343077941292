import {
  IDistributionPartnerAddChannelForm,
  IDistributionPartnerChannelDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import styled from '@emotion/styled';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { industryConfig } from '_Routes/Home/_routes/Thrive/config';
import { mapTranslatedLabel } from '_App/utils';
import { geoConfig } from '_Routes/Home/_routes/Spectrum/config';
import { PlxLoader } from '_Components/PlxLoader';
import { ButtonGroupSection } from '_Routes/Home/_Components/Sections/ButtonGroupSection';
import { channelTypeConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/distributionPartnerForm.config';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import { getChannels } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import _ from 'lodash';
import { PlxCheckbox } from './../../../../src/_Components/PlxCheckbox/PlxCheckbox';

const INITIAL_VALUES: IDistributionPartnerAddChannelForm = {
  channelType: '',
  industry: [],
  geolocations: [],
  channelName: '',
  channelUrl: '',
  numberOfFollowers: undefined,
  numberOfPostPerWeek: undefined,
  groupDescription: '',
  dontWantOffer: false,
};

const REQUIRED_FIELDS = [
  'channelType',
  'industry',
  'geolocations',
  'channelName',
  'channelUrl',
  'numberOfFollowers',
  'numberOfPostPerWeek',
];

const NLWrapper = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    flex: 1;
  }
`;

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

interface IProps {
  onSubmit: (form: IDistributionPartnerAddChannelForm) => void;
  apiError?: string;
  footerButtons?: boolean;
  id?: string;
  performSubmit?: boolean;
}

export const ChannelForm: FC<IProps> = ({
  onSubmit,
  footerButtons,
  id,
  performSubmit,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDistributionPartnerChannelDTO>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        onSubmit(form);
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      const Url = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
      if (
        form.channelUrl &&
        !form.channelUrl.startsWith('http://') &&
        !form.channelUrl.startsWith('https://')
      ) {
        errors.channelUrl = t('errors.companyWebsite');
      }

      if (form.channelUrl && !Url.test(form.channelUrl)) {
        errors.channelUrl = 'Please enter a valid URL';
      }
      if (
        form.numberOfFollowers &&
        (isNaN(form.numberOfFollowers) || form.numberOfFollowers < 0)
      ) {
        errors.numberOfFollowers = 'Please provide correct number value';
      }
      if (form.numberOfFollowers && form.numberOfFollowers > 2147483647) {
        errors.numberOfFollowers = 'Value is too big';
      }
      if (form.industry && form.industry.length < 1) {
        errors.industry = 'This field is required';
      }
      if (form.geolocations && form.geolocations.length < 1) {
        errors.geolocations = 'This field is required';
      }
      if (form.categoriesOfInterest && form.categoriesOfInterest.length < 1) {
        errors.categoriesOfInterest = 'This field is required';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const industryOptions = industryConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );

  const geolocationOptions = geoConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );

  const channelTypeOptions = _.cloneDeep(channelTypeConfig);

  const handleNextStep = () => {
    formik.handleSubmit();
  };

  const fillForm = (data?: IDistributionPartnerChannelDTO) => {
    formik.setValues({
      channelType: data?.channelType,
      industry: data?.industry,
      geolocations: data?.geolocations,
      channelName: data?.channelName,
      channelUrl: data?.channelUrl,
      channelVisibility: data?.channelVisibility,
      numberOfFollowers: data?.numberOfFollowers,
      numberOfPostPerWeek: data?.numberOfPostPerWeek,
      groupDescription: data?.groupDescription,
      dontWantOffer: data?.dontWantOffer,
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getChannels({ channelId: id }).then(
        ({ content }) => {
          setData(content[0]);
          fillForm(content[0]);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (performSubmit !== undefined) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [performSubmit]);

  const channelTypeInitialValue = useMemo(
    () => (data?.channelType ? [data?.channelType] : undefined),
    [data]
  );
  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <>
          <ButtonGroupSection
            buttonsConfig={channelTypeOptions}
            title={t('finfluencer.addChannel.channelType')}
            otherOption
            inputTitle={t('finfluencer.addChannel.channelTypeInput')}
            required
            placeholder={t(
              'finfluencer.addChannel.channelTypeInputPlaceholder'
            )}
            setValue={formik.setFieldValue}
            fieldName={'channelType'}
            value={channelTypeInitialValue}
            testId="channel-type-form"
          />
          <FieldErrorMessage
            message={formik.errors.channelType}
            testId="channel-type-error"
          />
          <ButtonGroupSection
            title={t('spectrum.program.geoLocation')}
            tooltip={t('finfluencer.addChannel.geoLocationTooltip')}
            multiple={true}
            required
            fieldName={'geolocations'}
            setValue={formik.setFieldValue}
            buttonsConfig={geolocationOptions}
            value={data?.geolocations}
            testId="geo-location-form"
          />
          <FieldErrorMessage
            message={formik.errors.geolocations}
            testId="geo-location-error"
          />
          <NLWrapper>
            <div>
              <InputSectionX
                title={t('finfluencer.addChannel.channelName')}
                placeholder={t('finfluencer.addChannel.channelNamePlaceholder')}
                required
                fieldName={'channelName'}
                handleChange={formik.handleChange}
                value={data?.channelName}
                testId="channel-name-field"
              />
              <FieldErrorMessage
                message={formik.errors.channelName}
                testId="channel-name-error"
              />
            </div>
            <div>
              <InputSectionX
                title={t('finfluencer.addChannel.channelUrl')}
                placeholder={t('finfluencer.addChannel.channelUrlPlaceholder')}
                required
                fieldName={'channelUrl'}
                handleChange={formik.handleChange}
                value={data?.channelUrl}
                testId="cahnnel-url-field"
              />
              <FieldErrorMessage
                message={formik.errors.channelUrl}
                testId="channel-url-error"
              />
            </div>
          </NLWrapper>
          <NLWrapper>
            <div>
              <InputSectionX
                typeNumber
                title={t('finfluencer.addChannel.numberOfFollowers')}
                placeholder={t(
                  'finfluencer.addChannel.numberOfFollowersPlaceholder'
                )}
                required
                fieldName={'numberOfFollowers'}
                handleChange={formik.handleChange}
                value={data?.numberOfFollowers}
                testId="followers-field"
              />
              <FieldErrorMessage
                message={formik.errors.numberOfFollowers}
                testId="followers-error"
              />
            </div>
            <div>
              <InputSectionX
                typeNumber
                title={t('finfluencer.addChannel.numberOfPostPerWeek')}
                placeholder={t(
                  'finfluencer.addChannel.numberOfPostPerWeekPlaceholder'
                )}
                required
                fieldName={'numberOfPostPerWeek'}
                handleChange={formik.handleChange}
                value={data?.numberOfPostPerWeek}
                testId="posts-field"
              />
              <FieldErrorMessage
                message={formik.errors.numberOfPostPerWeek}
                testId="posts-error"
              />
            </div>
          </NLWrapper>
          <ButtonGroupSection
            otherOption={true}
            buttonsConfig={industryOptions}
            title={t('spectrum.program.industry')}
            tooltip={t('finfluencer.addChannel.industryTooltip')}
            placeholder={t('spectrum.program.fillIndustry')}
            inputTitle={t('spectrum.program.specifyIndustry')}
            required
            multiple
            setValue={formik.setFieldValue}
            fieldName={'industry'}
            value={data?.industry}
            testId="industry-form"
          />
          <FieldErrorMessage
            message={formik.errors.industry}
            testId="industry-error"
          />
          <InputSectionX
            title={t('finfluencer.addChannel.groupDescription')}
            placeholder={t(
              'finfluencer.addChannel.groupDescriptionPlaceholder'
            )}
            fieldName={'groupDescription'}
            multiline
            rows={3}
            handleChange={formik.handleChange}
            value={data?.groupDescription}
            testId="description-field"
          />
          <PlxCheckbox
            label={t('finfluencer.addChannel.dontWantOffer')}
            name={'dontWantOffer'}
            onChange={formik.handleChange}
            value={formik.values.dontWantOffer}
            testId="offer-checkbox"
          />
          {footerButtons && (
            <FooterButtons
              onSubmit
              onNextStep={handleNextStep}
              testId="submit-button"
            />
          )}
        </>
      )}
    </>
  );
};
