import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import { IThemed } from '_Styles/types';

const Wrapper = styled.div<IThemed>`
  height: 4rem;
`;

const MessageBody = styled.div<IThemed & { error: boolean }>`
  // padding: 2rem 2.5rem;
  border-radius: 5px;
  font-size: 1.2rem;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : '#00AC26'};
`;

interface IProps extends ICommonProps {
  message: string;
  error?: boolean;
}

export const FormMassage: FC<IProps> = ({
  message,
  error = true,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {message && (
        <MessageBody error={error}>
          <span>{message}</span>
        </MessageBody>
      )}
    </Wrapper>
  );
};
