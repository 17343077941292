import React, { FC, useMemo, useState } from 'react';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { useTranslation } from 'react-i18next';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import styled from '@emotion/styled';
import { IThriveLead, IThriveLeadForm } from '_State/Thrive/types';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { AddNewLeadDialog } from './AddNewLeadDialog';
import { createThriveLeadsTableConfig } from '../../../../Thrive/Routes/ThriveDashboard/_Components/ThriveLeadsTable/utils';
import { ICommonProps } from '_Types/props';
const StyledButton = styled(PlxLinkTextButton)`
  margin-left: auto;
`;

const StyledTable = styled(PlxCustomTable)`
  && {
    width: 100%;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 15rem;
  }
`;

const Wrapper = styled.div``;

interface IProps extends ICommonProps {
  leads: IThriveLead[];
  onSortingChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onGeoLocationChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  sorting: string;
  geoLocation?: string[];
  handleAddNewLead: (form: IThriveLeadForm) => Promise<any>;
}

export const AdminThriveLeadsTable: FC<IProps> = ({
  className,
  leads,
  onSortingChange,
  onGeoLocationChange,
  sorting,
  geoLocation,
  handleAddNewLead,
}) => {
  const { t } = useTranslation();
  const [addNewLeadDialogOpen, setAddNewLeadDialogOpen] = useState(false);
  const sortingOptions = [
    {
      id: 'ASC',
      name: t('campaignsTable.sort.asc'),
    },
    {
      id: 'DESC',
      name: t('campaignsTable.sort.desc'),
    },
  ];
  const geoLocationOptions = [
    {
      id: 'USA',
      name: 'USA',
    },
    {
      id: 'CANADA',
      name: 'Canada',
    },
    {
      id: 'EUROPE',
      name: 'Europe',
    },
  ];

  const onAddNewLead = (form: IThriveLeadForm, campaignId?: string) => {
    return handleAddNewLead(form).then(() => {
      setAddNewLeadDialogOpen(false);
    });
  };

  const config = useMemo(() => {
    return createThriveLeadsTableConfig() as CellConfig<{}>[];
  }, []);
  return (
    <Wrapper className={className}>
      {config && (
        <StyledTable
          config={config}
          data={leads}
          maxContentHeight={'calc(48rem - 64px)'}
        >
          <PlxTableToolbar label={t('thrive.leadsTable.leadsDatabase')}>
            <StyledSelect
              options={sortingOptions}
              label={t('campaignsTable.sort.label')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledMultipleSelect
              options={geoLocationOptions}
              label={t('thrive.leadsTable.geoLocation')}
              onChange={onGeoLocationChange}
              value={geoLocation}
            />
            <StyledButton
              label={'Add new lead +'}
              onClick={() => setAddNewLeadDialogOpen(true)}
            />
          </PlxTableToolbar>
        </StyledTable>
      )}
      <AddNewLeadDialog
        onClose={() => setAddNewLeadDialogOpen(false)}
        open={addNewLeadDialogOpen}
        onSubmit={onAddNewLead}
      />
    </Wrapper>
  );
};
