import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxText } from '_Components/Text/PlxText';
import { useTranslation } from 'react-i18next';
import { CAMPAIGN_STATUS_MAP_STRATEGY } from '../../../../types';
import { getCampaignStatus } from '../utils';

interface IProps {
  status: string;
}

interface IText {
  color: string;
}

const StyledText = styled(PlxText)<IText>`
  color: ${({ color }) => color};
`;
export const Status: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <StyledText
      color={
        (CAMPAIGN_STATUS_MAP_STRATEGY as any)[getCampaignStatus(status)].color
      }
    >
      {t(
        (CAMPAIGN_STATUS_MAP_STRATEGY as any)[getCampaignStatus(status)].label
      )}
    </StyledText>
  );
};
