import { PlxSideContainer } from '_Components/PlxSideImage/PlxSideContainer';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const errorMessage =
    new URLSearchParams(location.search).get('message') ??
    'Unexpected Error occured';

  const errorMessageWithIcon = `❌ ${decodeURIComponent(errorMessage)}`;

  return (
    <PlxSideContainer
      // isClient
      subtitle={decodeURIComponent(errorMessageWithIcon)}
      className="side-image"
    />
  );
};

export default ErrorPage;
