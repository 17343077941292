import React, { FC, useContext, useEffect, useState } from 'react';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { Products } from '_Routes/Home/_routes/Dashboard/Products/Products';
import { HomePageWrapper } from '_Routes/Home/_Components/HomePageWrapper';
import { getThriveCampaigns } from '_State/Thrive/Api/thrive.api';
import { getSpectrumCampaigns } from '_State/User/Api/user.api';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { IThriveCampaign } from '_State/Thrive/types';
import { DashboardSpectrum } from '_Routes/Home/_routes/Dashboard/DashboardSpectrum/DashboardSpectrum';
import {
  DashboardContext,
  IDashboardContext,
} from '_Routes/Home/_routes/Dashboard/Context/DashboardContext';
import {
  ROUTE_SPECTRUM_MAP,
  ROUTE_THRIVE_MAP,
} from '_Routes/Home/_routes/Spectrum/utils';
import { ICampaignsQueryParams } from '_Types/campaigns';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { useTranslation } from 'react-i18next';

export const INITIAL_SPECTRUM_PARAMS: ICampaignsQueryParams = {
  campaignStatusList: ['COMPLETED', 'REVIEW_NEEDED', 'PAUSED'],
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  tagIdList: [],
};

export const INITIAL_THRIVE_PARAMS: ICampaignsQueryParams = {
  campaignStatusList: ['COMPLETED', 'LAUNCHED', 'PAUSED'],
  sortDirection: 'DESC',
  sortByField: 'startDate',
  tagIdList: [],
};

const DashboardWrapper = styled.div`
  height: 100%;
  display: flex;
  font-family: 'Quicksand';
  font-style: normal;
  color: #231f20;
  flex-direction: column;
`;

export const Dashboard: FC<ICommonProps> = () => {
  const { t } = useTranslation();
  const [spectrumCampaigns, setSpectrumCampaigns] = useState<
    ISpectrumCampaign[]
  >([]);
  const [thriveCampaigns, setThriveCampaigns] = useState<IThriveCampaign[]>([]);
  const [spectrumLink, setSpectrumLink] = useState<string>('');
  const [thriveLink, setThriveLink] = useState<string>('');
  const [spectrumLoading, setSpectrumLoading] = useState<boolean>(true);
  const [thriveLoading, setThriveLoading] = useState<boolean>(true);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);
  const [params, setParams] = useState<ICampaignsQueryParams>(
    INITIAL_SPECTRUM_PARAMS
  );

  useEffect(() => {
    if (isAgencyType && clientSelected) {
      setParams((prevParams) => ({
        ...prevParams,
        agencyClientId: clientSelected as string,
      }));
      setSpectrumCampaigns([]);
    }
  }, [clientSelected, isAgencyType]);

  useEffect(() => {
    getSpectrumCampaigns(params)
      .then((campaigns) => {
        setSpectrumCampaigns(campaigns.content);
        const route = ROUTE_SPECTRUM_MAP.find((strategy) =>
          strategy.isApplicable(campaigns.content)
        );
        if (route) {
          setSpectrumLink(isAgencyType ? route.agencyLinkPath : route.linkPath);
        }
      })
      .then(() => setSpectrumLoading(false));
    getThriveCampaigns(INITIAL_THRIVE_PARAMS)
      .then((campaigns) => {
        setThriveCampaigns(campaigns.content);
        const route = ROUTE_THRIVE_MAP.find((strategy) =>
          strategy.isApplicable(campaigns.content)
        );
        if (route) {
          setThriveLink(isAgencyType ? route.agencyLinkPath : route.linkPath);
        }
      })
      .then(() => setThriveLoading(false));
    //eslint-disable-next-line
  }, [clientSelected, isAgencyType, params]);

  const context: IDashboardContext = {
    spectrumLink,
    thriveLink,
    spectrumLoading,
    thriveLoading,
    spectrumCampaigns,
    thriveCampaigns,
  };
  return (
    <HomePageWrapper>
      <DashboardContext.Provider value={context}>
        <DashboardWrapper>
          <Products />
          {!isAgencyType || clientSelected ? (
            <DashboardSpectrum />
          ) : (
            t('campaignsTable.selectClientDashboard')
          )}
          {/* <DashboardThrive /> */}
        </DashboardWrapper>
      </DashboardContext.Provider>
    </HomePageWrapper>
  );
};
