import React, { ReactElement, ReactNode } from 'react';
import { PlxListItem } from '_Components/PlxList/PlxListItem';
import styled from '@emotion/styled';
// import { TableHeader } from '_Routes/Home/_routes/DistributionPartner/utils';

export interface CellConfigX<RowData> {
  name: string;
  prop: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}
export interface CellConfig<RowData> {
  name: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}

export interface PlxListProps<RowData> {
  data?: RowData[];
  config: CellConfig<RowData>[];
  title?: string;
  highlighted?: boolean;
  visibility?: any;
  disableItem?: boolean;
}

const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;
// const ListHeader = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-start;
//   font-family: Rubik;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 1.6rem;
//   line-height: 3.3rem;
//   color: #1b1b1b;
// `;

export const PlxList = <RowData extends {}>({
  highlighted,
  config,
  data,
  title,
  visibility,
  disableItem,
}: PlxListProps<RowData> & { children?: ReactNode }) => {
  return (
    <>
      <ListBody>
        {data?.map((rowData, index) => {
          return (
            <PlxListItem
              data={rowData}
              config={config}
              key={index}
              highlighted={highlighted}
              disableItem={disableItem}
            />
          );
        })}
      </ListBody>
    </>
  );
};
