import React, { FC, useEffect, useMemo, useState } from 'react';
import { PlxLoader } from '_Components/PlxLoader';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { useTranslation } from 'react-i18next';
import {
  IAddAdminOrEditorForm,
  IAdminUsersQueryParams,
  IUserDTO,
} from '_State/User/user.types';
import { createAdminsAndEditorsTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/utils';
import { SORTING_OPTIONS } from '_Types/adminTable';
import {
  addAdminOrEditor,
  deleteUser,
  getAdminsAndEditors,
  updateAdminOrEditor,
} from '_State/User/Api/admin-user.api';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { AddAdminOrEditorForm } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/Forms/AddAdminOrEditorForm';
import { EditAdminOrEditorForm } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/Forms/EditAdminOrEditorForm';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { DeleteConfirmationDialog } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/DeleteConfirmation';

const INITIAL_PARAMS: IAdminUsersQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageSize: 1000,
};
const sortingOptions = SORTING_OPTIONS;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledInput = styled(PlxInput)`
  margin-left: auto;
  width: 31rem;
  margin-right: 3.5rem;
`;

const StyledButton = styled(PlxLinkTextButton)`
  margin: 0 2rem;
`;

export const AdminsAndEditorsTable: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState<IUserDTO>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);
  const [searchedPhrase, setSearchedPhrase] = useState();
  const [sorting, setSorting] = useState<string>('DESC');
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const [
    deleteConfirmationDialogOpen,
    setDeleteConfirmationDialogOpen,
  ] = useState(false);

  const fetchData = () => {
    return getAdminsAndEditors(searchParams)
      .then(({ content }) => {
        setData(content);
      })
      .catch((error) =>
        error.response.json().then(() => {
          console.log(error);
          setLoading(false);
        })
      );
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const handleEdit = (form: any) => {
    setApiError(undefined);
    return updateAdminOrEditor(form, editData?.id).then(
      () => {
        setLoading(true);
        setEditDialogOpen(false);
        fetchData().then(() => {
          setLoading(false);
        });
      },
      (error) => {
        error.response.json().then((err: any) => {
          const errors = err.fieldErrors.map((v: any) => v.defaultMessage);
          console.log(errors);
          setApiError(errors);
        });
      }
    );
  };

  const handleAdd = (form: IAddAdminOrEditorForm) => {
    setApiError(undefined);
    return addAdminOrEditor(form).then(
      () => {
        setLoading(true);
        setAddDialogOpen(false);
        fetchData().then(() => {
          setLoading(false);
        });
      },
      (error) => {
        error.response.json().then((err: any) => {
          const errors = err.fieldErrors.map((v: any) => v.defaultMessage);
          console.log(errors);
          setApiError(errors);
        });
      }
    );
  };

  const openDeleteDialog = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDelete = () => {
    setLoading(true);
    deleteUser(editData?.id)
      .then(() => {
        fetchData().then(() => {
          setLoading(false);
          setDeleteConfirmationDialogOpen(false);
          setEditDialogOpen(false);
        });
      })
      .catch((error) => {
        error.response.json().then((err: any) => {
          const errors = err.fieldErrors.map((v: any) => v.defaultMessage);
          console.log(errors);
          setApiError(errors);
        });
      });
  };

  const openEditDialog = (client: IUserDTO) => {
    setEditData(client);
    setEditDialogOpen(true);
  };

  const config = useMemo(() => {
    return createAdminsAndEditorsTableConfig(
      openEditDialog
    ) as CellConfig<{}>[];
  }, []);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setSearchParams({
      ...searchParams,
      sortDirection: event.target.value as string,
    });
  };

  const onPhraseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchedPhrase(event.target.value as string);
    setSearchParams({
      ...searchParams,
      searchedPhrase: event.target.value as string,
    });
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <PlxCustomTable
          fullHeight
          data={data}
          config={config}
          key={'adminsAndEditors'}
        >
          <PlxTableToolbar label={'Editors/Admins'}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('admin.users.search')}
              value={searchedPhrase}
              onChange={onPhraseChange}
              fullWidth={false}
            />
            <StyledButton
              label={'+ Add new Admin/Editor'}
              onClick={() => setAddDialogOpen(true)}
            />
          </PlxTableToolbar>
        </PlxCustomTable>
      )}
      <PlxDialog
        title={'Add Admin or Editor'}
        open={addDialogOpen}
        onClose={closeAddDialog}
      >
        <AddAdminOrEditorForm
          onSubmit={handleAdd}
          onClose={closeAddDialog}
          error={apiError}
        />
      </PlxDialog>
      <PlxDialog
        title={'Edit Admin/Editor'}
        open={editDialogOpen}
        onClose={closeEditDialog}
      >
        <EditAdminOrEditorForm
          data={editData}
          onSubmit={handleEdit}
          onClose={closeEditDialog}
          onDelete={openDeleteDialog}
          error={apiError}
        />
      </PlxDialog>
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        onSubmit={handleDelete}
        error={apiError}
        processing={loading}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
};
