import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import {
  FormWrapper,
  InputTitle,
  InputWrapper,
  Inputs,
} from '_Routes/Home/_Components/PostSamples/Styles';
import { PlxFilesInput } from '_Routes/Home/_Components/PostSamples/FilesInput';
import { Attachment } from './SubmitAnalyticsForm';

interface IProps {
  formik: any;
  dialogTitle: string;
  open: boolean;
  initialValue?: any;
  onClose: () => void;
  onSave: () => void;
  updatedAttachments: Attachment[];
  setUpdatedAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}

export const AddScreenshotsDialog: FC<IProps> = ({
  formik,
  dialogTitle,
  open,
  initialValue,
  onClose,
  onSave,
  updatedAttachments,
  setUpdatedAttachments,
}) => {
  const { t } = useTranslation();

  const handleChange = (base64Urls: string[], fileNames: string[]) => {
    const postSample =
      formik.values.postSample !== null
        ? formik.values.postSample
        : initialValue;
    const cleanedBase64Urls = base64Urls.map((base64Content) =>
      base64Content.replace(/^data:image\/[a-z]+;base64,/, '')
    );
    const attachmentsData = cleanedBase64Urls.map((base64Content, index) => ({
      fileName: fileNames[index],
      base64Content: base64Content,
    }));
    const currentAttachments = postSample?.attachments || [];
    const updatedAttachments = [...currentAttachments, ...attachmentsData];
    setUpdatedAttachments(updatedAttachments);
  };

  const handleSave = () => {
    formik.setFieldValue('postSample.attachments', updatedAttachments);
    onSave();
  };

  return (
    <PlxDialog
      title={dialogTitle}
      onSave={handleSave}
      open={open}
      closeButton
      onClose={onClose}
    >
      <FormWrapper>
        <Inputs>
          <InputWrapper>
            <InputTitle>{t('spectrum.admin.uploadSample')}</InputTitle>
            <PlxFilesInput fileNames={[]} onChange={handleChange} />
          </InputWrapper>
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
