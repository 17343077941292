import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import React from 'react';
import { IThriveLeadForm } from '_State/Thrive/types';
import {
  getGeolocations,
  getGeolocationsStates,
} from '_State/Thrive/Api/thrive-admin.api';
import { InputSection } from '../../../../../_Components/Sections/InputSection';
import { SelectSection } from '../../../../../_Components/Sections/SelectSection';
import { ISimpleSelectOption } from '_Types/select';
import { mapGeoLocationsToSelect, mapStatesToSelect } from './config';
import { PlxDatePicker } from '../../../../../../../_Components/PlxDatePicker/PlxDatePicker';
import { FormSection } from '../../../../../../../_Components/FormSection/FormSection';
import moment from 'moment';
import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';

const FormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  overflow: auto;
`;

const Inputs = styled.div``;

interface IProps {
  open: boolean;
  campaignId?: string;
  onClose: () => void;
  onSubmit: (form: any, campaignId?: string) => Promise<any>;
}

export const AddNewLeadDialog: FC<IProps> = ({
  open,
  onClose,
  onSubmit,
  campaignId,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [geolocations, setGeoLocations] = useState<ISimpleSelectOption[]>([]);
  const [stateFieldValue, setStateFieldValue] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [states, setStates] = useState<ISimpleSelectOption[]>([]);
  const INITIAL_VALUES: IThriveLeadForm = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    geolocationId: '',
    geolocationStateId: '',
    optInDate: '',
  };

  const REQUIRED_FIELDS = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'geolocationId',
    'geolocationStateId',
    'optInDate',
  ];

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  useEffect(() => {
    getGeolocations().then((v) => {
      setGeoLocations(mapGeoLocationsToSelect(v));
    });
  }, []);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      setIsSubmitting(true);
      onSubmit(form, campaignId)
        .then(() => {
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
        });
    },
    validate: (form) => {
      return requiredFieldsValidation(form);
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleGeolocationChange = (fieldName: string, value: string) => {
    getGeolocationsStates(value).then((v) => {
      setStates(mapStatesToSelect(v));
    });
    formik.setFieldValue('geolocationStateId', '');
    setStateFieldValue('');
    formik.setFieldValue(fieldName, value);
  };

  const handleStateFieldChange = (fieldName: string, value: string) => {
    formik.setFieldValue(fieldName, value);
    setStateFieldValue(value);
  };

  const handleDateChange = (date: Date | null) => {
    const optInDate = moment(date).format('YYYY-MM-DD');
    formik.setFieldValue('optInDate', optInDate);
    setSelectedDate(date);
  };

  return (
    <PlxDialog
      title={'Add new Lead'}
      open={open}
      closeButton
      onClose={onClose}
      onSave={formik.handleSubmit}
      onSaveDisabled={isSubmitting}
    >
      <FormWrapper>
        <Inputs>
          <InputSection
            required
            title={t('thrive.admin.leadDialog.firstName')}
            placeholder={t('thrive.admin.leadDialog.firstNamePlaceholder')}
            fieldName={'firstName'}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.firstName} />
          <InputSection
            required
            title={t('thrive.admin.leadDialog.lastName')}
            placeholder={t('thrive.admin.leadDialog.lastNamePlaceholder')}
            fieldName={'lastName'}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.lastName} />
          <InputSection
            required
            title={t('thrive.admin.leadDialog.email')}
            placeholder={t('thrive.admin.leadDialog.emailPlaceholder')}
            fieldName={'email'}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.email} />
          <InputSection
            required
            title={t('thrive.admin.leadDialog.phone')}
            placeholder={t('thrive.admin.leadDialog.phonePlaceholder')}
            fieldName={'phone'}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.phone} />
          <SelectSection
            required
            selectConfig={geolocations}
            fieldName={'geolocationId'}
            title={t('thrive.admin.leadDialog.geolocation')}
            placeholder={t('thrive.admin.leadDialog.geolocationPlaceholder')}
            setValue={handleGeolocationChange}
          />
          <FieldErrorMessage message={formik.errors.geolocationId} />
          {formik.values.geolocationId && (
            <>
              <SelectSection
                required
                selectConfig={states}
                fieldName={'geolocationStateId'}
                title={t('thrive.admin.leadDialog.state')}
                placeholder={t('thrive.admin.leadDialog.statePlaceholder')}
                setValue={handleStateFieldChange}
                value={stateFieldValue}
              />
              <FieldErrorMessage message={formik.errors.geolocationStateId} />
            </>
          )}
          <FormSection title={t('thrive.admin.leadDialog.optInDate')} required>
            <PlxDatePicker
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              placeholder={t('thrive.admin.leadDialog.optInDatePlaceholder')}
              value={selectedDate}
              fullwidth={true}
              onChange={handleDateChange}
            />
            <FieldErrorMessage message={formik.errors.optInDate} />
          </FormSection>
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
