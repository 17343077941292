import { GeoLocation } from '_Routes/Home/_routes/Spectrum/config';
import { IThriveCampaign } from '_State/Thrive/types';
import { ITile } from '_Types/ITile';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { useEffect, useState } from 'react';

export const mapTranslatedLabel = (item: any, translatedLabel: string) => ({
  ...item,
  label: translatedLabel,
});

export const capitalize = (value: string): string => {
  if (value.toUpperCase() === GeoLocation[GeoLocation.USA]) {
    return value.toUpperCase();
  }
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};

export const arrayToSplittedString = (
  value: string[],
  capitalized = true
): string[] => {
  return value.map((item, index) => {
    if (capitalized) {
      return index === value.length - 1
        ? capitalize(item)
        : `${capitalize(item)}, `;
    } else {
      return index === value.length - 1 ? item : `${item}, `;
    }
  });
};

export function stringArrayToEnum<T>(
  enumObj: T,
  array: string[]
): T[keyof T][] {
  return array.map((v) => {
    return enumObj[v as keyof T];
  });
}

const getTotalThriveInfoByKey = (
  campaigns: IThriveCampaign[],
  label: string,
  key: keyof IThriveCampaign
): ITile | undefined => {
  const total = campaigns
    .map((campaign) => campaign[key])
    .reduce((a: any, b: any) => a + b, 0);

  return {
    label: label,
    amount: total,
  };
};

export const createThriveStatisticsConfig = (
  campaigns: IThriveCampaign[]
): unknown[] => {
  return [
    getTotalThriveInfoByKey(
      campaigns,
      'thrive.dashboard.totalLeads',
      'totalNumberOfLeads'
    ),
    getTotalThriveInfoByKey(
      campaigns,
      'thrive.dashboard.impressions',
      'impressions'
    ),
    {
      label: 'thrive.dashboard.totalCampaigns',
      amount: campaigns.length,
    },
  ];
};

const getTotalSpectrumInfoByKey = (
  campaigns: ISpectrumCampaign[],
  label: string,
  key: keyof ISpectrumCampaign
): ITile | undefined => {
  const total = campaigns
    .map((campaign) => campaign[key])
    .reduce((a: any, b: any) => a + b, 0);

  return {
    label: label,
    amount: total,
  };
};

const getEstimatedReach = (
  campaigns: ISpectrumCampaign[]
): ITile | undefined => {
  const totalReach = campaigns
    .map((campaign) => campaign.numberOfFollowers)
    .reduce((a, b) => a + b, 0);

  return {
    label: 'spectrum.dashboard.estimatedReach',
    amount: totalReach,
  };
};

export const createSpectrumStatisticsConfig = (
  campaigns: ISpectrumCampaign[]
): unknown[] => {
  return [
    getEstimatedReach(campaigns),
    getTotalSpectrumInfoByKey(
      campaigns,
      'spectrum.dashboard.totalReactions',
      'reactions'
    ),
    getTotalSpectrumInfoByKey(
      campaigns,
      'spectrum.dashboard.totalComments',
      'comments'
    ),
    getTotalSpectrumInfoByKey(
      campaigns,
      'spectrum.dashboard.totalNewsShared',
      'shares'
    ),
  ];
};

export interface IScreenSize {
  width: number;
  height: number;
}

// Hook
export const useWindowSize = (): IScreenSize => {
  const [windowSize, setWindowSize] = useState<IScreenSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize(): void {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize || 0;
};
