import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

export const NavWrap = styled.div`
  margin: 2rem 0;
  min-width: 35rem;
`;
export const Wrap = styled.div`
  padding: 3px 20px;
  border: 1px solid #0000003b;
  border-radius: 12px;
  max-width: 32rem;
  > div > div {
    margin-top: 0;
  }
  label {
    transform: translate(0, 8px) scale(1);
  }
  input,
  div::before,
  div::after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiSelect-select.MuiSelect-select {
    background: none;
  }
  .MuiInputLabel-shrink {
    transform: none;
    display: none;
  }
`;

export const SpectrumDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  margin: auto;
  background-color: #fff;
`;

export const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
`;

export const StyledContent = styled.div`
  display: flex;
  gap: 3rem;
  margin-bottom: 3rem;
`;
