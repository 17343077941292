import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { AuthPageWrapper } from '_Routes/Auth/_Components/AuthPageWrapper';
import { AuthHeader } from '_Routes/Auth/_Components/AuthHeader';
import { IThemed } from '_Styles/types';
import { PlxLinkWrapper } from '_Components/Buttons/PlxLinkWrapper/PlxLinkWrapper';
import { SubParagraph } from '_Routes/Auth/utils';
import { ClientRegForm } from './ClientRegForm';
import { AgencyRegForm } from '_Routes/Auth/Agency/Register/AgencyRegForm';

const LinkWrapper = styled(PlxLinkWrapper)<IThemed>`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const ChangeTypeWrapper = styled.div`
  font-size: 12px;
  margin: 2rem 0 0;
`;

interface IProps {
  registerOtherPath: string;
  client?: boolean;
  agency?: boolean;
  loginPath: string;
}

export const Register: FC<IProps> = ({
  loginPath,
  registerOtherPath,
  client,
  agency,
}) => {
  const { t } = useTranslation();
  return (
    <AuthPageWrapper>
      {/* <StyledStepButton
        type={StepButtonType.BACK}
        linkTo={loginPath}
        text={t('auth.register.goBack')}
      /> */}
      <AuthHeader>
        {t('auth.register.singUpTitle')}
        <span> or </span>
        <LinkWrapper to={loginPath} data-test-id="back-to-login-button">
          <span>{t('auth.login.title')} </span>
        </LinkWrapper>
        {/* {registerOtherPath && ( */}
        <ChangeTypeWrapper>
          {client
            ? t('auth.register.agency') + ' '
            : t('auth.register.notAgency') + ' '}
          <LinkWrapper to={registerOtherPath} data-test-id="sign-up-button">
            <span>{t('auth.register.here')} </span>
          </LinkWrapper>
          {' ' + t('auth.register.toRegister')}
        </ChangeTypeWrapper>
        {/* )} */}
      </AuthHeader>
      <SubParagraph paragraph={t('auth.register.singUpSubtitle')} />
      {client && <ClientRegForm />}
      {agency && <AgencyRegForm />}
    </AuthPageWrapper>
  );
};
