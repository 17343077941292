import React, { FC } from 'react';
import styled from '@emotion/styled';
import congratsIcon1 from './congratsIcon1.png';
import congratsIcon2 from './congratsIcon2.svg';
import { useLocation } from 'react-router-dom';

const SuccessInfoBody = styled.div`
  display: flex;
  align-items: center;
  color: #00ac26;
  font-size: 2.3rem;
  font-weight: 500;
  flex-direction: column;
`;

const Text = styled.span`
  // margin-left: 1rem;
  color: #4f007d;
`;

const IconWrapper = styled.div`
  display: block;
  padding-bottom: 2.3rem;
  img {
    width: 5rem;
  }
`;
interface IProps {
  text: string;
}

export const SuccessInfo: FC<IProps> = ({ text }) => {
  const location = useLocation();

  return (
    <SuccessInfoBody>
      {/* <CheckCircle /> */}
      <IconWrapper>
        {location.pathname === '/auth/finfluencer/register-confirmed' ? (
          <img src={congratsIcon1} alt="congratsIcon1" />
        ) : (
          <img src={congratsIcon2} alt="congratsIcon2" />
        )}
      </IconWrapper>
      <Text>{text}</Text>
    </SuccessInfoBody>
  );
};
