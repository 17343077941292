import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { IThemed } from '_Styles/types';

export const SamplesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
`;

export const UrlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const AddSampleArea = styled.div`
  color: #3c6ef6;
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 3rem;
  width: 24rem;
  height: 17.5rem;
  border: 2px dashed #3c6ef6;
  border-radius: 5px;
  :hover {
    cursor: pointer;
    background: rgba(60, 110, 246, 0.05);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem;
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 24rem;
  margin-bottom: 2rem;
  height: 17.5rem;
  border: 1px solid rgba(213, 221, 227, 0.5);
  border-radius: 5px;
  :hover {
    cursor: pointer;
    filter: brightness(50%);
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export const StyledImage = styled.img`
  width: 24rem;
  height: 17.5rem;
  pointer-events: auto;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 50%;
  cursor: pointer;
`;

export const FormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;
export const Inputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InputTitle = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const InputWrapper = styled.div`
  margin: 3rem;
`;
export const Img = styled.img`
  padding: 0 4rem;
  max-width: 70%;
`;

export const WrapperFileInput = styled.div`
  display: flex;
  width: 100%;
`;

export const PlxFileInputBody = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  // padding: 5px 10px;
`;
export const StyledFileInput = styled.input`
  display: none;
`;

export const FileNameWrapper = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.1rem;
  margin: auto 2rem;
`;

export const FileButton = styled(Button)`
  && {
    border: 1px solid #d5dde3;
    height: 3.5rem;
    background-color: #fff;
    font-size: 10px;
    padding: 1.1rem 1rem;
  }
`;
