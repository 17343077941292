import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import {
  createAdminTableConfig,
  mapCompaniesToSelect,
} from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/utils';
import { ICompany } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/types';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { IAdminCampaignsQueryParams } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';
import { getCompanies } from '_State/User/Api/user.api';
import { getSpectrumCampaignsAdmin } from '_State/Spectrum/Api/spectrum-admin.api';
import { PAYMENT_STATUSES, STATUS_OPTIONS } from '_Types/adminTable';
import { Paper } from '@material-ui/core';
import { XemTable } from '_Components/XemTable/XemTable';
import {
  GridColumns,
  GridFilterModelParams,
  GridPageChangeParams,
} from '@material-ui/data-grid';

const INITIAL_PARAMS: IAdminCampaignsQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageNumber: 0,
  pageSize: 20,
};

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 15rem;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const AdminTableBody = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

const StyledInput = styled(PlxInput)`
  && {
    margin-left: auto;
    width: 31rem;
    margin-right: 3.5rem;
  }
`;

const sortingOptions = [
  {
    id: 'ASC',
    name: 'Oldest to newest',
  },
  {
    id: 'DESC',
    name: 'Newest to oldest',
  },
];

const paymentStatuses = PAYMENT_STATUSES;

const statusOptions = STATUS_OPTIONS;

export const AdminSpectrumTable: FC = () => {
  const { t } = useTranslation();
  const [config, setConfig] = useState<GridColumns>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [sorting, setSorting] = useState<string>('DESC');
  const [campaignId, setCampaignId] = useState<string>('');
  const [params, setParams] = useState<IAdminCampaignsQueryParams>(
    INITIAL_PARAMS
  );
  const [selectedCompany, setSelectedCompany] = useState<string[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [campaigns, setCampaigns] = useState<ISpectrumCampaign[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalElements, setTotalElements] = useState<number>(0);

  const onSortingColumn = (value: string, value2: string): void => {
    setParams({ ...params, sortByField: value, sortDirection: value2 });
  };

  useEffect(() => {
    setTableLoading(true);

    getSpectrumCampaignsAdmin(params).then(({ content, totalElements }) => {
      if (content) {
        getCompanies({ type: 'SPECTRUM' })
          .then((companiesResponse) => {
            setTotalElements(totalElements);
            setCompanies(mapCompaniesToSelect(companiesResponse));
            setCampaigns(content);
            setConfig(createAdminTableConfig(onSortingColumn, params));
          })
          .finally(() => {
            setTableLoading(false);
          });
      }
    });
    // eslint-disable-next-line
  }, [params]);

  const onSortingChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onCompanyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSelectedCompany(event.target.value as string[]);
    setParams({ ...params, companyNameList: event.target.value as string[] });
  };

  const onPaymentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSelectedPayment(event.target.value as string[]);
    setParams({ ...params, paymentStatusList: event.target.value as string[] });
  };

  const onStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSelectedStatus(event.target.value as string[]);
    setParams({ ...params, statusList: event.target.value as string[] });
  };

  const onCampaignIdChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value as string)) {
      setCampaignId(event.target.value as string);
      setParams({ ...params, campaignId: event.target.value as string });
    }
  };

  const handlePageChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageNumber: param.page }));
  };

  const handlePageSizeChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageSize: param.pageSize }));
  };

  const resetFilters = (columns: GridColumns): void => {
    const filters: Record<string, string> = {};

    columns
      .filter((column) => column.filterable)
      .forEach((column) => (filters[String(column.field)] = ''));

    setParams((prevParams) => ({
      ...prevParams,
      ...filters,
    }));
  };

  const handleFilterModelChange = useCallback(
    ({ filterModel, columns }: GridFilterModelParams) => {
      const [{ columnField, value }] = filterModel.items;

      if (!value) {
        return resetFilters(columns);
      }

      setParams({
        ...params,
        pageNumber: 0,
        [String(columnField)]: value || '',
      });
    },
    [params]
  );

  return (
    <AdminTableBody>
      <Paper>
        <PlxTableToolbar label={t('campaignsTable.allCampaigns')}>
          <StyledSelect
            options={sortingOptions}
            label={t('admin.dashboard.table.sort')}
            onChange={onSortingChange}
            value={sorting}
            testId="sorting-select"
          />
          <StyledMultipleSelect
            options={companies}
            label={t('admin.dashboard.table.company')}
            onChange={onCompanyChange}
            value={selectedCompany}
            testId="company-select"
          />
          <StyledMultipleSelect
            options={paymentStatuses}
            label={t('admin.dashboard.table.payment')}
            onChange={onPaymentChange}
            value={selectedPayment}
            testId="payment-select"
          />
          <StyledMultipleSelect
            options={statusOptions}
            label={t('admin.dashboard.table.status')}
            onChange={onStatusChange}
            value={selectedStatus}
            testId="status-select"
          />
          <StyledInput
            margin={'dense'}
            placeholder={t('admin.dashboard.table.search')}
            value={campaignId}
            onChange={onCampaignIdChange}
            fullWidth={false}
            testId="search-company-field"
          />
        </PlxTableToolbar>
        <XemTable
          header={config}
          data={campaigns}
          loading={tableLoading}
          pagination
          page={params.pageNumber}
          paginationMode="server"
          pageSize={params.pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowCount={totalElements}
          onPageChange={handlePageChange}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
        />
      </Paper>
    </AdminTableBody>
  );
};
