import { PlxLoader } from '_Components/PlxLoader';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AddClientForm, IUserDTO } from '_State/User/user.types';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { getClients } from '_State/User/Api/user.api';
import { createConfig } from './utils';
import {
  StyledTableButton,
  TableButtonsWrapper,
} from '_Routes/Auth/Agency/StylesAg';
import { NewUserForm } from './NewUserForm';
import { EditUserForm } from './EditUserForm';
import { getUserInfo } from '_Api/auth';

export const AgencyClientsTable: FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IUserDTO[]>([]);
  const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [editClientData, setEditClientData] = useState<AddClientForm>({
    id: undefined,
    firstName: ' ',
    lastName: ' ',
    email: ' ',
    companyName: ' ',
    country: ' ',
  });

  const fetchData = () => {
    return getClients()
      .then(({ content }) => {
        setData(content);
      })
      .catch((error) =>
        error.response.json().then(() => {
          setLoading(false);
        })
      );
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    userId === 0 &&
      getUserInfo().then((data) => {
        data.id && setUserId(data.id);
      });
  }, [userId]);

  const openNewDialog = () => {
    setNewDialogOpen(true);
  };
  const closeNewDialog = () => {
    setNewDialogOpen(false);
  };
  const openEditDialog = (data?: AddClientForm) => {
    setEditClientData({
      id: data?.id,
      firstName: data?.firstName || ' ',
      lastName: data?.lastName || ' ',
      email: data?.email || ' ',
      companyName: data?.companyName || ' ',
      country: data?.country || ' ',
    });
    setEditDialogOpen(true);
  };
  const closeEditDialog = () => {
    setEditClientData({
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      country: '',
    });
    setEditDialogOpen(false);
  };

  const config = useMemo(() => {
    return createConfig(openEditDialog) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <PlxCustomTable data={data} config={config} fullHeight key={'clients'}>
          <PlxTableToolbar label={'Clients'}>
            <TableButtonsWrapper>
              <StyledTableButton onClick={openNewDialog}>New</StyledTableButton>
            </TableButtonsWrapper>
          </PlxTableToolbar>
        </PlxCustomTable>
      )}
      <PlxDialog
        title={'Add client'}
        open={newDialogOpen}
        onClose={closeNewDialog}
      >
        <NewUserForm userId={userId} />
      </PlxDialog>
      <PlxDialog
        title={'Edit user'}
        open={editDialogOpen}
        onClose={closeEditDialog}
      >
        <EditUserForm userId={userId} editClientData={editClientData} />
      </PlxDialog>
    </>
  );
};
