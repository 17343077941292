import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { AuthPageWrapper } from '_Routes/Auth/_Components/AuthPageWrapper';
import { AuthHeader } from '_Routes/Auth/_Components/AuthHeader';
import { PlxLinkWrapper } from '_Components/Buttons/PlxLinkWrapper/PlxLinkWrapper';
import { IThemed } from '_Styles/types';
import { SubParagraph } from '_Routes/Auth/utils';
import { FinfluencerRegForm } from './FinfluencerRegForm';

const LinkWrapper = styled(PlxLinkWrapper)<IThemed>`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

interface IProps {
  loginPath: string;
}

export const DistributionPartnerRegister: FC<IProps> = ({ loginPath }) => {
  const { t } = useTranslation();
  return (
    <AuthPageWrapper>
      <AuthHeader>
        {t('auth.register.singUpTitle')}
        <span> or </span>
        <LinkWrapper to={loginPath} data-test-id="back-to-login-button">
          <span>{t('auth.login.title')} </span>
        </LinkWrapper>
      </AuthHeader>
      <SubParagraph paragraph={t('auth.register.singUpSubtitleDp')} />
      <FinfluencerRegForm />
    </AuthPageWrapper>
  );
};
