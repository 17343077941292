import React, { FC } from 'react';
import styled from '@emotion/styled';

import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';
import {
  PlxLinkWrapper,
  IProps as IPlxLinkWrapperProps,
} from '_Components/Buttons/PlxLinkWrapper/PlxLinkWrapper';
import { CallMadeOutlined } from '@material-ui/icons';

// TODO: remove important
const PlxStepButtonBody = styled(PlxLinkWrapper)<
  IPlxLinkWrapperProps & IThemed
>`
  display: inline-flex;
  color: ${({ theme }: IThemed) => theme.palette.primary.main} !important;
  background: #231f20;
  border-radius: 6px;
  padding: 11px 16px;
  margin-right: 4rem;
  &:hover {
    background: #808285;
  }
`;

const Text = styled.span`
  display: inline-flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
`;

interface IProps extends ICommonProps {
  linkTo: string;
  text?: string;
  testId?: string;
}

export const PlxLinkButton: FC<IProps> = ({
  linkTo,
  text,
  className,
  testId,
}) => {
  return (
    <PlxStepButtonBody to={linkTo} className={className} data-test-id={testId}>
      <Text>{text}</Text>
      <CallMadeOutlined
        style={{
          fill: '#fff',
          width: '15px',
          height: '15px',
          marginLeft: '1rem',
        }}
      />
    </PlxStepButtonBody>
  );
};
