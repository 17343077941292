import React from 'react';
import { DisplayModeEnum } from './AvgEngagementRateAndTrendGraph';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IHeadingTable {
  mode: DisplayModeEnum;
}

const useStyles = makeStyles({
  cell: { fontWeight: 600, padding: 12 },
  headRow: { backgroundColor: '#F6F6F6' },
});

export const TableHeadComponent = ({ mode }: IHeadingTable) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <>
      <TableHead>
        <TableRow className={classes.headRow}>
          <TableCell className={classes.cell}>
            {t('spectrum.overview.Channel type')}
          </TableCell>
          <TableCell className={classes.cell} align="right">
            {t('spectrum.overview.EngRate')}
          </TableCell>
          <TableCell className={classes.cell} align="left">
            <span style={{ marginRight: '2px' }}>
              {t('spectrum.overview.Trend')}
            </span>
          </TableCell>
          {mode >= DisplayModeEnum.dual && (
            <>
              <TableCell className={classes.cell} align="right">
                {t('spectrum.overview.EngRate')}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                <span style={{ marginRight: '2px' }}>
                  {t('spectrum.overview.Trend')}
                </span>
              </TableCell>
            </>
          )}
          {mode >= DisplayModeEnum.triple && (
            <>
              <TableCell className={classes.cell} align="right">
                {t('spectrum.overview.EngRate')}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                <span style={{ marginRight: '2px' }}>
                  {t('spectrum.overview.Trend')}
                </span>
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    </>
  );
};

export default TableHeadComponent;
