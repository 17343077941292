import React, { FC, useEffect } from 'react';
import XemotoLogoLarge from '_Styles/images/XemotoLogoLarge.svg';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { Trans } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import ky from 'ky';
import moment from 'moment';

const BodyWrap = styled.div`
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  background: #fff;
`;
const Paragraph = styled.p``;
const H5 = styled.h5`
  font-size: 1.25rem;
  text-align: center;
`;
const UL = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
const OL = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  margin: 2rem;
  justify-content: space-between;
`;

const XemotoLogoContainer = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 auto;
`;

const XemotoLogoImage = styled.img<IThemed>`
  height: 2.6rem;
`;

interface IProps {
  handleNotAccept?: () => void;
  formik?: any;
  forAdminView?: boolean;
}
export const ClientContract: FC<IProps> = ({
  handleNotAccept,
  formik,
  forAdminView,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: IState) => state.user);
  const calledOnce = React.useRef(false);

  useEffect(() => {
    if (!forAdminView) {
      if (calledOnce.current) return;
      ky('https://api.ipify.org?format=jsonp?callback=?')
        .then((res) => res.text())
        .then((ip) => {
          formik.setFieldValue('signerIp', ip);
        });
      if (!formik.values.contractViewed) {
        formik.setFieldValue(
          'contractViewed',
          moment().format('DD-MM-YYYYTHH:mm:ss')
        );
      }
      calledOnce.current = true;
    }
  }, [formik, forAdminView]);

  return (
    <div>
      <BodyWrap>
        <XemotoLogoContainer>
          <XemotoLogoImage src={XemotoLogoLarge} />
        </XemotoLogoContainer>
        <H5>
          <Trans i18nKey="payment.clientContract.agreementTitle" />
        </H5>
        <Paragraph>
          <Trans
            i18nKey="payment.clientContract.body1"
            values={{
              name:
                user?.firstName.toUpperCase() +
                ' ' +
                user?.lastName.toUpperCase(),
            }}
          />
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="payment.clientContract.body2" />
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="payment.clientContract.body3" />
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="payment.clientContract.body4" />
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="payment.clientContract.body5" />
        </Paragraph>
        <UL>
          <li>
            <Trans i18nKey="payment.clientContract.services" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.services1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services3" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services4" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services5" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services6" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services7" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.services8" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.clientData" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.clientData1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.clientData2" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.fees" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.fees1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.fees2" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.intellectualProperty" />
          </li>
          <li>
            <Trans i18nKey="payment.clientContract.confidentiality" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.confidentiality1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.confidentiality2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.confidentiality3" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.equitableRelief" />
          </li>
          <li>
            <Trans i18nKey="payment.clientContract.representationsWarranties" />
          </li>
          <li>
            <Trans i18nKey="payment.clientContract.indemnification" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.indemnification1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.indemnification2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.indemnification3" />
            </li>
            <OL>
              <li>
                <Trans i18nKey="payment.clientContract.indemnification31" />
              </li>
              <li>
                <Trans i18nKey="payment.clientContract.indemnification32" />
              </li>
            </OL>
            <li>
              <Trans i18nKey="payment.clientContract.indemnification4" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.Disclaimer" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.Disclaimer1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.Disclaimer2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.Disclaimer3" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.termTermination" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.termTermination1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.termTermination2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.termTermination3" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.termTermination4" />
            </li>
          </OL>
          <li>
            <Trans i18nKey="payment.clientContract.generalTerms" />
          </li>
          <OL>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms1" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms2" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms3" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms4" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms5" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms6" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms7" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms8" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms9" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms10" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms11" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms12" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms13" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms14" />
            </li>
            <li>
              <Trans i18nKey="payment.clientContract.generalTerms15" />
            </li>
          </OL>
        </UL>
        <Typography variant="body2">
          <Trans i18nKey="payment.clientContract.body6" />
        </Typography>
      </BodyWrap>

      <ButtonWrapper>
        <>
          {!forAdminView && (
            <PlxButtonX onClick={handleNotAccept}>
              {t('payment.iDontAccept')}
            </PlxButtonX>
          )}

          <a
            href="https://storage.googleapis.com/untappedlab-prod/contracts/FRClientServicesAgreement.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GetAppIcon
              style={{
                width: '35px',
                height: 'auto',
                border: '1px solid #A9B3BB',
                padding: '.5rem',
                borderRadius: '3px',
              }}
            />
          </a>
          {!forAdminView && (
            <PlxButtonX onClick={formik.handleSubmit}>
              {t('payment.iAccept')}
            </PlxButtonX>
          )}
        </>
      </ButtonWrapper>
    </div>
  );
};
