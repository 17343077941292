import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  IInfoPanelProps,
  PlxInfoPanel,
} from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { PlxMap } from '_Components/PlxMap/PlxMap';
import { IMapCountry } from '_Components/PlxMap/types';

const StyledPlxMap = styled(PlxMap)`
  flex-grow: 1;
`;

interface IProps extends IInfoPanelProps {
  countries: IMapCountry[];
}

export const MapChart: FC<IProps> = ({ className, countries, label }) => {
  return (
    <PlxInfoPanel label={label} className={className}>
      <StyledPlxMap countries={countries} />
    </PlxInfoPanel>
  );
};
