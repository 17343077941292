import { ISpectrumState } from '_State/Spectrum/spectrum.types';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IThriveState } from './thrive.types';

const initialState: IThriveState = {
  step: '0',
};

type ThriveReducer<PayloadType> = CaseReducer<
  IThriveState,
  PayloadAction<PayloadType>
>;

const changeStep: ThriveReducer<ISpectrumState> = (
  state,
  { payload: thrive }
) => {
  const { step } = thrive;
  state.step = step;
};

export const thriveSlice = createSlice({
  name: 'thrive',
  initialState,
  reducers: {
    changeStep,
  },
});

export const thriveActions = thriveSlice.actions;
export const thriveReducer = thriveSlice.reducer;
