import { IFileDto, IUploadFileDto } from '_State/Thrive/types';
import {
  ChannelStatus,
  ChannelType,
  EligibleChannelStatus,
  EligibleTransactionStatus,
  ReferralPaymentStatus,
  ReferralStatus,
} from '_State/DistributionPartner/distributionPartner.enums';
import { IStockCompany } from '_Types/campaigns';
import { IPostSample, IUrlSample } from '_State/Spectrum/types';
import facebookLogo from '_Styles/images/_Icons/ChannelTypes/f_logo_RGB-White_250.png';
import instagramLogo from '_Styles/images/_Icons/ChannelTypes/instagramLogo.png';
import twitter from '_Styles/images/_Icons/ChannelTypes/twitter.png';
import tiktok from '_Styles/images/_Icons/ChannelTypes/tiktok.png';
import Youtube from '_Styles/images/_Icons/ChannelTypes/Youtube.svg';
export interface IDistributionPartnerState {
  step: string;
  numberOfNewOpportunities?: number;
}

export const DISTRIBUTION_PARTNER_NEW_CHANNEL_PROGRESS_STRATEGY = {
  profile: 0,
  addchannel: 1,
  summary: 2,
  agreements: 3,
};

export interface IDistributionPartnerCompleteProfileForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  businessName: string;
  mobileVerified?: boolean;
  contractAccepted?: boolean;
}
export interface IFinfluencerSubscriptionForm {
  campaignNotifications: boolean;
  opportunityInvitation: boolean;
  // payoutNotifications: boolean;
  // productUpdates: boolean;
}
export interface IDistributionPartnerCompleteProfileDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  businessName: string;
}

export interface IDistributionPartnerChannel {
  channelType?: string;
  industry?: string[];
  geolocations?: string[];
  channelList?: string[];
  summary?: string;
  channelName?: string;
  channelUrl?: string;
  channelVisibility?: string;
  channelCreationDate?: string;
  categoriesOfInterest?: string[];
  numberOfFollowers?: number;
  reactions?: number;
  shares?: number;
  comments?: number;
  postSize?: number;
  recentPostCount?: number;
  numberOfPostPerWeek?: number;
  groupDescription?: string;
  growthRateDateFrom?: string;
  growthRateDateTo?: string;
  engagementRateDateFrom?: string;
  engagementRateDateTo?: string;
  dontWantOffer?: boolean;
}

export interface IDistributionPartnerPostsUrls {
  id: number;
  campaignId: number;
  postUrl: string;
}

export interface IDistributionPartnerEligibleChannel {
  id: number;
  channelName: string;
  channelType: string;
  channelId: number;
  channelUrl: string;
  numberOfFollowers: number;
  clicks?: number;
  numberOfPostPerWeek: number;
  fullName: string;
  geolocations: string[];
  offer: number;
  shortenUrl: string;
  status: string;
  reactions: number;
  shares: number;
  comments: number;
  impressions: number;
  videoSaves: number;
  datePosted: string;
  postSample: IPostSample;
  reason?: string;
  defaultWalletEmail?: string;
  wallet?: string;
  transactionStatus?: string;
  transactionSuccess?: string;
  connected?: boolean;
  hourlyDataCollectionIsActiveAndEndsAt?: string;
  recentEngagementRate?: number;
}

export interface IDistributionPartnerOfferForm
  extends IDistributionPartnerChannel {
  offer?: number;
  oldOffer?: number;
  offeringSummary?: string;
  campaignData?: IDistributionPartnerOpportunityDTO[];
  termsAndConditions?: IUploadFileDto;
}
export interface IDistributionPartnerAddChannelForm
  extends IDistributionPartnerChannel {
  growthRateFile?: IUploadFileDto;
  engagementRateFile?: IUploadFileDto;
  uploadedFile?: IFileDto;
}
export interface IDistributionPartnerOfferDTO
  extends IDistributionPartnerChannel {
  offer: number;
}

export interface IDistributionPartnerChannelDTO
  extends IDistributionPartnerChannel {
  distributionPartner: DistributionPartnerDTO;
  earnings: number;
  offer: number;
  growthRateFile?: IFileDto;
  engagementRateFile?: IFileDto;
  rejectionReason?: string;
  status: string;
  id: number;
  offeringSummary: string;
  termsAndConditions?: IUploadFileDto;
  isDeletable: boolean;
  channelAccessGranted: boolean;
  socialMediaNodeId: string;
  dontWantOffer: boolean;
}

export interface IDistributionPartnerReferralsDTO {
  fullName: string;
  wallet: string;
  referrerName: string;
  earned: number;
  status: string;
  transactionStatus: string;
  completedDate: string;
}
export interface IAllNotifications {
  notification?: string;
  type?: string;
}
export interface IUrlForm {
  id?: number;
  postUrl?: string;
  note?: string;
  offer?: number;
  status?: string;
  campaignId?: number;
  seen?: boolean;
  analyticsDue?: string;
  postDue?: string;
  reactions?: number;
  shares?: number;
  comments?: number;
  numberOfFollowers?: number;
  impressions?: number;
  videoSaves?: number;
  channelName?: string;
  channelType?: string;
}
export interface IUrlDTO {
  id?: number;
  status?: string;
  channelName?: string;
  channelType?: string;
  offer?: number;
  campaignId?: number;
  seen?: boolean;
  analyticsDue?: string;
  postDue?: string;
  reactions?: number;
  shares?: number;
  comments?: number;
  numberOfFollowers?: number;
  impressions?: number;
  videoSaves?: number;
  url?: IUrlDTO;
  postUrl?: string;
  postSample?: IUrlSample;
}

export interface IAnalyticsForm {
  reactions?: number;
  shares?: number;
  comments?: number;
  numberOfFollowers?: number;
  datePosted?: string;
  postUrl?: string;
  impressions?: number;
  videoSaves?: number | string;
  postSample?: IUploadFileDto[] | null;
}

export interface IAnalyticsDTO {
  reactions?: number;
  shares?: number;
  comments?: number;
  numberOfFollowers?: number;
  datePosted?: string;
  postSample: IPostSample;
  note?: string;
  impressions?: number;
  postUrl?: string;
  videoSaves?: number;
  channelType?: string;
  connected?: boolean;
  analyticsDate?: string;
}
export interface IUpdateableAnalyticsDTO {
  reactions?: number;
  shares?: number;
  comments?: number;
  numberOfFollowers?: number;
  impressions?: number;
  videoSaves?: number;
}

export interface IDistributionPartnerChannelSelectableRow
  extends IDistributionPartnerEligibleChannel {
  selected?: boolean;
  postUrl?: string;
}

export interface IAdminChannelQueryParams {
  sortDirection?: 'ASC' | 'DESC' | string;
  sortByField?: string;
  channelTypeList?: string[];
  channelStatusList?: string[];
  channelId?: string;
  channelName?: string;
  pageSize?: number;
  pageNumber?: number;
}
export interface IAdminReferralQueryParams {
  sortDirection?: 'ASC' | 'DESC' | string;
  sortByField?: string;
  referralStatusList?: string[];
  referralId?: string;
  fullName?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface IApproveChannelForm {
  offer?: number;
  oldOffer?: number;
  offeringSummary: string;
  termsAndConditions?: IUploadFileDto;
}

export interface IDistributionPartnerOfferPlacedDTO
  extends IDistributionPartnerChannelDTO {
  offeringSummary: string;
  termsAndConditions: IFileDto;
}

export interface DistributionPartnerDTO
  extends IDistributionPartnerCompleteProfileDTO {
  id: number;
}

export interface IDistributionPartnerOpportunityDTO {
  id: number;
  industry: string;
  geolocations: string[];
  channelName: string;
  channelType: string;
  offer: number;
  status: string;
  campaignId: number;
  seen: boolean;
  campaignUrl: string;
  analyticsDue: string;
  postDue: string;
  reactions: number;
  shares: number;
  comments: number;
  recentPostCount: number;
  datePosted: string;
  postSample: IPostSample;
  needsWorkNote?: string;
  stockTickerList: IStockCompany[];
  onClick?: () => void;
  campaignStatus: string;
  companyName: string;
  numberOfFollowers: number;
  impressions: number;
  videoSaves: number;
  postUrl?: string;
  url: IUrlDTO;
  connected?: boolean;
}

export interface ICampaignDetailsDTO {
  campaignId: number;
  offer: number;
  companyName: string;
  furtherInstructions: string;
  industry: string[];
  hashtags: string[];
  scheduledDate: string;
  geolocations: string[];
  stockTickerList: IStockCompany[];
  contentType: string;
  url: string;
  contentCategories: string[];
  analyticsDue: string;
}

export interface IDistributionPartnerChannelStatistics {
  totalCampaigns: number;
  totalComments: number;
  totalEarned: number;
  totalReactions: number;
  totalShares: number;
  totalReferralEarnings: number;
}

export const CHANNEL_STATUS_MAP_STRATEGY = {
  [ChannelStatus.PENDING]: {
    color: '#000',
    label: 'channelStatus.new',
  },
  [ChannelStatus.ACTIVE]: {
    color: '#00A524',
    label: 'channelStatus.active',
  },
  [ChannelStatus.REJECTED]: {
    color: '#FF0000',
    label: 'channelStatus.rejected',
  },
  [ChannelStatus.OFFER_PLACED]: {
    color: '#E76F00',
    label: 'channelStatus.offerSubmitted',
  },
  [ChannelStatus.CONNECTED]: {
    color: '#258004',
    label: 'channelStatus.connected',
  },
  [ChannelStatus.DISABLED]: {
    color: '#231F20',
    label: 'channelStatus.disabled',
  },
  [ChannelStatus.EXPIRED]: {
    color: '#231F20',
    label: 'channelStatus.expired',
  },
};

export const REFERRALS_STATUS_MAP_STRATEGY = {
  [ReferralStatus.APPROVED]: {
    color: '#000000',
    label: 'referralStatus.approved',
  },
  [ReferralStatus.IN_PROGRESS]: {
    color: '#000000',
    label: 'referralStatus.inProgress',
  },
  [ReferralStatus.COMPLETED]: {
    color: '#008000',
    label: 'referralStatus.completed',
  },
  [ReferralStatus.VERIFIED]: {
    color: '#000000',
    label: 'referralStatus.verified',
  },
  [ReferralStatus.CREATED_PAYOUT]: {
    color: '#800080',
    label: 'referralStatus.createdPayout',
  },
  [ReferralStatus.COMPLETED_MANUL_PAYOUT]: {
    color: '#008000',
    label: 'referralStatus.manualPayoutCompleted',
  },
};

export const REFERRALS_TRANSACTION_STATUS_MAP_STRATEGY = {
  [ReferralPaymentStatus.CREATED]: {
    color: '#800080',
    label: 'referralStatus.created',
  },
  [ReferralPaymentStatus.COMPLETED]: {
    color: '#008000',
    label: 'referralStatus.completed',
  },
  [ReferralPaymentStatus.FAILED]: {
    color: '#FF0000',
    label: 'referralStatus.failed',
  },
};

export const ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY = {
  [EligibleChannelStatus.ELIGIBLE]: {
    color: '#00A3FF',
    label: 'channelStatus.eligible',
  },
  [EligibleChannelStatus.INVITATION_SENT]: {
    color: '#008000',
    label: 'channelStatus.invitationSent',
  },
  [EligibleChannelStatus.REJECTED]: {
    color: '#FF0000',
    label: 'channelStatus.rejected',
  },
  [EligibleChannelStatus.ACTIVE]: {
    color: '#E76F00',
    label: 'channelStatus.active',
  },
  [EligibleChannelStatus.ANALYTICS_NEEDED]: {
    color: '#FF0000',
    label: 'channelStatus.analyticsNeeded',
  },
  [EligibleChannelStatus.REVIEW_ANALYTICS]: {
    color: '#4F007D',
    label: 'channelStatus.reviewAnalytics',
  },
  [EligibleChannelStatus.NEEDS_WORK]: {
    color: '#FF0000',
    label: 'channelStatus.needsWork',
  },
  [EligibleChannelStatus.COMPLETED]: {
    color: '#9D05B0',
    label: 'channelStatus.completed',
  },
  [EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT]: {
    color: '#00A524',
    label: 'channelStatus.completedPayout',
  },
};

export const ELIGIBLE_TRANSACTION_STATUS_MAP_STRATEGY = {
  [EligibleTransactionStatus.SUCCESS]: {
    color: '#00A524',
    label: 'transactionStatus.success',
  },
  [EligibleTransactionStatus.FAILED]: {
    color: '#FF0000',
    label: 'transactionStatus.failed',
  },
  [EligibleTransactionStatus.PENDING]: {
    color: '#F7CB73',
    label: 'transactionStatus.pending',
  },
  [EligibleTransactionStatus.UNCLAIMED]: {
    color: '#D9512C',
    label: 'transactionStatus.unclaimed',
  },
  [EligibleTransactionStatus.RETURNED]: {
    color: '#077E8C',
    label: 'transactionStatus.returned',
  },
  [EligibleTransactionStatus.ONHOLD]: {
    color: '#F29339',
    label: 'transactionStatus.onhold',
  },
  [EligibleTransactionStatus.BLOCKED]: {
    color: '#D9512C',
    label: 'transactionStatus.blocked',
  },
  [EligibleTransactionStatus.REFUNDED]: {
    color: '#00A524',
    label: 'transactionStatus.refunded',
  },
  [EligibleTransactionStatus.REVERSED]: {
    color: '#00A524',
    label: 'transactionStatus.reversed',
  },
  [EligibleTransactionStatus.COMPLETED]: {
    color: '#00A524',
    label: 'transactionStatus.completed',
  },
  [EligibleTransactionStatus.CREATED]: {
    color: 'purple',
    label: 'transactionStatus.created',
  },
};

const subdomain = window.location.hostname.split('.')[0];

export const SOCIAL_MEDIA_BY_COLOR_MAP = {
  [ChannelType.FACEBOOK]: {
    label: 'Connect to Facebook',
    logo: facebookLogo,
    bgcolor: '#3b5998',
    color: '#fff',
    url: `https://www.facebook.com/v13.0/dialog/oauth?response_type=code&display=popup&client_id=7001491716531415&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/facebook/&scope=user_posts%2&email%2user_friends%2public_profile&state=fb`,
  },
  [ChannelType.FACEBOOK_USER]: {
    label: 'Connect to Facebook',
    logo: facebookLogo,
    bgcolor: '#3b5998',
    color: '#fff',
    url: `https://www.facebook.com/v13.0/dialog/oauth?response_type=code&display=popup&client_id=7001491716531415&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/facebook/&scope=user_posts%2&email%2user_friends%2public_profile&state=fb`,
  },
  [ChannelType.FACEBOOK_GROUP]: {
    label: 'Connect to Facebook',
    logo: facebookLogo,
    bgcolor: '#3b5998',
    color: '#fff',
    url: `https://www.facebook.com/v13.0/dialog/oauth?response_type=code&display=popup&client_id=7001491716531415&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/facebook/&scope=user_posts%2&email%2user_friends%2public_profile&state=fb`,
  },
  [ChannelType.FACEBOOK_PAGE]: {
    label: 'Connect to Facebook',
    logo: facebookLogo,
    bgcolor: '#3b5998',
    color: '#fff',
    url: `https://www.facebook.com/v13.0/dialog/oauth?response_type=code&display=popup&client_id=7001491716531415&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/facebook/&scope=user_posts%2&email%2user_friends%2public_profile&state=fb`,
  },
  [ChannelType.INSTAGRAM]: {
    label: 'Connect to Instagram',
    logo: instagramLogo,
    bgcolor: '#E1306C',
    color: '#fff',
    url: `https://www.facebook.com/v12.0/dialog/oauth?response_type=code&display=popup&client_id=7001491716531415&redirect_uri=https%3A%2F%2F${subdomain}.xemotomedia.com%2Fapi%2Fexternals%2Fauthpoint%2F&method%3DGET%26path%3Dme%253Fversion%3Dv12.0&auth_type=rerequest&scope=instagram_basic%2Cinstagram_manage_insights%2Cpages_read_engagement%2Cpages_show_list%2Cpublic_profile%2Cemail&state=ig'`,
  },
  [ChannelType.X]: {
    label: 'Connect to X',
    logo: twitter,
    bgcolor: '#1DA1F2',
    color: '#fff',
    url: `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=bUU2TUdwREg1bzhyX1FteDcxOGI6MTpjaQ&state=state&scope=tweet.read%20users.read%20offline.access&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/twitter&code_challenge=laZVD4Rd4bl5UBdFi_va_dZHBGJq2FMejQI60cqoAtY&code_challenge_method=plain`,
  },
  [ChannelType.TIKTOK]: {
    label: 'Connect to TikTok',
    logo: tiktok,
    bgcolor: '#ff0050',
    color: '#fff',
    url: `https://www.tiktok.com/v2/auth/authorize?client_key=awm18f90a4y8puzp&response_type=code&scope=user.info.basic%2Cvideo.list&redirect_uri=https%3A%2F%2F${subdomain}.xemotomedia.com/home/finfluencer/profile/tiktok`,
  },
  [ChannelType.YOUTUBE]: {
    label: 'Connect to Youtube',
    logo: Youtube,
    bgcolor: '#FF0000',
    color: '#fff',
    url: `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https://${subdomain}.xemotomedia.com/home/finfluencer/profile/youtube&client_id=215065364879-jdu8g2go1v2fqaerp8h64g66lrrkk1la.apps.googleusercontent.com`,
  },
  // [ChannelType.DEFAULT]: {
  //   label: 'Connect to TikTok',
  //   logo: '',
  //   bgcolor: '#000',
  //   color: '#fff',
  //   url:
  //     'https://www.tiktok.com/auth/authorize?client_key=awm18f90a4y8puzp&response_type=code&scope=user.info.basic,video.list&redirect_uri=https%3A%2F%2Fwww.xemotomedia.com&state=5607479033739012',
  // },
};

export interface IDistributionPartnerCampaignsQueryParams {
  sortDirection?: string;
  statusList: string[];
}

export const statusesSelectConfig = [
  {
    id: EligibleChannelStatus[EligibleChannelStatus.ACTIVE],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.ACTIVE]
    }`,
  },
  {
    id: EligibleChannelStatus[EligibleChannelStatus.ANALYTICS_NEEDED],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.ANALYTICS_NEEDED]
    }`,
  },
  {
    id: EligibleChannelStatus[EligibleChannelStatus.REVIEW_ANALYTICS],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.REVIEW_ANALYTICS]
    }`,
  },
  {
    id: EligibleChannelStatus[EligibleChannelStatus.COMPLETED],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.COMPLETED]
    }`,
  },
  {
    id: EligibleChannelStatus[EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT]
    }`,
  },
  {
    id: EligibleChannelStatus[EligibleChannelStatus.NEEDS_WORK],
    name: `campaignStatus.${
      EligibleChannelStatus[EligibleChannelStatus.NEEDS_WORK]
    }`,
  },
];
