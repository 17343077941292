import {
  arrayToSplittedString,
  stringArrayToEnum,
  capitalize,
} from '_App/utils';
import React from 'react';
import { AgeGroup, IThriveCampaign } from '_State/Thrive/types';
import styled from '@emotion/styled';

const StyledHref = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const configureThriveCampaignAssetsInfo = (
  t: any,
  campaign?: IThriveCampaign
) => [
  {
    label: t('campaignInformation.industry'),
    renderContent: () => <div>{campaign?.industry && campaign?.industry}</div>,
  },
  {
    label: t('campaignInformation.targetGeoLocation'),
    renderContent: () => (
      <div>
        {campaign?.geolocation.country &&
          capitalize(campaign?.geolocation.country)}
      </div>
    ),
  },
  {
    label: t('thrive.dashboard.ageGroup'),
    renderContent: () => (
      <div>
        {campaign?.ageGroups &&
          arrayToSplittedString(
            stringArrayToEnum(AgeGroup, campaign.ageGroups)
          )}
      </div>
    ),
  },
  {
    label: t('campaignInformation.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
  {
    label: t('thrive.dashboard.investorPresentation'),
    renderContent: () => (
      <>
        {campaign?.thrivePresentation.screenshotUrl && (
          <a
            href={campaign?.thrivePresentation?.screenshotUrl}
            target={'_blank'}
            rel="noopener noreferrer"
            download
          >
            {campaign?.thrivePresentation.fileName}
          </a>
        )}
      </>
    ),
  },
  {
    label: t('campaignInformation.companyWebsite'),
    renderContent: () => (
      <>
        {campaign?.companyWebsite && (
          <StyledHref target={'_blank'} href={campaign?.companyWebsite}>
            {campaign?.companyWebsite}
          </StyledHref>
        )}
      </>
    ),
  },
];
