export enum ChannelType {
  DISCORD = 'DISCORD',
  SNAPCHAT = 'SNAPCHAT',
  TWITCH = 'TWITCH',
  PINTEREST = 'PINTEREST',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_USER = 'FACEBOOK_USER',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  FACEBOOK_GROUP = 'FACEBOOK_GROUP',
  LINKEDIN = 'LINKEDIN',
  X = 'X',
  FORUM = 'FORUM',
  REDDIT = 'REDDIT',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  PODCAST = 'PODCAST',
  SLACK = 'SLACK',
  INSTAGRAM = 'INSTAGRAM',
  WHATSAPP = 'WHATSAPP',
  CLUBHOUSE = 'CLUBHOUSE',
  TELEGRAM = 'TELEGRAM',
  DEFAULT = 'DEFAULT',
}

export enum ChannelVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum CategoryOfInterest {
  INVESTMENT = 'INVESTMENT',
  DAY_TRADING = 'DAY_TRADING',
  FINANCIAL = 'FINANCIAL',
  RETIREMENT = 'RETIREMENT',
  REAL_ESTATE = 'REAL_ESTATE',
}

export enum ChannelStatus {
  PENDING = 'PENDING',
  OFFER_PLACED = 'OFFER_PLACED',
  ACTIVE = 'ACTIVE',
  CONNECTED = 'CONNECTED',
  REJECTED = 'REJECTED',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

export enum FacebookChannelType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_USER = 'FACEBOOK_USER',
  FACEBOOK_GROUP = 'FACEBOOK_GROUP',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
}

export enum EligibleChannelType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_USER = 'FACEBOOK_USER',
  FACEBOOK_GROUP = 'FACEBOOK_GROUP',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  X = 'X',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export enum EligibleChannelStatus {
  ELIGIBLE = 'ELIGIBLE',
  INVITATION_SENT = 'INVITATION_SENT',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  ANALYTICS_NEEDED = 'ANALYTICS_NEEDED',
  REVIEW_ANALYTICS = 'REVIEW_ANALYTICS',
  COMPLETED = 'COMPLETED',
  COMPLETED_MANUAL_PAYOUT = 'COMPLETED_MANUAL_PAYOUT',
  NEEDS_WORK = 'NEEDS_WORK',
}
export enum ReferralStatus {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  CREATED_PAYOUT = 'CREATED_PAYOUT',
  COMPLETED = 'COMPLETED',
  COMPLETED_MANUL_PAYOUT = 'COMPLETED_MANUL_PAYOUT',
}
export enum ReferralPaymentStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
export enum EligibleTransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  UNCLAIMED = 'UNCLAIMED',
  RETURNED = 'RETURNED',
  ONHOLD = 'ONHOLD',
  BLOCKED = 'BLOCKED',
  REFUNDED = 'REFUNDED',
  REVERSED = 'REVERSED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
}
export enum Province {
  ALBERTA = 'ALBERTA',
  NORTHWEST_TERRITORIES = 'NORTHWEST_TERRITORIES',
  NUNAVUT = 'NUNAVUT',
  YUKON_TERRITORY = 'YUKON_TERRITORY',
  BRITISH_COLUMBIA = 'BRITISH_COLUMBIA',
  MANITOBA = 'MANITOBA',
  NEW_BRUNSWICK = 'NEW_BRUNSWICK',
  NEWFOUNDLAND_AND_LABRADOR = 'NEWFOUNDLAND_AND_LABRADOR',
  NOVA_SCOTIA = 'NOVA_SCOTIA',
  PRINCE_EDWARD_ISLAND = 'PRINCE_EDWARD_ISLAND',
  ONTARIO = 'ONTARIO',
  QUEBEC = 'QUEBEC',
  SASKATCHEWAN = 'SASKATCHEWAN',
}
