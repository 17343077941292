import { IChannelConfig } from '_Types/IIconButtonConfig';

export const channelStatus: IChannelConfig[] = [
  {
    id: 'channelName',
    label: 'Channel Name',
    value: 'Name',
  },
  {
    id: 'channelType',
    label: 'Channel Type',
    value: 'YouTube',
  },
  {
    id: 'earningOpportunity',
    label: 'Earning Opportunity',
    value: 'Waiting for an offer',
  },
  {
    id: 'status',
    label: 'Status',
    value: 'Reviewing',
  },
];
