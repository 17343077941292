import { createContext } from 'react';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { ITag } from '_Types/campaigns';
import { ITableContext } from '_Types/tableContext';

const emptyTag: ITag = {
  name: '',
  id: 0,
  campaigns: [],
};

const emptyCallback = <T>(result: T) => () => Promise.resolve(result);

export const defaultContext = {
  tableConfig: [],
  tags: [],
  campaigns: [],
  updateTag: emptyCallback(undefined),
  addTag: emptyCallback(emptyTag),
  removeTag: emptyCallback(undefined),
  updateCampaignTag: emptyCallback(undefined),
};

export const SpectrumTableContext = createContext<
  ITableContext<ISpectrumCampaign>
>(defaultContext);
