import React, { FC, useState } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { EligibleChannelStatus } from '_State/DistributionPartner/distributionPartner.enums';
import { useTranslation } from 'react-i18next';
import { IAnalyticsDTO } from '_State/DistributionPartner/distributionPartner.types';
// import { approveSingleTransaction } from '_State/User/Api/admin-user.api';

interface IProps {
  status: string;
  opportunityId?: string;
  channelId?: string;
  onInvite: (id?: string) => void;
  onReview: (id?: string, dialogData?: IAnalyticsDTO) => void;
  onManualPay: (id?: string) => void;
  onCancel: (id?: string) => void;
  maxSpend: number | undefined;
  dialogData?: IAnalyticsDTO;
  campaignStatus: string;
  transactionStatus?: string;
  defaultWallet?: string;
}

export const EligibleChannelsActions: FC<IProps> = ({
  status,
  opportunityId,
  channelId,
  onInvite,
  onReview,
  onManualPay,
  onCancel,
  maxSpend,
  dialogData,
  campaignStatus,
  transactionStatus,
  defaultWallet,
}) => {
  const { t } = useTranslation();
  const [disabledChannelId, setDisabledChannelId] = useState<
    string | undefined
  >();

  const handleInvite = (id?: string) => {
    setDisabledChannelId(id);
    onInvite(id);
  };

  const handleCancel = (id?: string) => {
    setDisabledChannelId(id);
    onCancel(id);
  };

  return (
    <>
      {status === EligibleChannelStatus[EligibleChannelStatus.ELIGIBLE] ||
      status ===
        EligibleChannelStatus[EligibleChannelStatus.INVITATION_SENT] ? (
        <>
          <PlxButton
            size={'small'}
            isDisabled={
              disabledChannelId === channelId ||
              (maxSpend && maxSpend < 1) ||
              !maxSpend ||
              status ===
                EligibleChannelStatus[EligibleChannelStatus.INVITATION_SENT] ||
              campaignStatus === 'LAUNCHED' ||
              campaignStatus === 'REVIEW_NEEDED' ||
              campaignStatus === 'DRAFT'
            }
            label={t('eligibleChannelAction.invite')}
            variant={ButtonVariant.Outlined}
            onClick={() => handleInvite(channelId)}
          />
          <PlxButton
            size={'small'}
            isDisabled={
              status !==
              EligibleChannelStatus[EligibleChannelStatus.INVITATION_SENT]
            }
            label={t('eligibleChannelAction.cancel')}
            variant={ButtonVariant.Outlined}
            onClick={() => handleCancel(channelId)}
          />
        </>
      ) : (
        <>
          <PlxButton
            size={'small'}
            isDisabled={
              status === EligibleChannelStatus[EligibleChannelStatus.ACTIVE] ||
              status ===
                EligibleChannelStatus[EligibleChannelStatus.NEEDS_WORK] ||
              status ===
                EligibleChannelStatus[EligibleChannelStatus.ANALYTICS_NEEDED] ||
              status ===
                EligibleChannelStatus[EligibleChannelStatus.REJECTED] ||
              status ===
                EligibleChannelStatus[
                  EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT
                ] ||
              (status ===
                EligibleChannelStatus[EligibleChannelStatus.COMPLETED] &&
                transactionStatus !== 'FAILED' &&
                !!defaultWallet)
            }
            label={
              status === EligibleChannelStatus[EligibleChannelStatus.REJECTED]
                ? t('eligibleChannelAction.invite')
                : (status === 'COMPLETED' &&
                    !defaultWallet &&
                    !transactionStatus) ||
                  transactionStatus === 'FAILED'
                ? t('eligibleChannelAction.paidManually')
                : t('eligibleChannelAction.review')
            }
            variant={ButtonVariant.Outlined}
            onClick={() =>
              (status === 'COMPLETED' &&
                !defaultWallet &&
                !transactionStatus) ||
              transactionStatus === 'FAILED'
                ? onManualPay(opportunityId)
                : dialogData?.postSample !== undefined
                ? onReview(opportunityId, dialogData)
                : console.log('')
            }
          />
          <PlxButton
            size={'small'}
            isDisabled={
              status !== EligibleChannelStatus[EligibleChannelStatus.ACTIVE]
            }
            label={t('eligibleChannelAction.remove')}
            variant={ButtonVariant.Outlined}
            onClick={() => handleCancel(channelId)}
          />
        </>
      )}
    </>
  );
};
