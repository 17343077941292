import React, { FC, useEffect, useState } from 'react';
import { IFilterItem } from './utils';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { StyledItemList, StyledItem } from './styles/FiltersItem.styles';

interface IProps {
  filterItems: IFilterItem[];
  setFilterItems: (arg: any) => void;
}

export const FiltersItems: FC<IProps> = ({ filterItems, setFilterItems }) => {
  const [, setShowAll] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const allSelected = filterItems.every((obj) => obj.isChecked);
    if (allSelected && filterItems.length) setShowAll(true);
    else setShowAll(false);
  }, [filterItems]);

  const onIntervalClick = (id: string, subtitle: string): void => {
    setFilterItems((obj: IFilterItem[]): IFilterItem[] => {
      return obj.map((it: IFilterItem) =>
        it.id === id
          ? { ...it, isChecked: !it.isChecked }
          : { ...it, isChecked: false }
      );
    });
  };
  return (
    <div>
      <StyledItemList>
        {filterItems.map(({ isChecked, id, subtitle }: IFilterItem) => {
          return (
            <StyledItem
              variant="outlined"
              isChecked={isChecked}
              key={id}
              label={t(`spectrum.filterLabels.${subtitle}`)}
              onClick={(): void => onIntervalClick(id, subtitle)}
              onDelete={(): void => onIntervalClick(id, subtitle)}
              deleteIcon={
                isChecked ? <CloseIcon style={{ color: '#008B9C' }} /> : <></>
              }
            />
          );
        })}
      </StyledItemList>
    </div>
  );
};
