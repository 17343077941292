import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TypographySection } from './StylePayment';

export const PackageInfo: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <TypographySection>
        <Typography variant="h5" gutterBottom>
          {t('spectrum.payment.package')}
        </Typography>
        <div>{t('spectrum.payment.subtitle1')}</div>
        <div>
          {t('spectrum.payment.subtitle2')}
          <Link to={'../contact'}>{t('spectrum.payment.subtitle3')}</Link>
        </div>
      </TypographySection>
    </>
  );
};
