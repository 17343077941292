import { CampaignStatus, PaymentStatus } from '_Types/campaigns';
import { PaymentMethod } from '../../../../_Types/paymentMethod';

export const CAMPAIGN_STATUS_MAP_STRATEGY = {
  [CampaignStatus.DRAFT]: {
    color: '#b08b09',
    label: 'admin.draft',
  },
  [CampaignStatus.PAID]: {
    color: '#0f348f',
    label: 'admin.paid',
  },
  [CampaignStatus.NEW]: {
    color: '#FF0000',
    label: 'admin.newCampaign',
  },
  [CampaignStatus.LAUNCHED]: {
    color: '#00A3FF',
    label: 'admin.campaignLaunched',
  },
  [CampaignStatus.REVIEW_NEEDED]: {
    color: '#E76F00',
    label: 'admin.reviewNeeded',
  },
  [CampaignStatus.COMPLETED]: {
    color: '#00A524',
    label: 'admin.completed',
  },
  [CampaignStatus.PAUSED]: {
    color: '#ADADAD',
    label: 'admin.pauseProgram',
  },
};

export const PAYMENT_METHOD_MAP_STRATEGY = {
  [PaymentMethod.CREDIT_CARD]: {
    label: 'admin.payment.creditCard',
  },
  [PaymentMethod.WIRE_TRANSFER]: {
    label: 'admin.payment.wireTransfer',
  },
  [PaymentMethod.CHECK]: {
    label: 'admin.payment.check',
  },
  [PaymentMethod.OTHER]: {
    label: 'admin.payment.other',
  },
};

export const PAYMENT_STATUS_MAP_STRATEGY = {
  [PaymentStatus.FAILED]: {
    color: '#bc0000',
    label: 'admin.failed',
  },
  [PaymentStatus.IN_PROGRESS]: {
    color: '#ffb230',
    label: 'admin.inProgress',
  },
  [PaymentStatus.SUCCESS]: {
    color: '#00A524',
    label: 'admin.success',
  },
};
