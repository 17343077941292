export interface ISpectrumState {
  step: string;
}

export const SPECTRUM_PROGRESS_STRATEGY = {
  payment: 0,
  program: 1,
  campaign: 2,
  scheduling: 3,
};
