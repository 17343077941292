import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { CampaignStatus } from '_Types/campaigns';
import { PlxSelect } from '_Components/PlxSelect/PlxSelect';
import { useTranslation } from 'react-i18next';
import {
  configureEditorThriveCampaignInfo,
  configureMonthData,
  configureStatusSelect,
} from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaign/config';
import { MapChart } from '_Routes/Home/_Components/MapChart';
import {
  ICampaignInfoItem,
  PlxCampaignInformation,
} from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { LeadsInfo } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Components/ThriveLeadsAmount';
import { GenderChart } from '_Routes/Home/_Components/ThriveGenderChart';
import { ThriveAgeCharts } from '_Routes/Home/_Components/ThriveAgeCharts';
import { ThriveLeadsTimeChart } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaign/ThriveLeadsTimeChart';
import { GenderUpdateDialog } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaign/GenderUpdateDialog';
import { AgeRangeUpdateDialog } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaign/AgeRangeUpdateDialog';
import {
  IThriveCampaign,
  IThriveLead,
  IThriveLeadForm,
  IThriveLeadsQueryParams,
} from '_State/Thrive/types';
import { useHistory, useParams } from 'react-router-dom';
import { configureAdminThriveCampaignInfo } from './config';
import {
  addNewLead,
  connectExternalCampaign,
  getThriveCampaignsAdmin,
  updateThriveCampaignStatistics,
  updateThriveCampaignStatus,
  updateThrivePaymentStatus,
} from '_State/Thrive/Api/thrive-admin.api';
import { AdminThriveLeadsTable } from './AdminThriveLeadsTable';
import {
  getThriveCountryStatistics,
  getThriveLeads,
} from '_State/Thrive/Api/thrive.api';
import { UserType } from '_State/User/user.types';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { IMapCountry } from '_Components/PlxMap/types';
import { PlxTile } from '_Components/Panels/PlxTilesPanel/PlxTile';
import { InputSection } from '../../../../../_Components/Sections/InputSection';
import { FormMassage } from '_Components/Forms/FormMassage';

const AdminThriveCampaignBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledThriveLeadsTable = styled(AdminThriveLeadsTable)`
  grid-area: table;
`;
const StyledMapChart = styled(MapChart)`
  grid-area: map;
`;

const StyledLeadsTimeChart = styled(ThriveLeadsTimeChart)`
  grid-area: leads;
`;

const StyledCampaignInformation = styled(PlxCampaignInformation)`
  grid-area: information;
  overflow: auto;
`;
const StyledLeadsInfo = styled(LeadsInfo)`
  grid-area: leads-info;
`;

const StyledGenderChart = styled(GenderChart)`
  grid-area: gender;
`;

const StyledAgeCharts = styled(ThriveAgeCharts)`
  grid-area: age;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'leads-info gender map'
    'impressions gender map'
    'age age map'
    'leads leads leads'
    'information table table'
    'externalId externalId externalId';
  grid-template-columns: 26.5rem 26.5rem 1fr;
  grid-template-rows: 25rem 10rem 39rem 46rem 48rem;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  padding: 4rem;
  background: rgb(248, 249, 252);
`;

const StyledSelect = styled(PlxSelect)`
  margin: auto 1rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const AssetsButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

const StyledTile = styled(PlxTile)`
  && {
    grid-area: impressions;
  }
`;

const ExternalIdConnectWrapper = styled.div`
  grid-area: externalId;
`;

const StyledButton = styled(PlxButton)`
  float: right;
`;

const INITIAL_PARAMS: IThriveLeadsQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'optInDate',
  geolocationList: [],
};

export const AdminThriveCampaign: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [openGenderDialog, setOpenGenderDialog] = useState(false);
  const [openAgeDialog, setOpenAgeDialog] = useState(false);
  const [campaign, setCampaign] = useState<IThriveCampaign | null>();
  const [femaleLeadsAmount, setFemaleLeadsAmount] = useState<number>();
  const [maleLeadsAmount, setMaleLeadsAmount] = useState<number>();
  const [ageData, setAgeData] = useState<number[]>([]);
  const [monthData, setMonthData] = useState<number[]>([]);
  const [leads, setLeads] = useState<IThriveLead[]>([]);
  const [sorting, setSorting] = useState<string>('DESC');
  const [geoLocation, setGeoLocation] = useState<string[]>();
  const [params, setParams] = useState<IThriveLeadsQueryParams>(INITIAL_PARAMS);
  const [campaignInfoConfig, setCampaignInfoConfig] = useState<
    ICampaignInfoItem[]
  >([]);
  const [countryStats, setCountryStats] = useState<IMapCountry[]>([]);
  const [externalId, setExternalId] = useState<string>('');
  const [
    connectingExternalCampaignError,
    setConnectingExternalCampaignError,
  ] = useState<boolean>();
  const [
    connectingExternalCampaignMessage,
    setConnectingExternalCampaignMessage,
  ] = useState<string>('');
  const [connectingExternalCampaign, setConnectingExternalCampaign] = useState(
    false
  );

  const { user } = useSelector((state: IState) => state.user);

  const fetchLeads = () => {
    if (id) {
      getThriveLeads(id, params).then(({ content }) => {
        if (content) {
          setLeads(content);
          setMonthData(configureMonthData(content));
        }
        return content;
      });
    }
    getThriveCountryStatistics(id).then((stats) => setCountryStats(stats));
  };
  useEffect(() => {
    fetchLeads();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    fetchCampaign();
    // eslint-disable-next-line
  }, [id]);

  const handleAddNewLead = (form: IThriveLeadForm) => {
    return addNewLead(form, id).then(() => fetchLeads());
  };

  useEffect(() => {
    setFemaleLeadsAmount(campaign?.numberOfLeadsGenderFemale);
    setMaleLeadsAmount(campaign?.numberOfLeadsGenderMale);
    if (campaign) {
      setAgeData([
        campaign.numberOfLeadsAge25To34,
        campaign.numberOfLeadsAge35To44,
        campaign.numberOfLeadsAge45To54,
        campaign.numberOfLeadsAge55To64,
      ]);
      setCampaignInfoConfig(
        user?.userType === UserType.ADMIN
          ? configureAdminThriveCampaignInfo(t, changePaymentStatus, campaign)
          : configureEditorThriveCampaignInfo(t, campaign)
      );
    }
    // eslint-disable-next-line
  }, [campaign]);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onGeoLocationChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedGeoLocation = event.target.value as string[];
    setGeoLocation(selectedGeoLocation);
    setParams({ ...params, geolocationList: selectedGeoLocation });
  };

  const onImpressionsChange = (name: string, value: any) => {
    updateThriveCampaignStatistics(
      { impressions: Math.floor(value) },
      id
    ).then(() => fetchCampaign());
  };

  const fetchCampaign = () => {
    if (id) {
      getThriveCampaignsAdmin({ campaignId: id }).then((c) => {
        if (c) {
          const campaign = c.content[0];
          setCampaign(campaign);
        }
      });
    }
  };
  const changeCampaignStatus = (value: unknown) => {
    if (id) {
      updateThriveCampaignStatus(id, value as string).then();
    }
  };

  const updateRangeData = (form: any) => {
    updateThriveCampaignStatistics(form, id).then(() => fetchCampaign());
    setOpenAgeDialog(false);
  };

  const updateGenderData = (form: any) => {
    updateThriveCampaignStatistics(form, id).then(() => fetchCampaign());
    setOpenGenderDialog(false);
  };

  const changePaymentStatus = (value: unknown) => {
    updateThrivePaymentStatus(
      campaign?.id,
      campaign?.payment.paymentId,
      value as string
    ).then();
  };

  const notificationQuantity = useMemo(() => {
    if (
      campaign?.adminNotificationQuantity &&
      campaign.adminNotificationQuantity > 0
    ) {
      return campaign.adminNotificationQuantity;
    }
    return undefined;
  }, [campaign]);

  const handleExternalIdChange = (value: string | React.ChangeEvent<any>) => {
    setExternalId((value as React.ChangeEvent<any>).target.value);
  };

  const handleConnectExternalCampaign = () => {
    setConnectingExternalCampaign(true);
    connectExternalCampaign(externalId, campaign?.id)
      .then(() => {
        fetchLeads();
        fetchCampaign();
        setConnectingExternalCampaignMessage(
          'The external campaign ID has been connected successfully.'
        );
        setConnectingExternalCampaignError(false);
        setConnectingExternalCampaign(false);
      })
      .catch((error) => {
        error.response.json().then((error: any) => {
          setConnectingExternalCampaignMessage(
            'The external campaign ID is already connected.'
          );
          setConnectingExternalCampaignError(true);
          setConnectingExternalCampaign(false);
        });
      });
  };

  return (
    <AdminThriveCampaignBody>
      <HeaderWrapper>
        <PlxDashboardHeader
          backLink={'/home/admin/thrive-dashboard'}
          backLabel={'Thrive admin'}
          title={`${campaign?.companyName} · Campaign #${id}`}
        >
          <StyledSelect
            onChange={changeCampaignStatus}
            initialValue={
              campaign?.status
                ? campaign.status
                : CampaignStatus[CampaignStatus.NEW]
            }
            options={configureStatusSelect(t)}
          />
          <AssetsButton
            variant={ButtonVariant.Outlined}
            size={'small'}
            notificationQuantity={notificationQuantity}
            onClick={() =>
              history.push(`/home/admin/thrive-campaign/assets/${id}`)
            }
            label={'Manage Assets'}
          />
        </PlxDashboardHeader>
      </HeaderWrapper>
      <ContentWrapper>
        <StyledLeadsInfo totalLeads={leads.length} />
        <StyledTile
          formControlName={'impressions'}
          tilesQuantity={1}
          label={'Impressions'}
          amount={campaign?.impressions}
          editable
          onChange={onImpressionsChange}
        />
        <StyledGenderChart
          femaleAmount={femaleLeadsAmount}
          maleAmount={maleLeadsAmount}
          label={t('thrive.dashboard.gender')}
          onEditClick={() => setOpenGenderDialog(true)}
          editable={true}
        />
        <StyledMapChart
          label={t('thrive.dashboard.heatMap')}
          countries={countryStats}
        />
        <StyledAgeCharts
          editable
          onEditClick={() => setOpenAgeDialog(true)}
          label={t('thrive.dashboard.ageRange')}
          ageData={ageData}
        />
        <StyledLeadsTimeChart timeData={monthData} />
        <StyledCampaignInformation
          label={t('thrive.dashboard.campaignInformation')}
          config={campaignInfoConfig}
        />
        <StyledThriveLeadsTable
          onGeoLocationChange={onGeoLocationChange}
          handleAddNewLead={handleAddNewLead}
          sorting={sorting}
          geoLocation={geoLocation}
          leads={leads}
          onSortingChange={onSortingChange}
        />
        <ExternalIdConnectWrapper>
          <InputSection
            title={'External campaign ID'}
            placeholder={'Type in external campaign ID...'}
            fieldName={'externalId'}
            handleChange={handleExternalIdChange}
            value={externalId}
          />
          <StyledButton
            onClick={handleConnectExternalCampaign}
            variant={ButtonVariant.Contained}
            isProcessing={connectingExternalCampaign}
            label={'Connect'}
          />

          <FormMassage
            error={connectingExternalCampaignError}
            message={connectingExternalCampaignMessage}
          />
        </ExternalIdConnectWrapper>
      </ContentWrapper>
      <GenderUpdateDialog
        onSubmit={updateGenderData}
        statistics={{ ...campaign }}
        campaignId={id}
        open={openGenderDialog}
        onClose={() => setOpenGenderDialog(false)}
        title={t('thrive.dashboard.gender')}
      />
      <AgeRangeUpdateDialog
        onSubmit={updateRangeData}
        statistics={{ ...campaign }}
        campaignId={id}
        title={t('thrive.dashboard.ageRange')}
        open={openAgeDialog}
        onClose={() => setOpenAgeDialog(false)}
      />
    </AdminThriveCampaignBody>
  );
};
