import { Theme } from '@material-ui/core';

export interface IThemed {
  theme: Theme;
}
export enum TextSizes {
  S = 's',
  M = 'm',
  L = 'l',
}

export interface ISizes {
  [TextSizes.S]: string;
  [TextSizes.M]: string;
  [TextSizes.L]: string;
}
export interface IBreakpoints {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export interface IFontSizes {
  title: ISizes;
  text: ISizes;
  label: ISizes;
}

export interface IFpCustomThemeProperties {
  fontSizes: IFontSizes;
  screenSizes: IBreakpoints;
}
export interface IFpTheme extends Theme, IFpCustomThemeProperties {}
