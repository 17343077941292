import { IContent } from '../../utils';

export const trendlineEquation = (x: number, arr: IContent[]) => {
  let ASum = 0;
  let BSumOfElements = 0;
  let DSumOfX = 0;
  let BSumOfIndexes = 0;
  let CSumOfSquares = 0;

  for (let i = 0; i < arr.length; i++) {
    ASum =
      ASum + (i + 1) * (arr[i].reactions + arr[i].shares + arr[i].comments);

    BSumOfElements =
      BSumOfElements + (arr[i].reactions + arr[i].shares + arr[i].comments);

    DSumOfX = DSumOfX + (i + 1);

    BSumOfIndexes = BSumOfIndexes + (i + 1);

    CSumOfSquares = CSumOfSquares + Math.pow(i + 1, 2);
  }

  let A = arr.length * ASum;
  let B = BSumOfIndexes * BSumOfElements;
  let C = arr.length * CSumOfSquares;
  let D = Math.pow(DSumOfX, 2);
  let M = (A - B) / (C - D);
  let E = BSumOfElements;
  let FSumOfX = BSumOfIndexes;
  let F = M * FSumOfX;
  let Y0 = (E - F) / arr.length;

  return M * (x + 1) + Y0;
};
