import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { AdminThriveTable } from './AdminThriveTable';
import { getThriveCampaignsCSV } from '_State/Thrive/Api/thrive-admin.api';

const AdminThriveDashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const CSVButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

interface IProps {}

export const AdminThriveDashboard: FC<IProps> = () => {
  const [csvButtonState, setCsvButtonState] = useState<boolean>(false);

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleThriveCampaignsCSV = () => {
    setCsvButtonState(true);
    getThriveCampaignsCSV().then((data) => {
      createDownloadCSV(data, 'thrive-campaigns');
    });
    setCsvButtonState(false);
  };

  return (
    <AdminThriveDashboardBody>
      <HeaderWrapper>
        <StyledHeader title={'Thrive Admin'}>
          <CSVButton
            size={'small'}
            isDisabled={csvButtonState}
            label={'Download CSV'}
            onClick={handleThriveCampaignsCSV}
          />
        </StyledHeader>
      </HeaderWrapper>
      <AdminThriveTable />
    </AdminThriveDashboardBody>
  );
};
