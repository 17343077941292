import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Arrow } from '_Routes/Home/_routes/Spectrum/_Routes/SpectrumEngagement/_Components/AvgEngagementRateAndTrendGraph/AvgEngagementRateAndTrendGraph';
import { useTranslation } from 'react-i18next';
import { IBasicWidgetData } from '_Routes/Home/_routes/Spectrum/_Routes/SpectrumDashboard/utils';
import { XemTooltip } from '_Components/XemTooltip/XemTooltip';

export enum BasicWidgetVariant {
  Increase = 'Increase',
  Decrease = 'Decrease',
  NoChange = 'NoChange',
}
interface IProps {
  data: IBasicWidgetData;
  title: string;
  primaryText?: string;
  secondaryText?: string;
  additionalText?: string;
  totalClicks?: number;
}

const StyledStatisticsWidget = styled.div`
  position: relative;
  width: 100%;
  border-radius: 16px;
  border: 2px solid #d4d4d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 45rem;
  min-width: 25rem;
  &.Increase {
    border-color: #008b9c;
  }
  &.Decrease {
    border-color: #ea9000;
  }
  &.NoChange {
    border-color: #5f5f61;
  }
`;
const ArrowTooltipWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;
const BasicWidgetDataContainer = styled.div``;
const TitleBasicWidget = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 16px;
`;
const ValueBasicWidget = styled.div`
  font-weight: 600;
  font-size: 28px;
  margin: 16px;
`;
const RelativeValueContainer = styled.div`
  display: flex;
  margin: 16px;
`;
const RelativeValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  &.Increase {
    color: #008b9c;
  }
  &.Decrease {
    color: #ea9000;
  }
  &.NoChange {
    color: #5f5f61;
  }
`;
const RelativeValueDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const ArrowContainer = styled.div`
  align-self: flex-start;
  svg {
    background-color: #5f5f61;
    fill: white;
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 16px;
    &.Increase {
      transform: rotate(-45deg);
      background-color: #008b9c;
    }
    &.Decrease {
      transform: rotate(45deg);
      background-color: #ea9000;
    }
    &.NoChange {
      background-color: #5f5f61;
    }
  }
`;

export const StatisticsWidget: FC<IProps> = ({
  data,
  title,
  primaryText,
  secondaryText,
  additionalText,
  totalClicks,
}) => {
  const { t } = useTranslation();
  const variant = () => {
    if (!data.comparisonToAverage) return;
    if (data.comparisonToAverage > 0) return BasicWidgetVariant.Increase;
    if (data.comparisonToAverage < 0) return BasicWidgetVariant.Decrease;
    return BasicWidgetVariant.NoChange;
  };

  return (
    <StyledStatisticsWidget className={variant()}>
      <BasicWidgetDataContainer>
        <TitleBasicWidget>{title}</TitleBasicWidget>
        <ValueBasicWidget>
          {totalClicks !== undefined ? (
            totalClicks
          ) : typeof data.averagePotential !== 'undefined' ? (
            <>
              {Number(data.averagePotential)
                .toFixed(title === 'Average Engagement' ? 3 : 0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {title === 'Average Engagement' && '%'}
            </>
          ) : data.averagePotential == null &&
            data.comparisonToAverage == null ? (
            'N/A'
          ) : (
            '-'
          )}
        </ValueBasicWidget>
        <RelativeValueContainer>
          {totalClicks === undefined && (
            <RelativeValue className={variant()}>
              {typeof data.comparisonToAverage !== 'undefined' ? (
                <>
                  {data.comparisonToAverage > 0 && '+'}
                  {Math.round((data.comparisonToAverage as number) * 100) / 100}
                  %
                </>
              ) : (
                'no data'
              )}
            </RelativeValue>
          )}
          &nbsp;
          <RelativeValueDescription>
            {typeof data.comparisonToAverage !== 'undefined' &&
              t('spectrum.overview.comparedToTheAverage')}
          </RelativeValueDescription>
        </RelativeValueContainer>
      </BasicWidgetDataContainer>
      <ArrowTooltipWrap>
        <ArrowContainer className={variant()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className={variant()}
          >
            <Arrow />
          </svg>
        </ArrowContainer>
        <XemTooltip
          primaryText={primaryText}
          secondaryText={secondaryText}
          additionalText={additionalText}
        />
      </ArrowTooltipWrap>
    </StyledStatisticsWidget>
  );
};
