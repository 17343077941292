import { useEffect, useMemo, useState } from 'react';
import { HashtagChartElement } from '../../helpers';
import { BarsCountPeerPage } from './data';

export interface IUseCustomPagination {
  pageCount: number;
  paginationData: HashtagChartElement[];
  handlePageChange: (event: object, page: number) => void;
}

export const useCustomPagination = (
  data: HashtagChartElement[]
): IUseCustomPagination => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(1);

    if (data.length <= BarsCountPeerPage) return setPageCount(1);

    setPageCount(Math.floor(data.length / BarsCountPeerPage));
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePageChange = (_event: object, page: number) => {
    setPage(page);
  };

  const paginationData = useMemo(() => {
    const start = BarsCountPeerPage * (page - 1);
    const end = start + BarsCountPeerPage;

    return data.slice(start, end);
  }, [data, page]);

  return {
    pageCount,
    paginationData,
    handlePageChange,
  };
};
