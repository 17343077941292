import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles/ClosingButtons.styles';

interface IClosingButtons {
  setAnchorEl: (arg: null | HTMLElement) => void;
  resetFilters: () => void;
}
const ClosingButtons = ({ setAnchorEl, resetFilters }: IClosingButtons) => {
  const { t } = useTranslation();

  const handleClear = () => {
    resetFilters();
  };
  const handleDone = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <div onClick={handleClear}>{t('spectrum.filterLabels.clear')}</div>
      <div onClick={handleDone}>{t('spectrum.filterLabels.done')}</div>
    </Wrapper>
  );
};

export default ClosingButtons;
