import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { Button, createStyles, Dialog, makeStyles } from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import { Close } from '@material-ui/icons';
import { IThemed } from '_Styles/types';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  height: 6.5rem;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
  margin: 0 3rem;
  align-items: center;
  border-top: 1px solid #d5dde3;
  height: 8.5rem;
`;

const ChildrenWrapper = styled.div<{
  areButtons: boolean;
  isOfferWindow: boolean | undefined;
}>`
  display: flex;
  height: ${({ areButtons, isOfferWindow }) =>
    isOfferWindow ? '600px' : areButtons ? '54rem' : '100%'};
  overflow: auto;
`;

const CloseIcon = styled(Close)`
  margin: 2.5rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;

const Title = styled.div<IThemed>`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-top: 3rem;
`;

const CloseButton = styled(PlxButton)`
  && {
    width: 10rem;
    margin-right: 1rem;
  }
`;
const SaveButton = styled(PlxButton)`
  && {
    width: 10rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RedButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      // width: '55rem',
      // height: '60rem',
      maxWidth: '100rem',
      backgroundColor: '#fff',
      borderRadius: '15px',
    },
  })
);
export interface IDialogProps extends ICommonProps {
  title: string;
  open: boolean;
  onSaveDisabled?: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  onSave?: () => void;
  onRedAction?: () => void;
  onExtraOption?: () => void;
  closeButton?: boolean;
  isProcessing?: boolean;
  closeButtonLabel?: string;
  saveButtonLabel?: string;
  extraOptionLabel?: string;
  isOfferWindow?: boolean;
  onRedActionDisabled?: boolean;
  redActionLabel?: string;
  backgroundColor?: string;
  testId?: string;
}

export const PlxDialog: FC<IDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  onSaveDisabled = false,
  isDisabled = false,
  closeButton,
  children,
  closeButtonLabel = 'Close',
  saveButtonLabel = 'Save',
  onExtraOption,
  extraOptionLabel,
  isOfferWindow,
  onRedAction,
  onRedActionDisabled,
  redActionLabel,
  backgroundColor,
  testId,
}) => {
  const classes = useStyles();
  const isAnyActionButton = useMemo(() => onSave || closeButton, [
    onSave,
    closeButton,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="simple-dialog-title"
    >
      <Wrapper
        style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
      >
        <Header
          style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
        >
          <Title>{title}</Title>
          <CloseIcon onClick={onClose} data-test-id="close-button" />
        </Header>
        <ChildrenWrapper areButtons={!!onSave} isOfferWindow={isOfferWindow}>
          {children}
        </ChildrenWrapper>
        {isAnyActionButton && (
          <ButtonSection>
            {onRedAction && (
              <RedButton
                color={'secondary'}
                variant={'outlined'}
                disabled={onRedActionDisabled}
                onClick={onRedAction}
              >
                {redActionLabel}
              </RedButton>
            )}
            {onExtraOption && (
              <CloseButton
                isProcessing={onSaveDisabled}
                variant={ButtonVariant.Outlined}
                onClick={onExtraOption}
                label={extraOptionLabel}
                testId="extra-button"
              />
            )}
            {closeButton && (
              <CloseButton
                onClick={onClose}
                label={closeButtonLabel}
                variant={ButtonVariant.Outlined}
                testId="close-button"
              />
            )}
            {onSave && (
              <SaveButton
                isProcessing={onSaveDisabled}
                isDisabled={isDisabled || onSaveDisabled}
                onClick={onSave}
                label={saveButtonLabel}
                testId="save-button"
              />
            )}
          </ButtonSection>
        )}
      </Wrapper>
    </Dialog>
  );
};
