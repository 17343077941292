import React, { FC, useState } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { Content, Label, Wrapper, PayoutList, ButtonWrap } from './Styles';
import { IDistributionPartnerChannelSelectableRow } from '_State/DistributionPartner/distributionPartner.types';
import { useTranslation } from 'react-i18next';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { approveCompletedTransactions } from '_State/User/Api/admin-user.api';
import { XemTable } from '_Components/XemTable/XemTable';
import { xemCreatePayoutList } from './configs';

interface XemIProps {
  completedOpportunity: IDistributionPartnerChannelSelectableRow[];
  openClosePayoutDialog: () => void;
  id: string | undefined;
  fetchChannels: () => void;
  setSuccessDialogOpen: (v: boolean) => void;
}
export const BatchPayoutPopup: FC<XemIProps> = ({
  completedOpportunity,
  openClosePayoutDialog,
  id,
  fetchChannels,
  setSuccessDialogOpen,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [opportunitiesForPayout, setOpportunitiesForPayout] = useState<
    string[]
  >([]);
  const [total, setTotal] = useState(0);

  const handlePayout = () => {
    if (id) {
      setLoading(true);
      approveCompletedTransactions(opportunitiesForPayout, id).then(
        (res) => {
          console.log(res);
          openClosePayoutDialog();
          fetchChannels();
          setLoading(false);
          setSuccessDialogOpen(true);
        },
        (err) => {
          console.log(err);
          openClosePayoutDialog();
          setLoading(false);
          alert('Something is wrong, please try again later.');
          window.location.reload();
        }
      );
    }
  };
  const handleRowSelectionForPayout = ({ selectionModel }: any) => {
    const selectedRow = completedOpportunity
      .filter((c) => selectionModel.some((s: string) => Number(s) === c.id))
      .reduce((prev, current) => prev + +current.offer, 0);
    setTotal(selectedRow);
    setOpportunitiesForPayout(selectionModel);
  };

  return (
    <>
      <Wrapper>
        <PlxPanel
          enableActions={false}
          content={
            <Content style={{ margin: '0' }}>
              <Label>{t('channels.reviewBatchPayout.confirmation')}</Label>
              <div style={{ color: '#4B0082' }}>
                {t('channels.reviewBatchPayout.upto10')}
              </div>
              <PayoutList>
                <XemTable
                  header={xemCreatePayoutList}
                  data={completedOpportunity}
                  pageSize={10}
                  checkbox={true}
                  handleRowSelection={handleRowSelectionForPayout}
                  density={'compact'}
                  loading={loading}
                />
                <div
                  style={{
                    fontSize: '1.2rem',
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '3rem',
                    marginRight: '1rem',
                  }}
                >
                  <span>
                    {t('channels.reviewBatchPayout.total')}(
                    {opportunitiesForPayout.length}){' '}
                    {opportunitiesForPayout.length > 10 && (
                      <span style={{ color: '#820000' }}>
                        {t('channels.reviewBatchPayout.limit')}
                      </span>
                    )}
                  </span>
                  <span>
                    $<StandardizeAmount amount={total} />
                  </span>
                </div>
              </PayoutList>
              <ButtonWrap>
                <PlxButtonX
                  size="small"
                  onClick={openClosePayoutDialog}
                  label={t('channels.reviewAnalyticsConfirmation.cancel')}
                />
                <PlxButtonX
                  size="small"
                  onClick={handlePayout}
                  label={t('channels.reviewAnalyticsConfirmation.confirm')}
                  isDisabled={
                    opportunitiesForPayout.length > 10 ||
                    opportunitiesForPayout.length < 1 ||
                    loading
                      ? true
                      : false
                  }
                />
              </ButtonWrap>
            </Content>
          }
        />
      </Wrapper>
    </>
  );
};
