import { createStyles, Dialog, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Close } from '@material-ui/icons';
import { ICommonProps } from '_Types/props';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const CloseIcon = styled(Close)`
  margin: 1rem;
  right: 0;
  cursor: pointer;
  position: absolute;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: '50rem',
      minHeight: '10rem',
      backgroundColor: '#FFFFFF',
    },
  })
);

const ChildrenWrapper = styled.div`
  margin: 4.5rem 3.3rem;
  text-align: center;
`;

const Wrapper = styled.div`
  position: relative;
`;
const CongratsWrap = styled.div`
  font-size: 2rem;
  margin: 1rem;
  line-height: 2.5rem;
`;
export interface ISimpleDialogProps extends ICommonProps {
  open: boolean;
  onClose?: () => void;
}

export const XemSuccessDialog: FC<ISimpleDialogProps> = ({
  open,
  onClose,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.paper }}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <ChildrenWrapper>
          <CheckCircleIcon style={{ fontSize: '10rem', color: 'green' }} />
          <CongratsWrap>
            Congratulations. You have successfully sent your request.
          </CongratsWrap>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    </Dialog>
  );
};
