import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlxCampaignInformation } from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { ReviewAssets } from '_Routes/Home/_Components/ReviewAssets/ReviewAssets';
import { RequestUpdateDialog } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Routes/ThriveCampaignAssets/RequestUpdateDialog';
import { configureThriveCampaignAssetsInfo } from './config';
import {
  getCampaignAssets,
  getThriveCampaign,
  requestAssetInfo,
  requestCampaignUpdate,
} from '_State/Thrive/Api/thrive.api';
import {
  IAsset,
  IAssetsContext,
  IAssetUpdate,
  ICampaignUpdate,
  IThriveCampaign,
} from '_State/Thrive/types';
import { ConfirmRequestDialog } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Routes/ThriveCampaignAssets/ConfirmRequestDialog';
import { SuccessRequestDialog } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Routes/ThriveCampaignAssets/SuccessRequestDialog';
import { AssetsContext } from '_Types/assetsContext';
import { FormMassage } from '_Components/Forms/FormMassage';

const InfoWrapper = styled.div`
  width: 26.5rem;
  margin-right: 3rem;
  height: 100%;
`;

const StyledCampaignInformation = styled(PlxCampaignInformation)`
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4rem;
  height: calc(100% - 7.5rem);
  width: 100%;
`;

const ThriveCampaignAssetsBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const AssetsWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const SubmitAssetsButton = styled(PlxButton)`
  && {
    width: 13.5rem;
  }
`;

const StyledButton = styled(PlxButton)`
  && {
    margin-top: 2rem;
  }
`;
const HeaderWrapper = styled.div`
  height: 7.5rem;
`;
interface IProps {}

export const ThriveCampaignAssets: FC<IProps> = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState<IThriveCampaign>();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [assetRequests, setAssetRequests] = useState<IAssetUpdate[]>([]);
  const [campaignUpdate, setCampaignUpdate] = useState<ICampaignUpdate>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [assetRequestError, setAssetRequestError] = useState(false);
  const [assetRequestMessage, setAssetRequestMessage] = useState<string>('');

  const context: IAssetsContext = {
    assets,
    addAssetInstructions: (id: number, instructions: string) => {
      const currentRequests = assetRequests;
      const existingRequest = currentRequests.filter(
        (request) => request.id === id
      )[0];
      if (existingRequest) {
        existingRequest.instructions = instructions;
      } else {
        currentRequests.push({ id: id, instructions: instructions });
      }
      setAssetRequests(currentRequests.slice());
    },
  };

  const fetchCampaign = (id?: string) =>
    getThriveCampaign(id).then((value) => {
      setCampaign(value);
      return value;
    });

  useEffect(() => {
    fetchCampaign(id).then();
    getCampaignAssets(id).then((assetsData) => setAssets(assetsData));
  }, [id]);

  const openRequestUpdateDialog = () => {
    setDialogOpened(true);
  };

  const closeRequestUpdateDialog = () => {
    setDialogOpened(false);
  };

  const handleConfirmSubmit = () => {
    setOpenConfirmDialog(false);

    requestCampaignUpdate(id, campaignUpdate)
      .then(() => {
        assetRequests.forEach((asset) => requestAssetInfo(id, asset).then());
        //TODO some dialog shows conflicts
        setOpenSuccessDialog(true);
      })
      .catch((error) => {
        error.response.json().then((error: any) => {
          setAssetRequestError(true);
          setAssetRequestMessage(
            t('thrive.campaign.existedNotificationErrorMessage')
          );
        });
      });
  };

  const isRequest = useMemo(() => {
    return (
      assetRequests.filter((request) => request.instructions).length > 0 ||
      campaignUpdate
    );
  }, [assetRequests, campaignUpdate]);

  return (
    <ThriveCampaignAssetsBody>
      <HeaderWrapper>
        <PlxDashboardHeader
          title={`${t('thrive.dashboard.creativeAssetApproval')} #${id}`}
          backLabel={`${t('thrive.dashboard.campaign')} #${id}`}
          backLink={'/home/thrive-dashboard/campaign/' + id}
        >
          <SubmitAssetsButton
            isDisabled={!isRequest}
            size={'small'}
            label={t('thrive.dashboard.submitRequests')}
            onClick={() => setOpenConfirmDialog(true)}
          />
        </PlxDashboardHeader>
      </HeaderWrapper>

      <FormMassage error={assetRequestError} message={assetRequestMessage} />

      <ContentWrapper>
        <InfoWrapper>
          <StyledCampaignInformation
            label={t('thrive.dashboard.campaignInformation')}
            tooltip={t('thrive.dashboard.campaignInformationTooltip')}
            config={configureThriveCampaignAssetsInfo(t, campaign)}
          >
            <StyledButton
              label={t('thrive.dashboard.requestUpdate')}
              size={'small'}
              onClick={openRequestUpdateDialog}
              variant={ButtonVariant.Outlined}
            />
          </StyledCampaignInformation>
        </InfoWrapper>

        <AssetsContext.Provider value={context}>
          <AssetsWrapper>
            <ReviewAssets
              label={t('thrive.dashboard.reviewAssets')}
              tooltip={t('thrive.dashboard.reviewAssetsTooltip')}
            />
          </AssetsWrapper>
        </AssetsContext.Provider>
        {/*  */}
      </ContentWrapper>
      <RequestUpdateDialog
        campaignUpdate={campaignUpdate}
        onCampaignUpdateSave={(form) => setCampaignUpdate(form)}
        open={dialogOpened}
        title={t('thrive.dashboard.requestUpdate')}
        onClose={closeRequestUpdateDialog}
      />
      <ConfirmRequestDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onSubmit={handleConfirmSubmit}
      />
      <SuccessRequestDialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
      />
    </ThriveCampaignAssetsBody>
  );
};
