import styled from '@emotion/styled';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { IThriveCampaign, IThriveCampaignForm } from '_State/Thrive/types';
import {
  getThriveDraft,
  updateThriveCampaignDraft,
} from '_State/Thrive/Api/thrive.api';
import { UploadPresentationSection } from '_Routes/Home/_Components/Sections/UploadPresentationSection';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { getThriveLink } from '_Routes/Home/_routes/Spectrum/utils';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const StyledFooterButtons = styled(FooterButtons)`
  margin-top: auto;
`;

const INITIAL_VALUES: IThriveCampaignForm = {
  presentationUrl: '',
  presentationName: '',
  companyWebsite: '',
  otherInformation: '',
};

const REQUIRED_FIELDS = ['presentationName'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const ThriveCampaign: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [draft, setDraft] = useState<IThriveCampaign>();
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const saveDraft = (values: any) => {
    return updateThriveCampaignDraft(values);
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        saveDraft(form)
          .then(
            () => {
              history.push('/home/thrive-campaign/payment');
            },
            () => {
              setApiErrorMessage('File is too big - max size is 700kb');
            }
          )
          .catch((error) => {
            error.response
              .json()
              .then((error: any) => setApiErrorMessage(error.message));
          });
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (
        form.companyWebsite &&
        !form.companyWebsite.startsWith('http://') &&
        !form.companyWebsite.startsWith('https://')
      ) {
        errors.companyWebsite = t('errors.companyWebsite');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (form: IThriveCampaign) => {
    formik.setValues({
      presentationUrl: null,
      presentationName: form?.thrivePresentation?.fileName,
      companyWebsite: form?.companyWebsite,
      otherInformation: form?.otherInformations,
    });
  };

  const handleNextStep = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    getThriveDraft().then(
      (v) => {
        if (v) {
          fillForm(v);
          setDraft(v);
        }
      },
      () => null
    );
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (url: string, fileName: string) => {
    formik.setFieldValue('presentationUrl', url);
    formik.setFieldValue('presentationName', fileName);
  };

  const handleBack = () => {
    history.push('/home/thrive-campaign/program');
  };

  return (
    <Wrapper>
      <form>
        <StyledForm>
          <UploadPresentationSection
            required
            title={t('thrive.campaign.investorPresentation')}
            handleChange={handleFileChange}
            tooltip={t('thrive.campaign.investorPresentationTooltip')}
            fileName={draft?.thrivePresentation?.fileName}
          />
          <FieldErrorMessage message={formik.errors.presentationName} />
          <InputSection
            title={t('thrive.campaign.companyUrl')}
            placeholder={t('thrive.campaign.companyUrlPlaceholder')}
            fieldName={'companyWebsite'}
            handleChange={formik.handleChange}
            value={draft?.companyWebsite}
            required={false}
          />
          <FieldErrorMessage message={formik.errors.companyWebsite} />
          <InputSection
            title={t('thrive.campaign.otherInformation')}
            placeholder={t('thrive.campaign.otherInformationPlaceholder')}
            fieldName={'otherInformation'}
            handleChange={formik.handleChange}
            value={draft?.otherInformations}
            required={false}
            rowsMax={10}
            rows={5}
            multiline
          />
          <FieldErrorMessage message={apiErrorMessage} />
        </StyledForm>
      </form>
      <StyledFooterButtons
        onSave={() =>
          saveDraft(formik.values).then(() =>
            getThriveLink().then((link) => {
              if (link) {
                history.push(link);
              }
            })
          )
        }
        showNextStep
        onNextStep={handleNextStep}
        showBack
        showSaveForLater
        onBack={handleBack}
      />
    </Wrapper>
  );
};
