import styled from '@emotion/styled';
import React, { FC } from 'react';
import ImageProcessing from '_Styles/images/processing.svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 13rem;
`;

const ProcessingWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ProcessingBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProcessingImg = styled.img``;
const Label = styled.div`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 4.2rem;
  text-align: center;
  color: #1b1b1b;
  margin-top: 2rem;
`;
const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.3rem;
  text-align: center;
  color: #61657e;
  width: 40rem;
  margin-top: 1rem;
`;
export const Processing: FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ProcessingBody>
      {children}
      <ProcessingWrapper>
        <Wrapper>
          <ProcessingImg src={ImageProcessing} />
          <Label>{t('processing.label')}</Label>
          <Description>{t('processing.description')}</Description>
        </Wrapper>
      </ProcessingWrapper>
    </ProcessingBody>
  );
};
