import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

export const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FormWrapper = styled.div`
  margin: 5rem;
  width: 500px;
  fieldset {
    width: 100%;
  }
  legend {
    padding-bottom: 2rem;
    font-size: 2rem;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  margin: 5rem 2rem 0 2rem;
  justify-content: space-between;
`;
export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4rem 4rem;
  font-size: 1.5rem;
  line-height: 2.8rem;
  max-width: 720px;
  div {
    display: flex;
    justify-content: space-around;
  }
`;
