import React from 'react';
import { IContent } from '../../utils';

export const CustomXAxisTick = ({
  x,
  y,
  payload,
  engagementGraphData,
}: any) => {
  const element = engagementGraphData?.content?.find(
    (el: IContent) => el.startDate === payload.value
  );

  const description = (element: IContent) => {
    if (element?.tag?.name) {
      return element?.tag?.name;
    }
    if (element?.id) {
      return `Camp #${element?.id}`;
    }
    return false;
  };
  return (
    <>
      {description(element) && (
        <>
          <text x={x} y={y} dy={10} fontSize={10} textAnchor="middle">
            {payload.value}
          </text>
          <text
            x={x}
            y={y}
            dy={26}
            fontSize={10}
            textAnchor="middle"
            style={{ fill: '#9D05B0' }}
          >
            {description(element)}
          </text>
        </>
      )}
    </>
  );
};
