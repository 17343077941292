import { chain } from 'lodash';
import { ChannelType } from '_State/DistributionPartner/distributionPartner.enums';
import {
  Average,
  AverageTrend,
  HashtagEngagement,
  ISpectrumCampaignOptions,
} from '_State/Spectrum/types';
import { IFilterItem } from './utils';

export type ChartElement<N, V> = {
  name: N;
  'campaign-1'?: V;
  'campaign-2'?: V;
  'campaign-3'?: V;
};

export type AverageChartElement = ChartElement<ChannelType, Average>;
export type HashtagChartElement = ChartElement<string, number>;

export const serilizeCampaignIdsFilter = (
  compaignIds: ISpectrumCampaignOptions[]
): IFilterItem[] => {
  const result: IFilterItem[] = [];

  compaignIds.forEach((compaignId) => {
    result.push({
      id: compaignId.id.toString(),
      title: `#${compaignId.id} (${compaignId.tag?.name})`,
      isChecked: false,
    });
  });

  return result;
};

export const serilizeAveragesTrendKeys = (
  trendsArray: AverageTrend[][]
): string[] => {
  return chain(trendsArray.flat(1)).groupBy('channelType').keys().value();
};

export const serilizeAveragesKeys = (averagesArray: Average[][]): string[] => {
  return chain(averagesArray.flat(1)).groupBy('channelType').keys().value();
};

export const serilizeAverages = (
  averagesArray: Average[][]
): AverageChartElement[] => {
  const result: AverageChartElement[] = [];

  averagesArray.forEach((campaignAverages, index) => {
    campaignAverages.forEach((average) => {
      const itemIndex = result.findIndex((v) => v.name === average.channelType);

      if (itemIndex === -1) {
        return result.push({
          name: average.channelType,
          'campaign-1': {
            ...average,
            engagement: +average.engagement.toFixed(3),
            socialmediaEngagement: +average.socialmediaEngagement.toFixed(3),
          },
        });
      }

      return ((result[itemIndex] as any)[`campaign-${index + 1}`] = {
        ...average,
        engagement: +average.engagement.toFixed(3),
        socialmediaEngagement: +average.socialmediaEngagement.toFixed(3),
      });
    });
  });

  return result;
};

export const serilizeHashtags = (
  hashtagsArray: HashtagEngagement[][]
): HashtagChartElement[] => {
  const result: HashtagChartElement[] = [];

  hashtagsArray.forEach((hashtags, index) => {
    hashtags.forEach(({ hashTag, engagement }) => {
      const itemIndex = result.findIndex((v) => v.name === hashTag);

      if (itemIndex === -1) {
        return result.push({
          name: hashTag,
          [`campaign-${index + 1}`]: +engagement.toFixed(3),
        });
      }

      return ((result[itemIndex] as any)[
        `campaign-${index + 1}`
      ] = +engagement.toFixed(3));
    });
  });

  console.log(result);

  return result;
};
