import React, { useEffect, useState } from 'react';
import {
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  StyledEngagemenetGraphContainer,
  EngagementChartTitle,
} from './EngagementGraph.styles';
import CheckboxFooter from './CheckboxFooter';
import { CustomXAxisTick } from './CustomXAxisTick';
import { CustomizedTooltip } from './CustomizedTooltip';
import { isNumber } from 'lodash';
import { trendlineEquation } from './functions';
import { IContent, IEngagementGraphData } from '../../utils';
import { XemTooltip } from '_Components/XemTooltip/XemTooltip';

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
export interface IVisibility {
  [key: string]: boolean;
}
export interface IDailyData {
  [key: string]: number | string;
}

interface IEngagementGraph {
  engagementGraphData: IEngagementGraphData;
}

export function EngagementGraph({ engagementGraphData }: IEngagementGraph) {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState<IVisibility>({
    reactions: true,
    shares: true,
    comments: true,
    engagement: true,
    trend: true,
  });
  const [trendLinePoints, setTrendLinePoints] = useState<IContent[]>([]);
  const handleChange = (arg: string) => {
    setVisibility((prev: IVisibility) => {
      return { ...visibility, [arg]: !prev[arg] };
    });
  };
  const trendLineData = (start: number, end: number) => {
    const graphRange = end - start;
    const arr = [];
    for (let i = 0; i < graphRange; i++) {
      arr.push({
        ...engagementGraphData.content[start + i],
      });
    }
    arr[0] = {
      ...engagementGraphData.content[start],
    };
    arr[graphRange] = {
      ...engagementGraphData.content[end],
    };

    arr[0] = {
      trend: trendlineEquation(0, arr) < 0 ? 0 : trendlineEquation(0, arr),
      ...engagementGraphData.content[start],
    };
    arr[graphRange] = {
      trend: trendlineEquation(graphRange, arr),
      ...engagementGraphData.content[end],
    };

    setTrendLinePoints(arr);
  };
  const getDailyData = (label: string): IContent | undefined => {
    return engagementGraphData.content.find(
      (el: IContent) => el.startDate === label
    );
  };

  const dataTypes = [
    { name: 'shares', color: '#008b9c' },
    { name: 'comments', color: '#59b3be' },
    { name: 'reactions', color: '#afd7dd' },
    { name: 'engagement', color: '#9d05b0' },
    { name: 'trend', color: '#B38D20' },
  ];
  const dataTypesCheckbox = [
    { name: 'reactions', color: '#afd7dd' },
    { name: 'comments', color: '#59b3be' },
    { name: 'shares', color: '#008b9c' },
    { name: 'engagement', color: '#9d05b0' },
    { name: 'trend', color: '#B38D20' },
  ];

  useEffect(() => {
    if (engagementGraphData.content) {
      trendLineData(0, engagementGraphData?.content.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementGraphData.content]);
  return (
    <StyledEngagemenetGraphContainer chartHeight={700}>
      <EngagementChartTitle>
        {t('spectrum.overview.engagementByCampaign')}
      </EngagementChartTitle>
      <ResponsiveContainer width="98%" height="90%">
        <ComposedChart
          data={
            engagementGraphData?.content?.length
              ? engagementGraphData.content
              : []
          }
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          {engagementGraphData?.content?.length && (
            <Brush
              dataKey="startDate"
              height={30}
              fill="#afd7dd"
              onChange={(el) => {
                trendLineData(el.startIndex, el.endIndex);
              }}
            />
          )}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="startDate"
            tick={<CustomXAxisTick engagementGraphData={engagementGraphData} />}
          />
          <YAxis
            label={{ value: 'Interactions', angle: -90, dx: -15, fontSize: 16 }}
            domain={[
              () => {
                const min = Math.min(
                  ...trendLinePoints
                    .filter((el) => el.trend)
                    .map((o: any) => o.trend)
                );
                if (isNumber(min)) {
                  return min < 0 ? Math.ceil(min / 10) * 11 : 0;
                }
                return 0;
              },
              (dataMax: number) => {
                const max = Math.max(
                  ...trendLinePoints
                    .filter((el) => el.trend)
                    .map((o: any) => o.trend)
                );

                if (isNumber(max)) {
                  return max > dataMax
                    ? Math.ceil(max / 10) * 11
                    : Math.ceil(dataMax / 10) * 11;
                }

                return Math.ceil(dataMax / 10) * 11;
              },
            ]}
          />
          <YAxis
            yAxisId={2}
            orientation="right"
            label={{
              value: 'Engagement Rate (%)',
              angle: 90,
              dx: 15,
              fontSize: 16,
            }}
          />

          <Tooltip
            content={
              <CustomizedTooltip
                dataTypesCheckbox={dataTypesCheckbox}
                getDailyData={getDailyData}
                visibility={visibility}
              />
            }
          />

          <ReferenceLine y={0} stroke="#000" />

          {engagementGraphData &&
            dataTypes.map((el, index) => {
              if (el.name === 'engagement') {
                return (
                  visibility[el.name] && (
                    <Line
                      strokeWidth={3}
                      key={el.name + index}
                      dataKey={el.name}
                      stroke={el.color}
                      isAnimationActive={false}
                      yAxisId={2}
                      activeDot={{
                        stroke: '#9d05b0',
                        strokeWidth: 2,
                        r: 5,
                        strokeDasharray: '0',
                        fill: 'white',
                      }}
                      dot={{
                        stroke: 'white', // here
                        strokeWidth: 2,
                        r: 8,
                        strokeDasharray: '0',
                        fill: '#9d05b0',
                      }}
                    />
                  )
                );
              }
              if (el.name === 'trend') {
                return (
                  visibility[el.name] && (
                    <Line
                      dot={false}
                      strokeWidth={2.5}
                      key={el.name + index}
                      data={trendLinePoints}
                      connectNulls
                      dataKey={el.name}
                      stroke={el.color}
                      activeDot={false}
                      strokeDasharray="5 5"
                      isAnimationActive={false}
                    />
                  )
                );
              }
              return (
                visibility[el.name] && (
                  <Bar
                    key={el.name + index}
                    dataKey={el.name}
                    stackId="a"
                    fill={el.color}
                    isAnimationActive={false}
                    maxBarSize={100}
                  />
                )
              );
            })}
        </ComposedChart>
      </ResponsiveContainer>
      <div style={{ display: 'flex' }}>
        <CheckboxFooter
          dataTypesCheckbox={dataTypesCheckbox}
          visibility={visibility}
          handleChange={handleChange}
        />
        <span>
          <XemTooltip primaryText={t('spectrum.overview.engagementTooltip')} />
        </span>
      </div>
    </StyledEngagemenetGraphContainer>
  );
}
