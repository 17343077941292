import React, { FC } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';

const CircleWrap = styled.div`
  > div {
    width: 25px !important;
    height: auto !important;
    margin-top: 8px;
  }
`;

export interface IProps extends ICommonProps {
  label?: string;
  isDisabled?: boolean;
  inputType?: 'submit';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  notificationQuantity?: number;
  isProcessing?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  backgroundColor?: any;
  testId?: string;
}

const useStyles = makeStyles({
  button: {
    padding: '11px 16px',
    margin: '0 0 0 16px',
    backgroundColor: '#E5E5E5',
    borgerRadius: '6px',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#1B192C',
    '&:hover': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
});

export const PlxTableHeaderButton: FC<IProps> = ({
  label,
  children,
  inputType,
  onClick,
  size = 'small',
  isDisabled,
  isProcessing,
  color,
  backgroundColor,
  testId,
}) => {
  const classes = useStyles();
  return (
    <>
      <Button
        color={color}
        className={classes.button}
        type={inputType}
        onClick={onClick}
        size={size}
        disabled={isDisabled || isProcessing}
        style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
        data-test-id={testId}
      >
        {isProcessing ? (
          <CircleWrap>
            <CircularProgress color={'secondary'} />
          </CircleWrap>
        ) : (
          <>
            {label}
            {children}
          </>
        )}
      </Button>
    </>
  );
};
