import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import React from 'react';

interface DefaultCellProps<CellData> {
  data?: CellData[keyof CellData] | number | string;
  label?: string;
}

const VerticalCell = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
const Cell = styled.div<{ disableItem?: boolean }>`
  display: flex;
  align-items: center;
  // width: 10%;
  opacity: ${({ disableItem }) => (disableItem ? '.5' : '')};
`;
const Label = styled.div`
  font-size: 1rem;
  line-height: 2rem;
  color: #7c7c7c;
`;
const Value = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
  white-space: nowrap;
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Wrapper = styled.div<{ highlighted?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border: ${({ highlighted }) =>
    highlighted ? '1px solid #3c6ef6' : '1px solid #E5E5E5'};
  border-radius: 0.5rem;
  padding: 1rem 1rem 1.1rem 2.5rem;
  margin-bottom: 1rem;

  > div {
    flex: 1;
  }
  > div:last-of-type {
    // justify-content: end;
  }
  && > div button {
    margin: 0 0.3rem;
    border-color: #e5e5e5;
    transition: all 0.2s ease-in;
  }
  && > div button:first-of-type {
    font-size: 1rem;
    height: 2.6rem;
    color: #919191;
    font-weight: 300;

    :hover {
      color: #950000;
    }
  }
  && > div button:last-of-type {
    color: #00811c;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0px 15px;

    > span {
      margin-top: 1px;
    }
  }
`;

export const DefaultCell = <CellData extends {}>({
  data,
  label,
}: DefaultCellProps<CellData>) => {
  return (
    <VerticalCell>
      <Label>{label}</Label>
      <Value>{data}</Value>
    </VerticalCell>
  );
};

export interface CellConfig<RowData> {
  name: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}

export interface PlxListProps<RowData> {
  data: RowData;
  config: CellConfig<RowData>[];
  highlighted?: boolean;
  disableItem?: boolean;
}

export const PlxListItem = <CellData extends {}>({
  config,
  data,
  highlighted,
  disableItem,
}: PlxListProps<CellData> & { children?: ReactNode }) => {
  return (
    <Wrapper highlighted={highlighted}>
      {config.map(({ name, renderCell, propKey }, index) => {
        const key = propKey ? String(propKey) : index;
        return (
          <React.Fragment key={key}>
            <Cell key={key} disableItem={disableItem}>
              {renderCell ? (
                renderCell(data, key)
              ) : (
                <DefaultCell
                  data={propKey ? data[propKey] : Math.random()}
                  label={name}
                />
              )}
            </Cell>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};
