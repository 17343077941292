import React, { FC } from 'react';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { IThemed, TextSizes } from '_Styles/types';
import { PlxText } from '_Components/Text/PlxText';

const Wrapper = styled.div<IThemed>`
  margin: ${({ theme }): string => theme.spacing(2, 0)};
  display: flex;
  flex-direction: column;
  /* width: 100%; */
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;
const StyledText = styled(PlxText)<IThemed>`
  color: black;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  padding-left: 1rem;
`;

const StyledPageTitle = styled(StyledText)<IThemed>`
  font-size: 2.75rem;
  line-height: 5rem;
`;
const StyledBreadcrumbs = styled(PlxText)<IThemed>`
  font-size: 1.2rem;
  line-height: 3.5rem;
  padding-left: 1rem;
`;

interface IPageWrapperProps extends ICommonProps {
  parent: string;
  page: string;
  title: string;
}

export const BreadCrumb: FC<IPageWrapperProps> = ({
  children,
  parent,
  page,
  title,
}) => {
  return (
    <Wrapper>
      <StyledBreadcrumbs>
        {parent} / {page}
      </StyledBreadcrumbs>
      <StyledPageTitle size={TextSizes.L}>{title}</StyledPageTitle>
      {children}
    </Wrapper>
  );
};
