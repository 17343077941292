import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '../../../../../../_Components/Buttons/PlxButton/PlxButtonX';
import { useHistory } from 'react-router-dom';
import { TabPageHeader } from './../../utils';

const Wrapper = styled.div`
  width: 100%;
  /* height: 8rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 1rem; */
`;
export const DistributionPartnerDashboardHeader: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCompleteProfileInitialization = () => {
    history.push('/home/finfluencer/channel/add-channel');
  };

  return (
    <Wrapper>
      <TabPageHeader name={t('finfluencer.dashboard.label')} />
      <PlxButtonX
        testId="add-channel-button"
        variant2
        label={t('finfluencer.dashboard.welcomePage.addChannel')}
        onClick={handleCompleteProfileInitialization}
      />
    </Wrapper>
  );
};
