import { ISpectrumCampaign } from '_State/Spectrum/types';
import { IThriveCampaign } from '_State/Thrive/types';
import { createContext } from 'react';

export interface IDashboardContext {
  spectrumLink: string;
  thriveLink: string;
  spectrumLoading: boolean;
  thriveLoading: boolean;
  spectrumCampaigns: ISpectrumCampaign[];
  thriveCampaigns: IThriveCampaign[];
}

export const defaultContext = {
  spectrumLink: '',
  thriveLink: '',
  spectrumLoading: false,
  thriveLoading: false,
  spectrumCampaigns: [],
  thriveCampaigns: [],
};

export const DashboardContext = createContext<IDashboardContext>(
  defaultContext
);
