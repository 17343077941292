import React, { FC } from 'react';
import { ReactComponent as InfoIcon } from '_Components/PlxTooltip/Info.svg';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import { ICommonProps } from '_Types/props';

interface IProps extends ICommonProps {
  tooltipText: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: '31rem',
    },
  })
);

export const PlxTooltip: FC<IProps> = ({ tooltipText, className }) => {
  const classes = useStyles();
  return (
    <Tooltip
      className={className}
      title={tooltipText}
      placement="top"
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <div>
        <InfoIcon />
      </div>
    </Tooltip>
  );
};
