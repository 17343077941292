import { CellConfig } from '_Components/PlxList/PlxList';
import { IDistributionPartnerOpportunityDTO } from '_State/DistributionPartner/distributionPartner.types';
import React, { ReactElement } from 'react';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
// import {
//   ButtonVariant,
//   PlxButton,
// } from '_Components/Buttons/PlxButton/PlxButton';
import { DefaultCell } from '_Components/PlxList/PlxListItem';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { formatDate } from '_Components/DateFormat/DateFormat';

export const createListConfig = (
  acceptOpportunity: (opportunityId: number) => void,
  rejectOpportunity: (opportunityId: number) => void,
  openDetails: (
    campaignId: number,
    opportunityId: number,
    channelType: string
  ) => void
): CellConfig<IDistributionPartnerOpportunityDTO>[] => {
  return [
    {
      name: 'Channel Name',
      propKey: 'channelName',
    },
    {
      name: 'Channel Type',
      renderCell({
        channelType,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <DefaultCell
            label={'Channel Type'}
            data={_.startCase(channelType.toLowerCase())}
          />
        );
      },
    },
    {
      name: 'Earning Opportunity',
      renderCell({ offer }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <DefaultCell
            label={'Earning Opportunity'}
            data={offer ? `$ ${offer}.00` : '-'}
          />
        );
      },
    },
    {
      name: 'Date Due',
      renderCell({
        postDue,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <DefaultCell label={'Date Due'} data={formatDate(postDue)} />;
      },
    },
    {
      name: 'Campaign Information',
      renderCell({
        channelType,
        campaignId,
        id,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <PlxLinkTextButton
            label={'Campaign details'}
            onClick={() => {
              console.log(channelType);
              openDetails(campaignId, id, channelType);
            }}
          />
        );
      },
    },
    {
      name: '',
      renderCell({ id }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <>
            <Button
              variant="outlined"
              color="secondary"
              size={'small'}
              onClick={() => rejectOpportunity(id)}
            >
              REJECT
            </Button>
            <Button
              size={'small'}
              // label={'Accept'}
              variant="outlined"
              onClick={() => acceptOpportunity(id)}
            >
              ACCEPT
            </Button>
          </>
        );
      },
    },
  ];
};

export const xemCreateListConfig = (
  openDetails: (
    campaignId: number,
    opportunityId: number,
    channelType: string
  ) => void
): CellConfig<IDistributionPartnerOpportunityDTO>[] => {
  return [
    {
      name: 'Channel Name',
      propKey: 'channelName',
    },
    {
      name: 'Channel Type',
      renderCell({
        channelType,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <DefaultCell
            label={'Channel Type'}
            data={_.startCase(channelType.toLowerCase())}
          />
        );
      },
    },
    {
      name: 'Earning Opportunity',
      renderCell({ offer }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <DefaultCell
            label={'Earning Opportunity'}
            data={offer ? `$ ${offer}.00` : '-'}
          />
        );
      },
    },
    {
      name: 'Date Due',
      renderCell({
        postDue,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <DefaultCell label={'Date Due'} data={formatDate(postDue)} />;
      },
    },
    {
      name: 'Campaign Information',
      renderCell({
        channelType,
        campaignId,
        id,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <PlxLinkTextButton
            label={'Campaign details'}
            onClick={() => {
              openDetails(campaignId, id, channelType);
            }}
          />
        );
      },
    },
    {
      name: 'Status',
      renderCell({ status }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <DefaultCell
            label={'Status'}
            data={_.startCase(status.toLowerCase())}
          />
        );
      },
    },
  ];
};
