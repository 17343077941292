import React from 'react';

interface ICampaignCircle {
  color: string;
}

export const CampaignCircle = ({ color }: ICampaignCircle) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="4" cy="4" r="4" fill={color} />
    </svg>
  );
};

export default CampaignCircle;
