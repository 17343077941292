import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  ISimpleDialogProps,
  PlxSimpleDialog,
} from '_Components/Dialogs/PlxSimpleDialog';
import { IThemed } from '_Styles/types';
import { useTranslation } from 'react-i18next';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { ReactComponent as ConfirmationIcon } from '_Styles/images/_Icons/Confirmation.svg';

const Title = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 2.1rem;
  font-weight: bold;
  margin: 3rem auto 0 auto;
`;

const Buttons = styled.div`
  margin: 5rem auto 3rem auto;
`;

const StyledButton = styled(PlxButton)`
  && {
    width: 12rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  color: #767676;
  font-size: 1.6rem;
  margin: 2rem auto;
  text-align: center;
`;

export const SuccessRequestDialog: FC<ISimpleDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <PlxSimpleDialog open={open} onClose={onClose}>
      <Content>
        <ConfirmationIcon />
        <Title>{t('thrive.dashboard.requestSubmitted')}</Title>
        <Text>{t('thrive.dashboard.submitText')}</Text>
        <Buttons>
          <StyledButton onClick={onClose} label={t('thrive.dashboard.close')} />
        </Buttons>
      </Content>
    </PlxSimpleDialog>
  );
};
