import { Dialog } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

interface IProps {
  open: boolean;
  onClose?: () => void;
  image?: string;
}

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
`;

export const PlxImageDialog: FunctionComponent<IProps> = ({
  open,
  onClose,
  image,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <ImageContainer src={image} />
    </Dialog>
  );
};
