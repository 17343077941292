import { PlxLoader } from '_Components/PlxLoader';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createUsersTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/utils';
import { getClients, updateClient } from '_State/User/Api/admin-user.api';
import {
  IAdminUsersQueryParams,
  IEditClientForm,
  IUserDTO,
} from '_State/User/user.types';
import { SORTING_OPTIONS } from '_Types/adminTable';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { EditForm } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/Forms/EditForm';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { useHistory } from 'react-router-dom';

const INITIAL_PARAMS: IAdminUsersQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageSize: 1000,
};
const sortingOptions = SORTING_OPTIONS;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledInput = styled(PlxInput)`
  margin-left: auto;
  width: 31rem;
  margin-right: 3.5rem;
`;

export const ClientsTable: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IUserDTO[]>([]);
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);
  const [sorting, setSorting] = useState<string>('DESC');
  const [searchedPhrase, setSearchedPhrase] = useState<string>();
  const [editedClient, setEditedClient] = useState<IUserDTO>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const fetchData = () => {
    return getClients(searchParams)
      .then(({ content }) => {
        setData(content);
      })
      .catch((error) =>
        error.response.json().then(() => {
          setLoading(false);
        })
      );
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setSearchParams({
      ...searchParams,
      sortDirection: event.target.value as string,
    });
  };

  const onSearchedPhraseChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSearchedPhrase(event.target.value as string);
    setSearchParams({
      ...searchParams,
      searchedPhrase: event.target.value as string,
    });
  };

  const openEditDialog = (client: IUserDTO) => {
    setEditedClient(client);
    setEditDialogOpen(true);
  };

  const goToOrders = (client: IUserDTO) => {
    history.push(`/home/admin/users/orders/${client.id}`);
  };
  const goToAnalytics = (client: IUserDTO) => {
    history.push(`/home/admin/spectrum-analytics?userId=${client.id}`);
  };
  const config = useMemo(() => {
    return createUsersTableConfig(
      openEditDialog,
      goToOrders,
      goToAnalytics
    ) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleEdit = (form: IEditClientForm) => {
    setApiError(undefined);
    return updateClient(form, editedClient?.id).then(
      () => {
        setLoading(true);
        setEditDialogOpen(false);
        fetchData().then(() => {
          setLoading(false);
        });
      },
      (error) => {
        error.response.json().then((err: any) => {
          const errors = err.fieldErrors.map((v: any) => v.defaultMessage);
          console.log(errors);
          setApiError(errors);
        });
      }
    );
  };

  const handleDelete = () => {
    return;
  };

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <PlxCustomTable data={data} config={config} fullHeight key={'clients'}>
          <PlxTableToolbar label={'Clients'}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('admin.users.search')}
              value={searchedPhrase}
              onChange={onSearchedPhraseChange}
              fullWidth={false}
            />
          </PlxTableToolbar>
        </PlxCustomTable>
      )}
      <PlxDialog
        title={'Edit user'}
        open={editDialogOpen}
        onClose={closeEditDialog}
      >
        <EditForm
          client={editedClient}
          onSubmit={handleEdit}
          onClose={closeEditDialog}
          onDelete={handleDelete}
          error={apiError}
        />
      </PlxDialog>
    </>
  );
};
