import styled from '@emotion/styled';

export const ChannelWrapper = styled.div<{ highlight?: boolean }>`
  border: ${({ highlight }) =>
    highlight ? '1px solid #a7a7a7' : '1px solid #e5e5e5'};
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem 0 1.5rem 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;
export const Title = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const ChannelSection = styled.div`
  flex: 1;
`;
export const DateSection = styled.div`
  flex: 1;
`;
export const LinkContainer = styled.div`
  font-size: 0.8rem;
  color: #3c6ef6;
  flex: 1;
`;
export const TableHead = styled.div`
  width: 100%;
  background: #f8f9fb;
  border-radius: 10px;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 2rem;
  ul {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    margin-left: 2rem;
  }
  li {
    padding: 2rem 0 2rem;
  }
`;
