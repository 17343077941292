import React, { FC, useEffect, useState } from 'react';
import { IUserDTO, IEditClientForm } from '_State/User/user.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { PlxCheckbox } from '_Components/PlxCheckbox/PlxCheckbox';
import styled from '@emotion/styled';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { Button } from '@material-ui/core';
import { FormMassage } from '_Components/Forms/FormMassage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5.5rem;
  margin-top: 0;
  margin-bottom: 4rem;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

const RedButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;

const FormWrapper = styled.div``;

interface IProps {
  onSubmit: (form: IEditClientForm) => Promise<any>;
  onClose: () => void;
  onDelete: (id?: number) => void;
  client?: IUserDTO;
  error?: string;
}

const INITIAL_VALUES: IEditClientForm = {
  fullName: '',
  companyName: '',
  email: '',
  isActivated: true,
};

const REQUIRED_FIELDS = ['fullName', 'companyName', 'email'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const EditForm: FC<IProps> = ({
  client,
  onSubmit,
  onClose,
  onDelete,
  error,
}) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        if (form.email === client?.email) {
          onSubmit({ ...form, email: undefined }).then(() =>
            setIsProcessing(false)
          );
        } else {
          onSubmit(form).then(() => setIsProcessing(false));
        }
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (client?: IUserDTO) => {
    formik.setValues({
      fullName: client?.fullName,
      email: client?.email,
      companyName: client?.companyName,
      isActivated: client?.activated,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleDelete = () => {
    onDelete(client?.id);
  };

  useEffect(() => {
    fillForm(client);
    // eslint-disable-next-line
  }, [client]);

  return (
    <Wrapper>
      <FormWrapper>
        <InputSection
          title={t('admin.users.clientEdit.fullName')}
          placeholder={t('admin.users.clientEdit.fullNamePlaceholder')}
          required
          value={formik.values.fullName}
          fieldName={'fullName'}
          handleChange={formik.handleChange}
        />
        <FieldErrorMessage message={formik.errors.fullName} />
        <InputSection
          title={t('admin.users.clientEdit.email')}
          placeholder={t('admin.users.clientEdit.emailPlaceholder')}
          required
          value={formik.values.email}
          fieldName={'email'}
          handleChange={formik.handleChange}
        />
        <FieldErrorMessage message={formik.errors.email} />
        <InputSection
          title={t('admin.users.clientEdit.companyName')}
          placeholder={t('admin.users.clientEdit.companyNamePlaceholder')}
          required
          value={formik.values.companyName}
          fieldName={'companyName'}
          handleChange={formik.handleChange}
        />
        <FieldErrorMessage message={formik.errors.companyName} />
        <PlxCheckbox
          value={formik.values.isActivated}
          label={'Client account activated'}
          name={'isActivated'}
          onChange={formik.handleChange}
        />
        <FormMassage message={error ? error : ''} />
      </FormWrapper>
      <ButtonsWrapper>
        <RedButton
          color={'secondary'}
          variant={'outlined'}
          disabled
          onClick={handleDelete}
        >
          Delete
        </RedButton>
        <PlxButton
          label={t('admin.users.clientEdit.close')}
          onClick={handleClose}
          variant={ButtonVariant.Outlined}
        />
        <PlxButton
          label={t('admin.users.clientEdit.save')}
          onClick={handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
