import React, { FC, useEffect, useRef } from 'react';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1000px;
  /* min-height: 100%; */
`;
export const FormWrapper: FC<ICommonProps> = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [history.location]);

  const wrapperRef = useRef<any>();

  return <Wrapper ref={wrapperRef}>{children}</Wrapper>;
};
