import React, { ReactElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Grid, Theme } from '@material-ui/core';
import {
  campaignTagFilters,
  channelTypeFilters,
  IFilterItem,
  IFilters,
  moreFilters,
} from './utils';
import DateTimeFilter, {
  ICalendarDate,
} from '../SpectrumDashboard/_Component/Filters/DateTimeFilter/DateTimeFilter';
import SpectrumButton from '../SpectrumDashboard/_Component/SpectrumButton/SpectrumButton';
import AvgEngagementRateAndTrendGraph from './_Components/AvgEngagementRateAndTrendGraph/AvgEngagementRateAndTrendGraph';
import TopHashtagsGraph from './_Components/TopHastagsGraph/TopHashtagsGraph';
// import EngagementBenchmarkGraph from './_Components/EngagementBenchmarkGraph/EngagementBenchmarkGraph';
import EngagementBenchmarkGraphNo2 from './_Components/EngagementBenchmarkGraphNo2/EngagementBenchmarkGraphNo2';
import CampaignEngagementByChannelGraph from './_Components/CampaignEngagementByChannelGraph/CampaignEngagementByChannelGraph';
// import MarketPerformanceGraph from './_Components/MarketPerformanceGraph/MarketPerformanceGraph';
// import EngagementRatesByPostDate from './_Components/EngagementRatesByPostDate/EngagementRatesByPostDate';
import { CheckboxFilter } from '_Components/Filters/CheckboxFilter/CheckboxFilter';

import {
  BreadCrumb,
  HeaderContainer,
} from '_Routes/Home/_Components/Breadcrumb/BreadCrumb';
import { useHistory } from 'react-router-dom';
import { initialCalendarDate } from '../SpectrumDashboard/utils';
import { getSpectrumCampaignsOptions } from '_State/User/Api/user.api';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { serilizeCampaignIdsFilter } from './helpers';
import { isAdminTypeRole } from '_Routes/Home/NavigationPanel/NavigationLinks';
import { MultiSelectAnalytics } from '../SpectrumDashboard/SpectrumDashboard';
import { isAgencyTypeRole } from '_Routes/Home/Home';
export interface IThemed {
  theme: Theme;
}

const SpectrumEngagementWrapper = styled.div`
  margin: 0 auto;
  width: calc(100% - 8.5rem);
  .MuiGrid-spacing-xs-2 {
    width: 100% !important;
  }
`;

const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem;
  background-color: ${({ theme }): string => theme.palette.background.default};
`;

const StyledFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
`;

const FilterWrap = styled.div`
  display: flex;
`;
const LaunchWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const SpectrumEngagement = (): ReactElement => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IFilters>({
    campaignIds: [],
    campaign: campaignTagFilters,
    campaignCompare: campaignTagFilters,
    channelType: channelTypeFilters,
    date: initialCalendarDate,
    moreFilters,
  });
  const history = useHistory();
  const isAdmin = useSelector(isAdminTypeRole);
  const isAgency = useSelector(isAgencyTypeRole);
  let userId = useSelector((state: IState) => state.user.user?.id);

  if (isAdmin) {
    userId = Number(new URLSearchParams(window.location.search).get('userId'));
  }

  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const handleProgramLaunch = (): void => {
    history.push(
      isAgency
        ? '/home/agency/spectrum-campaign/payment'
        : '/home/client/spectrum-campaign/payment'
    );
  };

  const updateFilters = (
    type: string,
    items: IFilterItem[] | ICalendarDate
  ): void => {
    setFilters((f: IFilters) => {
      return { ...f, [type]: items };
    });
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userId) return;

      setFilterLoading(true);

      try {
        const { content: campaignIds } = await getSpectrumCampaignsOptions(
          userId,
          {
            startDate: filters.date.selection?.startDate,
            endDate: filters.date.selection?.endDate,
          }
        );

        updateFilters('campaignIds', serilizeCampaignIdsFilter(campaignIds));
      } catch (error) {
        console.log(error);
      } finally {
        setFilterLoading(false);
      }
    })();
  }, [filters.date, userId]);

  return (
    <SpectrumEngagementWrapper>
      <Wrapper>
        <HeaderContainer>
          <StyledFilters>
            <BreadCrumb
              parent={t('navBar.spectrum')}
              page={t('navBar.engagement')}
              title={t('navBar.engagement')}
            />
            <FilterWrap>
              <CheckboxFilter
                text={t('spectrum.filterLabels.campaignIds')}
                items={filters.campaignIds}
                onChange={(f: IFilterItem[]): void => {
                  updateFilters('campaignIds', f);
                }}
                onReset={(f: IFilterItem[]): void => {
                  updateFilters('campaignIds', f);
                }}
                loading={filterLoading}
                maxSelected={3}
              />
              {/* <CheckboxFilterCopy
                text={t('spectrum.filterLabels.campaignTag')}
                items={filters.campaign}
                setFilters={(f: IFilterItem[]): void => {
                  updateFilters('campaign', f);
                }}
                compareItems={filters.campaignCompare}
                setCompareFilters={(f: IFilterItem[]): void => {
                  updateFilters('campaignCompare', f);
                }}
              /> */}
              {/* <CheckboxFilter
                text={t('spectrum.filterLabels.channelType')}
                items={channelTypeFilters}
              /> */}
              <DateTimeFilter
                calendarDate={filters.date}
                setCalendarDate={(date: ICalendarDate): void => {
                  updateFilters('date', date);
                }}
                initialCalendarDate={initialCalendarDate}
              />
              {/* <MoreFilters /> */}
              {/* <ClearFiltersButton
                onClick={(): void => console.log('clicked')}
              /> */}
            </FilterWrap>
          </StyledFilters>
          {!isAdmin && (
            <LaunchWrap>
              <SpectrumButton
                text={t('spectrum.overview.launchProgram')}
                borderColor="#9D05B0"
                fontSize={16}
                handleProgramLaunch={handleProgramLaunch}
              />
              {/* <SpectrumButton text={t('spectrum.overview.export')} /> */}
            </LaunchWrap>
          )}
          {isAdmin && userId && (
            <MultiSelectAnalytics userId={userId} value={'engagement'} />
          )}
        </HeaderContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <AvgEngagementRateAndTrendGraph filters={filters} userId={userId} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopHashtagsGraph filters={filters} userId={userId} />
          </Grid>
          {/* <Grid item xs={12}>
            <EngagementBenchmarkGraph />
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <EngagementBenchmarkGraphNo2 filters={filters} userId={userId} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CampaignEngagementByChannelGraph
              filters={filters}
              userId={userId}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <MarketPerformanceGraph />
          </Grid> */}
          {/* <Grid item xs={12}>
            <EngagementRatesByPostDate />
          </Grid> */}
        </Grid>
      </Wrapper>
    </SpectrumEngagementWrapper>
  );
};

export default SpectrumEngagement;
