import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { DistributionPartnerReferralsTable } from './DistributionPartnerReferralsTable';

export const Body = styled.div<{
  isExpanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
`;

export const DistributionPartnerReferrals: FC = () => {
  const { navBarExpanded } = useContext(NavigationContext);

  return (
    <Body isExpanded={navBarExpanded}>
      <DistributionPartnerReferralsTable />
    </Body>
  );
};
