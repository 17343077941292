import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { FC, useReducer } from 'react';
// import { useTranslation } from 'react-i18next';
import { PlxInputAddChannelX } from '_Components/PlxInput/PlxInputAddChannelX';
import AddIcon from '@material-ui/icons/Add';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { CheckingMust, countryList } from './utils';
import {
  Agreement,
  BtnWrap,
  CardInfoWrapper,
  CardNumber,
  CountryWrap,
  CreditCardFormWrap,
  HorizontalDivider,
  SubTableHeader,
} from './utils';

const initialValues = {
  country: '',
  zip: '',
  routingNumber: '',
  accountNumber: '',
  institutionNumber: '',
  transitNumber: '',
  UkSortCode: '',
  bankName: '',
  branchNameNumber: '',
};
export const reducer = (state: any, action: any) => {
  // console.log(action);
  return { ...state, [action.input]: action.value };
};

export const FinfluencerWalletBankAccForm: FC = () => {
  // const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialValues);
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      console.log(state);
    },
    validate: () => {},
  });
  const handleChange = (e: any) => {
    const action = {
      input: e.target.name,
      value: e.target.value,
    };
    dispatch(action);
  };

  return (
    <CreditCardFormWrap>
      <form onSubmit={formik.handleSubmit}>
        <SubTableHeader>Country & Zip/Postal Code</SubTableHeader>
        <CardInfoWrapper>
          <CardNumber>
            <CountryWrap>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  name="country"
                  value={state.country}
                  onChange={handleChange}
                >
                  {countryList.map(({ name }, index) => {
                    return (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </CountryWrap>
          </CardNumber>
          <Divider />
          <HorizontalDivider>
            <PlxInputAddChannelX
              type="text"
              name="zip"
              value={state.zip}
              required
              placeholder="Postal Code"
              onChange={handleChange}
            />
          </HorizontalDivider>
        </CardInfoWrapper>
        {state.country === 'United States' ? (
          <>
            <SubTableHeader>Routing number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="routingNumber"
                  value={state.routingNumber}
                  placeholder="012347608"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Account number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="accountNumber"
                  value={state.accountNumber}
                  placeholder="12345678"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <CheckingMust>
              Your bank account must be a checking account.
            </CheckingMust>
          </>
        ) : state.country === 'Canada' ? (
          <>
            <SubTableHeader>Institution number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="institutionNumber"
                  value={state.institutionNumber}
                  placeholder="004"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Transit number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="transitNumber"
                  value={state.transitNumber}
                  placeholder="04934"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Account number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="accountNumber"
                  value={state.accountNumber}
                  placeholder="123456"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <CheckingMust>
              Your bank account must be a checking account.
            </CheckingMust>
          </>
        ) : state.country === 'United Kingdom' ? (
          <>
            <SubTableHeader>UK Sort code</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="UkSortCode"
                  value={state.UkSortCode}
                  placeholder="90-80-70"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Account number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="accountNumber"
                  value={state.accountNumber}
                  placeholder="12345678"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
          </>
        ) : (
          <>
            <SubTableHeader>Bank Name</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="bankName"
                  value={state.bankName}
                  placeholder="Name"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Branch Name/Number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="branchNameNumber"
                  value={state.branchNameNumber}
                  placeholder="Name"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
            <SubTableHeader>Account number</SubTableHeader>
            <CardInfoWrapper>
              <CardNumber>
                <PlxInputAddChannelX
                  type="tel"
                  name="accountNumber"
                  value={state.accountNumber}
                  placeholder="12345678"
                  required
                  onChange={handleChange}
                />
              </CardNumber>
            </CardInfoWrapper>
          </>
        )}

        <Agreement>
          By clicking Add, you agree to the Stripe Connected Account
          Agreement... to be confirmed if this is needed here
        </Agreement>
        <BtnWrap>
          <PlxButtonX inputType="submit">
            <AddIcon />
            ADD
          </PlxButtonX>
        </BtnWrap>
      </form>
    </CreditCardFormWrap>
  );
};
