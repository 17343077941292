import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { IThemed } from '_Styles/types';
import { ThriveDashboardHeader } from '../../_Components/ThriveDashboardHeader';
import { ThriveCampaignsTable } from './_Components/ThriveCampaingsTable/ThriveCampaignsTable';
import { ThriveDashboardStatistics } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Components/ThriveDashboardStatistics/ThriveDashboardStatistics';

const ThriveDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-color: ${({ theme }) => theme.palette.background.default};
`;
export const ThriveDashboard: FC = () => {
  const history = useHistory();

  const handleProgramLaunch = () => {
    history.push('/home/thrive-campaign/program');
  };
  return (
    <ThriveDashboardWrapper>
      <ThriveDashboardHeader onProgramLaunch={handleProgramLaunch} />
      <Wrapper>
        <ThriveDashboardStatistics />
        <ThriveCampaignsTable />
      </Wrapper>
    </ThriveDashboardWrapper>
  );
};
