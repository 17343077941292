import React, { ChangeEvent, FC } from 'react';
import { countryList } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerWallet/utils';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import styled from '@emotion/styled';

export const CountryWrap = styled.div`
  padding: 3px 20px;
  border: 1px solid #0000003b;
  border-radius: 50px;
  /* margin-top: 1rem; */
  max-width: 32rem;
  > div > div {
    margin-top: 0;
  }
  label {
    transform: translate(0, 8px) scale(1);
  }
  input,
  div::before,
  div::after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiSelect-select.MuiSelect-select {
    background: none;
  }
  .MuiInputLabel-shrink {
    transform: none;
    display: none;
  }
`;

interface CountryProps {
  value: string;
  handleCountryChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  testId?: string;
  maxWidth?: string;
}
export const XemInputCountry: FC<CountryProps> = ({
  value,
  handleCountryChange,
  testId,
  maxWidth,
}) => {
  return (
    <>
      <CountryWrap style={{ maxWidth: maxWidth ? maxWidth : undefined }}>
        <FormControl fullWidth data-test-id={testId}>
          <InputLabel id="demo-simple-select-label">Country *</InputLabel>
          <Select
            name="country"
            value={value}
            onChange={handleCountryChange}
            label="Country"
            // required
          >
            {countryList.map(({ name }, index) => {
              return (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </CountryWrap>
    </>
  );
};
