import React, { FC } from 'react';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import { ICommonProps } from '_Types/props';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';

const PlxCheckboxBody = styled.div`
  display: inline-flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  color: #61657e;
  line-height: 1.8rem;
`;

interface IProps extends ICommonProps {
  label?: string;
  name?: string;
  value?: boolean;
  indeterminate?: boolean;
  required?: boolean;
  onChange?: SwitchBaseProps['onChange'];
  isDisabled?: boolean;
  testId?: string;
}

export const PlxCheckbox: FC<IProps> = ({
  value,
  label,
  onChange,
  isDisabled,
  required,
  name,
  className,
  children,
  indeterminate,
  testId,
}) => {
  return (
    <PlxCheckboxBody className={className} data-test-id={testId}>
      <Checkbox
        indeterminate={indeterminate}
        color="primary"
        required={required}
        checked={value}
        disabled={isDisabled}
        onChange={onChange}
        name={name}
      />
      <TextWrapper>
        <span>{label}</span>
        {children}
      </TextWrapper>
    </PlxCheckboxBody>
  );
};
