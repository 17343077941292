/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { useTranslation } from 'react-i18next';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { createThriveLeadsTableConfig } from './utils';
import styled from '@emotion/styled';
import { IThriveLead, IThriveLeadsQueryParams } from '_State/Thrive/types';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import {
  getThriveLeads,
  getThriveLeadsCSV,
} from '_State/Thrive/Api/thrive.api';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';

interface IProps {
  className?: string;
  id?: string;
}

const INITIAL_PARAMS: IThriveLeadsQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'optInDate',
  geolocationList: [],
};

const StyledTable = styled(PlxCustomTable)`
  && {
    width: 100%;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 15rem;
  }
`;

const StyledButton = styled(PlxButton)`
  && {
    margin-left: auto;
    margin-right: 2rem;
  }
`;

const Wrapper = styled.div``;

export const ThriveLeadsTable: FC<IProps> = ({ className, id }) => {
  const { t } = useTranslation();
  const sortingOptions = [
    {
      id: 'ASC',
      name: t('campaignsTable.sort.asc'),
    },
    {
      id: 'DESC',
      name: t('campaignsTable.sort.desc'),
    },
  ];
  const geoLocationOptions = [
    {
      id: 'USA',
      name: 'USA',
    },
    {
      id: 'CANADA',
      name: 'Canada',
    },
    {
      id: 'EUROPE',
      name: 'Europe',
    },
  ];
  const [campaignId, setCampaignId] = useState(id);
  const [leads, setLeads] = useState<IThriveLead[]>([]);
  const [sorting, setSorting] = useState<string>('DESC');
  const [geoLocation, setGeoLocation] = useState<string[]>();
  const [params, setParams] = useState<IThriveLeadsQueryParams>(INITIAL_PARAMS);

  const fetchLeads = () => {
    if (campaignId) {
      getThriveLeads(campaignId, params).then(({ content }) => {
        if (content) {
          setLeads(content);
        }

        return content;
      });
    }
  };

  useEffect(() => {
    setCampaignId(id);
  }, [id]);

  useEffect(() => {
    fetchLeads();
  }, [params]);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onGeoLocationChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedGeoLocation = event.target.value as string[];
    setGeoLocation(selectedGeoLocation);
    setParams({ ...params, geolocationList: selectedGeoLocation });
  };

  const createDownloadCSV = (data: Blob) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', 'leads.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleExportCSV = () => {
    getThriveLeadsCSV(campaignId).then((r) => {
      createDownloadCSV(r);
    });
  };

  const config = useMemo(() => {
    return createThriveLeadsTableConfig() as CellConfig<{}>[];
  }, []);

  return (
    <Wrapper className={className}>
      {config && (
        <StyledTable
          config={config}
          data={leads}
          maxContentHeight={'calc(48rem - 64px)'}
        >
          <PlxTableToolbar label={t('thrive.leadsTable.leadsDatabase')}>
            <StyledSelect
              options={sortingOptions}
              label={t('campaignsTable.sort.label')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledMultipleSelect
              options={geoLocationOptions}
              label={t('thrive.leadsTable.geoLocation')}
              onChange={onGeoLocationChange}
              value={geoLocation}
            />
            <StyledButton
              size={'small'}
              variant={ButtonVariant.Outlined}
              onClick={handleExportCSV}
              label={t('thrive.leadsTable.exportCSV')}
            />
          </PlxTableToolbar>
        </StyledTable>
      )}
    </Wrapper>
  );
};
