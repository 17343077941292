import React, { FC } from 'react';
import { PlxDividedPanel } from '_Components/Panels/PlxDividedPanel/PlxDividedPanel';
import { createStyles, makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { PackageInfoContent } from '../PackageInfo/PackageInfoContent';
import { PackageInfoHeader } from '../PackageInfo/PackageInfoHeader';
import { PackageInfoPrice } from '../PackageInfo/PackageInfoPrice';
import { IPackageControlConfig } from './PackageControl.types';
import clsx from 'clsx';

const ControlWrapper = styled.div`
  border: 0.1rem solid #d5dde3;
  border-radius: 0.5rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-top: 1rem;
`;
const StyledPlxDividedPanel = styled(PlxDividedPanel)<IThemed>`
  height: 100%;
`;

const useButtonStyles = makeStyles(() =>
  createStyles({
    active: {
      boxShadow: '0 0 0 0.15rem #2a0042',
    },
  })
);

export const PackageControl: FC<IPackageControlConfig> = ({
  option,
  vertical,
  value,
  isChosen,
  disabled,
  onClick,
  isThrivePayment,
  type,
  savings,
}) => {
  const buttonStyles = useButtonStyles();
  const handleClick = (value: string) => {
    if (onClick) {
      onClick(value);
    }
  };
  return (
    <ControlWrapper
      onClick={() => handleClick(value)}
      className={clsx({
        [buttonStyles.active]: isChosen,
      })}
    >
      {!isThrivePayment && (
        <StyledPlxDividedPanel
          contentOne={<PackageInfoHeader header={option.header} />}
          contentTwo={
            <PackageInfoPrice value={value} type={type} savings={savings} />
          }
          contentThree={<PackageInfoContent properties={option.content} />}
          vertical={vertical}
        />
      )}
      {isThrivePayment && (
        <StyledPlxDividedPanel
          contentOne={<PackageInfoHeader header={option.header} />}
          contentThree={<PackageInfoContent properties={option.content} />}
          vertical={vertical}
        />
      )}
    </ControlWrapper>
  );
};
