import styled from '@emotion/styled';
import { createStyles, Switch, Theme, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

export const StyledAllTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 22,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#9D05B0',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#9D05B0',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 22 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const StyledToggle = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IProps {
  toggled: boolean;
  title: string;
  onChange: () => void;
}

export const ToggleSwitch: FC<IProps> = ({ toggled, onChange, title }) => {
  return (
    <StyledToggle>
      <StyledAllTitle>{title}</StyledAllTitle>
      <IOSSwitch
        checked={toggled}
        onChange={onChange}
        color="primary"
        name="checkedB"
      />
    </StyledToggle>
  );
};
