import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

interface IStyledEngagemenetGraphContainer {
  chartHeight: number;
}

interface IStyledCheckBox {
  checkboxColor?: string;
}

export const EngagementChartTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 16px;
`;

//prettier-ignore
export const StyledEngagemenetGraphContainer = styled.div<IStyledEngagemenetGraphContainer>`
  width: 100%;
  height: ${(props: IStyledEngagemenetGraphContainer) =>
  props.chartHeight + 110}px;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: 15px 20px 35px 20px;

  .recharts-surface {
    padding-top: 50px;
    overflow: visible;
  }
  .recharts-brush {
    transform: translateY(
      -${(props: IStyledEngagemenetGraphContainer) => props.chartHeight}px
    );
  }
  .recharts-wrapper{
    overflow: hidden;
  }

  rect {
    stroke: inherit !important;
    &.recharts-brush-slide {
      fill: #eecaf2 !important;
      stroke: #008b9c !important;
    }
  }

  g.recharts-layer.recharts-brush-traveller {
    rect {
      fill: #008b9c;
      stroke: #008b9c !important;
    }
  }
`;

export const StyledTooltip = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: column;
`;
export const TooltipElement = styled.div`
  padding: 5px;
`;

export const StyledCheckbox = styled(Checkbox)<IStyledCheckBox>`
  span {
    svg {
      color: ${(props: IStyledCheckBox) => props.checkboxColor};
    }
  }
`;
