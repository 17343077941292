import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import { ReactComponent as FacebookIcon } from '_Styles/images/_Icons/ChannelTypes/Facebook_1.svg';
import { ReactComponent as DiscordIcon } from '_Styles/images/_Icons/ChannelTypes/Discord_1.svg';
import { ReactComponent as TikTok } from '_Styles/images/_Icons/ChannelTypes/TikTok.svg';
import { ReactComponent as LinkedIcon } from '_Styles/images/_Icons/ChannelTypes/Linked_1.svg';
import { ReactComponent as TwitterIcon } from '_Styles/images/_Icons/ChannelTypes/Twitter_1.svg';
import { ReactComponent as ForumIcon } from '_Styles/images/_Icons/ChannelTypes/Forum_1.svg';
import { ReactComponent as RedditIcon } from '_Styles/images/_Icons/ChannelTypes/Reddit_1.svg';
import { ReactComponent as YoutubeIcon } from '_Styles/images/_Icons/ChannelTypes/YouTube_1.svg';
import { ReactComponent as Clubhouse } from '_Styles/images/_Icons/ChannelTypes/Clubhouse.svg';
import { ReactComponent as WhatsApp } from '_Styles/images/_Icons/ChannelTypes/Whatsapp.svg';
import { ReactComponent as PinterestIcon } from '_Styles/images/_Icons/ChannelTypes/Pinterest_1.svg';
import { ReactComponent as PodcastIcon } from '_Styles/images/_Icons/ChannelTypes/Podcast_1.svg';
import { ReactComponent as SlackIcon } from '_Styles/images/_Icons/ChannelTypes/Slack_1.svg';
import { ReactComponent as TwitchIcon } from '_Styles/images/_Icons/ChannelTypes/Twitch-1.svg';
import { ReactComponent as SnapchatIcon } from '_Styles/images/_Icons/ChannelTypes/Snapchat_1.svg';
import { ReactComponent as InstagramIcon } from '_Styles/images/_Icons/ChannelTypes/Instagram_1.svg';
import { ReactComponent as Telegram } from '_Styles/images/_Icons/ChannelTypes/Telegram.svg';
import React from 'react';
import {
  CategoryOfInterest,
  ChannelType,
  ChannelVisibility,
} from '_State/DistributionPartner/distributionPartner.enums';

export const channelTypeConfig: IIconButtonConfig[] = [
  {
    id: 'discord',
    label: 'Discord',
    icon: <DiscordIcon title="Discord" />,
    value: ChannelType[ChannelType.DISCORD],
    isChosen: false,
  },
  {
    id: 'facebook',
    label: 'Facebook',
    icon: <FacebookIcon title="Facebook" />,
    value: ChannelType[ChannelType.FACEBOOK],
    isChosen: false,
  },

  {
    id: 'forum',
    label: 'Forum',
    icon: <ForumIcon title="Forum" />,
    value: ChannelType[ChannelType.FORUM],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'instagram',
    label: 'Instagram',
    icon: <InstagramIcon title="Instagram" />,
    value: ChannelType[ChannelType.INSTAGRAM],
    isChosen: false,
  },
  {
    id: 'linkedin',
    label: 'Linkedin',
    icon: <LinkedIcon title="Linkedin" />,
    value: ChannelType[ChannelType.LINKEDIN],
    isChosen: false,
  },
  {
    id: 'podcast',
    label: 'Podcast',
    icon: <PodcastIcon title="Podcast" />,
    value: ChannelType[ChannelType.PODCAST],
    isChosen: false,
  },
  {
    id: 'pinterest',
    label: 'Pinterest',
    icon: <PinterestIcon title="Pinterest" />,
    value: ChannelType[ChannelType.PINTEREST],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'reddit',
    label: 'Reddit',
    icon: <RedditIcon title="Reddit" />,
    value: ChannelType[ChannelType.REDDIT],
    isChosen: false,
  },
  {
    id: 'slack',
    label: 'Slack',
    icon: <SlackIcon title="Slack" />,
    value: ChannelType[ChannelType.SLACK],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'snapchat',
    label: 'Snapchat',
    icon: <SnapchatIcon title="Snapchat" />,
    value: ChannelType[ChannelType.SNAPCHAT],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'x',
    label: 'X',
    icon: <TwitterIcon title="X" />,
    value: ChannelType[ChannelType.X],
    isChosen: false,
  },
  {
    id: 'tiktok',
    label: 'TikTok',
    icon: <TikTok title="TikTok" />,
    value: ChannelType[ChannelType.TIKTOK],
    isChosen: false,
  },
  {
    id: 'twitch',
    label: 'Twitch',
    icon: <TwitchIcon title="Twitch" />,
    value: ChannelType[ChannelType.TWITCH],
    isChosen: false,
  },
  {
    id: 'youtube',
    label: 'Youtube',
    icon: <YoutubeIcon title="Youtube" />,
    value: ChannelType[ChannelType.YOUTUBE],
    isChosen: false,
  },
  {
    id: 'whatsapp',
    label: 'WhatsApp',
    icon: <WhatsApp title="WhatsApp" />,
    value: ChannelType[ChannelType.WHATSAPP],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'clubhouse',
    label: 'Clubhouse',
    icon: <Clubhouse title="Clubhouse" />,
    value: ChannelType[ChannelType.CLUBHOUSE],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'telegram',
    label: 'Telegram',
    icon: <Telegram title="Telegram" />,
    value: ChannelType[ChannelType.TELEGRAM],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'facebook_user',
    label: 'Facebook_User',
    icon: <FacebookIcon title="Facebook" />,
    value: ChannelType[ChannelType.FACEBOOK_USER],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'facebook_page',
    label: 'Facebook_Page',
    icon: <FacebookIcon title="Facebook" />,
    value: ChannelType[ChannelType.FACEBOOK_PAGE],
    isChosen: false,
    disabled: true,
  },
  {
    id: 'facebook_group',
    label: 'Facebook_Group',
    icon: <FacebookIcon title="Facebook" />,
    value: ChannelType[ChannelType.FACEBOOK_GROUP],
    isChosen: false,
    disabled: true,
  },
];

export const channelVisibilityConfig: IIconButtonConfig[] = [
  {
    id: 'public',
    label: 'Public',
    value: ChannelVisibility[ChannelVisibility.PUBLIC],
    isChosen: false,
  },
  {
    id: 'private',
    label: 'Private',
    value: ChannelVisibility[ChannelVisibility.PRIVATE],
    isChosen: false,
  },
];

export const categoriesOfInterestConfig: IIconButtonConfig[] = [
  {
    id: 'investment',
    label: 'Investment',
    value: CategoryOfInterest[CategoryOfInterest.INVESTMENT],
    isChosen: false,
  },
  {
    id: 'dayTrading',
    label: 'Day Trading',
    value: CategoryOfInterest[CategoryOfInterest.DAY_TRADING],
    isChosen: false,
  },
  {
    id: 'financial',
    label: 'Financial',
    value: CategoryOfInterest[CategoryOfInterest.FINANCIAL],
    isChosen: false,
  },
  {
    id: 'retirement',
    label: 'Retirement',
    value: CategoryOfInterest[CategoryOfInterest.RETIREMENT],
    isChosen: false,
  },
  {
    id: 'realEstate',
    label: 'Real Estate',
    value: CategoryOfInterest[CategoryOfInterest.REAL_ESTATE],
    isChosen: false,
  },
];
