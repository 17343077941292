import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPaymentContext, IPaymentForm } from '_Types/paymentContext';
import { Payment } from '../../../../_Components/Sections/Payment/Payment';
import { IThriveCampaign } from '_State/Thrive/types';
import {
  createThrivePayment,
  getThriveDraft,
} from '_State/Thrive/Api/thrive.api';
import { getThriveLink } from '../../../Spectrum/utils';
import { ThrivePaymentContext } from './thrivePaymentContext';
import { IPackageControlConfig } from '../../../../_Components/Payments/PackageControl/PackageControl.types';
import { getThrivePaymentOptions } from './utils';

export const ThrivePayment: FC = () => {
  const [draft, setDraft] = useState<IThriveCampaign>();
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(
    false
  );
  const [failDialogOpened, setFailDialogOpened] = useState<boolean>(false);
  const [paymentConfig, setPaymentConfig] = useState<IPackageControlConfig[]>(
    []
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const isThrivePayment = true;
  const history = useHistory();
  const { t } = useTranslation();

  const context: IPaymentContext<IThriveCampaign> = {
    isThrivePayment,
    isProcessing,
    draft,
    packages: paymentConfig,
    handleBack: () => {
      history.push('/home/thrive-campaign/campaign');
    },
    closeSuccessDialog: () => {
      setSuccessDialogOpened(false);
    },
    closeFailDialog: () => {
      setFailDialogOpened(false);
    },
    failDialogOpened,
    successDialogOpened,
    handlePaymentConfirmed: () => {
      getThriveLink().then((link) => {
        if (link) {
          history.push(link);
        }
      });
    },
    paymentConfirmationDescription: t(
      'thrive.payment.paymentConfirmation.description'
    ),
    paymentFailureDescription: t('thrive.payment.paymentFailure.description'),
    onSubmit: (form: IPaymentForm) => {
      setIsProcessing(true);
      if (form.payment[0] === 'CHECK') {
        createThrivePayment(form, draft?.id)
          .then(
            () => {
              history.push('/home/thrive-campaign/payment/check');
            },
            (e) => {
              console.log(e);
              setFailDialogOpened(true);
            }
          )
          .finally(() => {
            setIsProcessing(false);
          });
      } else if (form.payment[0] === 'WIRE_TRANSFER') {
        createThrivePayment(form, draft?.id)
          .then(
            () => {
              history.push('/home/thrive-campaign/payment/wire');
            },
            (e) => {
              console.log(e);
              setFailDialogOpened(true);
            }
          )
          .finally(() => {
            setIsProcessing(false);
          });
      } else {
        createThrivePayment(form, draft?.id)
          .then((res) => {
            if (res.isSuccessful) {
              setSuccessDialogOpened(true);
            } else {
              setFailDialogOpened(true);
            }
          })
          .catch((e) => {
            setFailDialogOpened(true);
            console.log(e);
          })
          .finally(() => {
            setIsProcessing(false);
          });
      }
    },
  };

  useEffect(() => {
    getThriveDraft().then(
      (v) => {
        if (v) {
          setDraft(v);
          setPaymentConfig(getThrivePaymentOptions(v.budget.leadsPerProgram));
        }
      },
      () => null
    );
    // eslint-disable-next-line
  }, []);

  return (
    <ThrivePaymentContext.Provider value={context}>
      <Payment context={ThrivePaymentContext} />
    </ThrivePaymentContext.Provider>
  );
};
