import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core';
import { PlxText } from '_Components/Text/PlxText';
import { IThemed } from '_Styles/types';

export const MainWrapper = styled.div`
  display: flex;
  font-family: 'Quicksand';
  font-style: normal;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 72px);
`;

export const FormWrapper = styled.div`
  border: 1px #000 solid;
  border-radius: 20px;
  padding: 2rem;
  width: 560px;
  height: auto;
`;

export const StyledTextContainer = styled.div`
  font-size: 18px;
`;

export const StyledText = styled.h1`
  margin-bottom: 1.5rem;
`;

export const FormikWrapper = styled.div`
  padding: 2rem;
`;

export const StyledSelectClient = styled.div`
  margin: 2rem 5rem 3rem;
  display: block;
  justify-content: center;
`;

export const InputContainer = styled.div<IThemed>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0;
  > div > div {
    border-radius: 30px;
  }

  .MuiInputLabel-outlined {
    transform: translate(20px, 10px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 9px 20px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    transition-property: border-color;
    transition-duration: 1s;
    border-color: #000;
    border-width: 0.5px;
  }
  .MuiFormControl-root {
    max-width: 62rem;
  }
  .hideStepOne {
    display: none;
  }

  .plx-input {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
  .plx-checkbox {
    font-size: 1.1rem;
    line-height: 1.3rem;
    span {
      padding: 3px;
    }
    > div {
      line-height: 1rem;
    }
  }
`;

export const StyledTableButton = styled.button`
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background-color: #f8f8f8;
  cursor: pointer;
  color: #1b192c;
  border: none;
  border-radius: 6px;
  padding: 11px 16px;
  &:hover {
    background-color: #231f20;
    color: #fff;
  }
`;

export const TableButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 3rem;
  align-items: center;
  height: auto;
`;

export const TableStyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const useStyles = makeStyles({
  input: {
    border: '1px solid #008b9c',
    borderRadius: 24,
    fontFamily: 'Quicksand, sans-serif',
    backgroundColor: 'white',
    height: 32,
    width: '100%',
    padding: '0.625rem 1rem',
    alignItems: 'center',
    paddingRight: '40px',
    outlineColor: '#008b9c',
  },
});

export const SelectInputWrapper = styled.div`
  position: relative;
`;

export const StyledSelectToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
`;

export const ClientsPageWrap = styled.div`
  background: #f8f8f8;
  font-family: 'Quicksand';
  font-style: normal;
  min-width: 300px;
  margin: 0 auto;
  /* width: calc(100% - 8.5rem); */
  width: 100%;
  padding: 2rem 4rem;
`;

export const ClientFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 2rem;
  margin: auto;
`;

export const StyledButton = styled.button`
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 150px;
  height: 40px;
  color: #fff;
  background: #008b9c;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    color: #000;
    background-color: #afd7dd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid #afd7dd;
  }
`;
export const ErrMessage = styled.div<IThemed>`
  display: flex;
  align-items: center;
  justify-content: right;
  height: 1rem;
  padding: 0.75rem 5rem 0.25rem 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.error.main};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  justify-content: space-between;
  gap: 1rem;
  margin-left: auto;
`;
