import React, { FC } from 'react';
import { AdminSpectrumTable } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/AdminSpectrumTable';
import { AdminDashboardHeader } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/_Components/AdminDashboardHeader';

interface IProps {}

export const AdminSpectrumDashboard: FC<IProps> = () => {
  return (
    <>
      <AdminDashboardHeader />
      <AdminSpectrumTable />
    </>
  );
};
