import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxCustomTable } from '_Components/PlxCustomTable/PlxCustomTable';
import { mapCompaniesToSelect } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/utils';
import { ICompany } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/types';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { IAdminCampaignsQueryParams } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';
import { getCompanies } from '_State/User/Api/user.api';
import {
  PAYMENT_STATUSES,
  SORTING_OPTIONS,
  STATUS_OPTIONS,
} from '_Types/adminTable';
import { IThriveCampaign } from '_State/Thrive/types';
import { getThriveCampaignsAdmin } from '_State/Thrive/Api/thrive-admin.api';
import { createAdminThriveTableConfig } from './utils';

const INITIAL_PARAMS: IAdminCampaignsQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
};

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 15rem;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledTable = styled(PlxCustomTable)`
  && {
    width: 100%;
  }
`;

const AdminTableBody = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

const StyledInput = styled(PlxInput)`
  margin-left: auto;
  width: 31rem;
  margin-right: 3.5rem;
`;

export const AdminThriveTable: FC = () => {
  const { t } = useTranslation();
  const [config, setConfig] = useState<any>();
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [sorting, setSorting] = useState<string>('DESC');
  const [campaignId, setCampaignId] = useState<string>('');
  const [params, setParams] = useState<IAdminCampaignsQueryParams>(
    INITIAL_PARAMS
  );
  const [selectedCompany, setSelectedCompany] = useState<string[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [campaigns, setCampaigns] = useState<IThriveCampaign[]>([]);

  const statusOptions = STATUS_OPTIONS;
  const paymentStatuses = PAYMENT_STATUSES;
  const sortingOptions = SORTING_OPTIONS;

  useEffect(() => {
    getThriveCampaignsAdmin(params).then(({ content }) => {
      if (content) {
        getCompanies({ type: 'THRIVE' }).then((companiesResponse) => {
          setCampaigns(content);
          setCompanies(mapCompaniesToSelect(companiesResponse));
          setCampaigns(content);
          setConfig(createAdminThriveTableConfig());
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCompany(event.target.value as string[]);
    setParams({ ...params, companyNameList: event.target.value as string[] });
  };

  const onPaymentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPayment(event.target.value as string[]);
    setParams({ ...params, paymentStatusList: event.target.value as string[] });
  };

  const onStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as string[]);
    setParams({ ...params, statusList: event.target.value as string[] });
  };

  const onCampaignIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value as string)) {
      setCampaignId(event.target.value as string);
      setParams({ ...params, campaignId: event.target.value as string });
    }
  };

  useEffect(() => {
    getThriveCampaignsAdmin(params).then(({ content }) => {
      if (content) {
        setCampaigns(content);
      }
    });
  }, [params]);

  return (
    <AdminTableBody>
      {config && (
        <StyledTable config={config} data={campaigns} fullHeight={true}>
          <PlxTableToolbar label={t('campaignsTable.allCampaigns')}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledMultipleSelect
              options={companies}
              label={t('admin.dashboard.table.company')}
              onChange={onCompanyChange}
              value={selectedCompany}
            />
            <StyledMultipleSelect
              options={paymentStatuses}
              label={t('admin.dashboard.table.payment')}
              onChange={onPaymentChange}
              value={selectedPayment}
            />
            <StyledMultipleSelect
              options={statusOptions}
              label={t('admin.dashboard.table.status')}
              onChange={onStatusChange}
              value={selectedStatus}
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('admin.dashboard.table.search')}
              value={campaignId}
              onChange={onCampaignIdChange}
              fullWidth={false}
            />
          </PlxTableToolbar>
        </StyledTable>
      )}
    </AdminTableBody>
  );
};
