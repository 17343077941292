import React, { FC, useEffect, useState, useMemo } from 'react';
import { ChannelStatistics } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelStatistics';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { useTranslation } from 'react-i18next';
import {
  IAdminChannelQueryParams,
  IDistributionPartnerCampaignsQueryParams,
  IDistributionPartnerChannelDTO,
  IDistributionPartnerOpportunityDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { useParams } from 'react-router-dom';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { ChannelForm } from '_Routes/Home/_Components/ChannelForm';
import { OfferForm } from '_Routes/Home/_Components/OfferForm';
import { IDistributionPartnerAddChannelForm } from '_State/DistributionPartner/distributionPartner.types';
import { updateChannel } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { PlxSimpleDialog } from '_Components/Dialogs/PlxSimpleDialog';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { deleteChannel } from '_State/DistributionPartner/Api/distributionPartner.api';
import { FormMassage } from '_Components/Forms/FormMassage';
import { XemTable } from '_Components/XemTable/XemTable';
import { getOpportunitiesForChannel } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { CampaignDetails } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/CampaignDetails';
import { createAdminOpportunitiesTableConfig } from '../utils';
import {
  getChannelStatisticData,
  getChannels,
} from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { IDistributionPartnerChannelStatistics } from '_State/DistributionPartner/distributionPartner.types';
import {
  Body,
  ButtonWrapper,
  Content,
  Description,
  DialogWrapper,
  HeaderWrapper,
  Wrapper,
} from './utils';
import { Label } from '@material-ui/icons';
import { GridColumns } from '@material-ui/data-grid';

const INITIAL_PARAMS: IDistributionPartnerCampaignsQueryParams = {
  sortDirection: 'DESC',
  statusList: [],
};

export const Channel: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [editChannelDialogOpen, setEditChannelDialogOpen] = useState(false);
  const [editOfferDialogOpen, setEditOfferDialogOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [submit, setSubmit] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [channelsData, setChannelsData] = useState<
    IDistributionPartnerChannelDTO[]
  >([]);
  const [campaigns, setCampaigns] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [
    deleteConfirmationDialogOpen,
    setDeleteConfirmationDialogOpen,
  ] = useState(false);

  const [campaignDetailsIds, setCampaignDetailsIds] = useState<{
    campaignId?: number;
    opportunityId?: number;
  }>({
    campaignId: undefined,
    opportunityId: undefined,
  });
  const [statistics, setStatistics] = useState<
    IDistributionPartnerChannelStatistics
  >();

  const channelState = (id: string) => getChannelStatisticData(id);
  const openEditOfferDialog = () => {
    setEditOfferDialogOpen(true);
  };
  const openEditChannelDialog = () => {
    setEditChannelDialogOpen(true);
  };

  const openDetails = (campaignId: number, opportunityId: number) => {
    setCampaignDetailsIds({
      campaignId: campaignId,
      opportunityId: opportunityId,
    });
    setDetailsOpen(true);
  };
  const config = useMemo(() => {
    return createAdminOpportunitiesTableConfig(openDetails) as GridColumns;
    // eslint-disable-next-line
  }, []);

  const closeDetails = () => {
    setDetailsOpen(false);
  };
  const onChannelRemove = () => {
    if (id) {
      setLoading(true);
      deleteChannel(+id)
        .then(() => {
          setDeleteConfirmationDialogOpen(false);
        })
        .catch((error) => {
          error.response.json().then((error: any) => {
            setApiError(error.message);
            setLoading(false);
          });
        });
    }
  };

  const closeEditChannelDialog = () => {
    setEditChannelDialogOpen(false);
  };
  const closeEditOfferDialog = () => {
    setEditOfferDialogOpen(false);
  };

  const handleSubmit = (form: IDistributionPartnerAddChannelForm) => {
    setLoading(true);
    updateChannel(form, id).then(
      () => {
        closeEditChannelDialog();
        setLoading(false);
      },
      (error) => {
        error.response.json().then((error: any) => {
          setApiError(error.message);
          setLoading(false);
        });
      }
    );
  };

  const params: IAdminChannelQueryParams = {
    channelId: id,
  };

  const performSubmit = () => {
    setSubmit(!submit);
  };

  const handleDelete = () => {
    setDeleteConfirmationDialogOpen(true);
  };
  const handleBack = (): void => {
    setEditOfferDialogOpen(false);
  };

  const fetchOpportunities = (
    INITIAL_PARAMS: IDistributionPartnerCampaignsQueryParams
  ) => {
    setLoading(true);
    return getOpportunitiesForChannel(id, INITIAL_PARAMS).then(
      (opportunities) => {
        setCampaigns(opportunities);
        setLoading(false);
      }
    );
  };

  const fetchChannels = (): Promise<any> => {
    return getChannels(params).then(({ content }) => {
      setChannelsData(content);
    });
  };

  const existingOffer = Number(channelsData.map((a) => a.offer));
  const statusType = channelsData.map((a) => a.status).toString();

  useEffect(() => {
    fetchChannels().then();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    channelState(id).then((v) => {
      setStatistics(v);
    });
  }, [id]);
  useEffect(() => {
    fetchOpportunities(INITIAL_PARAMS).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [INITIAL_PARAMS]);

  return (
    <>
      <HeaderWrapper>
        <PlxDashboardHeader
          title={`Channel Details`}
          backLabel={t('channels.details.dashboard')}
          backLink={'/home/admin/channels'}
        >
          {statusType === 'ACTIVE' || statusType === 'CONNECTED' ? (
            <PlxButton
              label={'Edit Offer'}
              variant={ButtonVariant.Outlined}
              size={'medium'}
              onClick={openEditOfferDialog}
            />
          ) : null}
          <PlxButton
            label={'Edit Channel'}
            variant={ButtonVariant.Outlined}
            size={'medium'}
            onClick={openEditChannelDialog}
          />
        </PlxDashboardHeader>
      </HeaderWrapper>
      <Body>
        <ChannelStatistics statistics={statistics} />
        <XemTable
          header={config}
          data={campaigns}
          pageSize={10}
          loading={loading}
        />
        <PlxDialog
          title={'Edit Offer'}
          open={editOfferDialogOpen}
          onClose={closeEditOfferDialog}
          onSaveDisabled={loading}
          isOfferWindow={true}
        >
          <Wrapper>
            <OfferForm
              onBack={handleBack}
              channelId={Number(id)}
              closeWindowOffer={closeEditOfferDialog}
              existingOffer={existingOffer}
            />
            {apiError && <FormMassage message={apiError} />}
          </Wrapper>
        </PlxDialog>

        <PlxDialog
          title={'Edit channel'}
          open={editChannelDialogOpen}
          onClose={closeEditChannelDialog}
          closeButton
          closeButtonLabel={'Close'}
          onSave={performSubmit}
          saveButtonLabel={'Save'}
          onSaveDisabled={loading}
          onRedAction={handleDelete}
          onRedActionDisabled={loading}
          redActionLabel={'Delete'}
        >
          <Wrapper>
            <ChannelForm
              onSubmit={handleSubmit}
              id={id}
              performSubmit={submit}
              footerButtons={false}
            />
            {apiError && <FormMassage message={apiError} />}
          </Wrapper>
        </PlxDialog>
        <PlxSimpleDialog
          open={deleteConfirmationDialogOpen}
          onClose={() => setDeleteConfirmationDialogOpen(false)}
        >
          <DialogWrapper>
            <PlxPanel
              enableActions={false}
              content={
                <Content>
                  <Label>{t('channels.deleteChannel.title')}</Label>
                  <Description>
                    {t('channels.deleteChannel.description')}
                  </Description>
                  <ButtonWrapper>
                    <PlxButton
                      isDisabled={loading}
                      isProcessing={loading}
                      label={t('channels.deleteChannel.cancel')}
                      onClick={() => setDeleteConfirmationDialogOpen(false)}
                    />
                    <PlxButton
                      variant={ButtonVariant.Outlined}
                      isDisabled={loading}
                      isProcessing={loading}
                      label={t('channels.deleteChannel.delete')}
                      onClick={onChannelRemove}
                    />
                  </ButtonWrapper>
                  <FormMassage message={apiError} />
                </Content>
              }
            />
          </DialogWrapper>
        </PlxSimpleDialog>
        <PlxDialog
          title={t('channels.campaignDetails.title')}
          open={detailsOpen}
          onClose={closeDetails}
        >
          <CampaignDetails campaignDetailsIds={campaignDetailsIds} />
        </PlxDialog>
      </Body>
    </>
  );
};
