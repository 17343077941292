import { CampaignInfoTag } from '../../../../../../_Components/CampaignInfoTag';
import { arrayToSplittedString, stringArrayToEnum } from '_App/utils';
import React from 'react';
import { AgeGroup, IThriveCampaign } from '_State/Thrive/types';
import moment from 'moment';

export const configureThriveCampaignInfo = (
  t: any,
  openDialog: () => void,
  campaign?: IThriveCampaign
) => [
  {
    label: t('campaignInformation.campaignId'),
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('campaignInformation.dateOfCampaign'),
    renderContent: () => (
      <div>{campaign && moment(campaign.createdDate).format('MM/DD/yyyy')}</div>
    ),
  },
  {
    label: t('campaignInformation.tag'),
    renderContent: () => (
      <CampaignInfoTag
        tagName={campaign?.tag?.name}
        onDialogOpenDialog={openDialog}
      />
    ),
  },
  {
    label: t('campaignInformation.industry'),
    renderContent: () => <div>{campaign?.industry && campaign.industry}</div>,
  },
  {
    label: t('campaignInformation.targetGeoLocation'),
    renderContent: () => <div>{campaign?.geolocation.country}</div>,
  },
  {
    label: t('thrive.dashboard.ageGroup'),
    renderContent: () => (
      <div>
        {campaign?.ageGroups &&
          arrayToSplittedString(
            stringArrayToEnum(AgeGroup, campaign.ageGroups)
          )}
      </div>
    ),
  },
  {
    label: t('campaignInformation.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
  {
    label: t('thrive.dashboard.investorPresentation'),
    renderContent: () => (
      <>
        {campaign &&
          campaign.thrivePresentation &&
          campaign?.thrivePresentation.screenshotUrl && (
            <a href={campaign?.thrivePresentation?.screenshotUrl} download>
              {campaign?.thrivePresentation.fileName}
            </a>
          )}
      </>
    ),
  },
];
