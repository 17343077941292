import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { FormMassageX } from '_Components/Forms/FormMassageX';

export const StyledFormErrorMessage = styled(FormMassageX)`
  // margin-bottom: 1rem;
`;
export const CustomErrMess = styled.span`
  font-size: 1rem;
  color: red;
`;
export const InputContainer = styled.div<IThemed>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 2rem 0;
  > div > div {
    border-radius: 30px;
  }

  .MuiInputLabel-outlined {
    transform: translate(20px, 10px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 9px 20px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    transition-property: border-color;
    transition-duration: 1s;
    border-color: #000;
    border-width: 0.5px;
  }
  .MuiFormControl-root {
    max-width: 32rem;
  }
  .hideStepOne {
    display: none;
  }

  .plx-input {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
  .plx-checkbox {
    font-size: 1.1rem;
    line-height: 1.3rem;
    span {
      padding: 3px;
    }
    > div {
      line-height: 1rem;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  > div > div > div {
    border-radius: 30px;
  }

  .MuiInputLabel-outlined {
    transform: translate(20px, 10px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 9px 20px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    transition-property: border-color;
    transition-duration: 1s;
    border-color: #000;
    border-width: 0.5px;
  }
  .MuiFormControl-root {
    width: 90%;
  }
  .hideStepOne {
    display: none;
  }
`;

export const GoBack = styled.span`
  font-size: 1.2rem;
  display: flex;
  padding-right: 3rem;
  justify-content: right;
  color: #3c6ef6;
  cursor: pointer;
  align-items: center;
  width: 40%;
`;
export const ErrMessage = styled.div<IThemed>`
  display: flex;
  align-items: center;
  justify-content: right;
  height: 1rem;
  padding: 0.75rem 5rem 0.25rem 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.error.main};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  justify-content: space-between;
  gap: 1rem;
`;
export const TermsNConditions = styled.div`
  font-size: 1rem;
  padding: 1rem;
  line-height: 1.5rem;
`;
