import React, { FC, useEffect, useState } from 'react';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { useFormik } from 'formik';
import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { useParams } from 'react-router-dom';
import { IPostSample } from '_State/Spectrum/types';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import ky from 'ky';
import { FormWrapper, InputTitle, InputWrapper, Inputs } from './Styles';
import { PlxFilesInput } from './FilesInput';

interface IProps {
  dialogTitle: string;
  open: boolean;
  initialValue?: IPostSample;
  onClose: () => void;
  onSubmit: (
    campaignId: number,
    form: any,
    postSampleId?: number
  ) => Promise<any>;
  onSave?: () => void;
}

const INITIAL_VALUES = {
  originalPostUrl: '',
  attachments: [{ fileName: '', base64Content: null }],
};
const REQUIRED_FIELDS = ['originalPostUrl'];

export const AddSampleDialog: FC<IProps> = ({
  dialogTitle,
  open,
  initialValue,
  onClose,
  onSave,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState<string>('');
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      setIsSubmitting(true);
      id &&
        onSubmit(+id, form, initialValue?.id).then(
          () => {
            if (onSave) {
              setIsSubmitting(false);
              formik.setValues(INITIAL_VALUES);
              onSave();
              onClose();
            }
          },
          (error: ky.HTTPError) => {
            setApiError(error.message);
            setIsSubmitting(false);
          }
        );
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.attachments.length === 0 && !form.attachments[0].base64Content) {
        errors.base64Content = t('errors.reqField');
      }
      if (
        form.originalPostUrl &&
        !form.originalPostUrl.startsWith('http://') &&
        !form.originalPostUrl.startsWith('https://')
      ) {
        errors.originalPostUrl = t('errors.companyWebsite');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (initialValue: IPostSample) => {
    initialValue &&
      formik.setValues({
        originalPostUrl: initialValue.originalPostUrl,
        attachments: INITIAL_VALUES.attachments,
      });
  };

  useEffect(() => {
    initialValue && fillForm(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);

  const handleChange = (base64Urls: string[], fileNames: string[]) => {
    const cleanedBase64Urls = base64Urls.map((base64Content) =>
      base64Content.replace(/^data:image\/[a-z]+;base64,/, '')
    );
    const attachmentsData = cleanedBase64Urls.map((base64Content, index) => ({
      fileName: fileNames[index],
      base64Content: base64Content,
    }));
    const currentAttachments = formik.values.attachments || [];
    const updatedAttachments = [
      ...currentAttachments.slice(1),
      ...attachmentsData,
    ];

    formik.setFieldValue('attachments', updatedAttachments);
  };

  return (
    <PlxDialog
      title={dialogTitle}
      onSave={formik.handleSubmit}
      onSaveDisabled={isSubmitting}
      open={open}
      closeButton
      onClose={onClose}
    >
      <FormWrapper>
        <Inputs>
          <InputWrapper>
            <InputTitle>{t('spectrum.admin.uploadSample')}</InputTitle>
            <PlxFilesInput fileNames={[]} onChange={handleChange} />
          </InputWrapper>
          <InputWrapper>
            <InputTitle>{t('spectrum.admin.linkSample')}</InputTitle>
            <PlxInput
              defaultValue={initialValue?.originalPostUrl}
              name={'originalPostUrl'}
              onChange={formik.handleChange}
              placeholder={'www.example.com'}
            />
            <FieldErrorMessage message={formik.errors.originalPostUrl} />
            <FieldErrorMessage message={apiError} />
          </InputWrapper>
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
