import React, { FC } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import clsx from 'clsx';
import { StyledMenuIcon } from '_Routes/Home/NavigationPanel/Icons/StyledIcon';

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      // border: '1.5px solid #3C6EF6 !important',
      background: '#9D05B0',
      '&& path': {
        stroke: '#fff',
      },
      '&& span': {
        zIndex: '1',
        color: '#fff',
      },
      '&& span.MuiTouchRipple-root': {
        background: '#9D05B0',
        zIndex: '0',
      },
    },
    newActive: {
      background: '#231F20',
      '&& path': {
        stroke: '#fff',
      },
      '&& span': {
        zIndex: '1',
        color: '#fff',
      },
      '&& span.MuiTouchRipple-root': {
        background: '#231F20',
        zIndex: '0',
      },
    },
    other: {
      width: '100px',
      minWidth: '100px !important',
      background: ` ${theme.palette.background.default} !important`,
    },
  })
);
const StyledIconButton = styled(Button)<IThemed & { horizontal?: boolean }>`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: 0;
    border: 0.5px solid #d5dde3;
    // height: 6.5rem;
    min-width: ${({ horizontal }) => (horizontal ? '100%' : '14.2rem')};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    letter-spacing: 0;
    margin: 0 -1px -1px 0;
    font-weight: 400;
    width: 100%;
    line-height: 1.6rem;
    text-align: left;

    & .MuiButton-label {
      justify-content: ${({ horizontal }) => (horizontal ? 'end' : 'inherit')};
      margin-left: ${({ horizontal }) => (horizontal ? '2rem' : 'auto')};
    }
  }
`;
const NewStyledIconButton = styled(Button)<IThemed & { horizontal?: boolean }>`
  && {
    background: #f6f6f6;
    border-radius: 4px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #808285;
    border: 0.5px solid #d5dde3;
    height: 3rem;
    margin: 0 1rem 1rem 0;
    text-align: left;
    . & .MuiButton-label {
      justify-content: ${({ horizontal }) =>
        horizontal ? 'space-between' : 'inherit'};
      margin-left: ${({ horizontal }) => (horizontal ? '2rem' : 'auto')};
    }
  }
`;

const Icon = styled.div<IThemed>`
  margin-right: ${({ theme }) => theme.spacing(1)};
  display: flex;
`;

interface IProps extends IIconButtonConfig {
  disabled?: boolean;
  newDesign?: boolean;
  className?: string;
  other?: boolean;
  horizontal?: boolean;
}

export const PlxIconButton: FC<IProps> = ({
  id,
  label,
  isChosen,
  disabled,
  newDesign,
  icon,
  onClick,
  other,
  className,
  horizontal,
}) => {
  const buttonStyles = useButtonStyles();
  const handleClick = (id: string) => {
    if (onClick) {
      onClick(id);
    }
  };
  return newDesign ? (
    <NewStyledIconButton
      horizontal={horizontal}
      className={clsx({
        [buttonStyles.newActive]: isChosen,
        [buttonStyles.other]: other,
      })}
      disabled={disabled}
      onClick={() => handleClick(id)}
    >
      <StyledMenuIcon newDesign={true} isFocused={isChosen}>
        {icon}
      </StyledMenuIcon>
      {label}
    </NewStyledIconButton>
  ) : (
    <StyledIconButton
      horizontal={horizontal}
      className={clsx({
        [buttonStyles.active]: isChosen,
        [buttonStyles.other]: other,
      })}
      disabled={disabled}
      onClick={() => handleClick(id)}
    >
      {icon && <Icon>{icon}</Icon>} {label}
    </StyledIconButton>
  );
};
