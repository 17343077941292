import React, { FC, useEffect, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import {
  OptionLabel,
  OptionWrapper,
  PlxAutocomplete,
} from '_Components/PlxAutoComplete/PlxAutoComplete';
import { ISectionProps } from '_Routes/Home/_Components/Sections/types';
import { IRegion } from '_State/Thrive/types';

interface IProps extends ISectionProps {
  placeholder: string;
  regionsConfig: IRegion[];
}
export const StateSection: FC<IProps> = ({
  regionsConfig,
  title,
  required,
  tooltip,
  value,
  placeholder,
  setValue,
  fieldName,
}) => {
  const [stateValue, setStateValue] = useState<IRegion[]>([]);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const handleStateChange = (value: any) => {
    setStateValue(value);
    setValue(
      fieldName,
      value.map((v: IRegion) => v.id)
    );
  };
  return (
    <FormSection title={title} tooltip={tooltip} required={required}>
      <PlxAutocomplete
        placeholder={placeholder}
        value={stateValue}
        onChange={handleStateChange}
        getOptionLabel={(o) => o.region}
        renderOption={(o) => (
          <OptionWrapper>
            <OptionLabel>{o.region}</OptionLabel>
          </OptionWrapper>
        )}
        multiple={true}
        freeSolo={true}
        options={regionsConfig}
      />
    </FormSection>
  );
};
