import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelDetailsItem } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetailsItem';
import { IUserDTO } from '_State/User/user.types';
import {
  ButtonVariant,
  PlxButtonX,
} from '_Components/Buttons/PlxButton/PlxButtonX';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { XemFinfluencerContract } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerDocuments/XemFinfluencerContract';

const Body = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

interface IProps {
  data?: IUserDTO;
}

export const DistributionPartnerDetails: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const [openContract, setOpenContract] = useState(false);

  return (
    <Body>
      <ChannelDetailsItem label={t('admin.users.clientEdit.fullName')}>
        {data?.fullName}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('admin.users.clientEdit.phoneNumber')}>
        {data?.phoneNumber}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('admin.users.clientEdit.email')}>
        {data?.email}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('admin.users.clientEdit.mailingAddress')}>
        {data?.mailingAddress}
      </ChannelDetailsItem>
      {data?.businessName && (
        <ChannelDetailsItem label={t('admin.users.clientEdit.businessName')}>
          {data?.businessName}
        </ChannelDetailsItem>
      )}
      {data?.referrerName && (
        <ChannelDetailsItem label={t('admin.users.clientEdit.referredBy')}>
          {data?.referrerName}
        </ChannelDetailsItem>
      )}
      <ButtonWrapper>
        <PlxButtonX
          size="small"
          label={t('admin.users.buttons.signedContract')}
          onClick={(): void => setOpenContract(true)}
          variant={ButtonVariant.Outlined}
          fullWidth
        />
      </ButtonWrapper>
      <PlxDialog
        title={''}
        open={openContract}
        onClose={(): void => setOpenContract(false)}
      >
        <XemFinfluencerContract isAdmin contractUrl={data?.contractUrl} />
      </PlxDialog>
    </Body>
  );
};
