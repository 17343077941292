import { useCallback, useEffect, useState } from 'react';
import { getSpectrumHashtags } from '_State/Spectrum/Api/spectrum.api';
import { HashtagChartElement, serilizeHashtags } from '../../helpers';
import { IFilters } from '../../utils';

export interface IUseTopHashtags {
  data: HashtagChartElement[];
  selectedCampaigns: string[];
}

export const useTopHashtags = (
  filters?: IFilters,
  userId?: number
): IUseTopHashtags => {
  const [data, setData] = useState<HashtagChartElement[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

  const fetch = useCallback(
    async (params?: Record<string, any>): Promise<void> => {
      try {
        if (!userId) return;

        const hashtags = await getSpectrumHashtags(userId, params);

        if (!hashtags?.length) return setData([]);

        const data = serilizeHashtags([hashtags]);

        setSelectedCampaigns([]);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userId) return;

      if (!filters) return fetch();

      const selectedIds = filters.campaignIds
        .filter((value) => value.isChecked)
        .map((selected) => selected.id);

      if (!selectedIds || selectedIds?.length === 0)
        return fetch({
          startDate: filters.date.selection?.startDate,
          endDate: filters.date.selection?.endDate,
        });

      try {
        const averages = await Promise.all(
          selectedIds.map(async (id) => {
            return await getSpectrumHashtags(userId, {
              campaignList: [id],
              startDate: filters.date.selection?.startDate,
              endDate: filters.date.selection?.endDate,
            });
          })
        );

        if (!averages?.length) return setData([]);

        const data = serilizeHashtags(averages);

        setSelectedCampaigns(selectedIds);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    })();
  }, [filters, fetch, userId]);

  return {
    data,
    selectedCampaigns,
  };
};
