import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Arrow } from './Arrow.svg';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';

const Wrapper = styled.a<IThemed>`
  display: inline-flex;
  text-decoration: none;
  color: ${({ theme }: IThemed) => theme.palette.primary.main};
`;

const Text = styled.span<IThemed>`
  margin-left: ${({ theme }) => theme.spacing(1)};
  display: inline-flex;
`;

const ArrowForward = styled(Arrow)<IThemed>`
  transform: rotate(180deg);
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

interface IProps extends ICommonProps {
  href: string;
  text: string;
}

export const PlxHrefButton: FC<IProps> = ({ href, text }) => {
  return (
    <Wrapper href={href} target={'_blank'}>
      <Text>{text}</Text>
      <ArrowForward />
    </Wrapper>
  );
};
