import React, { FC } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

const StyledButton = styled(Button)<{
  fullWidth?: boolean;
}>`
  padding: 1.1rem 3.2rem;
  text-transform: capitalize;
  position: relative;
  white-space: nowrap;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const NotificationBadge = styled.div<IThemed>`
  position: absolute;
  right: -1rem;
  top: -20%;
  border-radius: 50%;
  background-color: #ff0000;
  color: #f4f4f4;
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
`;
const CircleWrap = styled.div`
  > div {
    width: 25px !important;
    height: auto !important;
    margin-top: 8px;
  }
`;
export enum ButtonVariant {
  Contained,
  Outlined,
  TextButton,
}
export enum ButtonType {
  Primary,
  Secondary,
}

export interface IProps extends ICommonProps {
  label?: string;
  isDisabled?: boolean;
  variant?: ButtonVariant;
  type?: ButtonType;
  inputType?: 'submit';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  notificationQuantity?: number;
  fullWidth?: boolean;
  isProcessing?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  backgroundColor?: any;
  testId?: string;
}

type MaterialPropsMapping = { [enumKey: number]: () => Partial<ButtonProps> };

const variantToMaterialProps: MaterialPropsMapping = {
  [ButtonVariant.Contained]: () => ({ variant: 'contained' }),
  [ButtonVariant.Outlined]: () => ({ variant: 'outlined' }),
  [ButtonVariant.TextButton]: () => ({ variant: 'text' }),
};

const typeToMaterialProps: MaterialPropsMapping = {
  [ButtonType.Primary]: () => ({ color: 'primary' }),
  [ButtonType.Secondary]: () => ({ color: 'secondary' }),
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sizeSmall: {
      padding: '0.4rem 1.2rem',
      fontWeight: 600,
    },
  })
);

export const PlxButton: FC<IProps> = ({
  label,
  children,
  variant = ButtonVariant.Contained,
  inputType,
  type = ButtonType.Primary,
  onClick,
  className,
  size = 'medium',
  isDisabled,
  notificationQuantity,
  fullWidth,
  isProcessing,
  color,
  backgroundColor,
  testId,
}) => {
  const variantProps = variantToMaterialProps[variant]();
  const typeProps = typeToMaterialProps[type]();
  const classes = useStyles();
  return (
    <>
      <StyledButton
        color={color}
        fullWidth={fullWidth}
        classes={{ sizeSmall: classes.sizeSmall }}
        className={className}
        type={inputType}
        {...variantProps}
        {...typeProps}
        onClick={onClick}
        size={size}
        disabled={isDisabled || isProcessing}
        style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
        data-test-id={testId}
      >
        {isProcessing ? (
          <CircleWrap>
            <CircularProgress color={'secondary'} />
          </CircleWrap>
        ) : (
          <>
            {label}
            {children}
            {notificationQuantity && (
              <NotificationBadge>{notificationQuantity}</NotificationBadge>
            )}
          </>
        )}
      </StyledButton>
    </>
  );
};
export const PlxButtonX: FC<IProps> = ({
  label,
  children,
  variant = ButtonVariant.Contained,
  inputType,
  type = ButtonType.Primary,
  onClick,
  className,
  size = 'medium',
  isDisabled,
  notificationQuantity,
  fullWidth,
  isProcessing,
  color,
  testId,
}) => {
  const variantProps = variantToMaterialProps[variant]();
  const typeProps = typeToMaterialProps[type]();
  const classes = useStyles();
  return (
    <>
      <StyledButton
        color={color}
        fullWidth={fullWidth}
        classes={{ sizeSmall: classes.sizeSmall }}
        className={className}
        type={inputType}
        {...variantProps}
        {...typeProps}
        onClick={onClick}
        size={size}
        disabled={isDisabled || isProcessing}
        data-test-id={testId}
      >
        {isProcessing ? (
          <CircularProgress color={'secondary'} />
        ) : (
          <>
            {label}
            {children}
            {notificationQuantity && (
              <NotificationBadge>{notificationQuantity}</NotificationBadge>
            )}
          </>
        )}
      </StyledButton>
    </>
  );
};
