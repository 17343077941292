import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as TitleTooltip } from './TitleTooltip.svg';
// import { ReactComponent as EllipsisVertical } from './EllipsisVertical.svg';

const Wrapper = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #231f20;
  margin-right: 8px;
  line-height: 25px;
`;
const TitleContainer = styled.div`
  display: flex;
`;
const WidgetDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #5f5f61;
  line-height: 20px;
`;

// const Menu = styled.div`
//   display: flex;
// `;
const ChildrenWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
interface IGraphContainer {
  title: string;
  widgetDescription: string;
  tooltipText?: string;
  height?: number;
}
export const GraphContainer: React.FC<IGraphContainer> = ({
  title,
  tooltipText,
  widgetDescription,
  children,
  height = 600,
}) => {
  return (
    <Wrapper style={{ height }}>
      <Header>
        <HeaderContainer>
          <TitleContainer>
            <Title>{title}</Title>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <TitleTooltip />
              </Tooltip>
            )}
          </TitleContainer>
          <WidgetDescription>{widgetDescription}</WidgetDescription>
        </HeaderContainer>
        {/* <Menu>
          <EllipsisVertical />
        </Menu> */}
      </Header>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export default GraphContainer;
