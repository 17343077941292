import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAdminUsersQueryParams,
  IOrderDTO,
  IOrderEditForm,
} from '_State/User/user.types';
import { SORTING_OPTIONS } from '_Types/adminTable';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import {
  createOrder,
  getOrders,
  updateOrder,
} from '_State/User/Api/admin-user.api';
import { createOrdersTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/utils';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxLoader } from '_Components/PlxLoader';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { useParams } from 'react-router-dom';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { ProgramDetails } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Orders/ProgramDetails';
import { ClientContract } from '_Routes/Home/_Components/Sections/Payment/ClientContract';
import { EditOrder } from './EditOrder';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { NewOrder } from './NewOrder';

const INITIAL_PARAMS: IAdminUsersQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageSize: 1000,
};
const sortingOptions = SORTING_OPTIONS;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

export const OrdersTab = () => {
  const { id, agencyClientId } = useParams<{
    id: string;
    agencyClientId: string;
  }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IOrderDTO[]>([]);
  const [latestId, setLatestId] = useState<number>(0);
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);
  const [sorting, setSorting] = useState<string>('DESC');
  const [detailsData, setDetailsData] = useState<IOrderDTO>();
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [openContract, setOpenContract] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newDialogOpen, setNewDialogOpen] = useState(false);

  const fetchData = () => {
    return getOrders(id, agencyClientId)
      .then((orders) => {
        const biggestId = orders.reduce((acc, curr) => {
          return curr.id > acc ? curr.id : acc;
        }, 0);
        setLatestId(biggestId);
        setData(orders);
      })
      .catch((error) =>
        error.response.json().then(() => {
          setLoading(false);
        })
      );
  };

  const handleCreateOrder = (form: IOrderEditForm): Promise<any> => {
    return createOrder(form).then(() => {
      window.location.reload();
    });
  };
  const handleEditOrder = (form: IOrderEditForm, id: number): Promise<any> => {
    return updateOrder(form, id).then(() => {
      fetchData().then(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setSearchParams({
      ...searchParams,
      sortDirection: event.target.value as string,
    });
  };

  const openEditOrder = (order: IOrderDTO) => {
    setDetailsData(order);
    setEditDialogOpen(true);
  };
  const openNewOrder = () => {
    setNewDialogOpen(true);
  };

  const goToCampaign = (order: IOrderDTO) => {
    console.log(order);
    setOpenContract(true);
  };

  const handleBack = (): void => {
    setEditDialogOpen(false);
    setNewDialogOpen(false);
  };

  const config = useMemo(() => {
    return createOrdersTableConfig(
      goToCampaign,
      openEditOrder,
      latestId
    ) as CellConfig<{}>[];
  }, [latestId]);

  const sorterData = () => {
    if (sorting === 'DESC') {
      data.sort((a, b) => {
        return b.id - a.id;
      });
    } else if (sorting === 'ASC') {
      data.sort((a, b) => {
        return a.id - b.id;
      });
    }

    return data;
  };

  const sortedData = sorterData();

  const listStatuses = sortedData.map((i) => {
    return i.status === 'ACTIVE';
  });
  const isButtonDissabled = listStatuses.includes(true);

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <>
          <PlxCustomTable
            data={sortedData}
            config={config}
            fullHeight
            key={'clients'}
          >
            <PlxTableToolbar label={'Orders'}>
              <StyledSelect
                options={sortingOptions}
                label={t('admin.dashboard.table.sort')}
                onChange={onSortingChange}
                value={sorting}
              />
              <div style={{ marginLeft: 'auto', marginRight: '35px' }}>
                <PlxButton
                  isDisabled={isButtonDissabled}
                  variant={ButtonVariant.Outlined}
                  size={'small'}
                  label={'New'}
                  onClick={openNewOrder}
                />
              </div>
            </PlxTableToolbar>
          </PlxCustomTable>
          <PlxDialog
            title={'Program Details'}
            open={detailsDialogOpen}
            onClose={() => setDetailsDialogOpen(false)}
          >
            <ProgramDetails data={detailsData} />
          </PlxDialog>
          <PlxDialog
            title={t('admin.users.orders.new')}
            open={newDialogOpen}
            onClose={() => setNewDialogOpen(false)}
          >
            <NewOrder
              handleCreateOrder={handleCreateOrder}
              data={sortedData}
              onBack={handleBack}
              userId={id}
              agencyClientId={agencyClientId}
            />
          </PlxDialog>
          <PlxDialog
            title={t('admin.users.orders.edit')}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
          >
            <EditOrder
              handleEditOrder={handleEditOrder}
              onBack={handleBack}
              data={detailsData}
              userId={id}
              agencyClientId={agencyClientId}
            />
          </PlxDialog>
          <PlxDialog
            title={''}
            open={openContract}
            onClose={() => setOpenContract(false)}
          >
            <ClientContract forAdminView />
          </PlxDialog>
        </>
      )}
    </>
  );
};
