import styled from '@emotion/styled';

export const TypographySection = styled.div`
  margin: 0 0 3rem;
  line-height: 2rem;
  font-size: 1.4rem;
  a {
    text-decoration: none;
    font-weight: bold;
  }
`;
export const CardPayment = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  > div {
    width: 100%;
  }
`;
export const CardDateAndCVCWrapper = styled.div`
  display: flex;
`;
export const BoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem 0;
  cursor: pointer;
`;
export const Box = styled.div`
  /* border: 1px solid #d5dde3; */
  /* border-radius: 5px; */
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;
export const BoxHeader = styled.div`
  font-size: 1.8rem;
  color: #4f007d;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0 1rem;
`;
export const BoxDetail = styled.div`
  text-align: center;
  line-height: 2rem;
  margin: 1rem;
  font-size: 1.4rem;
`;
export const BoxChannels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 0.5rem 0 1.5rem 0;
  height: 100%;
  max-width: 180px;
  & svg {
    max-width: 20px;
    max-height: 20px;
    margin: 0.5em;
  }
`;
export const BoxFooter = styled.div`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 1rem;
`;
export const Wrapper = styled.div`
  width: 100%;
`;
export const OptionalWrapper = styled.div`
  margin-top: 2rem;
`;
export const ExpirationWrapper = styled.div`
  width: 100%;
  margin-right: 1.5rem;
`;
export const TotalValue = styled.div`
  color: #1b1b1b;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fc;
  border-radius: 0.5rem;
  padding: 2.8rem;
  margin-top: 2rem;
  gap: 1rem;
  > div {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
  }
`;
export const TotalValueLabel = styled.div`
  font-size: 1.4rem;
  margin-right: 0.7rem;
`;
export const TotalValueSum = styled.div`
  font-weight: 500;
  font-size: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

export const CanadaTotal = styled.div`
  font-weight: 500;
  font-size: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

export const TextAreaInValid = styled.div`
  font-size: 1.2rem;
  color: #a00000;
  max-width: 42rem;
  margin: 1rem 0;
`;
export const TextAreaValid = styled.div`
  font-size: 1.2rem;
  color: green;
  max-width: 42rem;
  height: 2.5rem;
`;
export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
export const InputWrap = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    flex: 1;
  }
`;
export const ProvinceWrap = styled.div`
  border: 1px solid #d5dde3;
  padding: 3px 15px;
  /* border: 1px solid #d5dde3; */
  border-radius: 5px;
  > div > div {
    margin-top: 0;
  }
  label {
    transform: translate(0, 8px) scale(1);
  }
  input,
  div::before,
  div::after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiSelect-select.MuiSelect-select {
    background: none;
  }
  .MuiInputLabel-shrink {
    transform: none;
    display: none;
  }
`;
export const BillingWrap = styled.div`
  margin: 2rem 0.5rem;
  font-size: 1.4rem;
`;

export const DialogContent = styled.div`
  margin: 2rem;
`;

export const DialogText = styled.div`
  margin: 0 0 3rem 0;
  font-size: 2rem;
  max-width: 500px;
  line-height: 3rem;
  text-align: center;
`;

export const InputWrapper = styled.div`
  > div:first-of-type {
    border: 1px solid #d5dde3;
    border-radius: 5px;
  }
`;
