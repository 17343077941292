import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ITile } from '_Types/ITile';
import { IThemed } from '_Styles/types';
import { FORMAT_QUANTITY_MAP } from '_Routes/Home/_routes/Spectrum/utils';
import { ActionsInput } from '_Components/Panels/PlxTilesPanel/ActionsInput';
import { updateCampaignInfo } from '_State/Spectrum/Api/spectrum-admin.api';
import { useParams } from 'react-router-dom';
import { XemSuccessDialog } from '_Components/Dialogs/XemSuccessDialog';

const Wrapper = styled.div<IThemed & { tilesQuantity: number }>`
  margin: ${({ theme }) => theme.spacing(0, 3, 0, 0)};
  border: 1px solid #eaeef1;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  height: 10rem;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  width: ${({ tilesQuantity }) => `${100 / tilesQuantity}%`};
  &:last-of-type {
    margin: 0;
  }
`;

const Label = styled.div`
  color: #767676;
  font-size: 1.4rem;
  font-family: Rubik, sans-serif;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Amount = styled.div<IThemed & { editable?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
  font-size: 2rem;
  width: 100%;
  :hover {
    background-color: ${({ editable }) => (editable ? '#F4F4F4' : 'white')};
    transition: background-color 0.2s;
    cursor: pointer;
  }
`;
const IncreaseAmount = styled.div`
  font-size: 1rem;
  padding: 1.5rem;
  border: 1px solid #cacaca;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 3rem auto;
  width: 100%;
`;

interface IProps extends ITile {
  tilesQuantity: number;
  onChange?: (tileName: string, value: any) => void;
  className?: string;
  campaignStatus?: string;
}
export const PlxTile: FC<IProps> = ({
  label,
  amount,
  tilesQuantity,
  editable,
  formControlName,
  onChange,
  className,
  campaignStatus,
}) => {
  const [focused, setFocused] = useState(false);
  const [openSuccesfulDialog, setOpenSuccesfulDialog] = useState(false);
  const [amountValue, setAmountValue] = useState<any>();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    setAmountValue(amount);
  }, [amount]);

  const getFormattedAmount = (quantity: number) => {
    return FORMAT_QUANTITY_MAP.find((amount) =>
      amount.isApplicable(quantity)
    )?.transform(quantity);
  };

  const handleConfirm = (value: any) => {
    setAmountValue(parseInt(value));
    setFocused(false);
    formControlName && onChange && onChange(formControlName, value);
  };
  const handleThreshold = (amount: number) => {
    const dto: any = { estimatedReach: amount };
    id &&
      updateCampaignInfo(+id, dto).then((res) => {
        setOpenSuccesfulDialog(true);
        setAmountValue(parseInt(amount.toString()));
        setFocused(false);
      });
  };
  const handleCancel = () => {
    setFocused(false);
  };

  return (
    <Wrapper tilesQuantity={tilesQuantity} className={className}>
      <TextWrapper>
        <Label>{label}</Label>
        {editable ? (
          focused ? (
            <InputWrapper>
              <ActionsInput
                amount={amountValue}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
              {formControlName === 'estimatedReach' &&
                campaignStatus === 'NEW' && (
                  <>
                    <IncreaseAmount
                      onClick={() =>
                        handleThreshold(amountValue + (amountValue * 5) / 100)
                      }
                    >
                      +5% (
                      {(amountValue + (amountValue * 5) / 100)
                        .toFixed()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      )
                    </IncreaseAmount>
                    <IncreaseAmount
                      onClick={() =>
                        handleThreshold(amountValue + (amountValue * 15) / 100)
                      }
                    >
                      +15% (
                      {(amountValue + (amountValue * 15) / 100)
                        .toFixed()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      )
                    </IncreaseAmount>
                    <IncreaseAmount
                      onClick={() =>
                        handleThreshold(amountValue + (amountValue * 25) / 100)
                      }
                    >
                      +25% (
                      {(amountValue + (amountValue * 25) / 100)
                        .toFixed()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      )
                    </IncreaseAmount>
                  </>
                )}
            </InputWrapper>
          ) : (
            <Amount editable onClick={() => setFocused(!focused)}>
              {isNaN(amountValue)
                ? amountValue
                : getFormattedAmount(amountValue)}
            </Amount>
          )
        ) : (
          <Amount>
            {isNaN(amountValue) ? amountValue : getFormattedAmount(amountValue)}
          </Amount>
        )}
        <XemSuccessDialog
          open={openSuccesfulDialog}
          onClose={() => setOpenSuccesfulDialog(false)}
        />
      </TextWrapper>
    </Wrapper>
  );
};
