import React, { FC, useEffect, useState } from 'react';
import { FilterButton } from '_Components/Filters/FilterButton/FilterButton';
import { includes } from 'lodash';
import { FilterSearch } from '../FilterSearch/FilterSearch';
import { CheckboxList } from './CheckboxList/CheckboxList';
import { CompareToggle } from '../CompareToggle/CompareToggle';
import { IFilterItem } from '../utls';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  text: string;
  items: IFilterItem[];
  comparable?: boolean;
  onChange?: (items: IFilterItem[]) => void;
  onReset?: (items: IFilterItem[]) => void;
  loading?: boolean;
  maxSelected?: number;
}

const Wrapper = styled.div<{ comparing: boolean }>`
  padding: 14px 8px 10px;
  border-radius: 24px;
  display: flex;
  background: ${({ comparing }): string =>
    comparing ? '#fcfcfc' : 'transparent'};
  border: 1px solid
    ${({ comparing }): string => (comparing ? '#C3C3C3' : 'transparent')};
`;
const StyledText = styled.p`
  margin: 4px 12px 0;
  font-weight: 600;
`;

export const CheckboxFilter: FC<IProps> = ({
  items,
  text,
  comparable = false,
  onChange,
  onReset,
  loading = false,
  maxSelected,
}) => {
  const [campaignSearchTerm, setCampaignSearchTerm] = useState<string>('');
  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [filterItems, setFilterItems] = useState<IFilterItem[]>(items);
  const [compareFilterItems, setCompareFilterItems] = useState<IFilterItem[]>(
    items
  );
  const [compareSearchTerm, setCompareSearchTerm] = useState<string>('');
  const { t } = useTranslation();

  const searchFilters = (term: string): void => {
    setCampaignSearchTerm(term);
    setFilterItems(
      items.filter((it: IFilterItem) =>
        includes(it.title.toLowerCase(), term.toLowerCase())
      )
    );
  };
  const searchCompareFilters = (term: string): void => {
    setCompareSearchTerm(term);
    setCompareFilterItems(
      items.filter((it: IFilterItem) =>
        includes(it.title.toLowerCase(), term.toLowerCase())
      )
    );
  };
  const resetCampaignFilters = (): void => {
    const newItems = items.map((obj: IFilterItem) => {
      return { ...obj, isChecked: false };
    });

    setFilterItems(newItems);
    setCampaignSearchTerm('');
    onReset && onReset(newItems);
  };
  const resetCompare = (): void => {
    setCompareFilterItems(
      items.map((obj: IFilterItem) => {
        return { ...obj, isChecked: false };
      })
    );
    setCompareSearchTerm('');
  };

  const handleOnChange = (items: IFilterItem[]): void => {
    if (
      maxSelected &&
      items.filter((item) => item.isChecked).length > maxSelected
    )
      return;

    setFilterItems(items);
    onChange && onChange(items);
  };

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  return (
    <Wrapper comparing={isComparing}>
      <FilterButton
        name={text}
        resetFilters={resetCampaignFilters}
        items={filterItems}
      >
        <FilterSearch text={campaignSearchTerm} onSearch={searchFilters} />

        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <CheckboxList
            items={filterItems}
            changeFilters={handleOnChange}
            searchTerm={''}
          />
        )}
        {comparable && (
          <CompareToggle comparing={isComparing} setCompare={setIsComparing} />
        )}
      </FilterButton>
      {isComparing && (
        <>
          <StyledText>{t('analytics.compareTo')}</StyledText>
          <FilterButton
            name={text}
            resetFilters={resetCompare}
            items={compareFilterItems}
          >
            <FilterSearch
              text={compareSearchTerm}
              onSearch={searchCompareFilters}
            />
            <CheckboxList
              items={compareFilterItems}
              changeFilters={setCompareFilterItems}
              searchTerm={''}
            />
          </FilterButton>
        </>
      )}
    </Wrapper>
  );
};
