import { IPieChartOption } from '_Components/Charts/PlxDoughnutChart/types';
import {
  IInfoPanelProps,
  PlxInfoPanel,
} from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { PlxDoughnutChart } from '_Components/Charts/PlxDoughnutChart/PlxDoughnutChart';
import React, { FC, useEffect, useState } from 'react';

const option: IPieChartOption = {
  tooltip: {
    formatter: ' {d}% {b}',
    backgroundColor: '#192044',
  },
  series: [
    {
      name: 'Gender',
      type: 'pie',
      radius: ['50%', '100%'],
      avoidLabelOverlap: true,
      label: {
        show: false,
        position: 'center',
      },
      animation: false,
      labelLine: {
        show: false,
      },
      data: [
        { value: 0, name: 'Male', itemStyle: { color: '#3C6EF6' } },
        { value: 0, name: 'Female', itemStyle: { color: '#FF5099' } },
      ],
    },
  ],
};

interface IProps extends IInfoPanelProps {
  femaleAmount?: number;
  maleAmount?: number;
  className?: string;
}

export const GenderChart: FC<IProps> = ({
  femaleAmount,
  maleAmount,
  editable,
  onEditClick,
  label,
  className,
}) => {
  const [chartOption, setChartOption] = useState<IPieChartOption>();
  useEffect(() => {
    option.series[0].data[0].value = maleAmount ? maleAmount : 0;
    option.series[0].data[1].value = femaleAmount ? femaleAmount : 0;
    setChartOption(JSON.parse(JSON.stringify(option)));
  }, [femaleAmount, maleAmount]);

  return (
    <PlxInfoPanel
      editable={editable}
      label={label}
      onEditClick={onEditClick}
      className={className}
    >
      {chartOption && <PlxDoughnutChart option={chartOption} />}
    </PlxInfoPanel>
  );
};
