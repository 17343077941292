import {
  CHANNEL_STATUS_MAP_STRATEGY,
  DistributionPartnerDTO,
  ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY,
  IAdminChannelQueryParams,
  IDistributionPartnerChannelStatistics,
} from '_State/DistributionPartner/distributionPartner.types';
import React, { ReactElement } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import _ from 'lodash';
import {
  ChannelStatus,
  EligibleChannelStatus,
  EligibleTransactionStatus,
} from '_State/DistributionPartner/distributionPartner.enums';
import { ChannelStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelStatusCell';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { Link } from '@material-ui/core';
import {
  getGridStringOperators,
  GridColumnHeaderParams,
  GridColumns,
} from '@material-ui/data-grid';
import { CustomHeader } from './CustomHeader';

export const getChannelStatus = (key: string): ChannelStatus | string => {
  if ((ChannelStatus as any)[(ChannelStatus as any)[key]]) {
    return ChannelStatus[key as keyof typeof ChannelStatus];
  }
  return ChannelStatus.PENDING;
};

export const getEligibleChannelStatus = (
  key: string
): EligibleChannelStatus | string => {
  if ((EligibleChannelStatus as any)[(EligibleChannelStatus as any)[key]]) {
    return EligibleChannelStatus[key as keyof typeof EligibleChannelStatus];
  }
  return EligibleChannelStatus.ELIGIBLE;
};
export const getEligibleTransactionStatus = (
  key: string
): EligibleTransactionStatus | string => {
  if (
    (EligibleTransactionStatus as any)[(EligibleTransactionStatus as any)[key]]
  ) {
    return EligibleTransactionStatus[
      key as keyof typeof EligibleTransactionStatus
    ];
  }
  return EligibleTransactionStatus.UNCLAIMED;
};

export const formatString = (str?: string): string => {
  return _.startCase(_.toLower(str));
};

export const createChannelsTableConfig = (
  openDialog: (channelId: number) => void,
  handleChannelDetails: (channelId: number) => void,
  onSortingColumn: (value: string, value2: string) => void,
  parameters: IAdminChannelQueryParams
): GridColumns => {
  const onSort = (value: string, value2: string) => {
    const param = value2 ? value : 'createdDate';
    const param2 = value2 ? value2 : '';
    onSortingColumn(param, param2);
  };

  return [
    {
      headerName: 'Channel Name',
      field: 'channelName',
      width: 200,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Channel Name"
            field="channelName"
          />
        );
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
    },
    {
      headerName: 'Channel Type',
      field: 'channelType',
      type: 'string',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Channel Type"
            field="channelType"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return (
          <div>{value && _.startCase(value.toString().toLowerCase())}</div>
        );
      },
    },
    {
      headerName: 'Channel ID',
      field: 'id',
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Channel ID"
            field="id"
          />
        );
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
    },
    {
      headerName: 'Followers /Friends',
      field: 'numberOfFollowers',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Followers /Friends"
            field="numberOfFollowers"
          />
        );
      },
    },
    {
      headerName: 'Recent Reactions',
      field: 'reactions',
      filterable: false,
      sortable: false,
    },
    {
      headerName: 'Recent Shares',
      field: 'shares',
      filterable: false,
      sortable: false,
    },
    {
      headerName: 'Recent Comments',
      field: 'comments',
      filterable: false,
      sortable: false,
    },
    {
      headerName: 'Recent Post Count',
      field: 'recentPostCount',
      filterable: false,
      sortable: false,
    },
    {
      headerName: 'Finfluencer',
      field: 'distributionPartner',
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Finfluencer"
            field="distributionPartner"
          />
        );
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
      renderCell({ value }): ReactElement {
        const distributionPartner = value as DistributionPartnerDTO;

        return (
          <div>
            {distributionPartner.firstName} {distributionPartner.lastName}
          </div>
        );
      },
    },
    {
      headerName: 'Geolocation',
      field: 'geolocations',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Geolocation"
            field="geolocations"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return <div>{Array(value)?.join(', ')}</div>;
      },
    },
    {
      headerName: 'Channel URL',
      field: 'channelUrl',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Channel URL"
            field="channelUrl"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return (
          <Link href={value?.toString()} target="_blank">
            {value}
          </Link>
        );
      },
    },
    {
      headerName: 'Offer ($)',
      field: 'offer',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Offer ($)"
            field="offer"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return <div>{value ? `$ ${value}.00` : '-'}</div>;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Status"
            field="status"
          />
        );
      },
      renderCell({ value, row }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (CHANNEL_STATUS_MAP_STRATEGY as any)[
                getChannelStatus(value?.toString() || '')
              ]
            }
            rejectionReason={row.rejectionReason}
          />
        );
      },
    },
    {
      headerName: 'details',
      field: 'details',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell({ row }): ReactElement {
        return (
          <PlxLinkTextButton
            onClick={() => handleChannelDetails(Number(row.id))}
            label={'Channel details'}
          />
        );
      },
    },
    {
      headerName: 'actions',
      field: '',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell({ row }): ReactElement {
        return (
          <>
            {row.status === 'PENDING' && (
              <PlxButton
                size={'small'}
                variant={ButtonVariant.Outlined}
                onClick={() => openDialog(Number(row.id))}
                label={'Review Channel'}
              />
            )}
          </>
        );
      },
    },
  ];
};

export const createAdminChannelStatisticsConfig = (
  statistics?: IDistributionPartnerChannelStatistics
) => {
  return [
    {
      label: 'Total Campaigns',
      amount: statistics?.totalCampaigns,
    },
    {
      label: 'Total Earned',
      amount: `$${statistics?.totalEarned}`,
    },
    {
      label: 'Total Reactions',
      amount: statistics?.totalReactions,
    },
    {
      label: 'Total Comments',
      amount: statistics?.totalComments,
    },
    {
      label: 'Total Shares',
      amount: statistics?.totalShares,
    },
  ];
};

export const createAdminOpportunitiesTableConfig = (
  openDetails: (campaignId: number, opportunityId: number) => void
): GridColumns => {
  return [
    {
      field: 'channelName',
      headerName: 'Channel Name',
      width: 200,
    },
    {
      field: 'channelType',
      headerName: 'Channel Type',
      width: 200,
      editable: true,
    },
    {
      field: 'postDue',
      headerName: 'Post Due',
      width: 200,
      editable: true,
    },
    {
      field: 'analyticsDue',
      headerName: 'Data Due',
      width: 200,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Campaign Status',
      width: 200,
      renderCell({ row }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY as any)[
                getEligibleChannelStatus(row.status)
              ]
            }
          />
        );
      },
    },
    {
      field: 'More...',
      headerName: 'More...',
      width: 200,
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell({ row }): ReactElement {
        return (
          <PlxLinkTextButton
            label={'Campaign details'}
            onClick={() => {
              openDetails(row.campaignId, Number(row.id));
            }}
          />
        );
      },
    },
  ];
};
