import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import { USAIcon } from '_Styles/images/_Icons/USA';
import { CanadaIcon } from '_Styles/images/_Icons/Canada';
import { EuropeIcon } from '_Styles/images/_Icons/Europe';
import React from 'react';
import {
  IPackageControlConfig,
  PricingType,
} from '../../_Components/Payments/PackageControl/PackageControl.types';
import { ISliderInfoProps } from '_Routes/Home/_Components/Sections/ReachSection/SliderInfo';
import { ReactComponent as CannabisIcon } from '_Styles/images/_Icons/Industry/marijuana.svg';
import { ReactComponent as RealEstateIcon } from '_Styles/images/_Icons/Industry/RealEstate.svg';
import { ReactComponent as TechnologyIcon } from '_Styles/images/_Icons/Industry/Technology.svg';
import { ReactComponent as GenBusinessIcon } from '_Styles/images/_Icons/Industry/GenBusiness.svg';
import { ReactComponent as InvestingTradingIcon } from '_Styles/images/_Icons/Industry/InvestingTrading.svg';
import { ReactComponent as EconomyIcon } from '_Styles/images/_Icons/Industry/Economy.svg';
import { ReactComponent as CryptoForexIcon } from '_Styles/images/_Icons/Industry/CryptoForex.svg';
import { ReactComponent as MiningIcon } from '_Styles/images/_Icons/Industry/Mining.svg';
import { Industries } from '_Routes/Home/_routes/Spectrum/config';

export const geoConfig: IIconButtonConfig[] = [
  {
    id: '1',
    label: 'thrive.program.usa',
    icon: <USAIcon />,
    value: '1',
    isChosen: false,
  },
  {
    id: '2',
    label: 'thrive.program.canada',
    icon: <CanadaIcon />,
    value: '2',
    isChosen: false,
  },
  {
    id: '3',
    label: 'thrive.program.europe',
    icon: <EuropeIcon />,
    value: '3',
    isChosen: false,
  },
];
export enum ThrivePaymentPackage {
  _3000 = '$3000',
  _4250 = '$4250',
  _4500 = '$4500',
}

export const industryConfig: IIconButtonConfig[] = [
  {
    id: 'cannabis',
    label: 'spectrum.program.cannabis',
    icon: <CannabisIcon />,
    value: Industries[Industries.CANNABIS],
    isChosen: false,
  },
  {
    id: 'realEstate',
    label: 'thrive.program.realEstate',
    icon: <RealEstateIcon />,
    value: Industries[Industries.REAL_ESTATE],
    isChosen: false,
  },
  {
    id: 'cryptoForex',
    label: 'thrive.program.cryptoForex',
    icon: <CryptoForexIcon />,
    value: Industries[Industries.CRYPTO_FOREX],
    isChosen: false,
  },
  {
    id: 'mining',
    label: 'thrive.program.mining',
    icon: <MiningIcon />,
    value: Industries[Industries.MINING],
    isChosen: false,
  },
  {
    id: 'technology',
    label: 'thrive.program.technology',
    icon: <TechnologyIcon />,
    value: Industries[Industries.TECHNOLOGY],
    isChosen: false,
  },
  {
    id: 'generalBusiness',
    label: 'thrive.program.generalBusiness',
    icon: <GenBusinessIcon />,
    value: Industries[Industries.GENERAL_BUSINESS],
    isChosen: false,
  },
  {
    id: 'investingTrading',
    label: 'thrive.program.investingTrading',
    icon: <InvestingTradingIcon />,
    value: Industries[Industries.INVESTING_TRADING],
    isChosen: false,
  },
  {
    id: 'economics',
    label: 'thrive.program.economics',
    icon: <EconomyIcon />,
    value: Industries[Industries.ECONOMICS],
    isChosen: false,
  },
];

export const thrivePackageConfig: IPackageControlConfig[] = [
  {
    type: PricingType.SEMI_ANNUALLY,
    value: ThrivePaymentPackage._4250,
    vertical: false,
    isChosen: false,
    option: {
      header: {
        title: '$4250',
      },
      content: [
        {
          name: 'thrive.payment.estimatedImpressions',
          value: '97,500',
        },
        {
          name: 'thrive.payment.estimatedNumberOfLeads',
          value: '150',
        },
      ],
    },
  },
  {
    type: PricingType.MONTHLY,
    value: ThrivePaymentPackage._4500,
    vertical: false,
    isChosen: false,
    option: {
      header: {
        title: '$4500',
      },
      content: [
        {
          name: 'thrive.payment.estimatedImpressions',
          value: '97,500',
        },
        {
          name: 'thrive.payment.estimatedNumberOfLeads',
          value: '150',
        },
      ],
    },
  },
];
export const audienceOptions = [
  {
    id: 'INVESTORS',
    name: 'Investors',
  },
  {
    id: 'TRADERS',
    name: 'Traders ',
  },
];

export const ageGroupOptions = [
  {
    id: 'FROM_25_TO_34',
    name: '25-34',
  },
  {
    id: 'FROM_35_TO_44',
    name: '35-44 ',
  },
  {
    id: 'FROM_45_TO_54',
    name: '45-54 ',
  },
  {
    id: 'FROM_55_TO_64',
    name: '55-64',
  },
];

export const sliderInfoConfig = (
  t: (key: string) => string,
  leads?: number
): ISliderInfoProps[] => {
  return [
    {
      label: t('thrive.program.leads'),
      value: leads ? leads.toLocaleString() : '0',
      tooltipText: t('thrive.program.leadsTooltip'),
    },
  ];
};
