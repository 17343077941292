import React, { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';
import { IThemed, TextSizes } from '_Styles/types';
import { AuthPageWrapper } from '_Routes/Auth/_Components/AuthPageWrapper';
import { PlxInputX } from '_Components/PlxInput/PlxInputX';
import {
  ButtonVariant,
  PlxButtonX,
} from '_Components/Buttons/PlxButton/PlxButtonX';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { PlxText, TextType } from '_Components/Text/PlxText';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { updateUserPassword } from '_Api/auth';
import { FormMassage } from '_Components/Forms/FormMassage';
import { SubParagraph } from '_Routes/Auth/utils';

const ResetInput = styled(PlxInputX)<IThemed>`
  && {
    margin: 1rem 0rem;
    max-width: 90%;
  }
`;

const StyledFormErrorMessage = styled(FormMassage)`
  margin-bottom: 0.5rem;
`;

const NewPasswordHeader = styled.div<IThemed>`
  font-weight: 500;
  font-size: 18px;
  font-family: Rubik, sans-serif;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SendLinkAgainButton = styled(PlxButton)`
  && {
    font-size: 1.1rem;
  }
`;

export const NewPassword: FC = () => {
  const { t } = useTranslation();
  const { token } = useParams<any>();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
  };
  const handleSaveClick = () => {
    updateUserPassword(password, token)
      .then(() => {
        setError(false);
        history.push('/auth/password-saved');
      })
      .catch((error) => setError(true));
  };
  return (
    <AuthPageWrapper>
      {error && (
        <StyledFormErrorMessage
          message={t('auth.register.errors.passwordTooShort')}
          data-test-id="password-error"
        />
      )}
      <NewPasswordHeader>
        <PlxText type={TextType.title} size={TextSizes.S}>
          {t('auth.newPassword.title')}
        </PlxText>
      </NewPasswordHeader>
      <SubParagraph paragraph={t('auth.newPassword.message')} />
      <ResetInput
        value={password}
        onChange={handleChange}
        label={t('auth.newPassword.label')}
        placeholder={t('auth.newPassword.placeholder')}
        type="password"
        testId="password-field"
      />
      <Buttons>
        <PlxButtonX
          onClick={handleSaveClick}
          label={t('auth.newPassword.save')}
          testId="save-button"
        />
        <SendLinkAgainButton
          onClick={() => history.push('/auth/reset-password')}
          variant={ButtonVariant.TextButton}
          label={t('auth.newPassword.sendLinkAgain')}
          testId="send-link-again-button"
        />
      </Buttons>
    </AuthPageWrapper>
  );
};
