import React, { FC } from 'react';
import styled from '@emotion/styled';
import { buttonOptions } from './../../Sections/Payment/utils';

interface IProps {
  value: string;
  type: string;
  savings?: string;
}

const Wrapper = styled.div`
  text-align: center;
`;
const SavingsAmount = styled.div`
  font-size: 1.2rem;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.5rem;
  color: #1b1b1b;
  display: flex;
  justify-content: center;
  > div {
    font-size: 1.3rem;
  }
`;

const handleSavingsAmount = function (value: string, type: string) {
  let divider = 1;

  if (type === 'QUARTERLY') {
    divider = 3;
  } else if (type === 'SEMI_ANNUALLY') {
    divider = 6;
  } else if (type === 'ANNUALLY') {
    divider = 12;
  }

  return Math.round(parseInt(value) / divider);
};

export const PackageInfoPrice: FC<IProps> = ({ value, type, savings }) => {
  const priceValue =
    value === 'Custom' ? value : '$' + parseInt(value).toLocaleString();
  return (
    <Wrapper>
      <Title>
        {priceValue}
        {/* {type === 'MONTHLY' && <div>/post</div>} */}
        {type === 'QUARTERLY' && <div>/{buttonOptions.option1}</div>}
        {type === 'SEMI_ANNUALLY' && <div>/{buttonOptions.option2}</div>}
        {type === 'ANNUALLY' && <div>/{buttonOptions.option3}</div>}
      </Title>
      <SavingsAmount>
        <div>
          ($
          {handleSavingsAmount(value, type).toLocaleString()} per campaign)
        </div>
      </SavingsAmount>
    </Wrapper>
  );
};
