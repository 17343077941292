import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { DistributionPartnerAccountInformation } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerProfile/DistributionPartnerAccountInformation';

export const DistributionPartnerCompleteProfile: FC = () => {
  const history = useHistory();
  const handleSave = () => {
    history.push('/home/finfluencer/channel/add-channel');
  };
  return (
    <DistributionPartnerAccountInformation
      onSave={handleSave}
      confirmationLabel={'Next step'}
    />
  );
};
