import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { ReactComponent as ConfirmationIcon } from '_Styles/images/_Icons/Confirmation.svg';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Description = styled.div`
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.3rem;
  text-align: center;
  color: #767676;
`;
const Label = styled.div`
  margin-top: 2.5rem;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  color: #1b1b1b;
`;

interface IProps {
  onNext?: () => void;
  description: string;
}

export const PaymentConfirmation: FC<IProps> = ({ onNext, description }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onNext && onNext();
  };
  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        content={
          <Content>
            <ConfirmationIcon width={40} />
            <Label>{t('payment.paymentConfirmation.label')}</Label>
            <Description>{t(description)}</Description>
            <PlxButtonX
              onClick={handleClick}
              label={t('payment.paymentConfirmation.button')}
            />
          </Content>
        }
      />
    </Wrapper>
  );
};
