import React, { FC } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  InputContainer,
  ErrMessage,
  GoBack,
  ButtonWrapper,
  TermsNConditions,
  CustomErrMess,
} from './StyleDP';
import { Link, TextField } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { RegistrationForm } from './FinfluencerRegForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const stepTwoValidation = Yup.object({
  email: Yup.string()
    .email('Please check you email address')
    .required('* required')
    .label('Email'),
  password: Yup.string()
    .required('* required')
    .min(8, 'Password too short')
    .label('Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .label('Confirm Password')
    .required('* required'),
});

export const FinfluencerRegFormStepTwo: FC<{
  data: RegistrationForm;
  next: (val: RegistrationForm, final: boolean, token?: string) => void;
  back: (val: RegistrationForm) => void;
  apiError: any;
}> = ({ data, next, back, apiError }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const LinkText = (url: string, urlName: string) => {
    return (
      <Link href={url || ''} target="_blank" rel="noopener noreferrer">
        {urlName}
      </Link>
    );
  };

  const subdomain = window.location.hostname.split('.')[0];
  const isCaptcha =
    subdomain === 'dev' || subdomain === 'staging' || subdomain === 'app';

  const handleSubmitCaptcha = async (val: RegistrationForm) => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('registration_form_submission');

      if (token) {
        next(val, true, token);
      } else {
        console.log('reCAPTCHA validation error');
      }
    }
  };

  const handleSubmit = async (val: RegistrationForm) => {
    next(val, true);
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={isCaptcha ? handleSubmitCaptcha : handleSubmit}
      validationSchema={stepTwoValidation}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <InputContainer>
            {apiError && <ErrMessage>{apiError}</ErrMessage>}
            <TextField
              name="email"
              label={t('auth.register.email')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.email}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="email"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <TextField
              name="password"
              type="password"
              label={t('auth.register.password')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="password"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <TextField
              name="confirmPassword"
              type="password"
              label={t('auth.register.confirmPassword')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="confirmPassword"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />

            <ButtonWrapper>
              <PlxButtonX
                isDisabled={false}
                inputType="submit"
                label={t('auth.register.submit')}
                testId="submit-button"
              />
              <GoBack data-test-id="back-button" onClick={() => back(values)}>
                <span>&larr; {t('auth.register.goBackWithArrow')}</span>
              </GoBack>
            </ButtonWrapper>
            <TermsNConditions>
              <Trans
                i18nKey="auth.register.termsConditions"
                components={[
                  LinkText(
                    'https://www.xemoto.media/terms-of-use/',
                    'Terms Of Service'
                  ),
                  LinkText(
                    'https://www.xemoto.media/privacy-policy/',
                    'Privacy Policy'
                  ),
                ]}
              />
            </TermsNConditions>
          </InputContainer>
        </Form>
      )}
    </Formik>
  );
};
