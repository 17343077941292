import React, { FC } from 'react';
import { Theme, Tooltip, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';

interface IProps {
  primaryText?: string;
  secondaryText?: string;
  additionalText?: string;
}
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: '2rem',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    marginTop: '1rem',
  },
}))(Tooltip);

export const XemTooltip: FC<IProps> = ({
  primaryText,
  secondaryText,
  additionalText,
}) => {
  const { t } = useTranslation();
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <div>{t(`${primaryText}`)}</div>
          {secondaryText && (
            <>
              <div></div>
              <div style={{ marginTop: '1rem' }}>
                <em color="inherit">{t(`${secondaryText}`)}</em>
              </div>
            </>
          )}
          {additionalText && (
            <>
              <div></div>
              <div style={{ marginTop: '1rem' }}>
                <em color="inherit">{t(`${additionalText}`)}</em>
              </div>
            </>
          )}
        </React.Fragment>
      }
    >
      <div style={{ padding: '1rem', cursor: 'help' }}>
        <HelpOutlineIcon />
      </div>
    </HtmlTooltip>
  );
};
