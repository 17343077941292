import React, { FC } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
interface IProps {
  handleCloseManualPayoutId: () => void;
  confirmManualPay: () => void;
}
export const ManualPayoutConfirmationDialog: FC<IProps> = ({
  handleCloseManualPayoutId,
  confirmManualPay,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {t('channels.reviewAnalyticsConfirmation.confirmHeader')}
          </DialogTitle>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseManualPayoutId} color="primary">
          {t('channels.reviewAnalyticsConfirmation.cancel')}
        </Button>
        <Button onClick={confirmManualPay} color="primary" autoFocus>
          {t('channels.reviewAnalyticsConfirmation.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
