import React, { FC } from 'react';
import styled from '@emotion/styled';
import ReactEcharts from 'echarts-for-react';
import { ISimpleBarOptions } from '_Components/Charts/PlxBarChart/types';

const PlxSimpleBarChartBody = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

interface IProps {
  config: ISimpleBarOptions;
}

export const PlxSimpleBarChart: FC<IProps> = ({ config }) => {
  return (
    <PlxSimpleBarChartBody>
      <ReactEcharts option={config} />
    </PlxSimpleBarChartBody>
  );
};
