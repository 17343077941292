import { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';
import React from 'react';

export const ReferralsIcon: FC<IIconProps> = ({ isFocused }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 295.000000 298.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,298.000000) scale(0.100000,-0.100000)"
        fill={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1167 2883 c-18 -18 -1 -51 68 -131 l75 -87 -212 -6 c-182 -6 -227 -10 -309 -32 -222 -58 -373 -175 -463 -357 -68 -139 -116 -354 -116 -522 0 -46 4 -59 19 -68 31 -16 51 12 51 73 0 76 19 222 40 304 66 257 204 415 425 486 115 38 371 63 493 49 l63 -7 -90 -75 c-50 -41 -91 -82 -91 -91 0 -19 25 -42 39 -37 23 7 271 222 271 234 0 7 -50 72 -111 143 -101 120 -132 145 -152 124z" />
        <path d="M2066 2866 c-76 -32 -137 -92 -172 -170 -38 -81 -40 -195 -6 -270 47 -104 118 -165 222 -192 75 -19 124 -13 207 27 94 45 151 122 173 235 49 249 -198 465 -424 370z m206 -127 c71 -36 116 -130 103 -214 -29 -196 -281 -239 -369 -64 -44 89 -30 176 40 246 63 63 141 74 226 32z" />
        <path d="M1965 2086 c-76 -19 -148 -60 -206 -118 -117 -117 -176 -357 -99 -398 12 -6 213 -10 523 -10 l504 0 23 25 c21 22 22 31 18 104 -4 67 -11 93 -41 154 -62 126 -179 219 -305 246 -71 15 -354 13 -417 -3z m439 -132 c90 -31 188 -145 203 -239 l6 -35 -432 0 c-487 0 -447 -8 -416 80 30 85 108 164 192 195 58 21 383 20 447 -1z" />
        <path d="M690 1441 c-90 -29 -174 -103 -212 -186 -18 -40 -23 -67 -23 -140 0 -106 19 -157 85 -229 150 -163 400 -124 508 79 24 45 27 61 27 150 0 90 -3 105 -28 153 -34 65 -93 124 -154 153 -49 24 -159 35 -203 20z m178 -147 c58 -34 86 -87 90 -168 2 -59 -1 -77 -20 -110 -38 -65 -89 -99 -156 -104 -121 -9 -212 80 -212 205 1 159 165 256 298 177z" />
        <path d="M2683 1302 c-8 -5 -17 -53 -23 -124 -50 -578 -292 -797 -868 -786 l-153 3 90 75 c75 62 91 79 89 100 -5 47 -41 30 -172 -80 -85 -71 -126 -112 -126 -125 0 -12 46 -75 106 -145 107 -127 134 -145 157 -108 9 13 -3 33 -66 106 -42 50 -77 93 -77 96 0 3 69 6 153 6 247 1 371 23 526 96 81 38 107 57 176 127 96 98 152 200 189 349 44 172 63 391 35 409 -17 11 -21 11 -36 1z" />
        <path d="M563 650 c-114 -23 -243 -122 -297 -228 -56 -110 -71 -230 -35 -276 l20 -26 514 0 514 0 20 26 c25 32 27 94 7 170 -45 169 -184 304 -345 334 -63 12 -337 12 -398 0z m466 -155 c84 -47 146 -130 166 -222 l7 -33 -436 0 -436 0 6 28 c20 85 73 168 134 208 75 50 100 54 310 51 l195 -2 54 -30z" />
      </g>
    </svg>
  );
};
