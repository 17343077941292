import React, { FC } from 'react';
import { ChannelDetailsItem } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetailsItem';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  IAnalyticsDTO,
  IUpdateableAnalyticsDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { ErrorOutline } from '@material-ui/icons';
import { PlxUpdateField } from './PlxUpdateField';

interface IProps {
  analytics?: IAnalyticsDTO;
  handleUpdate: (data: IUpdateableAnalyticsDTO) => void;
}

const Body = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

const WarningSign = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

export const ReviewAnalyticsDialog: FC<IProps> = ({
  analytics,
  handleUpdate,
}) => {
  const { t } = useTranslation();

  const isAnalyticsDateInFuture = (analyticsDate?: string): boolean => {
    if (!analyticsDate) {
      return true;
    }

    const currentDate = new Date();
    const parsedAnalyticsDate = new Date(analyticsDate);

    if (parsedAnalyticsDate > currentDate) {
      return true;
    } else {
      return false;
    }
  };

  const isWarning = analytics?.analyticsDate
    ? isAnalyticsDateInFuture(analytics?.analyticsDate)
    : false;

  return (
    <Body>
      <WarningSign style={{ display: isWarning ? undefined : 'none' }}>
        <ErrorOutline
          style={{ height: 16, width: 16, marginRight: 6, fill: 'red' }}
        />
        {t('channels.reviewAnalytics.warning')}
      </WarningSign>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.reactions')}>
        <PlxUpdateField
          data={analytics?.reactions}
          handleUpdate={handleUpdate}
          reactions
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.comments')}>
        <PlxUpdateField
          data={analytics?.comments}
          handleUpdate={handleUpdate}
          comments
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.shares')}>
        <PlxUpdateField
          data={analytics?.shares}
          handleUpdate={handleUpdate}
          shares
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.impressions')}>
        <PlxUpdateField
          data={analytics?.impressions}
          handleUpdate={handleUpdate}
          impressions
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.videoSaves')}>
        <PlxUpdateField
          data={analytics?.videoSaves}
          handleUpdate={handleUpdate}
          videoSaves
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem
        label={t('channels.reviewAnalytics.numberOfFollowers')}
      >
        <PlxUpdateField
          data={analytics?.numberOfFollowers}
          handleUpdate={handleUpdate}
          numberOfFollowers
        />
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.datePosted')}>
        {formatDate(analytics?.datePosted)}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('channels.reviewAnalytics.postUrl')}>
        <a
          href={analytics?.postSample.originalPostUrl}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Link
        </a>
      </ChannelDetailsItem>
      {analytics && analytics?.postSample.attachments.length > 1 ? (
        <ChannelDetailsItem label={t('channels.reviewAnalytics.screenshots')}>
          {analytics?.postSample.attachments.map((attachment) => (
            <a
              key={attachment.screenshotUrl}
              download
              href={attachment.screenshotUrl}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {attachment.fileName}
            </a>
          ))}
        </ChannelDetailsItem>
      ) : (
        <ChannelDetailsItem label={t('channels.reviewAnalytics.screenshot')}>
          {analytics && analytics?.postSample.attachments.length > 0 && (
            <a
              download
              href={analytics?.postSample.attachments[0].screenshotUrl}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {analytics?.postSample.attachments[0].fileName}
            </a>
          )}
        </ChannelDetailsItem>
      )}
    </Body>
  );
};
