import { createTheme } from '@material-ui/core';
import { IFpCustomThemeProperties, IFpTheme } from '_Styles/types';

export const theme = createTheme({
  typography: {
    fontSize: 20,
    fontFamily: 'Quicksand, sans-serif',
  },
  palette: {
    primary: {
      light: '#fff',
      main: '#3C6EF6',
      dark: '#231F20',
      contrastText: '#1B1B1B',
    },
    secondary: {
      light: '#EAEEF1',
      main: '#A9B3BB',
      dark: '#9D05B0',
    },
    error: {
      main: '#FA4E61',
      light: '#F8F0F0',
    },
    background: {
      default: '#F8F9FC',
      paper: '#fff',
    },
  },
  spacing: (factor) => `${factor}rem`,
  overrides: {
    MuiButton: {
      root: {
        height: '5.5rem',
        margin: '0.5rem',
        fontSize: '1.4rem',
        fontFamily: 'Work Sans, sans-serif',
        padding: '1.1rem 3.2rem',
        textTransform: 'none',
      },
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
      outlinedSecondary: {
        '&:hover': {
          border: '1px solid rgba(255, 0, 0, 0.5)',
        },
        '&:active': {
          border: '1px solid rgba(255, 0, 0, 0.5)',
        },
        color: '#ff0000',
        border: '1px solid rgba(255, 0, 0, 0.5)',
      },
      label: {
        textTransform: 'capitalize',
      },
      contained: {
        boxShadow: 'none !important',
      },
      sizeSmall: {
        height: '3.3rem',
        fontSize: '1.4rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#A9B3BB',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1.4rem',
        '&$disabled': {
          backgroundColor: '#F6F7FB',
        },
      },
      input: {
        fontSize: '1.4rem',
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$shrink': {
          color: '#1B1B1B',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.4rem',
        backgroundColor: '#192044',
      },
    },
  },
});

export enum TextSizes {
  S = 's',
  M = 'm',
  L = 'l',
}

const customThemeProperties: IFpCustomThemeProperties = {
  fontSizes: {
    label: {
      [TextSizes.S]: '12px',
      [TextSizes.M]: '14px',
      [TextSizes.L]: '16px',
    },
    text: {
      [TextSizes.S]: '16px',
      [TextSizes.M]: '18px',
      [TextSizes.L]: '24px',
    },
    title: {
      [TextSizes.S]: '18px',
      [TextSizes.M]: '34px',
      [TextSizes.L]: '39px',
    },
  },
  screenSizes: {
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export const muiTheme: IFpTheme = { ...theme, ...customThemeProperties };
