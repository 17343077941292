import React, { useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { DateRangeWrapper } from './styles/DateCalendar.styles';
import { ICalendarDate } from './DateTimeFilter';

interface IDateCalendar {
  compare: boolean;
  isPredefinedSelected: boolean;
  calendarDate: ICalendarDate;
  setCalendarDate: (arg: ICalendarDate) => void;
  setText: (arg: string) => void;
}

export default function DateCalendar({
  compare,
  isPredefinedSelected,
  calendarDate,
  setCalendarDate,
  setText,
}: IDateCalendar) {
  const [isOpen, setIsOpen] = useState(true);
  const past = useRef(new Date('01/01/2020'));
  const now = useRef(new Date());
  const handleShow = () => {
    setIsOpen(!isOpen);
  };

  const onDateChange = (item: any) => {
    setCalendarDate({ ...calendarDate, ...item });
    if (isPredefinedSelected) return;
    if (calendarDate.selection.startDate) {
      setText(format(calendarDate.selection.startDate, 'MM/dd/yyyy'));
    } else return;
    if (calendarDate.selection.endDate) {
      setText(format(calendarDate.selection.startDate, 'MM/dd/yyyy') + ' + 1');
    } else return;
    if (calendarDate.compare.startDate) {
      setText(format(calendarDate.compare.startDate, 'MM/dd/yyyy') + ' + 2');
    } else return;
    if (calendarDate.compare.endDate) {
      setText(format(calendarDate.compare.startDate, 'MM/dd/yyyy') + ' + 3');
    } else return;
  };

  return (
    <div className="App">
      <div className="text_container" onClick={handleShow}></div>
      {isOpen && (
        // <div className="date_container">
        <DateRangeWrapper>
          <DateRange
            style={
              isPredefinedSelected
                ? { pointerEvents: 'none', opacity: '0.4' }
                : {}
            }
            onChange={onDateChange}
            ranges={
              compare
                ? [calendarDate.selection, calendarDate.compare]
                : [calendarDate.selection]
            }
            months={2}
            direction="horizontal"
            showSelectionPreview={true}
            rangeColors={['#008b9c', ' #afd7dd']}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            showDateDisplay={true}
            inputRanges={[]}
            showMonthArrow={true}
            dateDisplayFormat={'MM/dd/yyyy'}
            weekStartsOn={1}
            preventSnapRefocus={true}
            minDate={past.current}
            maxDate={now.current}
          />
        </DateRangeWrapper>
        // </div>
      )}
    </div>
  );
}
