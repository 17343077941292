import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';
import styled from '@emotion/styled';

interface IProps extends IIconProps {
  expanded?: boolean;
}

interface IWrapperProps {
  expanded?: boolean;
}

const StyledSvg = styled.svg`
  transition: transform 0.2s;
`;

const SvgWrapper = styled.div<IWrapperProps>`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: ${({ expanded }) =>
      expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const ExpandIcon: FC<IProps> = ({ isFocused, onClick, expanded }) => {
  return (
    <SvgWrapper onClick={onClick} expanded={expanded}>
      <StyledSvg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1L6 6L1 1"
          stroke="#1B1B1B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledSvg>
    </SvgWrapper>
  );
};
