import styled from '@emotion/styled';
import React, { FC, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SectionHeader } from '../../utils';
import { IAllNotifications } from '_State/DistributionPartner/distributionPartner.types';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { Link } from 'react-router-dom';
import { FileCopyRounded } from '@material-ui/icons';
import { getUserInfo } from '_Api/auth';
import { subdomain } from 'index';

const Wrapper = styled.div``;

const NotificationContainer = styled.div`
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  border: 1px solid #9d05b0;
  background: #fbd9ff;
  font-size: 1.2rem;
  span {
    font-weight: 700;
  }
`;

export const DistributionPartnerDashboardNotifications: FC<{
  notifications: IAllNotifications[];
  notConnectedChannels: IDistributionPartnerChannelDTO[];
}> = ({ notifications, notConnectedChannels }) => {
  const { t } = useTranslation();
  const [opportunity, setOpportunity] = useState<IAllNotifications[]>([]);
  const [channelApprove, setChannelApprove] = useState<IAllNotifications[]>([]);
  const [referralLink, setReferralLink] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  // const [paymentOption, setPaymentOption] = useState<IAllNotifications[]>([]);
  const [submitAnalytics, setSubmitAnalytics] = useState<IAllNotifications[]>(
    []
  );
  const [submitPostUrl, setSubmitPostUrl] = useState<IAllNotifications[]>([]);
  // const [profileInfo, setProfileInfo] = useState<IAllNotifications[]>([]);
  // const [checkDocument, setCheckDocument] = useState<IAllNotifications[]>([]);

  useEffect(() => {
    getUserInfo().then((data) => {
      data.referralCode &&
        setReferralLink(
          `https://${subdomain}.xemotomedia.com/auth/finfluencer/register?=${data.referralCode}`
        );
    });
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
      });
  };

  const handleCopyLinkClick = () => {
    copyToClipboard(referralLink);
  };

  useEffect(() => {
    setOpportunity(
      notifications.filter((r: IAllNotifications) => r.type === 'OPPORTUNITY')
    );
    setChannelApprove(
      notifications.filter(
        (r: IAllNotifications) => r.type === 'CHANNEL_APPROVE'
      )
    );
    // setPaymentOption(n.filter((r: any) => r.type === 'OPPORTUNITY'));
    setSubmitAnalytics(
      notifications.filter(
        (r: IAllNotifications) => r.type === 'SUBMIT_ANALYTICS'
      )
    );

    setSubmitPostUrl(
      notifications.filter(
        (r: IAllNotifications) => r.type === 'SUBMIT_POST_URL'
      )
    );
    // setProfileInfo(n.filter((r: any) => r.type === 'PROFILE_INFO_MISSING'));
    // setCheckDocument(notifications.filter((r: any) => r.type === 'DOCUMENTS'));
  }, [notifications]);
  return (
    <Wrapper>
      <SectionHeader>{t('dashboard.notifications')}</SectionHeader>
      <NotificationContainer
        style={{
          background: '#DEEFF0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h1>
          {t('finfluencer.dashboard.notification.referralNotification')}
          <a href="referrals">
            {t('finfluencer.dashboard.notification.referrals')}
          </a>
          {t('finfluencer.dashboard.notification.referralNotification2')}
        </h1>
        <div>
          <a target="_blank" rel="noopener noreferrer" href={referralLink}>
            {t('finfluencer.dashboard.notification.referralLink')}
          </a>
          {isCopied && (
            <span style={{ marginLeft: '0.5rem', color: 'green' }}>
              {t('finfluencer.dashboard.notification.copied')}
            </span>
          )}
          <FileCopyRounded
            onClick={handleCopyLinkClick}
            style={{
              fontSize: '1.3rem',
              marginLeft: '1rem',
              cursor: 'pointer',
            }}
          />
        </div>
      </NotificationContainer>
      {(opportunity.length > 0 ||
        submitAnalytics.length > 0 ||
        notConnectedChannels.length > 0 ||
        channelApprove.length > 0) && (
        <>
          {/* {channelApprove.length > 0 && (
            <NotificationContainer>
              <Trans
                i18nKey="finfluencer.dashboard.notification.channelApprove"
                t={t}
                components={[
                  <Link
                    key={Math.random()}
                    to={'/home/finfluencer/profile/#channels'}
                  ></Link>,
                ]}
              />
            </NotificationContainer>
          )} */}
          {/* {notConnectedChannels.length > 0 && (
            <NotificationContainer>
              <Trans
                i18nKey="finfluencer.dashboard.notification.connectChannelNotification"
                t={t}
                components={[
                  <Link
                    key={Math.random()}
                    to={'/home/finfluencer/profile/#channels'}
                  ></Link>,
                ]}
                values={{
                  ncc: notConnectedChannels.length,
                  s: notConnectedChannels.length === 1 ? '' : 's',
                }}
              />
            </NotificationContainer>
          )} */}

          {/* {opportunity.length > 0 && (
            <NotificationContainer>
              <Trans
                i18nKey="finfluencer.dashboard.notification.opportunity"
                values={{
                  opportunity: opportunity.length,
                  o: opportunity.length === 1 ? 'opportunity' : 'opportunities',
                }}
              />
            </NotificationContainer>
          )} */}

          {submitAnalytics.length > 0 && (
            <NotificationContainer>
              <Trans
                i18nKey="finfluencer.dashboard.notification.submitAnalytics"
                components={[
                  <Link
                    key={Math.random()}
                    to={'/home/finfluencer/dashboard/#campaign-table'}
                  ></Link>,
                ]}
                values={{
                  sa: submitAnalytics.length,
                  s: submitAnalytics.length === 1 ? '' : 's',
                }}
              />
            </NotificationContainer>
          )}

          {submitPostUrl.length > 0 && (
            <NotificationContainer>
              <Trans
                i18nKey="finfluencer.dashboard.notification.submitPostUrl"
                components={[
                  <Link
                    key={Math.random()}
                    to={'/home/finfluencer/dashboard/#campaign-table'}
                  ></Link>,
                ]}
                values={{
                  sa: submitPostUrl.length,
                  s: submitPostUrl.length === 1 ? '' : 's',
                }}
              />
            </NotificationContainer>
          )}

          {/* {profileInfo.length > 0 && (
                <NotificationContainer>
                  <span>Review:</span> Your profile is missing some information.
                </NotificationContainer>
              )} */}
          {/* {checkDocument.length > 0 && (
            <NotificationContainer>
              <span>{t('finfluencer.dashboard.notification.review')}</span>{' '}
              {t('finfluencer.dashboard.notification.checkDocument1')}{' '}
              {checkDocument.length}{' '}
              {t('finfluencer.dashboard.notification.checkDocument2')}
            </NotificationContainer>
          )} */}
        </>
      )}
    </Wrapper>
  );
};
