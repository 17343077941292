import styled from '@emotion/styled';

export const TableWrap = styled.div`
  margin-bottom: 3rem;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  overflow: hidden;
  && header.MuiAppBar-colorDefault {
    background: none;
    box-shadow: none;
    border-bottom: 1px solid #d4d4d4;
  }
  && .MuiTab-textColorPrimary.Mui-selected {
    color: #008b9c;
    background: #afd7dd;
  }
  && span.MuiTabs-indicator {
    background: #008b9c;
  }
  && button > span.MuiTab-wrapper {
    display: inline-flex;
    align-items: normal;
    text-align: start;
    line-height: 2rem;
    margin-top: 5px;
    height: 70px;
    width: 200px;
    padding-left: 2rem;
  }
`;

export const TopHeaderWrap = styled.span`
  font-size: 1.6rem;
  p {
    font-size: 1.2rem;
    text-transform: lowercase;
  }
`;
