import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import GraphContainer from '../graphContainer/GraphContainer';
import { useTranslation } from 'react-i18next';
import { CustomizedTooltip } from './CustomizedTooltip';
import VisibilityControlPanel from './VisibilityControlPanel';
import { dataColors, initialVisibility, IVisibility } from './data';
import { AverageChartElement, serilizeAverages } from '../../helpers';
import { getSpectrumAverageEngagements } from '_State/Spectrum/Api/spectrum.api';
import { IFilters } from '../../utils';

export interface IDailyData {
  name: string;
  campaign1?: number;
  campaign2?: number;
  campaign3?: number;
}

interface IProps {
  filters?: IFilters;
  userId?: number;
}

export const CampaignEngagementByChannelGraph: FC<IProps> = ({
  filters,
  userId,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState<IVisibility>(initialVisibility);
  const [data, setData] = useState<AverageChartElement[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

  const getDailyData = (label: string): IDailyData | undefined => {
    return data.find((el) => el.name === label);
  };

  const handleChange = (arg1: string, arg2: string) => {
    setVisibility((prev: IVisibility) => {
      return {
        ...visibility,
        [arg1]: { ...prev[arg1], [arg2]: !(prev[arg1] as any)[arg2] },
      };
    });
  };

  const fetch = useCallback(
    async (params?: Record<string, any>): Promise<void> => {
      try {
        if (!userId) return;

        const averages = await getSpectrumAverageEngagements(userId, params);

        if (!averages?.length) return setData([]);

        const data = serilizeAverages([averages]);

        setSelectedCampaigns([]);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userId) return;

      const selectedIds = filters?.campaignIds
        .filter((value) => value.isChecked)
        .map((selected) => selected.id);

      if (!selectedIds || selectedIds?.length === 0)
        return fetch({
          startDate: filters?.date.selection?.startDate,
          endDate: filters?.date.selection?.endDate,
        });

      try {
        const averages = await Promise.all(
          selectedIds.map(async (id) => {
            return await getSpectrumAverageEngagements(userId, {
              campaignId: id,
              startDate: filters?.date.selection?.startDate,
              endDate: filters?.date.selection?.endDate,
            });
          })
        );

        if (!averages?.length) return setData([]);

        const data = serilizeAverages(averages);

        setSelectedCampaigns(selectedIds);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    })();
  }, [filters, fetch, userId]);

  return (
    <GraphContainer
      title={t('spectrum.overview.CampaignEngagementByChannel')}
      widgetDescription={t(
        'spectrum.overview.CampaignEngagementByChannelDescription'
      )}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height="75%">
          <BarChart
            layout="vertical"
            data={data}
            margin={{
              top: 25,
              right: 15,
              left: 4 * Math.max(...data.map((el) => el.name.length)),
              bottom: 25,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              label={{
                value: `${t('spectrum.overview.Interactions')}`,
                dy: 15,
                fontSize: 14,
              }}
            />
            <YAxis dataKey="name" type="category" />
            <Tooltip
              cursor={{ fill: '#f5f5f5' }}
              content={
                <CustomizedTooltip
                  getDailyData={getDailyData}
                  dataColors={dataColors}
                  visibility={visibility}
                  selectedCampaigns={selectedCampaigns}
                />
              }
            />
            {dataColors
              .slice(0, selectedCampaigns.length || 1)
              .map((el, id) => {
                return el.data.map((item, index) => {
                  return (
                    (visibility[el.name] as any)[item.name] && (
                      <Bar
                        key={`${id}-${index}`}
                        dataKey={`${el.name}.${item.name}`}
                        barSize={(4 - (selectedCampaigns.length || 1)) * 10}
                        fill={item.color}
                        isAnimationActive={false}
                        stackId={el.name}
                      />
                    )
                  );
                });
              })}
          </BarChart>
        </ResponsiveContainer>
        <VisibilityControlPanel
          selectedCampaigns={selectedCampaigns}
          dataColors={dataColors}
          visibility={visibility}
          handleChange={handleChange}
        />
      </div>
    </GraphContainer>
  );
};

export default CampaignEngagementByChannelGraph;
