import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { KeyboardArrowDown } from '@material-ui/icons';
import { IThemed } from '_Styles/types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 300px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin: 2.5rem;
`;
export const Description = styled.div`
  margin-bottom: 5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.3rem;
  text-align: center;
  color: #767676;
`;
export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  color: #1b1b1b;
`;

export const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

export const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 15rem;
  }
`;

export const StyledInput = styled(PlxInput)`
  && {
    margin-left: auto;
    width: 31rem;
    margin-right: 3.5rem;
  }
`;

export const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

export const StyledDialog = styled(PlxDialog)`
  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

export const InputBody = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;
export const PayoutList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
  margin: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`;
export const InviteeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
  margin: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  div {
    display: flex;
    justify-content: space-between;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: right;
  margin-top: 1rem;
`;

export const XemTableHeaderWrap = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  min-width: 950px;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-size: 2rem;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #231f20;
`;

export const TitleWrap = styled.div`
  display: inline-flex;
  gap: 10rem;
  align-items: center;
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: 700;
  color: #231f20;
`;

export const StyledArrowIcon = styled(KeyboardArrowDown)`
  margin-left: 15px;
  margin-right: 15px;
`;

export const BoldDomain = styled.span`
  font-weight: 600;
  color: #1b192c;
  margin-right: 2rem;
`;

export const ColumnWrap = styled.div`
  width: 50%;
  height: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 2rem 0.5rem;
  width: 100%;
  justify-content: space-between;
`;

export const Row2 = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  width: 100%;
  justify-content: space-between;
`;

export const BorderLine = styled.div`
  margin-top: 1rem;
  width: 97%;
  border-bottom: solid 1px #e5e5e5;
`;

export const SelectorWrap = styled.div`
  margin; 0;
  padding: 0;
  display: inline-block;
`;

export const SelectorRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LabelSelect = styled.p`
  margin: 0 0 1rem;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #808285;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 2rem;
  flex-direction: row-reverse;
`;

export const StyledTable = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
`;

export const StyledTrHeader = styled.tr`
  border-top: solid 1px #e5e5e5;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.7;
`;

export const StyledTr = styled.tr`
  border-top: solid 1px #e5e5e5;
  justify-content: space-between;
  background-color: #f6f6f6;
`;
export const StyledTr2 = styled.tr`
  border-top: solid 1px #e5e5e5;
  justify-content: space-between;
`;

export const Th = styled.th`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: left;
  padding: 8px;
  font-weight: bold;
`;
export const Th2 = styled.th`
  border-radius: 5px;
  text-align: left;
  padding: 1rem 0 0.9rem 2.5rem;
  font-weight: bold;
`;
export const Th22 = styled.th`
  border-radius: 5px;
  text-align: right;
  padding: 1rem 2.5rem 0.9rem 0.7rem;
  font-weight: bold;
`;
export const Td = styled.td`
  border: 1px solid #e0e0e0;
  text-align: left;
  padding: 8px;
`;
export const Td2 = styled.td`
  text-align: left;
  padding: 1rem 0 0.7rem 2.5rem;
`;

export const Td22 = styled.td`
  text-align: right;
  padding: 1rem 2.5rem 0.7rem 0.7rem;
`;

export const TogglesWrap = styled.div`
  display: inline-flex;
  color: gray;
  min-width: 345px;
  margin-top: 1.25rem;
  align-items: center;
  > div {
    width: auto;
  }
`;

export const ShowToggleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  color: gray;
  align-items: center;
  > div {
    width: auto;
  }
`;

export const VerticalLine = styled.hr`
  width: 1px;
  height: 32px;
  margin: 0 16px;
  background: #e5e5e5;
  border-radius: 4px;
`;

export const ToggleTitle = styled.div`
  margin-left: 8px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const Amount = styled.div<IThemed & { editable?: boolean }>`
  display: inline-flex;
  height: 3.8rem;
  min-width: 20rem;
  width: 250px;
  border-radius: 0.6rem;
  padding: 9px 10px 9px 25px;
  font-weight: 600;
  border: 1px solid #d5dde3;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14p;
  line-height: 18px;
  :hover {
    background-color: '#F4F4F4';
    transition: background-color 0.2s;
    cursor: pointer;
  }
`;

export const PlaceholderText = styled.span`
  font-weight: 400;
  margin-left: 2rem;
  opacity: 0.6;
`;

export const GreenCircle = styled.div`
  height: 12px;
  width: 12px;
  margin-left: 7px;
  background-color: #3cb043;
  border-radius: 50%;
`;
