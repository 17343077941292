import { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';
import React from 'react';

export const ChannelsIcon: FC<IIconProps> = ({ isFocused }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={isFocused ? '#FFF' : '#231F20'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <path d="M8.59 13.51l6.83 3.98" />
        <path d="M15.41 6.51l-6.82 3.98" />
      </g>
    </svg>
  );
};
