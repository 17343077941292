import React, { FC, Fragment } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import GraphContainer from '../graphContainer/GraphContainer';
import { useTranslation } from 'react-i18next';
import { CustomizedTooltip } from './CustomizedTooltip';
import CampaignCircle from '../CampaignCircle/CampaignCircle';
import { IFilters } from '../../utils';
import { useTopHashtags } from './useTopHashtags';
import { dataColors } from './data';
import { Pagination } from '@material-ui/lab';
import { useCustomPagination } from './useCustomPagination';

export interface IDailyData {
  name: string;
  'campaign-1'?: number;
  'campaign-2'?: number;
  'campaign-3'?: number;
}

interface IProps {
  filters?: IFilters;
  userId?: number;
}

export const TopHashtagsGraph: FC<IProps> = ({ filters, userId }) => {
  const { t } = useTranslation();
  const { data, selectedCampaigns } = useTopHashtags(filters, userId);
  const { paginationData, pageCount, handlePageChange } = useCustomPagination(
    data
  );

  const getDailyData = (label: string): IDailyData | undefined => {
    return paginationData.find((el) => el.name === label);
  };

  return (
    <GraphContainer
      title={t('spectrum.overview.Top hashtags')}
      widgetDescription={t('spectrum.overview.TopHashtagsDescription')}
      tooltipText={t('spectrum.overview.TopHashtagsTooltip')}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height="85%">
          <BarChart
            layout="vertical"
            data={paginationData}
            margin={{
              top: 20,
              right: 15,
              left:
                4.5 * Math.max(...paginationData.map((el) => el.name.length)),
              bottom: 25,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              label={{
                value: `${t('spectrum.overview.Engagement Rate')} (%)`,
                dy: 15,
                fontSize: 16,
              }}
            />
            <YAxis dataKey="name" type="category" />
            <Tooltip
              cursor={{ fill: '#f5f5f5' }}
              content={
                <CustomizedTooltip
                  getDailyData={getDailyData}
                  dataColors={dataColors}
                  selectedCampaigns={selectedCampaigns}
                />
              }
            />
            {dataColors
              .slice(0, selectedCampaigns.length || 1)
              .map(({ name, color }, index) => (
                <Bar
                  key={index.toString()}
                  dataKey={name}
                  barSize={(4 - (selectedCampaigns.length || 1)) * 10}
                  fill={color}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
        <div
          style={{
            marginLeft: 15,
            height: 60,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            {selectedCampaigns.map((capmaignId, index) => (
              <Fragment key={`${index}`}>
                <CampaignCircle color={dataColors[index].color} />
                <span style={{ marginLeft: '6px', marginRight: '6px' }}>
                  {t('spectrum.overview.Campaign')} #{capmaignId}{' '}
                </span>
              </Fragment>
            ))}
          </div>
          <Pagination
            count={pageCount}
            onChange={handlePageChange}
            size="small"
          />
          {/* <SpectrumButton text={t('spectrum.overview.seeDetails')} /> */}
        </div>
      </div>
    </GraphContainer>
  );
};

export default TopHashtagsGraph;
