import React, { FC, useEffect, useMemo, useState } from 'react';
import { PlxLoader } from '_Components/PlxLoader';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import {
  deleteFinfluencer,
  getDistributionPartners,
  participation,
  updateDistributionPartner,
} from '_State/User/Api/admin-user.api';
import {
  IAdminUsersQueryParams,
  IEditDistributionPartnerForm,
  IUserDTO,
} from '_State/User/user.types';
import { createDistributionPartnersTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/utils';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { DistributionPartnerDetails } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/DistributionPartnerDetails';
import { SORTING_OPTIONS } from '_Types/adminTable';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { useTranslation } from 'react-i18next';
import { DistributionPartnerEditForm } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/Dialogs/Forms/DistributionPartnerEditForm';
import { DeleteConfirmation } from './utils';

const INITIAL_PARAMS: IAdminUsersQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageSize: 1000,
};

const sortingOptions = SORTING_OPTIONS;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledInput = styled(PlxInput)`
  margin-left: auto;
  width: 31rem;
  margin-right: 3.5rem;
`;

export const DistributionPartnersTable: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);
  const [searchedPhrase, setSearchedPhrase] = useState<string>();
  const [sorting, setSorting] = useState<string>('DESC');
  const [apiError, setApiError] = useState<string | undefined>();
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [confirmDel, setConfirmDel] = useState(false);
  const [confirmDelActiveUser, setConfirmDelActiveUser] = useState(false);
  const [editData, setEditData] = useState<IUserDTO>();
  const [id, setId] = useState<number | undefined>();

  const fetchData = () => {
    return getDistributionPartners(searchParams)
      .then(({ content }) => {
        setData(content);
      })
      .catch((error) =>
        error.response.json().then(() => {
          setLoading(false);
        })
      );
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const openDetailsDialog = (client: IUserDTO) => {
    setEditData(client);
    setDetailsDialogOpen(true);
  };

  const openEditDialog = (client: IUserDTO) => {
    setEditData(client);
    setEditDialogOpen(true);
  };

  const distributionPartnersConfig = useMemo(() => {
    return createDistributionPartnersTableConfig(
      openDetailsDialog,
      openEditDialog
    ) as CellConfig<{}>[];
  }, []);

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  const closeDetailsDialog = () => {
    setDetailsDialogOpen(false);
  };

  const handleEdit = (form: IEditDistributionPartnerForm) => {
    setApiError(undefined);
    return updateDistributionPartner(form, editData?.id).then(
      () => {
        setLoading(true);
        setEditDialogOpen(false);
        fetchData().then(() => {
          setLoading(false);
        });
      },
      (error) => {
        error.response.json().then((err: any) => {
          const errors = err.fieldErrors.map((v: any) => v.defaultMessage);
          console.log(errors);
          setApiError(errors);
        });
      }
    );
  };

  const handleDelete = (id: number | undefined) => {
    participation(id).then((res) => {
      setId(id);
      setConfirmDel(true);
      if (res === true) {
        setConfirmDelActiveUser(true);
      } else {
        setConfirmDelActiveUser(false);
      }
    });
  };
  const confirmedHandleDelete = () => {
    deleteFinfluencer(id).then(() => {
      setConfirmDel(false);
      closeEditDialog();
    });
  };
  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setSearchParams({
      ...searchParams,
      sortDirection: event.target.value as string,
    });
  };

  const onSearchedPhraseChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSearchedPhrase(event.target.value as string);
    setSearchParams({
      ...searchParams,
      searchedPhrase: event.target.value as string,
    });
  };
  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <PlxCustomTable
          key={'finfluencers'}
          fullHeight
          data={data}
          config={distributionPartnersConfig}
        >
          <PlxTableToolbar label={'Finfluencers'}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('admin.users.search')}
              value={searchedPhrase}
              onChange={onSearchedPhraseChange}
              fullWidth={false}
            />
          </PlxTableToolbar>
        </PlxCustomTable>
      )}
      <PlxDialog
        title={'Finfluencer Details'}
        open={detailsDialogOpen}
        onClose={closeDetailsDialog}
      >
        <DistributionPartnerDetails data={editData} />
      </PlxDialog>
      <PlxDialog
        title={''}
        open={confirmDel}
        onClose={() => setConfirmDel(false)}
      >
        <DeleteConfirmation
          onClose={() => setConfirmDel(false)}
          onSubmit={confirmedHandleDelete}
          title={t('admin.users.deleteConfirmationDialog.title')}
          alert={
            confirmDelActiveUser
              ? t('admin.users.deleteConfirmationDialog.alert')
              : ''
          }
        />
      </PlxDialog>

      <PlxDialog
        title={'Edit Finfluencer'}
        open={editDialogOpen}
        onClose={closeEditDialog}
      >
        <DistributionPartnerEditForm
          data={editData}
          onSubmit={handleEdit}
          onClose={closeEditDialog}
          onDelete={handleDelete}
          error={apiError}
        />
      </PlxDialog>
    </>
  );
};
