import { Dialog } from '@material-ui/core';
import React, { FC } from 'react';
import { PaymentConfirmation } from './PaymentConfirmation';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onNext?: () => void;
  description: string;
}

export const PaymentSuccessDialog: FC<IProps> = ({
  open,
  onClose,
  onNext,
  description,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };
  const handleNext = () => {
    onNext && onNext();
  };
  return (
    <Dialog onClose={handleClose} open={open} disableBackdropClick>
      <PaymentConfirmation onNext={handleNext} description={description} />
    </Dialog>
  );
};
