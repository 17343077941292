import { ISpectrumCampaign } from '_State/Spectrum/types';
import { IPackageControlConfig } from '../../../../_Components/Payments/PackageControl/PackageControl.types';

const createPaymentConfig = (
  type: string,
  value: string,
  title: string,
  programName: string,
  estimatedReach: string,
  contributors: string,
  numberOfNewSubmissions: string,
  id: number,
  vertical?: boolean,
  promo?: boolean,
  description?: string,
  industry?: string,
  geoLocation?: string,
  stockTicker?: string
): IPackageControlConfig => {
  const config = {
    type: type,
    value: value,
    vertical: vertical,
    isChosen: false,
    promo: promo,
    option: {
      header: {
        id: id,
        title: title,
        programName: programName,
        description: description,
      },
      content: [
        {
          name: 'spectrum.payment.totalMemberReach',
          value: estimatedReach
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        },
        // {
        //   name: 'spectrum.payment.postsPerProgram',
        //   value: numberOfNewSubmissions,
        // },
      ],
    },
  };

  industry &&
    config.option.content.push({
      name: 'spectrum.payment.industry',
      value: industry,
    });
  geoLocation &&
    config.option.content.push({
      name: 'spectrum.payment.geoLocation',
      value: geoLocation,
    });
  stockTicker &&
    config.option.content.push({
      name: 'spectrum.payment.stockTicker',
      value: stockTicker,
    });

  return config;
};

export const getPackageOptions = (
  draft: ISpectrumCampaign
): IPackageControlConfig[] => {
  const config: IPackageControlConfig[] = [];

  draft.budgets.forEach((budget) => {
    config.push(
      createPaymentConfig(
        budget.durationType,
        '' + budget.programPrice,
        budget.name,
        budget.programName,
        budget.estimatedReach,
        '' + budget.programDurationInMonths === 'custom'
          ? budget.programDurationInMonths
          : budget.programDurationInMonths + ' months',
        '' + budget.noOfPostsPerProgram,
        budget.id,
        undefined,
        undefined,
        budget.type
      )
    );
  });

  return config;
};
