import React, { FC, useState } from 'react';
import { IPostSample } from '_State/Spectrum/types';
import { PlxHrefButton } from '_Components/Buttons/PlxStepButton/PlxHrefButton';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '_Styles/images/_Icons/EditIcon';
import { PlxImageDialog } from '_Components/Dialogs/PlxImageDialog';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IconWrapper, ImageWrapper, StyledImage, Wrapper } from './Styles';

interface IProps {
  postSample?: IPostSample;
  editable?: boolean;
  onImageClick?: (id: number) => void;
}
export const PostSample: FC<IProps> = ({
  postSample,
  editable,
  onImageClick,
}) => {
  const { t } = useTranslation();
  const [mouseHovered, setMouseHovered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleMouseEnter = () => {
    if (editable) {
      setMouseHovered(true);
    }
  };
  const handleMouseLeave = () => {
    if (editable) {
      setMouseHovered(false);
    }
  };
  const handleImageClick = () => {
    if (editable) {
      onImageClick && postSample?.id && onImageClick(postSample.id);
    } else {
      setOpenDialog(true);
    }
  };

  return (
    <Wrapper>
      {postSample &&
        (postSample?.attachments.length > 1 ? (
          <ImageWrapper>
            <Carousel showThumbs={false}>
              {postSample.attachments.map((attachment, index) => (
                <div key={index} onClick={() => handleImageClick()}>
                  <StyledImage
                    src={attachment.screenshotUrl}
                    alt={attachment.fileName}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              ))}
            </Carousel>
            {mouseHovered && (
              <IconWrapper>
                <EditIcon />
              </IconWrapper>
            )}
          </ImageWrapper>
        ) : (
          <ImageWrapper
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleImageClick}
          >
            {postSample.attachments.length > 0 && (
              <img
                src={postSample.attachments[0].screenshotUrl}
                alt={postSample.attachments[0].fileName}
              />
            )}
            {mouseHovered && (
              <IconWrapper>
                <EditIcon />
              </IconWrapper>
            )}
          </ImageWrapper>
        ))}
      {postSample && (
        <PlxHrefButton
          href={postSample?.originalPostUrl}
          text={t('spectrum.dashboard.goToThePost')}
        />
      )}
      {postSample && postSample.attachments.length > 0 && (
        <PlxImageDialog
          image={postSample?.attachments[0].screenshotUrl}
          onClose={() => setOpenDialog(false)}
          open={openDialog}
        />
      )}
    </Wrapper>
  );
};
