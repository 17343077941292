import React, { FC, useEffect, useState } from 'react';
import { IPackageControlConfig } from '../PackageControl/PackageControl.types';
import { PackageControl } from '../PackageControl/PackageControl';
import styled from '@emotion/styled';
import i18next from 'i18next';

const Wrapper = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 0;
  }
`;

const BigPanel = styled.div`
  margin-bottom: 3.5rem;
  margin-top: 1rem;
`;
const SmallPanelSpectrum = styled.div`
  margin-bottom: 2rem;
  margin-top: 2.5rem;
  max-width: 100%;
  > div {
    flex: 1;
  }
`;

const SmallPanelThrive = styled.div`
  margin-bottom: 2rem;
  margin-top: 2.5rem;
  display: flex;
  overflow-y: auto;
  justify-content: space-between;
  div {
    width: 100%;
  }
`;
const Ul = styled.ul`
  margin: 2rem;
  line-height: 2rem;
  font-size: 1.2rem;
  color: #424242;
`;
interface IProps {
  packages: IPackageControlConfig[];
  onSelect: (value: string, type: string, id: number | null) => void;
  value?: string;
  isThrivePayment: boolean;
  campaignPackage: string;
  paymentPlan: string;
}
export interface BaseOptions {
  package?: string;
  type?: string;
  pricingTier?: string;
  value?: string;
}
export const PackageGroup: FC<IProps> = ({
  packages,
  onSelect,
  value,
  isThrivePayment,
  campaignPackage,
  paymentPlan,
}) => {
  const [options, setOptions] = useState<IPackageControlConfig[]>(packages);
  const [newPaymentPlan, setNewPaymentPlan] = useState('');

  useEffect(() => {
    setOptions(packages);
  }, [packages]);

  useEffect(() => {
    if (value) {
      options.forEach((o) => {
        if (o.value?.toUpperCase() === value.toUpperCase()) {
          o.isChosen = true;
        }
      });
      setOptions([...options]);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleClick = (selectedValue: string) => {
    const option = options.filter(
      ({ value, type }) => value === selectedValue && type === newPaymentPlan
    )[0];
    if (option.isChosen) {
      onSelect('', '', null);
    } else {
      onSelect(
        option.value,
        option.type,
        option.option.header.id ? option.option.header.id : null
      );
    }
    option.isChosen = !option.isChosen;
    options
      .filter((p) => p.value !== option.value)
      .forEach((o) => (o.isChosen = false));
    setOptions([...options]);
  };
  useEffect(() => {
    if (paymentPlan === 'left') {
      setNewPaymentPlan('QUARTERLY');
    } else if (paymentPlan === 'center') {
      setNewPaymentPlan('SEMI_ANNUALLY');
    } else {
      setNewPaymentPlan('ANNUALLY');
    }
  }, [paymentPlan]);

  const handlePaymentPlan = (value: any) => {
    let expire;

    if (value === 'left') {
      expire = '2';
    } else if (value === 'center') {
      expire = '4';
    } else if (value === 'right') {
      expire = '8';
    }
    return expire;
  };

  // (Savings of 13%!!)
  // useLayoutEffect(() => {
  //   if (options) {
  //     const newOptionsMonthly: BaseOptions[] = [];
  //     options.map((option) => {
  //       return (
  //         option.type === 'MONTHLY' &&
  //         newOptionsMonthly.push({
  //           package: option.option.header.description as string,
  //           type: option.type,
  //           pricingTier: option.option.header.title,
  //           value: option.value,
  //         })
  //       );
  //     });
  //     newOptionsMonthly.map((monthly) => {
  //       return options.map((option) => {
  //         return (
  //           option.type !== 'MONTHLY' &&
  //           monthly.package === option.option.header.description &&
  //           monthly.pricingTier === option.option.header.title &&
  //           (option.savings = `${Math.round(
  //             (100 *
  //               (Number(monthly.value) * (option.type === 'QUARTERLY' ? 3 : 6) -
  //                 Number(option.value))) /
  //               (Number(monthly.value) * (option.type === 'QUARTERLY' ? 3 : 6))
  //           )}`)
  //         );
  //       });
  //     });
  //   }
  // }, [options]);

  return (
    <Wrapper>
      {options &&
        options
          .filter((option) => option.promo)
          .map((option, index) => (
            <BigPanel key={index}>
              <PackageControl
                type={option.type}
                option={option.option}
                isChosen={option.isChosen}
                value={option.value}
                onClick={() => handleClick(option.value)}
                vertical={option.vertical}
              />
            </BigPanel>
          ))}
      {!isThrivePayment && (
        <SmallPanelSpectrum>
          {options &&
            options
              .filter(
                (option) =>
                  !option.promo &&
                  option.option.header.description ===
                    campaignPackage.split(' ').join('_').toUpperCase() &&
                  option.type === newPaymentPlan
              )
              .map((option, index) => {
                return (
                  <PackageControl
                    key={index}
                    type={option.type}
                    option={option.option}
                    isChosen={option.isChosen}
                    value={option.value}
                    onClick={() => handleClick(option.value)}
                    vertical={option.vertical}
                    isThrivePayment={isThrivePayment}
                    savings={option.savings}
                  />
                );
              })}
        </SmallPanelSpectrum>
      )}
      {!isThrivePayment && (
        <div>
          <Ul>
            <li>
              {i18next.t('spectrum.payment.campaignLast', {
                expire: handlePaymentPlan(paymentPlan),
              })}
            </li>
          </Ul>
        </div>
      )}
      {isThrivePayment && (
        <SmallPanelThrive>
          {options &&
            options
              .filter((option) => !option.promo)
              .map((option, index) => (
                <PackageControl
                  key={index}
                  type={option.type}
                  option={option.option}
                  isChosen={option.isChosen}
                  value={option.value}
                  onClick={() => handleClick(option.value)}
                  vertical={option.vertical}
                  isThrivePayment={isThrivePayment}
                />
              ))}
        </SmallPanelThrive>
      )}
    </Wrapper>
  );
};
