import { spectrumApi } from '_Api/api';
import { IAdminCampaignsQueryParams, ICampaigns } from '_Types/campaigns';
import { IPostSample, ISpectrumCampaign } from '../types';

export interface SearchCampaignsSortedDTO {
  userId?: number;
  campaignId?: number;
  status?: string;
  paymentStatus?: string;
  pageNumber?: number;
  pageSize?: number;
  sortDirection?: string;
  sortByField?: string;
}

export const getSpectrumCampaignsAdmin = (
  params?: IAdminCampaignsQueryParams
): Promise<ICampaigns<ISpectrumCampaign>> => {
  let url = 'spectrum-campaigns';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.campaignId) {
    urlParams.append('campaignId', params.campaignId);
  }
  if (params?.companyNameList && params.companyNameList.length > 0) {
    urlParams.append('companyNameList', params.companyNameList.join(','));
  }
  if (params?.paymentStatusList && params.paymentStatusList.length > 0) {
    urlParams.append('paymentStatusList', params.paymentStatusList.join(','));
  }
  if (params?.statusList && params.statusList.length > 0) {
    urlParams.append('statusList', params.statusList.join(','));
  }
  if (params?.companyName) {
    urlParams.append('companyNameList', params.companyName);
  }
  if (params?.pageNumber) {
    urlParams.append('pageNumber', params.pageNumber.toString());
  }
  if (params?.pageSize) {
    urlParams.append('pageSize', params.pageSize.toString());
  }

  url += `?${urlParams}`;
  return spectrumApi
    .get(url, {
      timeout: 60000,
    })
    .json();
};

export const getProgramPaymentInfoAdmin = (programIdList: number[]) => {
  return spectrumApi
    .get('spectrum-campaigns/payments?programIdList=' + programIdList[0])
    .json();
};

export const updateCampaignStatistics = (
  id: number,
  statisticsDTO: any
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/${id}/campaign-statistics`, {
      json: statisticsDTO,
    })
    .json();
};
export const updateCampaignInfo = (id: number, infoDTO: any): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/editor/campaign/${id}`, {
      json: infoDTO,
    })
    .json();
};

export const updateCampaignBudget = (
  id: number,
  budgetDTO: any
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/${id}/budget`, {
      json: budgetDTO,
    })
    .json();
};
export const replacePostSample = (
  campaignId: number,
  postSampleForm: any,
  postSampleId?: number
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/${campaignId}/post-samples/${postSampleId}`, {
      timeout: 60000,
      json: postSampleForm,
    })
    .json();
};
export const updatePostSample = (
  campaignId: number,
  postSampleForm: any,
  postSampleId?: number
): Promise<any> => {
  return spectrumApi
    .patch(`spectrum-campaigns/${campaignId}/post-samples/${postSampleId}`, {
      timeout: 60000,
      json: postSampleForm,
    })
    .json();
};

export const getAdminPostSamples = (
  id: number | string | undefined
): Promise<IPostSample[]> => {
  return spectrumApi.get(`spectrum-campaigns/${id}/post-samples`).json();
};
export const getAdminNoOfFollower = (
  id: number | string | undefined
): Promise<IPostSample[]> => {
  return spectrumApi.get(`spectrum-campaigns/${id}/campaign-stats`).json();
};

export const addPostSample = (
  id: number,
  postSampleForm: any
): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/${id}/post-samples`, {
      timeout: 60000,
      json: postSampleForm,
    })
    .json();
};

export const updateCampaignStatus = (
  id: string,
  status: string
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/${id}/status`, {
      json: {
        status: status,
      },
    })
    .json();
};

export const updatePaymentStatus = (
  programId: number,
  paymentId: string,
  status: string
): Promise<any> => {
  return spectrumApi
    .put(`payment/spectrum-program/${programId}/payment/${paymentId}`, {
      json: {
        paymentStatus: status,
      },
    })
    .json();
};

export const getSpectrumCampaignsCSV = (): Promise<any> => {
  return spectrumApi
    .get(`spectrum-campaigns/export-csv`, { timeout: 30000 })
    .blob();
};

export const getSpectrumAnalyticsCSV = (
  start: string,
  end: string,
  id?: number | undefined
): Promise<any> => {
  if (id)
    return spectrumApi
      .get(
        `spectrum-campaigns/export-client-analytics-csv?from=${start}&to=${end}&userId=${id}`,
        { timeout: 30000 }
      )
      .blob();
  else
    return spectrumApi
      .get(
        `spectrum-campaigns/export-client-analytics-csv?from=${start}&to=${end}`,
        { timeout: 30000 }
      )
      .blob();
};
