import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxActionsInput } from '_Components/PlxActionsInput';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const InputWrapper = styled.div`
  position: relative;
`;

interface IProps {
  onConfirm: (value: string) => void;
  fullName?: string;
}

export const ConfirmInput: FC<IProps> = ({ onConfirm, fullName }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  useEffect(() => {
    fullName && setValue(fullName);
  }, [fullName]);
  const [actionsPanelVisible, setActionsPanelVisible] = useState<boolean>(
    false
  );
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const handleCancel = () => {
    fullName && setValue(fullName);
    setActionsPanelVisible(false);
  };
  const handleConfirm = () => {
    onConfirm(value);
    setActionsPanelVisible(false);
  };
  return (
    <InputWrapper>
      <PlxInput
        onFocus={() => setActionsPanelVisible(true)}
        label={t('profile.fullName')}
        placeholder={t('profile.fullNamePlaceholder')}
        value={value}
        onChange={handleValueChange}
      />
      <PlxActionsInput
        visible={actionsPanelVisible}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </InputWrapper>
  );
};
