import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { useTranslation } from 'react-i18next';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { getSpectrumCampaignsCSV } from '_State/Spectrum/Api/spectrum-admin.api';

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const CSVButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

interface IProps {}

export const AdminDashboardHeader: FC<IProps> = () => {
  const { t } = useTranslation();

  const [csvButtonState, setCsvButtonState] = useState<boolean>(false);

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleSpectrumCampaignsCSV = () => {
    setCsvButtonState(true);
    getSpectrumCampaignsCSV().then((data) => {
      createDownloadCSV(data, 'spectrum-campaigns');
    });
    setCsvButtonState(false);
  };

  return (
    <>
      <HeaderWrapper>
        <StyledHeader title={t('spectrum.admin.header.label')}>
          <CSVButton
            size={'small'}
            isDisabled={csvButtonState}
            label={'Download CSV'}
            onClick={handleSpectrumCampaignsCSV}
            testId="download-csv-button"
          />
        </StyledHeader>
      </HeaderWrapper>
    </>
  );
};
