import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  IDialogProps,
  PlxDialog,
} from '_Components/Dialogs/PlxDialog/PlxDialog';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { useTranslation } from 'react-i18next';
import { IThriveStatistics } from '_State/Thrive/types';
import { useFormik } from 'formik';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem;
  width: 100%;
`;

interface IProps extends IDialogProps {
  statistics?: IThriveStatistics;
  campaignId?: string | number;
  onSubmit: (form: any) => void;
}

const INITIAL_VALUES: IThriveStatistics = {
  numberOfLeadsAge25To34: undefined,
  numberOfLeadsAge35To44: undefined,
  numberOfLeadsAge45To54: undefined,
  numberOfLeadsAge55To64: undefined,
};

export const AgeRangeUpdateDialog: FC<IProps> = ({
  statistics,
  title,
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (statistics) {
      fillForm(statistics);
    }
    // eslint-disable-next-line
  }, [statistics]);

  const handleSave = () => {
    formik.handleSubmit();
  };

  const fillForm = (statistics: IThriveStatistics) => {
    formik.setValues({
      numberOfLeadsAge25To34: statistics.numberOfLeadsAge25To34,
      numberOfLeadsAge35To44: statistics.numberOfLeadsAge35To44,
      numberOfLeadsAge45To54: statistics.numberOfLeadsAge45To54,
      numberOfLeadsAge55To64: statistics.numberOfLeadsAge55To64,
    });
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      onSubmit(form);
    },
  });

  return (
    <PlxDialog
      closeButton
      title={title}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    >
      <StyledForm>
        <InputSection
          title={'25-34'}
          value={statistics?.numberOfLeadsAge25To34}
          fieldName={'numberOfLeadsAge25To34'}
          placeholder={t('thrive.admin.numberOfLeads')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
        <InputSection
          title={'35-44'}
          value={statistics?.numberOfLeadsAge35To44}
          fieldName={'numberOfLeadsAge35To44'}
          placeholder={t('thrive.admin.numberOfLeads')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
        <InputSection
          title={'45-54'}
          value={statistics?.numberOfLeadsAge45To54}
          fieldName={'numberOfLeadsAge45To54'}
          placeholder={t('thrive.admin.numberOfLeads')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
        <InputSection
          title={'55-64'}
          fieldName={'numberOfLeadsAge55To64'}
          value={statistics?.numberOfLeadsAge55To64}
          placeholder={t('thrive.admin.numberOfLeads')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
      </StyledForm>
    </PlxDialog>
  );
};
