import React, { FC } from 'react';
import { Slider, withStyles } from '@material-ui/core';
import { ValueLabelProps } from '@material-ui/core/Slider/Slider';

const StyledSlider = withStyles({
  rail: {
    color: ' #D2D8DE',
    height: '3px',
  },
  track: {
    color: '#192044',
    height: '3px',
  },
  thumb: {
    color: '#192044',
    width: '20px',
    height: '20px',
    backgroundColor: 'transparent',
    border: 'solid 3px',
    marginTop: '-8px',
    marginLeft: '-2px',
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(25,32,68,0.16)',
    },
    '&:active': {
      boxShadow: '0px 0px 0px 14px rgba(25,32,68,0.16)',
    },
  },
  mark: {
    display: 'none',
  },
})(Slider);

interface ISliderProps {
  name?: string;
  minRange: number;
  maxRange: number;
  value: number | number[];
  marks?: boolean | any[];
  valueLabelComponent?: React.ElementType<ValueLabelProps>;
  onChange: (value: number) => void;
  step?: number | null;
  disabled?: boolean;
}

export const PlxSlider: FC<ISliderProps> = ({
  name,
  minRange,
  maxRange,
  value,
  valueLabelComponent,
  onChange,
  step,
  marks,
  disabled,
}) => {
  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => {
    if (typeof value === 'number') onChange(value);
  };
  return (
    <StyledSlider
      disabled={disabled}
      name={name}
      value={value}
      min={minRange}
      max={maxRange}
      step={step}
      marks={marks}
      ValueLabelComponent={valueLabelComponent}
      onChange={handleChange}
    />
  );
};
