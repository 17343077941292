import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IMapCountry } from './types';
import { IThemed } from '_Styles/types';
import { themeGetters } from '_Styles/themeHelpers';

const { spacing, color } = themeGetters;

const PlxMapStatisticsBody = styled.div<IThemed>`
  display: flex;
  flex-wrap: wrap;
  background-color: ${color('primary', 'light')};
  border-top: 1px solid ${color('secondary', 'light')};
  padding: ${spacing(1)};
`;

const StatisticWrapper = styled.div<IThemed>`
  margin: ${spacing(1.5)};
`;

const CountryLabel = styled.div<IThemed>`
  display: flex;
  align-items: center;
  font-weight: bold;

  &::before {
    content: '';
    display: inline-block;
    width: ${spacing(1)};
    height: ${spacing(1)};
    border-radius: ${spacing(0.5)};
    margin: ${spacing(1)};
    background-color: ${color('primary')};
  }
`;

const ValueLabel = styled.div<IThemed>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: ${spacing(3)};
  }
`;

const ValueText = styled.span<IThemed>`
  margin-right: ${spacing(0.5)};
`;
const PercentsText = styled.span<IThemed>`
  margin-left: ${spacing(0.5)};
  color: ${color('secondary')};
`;

interface IStatisticProp {
  country: IMapCountry;
}
const Statistic: FC<IStatisticProp> = ({
  country: { name, value, percents },
}) => {
  const formattedPercents = `${((percents || 0) * 100).toFixed(0)}%`;
  return (
    <StatisticWrapper>
      <CountryLabel>{name === '' ? 'Other' : name}</CountryLabel>
      <ValueLabel>
        <ValueText>{value}</ValueText> ·{' '}
        <PercentsText>{formattedPercents}</PercentsText>
      </ValueLabel>
    </StatisticWrapper>
  );
};

interface IProps {
  countries: IMapCountry[];
}

export const PlxMapStatistics: FC<IProps> = ({ countries }) => {
  const Statistics = countries.map((country, index) => (
    <Statistic key={index} country={country} />
  ));
  return <PlxMapStatisticsBody>{Statistics}</PlxMapStatisticsBody>;
};
