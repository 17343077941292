import React, { FC, useContext, useState } from 'react';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import styled from '@emotion/styled';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxFileInput } from '_Components/PlxFileInput';
import { IAsset, IUploadFileDto } from '_State/Thrive/types';
import { AssetsContext } from '_Types/assetsContext';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldErrorMessage } from '../../../../_Components/Forms/FieldErrorMessage';

const FormWrapper = styled.form`
  margin: 0 3.5rem;
  width: 100%;
`;

interface IProps {
  asset?: IAsset;
  open: boolean;
  onClose: () => void;
}

export const AddAssetDialog: FC<IProps> = ({ asset, open, onClose }) => {
  const { updateAsset, addAsset } = useContext(AssetsContext);
  const [assetUpdate, setAssetUpdate] = useState<IUploadFileDto>();
  const [apiError, setApiError] = useState('');
  const { id } = useParams();
  const { t } = useTranslation();
  const handleSave = () => {
    if (assetUpdate && asset && updateAsset) {
      updateAsset(assetUpdate, asset?.id, id)
        .then(() => {
          setAssetUpdate(undefined);
          onClose();
        })
        .catch((e) => {
          setApiError('File is too big');
        });
    } else if (assetUpdate && addAsset) {
      addAsset(assetUpdate, id)
        .then(() => {
          setAssetUpdate(undefined);
          onClose();
        })
        .catch((e) => {
          setApiError('File is too big');
        });
    }
  };
  const handleChange = (base64Url: string, fileName: string) => {
    setAssetUpdate({
      base64Content: base64Url,
      fileName: fileName,
    } as IUploadFileDto);
  };
  return (
    <PlxDialog
      open={open}
      closeButton
      onClose={onClose}
      title={t('thrive.admin.add')}
      onSave={handleSave}
    >
      <FormWrapper>
        <FormSection title={t('thrive.admin.upload')}>
          <PlxFileInput fileName={asset?.fileName} onChange={handleChange} />
          <FieldErrorMessage message={apiError} />
        </FormSection>
      </FormWrapper>
    </PlxDialog>
  );
};
