import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

export const USAIcon: FC<IIconProps> = () => {
  return (
    <svg
      height="28.5"
      width="28.5"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256 32C132.288 32 32 132.288 32 256C32 379.712 132.288 480 256 480C379.712 480 480 379.712 480 256C480 132.288 379.712 32 256 32Z"
        fill="#E92B2B"
      />
      <path
        d="M450.034 368H61.9688C74.1621 389.079 89.6917 407.986 107.835 424H404.168C422.311 407.986 437.841 389.079 450.034 368Z"
        fill="#FBFBFB"
      />
      <path
        d="M480 256H32C32 275.337 34.4502 294.101 39.057 312H472.943C477.55 294.101 480 275.337 480 256Z"
        fill="#FBFBFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M450.038 144H61.9727C51.9465 161.332 44.176 180.133 39.0625 200H472.949C467.835 180.133 460.065 161.332 450.038 144Z"
        fill="#FBFBFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.145 88H107.812C147.3 53.1476 199.17 32 255.979 32C312.788 32 364.657 53.1476 404.145 88Z"
        fill="#FBFBFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.606 33.1055V256H32C32 139.846 120.409 44.3412 233.606 33.1055Z"
        fill="#2A2FAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.1579 137.078H75.72L62.5536 146.644L67.5828 162.122L56.5 154.07C59.4771 148.254 62.701 142.586 66.1579 137.078Z"
        fill="#FBFBFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3125 207.332C37.5389 206.31 37.7722 205.291 38.0125 204.275H49.379L54.4081 188.797L59.4373 204.275H75.7118L62.5454 213.841L67.5745 229.319L54.4081 219.753L41.2418 229.319L46.2709 213.841L37.3125 207.332Z"
        fill="#FBFBFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.795 84.5865C118.024 79.3401 124.544 74.4271 131.326 69.875H142.908L129.741 79.4409L134.77 94.919L121.604 85.353L108.438 94.919L111.795 84.5865Z"
        fill="#FBFBFB"
      />
      <path
        d="M121.616 121.602L126.645 137.08H142.92L129.753 146.646L134.783 162.124L121.616 152.558L108.45 162.124L113.479 146.646L100.312 137.08H116.587L121.616 121.602Z"
        fill="#FBFBFB"
      />
      <path
        d="M121.616 188.797L126.645 204.275H142.92L129.753 213.841L134.783 229.319L121.616 219.753L108.45 229.319L113.479 213.841L100.312 204.275H116.587L121.616 188.797Z"
        fill="#FBFBFB"
      />
      <path
        d="M188.804 54.3984L193.833 69.8765H210.107L196.941 79.4424L201.97 94.9204L188.804 85.3545L175.637 94.9204L180.666 79.4424L167.5 69.8765H183.775L188.804 54.3984Z"
        fill="#FBFBFB"
      />
      <path
        d="M188.804 121.602L193.833 137.08H210.107L196.941 146.646L201.97 162.124L188.804 152.558L175.637 162.124L180.666 146.646L167.5 137.08H183.775L188.804 121.602Z"
        fill="#FBFBFB"
      />
      <path
        d="M188.804 188.797L193.833 204.275H210.107L196.941 213.841L201.97 229.319L188.804 219.753L175.637 229.319L180.666 213.841L167.5 204.275H183.775L188.804 188.797Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};
