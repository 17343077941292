import React, { FC, useContext } from 'react';
import { ICommonProps } from '_Types/props';
import { ProductCard } from '_Routes/Home/_routes/Dashboard/Products/ProductCard';
import { PlexusProduct } from '_Types/products';
import { DashboardContext } from '_Routes/Home/_routes/Dashboard/Context/DashboardContext';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { PlxButtonMenu } from '../Utils/PlxButtonMenu';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div<IThemed>`
  margin: ${({ theme }) => theme.spacing(1.25, 0)};
  display: inline-flex;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  align-items: center;
  color: #231f20;
`;

const TitleDashboard = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
`;

const Spaceholder = styled.div`
  position: relative;
  width: 33%;
  margin: 1.25rem 1.25rem 1.25rem 0;
  height: 18rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  width: 100%;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: row-reverse;
  align-items: center;
  height: auto;
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Products: FC<ICommonProps> = () => {
  const { spectrumCampaigns } = useContext(DashboardContext);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);
  const { user } = useSelector((state: IState) => state.user);
  return (
    <Wrapper>
      <TitleDashboard>Dashboard</TitleDashboard>
      <Row>
        <Title>Hi {user?.firstName}, start a campaign!</Title>
        <ButtonsWrapper>
          <PlxButtonMenu spectrumCampaigns={spectrumCampaigns} />
          {/* <PlxButton>Filter</PlxButton> */}
        </ButtonsWrapper>
      </Row>
      {(!isAgencyType || clientSelected) && (
        <ProductsWrapper>
          <ProductCard
            spectrumCampaigns={spectrumCampaigns}
            linkTo={
              isAgencyType
                ? '/home/agency/spectrum/campaigns'
                : '/home/client/spectrum/campaigns'
            }
            type={PlexusProduct.Spectrum}
          />
          {/* <ProductCard type={PlexusProduct.Thrive} /> */}
          {/* <ProductCard type={PlexusProduct.Chatter} /> */}
          <Spaceholder />
          <Spaceholder />
        </ProductsWrapper>
      )}
    </Wrapper>
  );
};
