import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import React from 'react';
import {
  IRegion,
  IThriveCampaign,
  IThriveCampaignEditForm,
} from '_State/Thrive/types';
import { getThriveCampaignsAdmin } from '_State/Thrive/Api/thrive-admin.api';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { ButtonGroupSection } from '_Routes/Home/_Components/Sections/ButtonGroupSection';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  ageGroupOptions,
  audienceOptions,
  geoConfig,
  industryConfig,
} from '../../../../Thrive/config';
import { StateSection } from '_Routes/Home/_Components/Sections/StateSection';
import { MultiSelectSection } from '_Routes/Home/_Components/Sections/MultiSelectSection';
import { StockSection } from '_Routes/Home/_Components/Sections/StockSection/StockSection';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { getThriveGeolocationsStates } from '_State/Thrive/Api/thrive.api';
import { mapTranslatedLabel } from '_App/utils';
import { UploadPresentationSection } from '_Routes/Home/_Components/Sections/UploadPresentationSection';

const FormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  overflow: auto;
`;

const Inputs = styled.div``;

const StyledUploadPresentationSection = styled(UploadPresentationSection)`
  margin-top: 2rem;
`;

interface IProps {
  open: boolean;
  campaignId?: string;
  onClose: () => void;
  onSubmit: (form: any) => Promise<any>;
}

const INITIAL_VALUES: IThriveCampaignEditForm = {
  industry: [],
  geolocation: undefined,
  geolocationStates: undefined,
  audiences: undefined,
  ageGroups: undefined,
  stockTickerList: undefined,
  presentationUrl: '',
  presentationName: '',
  companyWebsite: '',
};

const REQUIRED_FIELDS = [
  'industry',
  'geolocation',
  'geolocationStates',
  'audiences',
  'presentationName',
];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const AdminEditCampaignDialog: FC<IProps> = ({
  open,
  onClose,
  onSubmit,
  campaignId,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [campaign, setCampaign] = useState<IThriveCampaign>();
  const [regionsConfig, setRegionsConfig] = useState<IRegion[]>([]);
  const [regionsValue, setRegionsValue] = useState<IRegion[]>([]);
  const [stateLabel, setStateLabel] = useState<string>('');

  const industryInitialValue = useMemo(
    () => (campaign?.industry ? campaign?.industry : []),
    [campaign]
  );

  const geolocationInitialValue = useMemo(
    () => (campaign?.geolocation ? [campaign?.geolocation.id.toString()] : []),
    [campaign]
  );

  const industryOptions = industryConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsSubmitting(true);
        onSubmit(form)
          .then(() => {
            setIsSubmitting(false);
          })
          .catch((e) => {
            setIsSubmitting(false);
          });
      }
    },

    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.geolocationStates && form.geolocationStates.length < 1) {
        errors.geolocationStates = t('errors.reqField');
      }
      if (form.audiences && form.audiences.length < 1) {
        errors.audiences = t('errors.reqField');
      }
      if (
        form.companyWebsite &&
        !form.companyWebsite.startsWith('http://') &&
        !form.companyWebsite.startsWith('https://')
      ) {
        errors.companyWebsite = t('errors.companyWebsite');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (campaign: IThriveCampaign) => {
    formik.setValues({
      industry: campaign.industry,
      geolocation: campaign.geolocation?.id,
      geolocationStates: campaign.geolocationStates.map((state) => state.id),
      audiences: campaign.audiences,
      ageGroups: campaign.ageGroups,
      stockTickerList: campaign.stockTickerList,
      presentationUrl: null,
      presentationName: campaign?.thrivePresentation?.fileName,
      companyWebsite: campaign?.companyWebsite,
    });
  };

  useEffect(() => {
    if (campaignId) {
      getThriveCampaignsAdmin({ campaignId }).then(
        (c) => {
          if (c.content[0]) {
            fillForm(c.content[0]);
            setCampaign(c.content[0]);
            setRegionsValue(c.content[0].geolocationStates);
            setStateLabel(getStateLabel(c.content[0].geolocation?.id));
            getThriveGeolocationsStates(c.content[0].geolocation?.id).then(
              (states) => {
                setRegionsConfig(states);
              }
            );
          }
        },
        () => null
      );
    }
    // eslint-disable-next-line
  }, [campaignId]);

  const handleLocationChange = (options: string[]) => {
    const id = +options[0];
    if (id) {
      getThriveGeolocationsStates(id).then((states) => {
        setRegionsConfig(states);
        setRegionsValue([]);
      });
    } else {
      setRegionsConfig([]);
      setRegionsValue([]);
      formik.setFieldValue('geolocationStates', []);
    }
    formik.setFieldValue('geolocation', options[0]);
    setStateLabel(getStateLabel(id));
  };

  const getStateLabel = (geolocationId: number) => {
    if (geolocationId === 1) {
      return t('thrive.program.state');
    } else if (geolocationId === 2) {
      return t('thrive.program.province');
    } else {
      return t('thrive.program.region');
    }
  };

  const handleFileChange = (url: string, fileName: string) => {
    formik.setFieldValue('presentationUrl', url);
    formik.setFieldValue('presentationName', fileName);
  };

  const handleDeleteStock = () => {
    const stockTickers = formik.values.stockTickerList;
    formik.setFieldValue(
      'stockTickerList',
      stockTickers?.slice(0, stockTickers?.length - 1)
    );
  };

  return (
    <PlxDialog
      title={'Edit campaign information'}
      open={open}
      onClose={onClose}
      onSave={formik.handleSubmit}
      onSaveDisabled={isSubmitting}
    >
      <FormWrapper>
        <Inputs>
          <ButtonGroupSection
            required
            buttonsConfig={industryOptions}
            title={t('thrive.program.industry')}
            placeholder={t('thrive.program.fillIndustry')}
            tooltip={t('thrive.program.industryTooltip')}
            inputTitle={t('thrive.program.specifyIndustry')}
            value={industryInitialValue}
            fieldName={'industry'}
            setValue={formik.setFieldValue}
          />
          <FieldErrorMessage message={formik.errors.industry} />
          <ButtonGroupSection
            required
            title={t('spectrum.program.geoLocation')}
            tooltip={t('thrive.program.geoLocationTooltip')}
            multiple={false}
            buttonsConfig={geoConfig.map((item) => {
              return { ...item, label: t(item.label) };
            })}
            value={geolocationInitialValue}
            onChoose={handleLocationChange}
          />
          <FieldErrorMessage message={formik.errors.geolocation} />
          {regionsConfig.length > 0 && (
            <>
              <StateSection
                required
                title={stateLabel}
                value={regionsValue}
                fieldName={'geolocationStates'}
                setValue={formik.setFieldValue}
                placeholder={t('thrive.program.statePlaceholder')}
                regionsConfig={regionsConfig}
              />
              <FieldErrorMessage message={formik.errors.geolocationStates} />
            </>
          )}
          <MultiSelectSection
            selectConfig={audienceOptions}
            required
            title={t('thrive.program.audience')}
            placeholder={t('thrive.program.audiencePlaceholder')}
            tooltip={t('thrive.program.audienceTooltip')}
            value={campaign?.audiences}
            setValue={formik.setFieldValue}
            fieldName={'audiences'}
          />
          <FieldErrorMessage message={formik.errors.audiences} />
          <MultiSelectSection
            required={false}
            selectConfig={ageGroupOptions}
            title={t('thrive.program.ageGroup')}
            placeholder={t('thrive.program.ageGroupPlaceholder')}
            tooltip={t('thrive.program.ageGroupTooltip')}
            value={campaign?.ageGroups}
            setValue={formik.setFieldValue}
            fieldName={'ageGroups'}
          />
          <StockSection
            onDelete={handleDeleteStock}
            value={campaign?.stockTickerList}
            setValue={formik.setFieldValue}
            fieldName={'stockTickerList'}
          />

          <StyledUploadPresentationSection
            required
            title={t('thrive.campaign.investorPresentation')}
            handleChange={handleFileChange}
            tooltip={t('thrive.campaign.investorPresentationTooltip')}
            fileName={campaign?.thrivePresentation?.fileName}
          />
          <FieldErrorMessage message={formik.errors.presentationName} />
          <InputSection
            title={t('thrive.campaign.companyUrl')}
            placeholder={t('thrive.campaign.companyUrlPlaceholder')}
            fieldName={'companyWebsite'}
            handleChange={formik.handleChange}
            value={campaign?.companyWebsite}
            required={false}
          />
          <FieldErrorMessage message={formik.errors.companyWebsite} />
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
