import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandIcon: {
      transform: 'none !important',
    },
    header: {
      margin: '0.05rem',
      borderRadius: '0.5rem',
      minHeight: '6rem',
      height: '6rem',
      '&.Mui-expanded': {
        minHeight: '6rem',
        height: '6rem',
      },
      '&.Mui-focused': {
        background: 'white',
      },
    },
    ripple: {
      display: 'none',
    },
    panel: {
      marginBottom: '1.7rem',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    expanded: {
      borderRadius: '0.5rem  0.5rem 0 0',
    },
    details: {
      borderRadius: '0 0 0.5rem 0.5rem',
      border: '1px solid #D5DDE3',
      borderTop: 'none',
    },
  })
);

interface ISelectablePanel {
  selected?: boolean;
}

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)<
  ISelectablePanel
>`
  && {
    border: ${({ selected }) =>
      selected ? '1.5px solid #3C6EF6' : '1px solid #D5DDE3'};
    padding: ${({ selected }) => (selected ? '0rem 1.95rem' : '0.05rem 2rem')};
  }
`;

interface IProps {
  expanded: boolean;
  selected?: boolean;
  onClick?: () => void;
  controlComponent?: () => ReactNode;
  headerComponent?: () => ReactNode;
  detailsComponent?: () => ReactNode;
}
export const PlxExpansionPanel: FC<IProps> = ({
  selected,
  expanded,
  onClick,
  controlComponent,
  headerComponent,
  detailsComponent,
}) => {
  const classes = useStyles();
  return (
    <ExpansionPanel
      classes={{ root: classes.panel }}
      expanded={expanded}
      onClick={onClick}
    >
      <StyledExpansionPanelSummary
        selected={selected}
        classes={{
          expandIcon: classes.expandIcon,
          root: classes.header,
          expanded: classes.expanded,
        }}
        IconButtonProps={{
          disableRipple: true,
        }}
        expandIcon={controlComponent && controlComponent()}
      >
        {headerComponent && headerComponent()}
      </StyledExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.details }}>
        {detailsComponent && detailsComponent()}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
