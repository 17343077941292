import { spectrumApi } from '_Api/api';
import {
  IApproveChannelForm,
  IDistributionPartnerAddChannelForm,
  IDistributionPartnerCampaignsQueryParams,
  IDistributionPartnerChannelStatistics,
  IDistributionPartnerEligibleChannel,
  IDistributionPartnerOpportunityDTO,
  IDistributionPartnerPostsUrls,
  IUpdateableAnalyticsDTO,
} from '_State/DistributionPartner/distributionPartner.types';

export const getChannels = (params?: any): Promise<any> => {
  let url = 'spectrum-campaigns/channel/admin';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.channelTypeList && params.channelTypeList.length > 0) {
    urlParams.append('channelTypeList', params.channelTypeList.join(','));
  }
  if (params?.channelStatusList && params.channelStatusList.length > 0) {
    urlParams.append('channelStatusList', params.channelStatusList.join(','));
  }
  if (params?.channelId) {
    if (isNaN(params?.channelId)) {
      urlParams.append('channelIdList', '0');
    } else {
      urlParams.append('channelIdList', params.channelId);
    }
  }
  if (params?.id) {
    if (isNaN(params?.id)) {
      urlParams.append('channelIdList', '0');
    } else {
      urlParams.append('channelIdList', params?.id);
    }
  }
  if (params?.channelName) {
    urlParams.append('channelName', params.channelName);
  }
  if (params?.distributionPartner) {
    urlParams.append('finfluencer', params.distributionPartner);
  }
  if (params?.pageNumber) {
    urlParams.append('pageNumber', params.pageNumber.toString());
  }
  if (params?.pageSize) {
    urlParams.append('pageSize', params.pageSize.toString());
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};
export const getReferrals = (params?: any): Promise<any> => {
  let url = 'referrals';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.referralStatusList && params.referralStatusList.length > 0) {
    urlParams.append('referralStatusList', params.referralStatusList.join(','));
  }
  if (params?.fullName) {
    urlParams.append('fullName', params.fullName);
  }
  if (params?.pageNumber) {
    urlParams.append('pageNumber', params.pageNumber.toString());
  }
  if (params?.pageSize) {
    urlParams.append('pageSize', params.pageSize.toString());
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};

export const getEligibleChannels = (
  channelId?: string
): Promise<IDistributionPartnerEligibleChannel[]> => {
  return spectrumApi
    .get(
      `spectrum-campaigns/${channelId}/opportunities?sortDirection=DESC&sortByField=channelCreationDate`,
      { timeout: 60000 }
    )
    .json();
};

export const getEligibleChannelsCSV = (channelId?: string): Promise<any> => {
  return spectrumApi
    .get(
      `spectrum-campaigns/${channelId}/opportunitiesCSV?sortDirection=DESC&sortByField=channelCreationDate`
    )
    .blob();
};

export const getPostUrls = (
  campaignID?: string
): Promise<IDistributionPartnerPostsUrls[]> => {
  return spectrumApi.get(`spectrum-campaigns/${campaignID}/post-urls`).json();
};

export const approveChannel = (
  form: IApproveChannelForm,
  channelId: number
): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/channel/${channelId}/approve`, {
      json: form,
      timeout: false,
    })
    .json();
};

export const approveChannelActive = (
  form: IApproveChannelForm,
  channelId: number
): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/channel/${channelId}/active_approve`, {
      json: form,
      timeout: false,
    })
    .json();
};

export const rejectChannel = (
  rejectMessage: string,
  channelId?: number
): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/channel/${channelId}/reject`, {
      json: { rejectingReason: rejectMessage },
    })
    .json();
};

export const inviteChannels = (
  channelIdList: string[],
  campaignId?: string,
  selectedDomain?: string
): Promise<any> => {
  let url = `spectrum-campaigns/${campaignId}/invite/${channelIdList}`;
  if (selectedDomain !== 'Please select domain...') {
    if (selectedDomain) {
      url += `?domain=${selectedDomain}`;
    }
  }
  return spectrumApi.post(url);
};

export const cancelChannels = (
  channelIdList: string,
  campaignId?: string
): Promise<any> => {
  return spectrumApi.post(
    `spectrum-campaigns/${campaignId}/cancel-channel-invitation/${channelIdList}`
  );
};

export const manualPayout = (id: string): Promise<any> => {
  return spectrumApi.put(
    `spectrum-campaigns/opportunity/${id}?status=COMPLETED_MANUAL_PAYOUT`
  );
};

export const manualReferralPayout = (id: string): Promise<any> => {
  return spectrumApi.put(`referrals/${id}?status=COMPLETED_MANUAL_PAYOUT`);
};

export const approveReferralPayout = (id: number): Promise<any> => {
  return spectrumApi
    .put(`referrals/approve-payout`, {
      json: { referrals: [id] },
    })
    .json();
};

export const getOpportunitiesForChannel = (
  channelId?: string,
  params?: IDistributionPartnerCampaignsQueryParams
): Promise<IDistributionPartnerOpportunityDTO[]> => {
  const urlParams = new URLSearchParams();
  let url = `spectrum-campaigns/editor/channel/${channelId}/dashboard`;
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.statusList) {
    urlParams.append('statusList', params.statusList.join(','));
  }
  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const getChannelStatisticData = (
  channelId?: string
): Promise<IDistributionPartnerChannelStatistics> => {
  return spectrumApi
    .get(`spectrum-campaigns/editor/channel/${channelId}/dashboard-statistics`)
    .json();
};

export const approveStatistics = (
  opportunityId?: string,
  updatedAnalitics?: IUpdateableAnalyticsDTO
): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${opportunityId}/review-analytics/approve`;
  const data = {
    reactions: updatedAnalitics?.reactions,
    shares: updatedAnalitics?.shares,
    comments: updatedAnalitics?.comments,
    impressions: updatedAnalitics?.impressions,
    videoSaves: updatedAnalitics?.videoSaves,
    numberOfFollowers: updatedAnalitics?.numberOfFollowers,
  };
  return spectrumApi.post(url, { json: data }).json();
};

export const getDomains = (): Promise<any> => {
  const url =
    'https://api.rebrandly.com/v1/domains?orderBv=createdAt&orderDir=desc&limit=100&active=true&tvoe=user';
  return fetch(url, {
    method: 'GET',
    headers: {
      apiKey: 'daabe7cc7dcc4cbdb652eac4ec6fe654',
    },
  }).then((res) => res.json());
};

export const toggleRebrandlyUrl = (
  value: boolean | undefined,
  id?: string
): Promise<any> => {
  const url = `spectrum-campaigns/editor/campaign/${id}`;

  const RebrandlyUrlValue = {
    enableRebrandlyUrl: value,
  };

  return spectrumApi.put(url, { json: RebrandlyUrlValue }).json();
};

export const updateMaxSpendValue = (
  value: number | undefined,
  id?: string
): Promise<any> => {
  const url = `spectrum-campaigns/editor/campaign/${id}`;

  const maxSpent = {
    maxSpent: value as number,
  };

  return spectrumApi.put(url, { json: maxSpent }).json();
};
export const updateBoostValue = (
  value: number | undefined,
  id?: string
): Promise<any> => {
  const url = `spectrum-campaigns/editor/campaign/${id}`;

  const boostSpend = {
    boostingSpend: value as number,
  };

  return spectrumApi.put(url, { json: boostSpend }).json();
};

export const declineStatistics = (
  opportunityId?: string,
  needsWorkNote?: string
): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${opportunityId}/review-analytics/decline`;
  return spectrumApi
    .post(url, {
      json: {
        needsWorkNote: needsWorkNote,
      },
    })
    .json();
};

export const updateChannel = (
  form: IDistributionPartnerAddChannelForm,
  id?: string
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/editor/channel/${id}`, { json: form })
    .json();
};

export const getChannelsCSV = (): Promise<any> => {
  return spectrumApi
    .get(`spectrum-campaigns/channels/export-csv`, { timeout: 30000 })
    .blob();
};
