import {
  IAdminChannelQueryParams,
  IDistributionPartnerChannelSelectableRow,
  REFERRALS_STATUS_MAP_STRATEGY,
  REFERRALS_TRANSACTION_STATUS_MAP_STRATEGY,
} from '_State/DistributionPartner/distributionPartner.types';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { GridCellParams } from '@material-ui/data-grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _ from 'lodash';
import {
  ReferralStatus,
  ReferralPaymentStatus,
} from '_State/DistributionPartner/distributionPartner.enums';
import {
  getGridStringOperators,
  GridColumnHeaderParams,
  GridColumns,
} from '@material-ui/data-grid';
import { CustomHeader } from './CustomHeader';
import { EligibleReferralsActions } from './EligibleReferralsActions';
import { ChannelStatusCell } from '../Channels/ChannelStatusCell';

export const getReferralStatus = (key: string): ReferralStatus | string => {
  if ((ReferralStatus as any)[(ReferralStatus as any)[key]]) {
    return ReferralStatus[key as keyof typeof ReferralStatus];
  }
  return '';
};
export const getReferralTransactionStatus = (
  key: string
): ReferralPaymentStatus | string => {
  if ((ReferralPaymentStatus as any)[(ReferralPaymentStatus as any)[key]]) {
    return ReferralPaymentStatus[key as keyof typeof ReferralPaymentStatus];
  }
  return '';
};

export const formatString = (str?: string): string => {
  return _.startCase(_.toLower(str));
};

export const createReferralsTableConfig = (
  onManualPay: (id?: string) => void,
  onApprovePayout: (id?: string) => void,
  onSortingColumn: (value: string, value2: string) => void,
  parameters: IAdminChannelQueryParams
): GridColumns => {
  const onSort = (value: string, value2: string) => {
    const param = value2 ? value : 'createdDate';
    const param2 = value2 ? value2 : '';
    onSortingColumn(param, param2);
  };

  return [
    {
      headerName: 'Full Name',
      field: 'fullName',
      width: 150,
      sortable: false,
      renderHeader: () => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Full Name"
            field="fullName"
          />
        );
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
    },
    {
      headerName: 'Wallet Id',
      field: 'wallet',
      width: 200,
      type: 'string',
      filterable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Wallet Id"
            field="wallet"
          />
        );
      },
    },
    {
      headerName: 'Referred Name',
      field: 'referrerName',
      width: 150,
      sortable: false,
      renderHeader: () => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Referred Name"
            field="referrerName"
          />
        );
      },
    },
    {
      headerName: 'Earned',
      field: 'earned',
      width: 100,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Earned"
            field="earned"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return <div>{value ? `$ ${value}.00` : '-'}</div>;
      },
    },
    {
      headerName: 'Сompletion Date',
      field: 'completedDate',
      width: 200,
      sortable: false,
      filterable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Completion Date"
            field="completedDate"
          />
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 210,
      filterable: false,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Status"
            field="status"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (REFERRALS_STATUS_MAP_STRATEGY as any)[
                getReferralStatus(value?.toString() || '')
              ]
            }
          />
        );
      },
    },
    {
      headerName: 'Payment Status',
      field: 'transactionStatus',
      width: 170,
      filterable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Payment Status"
            field="transactionStatus"
          />
        );
      },
      renderCell({ value, row }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (REFERRALS_TRANSACTION_STATUS_MAP_STRATEGY as any)[
                getReferralTransactionStatus(value?.toString() || '')
              ]
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (
        params: GridCellParams
      ): ReactElement<any, string | JSXElementConstructor<any>> => {
        const row = params.row as IDistributionPartnerChannelSelectableRow;
        return (
          <>
            {row.transactionStatus ===
              ReferralStatus[ReferralStatus.COMPLETED] ||
            row.status ===
              ReferralStatus[ReferralStatus.COMPLETED_MANUL_PAYOUT] ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CheckCircleIcon
                  style={{
                    color: 'green',
                  }}
                />
              </div>
            ) : (
              <EligibleReferralsActions
                onApprovePayout={onApprovePayout}
                onManualPay={onManualPay}
                referralId={row.id.toString()}
                status={row.status}
                defaultWallet={row.wallet}
                transactionStatus={row.transactionStatus}
              />
            )}
          </>
        );
      },
    },
  ];
};
