import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Arrow } from './Arrow.svg';
import {
  PlxLinkWrapper,
  IProps as IPlxLinkWrapperProps,
} from '../PlxLinkWrapper/PlxLinkWrapper';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';

// TODO: remove important
const PlxStepButtonBody = styled(PlxLinkWrapper)<
  IPlxLinkWrapperProps & IThemed
>`
  display: inline-flex;
  color: ${({ theme }: IThemed) => theme.palette.primary.main} !important;
`;

const Text = styled.span<IThemed>`
  margin-left: ${({ theme }) => theme.spacing(1)};
  display: inline-flex;
`;

export enum StepButtonType {
  FORWARD,
  BACK,
}

const ArrowForward = styled(Arrow)<IThemed>`
  transform: rotate(180deg);
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

interface IProps extends ICommonProps {
  linkTo: string;
  text?: string;
  type: StepButtonType;
  testId?: string;
}

export const PlxStepButton: FC<IProps> = ({
  linkTo,
  text,
  type,
  className,
  testId,
}) => {
  return (
    <PlxStepButtonBody to={linkTo} className={className} data-test-id={testId}>
      {type === StepButtonType.BACK ? (
        <>
          <Arrow />
          <Text>{text}</Text>
        </>
      ) : (
        <>
          <Text>{text}</Text>
          <ArrowForward />
        </>
      )}
    </PlxStepButtonBody>
  );
};
