import { Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxLoader } from '_Components/PlxLoader';
import { PackageGroup } from '_Routes/Home/_Components/Payments/PackageGroup/PackageGroup';
import { ISectionProps } from '_Routes/Home/_Components/Sections/types';
import { IPackageControlConfig } from '../Payments/PackageControl/PackageControl.types';

interface IProps extends ISectionProps {
  packages: IPackageControlConfig[];
  isThrivePayment: boolean;
  campaignPackage: string;
  paymentPlan: string;
}
export const PackageSection: FC<IProps> = ({
  setValue,
  fieldName,
  packages,
  title,
  // required,
  // tooltip,
  value,
  isThrivePayment,
  campaignPackage,
  paymentPlan,
}) => {
  const { t } = useTranslation();
  const [val, setVal] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleSelect = (option: string, type: string, id?: number | null) => {
    setValue(fieldName, option, undefined, type, id);
  };
  useEffect(() => {
    setLoading(true);
    if (value) {
      setVal(value);
    }
  }, [value]);
  useEffect(() => {
    if (packages.length > 1) {
      setLoading(false);
    }
  }, [packages]);
  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            {t(`${title}`)}
          </Typography>
          <PackageGroup
            packages={packages}
            onSelect={handleSelect}
            value={val}
            isThrivePayment={isThrivePayment}
            campaignPackage={campaignPackage}
            paymentPlan={paymentPlan}
          />
        </>
      )}
    </>
  );
};
