import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReviewingMessage } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/ReviewingMessage';

const Wrapper = styled.div`
  display: flex;
  // margin-top: 3.5rem;
  /* min-height: 90vh; */
  align-items: center;
  .PlxPanel-body {
    // height: 43rem;
    // width: 77rem;
    // margin: auto;
    // padding: 5rem;
  }
  .PlxPanel-content {
    width: 80%;
    margin: 0 auto;
  }
`;

export const DistributionPartnerReviewingChannel: FC = () => {
  return (
    <Wrapper>
      <ReviewingMessage />
    </Wrapper>
  );
};
