import moment from 'moment';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSpectrumAnalyticsCSV } from '_State/Spectrum/Api/spectrum-admin.api';
import { ExportDropDownMenu } from './ExportDropDownMenu';
import { SpectrumExportButtonX } from './SpectrumExportButtonX';

export interface IExportOptions {
  label: string;
  action: () => void;
  disabled?: boolean;
}

interface IProps {
  startDate: string;
  endDate: string;
  id?: number;
}

export const SpectrumExportButton: FC<IProps> = ({
  startDate,
  endDate,
  id,
}) => {
  const { t } = useTranslation();
  const [isSubExtended, setIsSubExtended] = useState(false);

  const start = startDate === 'Invalid date' ? '2020-01-01' : startDate;
  const end =
    endDate === 'Invalid date'
      ? moment(new Date()).format('yyyy-MM-DD')
      : endDate;

  const handleOpenSubMenu = () => {
    setIsSubExtended((prevstate) => !prevstate);
  };

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleSpectrumCampaignsCSV = () => {
    getSpectrumAnalyticsCSV(start, end, id).then((data) => {
      createDownloadCSV(data, 'spectrum-analytics');
    });
    setIsSubExtended(false);
  };

  const exportOptions: IExportOptions[] = [
    {
      label: 'Export as CSV',
      action: handleSpectrumCampaignsCSV,
    },
    // add new options here
  ];

  return (
    <SpectrumExportButtonX
      isExtended
      isSubExtended={isSubExtended}
      isFocused
      label={t('spectrum.overview.export')}
      subNavigation={
        <ExportDropDownMenu
          open
          isExtended={isSubExtended}
          items={exportOptions}
        />
      }
      onClick={handleOpenSubMenu}
    />
  );
};
