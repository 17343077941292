export interface IVisibility {
  [key: string]: {
    reactions: boolean;
    shares: boolean;
    comments: boolean;
  };
}

export const initialVisibility: IVisibility = {
  'campaign-1': {
    reactions: true,
    shares: true,
    comments: true,
  },
  'campaign-2': {
    reactions: true,
    shares: true,
    comments: true,
  },
  'campaign-3': {
    reactions: true,
    shares: true,
    comments: true,
  },
};

export type DataColor = {
  name: string;
  data: {
    name: 'reactions' | 'shares' | 'comments';
    color: string;
  }[];
};

export const dataColors: DataColor[] = [
  {
    name: 'campaign-1',
    data: [
      { name: 'reactions', color: '#DCA8E4' },
      { name: 'comments', color: '#BC65CA' },
      { name: 'shares', color: '#9D05B0' },
    ],
  },
  {
    name: 'campaign-2',
    data: [
      { name: 'reactions', color: '#AFD7DD' },
      { name: 'comments', color: '#11DFEF' },
      { name: 'shares', color: '#008B9C' },
    ],
  },
  {
    name: 'campaign-3',
    data: [
      { name: 'reactions', color: '#FBE766' },
      { name: 'comments', color: '#F9D700' },
      { name: 'shares', color: '#E0B028' },
    ],
  },
];

export const visibilityLabels = {
  reactions: 'Reactions',
  shares: 'Shares',
  comments: 'Comments',
};
