import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { themeGetters } from '_Styles/themeHelpers';
import { Divider } from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import { PlxTooltip } from '_Components/PlxTooltip/PlxTooltip';
import { EditIcon } from '_Styles/images/_Icons/EditIcon';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';

const { color } = themeGetters;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #eaeef1;
  width: 100%;
  height: 100%;
`;

const LabelWrapper = styled.div`
  display: inherit;
`;

const Header = styled.div<IThemed & { withButton?: boolean }>`
  color: ${color('primary', 'contrastText')};
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${({ withButton }) =>
    withButton ? '1rem 3.5rem' : '2.3rem 3.5rem'};
  justify-content: space-between;
  white-space: nowrap;
`;

const MainDivider = styled(Divider)`
  && {
    width: 100%;
    background-color: #eaeef1;
  }
`;

const StyledTooltip = styled(PlxTooltip)`
  margin-left: 2rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledEditIcon = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export interface IInfoPanelProps extends ICommonProps {
  label: string;
  editable?: boolean;
  tooltip?: string;
  onEditClick?: () => void;
  withButton?: boolean;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

export const PlxInfoPanel: FC<IInfoPanelProps> = ({
  label,
  children,
  tooltip,
  editable,
  className,
  onEditClick,
  withButton,
  onButtonClick,
  buttonLabel,
}) => {
  return (
    <Wrapper className={className}>
      <Header withButton={withButton}>
        <LabelWrapper>
          {label}
          {tooltip && <StyledTooltip tooltipText={tooltip} />}
        </LabelWrapper>
        {editable && (
          <StyledEditIcon onClick={onEditClick}>
            <EditIcon />
          </StyledEditIcon>
        )}
        {withButton && (
          <PlxButton
            size={'small'}
            label={buttonLabel}
            onClick={onButtonClick}
          />
        )}
      </Header>
      <MainDivider />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
