import React, { FC, useEffect, useState } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import styled from '@emotion/styled';
import { IStockCompany } from '_Types/campaigns';
import { StockSelect } from '_Routes/Home/_Components/Sections/StockSection/StockSelect';
import { ReactComponent as TrashIcon } from './Trash.svg';
import { Button } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const AddButton = styled(PlxButton)`
  && {
    width: 215px;
  }
`;
export const NewButton = styled(PlxButton)`
  && {
    display: inline-flex;
    padding: 3px;
    border: none;
    color: black;
    height: 20px;
    :hover {
      background: #fff;
      color: #000;
      border: none;
    }
  }
`;

const DeleteButton = styled(Button)`
  && {
    margin-left: 1rem;
    border: 1px solid #ff0000;

    :hover {
      border: 1px solid #ff0000;
      background-color: #fee2e5;
      transition: all 0.3s;
    }
    :active {
      background-color: #fee2e5 !important;
    }
  }
`;
interface IProps {
  newDesign?: boolean;
  value?: any;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName: string;
  onDelete: () => void;
  deleteAll?: boolean;
}
export const StockSection: FC<IProps> = ({
  value,
  setValue,
  fieldName,
  onDelete,
  deleteAll,
  newDesign,
}) => {
  const [numChildren, setNumChildren] = useState(0);
  const [stockTickers, setStockTickers] = useState<IStockCompany[]>([]);
  const handleDeleteClick = () => {
    if (numChildren > 0) {
      setNumChildren(numChildren - 1);
      onDelete();
    } else if (numChildren === 0 && deleteAll) {
      setNumChildren(numChildren - 1);
      onDelete();
    }
  };
  useEffect(() => {
    if (value) {
      setStockTickers(value);
      setNumChildren(value.length - 1);
    }
  }, [value]);

  return (
    <>
      {numChildren > -1 && deleteAll && (
        <StockSelect
          newDesign={newDesign}
          value={stockTickers[0]}
          fieldName={`${fieldName}[0]`}
          setValue={setValue}
        />
      )}
      {!deleteAll && (
        <StockSelect
          newDesign={newDesign}
          value={stockTickers[0]}
          fieldName={`${fieldName}[0]`}
          setValue={setValue}
        />
      )}

      {Array.from({ length: numChildren }, (_, i) => (
        <StockSelect
          newDesign={newDesign}
          key={i}
          value={stockTickers[i + 1]}
          fieldName={`${fieldName}[${i + 1}]`}
          setValue={setValue}
        />
      ))}
      <ButtonsWrapper>
        {newDesign ? (
          <>
            <Add
              onClick={() => {
                setNumChildren(numChildren + 1);
              }}
              style={{
                display: 'inline-flex',
                marginTop: '8px',
                fill: '#fff',
                background: '#000',
                borderRadius: '2px',
                height: '15px',
                width: '15px',
              }}
            />
            <NewButton
              variant={ButtonVariant.Outlined}
              label="Add New"
              onClick={() => {
                setNumChildren(numChildren + 1);
              }}
            />
          </>
        ) : (
          <AddButton
            variant={ButtonVariant.Outlined}
            label="ADD"
            onClick={() => {
              setNumChildren(numChildren + 1);
            }}
          />
        )}

        {newDesign && numChildren > 0 && (
          <>
            <Remove
              onClick={handleDeleteClick}
              style={{
                display: 'inline-flex',
                marginTop: '8px',
                fill: '#fff',
                background: '#ff0000',
                borderRadius: '2px',
                height: '15px',
                width: '15px',
              }}
            />
            <NewButton
              variant={ButtonVariant.Outlined}
              label="Remove"
              onClick={handleDeleteClick}
            />
          </>
        )}
        {newDesign && deleteAll && numChildren === 0 && (
          <>
            <Remove
              onClick={handleDeleteClick}
              style={{
                display: 'inline-flex',
                marginTop: '8px',
                fill: '#fff',
                background: '#000',
                borderRadius: '2px',
                height: '15px',
                width: '15px',
              }}
            />
            <NewButton
              variant={ButtonVariant.Outlined}
              label={'Remove'}
              onClick={handleDeleteClick}
            />
          </>
        )}
        {!newDesign && numChildren > 0 && (
          <DeleteButton onClick={handleDeleteClick} variant={'outlined'}>
            <TrashIcon />
          </DeleteButton>
        )}
        {!newDesign && deleteAll && numChildren === 0 && (
          <DeleteButton onClick={handleDeleteClick} variant={'outlined'}>
            <TrashIcon />
          </DeleteButton>
        )}
      </ButtonsWrapper>
    </>
  );
};
