import { InputBase, makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';

export const useStyles = makeStyles({
  root: {
    height: '40px',
    width: '40px',
    margin: '16px 0',
  },
  popover: {
    marginTop: 20,
    borderRadius: 4,
    color: '#000',
  },
  menuPopover: {
    marginTop: 10,
    borderRadius: 4,
    overflow: 'hidden',
    color: '#000',
  },
  button: {
    height: '6rem',
    margin: '0.6rem 0',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'inherit',
    },
  },
});

export const StyledNotificationTitle = styled.h2`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(25, 39, 57, 0.94);
`;

export const StyledButtonRead = styled.button`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #231f20;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledNotificationMessage = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #231f20;
`;
export const StyledNotificationMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
`;
export const Time = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5f5f61;
  padding-left: 12px;
  margin-top: 8px;
`;

export const Wrapper = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  position: sticky;
  background: #fff;
`;

export const CompanyName = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 72px;
  display: flex;
  margin: auto 0;
  letter-spacing: 0.01em;
  color: #231f20;
`;
export const MenuItem = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 72px;
  display: flex;
  margin: auto 0;
  letter-spacing: 0.01em;
  color: #231f20;
`;

export const IconContainer = styled.span`
  opacity: 0.43;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0 16px 12px;
`;
export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  margin: 16px 0 16px 12px;
`;
export const VerticalDivider = styled.div`
  background: #d4d4d4;
  border-radius: 10px;
  width: 1px;
  margin: 20px 16px 20px 5px;
`;

export const AvatarImage = styled.img`
  height: 45px;
  width: 45px;
  cursor: pointer;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 72px;
`;

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  background: '#fff',
  border: '1px solid rgba(35, 31, 32, 0.16)',
  borderRadius: '6px',
  marginRight: '2rem',
  padding: '0 0 0 2rem',
  marginLeft: 0,
  width: '258px',
  height: '40px',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '0 1rem 1rem 1rem',
    width: '100%',
  },
}));
