import React, { FC } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

interface IProps {
  path: string;
}

const StyledButton = styled(PlxButton)`
  && {
    height: 3.5rem;
  }
`;

export const SeeCampaign: FC<IProps> = ({ path }) => {
  const history = useHistory();

  return (
    <StyledButton
      testId="see-campaign-button"
      label={'See campaign'}
      onClick={() => {
        history.push(path);
      }}
      variant={ButtonVariant.Outlined}
    />
  );
};
