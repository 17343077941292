import React, { useState } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from 'recharts';

import GraphContainer from '../graphContainer/GraphContainer';
import { data } from './data';
import { useTranslation } from 'react-i18next';
import { CustomizedTooltip } from './CustomizedTooltip';
import SpectrumButton from '../../../SpectrumDashboard/_Component/SpectrumButton/SpectrumButton';
import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

export interface IDailyData {
  name?: string;
  Interactions?: number;
  Engagement_Rate?: number;
}
interface IStyledCheckBox {
  checkboxcolor?: string;
}

export const StyledCheckbox = styled(Checkbox)<IStyledCheckBox>`
  span {
    svg {
      color: ${(props: IStyledCheckBox) => props.checkboxcolor};
    }
  }
`;

const dataColors = [
  { name: 'Interactions', color: '#9D05B0' },
  { name: 'Interactions_cumulative', color: '#DCA8E4' },
  { name: 'Engagement_Rate', color: '#008B9C' },
];

interface IColor {
  name: string;
  color: string;
}

export interface IVisibility {
  [key: string]: boolean;
}
export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const EngagementBenchmarkGraph = () => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState<IVisibility>({
    Interactions: true,
    Engagement_Rate: true,
    Interactions_cumulative: true,
  });

  const getDailyData = (label: string): IDailyData | undefined => {
    return data.find((el) => el.name === label);
  };
  const handleChange = (arg: string) => {
    setVisibility((prev: IVisibility) => {
      return { ...visibility, [arg]: !prev[arg] };
    });
  };
  return (
    <>
      <GraphContainer
        title={t('spectrum.overview.CampaignEngagementTitle')}
        widgetDescription={t(
          'spectrum.overview.EngagementBenchmarkDescription'
        )}
        tooltipText={t('spectrum.overview.EngagementBenchmarkTooltip')}
        height={600}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data}
              margin={{
                top: 25,
                right: 15,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                label={{
                  dy: 15,
                  fontSize: 14,
                }}
              />
              <YAxis
                label={{
                  value: `${t('spectrum.overview.Interactions')}`,
                  angle: -90,
                  dx: -15,
                  fontSize: 14,
                }}
              />
              <YAxis
                yAxisId={2}
                orientation="right"
                label={{
                  value: `${t('spectrum.overview.Engagement Rate')} (%)`,
                  angle: 90,
                  dx: 25,
                  fontSize: 14,
                }}
              />
              <Tooltip
                cursor={{ fill: '#f5f5f5' }}
                content={
                  <CustomizedTooltip
                    getDailyData={getDailyData}
                    dataColors={dataColors}
                    visibility={visibility}
                  />
                }
              />
              {visibility.Interactions && (
                <Bar
                  dataKey="Interactions"
                  barSize={10}
                  fill="#9D05B0"
                  isAnimationActive={false}
                  stackId="Interactions"
                />
              )}
              {visibility.Interactions_cumulative && (
                <Bar
                  dataKey="Interactions_cumulative"
                  barSize={10}
                  fill="#DCA8E4"
                  isAnimationActive={false}
                  stackId="Interactions"
                />
              )}

              {visibility.Engagement_Rate && (
                <Line
                  strokeWidth={3}
                  dataKey="Engagement_Rate"
                  stroke="#008B9C"
                  isAnimationActive={false}
                  yAxisId={2}
                  activeDot={{
                    stroke: '#086E7A',
                    strokeWidth: 2,
                    r: 5,
                    strokeDasharray: '0',
                    fill: 'white',
                  }}
                  dot={{
                    stroke: 'white',
                    strokeWidth: 2,
                    r: 8,
                    strokeDasharray: '0',
                    fill: '#086E7A',
                  }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <div
            style={{
              marginLeft: 15,
              height: 60,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'baseline',
              width: '100%',
            }}
          >
            <FormControl component="fieldset">
              <FormGroup style={{ flexDirection: 'row', width: '100%' }}>
                {dataColors.map((el: IColor, index: number) => (
                  <FormControlLabel
                    key={el.name + index}
                    control={
                      <StyledCheckbox
                        checked={visibility[el.name]}
                        onChange={() => handleChange(el.name)}
                        checkboxcolor={el.color}
                      />
                    }
                    label={capitalize(
                      el.name
                        .replace('_', ' ')
                        .replace('cumulative', '(cumulative)')
                    )}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <div style={{ marginRight: '24px' }}>
              <SpectrumButton text={t('spectrum.overview.seeDetails')} />
            </div>
          </div>
        </div>
      </GraphContainer>
    </>
  );
};

export default EngagementBenchmarkGraph;
