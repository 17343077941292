import { Dialog } from '@material-ui/core';
import React, { FC } from 'react';

import { PaymentFailure } from './PaymentFailure';

interface IProps {
  open: boolean;
  description: string;
  onClose?: () => void;
}

export const PaymentFailureDialog: FC<IProps> = ({
  open,
  onClose,
  description,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <PaymentFailure description={description} onClose={handleClose} />
    </Dialog>
  );
};
