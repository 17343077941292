import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { FormSection } from '_Components/FormSection/FormSection';
import { ISectionProps } from '_Routes/Home/_Components/Sections/types';

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    width: 100%;
    background: white;
    .MuiSvgIcon-root {
      top: auto;
    }
  }
`;
interface IProps extends ISectionProps {
  placeholder: string;
  selectConfig: any[];
}

export const MultiSelectSection: FC<IProps> = ({
  value,
  setValue,
  fieldName,
  title,
  tooltip,
  selectConfig,
  required,
  placeholder,
}) => {
  const [selectValue, setSelectValue] = useState<string[]>([]);
  useEffect(() => {
    setSelectValue(value);
  }, [value]);
  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const targetValue = event.target.value as string[];
    setSelectValue(targetValue);
    setValue(fieldName, targetValue);
  };
  return (
    <FormSection title={title} tooltip={tooltip} required={required}>
      <div>
        <StyledMultipleSelect
          onChange={handleChange}
          size={'medium'}
          value={selectValue}
          options={selectConfig}
          label={placeholder}
          placeholder={true}
        />
      </div>
    </FormSection>
  );
};
