import React, { FC, useEffect, useState } from 'react';
import { getDraft } from '_State/Spectrum/Api/spectrum.api';
import { getProgramPaymentInfo } from '_Api/auth';
import { useTranslation } from 'react-i18next';
import { PayViaWireTransfer } from '../../../../_Components/Payments/PayViaWireTransfer/PayViaWireTransfer';

export const SpectrumPayViaWire: FC = () => {
  const [amount, setAmount] = useState('');
  const { t } = useTranslation();
  const PAYMENT_BACKPATH = '/home/client/spectrum-campaign/payment';
  const PAYMENT_NOTIFICATION = 'spectrum.payment.notification';

  useEffect(() => {
    getDraft().then((v) => {
      getProgramPaymentInfo([v.programId]).then((payment: any) => {
        setAmount(payment.content[0].amount);
      });
    });
  }, []);

  return (
    <PayViaWireTransfer
      amount={amount}
      backPath={PAYMENT_BACKPATH}
      notification={t(PAYMENT_NOTIFICATION)}
    />
  );
};
