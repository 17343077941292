import styled from '@emotion/styled';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxDatePicker } from '_Components/PlxDatePicker/PlxDatePicker';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { IOrderDTO, IOrderEditForm } from '_State/User/user.types';
import { ButtonsWrapper } from '../../../Spectrum/AdminSpectrumCampaign/Styles';

const Body = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

const INITIAL_VALUES: IOrderEditForm = {
  followers: undefined,
  remainingCampaigns: undefined,
  expiryDate: undefined,
  invoiceId: undefined,
};

const REQUIRED_FIELD = ['remainingCampaigns'];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELD);

interface IProps {
  userId?: string;
  agencyClientId?: string;
  data?: IOrderDTO[];
  onBack: () => void;
  handleCreateOrder: (form: IOrderEditForm) => Promise<any>;
}

export const NewOrder: FC<IProps> = ({
  onBack,
  data,
  userId,
  agencyClientId,
  handleCreateOrder,
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const { t } = useTranslation();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELD,
    t('errors.reqField')
  );

  const invoiceIds = data?.map((i) => {
    return i.invoiceId;
  });

  useEffect(() => {
    const today = new Date();
    setExpiryDate(today);
  }, []);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        handleCreateOrder(form).then(
          () => {
            setIsProcessing(false);
            onBack();
          },
          (error) => {
            error.response
              .json()
              .then((error: any) => setApiErrorMessage(error.message));
          }
        );
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.remainingCampaigns === undefined) {
        errors.remainingCampaigns = 'Required';
      }
      if (form.remainingCampaigns && form.remainingCampaigns < 1) {
        errors.remainingCampaigns = 'Value must be > 0';
      }
      if (form.remainingCampaigns && form.remainingCampaigns > 2147483647) {
        errors.remainingCampaigns = 'Value is too big';
      }
      if (form.totalCampaigns === undefined) {
        errors.totalCampaigns = 'Required';
      }
      if (form.totalCampaigns && form.totalCampaigns < 1) {
        errors.totalCampaigns = 'Value must be > 0';
      }
      if (form.totalCampaigns && form.totalCampaigns > 2147483647) {
        errors.totalCampaigns = 'Value is too big';
      }
      if (form.followers === undefined) {
        errors.followers = 'Required';
      }
      if (form.followers && form.followers < 1) {
        errors.followers = 'Value must be > 0';
      }
      if (form.followers && form.followers > 2147483647) {
        errors.followers = 'Value is too big';
      }
      if (form.invoiceId === undefined) {
        errors.invoiceId = 'Required';
      }
      if (invoiceIds?.includes(form.invoiceId as string)) {
        errors.invoiceId = 'Invoice Id is already in use';
      }
      if (form.expiryDate === undefined) {
        errors.expiryDate = 'Required';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (
    id: string | undefined,
    agencyClientId: string | undefined
  ) => {
    if (agencyClientId) {
      formik.setValues({
        agencyClientId: agencyClientId,
        userId: id,
      });
    } else {
      formik.setValues({
        userId: id,
      });
    }
  };

  useEffect(() => {
    fillForm(userId, agencyClientId);
    // eslint-disable-next-line
  }, [userId]);

  const handlePostedDateChange = (date: Date | null) => {
    const datePosted = moment(date).format('YYYY-MM-DD[T]HH:MM:ss');
    formik.setFieldValue('expiryDate', datePosted);
    setExpiryDate(date);
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Body>
      <InputSectionX
        required
        typeNumber
        title={t('admin.users.orders.remaining')}
        fieldName={'remainingCampaigns'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.remainingCampaigns} />
      <InputSectionX
        required
        typeNumber
        title={t('admin.users.orders.total')}
        fieldName={'totalCampaigns'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.totalCampaigns} />
      <div>
        <FormSection title={t('admin.users.orders.expire')} required>
          <PlxDatePicker
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            placeholder={t(
              'opportunities.submitAnalytics.datePostedPlaceholder'
            )}
            value={expiryDate}
            fullwidth={true}
            onChange={handlePostedDateChange}
            minDate={new Date()}
          />
        </FormSection>
        <FieldErrorMessage message={formik.errors.expiryDate} />
      </div>
      <InputSectionX
        required
        typeNumber
        title={t('admin.users.orders.followers')}
        fieldName={'followers'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.followers} />
      <InputSectionX
        required
        title={t('admin.users.orders.invoiceId')}
        fieldName={'invoiceId'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.invoiceId} />
      <FieldErrorMessage message={apiErrorMessage} />
      <ButtonsWrapper>
        <PlxButton
          label={t('admin.users.orders.save')}
          onClick={formik.handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
          testId="submit-button"
        />
        <PlxButton
          label={t('admin.users.orders.close')}
          onClick={handleBack}
          variant={ButtonVariant.Outlined}
          testId="back-button"
        />
      </ButtonsWrapper>
    </Body>
  );
};
