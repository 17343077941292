import styled from '@emotion/styled';
import { InputBase } from '@material-ui/core';

export const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  background: '#fff',
  border: '1px solid rgba(35, 31, 32, 0.16)',
  borderRadius: '6px',
  marginBottom: '1.5rem',
  paddingLeft: '2rem',
  width: '55%',
  height: '40px',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  minWidth: '250px',
  '& .MuiInputBase-input': {
    width: '100%',
    paddingLeft: '1rem',
  },
}));

export const IconContainer = styled.span`
  opacity: 0.43;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
`;
