import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IWelcomePageFeature } from '../../types';

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'number label'
    '. description';
  grid-template-columns: 2fr 22fr;
  grid-template-rows: 1fr 2fr;
  width: 45rem;
  margin-bottom: 3rem;
`;
const Label = styled.div`
  grid-area: label;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: #1b1b1b;
  display: flex;
  align-items: center;
`;
const Description = styled.div`
  grid-area: description;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #61657e;
`;
const Number = styled.div`
  grid-area: number;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.8rem;
  color: #3c6ef6;
  display: flex;
  align-items: center;
`;

export const ExampleFeature: FC<IWelcomePageFeature> = ({
  label,
  number,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Number>{number}</Number>
      <Label>{t(label)}</Label>
      <Description>{t(description)}</Description>
    </Grid>
  );
};
