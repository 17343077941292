import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxPanel } from '../../../../../_Components/Panels/PlxPanel/PlxPanel';
import { ReactComponent as FailureIcon } from '_Styles/images/_Icons/Failure.svg';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: center;
  color: #767676;
  margin-bottom: 5rem;
`;
const Label = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  text-align: center;
  color: #1b1b1b;
`;

interface IProps {
  description: string;
  onClose: () => void;
}

export const PaymentFailure: FC<IProps> = ({ description, onClose }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        content={
          <Content>
            <FailureIcon width={40} />
            <Label>{t('payment.paymentFailure.label')}</Label>
            <Description>{t(description)}</Description>
            <PlxButtonX
              label={t('payment.paymentFailure.button')}
              onClick={onClose}
            />
          </Content>
        }
      />
    </Wrapper>
  );
};
