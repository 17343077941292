import React, { FC } from 'react';
import { ButtonProps, CircularProgress, Tooltip } from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import {
  ButtonX,
  ButtonXAnalytics,
  StyledButton,
  StyledButtonAnalytics,
  NotificationBadge,
  StyledEditIconButton,
  NewButtonX,
} from './ButtonXStyles';

export enum ButtonVariant {
  Contained,
  Outlined,
  TextButton,
}
export enum ButtonType {
  Primary,
  Secondary,
}

export interface IProps extends ICommonProps {
  newDesign?: boolean;
  label?: string;
  isDisabled?: boolean;
  variant?: ButtonVariant;
  type?: ButtonType;
  tooltip?: boolean;
  inputType?: 'submit';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  notificationQuantity?: number;
  fullWidth?: boolean;
  isProcessing?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  variant2?: boolean;
  testId?: string;
  validation?: boolean;
}

type MaterialPropsMapping = { [enumKey: number]: () => Partial<ButtonProps> };

const variantToMaterialProps: MaterialPropsMapping = {
  [ButtonVariant.Contained]: () => ({ variant: 'contained' }),
  [ButtonVariant.Outlined]: () => ({ variant: 'outlined' }),
  [ButtonVariant.TextButton]: () => ({ variant: 'text' }),
};

const typeToMaterialProps: MaterialPropsMapping = {
  [ButtonType.Primary]: () => ({ color: 'primary' }),
  [ButtonType.Secondary]: () => ({ color: 'secondary' }),
};

export const PlxButtonX: FC<IProps> = ({
  newDesign,
  label,
  children,
  variant = ButtonVariant.Contained,
  inputType,
  type = ButtonType.Primary,
  onClick,
  className,
  size = 'medium',
  isDisabled,
  notificationQuantity,
  fullWidth,
  isProcessing,
  color,
  variant2,
  testId,
  validation,
}) => {
  const variantProps = variantToMaterialProps[variant]();
  const typeProps = typeToMaterialProps[type]();
  return newDesign ? (
    <>
      <NewButtonX buttonV2={variant2} size={size} validation={validation}>
        <StyledButton
          color={color}
          fullWidth={fullWidth}
          className={className}
          type={inputType}
          {...variantProps}
          {...typeProps}
          onClick={onClick}
          size={size}
          disabled={isDisabled || isProcessing}
          data-test-id={testId}
        >
          {isProcessing ? (
            <CircularProgress color={'secondary'} />
          ) : (
            <>
              {label}
              {children}
              {notificationQuantity && (
                <NotificationBadge>{notificationQuantity}</NotificationBadge>
              )}
            </>
          )}
        </StyledButton>
      </NewButtonX>
    </>
  ) : (
    <>
      <ButtonX buttonV2={variant2} size={size}>
        <StyledButton
          color={color}
          fullWidth={fullWidth}
          className={className}
          type={inputType}
          {...variantProps}
          {...typeProps}
          onClick={onClick}
          size={size}
          disabled={isDisabled || isProcessing}
          data-test-id={testId}
        >
          {isProcessing ? (
            <CircularProgress color={'secondary'} />
          ) : (
            <>
              {label}
              {children}
              {notificationQuantity && (
                <NotificationBadge>{notificationQuantity}</NotificationBadge>
              )}
            </>
          )}
        </StyledButton>
      </ButtonX>
    </>
  );
};

export const PlxButtonXAnalytics: FC<IProps> = ({
  label,
  children,
  variant = ButtonVariant.Contained,
  inputType,
  type = ButtonType.Primary,
  onClick,
  className,
  size = 'medium',
  isDisabled,
  notificationQuantity,
  fullWidth,
  isProcessing,
  color,
  variant2,
  tooltip,
}) => {
  const variantProps = variantToMaterialProps[variant]();
  const typeProps = typeToMaterialProps[type]();
  return (
    <>
      {/* {!buttonV2 ? ( */}
      {tooltip ? (
        <Tooltip title="Analytics cannot be submitted before Data Due date.">
          <ButtonXAnalytics>
            <StyledButtonAnalytics
              className={className}
              type={inputType}
              {...variantProps}
              {...typeProps}
              onClick={onClick}
              disabled={isDisabled || isProcessing}
            >
              {isProcessing ? (
                <CircularProgress color={'secondary'} />
              ) : (
                <>
                  {label}
                  {children}
                  {notificationQuantity && (
                    <NotificationBadge>
                      {notificationQuantity}
                    </NotificationBadge>
                  )}
                </>
              )}
            </StyledButtonAnalytics>
          </ButtonXAnalytics>
        </Tooltip>
      ) : (
        <ButtonXAnalytics>
          <StyledButtonAnalytics
            className={className}
            type={inputType}
            {...variantProps}
            {...typeProps}
            onClick={onClick}
            disabled={isDisabled || isProcessing}
          >
            {isProcessing ? (
              <CircularProgress color={'secondary'} />
            ) : (
              <>
                {label}
                {children}
                {notificationQuantity && (
                  <NotificationBadge>{notificationQuantity}</NotificationBadge>
                )}
              </>
            )}
          </StyledButtonAnalytics>
        </ButtonXAnalytics>
      )}
    </>
  );
};

export const PlxIconButton: FC<IProps> = ({ children, onClick }) => {
  return (
    <StyledEditIconButton onClick={onClick}>{children}</StyledEditIconButton>
  );
};
