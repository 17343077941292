import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  IDialogProps,
  PlxDialog,
} from '_Components/Dialogs/PlxDialog/PlxDialog';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { useTranslation } from 'react-i18next';
import { IThriveStatistics } from '_State/Thrive/types';
import { useFormik } from 'formik';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem;
  width: 100%;
`;

interface IProps extends IDialogProps {
  statistics?: IThriveStatistics;
  campaignId?: string | number;
  onSubmit: (form: any) => void;
}

const INITIAL_VALUES: IThriveStatistics = {
  numberOfLeadsGenderFemale: undefined,
  numberOfLeadsGenderMale: undefined,
};

export const GenderUpdateDialog: FC<IProps> = ({
  statistics,
  title,
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (statistics) {
      fillForm(statistics);
    }
    // eslint-disable-next-line
  }, [statistics]);

  const handleSave = () => {
    formik.handleSubmit();
  };

  const fillForm = (statistics: IThriveStatistics) => {
    formik.setValues({
      numberOfLeadsGenderFemale: statistics.numberOfLeadsGenderFemale,
      numberOfLeadsGenderMale: statistics.numberOfLeadsGenderMale,
    });
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      onSubmit(form);
    },
  });

  return (
    <PlxDialog
      title={title}
      open={open}
      onClose={onClose}
      onSave={handleSave}
      closeButton
    >
      <StyledForm>
        <InputSection
          placeholder={t('thrive.admin.numberOfMaleLeads')}
          fieldName={'numberOfLeadsGenderMale'}
          value={statistics?.numberOfLeadsGenderMale}
          title={t('thrive.admin.male')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
        <InputSection
          placeholder={t('thrive.admin.numberOfFemaleLeads')}
          fieldName={'numberOfLeadsGenderFemale'}
          value={statistics?.numberOfLeadsGenderFemale}
          title={t('thrive.admin.female')}
          setFieldValue={formik.setFieldValue}
          typeNumber
        />
      </StyledForm>
    </PlxDialog>
  );
};
