import styled from '@emotion/styled/macro';
import React, { FC } from 'react';

const Wrapper = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem 0 1.5rem 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  div {
    flex: 1;
  }
  div:nth-of-type(2) {
    flex: 0.5;
  }
  div:nth-of-type(3) {
    flex: 2;
  }
  div:nth-of-type(5) {
    text-align: center;
  }
`;

export const XemTableRow: FC = ({ children }) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
};
