import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { Link } from 'react-router-dom';
import { ICommonProps } from '_Types/props';

const LingWrapper = styled.div``;

const LinkContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  justify-content: right;
  width: 11rem;
  margin-left: auto;
  margin-right: 4rem;
  // order: 2;
  @media (max-width: 840px) {
    margin-right: 0;
  }
  @media (max-width: 650px) {
    margin-right: 4rem;
  }
`;

const Label = styled.p<IThemed>`
  // margin: auto;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: normal;
  font-size: 1rem;
`;

interface IProps extends ICommonProps {
  label?: string;
  pathName?: string;
  target?: string;
  testId?: string;
  marginLeft?: string;
}
export const PlxLink: FC<IProps> = ({
  label,
  pathName,
  className,
  target,
  testId,
  marginLeft,
}) => {
  return (
    <LingWrapper>
      <LinkContainer
        style={{ marginLeft: marginLeft ? marginLeft : undefined }}
        to={{ pathname: pathName }}
        target={target && target}
      >
        <Label className={className} data-test-id={testId}>
          {label}
        </Label>
      </LinkContainer>
    </LingWrapper>
  );
};
