import React, { FC, ReactChild } from 'react';
import { ICommonProps } from '_Types/props';

import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHover } from '_Routes/Home/_routes/Spectrum/utils';
import {
  iconStyles,
  CustomTooltip,
  StyledIconButton,
  NotificationBadge,
  StyledExpandIcon,
  buttonStyles,
  Children,
  Label,
  buttonStylesClient,
  ClientLabel,
  NotificationBadgeExtended,
} from './MenuIconButtonX.styles';

interface IProps extends ICommonProps {
  isExtended?: boolean;
  label?: string;
  onClick?: () => void;
  afterRedirect?: () => void;
  isFocused?: boolean;
  linkPath?: string;
  notificationQuantity?: number;
  subNavigation?: ReactChild;
  testId?: string;
  isAdmin?: boolean;
  client?: boolean;
}

export const MenuIconButtonX: FC<IProps> = ({
  label,
  isExtended,
  isFocused,
  onClick,
  afterRedirect,
  linkPath,
  children,
  className,
  notificationQuantity,
  subNavigation,
  testId,
  isAdmin,
  client,
}) => {
  const buttonClasses = buttonStyles();
  const buttonClassesClient = buttonStylesClient();
  const iconClasses = iconStyles();
  const history = useHistory();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const redirectTo = (path: string): void => {
    history.push(path);
  };
  const getButtonClass = (): string => {
    let classes = '';
    if (isFocused || isHovered) {
      if (client) {
        classes = `${
          !subNavigation
            ? buttonClassesClient.active
            : buttonClassesClient.children
        } `;
      } else {
        classes = `${
          !subNavigation ? buttonClasses.active : buttonClasses.children
        } `;
      }
    }
    const base =
      isExtended && client
        ? classes + `${buttonClassesClient.expanded}`
        : !isExtended && client
        ? classes + `${buttonClassesClient.rolled}`
        : !client && isExtended
        ? classes + `${buttonClasses.expanded}`
        : classes + `${buttonClasses.rolled}`;

    return `${base} ${className}`;
  };
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as React.ReactElement<{
          isFocused: boolean;
          isHovered?: boolean;
        }>,
        {
          isHovered,
          isFocused:
            (!subNavigation && isFocused) || (!subNavigation && isHovered),
        }
      );
    }
    return child;
  });

  return (
    <CustomTooltip
      title={label || ''}
      placement="right"
      disableFocusListener={isExtended}
      disableHoverListener={isExtended}
      style={{
        borderTop: client ? '1px solid #f2f2f2' : '',
        borderLeft:
          client && isFocused
            ? '3px solid #231F20'
            : client && isHovered
            ? '3px solid #231F20'
            : '',
      }}
    >
      <div>
        <div ref={hoverRef}>
          <StyledIconButton
            onClick={
              linkPath
                ? (): void => {
                    redirectTo(linkPath);
                    if (afterRedirect) {
                      afterRedirect();
                    }
                  }
                : onClick
            }
            className={getButtonClass()}
            classes={{
              root: client ? buttonClassesClient.root : buttonClasses.root,
            }}
            data-test-id={testId}
          >
            <Children
              className={isExtended ? iconClasses.expanded : iconClasses.rolled}
            >
              {notificationQuantity &&
                notificationQuantity > 0 &&
                !isExtended && (
                  <NotificationBadge>{notificationQuantity}</NotificationBadge>
                )}
              {childrenWithProps}
            </Children>
            {isExtended && client ? (
              <ClientLabel
                style={{
                  color: isFocused || isHovered ? '#231F20' : '',
                  opacity: isFocused || isHovered ? 1 : undefined,
                  fontWeight: isFocused || isHovered ? 700 : undefined,
                }}
              >
                {label}
              </ClientLabel>
            ) : isExtended ? (
              <Label>{label}</Label>
            ) : null}

            {notificationQuantity && notificationQuantity > 0 && isExtended && (
              <NotificationBadgeExtended>
                {notificationQuantity}
              </NotificationBadgeExtended>
            )}

            {!!subNavigation && isExtended && !isAdmin && (
              <StyledExpandIcon>
                {isFocused ? (
                  <KeyboardArrowDownIcon
                    style={{ color: isHovered ? '#fff' : '#231F20' }}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{ color: isHovered ? '#fff' : '#231F20' }}
                  />
                )}
              </StyledExpandIcon>
            )}
          </StyledIconButton>
        </div>
        {isExtended ? subNavigation : null}
      </div>
    </CustomTooltip>
  );
};
