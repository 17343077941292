import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';
import { StyledH1, StyledTip2, StyledValidationWindow } from '../../utils';
import { CheckCircle } from '@material-ui/icons';
import { ISpectrumProgram } from '_State/Spectrum/types';

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
`;

const Bold = styled.span`
  font-weight: 700;
  color: #000;
`;

const StyledWraper = styled(StyledValidationWindow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 16px auto;
`;
export const ValidatedCampaignInfo: FC<{ data: ISpectrumProgram }> = ({
  data,
}) => {
  const { t } = useTranslation();

  const date = new Date(data.expiryDate);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <StyledWraper>
      <StyledH1 style={{ marginBottom: '18px' }}>
        {t('spectrum.validation.campaign')}
      </StyledH1>
      <Divider />
      <StyledWrap>
        <CheckCircle
          style={{
            fill: '#258004',
            height: '16px',
            width: '16px',
            marginRight: '12px',
          }}
        />
        <StyledTip2>
          {t('spectrum.validation.campaigns')}
          <Bold> {data.remainingCampaigns}</Bold>
        </StyledTip2>
      </StyledWrap>
      <StyledWrap>
        <CheckCircle
          style={{
            fill: '#258004',
            height: '16px',
            width: '16px',
            marginRight: '12px',
          }}
        />
        <StyledTip2>
          {t('spectrum.validation.followers')}
          <Bold> {data?.estimatedReach || 'N/A'}</Bold>
        </StyledTip2>
      </StyledWrap>
      <StyledWrap>
        <CheckCircle
          style={{
            fill: '#258004',
            height: '16px',
            width: '16px',
            marginRight: '12px',
          }}
        />
        <StyledTip2>
          {t('spectrum.validation.expire')}
          <Bold> {formattedDate}</Bold>
        </StyledTip2>
      </StyledWrap>
    </StyledWraper>
  );
};
