import React, { FC, ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';
import {
  IInfoPanelProps,
  PlxInfoPanel,
} from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { UpdateValue } from './UpdateValue';
import { StockSection } from '_Routes/Home/_Components/Sections/StockSection/StockSection';
import DOMPurify from 'dompurify';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { updateCampaignInfo } from '_State/Spectrum/Api/spectrum-admin.api';
import { AdminNotes } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/AdminNotes';

const MainDivider = styled(Divider)`
  && {
    width: 100%;
    background-color: #eaeef1;
  }
`;

const Wrapper = styled.div``;
const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  text-overflow: ellipsis;
  height: 80rem;
  overflow-y: scroll;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  min-height: 5rem;
  justify-content: center;
`;

const InfoLabel = styled.div`
  color: #767676;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const AdminStockTicker = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

interface IProps extends IInfoPanelProps {
  config: ICampaignInfoItem[];
  className?: string;
  children?: ReactNode;
  handleInfoSubmit?: (key: string, value: string) => void;
  handleStockTicker?: (value: any) => void;
  campaignStatus?: string;
  instructionsValue?: string;
  updateInstructionValue?: (value: string) => void;
}

export interface ICampaignInfoItem {
  label: string;
  value?: string;
  key?: string;
  renderContent: (args?: unknown) => ReactNode;
}

export const PlxCampaignInformation: FC<IProps> = ({
  config,
  label,
  className,
  tooltip,
  children,
  editable,
  onEditClick,
  handleInfoSubmit,
  handleStockTicker,
  campaignStatus,
  instructionsValue,
  updateInstructionValue,
}) => {
  const [openInstruction, setOpenInstructions] = useState<boolean>(false);

  const items = useMemo(() => config, [config]);

  const handleDeleteStock = () => {
    if (handleStockTicker) {
      const stockTicker = items.filter(
        (item) => item.key === 'stockTickerList'
      )[0].value;
      const delStockTicker = stockTicker?.slice(0, stockTicker?.length - 1);
      handleStockTicker(delStockTicker);
    }
  };
  const handleFieldValue = (value: any, oldValue: any) => {
    if (handleStockTicker) {
      const allVal = [...oldValue, value];
      handleStockTicker(allVal);
    }
  };

  const handleOpenInstruction = () => {
    setOpenInstructions(true);
  };
  const handleCloseInstruction = () => {
    setOpenInstructions(false);
  };

  const renderListContent = (item: string) => {
    const sanitizedContent = DOMPurify.sanitize(item || '');
    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = sanitizedContent;

    const ulElements = wrapperDiv.getElementsByTagName('ul');
    for (let i = 0; i < ulElements.length; i++) {
      const ulElement = ulElements[i];
      ulElement.style.listStyleType = 'disc';
      ulElement.style.marginLeft = '1.5em';
    }

    const olElements = wrapperDiv.getElementsByTagName('ol');
    for (let i = 0; i < olElements.length; i++) {
      const olElement = olElements[i];
      olElement.style.listStyleType = 'decimal';
      olElement.style.marginLeft = '1.5em';
    }

    return <div dangerouslySetInnerHTML={{ __html: wrapperDiv.innerHTML }} />;
  };

  const parseHTML = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent;
  };

  return (
    <Wrapper className={className}>
      <PlxInfoPanel
        editable={editable}
        tooltip={tooltip}
        label={label}
        onEditClick={onEditClick}
      >
        <InfoWrapper>
          {items.map((item, index) => (
            <div key={index}>
              <Info>
                <InfoLabel>{item.label}</InfoLabel>
                {item.key === 'furtherInstructions' ? (
                  <div
                    style={{
                      cursor: updateInstructionValue ? 'pointer' : undefined,
                    }}
                    onClick={
                      updateInstructionValue ? handleOpenInstruction : undefined
                    }
                  >
                    {parseHTML(instructionsValue || '')?.length !== 0 ? (
                      renderListContent(instructionsValue || '')
                    ) : (
                      <span style={{ color: 'rgb(177, 177, 177)' }}>
                        click to edit
                      </span>
                    )}
                  </div>
                ) : campaignStatus === 'DRAFT' || campaignStatus === 'NEW' ? (
                  item.key === 'companyName' ||
                  item.key === 'creatorEmail' ||
                  item.key === 'creatorName' ||
                  item.key === 'id' ||
                  item.key === 'invoiceId' ||
                  item.key === 'createdDate' ||
                  item.key === 'payment.amount' ||
                  item.key === 'payment.paymentMethod' ? (
                    item.renderContent()
                  ) : item.key === 'stockTickerList' ? (
                    <AdminStockTicker>
                      {/* <div>{item?.value}</div> */}
                      <StockSection
                        value={item?.value}
                        fieldName={'stockTickerList'}
                        setValue={(value) =>
                          handleFieldValue(value, item?.value)
                        }
                        onDelete={handleDeleteStock}
                        deleteAll
                      />
                    </AdminStockTicker>
                  ) : item.key === 'InvoiceId' ? (
                    item.renderContent()
                  ) : (
                    <UpdateValue
                      keyValue={item?.key ? item?.key : ''}
                      value={item?.value ? item.value : ''}
                      handleSubmit={handleInfoSubmit}
                    />
                  )
                ) : (
                  item.renderContent()
                )}
              </Info>

              <MainDivider />
            </div>
          ))}
        </InfoWrapper>
        {children && (
          <FooterWrapper>
            <MainDivider />
            {children}
          </FooterWrapper>
        )}
      </PlxInfoPanel>
      <PlxDialog
        title={'Edit further instructions'}
        open={openInstruction}
        onClose={handleCloseInstruction}
      >
        <AdminNotes
          notesValue={instructionsValue || ''}
          onChange={updateCampaignInfo}
          updateInstructionValue={updateInstructionValue}
        />
      </PlxDialog>
    </Wrapper>
  );
};
