import React, { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';
import {
  PlxStepButton,
  StepButtonType,
} from '_Components/Buttons/PlxStepButton/PlxStepButton';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { AuthPageWrapper } from '../../_Components/AuthPageWrapper';
import { IThemed } from '_Styles/types';
import { FormMassage } from '_Components/Forms/FormMassage';
import { useForm } from 'react-hook-form';
import { Wrapper } from '../../DistributionPartner/Register/StyleDP';
import { SubParagraph } from '_Routes/Auth/utils';

const InputWrapper = styled(Wrapper)<IThemed>`
  && {
    margin: ${({ theme }) => theme.spacing(4, 0)};
  }
`;

const ResetHeader = styled.div<IThemed>`
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const TextArea = styled.div`
  font-size: 1.4rem;
  color: #61657e;
  max-width: 42rem;
  line-height: 2rem;
  font-weight: 600;
`;

const ResetWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: space-between;

  svg {
    width: 1.2rem;
  }
`;

interface IProps {
  onResetPassword: (email: string) => Promise<void>;
  backPath: string;
}

export const ResetPassword: FC<IProps> = ({ backPath, onResetPassword }) => {
  const { t } = useTranslation();
  const [emailValue, setEmailValue] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    register,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmailValue(value);
  };

  const handleResetClick = () => {
    onResetPassword(emailValue).then(() => setSuccess(true));
  };
  return (
    <AuthPageWrapper>
      {success && (
        <FormMassage error={false} message={t('auth.reset.confirm')} />
      )}
      <ResetHeader>{t('auth.reset.title')}</ResetHeader>
      <TextArea>
        <SubParagraph paragraph={t('auth.reset.message')} />
      </TextArea>
      <InputWrapper>
        <div>
          <TextField
            name="email"
            // value={emailValue}
            onChange={handleChange}
            label={t('auth.reset.email')}
            placeholder={t('auth.register.emailPlaceholder')}
            variant="outlined"
            required
            inputRef={register({
              required: {
                value: true,
                message: '* Required',
              },
              pattern: /^\S+@\S+$/i,
            })}
            data-test-id="email-field"
          />
        </div>
      </InputWrapper>
      <ResetWrap>
        <PlxButtonX
          isDisabled={!isValid}
          onClick={handleResetClick}
          label={t('auth.reset.button')}
          testId="reset-password-button"
        />
        <PlxStepButton
          type={StepButtonType.BACK}
          linkTo={backPath}
          text={t('auth.reset.backLinkLabel')}
          testId="back-to-login-button"
        />
      </ResetWrap>
    </AuthPageWrapper>
  );
};
