import React, { FC } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ICommonProps } from '_Types/props';
import { makeStyles, Slider } from '@material-ui/core';
import styled from '@emotion/styled';
import { CheckCircle, Brightness1 } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps extends ICommonProps {
  steps: string[];
  activeStep: number;
  onStepClick: (index: number) => void;
}

const StepperWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const useStyles = makeStyles(() => ({
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'initial',
    marginBottom: 'auto',
    padding: '2rem 2.5rem',
    width: '100%',
    background: '#f8f8f8',
    alignItems: 'start',
    '& .MuiStep-horizontal': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  step: {
    width: '100%',
    marginBottom: '5px',
    padding: '1.7rem 0 1.7rem 2rem',
  },
  stepCompleted: {
    marginBottom: '5px',
    border: '1px solid #F2F2F2',
    padding: '1.7rem 0 1.7rem 2rem',
    borderRadius: '12px',
    width: '100%',
  },
  stepActive: {
    marginBottom: '5px',
    width: '100%',
    padding: '1.7rem 0 1.7rem 2rem',
    border: '1px solid #F2F2F2',
    borderRadius: '12px',
    background: '#fff',
  },
  stepLabel: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000000',
    paddingLeft: '1.5rem',
  },
  activeStepLabel: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    paddingLeft: '1.5rem',
    color: '#000000',
    '& .MuiStepLabel-label': {
      fontWeight: 700,
    },
  },
  slider: {
    '& .MuiSlider-thumb': {
      background: '#fff',
      border: '2px solid #000',
    },
    '& .Mui-disabled': {
      color: '#B8B7B7',
    },
    '&	.MuiSlider-track': {
      color: '#000',
    },
  },
}));

const ProgressBar = styled.div`
  text-align: left;
  padding: 3rem 2.5rem 1.5rem 2.5rem;
  width: 100%;
`;
const ProgressTitle = styled.h2`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #231f20;
`;

const ProgressText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: rgba(35, 31, 32, 0.94);
`;

export const PlxStepper: FC<IProps> = ({ steps, activeStep, onStepClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sliderValue =
    activeStep === 0
      ? 0
      : activeStep === 1
      ? 25
      : activeStep === 2
      ? 50
      : activeStep === 3
      ? 75
      : 100;

  return (
    <StyledContainer>
      <ProgressBar>
        <ProgressTitle>{t('spectrum.stepper.newCampaign')}</ProgressTitle>
        <Slider className={classes.slider} disabled value={sliderValue} />
        <ProgressText>
          {sliderValue}
          {t('spectrum.stepper.progress')}
        </ProgressText>
      </ProgressBar>
      <StepperWrap>
        <Stepper className={classes.stepper} activeStep={activeStep}>
          {steps.map((label, index) => {
            const completed = index < activeStep;
            const icon = completed ? (
              <CheckCircle
                style={{ fill: '#258004', height: '14px', width: '14px' }}
              />
            ) : activeStep === index ? (
              <Brightness1
                style={{
                  fill: '#000',
                  height: '5px',
                  width: '5px',
                  marginLeft: '9px',
                }}
              />
            ) : undefined;

            return (
              <Step
                onClick={() => onStepClick(index)}
                key={label}
                completed={completed}
                className={
                  activeStep === index
                    ? classes.stepActive
                    : completed
                    ? classes.stepCompleted
                    : classes.step
                }
              >
                <StepLabel
                  className={
                    activeStep === index
                      ? classes.activeStepLabel
                      : classes.stepLabel
                  }
                  icon={icon}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </StepperWrap>
    </StyledContainer>
  );
};
