import React, { ReactElement } from 'react';
import { Payment } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/_Components/Payment';
import { SeeCampaign } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/_Components/SeeCampaign';
import {
  ICompany,
  ICompanyResponse,
} from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/types';
import { Status } from './_Components/Status';
import { CampaignStatus, PaymentStatus } from '_Types/campaigns';
import styled from '@emotion/styled';
import { PlxText } from '_Components/Text/PlxText';
import moment from 'moment';
import { PaymentMethod } from '_Types/paymentMethod';
import { GridColumns, getGridStringOperators } from '@material-ui/data-grid';
import {
  PAYMENT_METHOD_MAP_STRATEGY,
  PAYMENT_STATUS_MAP_STRATEGY,
} from '../../../types';
import { AddClientForm } from '_State/User/user.types';
import { CustomHeader } from '../../Channels/CustomHeader';
import { IAdminChannelQueryParams } from '_State/DistributionPartner/distributionPartner.types';

const StyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const mapCompaniesToSelect = (
  companies: ICompanyResponse
): ICompany[] => {
  return companies.content.map((company) => {
    return {
      id: company,
      name: company,
    };
  });
};

export const getCampaignStatus = (key: string): CampaignStatus => {
  if ((CampaignStatus as any)[(CampaignStatus as any)[key]]) {
    return CampaignStatus[key as keyof typeof CampaignStatus];
  }
  return CampaignStatus.NEW;
};

export const getPaymentMethod = (key: string): PaymentMethod | string => {
  if ((PaymentMethod as any)[(PaymentMethod as any)[key]]) {
    return PaymentMethod[key as keyof typeof PaymentMethod];
  }
  return PaymentMethod.OTHER;
};

export const getPaymentStatus = (key: string): PaymentStatus | string => {
  if ((PaymentStatus as any)[(PaymentStatus as any)[key]]) {
    return PaymentStatus[key as keyof typeof PaymentStatus];
  }
  return PaymentStatus.IN_PROGRESS;
};

export const createAdminTableConfig = (
  onSortingColumn: (value: string, value2: string) => void,
  parameters: IAdminChannelQueryParams
): GridColumns => {
  const onSort = (value: string, value2: string) => {
    const param = value2 ? value : 'createdDate';
    const param2 = value2 ? value2 : '';
    onSortingColumn(param, param2);
  };
  return [
    {
      headerName: 'Company Name',
      field: 'companyName',
      filterable: false,
      width: 200,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
      ),
    },
    {
      headerName: 'Agency Client Name',
      field: 'agencyClient',
      width: 180,
      renderCell({ value }): ReactElement {
        const agencyClient = value as AddClientForm;
        const nameCompany = agencyClient?.companyName || '';
        return <StyledText>{nameCompany}</StyledText>;
      },
    },
    {
      headerName: 'Campaign ID',
      field: 'id',
      width: 150,
    },
    {
      headerName: 'Submit Date',
      field: 'createdDate',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell({ value }): ReactElement {
        return (
          <StyledText>
            {moment(value?.toString()).format('MMM DD, YYYY')}
          </StyledText>
        );
      },
    },
    {
      headerName: 'Launch Date',
      field: 'startDate',
      width: 150,
      sortable: false,
      filterable: false,
      renderHeader: () => {
        return (
          <CustomHeader
            onSort={onSort}
            params={parameters}
            title="Launch Date"
            field="startDate"
          />
        );
      },
      renderCell({ value }): ReactElement {
        return (
          <StyledText>
            {moment(value?.toString()).format('MMM DD, YYYY')}
          </StyledText>
        );
      },
    },

    {
      headerName: 'Сompletion Date',
      field: 'completionDate',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell({ value }): ReactElement {
        return (
          <StyledText>
            {value && moment(value?.toString()).format('MMM DD, YYYY')}
          </StyledText>
        );
      },
    },
    {
      headerName: 'Invoice ID',
      field: 'program.invoiceId',
      width: 150,
      renderCell({ row }): ReactElement {
        return <StyledText>{row.program?.invoiceId}</StyledText>;
      },
    },
    {
      headerName: 'Payment method',
      field: 'payment',
      width: 200,
      renderCell({ row }): ReactElement {
        return (
          <Payment
            status={
              (PAYMENT_STATUS_MAP_STRATEGY as any)[
                getPaymentStatus(row?.payment?.paymentStatus)
              ]
            }
            method={
              (PAYMENT_METHOD_MAP_STRATEGY as any)[
                getPaymentMethod(row?.payment?.paymentMethod)
              ].label
            }
          />
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 200,
      filterable: false,
      renderCell({ value }): ReactElement {
        return (
          <Status
            status={value?.toString() || CampaignStatus[CampaignStatus.NEW]}
          />
        );
      },
    },
    {
      headerName: 'actions',
      field: '',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell({ row }): ReactElement {
        return (
          <SeeCampaign
            path={`/home/admin/spectrum-campaign/${row.id?.toString()}`}
          />
        );
      },
    },
  ];
};
