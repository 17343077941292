import React, { FC } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit?: boolean;
  showBack?: boolean;
  showNextStep?: boolean;
  showPayNow?: boolean;
  isNextStepDisabled?: boolean;
  isBackStepDisabled?: boolean;
  onSave?: () => void;
  onBack?: () => void;
  onNextStep?: () => void;
  onPayNow?: () => void;
  className?: string;
  showSaveForLater?: boolean;
  testId?: string;
}

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* height: 5.5rem;
  margin-top: 2rem; */
  /* margin-bottom: 4rem; */

  .MuiButton-root {
    padding: 0.6rem 10rem;
  }
`;

const RightButton = styled(PlxButtonX)`
  && {
    margin-left: auto;
  }

  button {
    margin-left: auto;
  }
`;

export const FooterButtons: FC<IProps> = ({
  onSubmit,
  showBack,
  showNextStep,
  showPayNow,
  isNextStepDisabled,
  isBackStepDisabled,
  onSave,
  onBack,
  onNextStep,
  onPayNow,
  className,
  showSaveForLater,
  testId,
}) => {
  const { t } = useTranslation();

  const handleSave = () => {
    if (onSave) onSave();
  };

  const handleBack = () => {
    if (onBack) onBack();
  };

  const handleNextStep = () => {
    if (onNextStep) onNextStep();
  };

  const handlePayNow = () => {
    if (onPayNow) onPayNow();
  };

  return (
    <ButtonsSection className={className}>
      {showBack && (
        <PlxButtonX
          label={t('spectrum.footerButtons.back')}
          isDisabled={isBackStepDisabled}
          // variant={ButtonVariant.Outlined}
          onClick={handleBack}
          testId={'back-button'}
        />
      )}
      {showSaveForLater && (
        <PlxButtonX
          label={t('spectrum.footerButtons.saveForLater')}
          variant2
          onClick={handleSave}
          testId={testId}
        />
      )}

      {showNextStep && (
        <RightButton
          label={t('spectrum.footerButtons.nextStep')}
          onClick={handleNextStep}
          isDisabled={isNextStepDisabled}
          testId={'next-button'}
        />
      )}
      {onSubmit && (
        <PlxButtonX
          label={t('spectrum.footerButtons.onSubmit')}
          onClick={handleNextStep}
          isDisabled={isNextStepDisabled}
          testId={testId}
        />
      )}
      {showPayNow && (
        <PlxButtonX
          label={t('spectrum.footerButtons.checkOut')}
          onClick={handlePayNow}
          isDisabled={isNextStepDisabled}
          isProcessing={isNextStepDisabled}
          testId={testId}
        />
      )}
    </ButtonsSection>
  );
};
