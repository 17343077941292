import React, { FC, useContext } from 'react';
import { ReactComponent as Twitter } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Twitter_1.svg';
import { ReactComponent as LinkedIn } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Linked_1.svg';
import { ReactComponent as YouTube } from './../../../../../../_Styles/images/_Icons/ChannelTypes/YouTube_1.svg';
import { ReactComponent as Instagram } from './../../../../../../_Styles/images/_Icons/ChannelTypes/Instagram_1.svg';
import { TabPageHeader } from '../../utils';
import { FinfluencerContactUsForm } from './FinfluencerContactUsForm';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import {
  ContactDetailsWrap,
  Header,
  SubHeader,
  Contact,
  SocialLinks,

  // Faqs,
} from './FinfluencerContactUsStyle';
import { useTranslation } from 'react-i18next';

export const FinfluencerContactUs: FC = () => {
  const { t } = useTranslation();
  const { navBarExpanded } = useContext(NavigationContext);

  return (
    <Body isExpanded={navBarExpanded}>
      <div>
        <TabPageHeader name={'Contact Us'} />
      </div>

      <ContactDetailsWrap>
        <Header>{t('contactUs.gettingInTouch')}</Header>
        <SubHeader>{t('contactUs.happyToChat')}</SubHeader>
        {/* <Faqs
            href="https://www.untappedlab.com/?page_id=5250"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('contactUs.faqs')}
          </Faqs> */}

        <Contact>
          {t('contactUs.phoneNo')}
          {/* <span>{t('contactUs.tollFree')}</span> */}
        </Contact>
        <Contact>
          <a href="mailto:support@brandpilot.ai">{t('contactUs.email')}</a>
        </Contact>
        <Contact>
          <a href="https://www.brandpilot.ai/">{t('contactUs.website')}</a>
        </Contact>

        <SocialLinks>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/brandpilot-ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/brandpilotai"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC0UA4nk7_NUScpIYSHzpRHg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/BrandPilotAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
            </li>
          </ul>
        </SocialLinks>
      </ContactDetailsWrap>
      <FinfluencerContactUsForm />
    </Body>
  );
};
