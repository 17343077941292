import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

export const RemoveIcon: FC<IIconProps> = ({ isFocused, onClick }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.42969 4.01538H2.93738H14.9989"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19904 4.01538V2.50769C5.19904 2.10783 5.35788 1.72434 5.64063 1.44159C5.92338 1.15885 6.30687 1 6.70673 1H9.72212C10.122 1 10.5055 1.15885 10.7882 1.44159C11.071 1.72434 11.2298 2.10783 11.2298 2.50769V4.01538M13.4913 4.01538V14.5692C13.4913 14.9691 13.3325 15.3526 13.0498 15.6353C12.767 15.9181 12.3835 16.0769 11.9837 16.0769H4.44519C4.04533 16.0769 3.66184 15.9181 3.37909 15.6353C3.09635 15.3526 2.9375 14.9691 2.9375 14.5692V4.01538H13.4913Z"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70703 7.78467V12.3077"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72266 7.78467V12.3077"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
