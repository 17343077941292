import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';
import { ActivationWrapperX } from '_Routes/Auth/_Components/ActivationWrapperX';
import { SuccessInfo } from '_Routes/Auth/_Components/SuccessInfo';
import { useTranslation } from 'react-i18next';
import { activateAccount } from '_Api/auth';
import { FormMassage } from '_Components/Forms/FormMassage';
import { capitalize } from '_App/utils';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';

const ButtonWrapper = styled.div`
  margin: 0 auto;
`;

const TextP = styled.p`
  text-align: center;
  padding: 3rem;
`;

export const ActivateAccount: FC = () => {
  const { token } = useParams<any>();
  const { t } = useTranslation();
  const history = useHistory();
  const [activated, setActivated] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    if (timeLeft >= 1) {
      const intervalId = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      return history.push('/auth/finfluencer/login');
    }
  }, [timeLeft, history]);

  useEffect(() => {
    if (token) {
      activateAccount(token).then(
        () => setActivated(true),
        (error) => {
          error.response.text().then((value: any) => {
            const apiError = JSON.parse(value);
            setError(`${capitalize(apiError.message)}`);
          });
        }
      );
    }
  }, [token]);
  return (
    <ActivationWrapperX>
      {activated && (
        <>
          <SuccessInfo text={t('auth.activateAccount.confirmation')} />
          <TextP>
            Redirecting to the Login page in {timeLeft} Second
            {timeLeft === 1 ? 's' : ''}.
          </TextP>
        </>
      )}
      {error && <FormMassage message={error} />}
      <ButtonWrapper>
        <PlxButtonX
          label={t('auth.register.confirmGo')}
          onClick={() => history.push('/auth/login')}
          testId="go-to-login-button"
        />
      </ButtonWrapper>
    </ActivationWrapperX>
  );
};
