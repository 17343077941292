import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DateTimeFilter, {
  ICalendarDate,
} from './_Component/Filters/DateTimeFilter/DateTimeFilter';
import { useTranslation } from 'react-i18next';
import { StatisticsWidget } from '_Components/StatisticWidget/StatisticsWidget';
import { SpectrumTable } from '_Routes/Home/_routes/Spectrum/_Routes/SpectrumTable/SpectrumTable';
import { EngagementGraph } from './_Component/EngagementGraph/EngagementGraph';
import SpectrumButton from './_Component/SpectrumButton/SpectrumButton';
import { spectrumApi } from '_Api/api';
import { format, isDate } from 'date-fns';
import moment from 'moment';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { spectrumNavigationItems } from '_Routes/Home/NavigationPanel/navigationItems';
import {
  NavWrap,
  Wrap,
  SpectrumDashboardWrapper,
  Wrapper,
  StyledContent,
} from './SpectrumDashboard.styles';
import {
  IEngagementGraphData,
  IBasicWidgetState,
  initialCalendarDate,
} from './utils';
import {
  BreadCrumb,
  HeaderContainer,
} from '_Routes/Home/_Components/Breadcrumb/BreadCrumb';
import styled from '@emotion/styled';
import { SelectFilter } from '_Components/Filters/SelectFilter/SelectFilter';
import {
  getAgencies,
  getAgenciesClients,
  getClients,
} from '_State/User/Api/admin-user.api';
import { SpectrumExportButton } from './_Component/SpectrumButton/SpectrumExportButton';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
`;

const INITIALGRAPHDATA = { content: [] };
const INITIALWIDGETSDATA = {
  reach: {},
  engagement: {},
  impressions: {},
  clicks: {},
  followers: {},
  totalClicks: 0,
};

export const MultiSelectAnalytics: FC<{
  userId: number;
  value: 'overview' | 'campaigns' | 'engagement';
}> = ({ userId, value }) => {
  const [analyticsOptions, setAnalyticsOptions] = useState<
    'overview' | 'campaigns' | 'engagement'
  >(value);

  const history = useHistory();

  const handleAnalyticsOptionsChange = (e: any) => {
    const value = e.target.value;
    setAnalyticsOptions(value);

    const path: string = ({
      overview: `/home/admin/spectrum-analytics?userId=${userId}`,
      campaigns: `/home/admin/users/analyt/${value}/${userId}`,
      engagement: `/home/admin/spectrum-engagement?userId=${userId}`,
    } as any)[value];

    return history.push(path);
  };

  return (
    <NavWrap>
      <Wrap>
        <FormControl fullWidth>
          <Select
            name="analytics"
            value={analyticsOptions}
            onChange={handleAnalyticsOptionsChange}
          >
            {spectrumNavigationItems.map(({ label, disabled }, index) => {
              return (
                !disabled && (
                  <MenuItem key={index} value={label}>
                    {label}
                  </MenuItem>
                )
              );
            })}
          </Select>
        </FormControl>
      </Wrap>
    </NavWrap>
  );
};

export const SpectrumDashboard: FC<{ userId?: number; isAdmin?: boolean }> = ({
  userId,
  isAdmin,
}) => {
  const [engagementGraphData, setEngagementGraphData] = useState<
    IEngagementGraphData
  >(INITIALGRAPHDATA);
  const [basicWidgetData, setBasicWidgetData] = useState<IBasicWidgetState>(
    INITIALWIDGETSDATA
  );
  const [calendarDate, setCalendarDate] = useState<ICalendarDate>(
    initialCalendarDate
  );
  const [id, setId] = useState<number | undefined>(userId);
  const [agencyId, setAgencyId] = useState<number | undefined>();
  const [getClientsLoading, setClientsLoading] = useState(false);
  const [clientsOptions, setClientsOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [clientFilterOpen, setClientFilterOpen] = useState<boolean>(false);
  const [getAgencyLoading, setAgencyLoading] = useState(false);
  const [agencyOptions, setAgencyOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [agencyFilterOpen, setAgencyFilterOpen] = useState<boolean>(false);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);

  const history = useHistory();
  const { t } = useTranslation();

  const handleProgramLaunch = (): void => {
    history.push(
      isAgencyType
        ? '/home/agency/spectrum-campaign/payment'
        : '/home/client/spectrum-campaign/payment'
    );
  };
  const formatterRequestDate = (date: Date): string =>
    format(date, 'yyyy-MM-dd');

  useEffect(() => {
    const spectrumCampaignsRequest = (
      startDate: string,
      endDate: string
    ): Promise<void> => {
      return spectrumApi
        .get(
          id
            ? agencyId
              ? `users/self/spectrum-campaigns?sortDirection=ASC&sortByField=startDate&campaignStatusList=COMPLETED&pageSize=10000&from=${startDate}&to=${endDate}&userId=${agencyId}&agencyClientId=${id}`
              : `users/self/spectrum-campaigns?sortDirection=ASC&sortByField=startDate&campaignStatusList=COMPLETED&pageSize=10000&from=${startDate}&to=${endDate}&userId=${id}`
            : clientSelected
            ? `users/self/spectrum-campaigns?sortDirection=ASC&sortByField=startDate&campaignStatusList=COMPLETED&pageSize=10000&from=${startDate}&to=${endDate}&agencyClientId=${clientSelected}`
            : `users/self/spectrum-campaigns?sortDirection=ASC&sortByField=startDate&campaignStatusList=COMPLETED&pageSize=10000&from=${startDate}&to=${endDate}`
        )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          const newArr = res.content.map((a: any) => {
            if (a.engagement) {
              a.engagement = a.engagement.toFixed(3);
            }
            return a;
          });
          setEngagementGraphData({ ...res, content: newArr });
        });
    };

    const getBasicWidgetData = (
      startDate: string,
      endDate: string
    ): Promise<void> =>
      spectrumApi
        .get(
          id
            ? agencyId
              ? `spectrum-campaigns/widgets-metrics?from=${startDate}&to=${endDate}&userId=${agencyId}&agencyClientId=${id}`
              : `spectrum-campaigns/widgets-metrics?from=${startDate}&to=${endDate}&userId=${id}`
            : clientSelected
            ? `spectrum-campaigns/widgets-metrics?from=${startDate}&to=${endDate}&agencyClientId=${clientSelected}`
            : `spectrum-campaigns/widgets-metrics?from=${startDate}&to=${endDate}`
        )
        .then((res) => res.json())
        .then((res) => setBasicWidgetData(res))
        .catch(() => {
          setBasicWidgetData({
            engagement: {
              averagePotential: 0.0,
              comparisonToAverage: 0.0,
            },
            reach: { averagePotential: 0.0, comparisonToAverage: 0.0 },
            followers: { averagePotential: 0.0, comparisonToAverage: 0.0 },
            impressions: {
              averagePotential: 0.0,
              comparisonToAverage: 0.0,
            },
            clicks: {
              averagePotential: 0.0,
              comparisonToAverage: 0.0,
            },
            totalClicks: 0,
          });
        });

    if (
      !isDate(calendarDate.selection.startDate) ||
      !isDate(calendarDate.selection.endDate)
    ) {
      spectrumCampaignsRequest('2020-01-01', formatterRequestDate(new Date()));
      getBasicWidgetData('2020-01-01', formatterRequestDate(new Date()));
      return;
    } else {
      spectrumCampaignsRequest(
        formatterRequestDate(calendarDate.selection.startDate as Date),
        formatterRequestDate(calendarDate.selection.endDate as Date)
      );
      getBasicWidgetData(
        formatterRequestDate(calendarDate.selection.startDate as Date),
        formatterRequestDate(calendarDate.selection.endDate as Date)
      );
    }
  }, [
    calendarDate.selection.startDate,
    calendarDate.selection.endDate,
    id,
    agencyId,
    clientSelected,
  ]);

  useEffect(() => {
    let active = true;

    if (!clientFilterOpen) return;

    setClientsLoading(true);

    if (agencyId && agencyId !== 0) {
      (async () => {
        try {
          const { content } = await getAgenciesClients(agencyId.toString());

          if (active) {
            setClientsOptions(
              content.map((client: { id: number; companyName: string }) => ({
                value: client.id.toString(),
                label: client.companyName,
              }))
            );
          }
        } finally {
          setClientsLoading(false);
        }
      })();
    } else {
      (async () => {
        try {
          const { content } = await getClients({ id });

          if (active) {
            setClientsOptions(
              content.map((client: { id: number; companyName: string }) => ({
                value: client.id.toString(),
                label: client.companyName,
              }))
            );
          }
        } finally {
          setClientsLoading(false);
        }
      })();
    }

    return (): void => {
      active = false;
    };
  }, [agencyId, clientFilterOpen, id]);

  useEffect(() => {
    let active = true;

    if (agencyId === 0) {
      setEngagementGraphData(INITIALGRAPHDATA);
      setBasicWidgetData(INITIALWIDGETSDATA);
    }

    if (!agencyFilterOpen) return;

    setAgencyLoading(true);

    (async () => {
      try {
        const { content } = await getAgencies();

        if (active) {
          setAgencyOptions(
            content.map((agency: { id: number; companyName: string }) => ({
              value: agency.id.toString(),
              label: agency.companyName,
            }))
          );
        }
      } finally {
        setClientsOptions([]);
        setAgencyLoading(false);
      }
    })();

    return (): void => {
      active = false;
    };
  }, [agencyFilterOpen, agencyId]);

  return (
    <SpectrumDashboardWrapper>
      <Wrapper>
        {!isAgencyType || clientSelected ? (
          <>
            <HeaderContainer>
              <div>
                <BreadCrumb
                  parent={t('navBar.spectrum')}
                  page={t('navBar.overview')}
                  title={t('navBar.overview')}
                />
                <FilterWrap>
                  <DateTimeFilter
                    calendarDate={calendarDate}
                    setCalendarDate={setCalendarDate}
                    initialCalendarDate={initialCalendarDate}
                  />
                  {isAdmin && (
                    <>
                      <SelectFilter
                        name={t('Agency')}
                        onStateChange={(isOpen): void =>
                          setAgencyFilterOpen(isOpen)
                        }
                        agency
                        loading={getAgencyLoading}
                        initialValue={agencyId?.toString() || undefined}
                        options={agencyOptions}
                        onChange={(value): void => setAgencyId(Number(value))}
                      />
                      <SelectFilter
                        name={t('Client')}
                        onStateChange={(isOpen): void =>
                          setClientFilterOpen(isOpen)
                        }
                        agencyId={agencyId?.toString()}
                        loading={getClientsLoading}
                        initialValue={id?.toString() || undefined}
                        options={clientsOptions}
                        onChange={(value): void => setId(Number(value))}
                      />
                    </>
                  )}
                </FilterWrap>
              </div>
              <div>
                {!isAdmin && (
                  <SpectrumButton
                    text={t('spectrum.overview.launchProgram')}
                    borderColor="#008b9c"
                    fontSize={16}
                    handleProgramLaunch={handleProgramLaunch}
                  />
                )}
                {!!id && (
                  <MultiSelectAnalytics userId={id} value={'overview'} />
                )}
                <SpectrumExportButton
                  startDate={moment(
                    calendarDate.selection.startDate as Date
                  ).format('yyyy-MM-DD')}
                  endDate={moment(
                    calendarDate.selection.endDate as Date
                  ).format('yyyy-MM-DD')}
                  id={isAdmin ? id : undefined}
                />
              </div>
            </HeaderContainer>
            <StyledContent>
              <StatisticsWidget
                data={basicWidgetData.followers}
                title={t('spectrum.overview.avePotentialReach')}
                primaryText={'spectrum.overview.averageFollowersTooltip2'}
                secondaryText={'spectrum.overview.averageFollowersTooltip'}
              />
              <StatisticsWidget
                data={basicWidgetData.impressions}
                title={t('spectrum.overview.Impressions')}
                primaryText={'spectrum.overview.averageImpressionsTooltip2'}
                secondaryText={'spectrum.overview.averageImpressionsTooltip'}
              />
              <StatisticsWidget
                data={basicWidgetData.engagement}
                title={t('spectrum.overview.aveEngagement')}
                primaryText={t('spectrum.overview.averageEngagementTooltip2')}
                secondaryText={t('spectrum.overview.averageEngagementTooltip')}
              />
              <StatisticsWidget
                totalClicks={basicWidgetData.totalClicks}
                data={basicWidgetData.clicks}
                title={t('spectrum.overview.totalClicks')}
                primaryText={'spectrum.overview.averageClicksTooltip'}
                additionalText={'spectrum.overview.averageClicksNote'}
              />
            </StyledContent>
            <EngagementGraph engagementGraphData={engagementGraphData} />
            <SpectrumTable
              startDate={moment(
                calendarDate.selection.startDate as Date
              ).format('yyyy-MM-DD')}
              endDate={moment(calendarDate.selection.endDate as Date).format(
                'yyyy-MM-DD'
              )}
              clientSelected={clientSelected}
              isAdmin={id}
              agencyId={agencyId}
            />
          </>
        ) : (
          t('campaignsTable.selectClientOverview')
        )}
      </Wrapper>
    </SpectrumDashboardWrapper>
  );
};
