import React, { FC, useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import styled from '@emotion/styled';
import { NavigationLinks } from '../NavigationLinks';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { makeStyles } from '@material-ui/styles';
import { Divider, IconButton } from '@material-ui/core';
import { FullLogoX } from '../Logo/FullLogoX';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: transparent;
  }
`;
const StyledLogo = styled.div`
  height: 6.5rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;
const StyledLogoTop = styled(StyledLogo)`
  height: 6.563rem;
  position: absolute;
  top: 1px;
  left: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
`;
const StyledLogoImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
});
const ExpandableDrawer: FC = () => {
  const classes = useStyles();
  const { navBarExpanded, onExpandClick } = useContext(NavigationContext);

  return !navBarExpanded ? (
    <>
      <StyledLogoTop>
        <IconButton onClick={onExpandClick}>
          <MenuIcon style={{ color: '#231F20' }} />
        </IconButton>
        <StyledLogoImage>
          <FullLogoX />
        </StyledLogoImage>
      </StyledLogoTop>
      <Divider />
    </>
  ) : (
    <StyledDrawer
      onClose={onExpandClick}
      variant="temporary"
      open={navBarExpanded}
      anchor={'left'}
      classes={{
        paper: classes.paper,
      }}
    >
      <StyledLogo>
        <IconButton onClick={onExpandClick}>
          <CloseIcon style={{ color: '#231F20' }} />
        </IconButton>
        <StyledLogoImage>
          <FullLogoX />
        </StyledLogoImage>
      </StyledLogo>
      <Divider />
      <NavigationLinks
        isExpanded={navBarExpanded}
        onExpandClick={onExpandClick}
      />
    </StyledDrawer>
  );
};

export default ExpandableDrawer;
