import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { PlxFormSection } from '_Components/FormSection/FormSection';
import { useTranslation } from 'react-i18next';
import {
  getDraft,
  updateCampaignDraft,
} from '_State/Spectrum/Api/spectrum.api';
import { ICampaignForm, ISpectrumCampaign } from '_State/Spectrum/types';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import {
  ButtonsSection,
  ButtonsWrapper,
  getSpectrumLink,
  StyledForm,
  StyledTitle,
} from '_Routes/Home/_routes/Spectrum/utils';
import { ButtonVariant } from '_Components/Buttons/PlxButton/PlxButton';
import { HashtagInput } from '_Routes/Home/_Components/Sections/HashtagInput';
import {
  contentCategoryConfig,
  contentTypeConfig,
} from '_Routes/Home/_routes/Spectrum/config';
import { mapTranslatedLabel } from '_App/utils';
import { PlxButtonGroupSection } from '_Routes/Home/_Components/Sections/PlxButtonGroupSection';
import { UrlSection } from '_Routes/Home/_Components/Sections/UrlSection';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { Add, Remove } from '@material-ui/icons';
import { NewButton } from '_Routes/Home/_Components/Sections/StockSection/StockSection';
import { FurtherInstructions } from '_Routes/Home/_Components/Sections/FurtherInstructions';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HashtagsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    margin-right: 1.5rem !important;
  }
  .MuiFormControl-root:last-of-type {
    margin-right: 0;
  }
`;

const INITIAL_VALUES: ICampaignForm = {
  contentType: '',
  url: '',
  contentCategories: [],
  hashtags: [],
  furtherInstructions: '',
};

const REQUIRED_FIELDS = ['contentType', 'url', 'contentCategories'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const SpectrumCampaign: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isAgencyType, clientSelected } = useContext(NavigationContext);
  const [draft, setDraft] = useState<ISpectrumCampaign>();
  const [hashtagConfig, setHashtagConfig] = useState([
    {
      label: 'Hashtag 1',
    },
  ]);

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const saveDraft = (values: any) => {
    return updateCampaignDraft(values, clientSelected).then();
  };
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        saveDraft(form).then(() =>
          history.push(
            isAgencyType
              ? '/home/agency/spectrum-campaign/scheduling'
              : '/home/client/spectrum-campaign/scheduling'
          )
        );
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.contentCategories && form.contentCategories.length < 1) {
        errors.contentCategories = t('Content category is required');
      }
      if (
        form.url &&
        !form.url.startsWith('http://') &&
        !form.url.startsWith('https://')
      ) {
        errors.url = t('errors.companyWebsite');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (form: ISpectrumCampaign) => {
    formik.setValues({
      contentType: form.contentType,
      url: form.url,
      contentCategories: form.contentCategories,
      hashtags: form.hashtags,
      furtherInstructions: form.furtherInstructions,
    });
  };

  useEffect(() => {
    getDraft(clientSelected).then(
      (v) => {
        if (v) {
          fillForm(v);
          setDraft(v);
        }
      },
      () => null
    );
    // eslint-disable-next-line
  }, []);

  const contentTypeOptions = contentTypeConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );
  const contentCategoryOptions = contentCategoryConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );

  const contentTypeInitialValue = useMemo(
    () => (draft?.contentType ? [draft?.contentType] : undefined),
    [draft]
  );

  const categoryFilled = formik.values.contentCategories
    ? formik.values.contentCategories.length > 0
      ? true
      : false
    : false;

  const isButtonReady =
    formik.values.contentType && categoryFilled && formik.values.url
      ? true
      : false;

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <StyledForm>
          <StyledTitle>{t('spectrum.campaign.configure')}</StyledTitle>
          <PlxButtonGroupSection
            buttonsConfig={contentTypeOptions}
            required
            otherOption={true}
            title={t('spectrum.campaign.contentType')}
            tooltip={t('spectrum.campaign.contentTypeTooltip')}
            inputTitle={t('spectrum.campaign.specifyContentType')}
            placeholder={t('spectrum.campaign.specifyContentTypePlaceholder')}
            value={contentTypeInitialValue}
            setValue={formik.setFieldValue}
            fieldName={'contentType'}
          />
          <FieldErrorMessage message={formik.errors.contentType} />
          <PlxButtonGroupSection
            translateY={25}
            search={true}
            otherOption={true}
            buttonsConfig={contentCategoryOptions}
            searchPlaceholder={t('spectrum.campaign.searchPlaceholder')}
            required
            multiple={true}
            title={t('spectrum.campaign.contentCategory')}
            inputTitle={t('spectrum.campaign.specifyContentCategory')}
            tooltip={t('spectrum.campaign.contentCategoryTooltip')}
            placeholder={t(
              'spectrum.campaign.specifyContentCategoryPlaceholder'
            )}
            value={draft?.contentCategories}
            setValue={formik.setFieldValue}
            fieldName={'contentCategories'}
          />
          <FieldErrorMessage message={formik.errors.contentCategories} />
          <UrlSection
            title={t('spectrum.campaign.url')}
            required
            tooltip={t('spectrum.campaign.urlTooltip')}
            placeholder={t('spectrum.campaign.urlPlaceholder')}
            fieldName={'url'}
            handleChange={formik.handleChange}
            value={draft?.url}
          />
          <FieldErrorMessage message={formik.errors.url} />

          <PlxFormSection
            title={t('spectrum.campaign.hashtags')}
            tooltip={t('spectrum.campaign.hashtagsTooltip')}
            required={false}
          >
            <HashtagsWrapper>
              {hashtagConfig.map((config, key) => {
                return (
                  <HashtagInput
                    title={t('spectrum.campaign.hashtags')}
                    placeholder={t('spectrum.campaign.hashtagPlaceholder')}
                    setValue={formik.setFieldValue}
                    value={draft?.hashtags[key]}
                    key={key}
                    label={config.label}
                    fieldName={`hashtags[${key}]`}
                    handleChange={formik.handleChange}
                  />
                );
              })}
              <ButtonsWrapper>
                <>
                  <Add
                    onClick={() => {
                      setHashtagConfig([
                        ...hashtagConfig,
                        { label: `Hashtag ${hashtagConfig.length + 1}` },
                      ]);
                    }}
                    style={{
                      display:
                        hashtagConfig.length > 4 ? 'none' : 'inline-flex',
                      marginTop: '8px',
                      fill: '#fff',
                      background: '#000',
                      borderRadius: '2px',
                      height: '15px',
                      width: '15px',
                    }}
                  />
                  {hashtagConfig.length < 5 && (
                    <NewButton
                      variant={ButtonVariant.Outlined}
                      label={'Add New'}
                      onClick={() => {
                        setHashtagConfig([
                          ...hashtagConfig,
                          { label: `Hashtag ${hashtagConfig.length + 1}` },
                        ]);
                      }}
                    />
                  )}
                </>
                <>
                  <Remove
                    onClick={() => {
                      setHashtagConfig(hashtagConfig.slice(0, -1));
                    }}
                    style={{
                      display:
                        hashtagConfig.length > 1 ? 'inline-flex' : 'none',
                      marginTop: '8px',
                      fill: '#fff',
                      background: '#ff0000',
                      borderRadius: '2px',
                      height: '15px',
                      width: '15px',
                    }}
                  />
                  {hashtagConfig.length > 1 && (
                    <NewButton
                      variant={ButtonVariant.Outlined}
                      label={'Remove'}
                      onClick={() => {
                        setHashtagConfig(hashtagConfig.slice(0, -1));
                      }}
                    />
                  )}
                </>
              </ButtonsWrapper>
            </HashtagsWrapper>
            {formik.errors.hashtags && (
              <FieldErrorMessage message={formik.errors.hashtags} />
            )}
          </PlxFormSection>
          <FurtherInstructions
            title={t('spectrum.campaign.furtherInstructions')}
            fieldName={'furtherInstructions'}
            handleChange={formik.handleChange}
            value={draft?.furtherInstructions}
            formik={formik}
          />
        </StyledForm>
        <ButtonsSection>
          <PlxButtonX
            newDesign
            size="small"
            onClick={() => {
              history.push(
                isAgencyType
                  ? '/home/agency/spectrum-campaign/program'
                  : '/home/client/spectrum-campaign/program'
              );
            }}
            label={t('spectrum.footerButtons.back')}
          />
          <ButtonsWrapper>
            <PlxButtonX
              newDesign
              size="small"
              onClick={() =>
                saveDraft(formik.values).then(() =>
                  getSpectrumLink().then((link) => {
                    if (link) {
                      history.push(link);
                    }
                  })
                )
              }
              label={t('spectrum.footerButtons.save')}
            />
            <PlxButtonX
              isDisabled={!isButtonReady}
              newDesign
              size="small"
              inputType={'submit'}
              label={t('spectrum.footerButtons.next')}
              variant2
            />
          </ButtonsWrapper>
        </ButtonsSection>
      </form>
    </Wrapper>
  );
};
