import React, { FC } from 'react';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  tagName?: string;
  onDialogOpenDialog?: () => void;
}
export const CampaignInfoTag: FC<IProps> = ({
  tagName,
  onDialogOpenDialog,
}) => {
  const { t } = useTranslation();
  const manageTag = () => {
    onDialogOpenDialog && onDialogOpenDialog();
  };

  return (
    <>
      {tagName ? (
        <PlxLinkTextButton label={tagName} onClick={manageTag} />
      ) : (
        <PlxLinkTextButton
          label={t('campaignsTable.addNewTag')}
          onClick={manageTag}
        />
      )}
    </>
  );
};
