import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TextField, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PlxFormSection } from '_Components/FormSection/FormSection';

interface RichTextFieldProps {
  value: string;
  onChange: (value: string) => void;
}

interface IProps {
  placeholder?: string;
  fieldname?: string;
  inputTitle?: string;
  fullWidth?: boolean;
  onChoose?: (options: string[]) => void;
  title: string;
  otherOption?: boolean;
  tooltip?: string;
  value?: string;
  setValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange?: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  fieldName?: string;
  required?: boolean;
  horizontal?: boolean;
  testId?: string;
  search?: boolean;
  formik: any;
}

const useStyles = makeStyles({
  textField: {
    '& .ql-container.ql-snow': {
      minHeight: 150,
      border: 'none',
    },
    '& .ql-editor': {
      minHeight: 150,
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: '1px solid rgba(35, 31, 32, 0.16)',
    },
    '& .MuiInputBase-inputMultiline': {
      border: '1px solid rgba(35, 31, 32, 0.16)',
      borderRadius: 6,
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
  },
  formSection: {
    marginTop: '18px',
    '& ol': {
      paddingLeft: 0,
      marginLeft: '4px',
      listStyleType: 'none',
    },
  },
});

const RichTextField: React.FC<RichTextFieldProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleQuillChange = (content: string) => {
    onChange(content);
  };

  return (
    <TextField
      multiline
      fullWidth
      placeholder={t('spectrum.campaign.furtherInstructionsPlaceholder')}
      value={value}
      onChange={(event) => handleQuillChange((event as unknown) as string)}
      InputProps={{
        inputComponent: ReactQuill as any,
        inputProps: {
          theme: 'snow',
          value: value,
          modules: {
            clipboard: {
              matchVisual: false,
            },
          },
        },
      }}
      className={classes.textField}
    />
  );
};

export const FurtherInstructions: React.FC<IProps> = ({
  value,
  fieldName,
  formik,
  title,
  tooltip,
  required,
}) => {
  const [richText, setRichText] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      setRichText(value);
    }
  }, [value]);

  const handleQuillChange = (content: string) => {
    setRichText(content);
    formik.setFieldValue(fieldName, content);
  };

  return (
    <PlxFormSection
      className={classes.formSection}
      title={title}
      tooltip={tooltip}
      required={required}
    >
      <RichTextField value={richText} onChange={handleQuillChange} />
    </PlxFormSection>
  );
};
