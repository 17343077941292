import React, { FC } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { PlxLoader } from '_Components/PlxLoader';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import {
  Content,
  Wrapper,
  ButtonWrap,
  InviteeList,
  Th,
  Td,
  Td2,
} from './Styles';
import { IDistributionPartnerChannelSelectableRow } from '_State/DistributionPartner/distributionPartner.types';
import { useTranslation } from 'react-i18next';

interface XemIProps {
  inviteLoading: boolean;
  rowSelected: IDistributionPartnerChannelSelectableRow[];
  invitedChannels: IDistributionPartnerChannelSelectableRow[];
  closeDialog: () => void;
  handleConfirmInviteMultiple: () => void;
  totals: any;
}
export const MultiInvitePopup: FC<XemIProps> = ({
  inviteLoading,
  rowSelected,
  invitedChannels,
  closeDialog,
  handleConfirmInviteMultiple,
  totals,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {!inviteLoading ? (
        <Content>
          <InviteeList>
            <table>
              <tr>
                <Th>{t('channels.table.channelName')}</Th>
                <Th>{t('channels.table.offer')}</Th>
                <Th>{t('channels.table.followers')}</Th>
              </tr>
              <>
                {rowSelected.map(
                  ({ id, channelName, numberOfFollowers, offer }) => {
                    return (
                      <React.Fragment key={id}>
                        <tr>
                          <Td>{channelName}</Td>
                          <Td>
                            $<StandardizeAmount amount={offer} />
                          </Td>
                          <Td>
                            <StandardizeAmount
                              amount={numberOfFollowers}
                              decimal={0}
                            />
                          </Td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                )}
                <tr>
                  <Th>
                    {t('channels.table.selectedTotal')}({rowSelected.length})
                  </Th>
                  <Th>
                    $<StandardizeAmount amount={totals.invitingTotalOffers} />
                  </Th>
                  <Th>
                    <StandardizeAmount
                      amount={totals.invitingTotalFollowers}
                      decimal={0}
                    />
                  </Th>
                </tr>
              </>
              <tr>
                <Td2>
                  {t('channels.table.invitedPreviously')} (
                  {invitedChannels.length})
                </Td2>
                <Td2>
                  $<StandardizeAmount amount={totals.invitedTotalOffers} />
                </Td2>
                <Td2>
                  <StandardizeAmount
                    amount={totals.invitedTotalFollowers}
                    decimal={0}
                  />
                </Td2>
              </tr>

              <tr>
                <Td2>
                  {t('channels.table.total')}(
                  {invitedChannels.length + rowSelected.length})
                </Td2>
                <Td2>
                  $
                  <StandardizeAmount
                    amount={
                      totals.invitedTotalOffers + totals.invitingTotalOffers
                    }
                  />
                </Td2>

                <Td2>
                  <StandardizeAmount
                    amount={
                      totals.invitedTotalFollowers +
                      totals.invitingTotalFollowers
                    }
                    decimal={0}
                  />
                </Td2>
              </tr>
              <div style={{ marginTop: '2rem' }}></div>

              <tr>
                <Td2>
                  {t('channels.table.acceptedOffer')}(
                  {totals.acceptedTotalChannel})
                </Td2>
                <Td2>
                  $
                  <StandardizeAmount amount={totals.acceptedTotalOffers} />
                </Td2>
                <Td2>
                  <StandardizeAmount
                    amount={totals.acceptedTotalFollowers}
                    decimal={0}
                  />
                </Td2>
              </tr>
            </table>
          </InviteeList>
          <ButtonWrap>
            <PlxButtonX
              onClick={closeDialog}
              label={t('channels.reviewAnalyticsConfirmation.cancel')}
            />
            <PlxButtonX
              onClick={handleConfirmInviteMultiple}
              label={t('channels.reviewAnalyticsConfirmation.confirm')}
            />
          </ButtonWrap>
        </Content>
      ) : (
        <PlxLoader />
      )}
    </Wrapper>
  );
};
