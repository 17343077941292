import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './Auth/auth.state';
import { userReducer } from './User/user.state';
import { spectrumReducer } from '_State/Spectrum/spectrum.state';
import { thriveReducer } from '_State/Thrive/thrive.state';
import { distributionPartnerReducer } from '_State/DistributionPartner/distributionPartner.state';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  spectrum: spectrumReducer,
  thrive: thriveReducer,
  distributionPartner: distributionPartnerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
