import styled from '@emotion/styled';

export const DateRangeWrapper = styled.div`
  & .rdrDateDisplayWrapper {
    margin-left: 25px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    & .rdrDateDisplay {
      display: flex;
      justify-content: space-around;
      margin-right: 10px;

      & .rdrDateInput:nth-of-type(1):after {
        content: url('data:image/svg+xml; utf8,<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.22276 0.574371L13.5259 5.63644C13.669 5.77297 13.75 5.96216 13.75 6.16013C13.75 6.35809 13.669 6.54701 13.5259 6.68381L8.22276 11.7459C7.93368 12.0208 7.47611 12.0105 7.2001 11.7214C6.92422 11.4352 6.93459 10.9756 7.22458 10.6987L11.223 6.88411H0.973454C0.574214 6.88411 0.25 6.55989 0.25 6.16065C0.25 5.76141 0.574214 5.4378 0.973454 5.4378H11.2211L7.2227 1.62317C6.93314 1.34573 6.9241 0.886231 7.1983 0.599079C7.4755 0.309818 7.90638 0.299573 8.22276 0.574371Z" fill="black"/></svg>');
        width: 15px;
        box-sizing: border-box;
        float: left;
        margin: auto 0 auto 10px;
        transform: scale(1);
        color: #231f20;
      }

      & .rdrDateDisplayItemActive {
        border: 1px solid #008b9c;
      }

      & .rdrDayToday {
        color: 1px solid #008b9c;
      }

      & .css-j0p8y2 .rdrMonth * {
        color: black !important;
      }

      & .rdrDateInput {
        position: relative;
        width: 125px;
        display: flex;
        padding: 0 10px;
        border-radius: 20px;
        margin-right: 35px;
        margin-left: 0;
        justify-content: flex-start;

        & input {
          text-align: left;
          width: 94px;
          color: #231f20;
          font-size: 14px;
          font-weight: 600;
          font-family: 'Quicksand', 'sans-serif';
        }
      }
      & .rdrDateInput:before {
        content: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
        box-sizing: border-box;
        float: left;
        width: 20px;
        margin: auto 0;
        transform: scale(0.7);
      }
    }
  }

  & .rdrMonthAndYearWrapper {
    justify-content: center;
    & .rdrMonthAndYearPickers {
      flex: 0 0 auto !important;
    }
    & button.rdrPprevButton {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      display: inline-block;
      background-repeat: no-repeat;
      /* comment to line below <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */
      background-image: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M234.8 36.25c3.438 3.141 5.156 7.438 5.156 11.75c0 3.891-1.406 7.781-4.25 10.86L53.77 256l181.1 197.1c6 6.5 5.625 16.64-.9062 22.61c-6.5 6-16.59 5.594-22.59-.8906l-192-208c-5.688-6.156-5.688-15.56 0-21.72l192-208C218.2 30.66 228.3 30.25 234.8 36.25z"/></svg>');
      background-color: rgba(0, 0, 0, 0);
      & i {
        display: none;
      }
    }

    & button.rdrNextButton {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      display: inline-block;
      background-repeat: no-repeat;
      /* comment to line below <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */
      background-image: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M85.14 475.8c-3.438-3.141-5.156-7.438-5.156-11.75c0-3.891 1.406-7.781 4.25-10.86l181.1-197.1L84.23 58.86c-6-6.5-5.625-16.64 .9062-22.61c6.5-6 16.59-5.594 22.59 .8906l192 208c5.688 6.156 5.688 15.56 0 21.72l-192 208C101.7 481.3 91.64 481.8 85.14 475.8z"/></svg>');
      background-color: rgba(0, 0, 0, 0);
      & i {
        display: none;
      }
    }
    & span select {
      font-family: 'Quicksand', 'sans-serif';
      color: #231f20;
      font-size: 14px;
      font-weight: 600;
    }
  }

  & .rdrMonth {
    position: relative;
    & .rdrEndEdge:after,
    & .rdrStartEdge:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      background-color: inherit;
      height: 40px;
      width: 40px;
      border: 5px solid;
      border-radius: 50%;
      filter: brightness(60%);
    }
    & * {
      color: #231f20;
      font-size: 14px;
      font-weight: 600;
    }
    & .rdrDayNumber span {
      font-weight: 600;
    }
  }
`;
