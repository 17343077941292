import React, { FC, useRef, useState } from 'react';
import { FormMassage } from '_Components/Forms/FormMassage';
import { ReactComponent as FileUpload } from '../../../../_Styles/images/file_upload.svg';
import {
  FileButton,
  FileNameWrapper,
  PlxFileInputBody,
  StyledFileInput,
  WrapperFileInput,
} from './Styles';

interface IProps {
  onChange: (base64Urls: string[], fileNames: string[]) => void;
  fileNames?: string[];
  name?: string;
  className?: string;
  accept?: string;
  fileSizeLimit?: number;
  uploadIcon?: boolean;
}

export const PlxFilesInput: FC<IProps> = ({
  onChange,
  fileNames = [],
  name,
  className,
  accept = 'image/*',
  fileSizeLimit = 700000,
  uploadIcon,
}) => {
  const [filenames, setFilenames] = useState<string[]>(fileNames);
  const [errors, setErrors] = useState<string[]>([]);

  const encodeFile = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const fr = new FileReader();
      if (file.size > fileSizeLimit) {
        const errorMessage = `File "${file.name}" is too big. Max size is 700kb`;
        reject(errorMessage);
        setErrors((prevErrors) => [...prevErrors, errorMessage]);
      } else {
        fr.readAsDataURL(file);
        fr.onload = () => resolve(fr.result);
        fr.onerror = (error) => reject(error);
      }
    });

  const getEncodedFiles = async (files: FileList) => {
    const encodedFiles: (string | ArrayBuffer | null)[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        const encodedFile = await encodeFile(file);
        if (encodedFile) {
          encodedFiles.push(encodedFile);
        }
      } catch (error) {
        console.error(error);
      }
    }
    return encodedFiles;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async () => {
    setErrors([]);
    if (inputRef && inputRef.current && inputRef.current.files) {
      const files = inputRef.current.files;
      const fileList = new DataTransfer();
      Array.from(files).forEach((file) => {
        fileList.items.add(file);
      });

      const names = Array.from(files).map((file) => file.name);
      setFilenames(names);

      const encodedFiles = await getEncodedFiles(fileList.files);
      onChange(encodedFiles as string[], names);
    }
  };

  return (
    <>
      <WrapperFileInput>
        <PlxFileInputBody className={className}>
          <FileButton
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {uploadIcon ? <FileUpload /> : 'Upload'}
          </FileButton>
          <FileNameWrapper>
            {filenames.length > 0 ? filenames.join(', ') : 'No files selected'}
          </FileNameWrapper>
          <StyledFileInput
            accept={accept}
            ref={inputRef}
            type="file"
            name={name}
            data-testid="fileInput"
            onChange={handleChange}
            multiple // Enable multiple file selection
          />
        </PlxFileInputBody>
      </WrapperFileInput>
      {errors.length > 0 && (
        <FormMassage message={`Error(s): ${errors.join(', ')}`} />
      )}
    </>
  );
};
