import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { ReviewAssets } from '_Routes/Home/_Components/ReviewAssets/ReviewAssets';
import {
  ICampaignInfoItem,
  PlxCampaignInformation,
} from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { useTranslation } from 'react-i18next';
import { AssetsContext } from '_Types/assetsContext';
import {
  IAsset,
  IAssetsContext,
  IUploadFileDto,
  IThriveCampaign,
  IThriveCampaignEditForm,
} from '_State/Thrive/types';
import {
  addAssetScreenshot,
  deleteAssetNotification,
  deleteCampaignNotification,
  getAssetsAdmin,
  getThriveCampaignsAdmin,
  updateAssetScreenshot,
  updateThriveCampaign,
  updateThrivePaymentStatus,
} from '_State/Thrive/Api/thrive-admin.api';
import { useParams } from 'react-router-dom';
import { IAdminCampaignsQueryParams } from '_Types/campaigns';
import { ClientInstructionsDialog } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaignAssets/ClientInstructionsDialog';
import {
  configureAdminThriveCampaignInfo,
  configureEditorThriveCampaignInfo,
} from '../AdminThriveCampaign/config';
import { AdminEditCampaignDialog } from './AdminEditCampaignDialog';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { UserType } from '_State/User/user.types';

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const AssetsWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const InfoWrapper = styled.div`
  width: 26.5rem;
  margin-right: 3rem;
  height: 100%;
`;

const StyledCampaignInformation = styled(PlxCampaignInformation)`
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4rem;
  height: calc(100% - 7.5rem);
  width: 100%;
`;

const ClientInstructionsButton = styled(PlxButton)`
  && {
    margin-top: 2rem;
  }
`;

interface IProps {}

export const AdminThriveCampaignAssets: FC<IProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [campaign, setCampaign] = useState<IThriveCampaign>();
  const [clientInstructionsDialog, setClientInstructionDialog] = useState(
    false
  );
  const [campaignInfoConfig, setCampaignInfoConfig] = useState<
    ICampaignInfoItem[]
  >([]);
  const [openEditCampaignDialog, setOpenEditCampaignDialog] = useState(false);
  const { user } = useSelector((state: IState) => state.user);

  const fetchAssets = (id?: string | number) => {
    getAssetsAdmin(id).then((assets) => setAssets(assets));
  };

  const changePaymentStatus = (value: unknown) => {
    updateThrivePaymentStatus(
      campaign?.id,
      campaign?.payment.paymentId,
      value as string
    ).then();
  };

  const fetchCampaign = (id?: string | number) => {
    if (id) {
      getThriveCampaignsAdmin({
        campaignId: id,
      } as IAdminCampaignsQueryParams).then((c) => {
        setCampaign(c.content[0]);
        return c;
      });
    }
  };

  useEffect(() => {
    setCampaignInfoConfig(
      user?.userType === UserType.ADMIN
        ? configureAdminThriveCampaignInfo(t, changePaymentStatus, campaign)
        : configureEditorThriveCampaignInfo(t, campaign)
    );
    // eslint-disable-next-line
  }, [campaign, t, user]);

  useEffect(() => {
    fetchAssets(id);
    fetchCampaign(id);
    // eslint-disable-next-line
  }, [id]);

  const context: IAssetsContext = {
    assets,
    admin: true,
    addAsset: (assetUpdate: IUploadFileDto, campaignId?: string | number) =>
      addAssetScreenshot(assetUpdate, campaignId).then(() => fetchAssets(id)),
    updateAsset: (
      assetUpdate: IUploadFileDto,
      assetId?: string | number,
      campaignId?: string | number
    ) =>
      updateAssetScreenshot(assetUpdate, assetId, campaignId).then(() =>
        fetchAssets(id)
      ),
    deleteAssetNotification: (assetId?: number, notificationId?: number) => {
      deleteAssetNotification(id, assetId, notificationId).then();
    },
  };

  const openEditCampaign = () => {
    setOpenEditCampaignDialog(true);
  };

  const closeEditCampaign = () => {
    setOpenEditCampaignDialog(false);
  };

  const handleCampaignEditSubmit = (form: IThriveCampaignEditForm) => {
    return updateThriveCampaign(form, id)
      .then(() => fetchCampaign(id))
      .then(() => setOpenEditCampaignDialog(false));
  };

  const campaignUpdatesNotification = useMemo(() => {
    if (
      campaign?.campaignNotification?.instructions &&
      campaign?.campaignNotification?.thrivePresentation
    ) {
      return 2;
    } else if (campaign?.campaignNotification?.instructions) {
      return 1;
    } else if (campaign?.campaignNotification?.thrivePresentation) {
      return 1;
    }
    return undefined;
  }, [campaign]);

  const handleInstructionsButtonClick = () => {
    campaign?.campaignNotification &&
      deleteCampaignNotification(
        campaign?.id,
        campaign?.campaignNotification.id
      ).then();
    setClientInstructionDialog(true);
  };

  return (
    <>
      <AssetsContext.Provider value={context}>
        <HeaderWrapper>
          <PlxDashboardHeader
            backLink={`/home/admin/thrive-campaign/${id}`}
            backLabel={`${t('thrive.dashboard.campaign')} #${id}`}
            title={`${t('thrive.admin.assetApproval')} #${id}`}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <InfoWrapper>
            <StyledCampaignInformation
              label={t('thrive.dashboard.campaignInformation')}
              config={campaignInfoConfig}
              onEditClick={openEditCampaign}
              editable
            >
              <ClientInstructionsButton
                size={'small'}
                notificationQuantity={campaignUpdatesNotification}
                label={t('thrive.admin.clientInstructions')}
                onClick={handleInstructionsButtonClick}
              />
            </StyledCampaignInformation>
          </InfoWrapper>
          <AssetsWrapper>
            <ReviewAssets label={t('thrive.admin.approveAssets')} />
          </AssetsWrapper>
        </ContentWrapper>
      </AssetsContext.Provider>
      <ClientInstructionsDialog
        campaignNotification={campaign?.campaignNotification}
        title={t('thrive.admin.clientInstructions')}
        open={clientInstructionsDialog}
        onClose={() => setClientInstructionDialog(false)}
      />
      <AdminEditCampaignDialog
        campaignId={id}
        onSubmit={handleCampaignEditSubmit}
        open={openEditCampaignDialog}
        onClose={closeEditCampaign}
      />
    </>
  );
};
