import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Form, Formik } from 'formik';
import { TextField } from '@material-ui/core';
import { XemInputCountry } from '_Components/PlxInput/XemInputCountry';
import {
  InputContainer,
  ButtonWrapper,
  StyledButton,
  ClientFormWrapper,
} from '_Routes/Auth/Agency/StylesAg';
import { CustomErrMess } from '_Routes/Auth/DistributionPartner/Register/StyleDP';
import { addClient } from '_State/User/Api/agency.api';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AddClientForm } from '_State/User/user.types';

const FormValidation = Yup.object({
  firstName: Yup.string().required('* required').label('First Name'),
  lastName: Yup.string().required('* required').label('Last Name'),
  businessName: Yup.string().required('* required').label('Business Name'),
  country: Yup.string().required('* required').label('Phone Number'),
  email: Yup.string()
    .email('Invalid email')
    .required('* required')
    .label('Email'),
});

export const NewUserForm: FC<{
  userId: number;
}> = ({ userId }) => {
  const { t } = useTranslation();
  //const [apiErrorMessage, setApiErrorMessage] = useState('');
  const history = useHistory();
  const [data, setData] = useState<AddClientForm>({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    companyName: '',
  });

  const handleSubmit = (newData: AddClientForm): void => {
    setData((prev) => ({ ...prev, ...newData }));
    const data = {
      firstName: newData.firstName,
      lastName: newData.lastName,
      email: newData.email,
      country: newData.country,
      companyName: newData.companyName,
    };
    addClient(data, userId).then(() => {
      history.push('/home/agency/spectrum/clients');
      window.location.reload();
    });
  };

  return (
    <ClientFormWrapper>
      <Formik
        initialValues={data}
        onSubmit={(val) => console.log(val)}
        validationSchema={FormValidation}
      >
        {({ values, handleChange }) => (
          <Form>
            <InputContainer>
              <TextField
                name="firstName"
                label={t('agency.firstName')}
                placeholder="required"
                variant="outlined"
                onChange={handleChange}
                value={values.firstName}
              />
              <ErrorMessage
                name="firstName"
                render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
              />
              <TextField
                name="lastName"
                label={t('agency.lastName')}
                placeholder="required"
                variant="outlined"
                onChange={handleChange}
                value={values.lastName}
              />
              <ErrorMessage
                name="lastName"
                render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
              />
              <TextField
                name="email"
                label={t('agency.email')}
                placeholder="required"
                variant="outlined"
                onChange={handleChange}
                value={values.email}
              />
              <ErrorMessage
                name="email"
                render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
              />

              <TextField
                name="companyName"
                label={t('agency.companyName') + ' *'}
                placeholder="required"
                variant="outlined"
                onChange={handleChange}
                value={values.companyName}
              />
              <ErrorMessage
                name="companyName"
                render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
              />
              <XemInputCountry
                value={values.country}
                maxWidth="62rem"
                handleCountryChange={handleChange('country')}
                testId="country-field"
              />
              <ErrorMessage
                name="country"
                render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
              />
              <ButtonWrapper>
                <StyledButton onClick={() => handleSubmit(values)}>
                  {t('agency.submit')}
                </StyledButton>
              </ButtonWrapper>
            </InputContainer>
          </Form>
        )}
      </Formik>
    </ClientFormWrapper>
  );
};
