import React, { FC, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { Asset } from '_Routes/Home/_Components/ReviewAssets/Asset';
import { IAsset } from '_State/Thrive/types';
import { AddAssetDialog } from '_Routes/Home/_Components/ReviewAssets/AddAssetDialog';
import { AssetsContext } from '_Types/assetsContext';
import { useTranslation } from 'react-i18next';
import { EmptyDataPlaceHolder } from '_Components/EmptyDataPlaceHolder';

const AssetsWrapper = styled.div`
  margin: 0 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  align-content: flex-start;
`;

const AddAssetArea = styled.div`
  color: #3c6ef6;
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 4rem 2rem 0 2rem;
  height: 28.5rem;
  width: 37rem;
  border: 2px dashed #3c6ef6;
  border-radius: 5px;
  :hover {
    cursor: pointer;
    background: rgba(60, 110, 246, 0.05);
  }
`;

const AssetWrapper = styled.div`
  margin: 4rem 2rem 0 2rem;
`;

interface IProps {
  label: string;
  tooltip?: string;
}

export const ReviewAssets: FC<IProps> = ({ label, tooltip }) => {
  const { assets, admin } = useContext(AssetsContext);
  const { t } = useTranslation();
  const [addDialog, setAddDialog] = useState(false);
  const [chosenAsset, setChosenAsset] = useState<IAsset>();

  const handleImageClick = (id: number) => {
    setChosenAsset(assets.filter((a) => a.id === id)[0]);
    setAddDialog(true);
  };

  const handleAddAssetClick = () => {
    setChosenAsset(undefined);
    setAddDialog(true);
  };

  const areAssets = useMemo(() => assets.length > 0 || admin, [assets, admin]);

  return (
    <PlxInfoPanel label={label} tooltip={tooltip}>
      <AssetsWrapper>
        {admin && (
          <AddAssetArea onClick={handleAddAssetClick}>
            {t('thrive.admin.add')}
          </AddAssetArea>
        )}

        {areAssets ? (
          <>
            {assets.map((asset, index) => {
              return (
                <AssetWrapper key={index}>
                  <Asset onImageClick={handleImageClick} asset={asset} />
                </AssetWrapper>
              );
            })}
          </>
        ) : (
          <EmptyDataPlaceHolder massage={t('noData')} />
        )}
      </AssetsWrapper>

      <AddAssetDialog
        onClose={() => setAddDialog(false)}
        open={addDialog}
        asset={chosenAsset}
      />
    </PlxInfoPanel>
  );
};
