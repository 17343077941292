import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { FC, useReducer } from 'react';
import { PlxInputAddChannelX } from '_Components/PlxInput/PlxInputAddChannelX';
import AddIcon from '@material-ui/icons/Add';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import {
  Agreement,
  BtnWrap,
  CardInfoWrapper,
  CardNumber,
  countryList,
  CountryWrap,
  CreditCardFormWrap,
  HorizontalDivider,
  reducer,
  SubTableHeader,
} from './utils';

const initialValues = {
  number: '',
  expiry: '',
  cvc: '',
  name: '',
  email: '',
  country: '',
  zip: '',
};

export const FinfluencerWalletCreditCardForm: FC = () => {
  // const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialValues);

  const handleChange = (e: any) => {
    const action = {
      input: e.target.name,
      value: e.target.value,
    };
    dispatch(action);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      console.log(state);
    },
    validate: () => {},
  });

  return (
    <CreditCardFormWrap>
      <form onSubmit={formik.handleSubmit}>
        <SubTableHeader>Card Information</SubTableHeader>
        <CardInfoWrapper>
          <CardNumber>
            <PlxInputAddChannelX
              name="number"
              value={state.number}
              placeholder="Card Number"
              required
              onChange={handleChange}
            />
          </CardNumber>
          <Divider />
          <HorizontalDivider>
            <PlxInputAddChannelX
              type="tel"
              name="expiry"
              value={state.expiry}
              required
              placeholder="MM/YY"
              onChange={handleChange}
            />
            <Divider orientation="vertical" flexItem />
            <PlxInputAddChannelX
              value={state.cvc}
              type="tel"
              name="cvc"
              placeholder="CVC"
              required
              onChange={handleChange}
            />
          </HorizontalDivider>
        </CardInfoWrapper>
        <SubTableHeader>Name & Email</SubTableHeader>
        <CardInfoWrapper>
          <CardNumber>
            <PlxInputAddChannelX
              type="text"
              name="name"
              value={state.name}
              placeholder="Name"
              required
              onChange={handleChange}
            />
          </CardNumber>
          <Divider />
          <HorizontalDivider>
            <PlxInputAddChannelX
              type="text"
              name="email"
              value={state.email}
              required
              placeholder="example@example.com"
              onChange={handleChange}
            />
          </HorizontalDivider>
        </CardInfoWrapper>
        <SubTableHeader>Country & Zip/Postal Code</SubTableHeader>
        <CardInfoWrapper>
          <CardNumber>
            <CountryWrap>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  name="country"
                  value={state.country}
                  onChange={handleChange}
                  required
                >
                  {countryList.map(({ name }, index) => {
                    return (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </CountryWrap>
          </CardNumber>
          <Divider />
          <HorizontalDivider>
            <PlxInputAddChannelX
              type="text"
              name="zip"
              value={state.zip}
              required
              placeholder="Postal Code"
              onChange={handleChange}
            />
          </HorizontalDivider>
        </CardInfoWrapper>
        <Agreement>
          By clicking Add, you agree to the Stripe Connected Account
          Agreement... to be confirmed if this is needed here
        </Agreement>
        <BtnWrap>
          <PlxButtonX inputType="submit">
            <AddIcon />
            ADD
          </PlxButtonX>
        </BtnWrap>
      </form>
    </CreditCardFormWrap>
  );
};
