import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;
export const StyledSubtitle = styled.div`
  position: absolute;
  bottom: -5px;
  font-size: 1rem;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0.5rem;
  border-width: 1px;

  svg.MuiSvgIcon-root {
    color: #9d05b0;
    border-width: 1px;
  }
`;

export const StyledLabel = styled(FormControlLabel)`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.25rem;
  position: relative;
`;
