import React from 'react';
import { AgencyClientsTable } from './AgencyClientsTable';
import { ClientsPageWrap } from '_Routes/Auth/Agency/StylesAg';

export const AgencyClients = () => {
  return (
    <ClientsPageWrap>
      <AgencyClientsTable />
    </ClientsPageWrap>
  );
};
