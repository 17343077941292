import React, { FC } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ApplePay } from './../../../../../../_Styles/images/ApplePay2.svg';
import { ReactComponent as PayPal } from './../../../../../../_Styles/images/PayPal.svg';
// import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SectionHeader } from '../../utils';
import { useHistory } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { ToggleButtons } from './FinfluencerWalletAddPaymentMethodsToggleButton';
import { FinfluencerWalletCreditCardForm } from './FinfluencerWalletCreditCardForm';
import { FinfluencerWalletBankAccForm } from './FinfluencerWalletBankAccForm';

const Body = styled.div`
  width: 100%;
  max-width: 980px;
  /* border: 1px solid; */
  height: 100%;
`;
const Navigator = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4rem 3rem 4rem;
  align-items: center;
  svg {
    width: 2.5rem;
    height: auto;
    margin-right: 2rem;
  }
`;
const MainContainer = styled.div`
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 600px; */
  width: 100%;
  justify-content: center;
  button {
    width: 50rem;
  }
`;
const AddPaymentMethodsWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  gap: 1rem;
`;
const ArrowWrap = styled.div`
  border: 1px solid #a9b3bb;
  padding: 1rem;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  width: 50px;
  height: 50px;
  cursor: pointer;
  :hover svg {
    fill: #000;
  }
`;
// const ApplePayWrap = styled.div`
//   && button {
//     background: #000;
//   }
// `;
const ButtonWrap = styled.div<{ applePay: boolean }>`
  width: 500px;
  max-width: 100%;
  height: 40px;
  border: ${({ applePay }) =>
    applePay ? '1px solid #000' : '1px solid #a9b3bb'};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: ${({ applePay }) => (applePay ? '#000' : '')};
  cursor: pointer;
`;
const DividerWrap = styled.div`
  margin-top: 2rem;
  /* display: flex; */
  /* justify-content: center; */
`;
const DividerText = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  width: fit-content;
  margin: -8px auto 0;
`;
const ToggleWrap = styled.div`
  margin-top: 2rem;
  button {
    max-width: 200px;
  }
`;
const CreditCardInfo = styled.div`
  max-width: 500px;
  width: 100%;
`;
export const FinfluencerWalletAddPaymentMethods: FC = () => {
  // const { t } = useTranslation();
  const history = useHistory();

  const [alignment, setAlignment] = React.useState('left');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Body>
      <Navigator>
        <ArrowWrap onClick={() => history.push('./')}>
          <ArrowBackIcon />
        </ArrowWrap>
        <div>
          <SectionHeader> Add Payment Method </SectionHeader>
        </div>
      </Navigator>
      <MainContainer>
        <AddPaymentMethodsWrap>
          <ButtonWrap applePay={false}>
            <PayPal />
          </ButtonWrap>
          <ButtonWrap applePay={true}>
            <ApplePay />
            {/* <img src={ApplePay2} alt="apple pay" /> */}
          </ButtonWrap>
          <DividerWrap>
            <Divider />
            <DividerText>Or add your credit card</DividerText>
          </DividerWrap>
        </AddPaymentMethodsWrap>
        <ToggleWrap>
          <ToggleButtons
            // handleChange={handleSwitch}
            options={{ option1: 'Debit/Credit', option2: 'Bank Account' }}
            alignment={alignment}
            handleChange={handleChange}
          />
        </ToggleWrap>
        {alignment === 'left' && (
          <CreditCardInfo>
            <FinfluencerWalletCreditCardForm />
          </CreditCardInfo>
        )}
        {alignment === 'right' && (
          <CreditCardInfo>
            <FinfluencerWalletBankAccForm />
          </CreditCardInfo>
        )}
      </MainContainer>
    </Body>
  );
};
