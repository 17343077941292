import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

export const AuthHeader = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: 500;
  font-size: 2.5rem;
  font-family: 'Quicksand', sans-serif;
  padding-bottom: 1rem;

  span:first-of-type {
    font-size: 1.5rem;
  }
  a {
    margin-top: 0;
  }
  a span {
    font-size: 2rem;
    color: #3c6ef6;
  }
`;
