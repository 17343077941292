import React, { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import { useTranslation } from 'react-i18next';
import { IChannelData } from './utils';
// import { Link } from 'react-router-dom';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import LinkIcon from '@material-ui/icons/Link';

import {
  EnhancedTableHead,
  getComparator,
  Order,
  stableSort,
  useStyles,
} from './utils';
import { XemTooltip } from '_Components/XemTooltip/XemTooltip';
import { useTranslation } from 'react-i18next';

interface Data {
  channelType: string;
  engagement: number;
  followers: number;
  reactions: number;
  shares: number;
  comments: number;
  clicks: number;
  startDate: string;
  impressions: number;
  videoSaves: number;
  other: string;
}
const createData = (
  channelType: string,
  engagement: number,
  followers: number,
  reactions: number,
  shares: number,
  comments: number,
  clicks: number,
  startDate: string,
  impressions: number,
  videoSaves: number,
  other: any
): Data => {
  return {
    channelType,
    engagement,
    followers,
    reactions,
    shares,
    comments,
    clicks,
    startDate,
    impressions,
    videoSaves,
    other,
  };
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'channelType',
    numeric: false,
    disablePadding: false,
    label: 'Channel type',
  },
  {
    id: 'engagement',
    numeric: true,
    disablePadding: false,
    label: 'Engagement',
  },
  { id: 'followers', numeric: true, disablePadding: false, label: 'Followers' },
  {
    id: 'reactions',
    numeric: true,
    disablePadding: false,
    label: 'Reactions',
  },
  {
    id: 'shares',
    numeric: true,
    disablePadding: false,
    label: 'Shares',
  },
  {
    id: 'comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'clicks',
    numeric: true,
    disablePadding: false,
    label: 'Clicks',
  },
  {
    id: 'impressions',
    numeric: true,
    disablePadding: false,
    label: 'Impressions',
  },
  {
    id: 'videoSaves',
    numeric: true,
    disablePadding: false,
    label: 'Video saves',
  },
  {
    id: 'other',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

export const TopChannels: FC<{
  channelData: IChannelData[];
}> = ({ channelData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('engagement');
  const [page, setPage] = React.useState(0);

  const rows = channelData.map(
    ({
      channelType,
      engagement,
      followers,
      reactions,
      shares,
      comments,
      clicks,
      startDate,
      impressions,
      videoSaves,
    }) =>
      createData(
        channelType,
        engagement,
        followers,
        reactions,
        shares,
        comments,
        clicks,
        startDate,
        impressions,
        videoSaves,
        <div className={classes.linksWrap}>
          {/* <Link to={`./engagement?filterBy=${channelType}`}>
            <VisibilityIcon />
          </Link>
          <LinkIcon
            className={classes.copyLink}
            onClick={() => {
              navigator.clipboard.writeText(
                `https://app.xemotomedia.com/home/client/spectrum/engagement?filterBy=${channelType}`
              );
            }}
          /> */}
          {/* </Link> */}
        </div>
      )
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const emptyRows = 5 - Math.min(5, rows.length - page * 5);
  const standardAmount = (amount: number) =>
    amount
      ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : amount === 0
      ? '0'
      : 'N/A';

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * 5, page * 5 + 5)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={classes.tableRow}
                    >
                      <TableCell component="th" scope="row">
                        {row.channelType}
                      </TableCell>
                      <TableCell align="right">
                        {row.engagement
                          .toFixed(3)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.followers)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.reactions)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.shares)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.comments)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.clicks)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.impressions)}
                      </TableCell>
                      <TableCell align="right">
                        {standardAmount(row.videoSaves)}
                      </TableCell>
                      <TableCell align="right">{row.other}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.paginationWrap}>
        <div>
          <XemTooltip
            primaryText={t('spectrum.topSpectrumTable.topChannelsTooltip')}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
          />
          {/* <Link className={classes.seeAll} to="./engagement">
          {t('spectrum.topSpectrumTable.seeAllChannels')}
        </Link> */}
        </div>
      </div>
    </div>
  );
};
