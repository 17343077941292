import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ToggleSwitch } from '_Components/ToggleSwitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div``;

interface IProps {
  comparing: boolean;
  setCompare: (value: boolean) => void;
}

export const CompareToggle: FC<IProps> = ({ comparing, setCompare }) => {
  const { t } = useTranslation();

  const onChange = (): void => {
    setCompare(!comparing);
  };
  return (
    <Wrapper>
      <ToggleSwitch
        onChange={onChange}
        toggled={comparing}
        title={t('analytics.compare')}
      />
    </Wrapper>
  );
};
