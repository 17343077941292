import React, { FC } from 'react';
import { PlxText } from '_Components/Text/PlxText';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface IProps {
  status: any;
  method: string;
}

interface IText {
  color: any;
}

const StyledText = styled(PlxText)<IText>`
  color: ${({ color }) => color};
`;

export const Payment: FC<IProps> = ({ method, status }) => {
  const { t } = useTranslation();
  return <StyledText color={status?.color}>{t(method)}</StyledText>;
};
