import React, { FC, useContext } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import DoneIcon from '_Styles/images/DoneIcon.png';
import {
  ButtonsWrapper,
  StyledCompleted,
  StyledH1,
  StyledImage,
  StyledTip2,
  StyledValCompleted,
  StyledValidationCompletedWrapper,
} from '../../utils';
import { ValidatedCampaignInfo } from './ValidatedCampaignInfo';
import { ISpectrumProgram } from '_State/Spectrum/types';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  height: 60px;
`;

export const CompleteValidation: FC<{ data: ISpectrumProgram }> = ({
  data,
}) => {
  const { isAgencyType } = useContext(NavigationContext);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledValidationCompletedWrapper>
        <StyledCompleted>
          <StyledValCompleted>
            {t('spectrum.validation.completed')}
          </StyledValCompleted>
        </StyledCompleted>
        <div style={{ textAlign: 'center' }}>
          <StyledImage src={DoneIcon} alt="my Image" />
          <StyledH1>{t('spectrum.validation.validated')}</StyledH1>
          <StyledTip2>{t('spectrum.validation.details')}</StyledTip2>
          <ValidatedCampaignInfo data={data} />
          <Divider style={{ width: '220px', margin: '100px auto 0' }} />
        </div>
      </StyledValidationCompletedWrapper>
      <ButtonsSection>
        <PlxButtonX
          newDesign
          size="small"
          onClick={() => {
            history.push(
              isAgencyType ? '/home/agency/dashboard' : '/home/client/dashboard'
            );
          }}
          label={t('spectrum.footerButtons.back')}
        />
        <ButtonsWrapper>
          <PlxButtonX
            newDesign
            size="small"
            onClick={() => {
              history.push(
                isAgencyType
                  ? '/home/agency/spectrum-campaign/program'
                  : '/home/client/spectrum-campaign/program'
              );
            }}
            label={t('spectrum.footerButtons.next')}
            variant2
          />
        </ButtonsWrapper>
      </ButtonsSection>
    </div>
  );
};
