import { spectrumApi } from '_Api/api';
import {
  IProgramDraft,
  ISchedulingForm,
  IPostSample,
  ISpectrumCampaign,
  // ISpectrumBudget,
  ISpectrumProgram,
  AverageTrend,
  Average,
  HashtagEngagement,
} from '_State/Spectrum/types';
import { IStockTickersData, ITag, ITagUpdate } from '_Types/campaigns';
import moment from 'moment';
import { IDistributionPartnerPostsUrls } from '_State/DistributionPartner/distributionPartner.types';
import { XPropWallet } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerWallet/utils';

export const getDraft = (
  clientSelected?: string | null
): Promise<ISpectrumCampaign> => {
  let url = `spectrum-campaigns/draft`;
  if (clientSelected) {
    url = `spectrum-campaigns/draft?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.get(url).json();
};

export const getProgramActive = (
  clientSelected?: string | null
): Promise<ISpectrumProgram> => {
  let url = `spectrum-campaigns/programs/active`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/programs/active?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.get(url).json();
};

export const checkProcessCheckout = (
  campaignId: string,
  sessionId: string
): Promise<any> => {
  return spectrumApi
    .post(
      `payment/process-checkout?campaignId=${campaignId}&checkoutId=${sessionId}`
    )
    .json();
};

export const checkoutSession = (createCheckoutSession: {
  campaignId: any;
  packageId: number | undefined;
  // acceptClientContract: boolean;
  pricingType: string;
}): Promise<any> => {
  console.log(createCheckoutSession);

  return spectrumApi
    .post(`payment/create-checkout-session`, {
      json: createCheckoutSession,
    })
    .json();
};
export const createWallet = (
  code: string,
  wallets: XPropWallet[]
): Promise<any> => {
  const label = 'Wallet ' + (wallets.length + 1).toString();
  return spectrumApi
    .post(`wallets/paypal`, {
      json: {
        code: code,
        label: label,
        phone: '',
        defaultWallet: false,
      },
    })
    .json();
};
export const getWallets = (): Promise<any> => {
  return spectrumApi.get(`wallets/me`).json();
};
export const getWalletTotal = (): Promise<any> => {
  return spectrumApi.get(`transactions/balance`).json();
};
export const getRecentTransactions = (): Promise<any> => {
  return spectrumApi.get(`transactions/me`).json();
};

export const updateWalletById = (walletId: number): Promise<any> => {
  return spectrumApi
    .patch(`wallets/${walletId}`, {
      json: {
        defaultWallet: true,
      },
    })
    .json();
};
export const updateWalletNameById = (
  walletId: number,
  name: string
): Promise<any> => {
  return spectrumApi
    .patch(`wallets/${walletId}`, {
      json: {
        label: name,
      },
    })
    .json();
};

export const deleteWallet = (walletId: number): Promise<any> => {
  return spectrumApi.delete(`wallets/${walletId}`);
};

export const getBudgets = (): Promise<any[]> => {
  const callKy: any = (url: string) => {
    return spectrumApi.get(url).json();
  };
  const all = Promise.all([
    callKy('campaign-packages/all?campaigns=3&type=BUSINESS'),
    callKy('campaign-packages/all?campaigns=6&type=BUSINESS'),
    callKy('campaign-packages/all?campaigns=12&type=BUSINESS'),
  ]).then((res) => {
    return res[0].concat(res[1]).concat(res[2]);
  });
  return all;
};

export const getPostSamples = (
  id: number | string | undefined
): Promise<IPostSample[]> => {
  return spectrumApi
    .get(`users/self/spectrum-campaigns/${id}/post-samples`)
    .json();
};
export const getPostUrls = (
  id: number | string | undefined
): Promise<IDistributionPartnerPostsUrls[]> => {
  return spectrumApi.get(`spectrum-campaigns/${id}/post-urls`).json();
};

export const updateSpectrumCampaignTag = (
  campaignId: number,
  tagId: number | null | undefined,
  isAdmin?: number
): Promise<any> => {
  const url = isAdmin
    ? `spectrum-campaigns/${campaignId}/tags?userId=${isAdmin}`
    : `spectrum-campaigns/${campaignId}/tags`;

  return spectrumApi.put(url, { json: { tagId: tagId } }).json();
};

export const getStockTickers = (
  searchString: string,
  stockExchange?: string
): Promise<IStockTickersData> => {
  const searchParams = stockExchange
    ? {
        searchString: searchString,
        inStockExchanges: stockExchange,
        pageSize: 50,
      }
    : {
        searchString: searchString,
        pageSize: 50,
      };
  return spectrumApi
    .get(`stock-ticker`, {
      searchParams: searchParams as any,
    })
    .json();
};

export const completeDraft = (clientSelected?: string | null): Promise<any> => {
  let url = `spectrum-campaigns/draft/complete`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/draft/complete?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.post(url).json();
};

export const saveProgramDraft = (
  form: IProgramDraft,
  clientSelected?: string | null
): Promise<any> => {
  let url = `spectrum-campaigns/draft`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/draft?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.post(url, { json: form }).json();
};

export const updateProgramDraft = (
  form: IProgramDraft,
  clientSelected?: string | null
): Promise<any> => {
  let url = `spectrum-campaigns/draft`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/draft?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.put(url, { json: form }).json();
};

export const updateSchedulingDraft = (
  form: ISchedulingForm,
  clientSelected?: string | null
): Promise<any> => {
  let url = `spectrum-campaigns/draft/campaign-scheduling`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/draft/campaign-scheduling?agencyClientId=${clientSelected}`;
  }
  return spectrumApi
    .put(url, {
      json: { ...form, utcOffset: moment().utcOffset() },
    })
    .json();
};

export const updateCampaignDraft = (
  form: IProgramDraft,
  clientSelected?: string | null
): Promise<any> => {
  let url = `spectrum-campaigns/draft/campaign-content`;
  if (clientSelected !== null && clientSelected !== undefined) {
    url = `spectrum-campaigns/draft/campaign-content?agencyClientId=${clientSelected}`;
  }
  return spectrumApi.put(url, { json: form }).json();
};

export const getSpectrumTags = (): Promise<ITag[]> => {
  return spectrumApi.get(`spectrum-campaigns/tags`).json();
};

export const updateSpectrumTags = (
  newTags: ITagUpdate,
  isAdmin?: number
): Promise<any> => {
  const url = isAdmin
    ? `spectrum-campaigns/tags?userId=${isAdmin}`
    : `spectrum-campaigns/tags`;

  return spectrumApi.put(url, { json: newTags }).json();
};

export const deleteSpectrumTag = (
  tagId: number,
  isAdmin?: number
): Promise<any> => {
  const url = isAdmin
    ? `spectrum-campaigns/tags/${tagId}?userId=${isAdmin}`
    : `spectrum-campaigns/tags/${tagId}`;

  return spectrumApi.delete(url);
};

export const createSpectrumTag = (
  name: string,
  isAdmin?: number
): Promise<ITag> => {
  const url = isAdmin
    ? `spectrum-campaigns/tags?userId=${isAdmin}`
    : `spectrum-campaigns/tags`;

  return spectrumApi
    .post(url, {
      json: {
        name,
      },
    })
    .json();
};

export const getSpectrumStatistics = (): Promise<ITag[]> => {
  return spectrumApi.get(`spectrum-campaigns/dashboard-statistics`).json();
};
export const getCampaignData = (
  start: string,
  end: string,
  selectedClient: string | null
): Promise<any> => {
  let url = `engagements/campaigns?from=${start}&to=${end}`;
  if (selectedClient !== null) {
    url += `&agencyClientId=${selectedClient}`;
  }
  return spectrumApi.get(url).json();
};

export const getChannelData = (
  start: string,
  end: string,
  selectedClient: string | null
): Promise<any> => {
  let url = `engagements/channels?from=${start}&to=${end}`;
  if (selectedClient !== null) {
    url += `&agencyClientId=${selectedClient}`;
  }
  return spectrumApi.get(url).json();
};
export const getTopFinfluencerData = (
  start: string,
  end: string,
  selectedClient: string | null
): Promise<any> => {
  let url = `engagements/finfluencers?from=${start}&to=${end}`;
  if (selectedClient !== null) {
    url += `&agencyClientId=${selectedClient}`;
  }
  return spectrumApi.get(url).json();
};
export const getTopPostData = (
  start: string,
  end: string,
  selectedClient: string | null
): Promise<any> => {
  let url = `engagements/posts?from=${start}&to=${end}`;
  if (selectedClient !== null) {
    url += `&agencyClientId=${selectedClient}`;
  }
  return spectrumApi.get(url).json();
};

export const getSpectrumAverageEngagementsTrend = (
  userId: number,
  params?: Record<string, any>
): Promise<AverageTrend[]> => {
  let url = 'engagements/channels/channel-type/average-engagement/trend';
  const urlParams = new URLSearchParams();

  urlParams.append('userId', userId.toString());

  if (params?.campaignId) {
    urlParams.append('campaignId', params?.campaignId);
  }

  if (params?.startDate) {
    const from = moment(params?.startDate);

    if (from.isValid()) {
      urlParams.append('from', from.format('yyyy-MM-DD'));
    }
  }

  if (params?.endDate) {
    const to = moment(params?.endDate);

    if (to.isValid()) {
      urlParams.append('to', to.format('yyyy-MM-DD'));
    }
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};

export const getSpectrumAverageEngagements = (
  userId: number,
  params?: Record<string, any>
): Promise<Average[]> => {
  let url = 'engagements/channels/channel-type/average-engagement';
  const urlParams = new URLSearchParams();

  urlParams.append('userId', userId.toString());

  if (params?.campaignId) {
    urlParams.append('campaignId', params?.campaignId);
  }

  if (params?.startDate) {
    const from = moment(params?.startDate);

    if (from.isValid()) {
      urlParams.append('from', from.format('yyyy-MM-DD'));
    }
  }

  if (params?.endDate) {
    const to = moment(params?.endDate);

    if (to.isValid()) {
      urlParams.append('to', to.format('yyyy-MM-DD'));
    }
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};

export const getSpectrumHashtags = (
  userId: number,
  params?: Record<string, any>
): Promise<HashtagEngagement[]> => {
  let url = 'engagements/hashtags';
  const urlParams = new URLSearchParams();

  urlParams.append('userId', userId.toString());

  if (params?.campaignList) {
    urlParams.append('campaignList', String(params?.campaignList));
  }

  if (params?.startDate) {
    const from = moment(params?.startDate);

    if (from.isValid()) {
      urlParams.append('from', from.format('yyyy-MM-DD'));
    }
  }

  if (params?.endDate) {
    const to = moment(params?.endDate);

    if (to.isValid()) {
      urlParams.append('to', to.format('yyyy-MM-DD'));
    }
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};
