import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import React, { ReactElement } from 'react';
import { Payment } from '../../Spectrum/Dashboard/_Components/Payment';
import { Status } from '../../Spectrum/Dashboard/_Components/Status';
import { CampaignStatus } from '_Types/campaigns';
import { SeeCampaign } from '../../Spectrum/Dashboard/_Components/SeeCampaign';
import { IThriveCampaign } from '_State/Thrive/types';
import { PlxText } from '_Components/Text/PlxText';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  PAYMENT_METHOD_MAP_STRATEGY,
  PAYMENT_STATUS_MAP_STRATEGY,
} from '../../../types';
import {
  getPaymentMethod,
  getPaymentStatus,
} from '../../Spectrum/Dashboard/utils';

const StyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const createAdminThriveTableConfig = (): CellConfig<
  IThriveCampaign
>[] => {
  return [
    {
      name: 'Company Name',
      propKey: 'companyName',
    },
    {
      name: 'Campaign ID',
      propKey: 'id',
    },
    {
      name: 'Submit Date',
      renderCell({ createdDate }: IThriveCampaign): ReactElement {
        return (
          <StyledText>{moment(createdDate).format('MM/DD/yyyy')}</StyledText>
        );
      },
    },
    {
      name: 'Payment method',
      renderCell(value: IThriveCampaign): ReactElement {
        return (
          <Payment
            status={
              (PAYMENT_STATUS_MAP_STRATEGY as any)[
                getPaymentStatus(value.payment?.paymentStatus)
              ]
            }
            method={
              (PAYMENT_METHOD_MAP_STRATEGY as any)[
                getPaymentMethod(value.payment?.paymentMethod)
              ].label
            }
          />
        );
      },
    },
    {
      name: 'Status',
      renderCell(value: IThriveCampaign): ReactElement {
        return (
          <Status
            status={
              value.status ? value.status : CampaignStatus[CampaignStatus.NEW]
            }
          />
        );
      },
    },
    {
      name: 'Lead Goal',
      renderCell(value: IThriveCampaign): ReactElement {
        return <PlxText>{value.budget.leadsPerProgram}</PlxText>;
      },
    },
    {
      name: 'Lead Count',
      propKey: 'totalNumberOfLeads',
    },
    {
      name: '',
      renderCell(values: IThriveCampaign): ReactElement {
        return (
          <SeeCampaign path={`/home/admin/thrive-campaign/${values.id}`} />
        );
      },
    },
  ];
};
