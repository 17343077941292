import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';

const EmptyDataPlaceHolderBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #abb1b6;
`;

interface IProps extends ICommonProps {
  massage: string;
}

export const EmptyDataPlaceHolder: FC<IProps> = ({ massage, className }) => {
  return (
    <EmptyDataPlaceHolderBody className={className}>
      {massage}
    </EmptyDataPlaceHolderBody>
  );
};
