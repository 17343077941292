import React, { FC, useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ReactQuill from 'react-quill';
import _ from 'lodash';

interface IProps {
  notesValue: string;
  onChange: (is: number, infoDTO: any) => void;
  adminNotes?: boolean;
  updateInstructionValue?: (value: string) => void;
}

const NotesWrapper = styled.div`
  height: 320px;
  overflow: auto;
  margin-top: 4rem;
  border: 1px solid rgba(35, 31, 32, 0.16);
  border-radius: 6px;
  background: #ffffff;
`;

const NotesTitle = styled.h1`
  margin: 1rem;
  font-weight: 700;
`;

const useStyles = makeStyles({
  textField: {
    '& .ql-container.ql-snow': {
      minHeight: 150,
      border: 'none',
    },
    '& .ql-editor': {
      minHeight: 150,
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: '1px solid rgba(35, 31, 32, 0.16)',
    },
    '& .MuiInputBase-inputMultiline': {
      border: '1px solid rgba(35, 31, 32, 0.16)',
      borderRadius: 6,
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
  },
});

export const AdminNotes: FC<IProps> = ({
  notesValue,
  onChange,
  adminNotes,
  updateInstructionValue,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [richText, setRichText] = useState<string>('');
  const reactQuillRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    setRichText(notesValue);
  }, [notesValue]);

  const handleQuillChange = useRef(
    _.debounce((value: string) => {
      if (value !== notesValue) {
        setRichText(value);
        updateInstructionValue && updateInstructionValue(value);
        const dto: any = adminNotes
          ? { adminNotes: value }
          : { furtherInstructions: value };
        id && onChange(+id, dto);
      }
    }, 500)
  ).current;

  const checkCharacterCount = (event: KeyboardEvent) => {
    if (reactQuillRef.current && reactQuillRef.current.unprivilegedEditor) {
      const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
      const text = unprivilegedEditor.getText();

      if (
        text.length >= 501 &&
        event.key !== 'Backspace' &&
        event.key !== 'Enter'
      ) {
        event.preventDefault();
      }
    }
  };

  return (
    <>
      <NotesWrapper>
        <NotesTitle style={{ display: adminNotes ? undefined : 'none' }}>
          {t('channels.reviewAnalytics.notesTitle')}
        </NotesTitle>
        <ReactQuill
          style={{
            borderTop: adminNotes
              ? '1px solid rgba(35, 31, 32, 0.16)'
              : undefined,
            minWidth: !adminNotes ? '600px' : undefined,
          }}
          value={richText}
          ref={reactQuillRef}
          onChange={handleQuillChange}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          placeholder={t('campaignInformation.note')}
          theme="snow"
          onKeyDown={checkCharacterCount}
          className={classes.textField}
        />
      </NotesWrapper>
    </>
  );
};
