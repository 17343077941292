/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import styled from '@emotion/styled';

import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { CampaignStatus, ITag } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';
import {
  IThriveCampaign,
  IThriveCampaignsQueryParams,
} from '_State/Thrive/types';
import {
  createThriveTag,
  deleteThriveTag,
  getThriveCampaigns,
  getThriveTags,
  updateThriveCampaignTag,
  updateThriveTags,
} from '_State/Thrive/Api/thrive.api';
import { ThriveTableContext } from './thriveTableContext';
import { TagsMangerDialog } from '../../../../../../_Components/TagManager/_Compontents/TagsMangerDialog';
import { ITableContext } from '_Types/tableContext';
import { createThriveCampaignsTableConfig } from './utils';

const INITIAL_PARAMS: IThriveCampaignsQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  tagIdList: [],
  campaignStatusList: ['COMPLETED', 'LAUNCHED', 'PAUSED'],
};

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 15rem;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

const StyledTable = styled(PlxCustomTable)`
  && {
    width: 100%;
  }
`;

const StyledTextButton = styled(PlxLinkTextButton)`
  && {
    margin-left: 3rem;
    font-weight: 500;
  }
`;

export const ThriveCampaignsTable: FC = () => {
  const [tags, setTags] = useState<ITag[]>([]);
  const [campaigns, setCampaigns] = useState<IThriveCampaign[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [sorting, setSorting] = useState<string>('DESC');
  const [params, setParams] = useState<IThriveCampaignsQueryParams>(
    INITIAL_PARAMS
  );

  const [dialogCampaignId, setDialogCampaignId] = useState<any>(null);
  const [dialogMode, setDialogMode] = useState<'add' | 'manage' | null>(null);

  const { t } = useTranslation();

  const statuses = [
    {
      id: CampaignStatus[CampaignStatus.COMPLETED],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.COMPLETED]}`),
    },
    {
      id: CampaignStatus[CampaignStatus.LAUNCHED],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.LAUNCHED]}`),
    },
    {
      id: CampaignStatus[CampaignStatus.NEW],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.NEW]}`),
    },
    {
      id: CampaignStatus[CampaignStatus.PAID],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.PAID]}`),
    },
    {
      id: CampaignStatus[CampaignStatus.REVIEW_NEEDED],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.REVIEW_NEEDED]}`),
    },
    {
      id: CampaignStatus[CampaignStatus.PAUSED],
      name: t(`campaignStatus.${CampaignStatus[CampaignStatus.PAUSED]}`),
    },
  ];

  const openAdd = (id: number) => {
    setDialogCampaignId(id);
    setDialogMode('add');
  };
  const openManage = () => setDialogMode('manage');
  const close = () => {
    setDialogMode(null);
    setDialogCampaignId(null);
  };

  const fetchCampaigns = () =>
    getThriveCampaigns(params).then(({ content }) => {
      if (content) {
        setCampaigns(content);
      }

      return content;
    });

  const fetchTags = () =>
    getThriveTags().then((tags) => {
      setTags(tags);
      return tags;
    });

  const sortingOptions = [
    {
      id: 'ASC',
      name: t('campaignsTable.sort.asc'),
    },
    {
      id: 'DESC',
      name: t('campaignsTable.sort.desc'),
    },
  ];

  const updateTableData = () => {
    fetchCampaigns().then(fetchTags);
  };

  const config = useMemo(() => {
    return createThriveCampaignsTableConfig(
      t,
      tags,
      openAdd
    ) as CellConfig<{}>[];
  }, [tags]);

  useEffect(() => {
    updateTableData();
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, [params]);

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedTags = event.target.value as string[];
    setSelectedTags(selectedTags);
    setParams({ ...params, tagIdList: selectedTags });
  };

  const onStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedStatus = event.target.value as string[];
    setSelectedStatus(selectedStatus);
    setParams({ ...params, campaignStatusList: selectedStatus });
  };

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const context: ITableContext<IThriveCampaign> = {
    tableConfig: config,
    campaigns,
    tags,
    updateTag: (newTag) => {
      return updateThriveTags(newTag).then(() => {
        updateTableData();
      });
    },
    updateCampaignTag: (campaignId, newTagId) => {
      return updateThriveCampaignTag(campaignId, newTagId).then(() => {
        updateTableData();
      });
    },
    removeTag: (tagId) => {
      return deleteThriveTag(tagId).then(() => {
        updateTableData();
      });
    },
    addTag: (newTagName: string) => {
      return createThriveTag(newTagName).finally(() => {
        updateTableData();
      });
    },
  };

  return (
    <>
      <ThriveTableContext.Provider value={context}>
        {config && (
          <StyledTable config={config} data={campaigns}>
            <PlxTableToolbar label={t('campaignsTable.allCampaigns')}>
              <StyledSelect
                options={sortingOptions}
                label={t('campaignsTable.sort.label')}
                onChange={onSortingChange}
                value={sorting}
              />
              <StyledMultipleSelect
                options={statuses}
                label={t('campaignsTable.status')}
                onChange={onStatusChange}
                value={selectedStatus}
              />
              <StyledMultipleSelect
                options={tags}
                label={t('campaignsTable.tags')}
                onChange={onChange}
                value={selectedTags}
              />
              <StyledTextButton
                label={t('campaignsTable.manageTags')}
                onClick={openManage}
              />
            </PlxTableToolbar>
          </StyledTable>
        )}

        <TagsMangerDialog
          context={ThriveTableContext}
          campaignId={dialogCampaignId}
          isAddOnly={dialogMode === 'add'}
          open={!!dialogMode}
          onClose={close}
        />
      </ThriveTableContext.Provider>
    </>
  );
};
