import React, { FC } from 'react';
import { IDailyData } from './TopHashtagsGraph';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface ICustomizedTooltip {
  getDailyData: (arg: string) => IDailyData | undefined;
  label?: string;
  dataColors: { name: string; color: string }[];
  selectedCampaigns: string[];
}

export const StyledTooltip = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: column;
`;
export const TooltipElement = styled.div`
  padding: 5px;
`;

export const CustomizedTooltip: FC<ICustomizedTooltip> = ({
  label,
  getDailyData,
  dataColors,
  selectedCampaigns,
}) => {
  const { t } = useTranslation();

  if (!label) return null;

  const dailyData = getDailyData(label);

  return (
    <>
      <StyledTooltip>
        {dailyData &&
          Object.keys(dailyData as IDailyData)
            .filter((el) => el !== 'name')
            .map((el, index) => {
              return (
                <TooltipElement key={index}>
                  {
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          marginRight: '5px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <span
                            style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: `${
                                dataColors.find((col) => col.name === el)?.color
                              }`,
                              borderRadius: '2px',
                              display: 'inline-block',
                              marginRight: '3px',
                            }}
                          />
                          {!selectedCampaigns.length
                            ? 'Campaign Average: '
                            : el
                                .replace(
                                  'campaign-',
                                  t('spectrum.overview.Campaign') + ' #'
                                )
                                .slice(0, -1)
                                .concat(
                                  selectedCampaigns.length
                                    ? selectedCampaigns[+el.split('-')[1] - 1]
                                    : ''
                                ) + `(${dailyData.name}): `}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            marginRight: '0',
                            paddingLeft: '4px',
                            display: 'inline-block',
                          }}
                        >
                          {(dailyData as any)[el]}
                          {'%'}
                        </div>
                      </div>
                    </div>
                  }
                </TooltipElement>
              );
            })}
      </StyledTooltip>
    </>
  );
};
