/* eslint-disable react-hooks/exhaustive-deps */
import React, { Context, FC, useContext, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import styled from '@emotion/styled';
import { EditableTag } from '../../_routes/Spectrum/_Routes/SpectrumDashboard/_Component/EditableTag/EditableTag';
import { IThemed } from '_Styles/types';
import { themeGetters } from '_Styles/themeHelpers';
import { AddNewTagButton } from './_Compontents/AddNewTagButton';
import { ITableContext } from '_Types/tableContext';

const AddTagBody = styled.div<IThemed>`
  overflow: auto;
  max-height: ${themeGetters.spacing(47)};
  padding: ${themeGetters.spacing(2)};
  width: 100%;
`;

const StyledAddNewTagButton = styled(AddNewTagButton)<IThemed>`
  margin-bottom: ${themeGetters.spacing(2)};
`;

interface IProps {
  isAddOnly: boolean;
  campaignId: number | undefined;
  context: Context<ITableContext<any>>;
}

export const TagManager: FC<IProps> = ({ isAddOnly, campaignId, context }) => {
  const { tags, updateTag, updateCampaignTag, removeTag, addTag } = useContext(
    context
  );
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitialized && campaignId) {
      if (tags.length > 0) {
        const tag = tags?.find(({ campaigns }) => {
          campaigns?.find(({ id }) => campaignId === id);
        });
        if (tag) {
          setSelectedTag(tag.id);
          setIsInitialized(true);
        }
      }
    }
  }, [tags]);

  const selectTag = (id: number | undefined) => {
    const newSelectedTag = id === selectedTag || id === undefined ? null : id;
    updateCampaignTag(campaignId!, newSelectedTag);
    setSelectedTag(newSelectedTag);
  };

  const onAddTag = async (tagName: string) => {
    const tag = await addTag(tagName);
    if (campaignId) {
      selectTag(tag.id);
    }
  };

  const Tags = sortBy(tags, 'id').map((tag) => {
    const { id } = tag;
    const isSelected = selectedTag === id;

    const onTagUpdate = (newName: string) => updateTag({ id, newName });

    const onClick = (id: number | undefined) => {
      if (campaignId) {
        selectTag(id);
      }
    };

    const onRemoveTag = (tagId: number | undefined) => {
      if (tagId) {
        removeTag(tagId);
      }
      if (tagId === selectedTag) {
        setSelectedTag(null);
      }
    };

    return (
      <EditableTag
        key={id}
        enableDeleting={!isAddOnly}
        tag={tag}
        showCampaigns={!isAddOnly}
        onClick={onClick}
        onRemove={onRemoveTag}
        onTagUpdate={onTagUpdate}
        isChosen={isSelected}
      />
    );
  });

  return (
    <AddTagBody>
      {Tags}
      <StyledAddNewTagButton onAddNewTag={onAddTag} />
    </AddTagBody>
  );
};
