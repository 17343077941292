import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { SpectrumCampaign } from '_Routes/Home/_routes/Spectrum/_Routes/SpectrumCampaign/SpectrumCampaign';
interface IProps {
  id: string;
}
export const ClientAnalyticsCampaign: FC = () => {
  const { id } = useParams<IProps>();
  return <SpectrumCampaign userId={Number(id)} />;
};
