import React, { FC, useEffect, useState } from 'react';
import { IUserDTO, IEditClientForm } from '_State/User/user.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { Button } from '@material-ui/core';
import { FormMassage } from '_Components/Forms/FormMassage';
import { SelectSection } from '_Routes/Home/_Components/Sections/SelectSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 2rem auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5.5rem;
  margin-bottom: 4rem;
  margin-top: auto;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

const RedButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;

const FormWrapper = styled.div``;

interface IProps {
  onSubmit: (form: IEditClientForm) => Promise<any>;
  onClose: () => void;
  onDelete: (id?: number) => void;
  data?: IUserDTO;
  error?: string;
}

const INITIAL_VALUES: any = {
  privilege: '',
};

const REQUIRED_FIELDS = ['privilege'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const EditAdminOrEditorForm: FC<IProps> = ({
  data,
  onSubmit,
  onClose,
  onDelete,
  error,
}) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        onSubmit(form).then(() => setIsProcessing(false));
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (data?: IUserDTO) => {
    formik.setValues({
      privilege: data?.privilege,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleDelete = () => {
    onDelete(data?.id);
  };

  useEffect(() => {
    fillForm(data);
    // eslint-disable-next-line
  }, [data]);

  const handleSelectChange = (fieldName: string, value: string) => {
    formik.setFieldValue(fieldName, value);
  };

  const selectConfig = [
    {
      id: 'ADMIN',
      name: 'Admin',
    },
    {
      id: 'EDITOR',
      name: 'Editor',
    },
  ];

  return (
    <Wrapper>
      <FormWrapper>
        <SelectSection
          required
          selectConfig={selectConfig}
          fieldName={'privilege'}
          title={t('admin.users.clientEdit.privilege')}
          placeholder={t('admin.users.clientEdit.privilegePlaceholder')}
          setValue={handleSelectChange}
          value={data?.privilege}
        />
      </FormWrapper>
      <FormMassage message={error ? error : ''} />
      <ButtonsWrapper>
        <RedButton
          color={'secondary'}
          variant={'outlined'}
          disabled={isProcessing}
          onClick={handleDelete}
        >
          Delete
        </RedButton>
        <PlxButton
          label={t('admin.users.clientEdit.close')}
          onClick={handleClose}
          variant={ButtonVariant.Outlined}
        />
        <PlxButton
          label={t('admin.users.clientEdit.save')}
          onClick={handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
