import styled from '@emotion/styled';
import { StyleSheet } from '@react-pdf/renderer';
import Popup from 'reactjs-popup';

export const getCurrentDate = (separator = '/') => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
};

export const styles = StyleSheet.create({
  bodyPdf: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 8,
    fontWeight: 100,
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 22,
  },
  textForwarded: {
    marginLeft: 50,
    textAlign: 'justify',
    lineHeight: 1,
    marginBottom: 10,
  },
  bodyWeb: {
    padding: 35,
    fontSize: 16,
    fontFamily: 'sans-serif',
  },
  bold: {
    fontWeight: 600,
  },
  re: {
    marginLeft: 50,
    maxWidth: '90%',
  },
  title: {
    fontSize: 16,
    marginBottom: 14,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  addressStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  text: {
    textAlign: 'justify',
    lineHeight: 1.6,
    marginBottom: 8,
  },
  text2: {
    textAlign: 'justify',
    lineHeight: 1.6,
  },
  text3: {
    textAlign: 'justify',
    lineHeight: 0.5,
  },
  form: {
    display: 'flex',
  },
  formSpan: {
    minWidth: 120,
  },
  formSpanBold: { minWidth: 120, fontWeight: 600 },
  signBtn: {
    height: '100px',
    width: '250px',
    backgroundColor: '#fcf0ff',
    margin: '1rem',
    textAlign: 'justify',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    cursor: 'pointer',
  },
  image: {
    width: 60,
    height: 60,
  },
});

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
  }
  // use your custom style for ".popup-content"
  &-content.popup-content {
    background: #e3e3e3;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    padding: 1rem;
    canvas {
      background: #fff;
      width: 100%;
      max-width: 400px;
      height: 300px;
    }
  }
`;
export const SignatureWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  line-height: 2rem;
  margin: 0 3rem 3rem;
  && .MuiInputBase-input {
    padding: 5px 0;
    font-size: 1.2rem;
  }
  && div.MuiInputBase-root.MuiInput-root.MuiInput-underline {
    background: #fcf0ff;
    padding-left: 10px;
  }
  && .MuiInput-underline:after {
    border: none;
    border-radius: 8px;
    /* border-color: #4f007d; */
  }
  && .MuiInput-underline:before {
    border: none;
  }
  && .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
`;
export const ParagraphWrap = styled.div`
  p {
    display: inline;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
export const UL = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
export const OL = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @media (max-width: 992px) {
    padding-left: 0;
  }
`;

export const IdentifierWrap = styled.div`
  display: flex;
  align-items: baseline;
  span {
    padding: 0 0.5rem;
  }
`;

export const ErrorWrap = styled.div`
  color: red;
  font-size: 1rem;
  margin-left: 3rem;
`;
