import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import React from 'react';
import { capitalize, IVisibility } from './EngagementGraph';
import { StyledCheckbox } from './EngagementGraph.styles';

export interface IDataTypeCheckbox {
  name: string;
  color: string;
}
interface ICheckboxFooter {
  dataTypesCheckbox: IDataTypeCheckbox[];
  visibility: IVisibility;
  handleChange: (arg: string) => void;
}

export const CheckboxFooter = ({
  dataTypesCheckbox,
  visibility,
  handleChange,
}: ICheckboxFooter) => {
  return (
    <FormControl
      component="fieldset"
      style={{ width: '100%', marginBottom: '50px' }}
    >
      <FormGroup style={{ flexDirection: 'row', width: '100%' }}>
        {dataTypesCheckbox.map((el: IDataTypeCheckbox, index: number) => (
          <FormControlLabel
            key={el.name + index}
            control={
              <StyledCheckbox
                checked={visibility[el.name]}
                onChange={() => handleChange(el.name)}
                checkboxColor={el.color}
              />
            }
            label={capitalize(el.name.replace('_', ' '))}
          />
        ))}
        {/* <div style={{ marginLeft: 'auto', marginRight: '30px' }}>
          <SpectrumButton text={t('spectrum.overview.seeDetails')} />
        </div> */}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxFooter;
