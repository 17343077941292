import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 14px 8px 10px;
  border-radius: 24px;
  display: flex;
`;
export const StyledItems = styled.div`
  width: 100%;
  padding: 10px 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #231f20;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Quicksand', 'sans-serif';
`;
