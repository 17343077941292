import { createContext } from 'react';

export interface INavigationContext {
  navBarExpanded: boolean;
  onExpandClick: () => void;
  clientSelected: string | null;
  clients: any;
  updateSelectedClient: (value: string | null) => void;
  isAgencyType: boolean;
}

export const defaultContext: INavigationContext = {
  navBarExpanded: true,
  onExpandClick: () => {},
  clientSelected: '',
  clients: undefined,
  updateSelectedClient: () => {},
  isAgencyType: false,
};

export const NavigationContext = createContext<INavigationContext>(
  defaultContext
);
