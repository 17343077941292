import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { IDistributionPartnerCompleteProfileForm } from '_State/DistributionPartner/distributionPartner.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useFormik } from 'formik';
import {
  completeProfile,
  getDistributionPartnerProfileInfo,
  updateProfile,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { InputSectionXPhone } from '_Routes/Home/_Components/Sections/InputSectionXPhone';

const INITIAL_VALUES: IDistributionPartnerCompleteProfileForm = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  businessName: '',
};

const REQUIRED_FIELDS = ['firstName', 'lastName', 'phoneNumber'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

const StyledButton = styled(PlxButtonX)`
  width: 14rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 4rem;
`;

interface IProps {
  onSave: () => void;
  confirmationLabel: string;
}

export const DistributionPartnerAccountInformation: FC<IProps> = ({
  onSave,
  confirmationLabel,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [profileInfo, setProfileInfo] = useState<
    IDistributionPartnerCompleteProfileForm
  >();
  const [processing, setProcessing] = useState(false);

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      setProcessing(true);
      if (requiredFormValidation(form)) {
        if (profileInfo) {
          updateProfile(form).then(() => {
            onSave();
            setProcessing(false);
            window.location.reload();
          });
        } else {
          completeProfile(form).then(() => {
            onSave();
            setProcessing(false);
          });
        }
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (form: IDistributionPartnerCompleteProfileForm) => {
    formik.setValues({
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: form.phoneNumber,
      businessName: form.businessName,
    });
  };

  useEffect(() => {
    getDistributionPartnerProfileInfo().then(
      (v) => {
        setProfileInfo(v);
        fillForm(v);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <StyledCircularProgress color="secondary" />
      ) : (
        <>
          <InputSectionX
            title={t('finfluencer.completeProfile.firstName')}
            placeholder={t('finfluencer.completeProfile.firstName')}
            required
            fieldName={'firstName'}
            value={profileInfo?.firstName}
            handleChange={formik.handleChange}
            testId="name-field"
          />
          <FieldErrorMessage
            message={formik.errors.firstName}
            testId="name-error"
          />
          <InputSectionX
            title={t('finfluencer.completeProfile.lastName')}
            placeholder={t('finfluencer.completeProfile.lastName')}
            required
            fieldName={'lastName'}
            value={profileInfo?.lastName}
            handleChange={formik.handleChange}
            testId="name-field"
          />
          <FieldErrorMessage
            message={formik.errors.lastName}
            testId="name-error"
          />
          <InputSectionXPhone
            title={t('finfluencer.completeProfile.phoneNumber')}
            placeholder={t(
              'finfluencer.completeProfile.phoneNumberPlaceholder'
            )}
            required
            value={profileInfo?.phoneNumber}
            fieldName={'phoneNumber'}
            handleChange={formik.handleChange}
            notVerified={profileInfo?.mobileVerified}
            edited={
              profileInfo?.phoneNumber === formik.values.phoneNumber
                ? false
                : true
            }
            testId="phone-field"
          />
          <FieldErrorMessage
            message={formik.errors.phoneNumber}
            testId="phone-error"
          />
          <InputSectionX
            title={t('finfluencer.completeProfile.email')}
            placeholder={t('finfluencer.completeProfile.emailPlaceholder')}
            fieldName={'email'}
            required
            handleChange={formik.handleChange}
            value={profileInfo?.email}
            disabled
            testId="mail-field"
          />
          <FieldErrorMessage
            message={formik.errors.email}
            testId="mail-error"
          />

          <InputSectionX
            title={t('finfluencer.completeProfile.businessName')}
            placeholder={t(
              'finfluencer.completeProfile.businessNamePlaceholder'
            )}
            fieldName={'businessName'}
            value={profileInfo?.businessName}
            handleChange={formik.handleChange}
            tooltip={t('finfluencer.completeProfile.businessNameTooltip')}
            testId="business-name-field"
          />
          <FieldErrorMessage
            message={formik.errors.businessName}
            testId="business-name-error"
          />

          <StyledButton
            isProcessing={processing}
            onClick={formik.handleSubmit}
            label={confirmationLabel}
            isDisabled={
              profileInfo?.firstName === formik.values.firstName &&
              profileInfo?.lastName === formik.values.lastName &&
              profileInfo?.phoneNumber === formik.values.phoneNumber &&
              profileInfo?.businessName === formik.values.businessName
            }
            testId="update-button"
          />
        </>
      )}
    </>
  );
};
