import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { IThemed } from '_Styles/types';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 43rem;
`;

const Title = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 3.2rem;
  text-align: center;
  margin: 1rem 2rem;
`;

const Buttons = styled.div`
  margin: 5rem auto 3rem auto;
`;

const StyledButton = styled(PlxButton)`
  && {
    width: 12rem;
  }
`;

const Alert = styled.div`
  color: #bd0000;
  font-size: 1.6rem;
  margin: 2rem;
  text-align: center;
  line-height: 3.3rem;
`;

interface IProps {
  onSubmit: () => void;
  onClose?: () => void;
  title: string;
  alert: string;
}
export const DeleteConfirmation: FC<IProps> = ({
  onSubmit,
  onClose,
  title,
  alert,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoWrapper>
        <Title>{title}</Title>
        <Alert>{alert}</Alert>
        <Buttons>
          <StyledButton
            onClick={onClose}
            label={t('admin.users.deleteConfirmationDialog.cancel')}
          />
          <StyledButton
            onClick={onSubmit}
            variant={ButtonVariant.Outlined}
            label={t('admin.users.deleteConfirmationDialog.delete')}
          />
        </Buttons>
      </InfoWrapper>
    </>
  );
};
