import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import React, { ReactElement } from 'react';
import { PlxText } from '_Components/Text/PlxText';
import { PlxLink } from '_Components/PlxLink/PlxLink';
import { TagTableCell } from '_Routes/Home/_Components/CampaignsTableCells/TagTableCell';
import styled from '@emotion/styled';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { ITag } from '_Types/campaigns';
import { ISelectableTagConfig } from '_Types/selectableTagConfig';
import moment from 'moment';
import { CAMPAIGN_STATUS_MAP_STRATEGY } from '_Routes/Home/_routes/Admin/types';
import { getCampaignStatus } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/utils';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

const StyledLinkButton = styled(PlxLink)`
  margin: unset;
`;

const StyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const createConfig = (
  tags: ITag[],
  openDialog: (campaignId: number) => void,
  t: any,
  isAdmin?: number,
  isAgency?: boolean
): CellConfig<ISpectrumCampaign>[] => {
  return [
    {
      name: 'Campaign ID',
      propKey: 'id',
    },
    {
      name: 'Tag',
      renderCell(value: ISpectrumCampaign): ReactElement {
        return (
          <TagTableCell
            onDialogOpenDialog={openDialog}
            tagName={value.tag?.name}
            campaignId={value.id}
            tag={tags.find(({ id }) => id === value.tag?.id)}
          />
        );
      },
    },

    {
      name: 'Launch Date',
      renderCell({ startDate }: ISpectrumCampaign): ReactElement {
        return (
          <StyledText>{moment(startDate).format('MMM DD, YYYY')}</StyledText>
        );
      },
    },
    {
      name: 'Campaign Url',
      renderCell(value: ISpectrumCampaign): ReactElement {
        return (
          <StyledLinkButton
            label={value.url}
            pathName={value.url}
            target={'_blank'}
            marginLeft="none"
          />
        );
      },
    },
    {
      name: 'Content Categories',
      renderCell(value: ISpectrumCampaign): ReactElement {
        return (
          <StyledText>
            {value.contentCategories.map((category: string, index: number) =>
              index < value.contentCategories.length - 1
                ? `${category},`
                : `${category}`
            )}
          </StyledText>
        );
      },
    },
    {
      name: 'Content type',
      renderCell({ contentType }: ISpectrumCampaign): ReactElement {
        return <StyledText>{contentType}</StyledText>;
      },
    },
    {
      name: 'Status',
      renderCell({ status }: ISpectrumCampaign): ReactElement {
        return (
          <StyledText>
            {t(
              (CAMPAIGN_STATUS_MAP_STRATEGY as any)[
                getCampaignStatus(status as string)
              ].label
            )}
          </StyledText>
        );
      },
    },

    {
      name: 'Actions',
      renderCell(values: ISpectrumCampaign): ReactElement {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link
              to={
                !!isAdmin
                  ? `/home/admin/users/analyt/campaign/${isAdmin}/`
                  : isAgency
                  ? `/home/agency/spectrum-dashboard/campaign/${values.id}`
                  : `/home/client/spectrum-dashboard/campaign/${values.id}`
              }
            >
              <VisibilityIcon style={{ fill: '#000' }} />
            </Link>
          </div>
        );
      },
    },
  ];
};

export const createManageTagsConfig = (
  tags: ITag[]
): ISelectableTagConfig[] => {
  return tags.map((tag: ITag) => {
    return {
      tag: {
        id: tag.id,
        name: tag.name,
        campaigns: tag.campaigns,
      },
      isChosen: false,
    };
  });
};

export const generateOptions = (
  tags: ITag[],
  campaignId: number
): ISelectableTagConfig[] => {
  return tags.map((tag: ITag) => {
    return {
      tag: {
        id: tag.id,
        name: tag.name,
        campaigns: tag.campaigns,
      },
      isChosen:
        tag.campaigns.filter((campaign) => campaign.id === campaignId).length >
        0,
    };
  });
};
