import React, { FC } from 'react';
import {
  // getOpportunities,
  getUserChannels,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import styled from '@emotion/styled';
// import { StringDecoder } from 'string_decoder';

// exporting Empty container when no data in the box or under the header
export const EmptyContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 1.7rem 0 1.7rem 2rem;
`;

const HeaderContainer = styled.h1`
  font-size: 2.2rem;
  padding: 2.5rem 0 2.5rem 2rem;
  font-weight: 800;
`;
const TableHeaderContainer = styled.h2<{ center?: boolean }>`
  font-size: 2rem;
  font-weight: 500;
  padding: 3rem 2rem;
  width: 100%;
  color: #545454;
  text-align: ${(props) => (props.center ? 'center' : '')};
`;

export const getDistributionPartnerLink = async () => {
  try {
    return await getUserChannels().then(
      (v) => {
        if (v.length > 0) {
          return '/home/finfluencer/dashboard';
        } else {
          return '/home/finfluencer/channel/add-channel';
        }
      },
      () => {
        return '/home/finfluencer/channel/add-channel';
      }
    );
  } catch (httpError) {
    console.log(httpError);
    return '/home/finfluencer/channel/add-channel';
  }
};

export const TabPageHeader: FC<{
  name: string;
}> = ({ name }) => {
  return <HeaderContainer>{name.toUpperCase()}</HeaderContainer>;
};
export const SectionHeader: FC<{ center?: boolean }> = ({
  children,
  center,
}) => {
  return (
    <TableHeaderContainer center={center}>{children}</TableHeaderContainer>
  );
};

export const TrimString: FC<{
  paragraph?: string;
  maxLetters: number;
}> = ({ paragraph, maxLetters }) => {
  return (
    <>
      {paragraph?.slice(0, maxLetters) +
        (paragraph && paragraph.length > maxLetters ? '...' : '')}
    </>
  );
};
