import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { themeGetters } from '_Styles/themeHelpers';
import { AccountInformation } from '_Routes/Home/_routes/Profile/AccountInformation/AccountInformation';
import { PasswordChange } from '_Routes/Home/_Components/PasswordChange';
import { ProgramHistory } from '_Routes/Home/_routes/Profile/ProgramHistory/ProgramHistory';
import { FormMassage } from '_Components/Forms/FormMassage';
import { useTranslation } from 'react-i18next';

const { color } = themeGetters;

const ProfileBody = styled.div`
  width: 100%;
`;

const FormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fc;
  padding: 4rem 20rem;
`;

const Title = styled.div<IThemed>`
  font-size: 1.8rem;
  margin: auto 0 auto 4rem;
  color: ${color('primary', 'contrastText')};
`;

const HeaderWrapper = styled.div<IThemed>`
  display: flex;
  height: 7.5rem;
  background-color: ${color('primary', 'light')};
  border-bottom: 1px solid #eaeef1;
`;

export const Profile: FC = () => {
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const handlePasswordChange = (error?: boolean) => {
    if (error) {
      setError(true);
    } else {
      setPasswordChanged(true);
    }
  };
  return (
    <ProfileBody>
      <HeaderWrapper>
        <Title>Profile</Title>
      </HeaderWrapper>
      <FormsWrapper>
        {passwordChanged && (
          <FormMassage error={false} message={t('profile.passwordChanged')} />
        )}
        {error && <FormMassage message={t('errors.password')} />}
        <AccountInformation />
        <PasswordChange onPasswordChange={handlePasswordChange} />
        <ProgramHistory />
      </FormsWrapper>
    </ProfileBody>
  );
};
