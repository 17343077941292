import React, { FC } from 'react';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { ITile } from '_Types/ITile';
import { IThemed } from '_Styles/types';
import { PlxTileX } from '_Components/Panels/PlxTilesPanel/PlxTileX';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 5rem 0;
`;

const Tiles = styled.div<IThemed>`
  // margin-top: ${({ theme }) => theme.spacing(5)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
`;

interface IProps extends ICommonProps {
  tiles: ITile[];
  onTileChange?: (tileName: string, tileValue: any) => void;
  className?: string;
}

export const PlxTilesPanelX: FC<IProps> = ({
  tiles,
  onTileChange,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Tiles>
        {tiles.map((tile, index) => (
          <PlxTileX
            tilesQuantity={tiles.length}
            key={index}
            label={tile.label}
            amount={tile.amount}
            editable={tile.editable}
            formControlName={tile.formControlName}
            onChange={onTileChange}
          />
        ))}
      </Tiles>
    </Wrapper>
  );
};
