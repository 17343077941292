import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Clear, Done } from '@material-ui/icons';
import { IThemed } from '_Styles/types';

const ActionsInputBody = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -30px;
  right: -5px;
`;

const IconWrapper = styled.div<IThemed>`
  border: 1px solid #d5dde3;
  border-radius: 3px;
  color: #a9b3bb;
  margin-right: 5px;
  background: white;
  :hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

interface IProps {
  onConfirm: (value: any) => void;
  onCancel: () => void;
  visible: boolean;
}

export const PlxActionsInput: FC<IProps> = ({
  onCancel,
  onConfirm,
  visible,
}) => {
  return (
    <>
      {visible && (
        <ActionsInputBody>
          <IconWrapper onClick={onConfirm}>
            <Done />
          </IconWrapper>
          <IconWrapper onClick={onCancel}>
            <Clear />
          </IconWrapper>
        </ActionsInputBody>
      )}
    </>
  );
};
