import React, { Fragment } from 'react';
import { DisplayModeEnum } from './AvgEngagementRateAndTrendGraph';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CampaignCircle from '../CampaignCircle/CampaignCircle';

interface IHeadingTable {
  mode: DisplayModeEnum;
  selectedIds?: string[];
}

const useStyles = makeStyles({
  table: {
    fontWeight: 600,
  },
  cell: { fontWeight: 600, padding: '12px 0px' },
  headRow: { backgroundColor: '#F6F6F6' },
});

export const HeadingTable = ({ mode, selectedIds = [] }: IHeadingTable) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <>
      {mode > DisplayModeEnum.single && (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.cell}
                style={{ color: 'transparent' }}
              >
                {t('spectrum.overview.Channel type')}
              </TableCell>

              {selectedIds.map((id, index) => (
                <Fragment key={index.toString()}>
                  <TableCell className={classes.cell} align="right"></TableCell>
                  <TableCell className={classes.cell} align="left">
                    <CampaignCircle
                      color={['#9D05B0', '#008B9C', '#E0B028'][index]}
                    />
                    <span style={{ marginLeft: '6px', marginRight: '6px' }}>
                      {t('spectrum.overview.Campaign')} #{id}
                    </span>
                  </TableCell>
                </Fragment>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      )}
    </>
  );
};

export default HeadingTable;
