import React, { FC, MouseEvent } from 'react';
import { ICommonProps } from '_Types/props';
import {
  withStyles,
  MenuProps,
  Menu,
  createStyles,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IFilterItem } from './utils';
import { useTranslation } from 'react-i18next';
import {
  StyledBackground,
  StyledButton,
  StyledToggleIcon,
} from './styles/FilterButton.styles';

interface IProps extends ICommonProps {
  items: IFilterItem[];
  resetFilters: () => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: (arg: null | HTMLElement) => void;
  text: string;
}
const StyledMenu = withStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: '0.5rem',
      border: '1px solid #D4D4D4',
      borderRadius: '2rem',
      padding: '0.5rem',
      backgroundColor: 'white',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        width: '100%',
        height: 'fit-content',
        top: 'auto !important',
        bottom: 16,
      },
    },
  })
)((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

export const FilterButton: FC<IProps> = ({
  children,
  items,
  resetFilters,
  anchorEl,
  setAnchorEl,
  text,
}) => {
  const { t, i18n } = useTranslation();

  const checkedItems = items.filter((obj: IFilterItem) => obj.isChecked);

  const onReset = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    resetFilters();
  };

  const handleClick = (event: MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  return (
    <div>
      {!!anchorEl && <StyledBackground />}
      <StyledButton isOpen={!!anchorEl} onClick={handleClick}>
        {i18n.exists(`spectrum.filterLabels.${text}`)
          ? t(`spectrum.filterLabels.${text}`)
          : text}
        <StyledToggleIcon onClick={onReset}>
          {checkedItems.length ? (
            <CloseIcon style={{ color: '#5f5f61' }} />
          ) : (
            <KeyboardArrowDownIcon style={{ color: '#5f5f61' }} />
          )}
        </StyledToggleIcon>
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </div>
  );
};
