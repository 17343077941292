import React, { Context, FC } from 'react';

import { useTranslation } from 'react-i18next';
import { TagManager } from '../TagManager';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';

interface IProps {
  open: boolean;
  onClose?: () => void;
  campaignId: number | undefined;
  isAddOnly?: boolean;
  context: Context<any>;
}

export const TagsMangerDialog: FC<IProps> = ({
  open,
  onClose,
  campaignId,
  isAddOnly = false,
  context,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <PlxDialog
      title={t('campaignsTable.addToTag')}
      onClose={handleClose}
      open={open}
    >
      <TagManager
        isAddOnly={isAddOnly}
        campaignId={campaignId}
        context={context}
      />
    </PlxDialog>
  );
};
