import React, { useEffect, useState } from 'react';
import { createFinfluencerReferralsTableConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/utils';
import { useTranslation } from 'react-i18next';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { Paper } from '@material-ui/core';
import { XemTable } from '_Components/XemTable/XemTable';
import { GridColumns, GridPageChangeParams } from '@material-ui/data-grid';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { getUserInfo } from '_Api/auth';
import { getReferralsData } from '_State/DistributionPartner/Api/distributionPartner.api';
import { InfoSection } from './InfoSection';

export const DistributionPartnerReferralsTable = () => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState<number>(0);
  const [config, setConfig] = useState<GridColumns>([]);
  const [campaigns, setCampaigns] = useState<ISpectrumCampaign[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [params, setParams] = useState<{
    pageSize: number;
    pageNumber: number;
  }>({ pageNumber: 0, pageSize: 20 });

  useEffect(() => {
    setTableLoading(true);
    userId === 0 &&
      getUserInfo().then((data) => {
        data.id && setUserId(data.id);
      });

    userId !== 0 &&
      getReferralsData(userId)
        .then(({ content, totalElements }) => {
          setTotalElements(totalElements);
          setCampaigns(content);
          setConfig(createFinfluencerReferralsTableConfig());
        })
        .finally(() => {
          setTableLoading(false);
        });
  }, [userId]);

  const handlePageChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageNumber: param.page }));
  };

  const handlePageSizeChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageSize: param.pageSize }));
  };

  return (
    <Paper style={{ width: '100%' }}>
      <PlxTableToolbar label={t('navBar.referrals')}></PlxTableToolbar>
      <InfoSection />
      <XemTable
        header={config}
        data={campaigns}
        loading={tableLoading}
        pagination
        page={params.pageNumber}
        paginationMode="server"
        pageSize={params.pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowCount={totalElements}
        onPageChange={handlePageChange}
        filterMode="server"
      />
    </Paper>
  );
};
