import React, { FC } from 'react';
import { PlexusProduct } from '_Types/products';
import { ReactComponent as SpectrumIcon } from '_Routes/Home/_routes/Dashboard/Products/icons/Spectrum.svg';
import { ReactComponent as ThriveIcon } from '_Routes/Home/_routes/Dashboard/Products/icons/Thrive.svg';
import { ReactComponent as SpotlightIcon } from '_Routes/Home/_routes/Dashboard/Products/icons/Spotlight.svg';
import { ReactComponent as EdgeIcon } from '_Routes/Home/_routes/Dashboard/Products/icons/Edge.svg';
import { ReactComponent as ChatterIcon } from '_Routes/Home/_routes/Dashboard/Products/icons/Chatter.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CallMadeOutlined } from '@material-ui/icons';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

interface IProductStyles {
  color: string;
}
const productConfigs = {
  [PlexusProduct.Spectrum]: {
    icon: <SpectrumIcon />,
    color: '#006AFF',
    text: 'productCard.spectrum',
  },
  [PlexusProduct.Thrive]: {
    icon: <ThriveIcon />,
    color: '#f47d48',
    text: 'productCard.thrive',
  },
  [PlexusProduct.Chatter]: {
    icon: <ChatterIcon />,
    color: '#44ba7f',
    text: 'productCard.chatter',
  },
  [PlexusProduct.Spotlight]: {
    icon: <SpotlightIcon />,
    color: '#7F2183',
    text: 'productCard.spotlight',
  },
  [PlexusProduct.Edge]: {
    icon: <EdgeIcon />,
    color: '#CB025D',
    text: 'productCard.edge',
  },
};

const ProductCardBody = styled.div<IThemed & IProductStyles>`
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  height: 18rem;
  width: 33%;
  margin: 1.25rem 1.25rem 1.25rem 0;
  transition: box-shadow 0.3s;
  :hover {
    box-shadow: 0 32px 41px -40px ${({ color }) => color};
  }
`;

const ProductCardTitle = styled.span<IThemed & IProductStyles>`
  color: #231f20;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 0.5rem;
`;

const Number = styled.span<IThemed & IProductStyles>`
  color: ${({ color }) => color};
  display: inline-flex;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  align-items: center;
`;

const StyledCardText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808285;
  margin: 0.7rem 0;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
`;

const BottomSection = styled.div`
  margin: 0;
  padding: 1.25rem 1.5rem;
`;

const ViewAll = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #1b192c;
  margin-right: 1.5rem;
`;

const DividerLine = styled.div`
  border-top: 1px solid #f2f2f2;
`;

// const ChangedValue = styled.div`
//   display: inline-flex;
//   margin: 0.7rem 0.5rem 1rem;
//   padding: 3px 4px;
//   gap: 4px;
//   background: #f6f6f6;
//   border-radius: 24px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 10px;
//   line-height: 12px;
//   color: #231f20;
// `;

interface IProps {
  type: PlexusProduct;
  spectrumCampaigns?: ISpectrumCampaign[];
  linkTo?: string;
}

export const ProductCard: FC<IProps> = ({
  type,
  spectrumCampaigns,
  linkTo,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { text, color } = productConfigs[type];
  return (
    <ProductCardBody
      onClick={() => linkTo && history.push(linkTo)}
      color={color}
    >
      <TopSection>
        <div style={{ display: 'flex' }}>
          <Number color={spectrumCampaigns?.length === 0 ? '#403D3F' : color}>
            {spectrumCampaigns?.length}
          </Number>
          {/* <ChangedValue>0.00%</ChangedValue> */}
        </div>

        <ViewAll>
          View All
          <CallMadeOutlined
            style={{
              fill: '#231F20',
              width: '14px',
              height: '14px',
              marginLeft: '0.5rem',
            }}
          />
        </ViewAll>
      </TopSection>
      <DividerLine />
      <BottomSection>
        <ProductCardTitle color={color}>{type}</ProductCardTitle>
        <StyledCardText>{t(text)}</StyledCardText>
      </BottomSection>
    </ProductCardBody>
  );
};
