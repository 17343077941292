import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { TotalValue, TotalValueLabel, TotalValueSum } from './StylePayment';
import { buttonOptions, campaignPost } from './utils';

interface IProps {
  formik: any;
  discountedValue: number;
}
export const TotalAmount: FC<IProps> = ({ formik, discountedValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <TotalValue>
        <div>
          <TotalValueLabel>{t('payment.total')}</TotalValueLabel>
          <TotalValueSum>
            <span style={{ fontSize: '1.2rem' }}>
              {`(${campaignPost(formik.values.pricingType, buttonOptions)})`}
            </span>
            <span>
              $<StandardizeAmount amount={discountedValue} />
            </span>
          </TotalValueSum>
        </div>
      </TotalValue>
    </>
  );
};
