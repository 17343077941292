import React, { FC, useMemo, useState } from 'react';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { IPostSample, IUrlPostSample } from '_State/Spectrum/types';
import { useTranslation } from 'react-i18next';
import { PostSample } from '_Routes/Home/_Components/PostSamples/PostSample';
import { AddSampleDialog } from '_Routes/Home/_Components/PostSamples/AddSampleDialog';
import {
  addPostSample,
  replacePostSample,
  updatePostSample,
} from '_State/Spectrum/Api/spectrum-admin.api';
import { EmptyDataPlaceHolder } from '_Components/EmptyDataPlaceHolder';
import { PlxHrefButton } from '_Components/Buttons/PlxStepButton/PlxHrefButton';
import { AddSampleArea, SamplesWrapper, UrlsWrapper } from './Styles';
import { EditSampleDialog } from './EditSampleDialog';

interface IProps {
  postSamples: IPostSample[];
  urlSamples?: IUrlPostSample[];
  editable?: boolean;
  onSampleAdd?: () => void;
}

export const PostSamples: FC<IProps> = ({
  postSamples,
  urlSamples = [],
  editable,
  onSampleAdd,
}) => {
  const { t } = useTranslation();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [postId, setPostId] = useState<number>(0);
  const [chosenSample, setChosenSample] = useState<IPostSample>();
  const handleClose = () => {
    setAddDialogOpen(false);
    setEditDialogOpen(false);
    setUpdateDialogOpen(false);
  };
  const handleUpload = (value: number) => {
    setAddDialogOpen(false);
    setEditDialogOpen(false);
    setUpdateDialogOpen(true);
    setPostId(value);
  };
  const handleSave = () => {
    setAddDialogOpen(false);
    onSampleAdd && onSampleAdd();
  };
  const handleImageClick = (id: number) => {
    setChosenSample(postSamples?.filter((sample) => sample.id === id)[0]);
    setEditDialogOpen(true);
  };

  const handleAddSampleAreaClick = () => {
    setChosenSample(undefined);
    setAddDialogOpen(true);
  };

  const arePostSamples = useMemo(() => postSamples.length > 0 || editable, [
    postSamples,
    editable,
  ]);
  return (
    <PlxInfoPanel label={t('spectrum.dashboard.postSamples')}>
      <SamplesWrapper>
        {editable && (
          <AddSampleArea onClick={handleAddSampleAreaClick}>
            Add post sample
          </AddSampleArea>
        )}
        {arePostSamples ? (
          <>
            {postSamples?.map((sample, index) => (
              <PostSample
                onImageClick={handleImageClick}
                editable={editable}
                postSample={sample}
                key={index}
              />
            ))}
          </>
        ) : urlSamples ? (
          <>
            {urlSamples.map((sample, index) => (
              <UrlsWrapper>
                <PlxHrefButton
                  href={sample.postUrl}
                  text={t('spectrum.dashboard.goToThePost')}
                  key={index}
                />
              </UrlsWrapper>
            ))}
          </>
        ) : (
          <EmptyDataPlaceHolder massage={t('noData')} />
        )}
      </SamplesWrapper>
      <AddSampleDialog
        onSubmit={addPostSample}
        dialogTitle={'Add post sample'}
        initialValue={chosenSample}
        open={addDialogOpen}
        onClose={handleClose}
        onSave={handleSave}
      />
      <EditSampleDialog
        onSubmit={replacePostSample}
        dialogTitle={'Edit post sample'}
        initialValue={chosenSample}
        open={editDialogOpen}
        onClose={handleClose}
        onSave={handleSave}
        handleUpload={handleUpload}
      />
      <AddSampleDialog
        onSubmit={updatePostSample}
        dialogTitle={'Update post #' + postId}
        initialValue={chosenSample}
        open={updateDialogOpen}
        onClose={handleClose}
        onSave={handleSave}
      />
    </PlxInfoPanel>
  );
};
