import React, { FC } from 'react';
import styled from '@emotion/styled';
import Collapse from '@material-ui/core/Collapse';
import { IThemed } from '_Styles/types';
import { useTranslation } from 'react-i18next';
import { IExportOptions } from './SpectrumExportButton';
import { SpectrumExportButtonX } from './SpectrumExportButtonX';
import { ICommonProps } from '_Types/props';
import { StyledList } from './SpectrumExportButtonX.styles';
interface IProps extends ICommonProps {
  open: boolean;
  items: IExportOptions[];
  isExtended: boolean;
}

const StyledCollapse = styled(Collapse)<IThemed>`
  width: 100%;
`;
const StyledMenuButton = styled(SpectrumExportButtonX)`
  cursor: pointer;
  font-size: 16px;
`;

export const ExportDropDownMenu: FC<IProps> = ({ items, isExtended, open }) => {
  const { t } = useTranslation();

  return (
    <StyledCollapse in={open} timeout="auto" unmountOnExit>
      {isExtended && (
        <StyledList>
          {items?.map(
            (item: IExportOptions) =>
              !item.disabled &&
              isExtended && (
                <StyledMenuButton
                  key={item.label}
                  isExtended={isExtended}
                  isDropDown
                  label={t(item.label)}
                  onClick={item.action}
                />
              )
          )}
        </StyledList>
      )}
    </StyledCollapse>
  );
};
