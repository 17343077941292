import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  completeDraft,
  getDraft,
  updateSchedulingDraft,
} from '_State/Spectrum/Api/spectrum.api';
import { ISchedulingForm, ISpectrumCampaign } from '_State/Spectrum/types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { Tabs, Typography, Tab, makeStyles } from '@material-ui/core';
import {
  ButtonsSection,
  ButtonsWrapper,
  datesConfig,
  getSpectrumLink,
  StyledForm,
  StyledTitle,
} from '../../utils';
import { PlxDatePicker } from '_Components/PlxDatePicker/PlxDatePicker';
import moment from 'moment';
import { NewTitle } from '_Components/FormSection/FormSection';
import { FinishDialog } from './_Components/FinishDialog/FinishDialog';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { mapTranslatedLabel } from '_App/utils';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import { PlxIconButton } from '_Components/Buttons/PlxIconButton/PlxIconButton';
import {
  NewButtonContainer,
  NewButtonWrapper,
} from '_Components/Buttons/PlxButtonGroup/PlxButtonGroup';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const useStyles = makeStyles({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    position: 'relative',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 600,
    fontsize: '14px',
    lineHeight: '20px',
    color: '#000',
    '&.MuiTab-root': {
      padding: 0,
      marginRight: 24,
      minWidth: 40,
      textTransform: 'none',
    },
  },
  tabActive: {
    position: 'relative',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 600,
    fontsize: '14px',
    lineHeight: '20px',
    color: '#000',
    '&.MuiTab-root': {
      padding: 0,
      marginRight: 24,
      minWidth: 40,
      textTransform: 'none',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '35%',
      width: '30%',
      borderBottom: '2px solid #231F20',
    },
  },
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DateWrapper = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid rgba(35, 31, 32, 0.16);
  border-radius: 6px;
  padding-left: 1rem;
  width: 55%;
  height: 40px;
  margin: 1.5rem 0;
`;

const INITIAL_VALUES: ISchedulingForm = {
  startDate: '',
};

const REQUIRED_FIELDS = ['startDate'];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const SpectrumScheduling: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [apiError, setApiError] = useState('');
  const [postedDate, setPostedDate] = useState<Date | null>(null);
  const [buttons, setButtons] = useState<IIconButtonConfig[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  let dateValue = moment().format('YYYY-MM-DD');

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const handleBack = () => {
    history.push(
      isAgencyType
        ? '/home/agency/spectrum-campaign/campaign'
        : '/home/client/spectrum-campaign/campaign'
    );
  };

  const handleNextStep = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubscriptionConfirmed = () => {
    getSpectrumLink().then(() => {
      history.push(
        isAgencyType
          ? '/home/agency/spectrum/campaigns'
          : '/home/client/spectrum/campaigns'
      );
    });
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values) => {
      if (requiredFormValidation(values)) {
        updateSchedulingDraft(values, clientSelected)
          .then((v) => {
            completeDraft(clientSelected).then((res) => {
              handleNextStep();
            });
          })
          .catch((error) =>
            error.response
              .json()
              .then((error: any) => setApiError(error.message))
          );
      }
    },
    validate: (form) => {
      return requiredFieldsValidation(form);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  const fillForm = (form: ISpectrumCampaign) => {
    console.log(form.startDate);

    formik.setValues({
      startDate: form.startDate,
    });
  };

  useEffect(() => {
    getDraft(clientSelected).then(
      (v) => {
        if (v) {
          fillForm(v);
          // ssetDraft(v);
        }
      },
      () => null
    );
    // eslint-disable-next-line
  }, []);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Typography>{children}</Typography>}
      </div>
    );
  };

  const handlePostedDateChange = (date: Date | null) => {
    const datePosted = moment(date).format('YYYY-MM-DD[T]HH:MM:ss.SSS[Z]');
    buttons.forEach((button) => (button.isChosen = false));
    formik.setFieldValue('startDate', datePosted);
    setPostedDate(date);
  };

  useEffect(() => {
    const data = datesConfig.map((item) =>
      mapTranslatedLabel(item, t(item.label))
    );
    setButtons(data);
  }, [t]);

  const handleClick = (id: string) => {
    const option = buttons.filter((option) => option.id === id)[0];
    option.isChosen = !option.isChosen;
    buttons
      .filter((b) => b.id !== option.id)
      .forEach((o) => (o.isChosen = false));
    setButtons([...buttons]);
  };

  useEffect(() => {
    const chosen = buttons.find((button) => button.isChosen === true);
    formik.setFieldValue('startDate', chosen?.value);
    setPostedDate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons]);

  const isButtonReady = formik.values.startDate?.length > 11;

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <StyledForm>
          <StyledTitle style={{ paddingBottom: 0 }}>
            {t('spectrum.scheduling.schedule')}
          </StyledTitle>
          <div style={{ borderBottom: '1px solid #D9D9D9' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              classes={{
                indicator: classes.tabIndicator,
              }}
            >
              <Tab
                label={
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {t('spectrum.scheduling.available')}
                    <div
                      style={{
                        background: '#000',
                        color: '#fff',
                        width: 19,
                        height: 19,
                        borderRadius: 24,
                        marginLeft: 6,
                      }}
                    >
                      5
                    </div>
                  </div>
                }
                {...tabProps(0)}
                className={tabValue === 0 ? classes.tabActive : classes.tab}
              />
              <Tab
                label="Schedule"
                {...tabProps(1)}
                className={tabValue === 1 ? classes.tabActive : classes.tab}
              />
            </Tabs>
          </div>
          <TabPanel value={tabValue} index={0}>
            <NewTitle style={{ marginTop: '16px' }}>
              {t('spectrum.scheduling.select')}
            </NewTitle>
            <DateWrapper style={{ pointerEvents: 'none' }}>
              <PlxDatePicker
                schedule
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="MMMM, yyyy"
                margin="normal"
                value={dateValue}
                fullwidth={true}
                onChange={handlePostedDateChange}
              />
            </DateWrapper>
            <NewButtonWrapper className={'PlxButtonGroup-container'}>
              {buttons.map((button) => {
                return (
                  !button.disabled && (
                    <NewButtonContainer
                      className={'PlxButtonGroup-button-container'}
                      key={button.id}
                    >
                      <PlxIconButton
                        newDesign={true}
                        id={button.id}
                        isChosen={button.isChosen}
                        icon={button.icon}
                        disabled={button.disabled}
                        label={button.label}
                        onClick={handleClick}
                      />
                    </NewButtonContainer>
                  )
                );
              })}
            </NewButtonWrapper>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <NewTitle style={{ marginTop: '16px' }}>
              {t('spectrum.scheduling.date')}
              <span style={{ color: 'red' }}> *</span>
            </NewTitle>
            <DateWrapper>
              <PlxDatePicker
                schedule
                disablePast
                disableToolbar
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                placeholder={t(
                  'opportunities.submitAnalytics.datePostedPlaceholder'
                )}
                value={postedDate}
                fullwidth={true}
                onChange={handlePostedDateChange}
              />
            </DateWrapper>
          </TabPanel>
        </StyledForm>
        <ButtonsSection>
          <PlxButtonX
            newDesign
            size="small"
            onClick={handleBack}
            label={t('spectrum.footerButtons.back')}
          />
          <ButtonsWrapper>
            <PlxButtonX
              newDesign
              size="small"
              onClick={() => {
                updateSchedulingDraft(formik.values, clientSelected).then(() =>
                  getSpectrumLink().then((link) => {
                    if (link) {
                      history.push(link);
                    }
                  })
                );
              }}
              label={t('spectrum.footerButtons.save')}
            />
            <PlxButtonX
              isDisabled={!isButtonReady}
              newDesign
              size="small"
              inputType={'submit'}
              onClick={formik.handleSubmit}
              label={t('spectrum.footerButtons.onSubmit')}
              variant2
            />
          </ButtonsWrapper>
        </ButtonsSection>
      </form>
      <FieldErrorMessage message={apiError} />
      <FinishDialog
        open={openDialog}
        onClose={handleClose}
        onNext={handleSubscriptionConfirmed}
      />
    </Wrapper>
  );
};
