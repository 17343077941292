import { ReactNode } from 'react';
import styled from '@emotion/styled';

export interface ISelectOption {
  label?: string;
  optionIcon?: (args?: any) => ReactNode;
  value: any;
}

interface ICampaignStatusIconProps {
  color: string;
}
export const CampaignStatusIcon = styled.div<ICampaignStatusIconProps>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin: auto 1rem auto 1rem;
  width: 0.8rem;
  height: 0.8rem;
`;
