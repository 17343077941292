import React, { useEffect, useState } from 'react';
import { FilterButton } from './FilterButton';
import { dailyTags } from './utils';
import { FiltersItems } from './FiltersItem';
import DateCalendar from './DateCalendar';
import ClosingButtons from './ClosingButtons';
import { IFilterItem } from './utils';
import { subWeeks, subMonths, subDays, format } from 'date-fns';
import { StyledItems, Wrapper } from './styles/DateTimeFilter.styles';

interface IDateTimeFilter {
  calendarDate: ICalendarDate;
  setCalendarDate: (arg: ICalendarDate) => void;
  initialCalendarDate: ICalendarDate;
}

export interface ICalendarDate {
  [key: string]: {
    startDate: Date | null;
    endDate: Date | null;
    key: string;
  };
}

export const DateTimeFilter = ({
  calendarDate,
  setCalendarDate,
  initialCalendarDate,
}: IDateTimeFilter) => {
  const [compare, setCompare] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [text, setText] = useState<string>('Date');
  const [filterItems, setFilterItems] = useState<IFilterItem[]>(dailyTags);

  const selectedInterval = filterItems.filter(
    (obj: IFilterItem) => obj.isChecked
  );
  const isPredefinedSelected = selectedInterval.length ? true : false;

  const resetFilters = () => {
    setText('Date');
    setFilterItems(dailyTags);
    setCompare(false);
    setCalendarDate(initialCalendarDate);
  };

  useEffect(() => {
    if (!compare) {
      setCalendarDate({
        ...calendarDate,
        compare: initialCalendarDate.compare,
      });

      if (calendarDate.selection.startDate && !isPredefinedSelected) {
        if (calendarDate.selection.startDate) {
          setText(format(calendarDate.selection.startDate, 'MM/dd/yyyy'));
        } else return;
        if (calendarDate.selection.endDate) {
          setText(
            format(calendarDate.selection.startDate, 'MM/dd/yyyy') + ' + 1'
          );
        } else return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare]);

  useEffect(() => {
    if (selectedInterval.length && selectedInterval[0].subtitle) {
      setText(selectedInterval[0].subtitle);
      setCompare(false);
      switch (selectedInterval[0].subtitle) {
        case 'lastDay':
          setCalendarDate({
            ...calendarDate,
            selection: {
              startDate: new Date(subDays(new Date(), 1)),
              endDate: new Date(),
              key: 'selection',
            },
          });
          break;
        case 'lastWeek':
          setCalendarDate({
            ...calendarDate,
            selection: {
              startDate: new Date(subWeeks(new Date(), 1)),
              endDate: new Date(),
              key: 'selection',
            },
          });
          break;
        case 'last2Weeks':
          setCalendarDate({
            ...calendarDate,
            selection: {
              startDate: new Date(subWeeks(new Date(), 2)),
              endDate: new Date(),
              key: 'selection',
            },
          });
          break;
        case 'lastMonth':
          setCalendarDate({
            ...calendarDate,
            selection: {
              startDate: new Date(subMonths(new Date(), 1)),
              endDate: new Date(),
              key: 'selection',
            },
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  return (
    <Wrapper>
      <FilterButton
        resetFilters={resetFilters}
        items={filterItems}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        text={text}
      >
        <StyledItems>
          <FiltersItems
            filterItems={filterItems}
            setFilterItems={setFilterItems}
          />
        </StyledItems>
        {/* <StyledItems
          style={
            isPredefinedSelected
              ? { pointerEvents: 'none', opacity: '0.4' }
              : {}
          }
        >
          <ToggleSwitch
            title={t('spectrum.filterLabels.compare')}
            toggled={compare}
            onChange={() => setCompare((el) => !el)}
          />
        </StyledItems> */}
        <DateCalendar
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          compare={compare}
          isPredefinedSelected={isPredefinedSelected}
          setText={setText}
        />
        <ClosingButtons setAnchorEl={setAnchorEl} resetFilters={resetFilters} />
      </FilterButton>
    </Wrapper>
  );
};

export default DateTimeFilter;
