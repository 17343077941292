import React, { FC, useState } from 'react';
import { createStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';
import { AdminsAndEditorsTable } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/AdminsAndEditorsTable';
import { ClientsTable } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/ClientsTable';
import { DistributionPartnersTable } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Tables/DistributionPartnersTable';
import { AgenciesTable } from './Tables/AgenciesTable';

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F5333F',
    height: '1px',
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#1B1B1B',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(1),
      borderRadius: '5px 5px 0px 0px',
      border: '1px solid #EAEEF1',
      '&:focus': {
        opacity: 1,
      },
    },
    selected: {
      backgroundColor: '#ffffff',
      borderBottom: 'none',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export const UserManagementTabs: FC = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
      >
        <StyledTab label="Clients" {...a11yProps(0)} />
        <StyledTab label="Editors/Admins" {...a11yProps(1)} />
        <StyledTab label="Finfluencers" {...a11yProps(2)} />
        <StyledTab label="Agencies" {...a11yProps(3)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <ClientsTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminsAndEditorsTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DistributionPartnersTable />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AgenciesTable />
      </TabPanel>
    </>
  );
};
