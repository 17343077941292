import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { useTranslation } from 'react-i18next';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { changeUserPassword } from '_State/User/Api/user.api';

const PasswordChangeBody = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 3.5rem;
  height: 29rem;
  justify-content: space-around;
`;

const StyledButton = styled(PlxButton)`
  && {
    width: 18.5rem;
  }
`;
interface IProps {
  onPasswordChange: (error?: boolean) => void;
}
export const PasswordChange: FC<IProps> = ({ onPasswordChange }) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const handleSubmit = () => {
    changeUserPassword({ oldPassword, newPassword }).then(
      () => onPasswordChange(false),
      () => onPasswordChange(true)
    );
  };
  return (
    <PasswordChangeBody>
      <PlxInfoPanel label={t('profile.changePassword')}>
        <Wrapper>
          <PlxInput
            value={oldPassword}
            type={'password'}
            onChange={(event) => setOldPassword(event.target.value)}
            label={t('profile.oldPassword')}
            placeholder={t('profile.oldPasswordPlaceholder')}
          />
          <PlxInput
            value={newPassword}
            type={'password'}
            onChange={(event) => setNewPassword(event.target.value)}
            label={t('profile.newPassword')}
            placeholder={t('profile.newPasswordPlaceholder')}
          />
          <StyledButton
            onClick={handleSubmit}
            label={t('profile.changePassword')}
          />
        </Wrapper>
      </PlxInfoPanel>
    </PasswordChangeBody>
  );
};
