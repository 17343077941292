import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import { IThemed } from '_Styles/types';
import { ReactComponent as XCircle } from './icons/x-circle.svg';

const ErrorMessageBody = styled.div<IThemed>`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 1rem 0;
  font-size: 1.2rem;
  justify-content: right;
  color: ${({ theme }) => theme.palette.error.main};
`;

const Message = styled.div<IThemed>`
  margin-left: 1rem;
  line-height: 2rem;
`;

interface IProps extends ICommonProps {
  message?: string | string[] | (string | undefined)[];
  testId?: string;
}

export const FieldErrorMessage: FC<IProps> = ({
  message,
  className,
  testId,
}) => {
  return (
    <ErrorMessageBody data-test-id={testId} className={className}>
      {((Array.isArray(message) && message.some((el) => el !== undefined)) ||
        (!Array.isArray(message) && message)) && <XCircle />}
      <Message>{message}</Message>
    </ErrorMessageBody>
  );
};
