import React, { FC } from 'react';
import { ICommonProps } from '_Types/props';
import { IThemed } from '_Styles/types';
import styled from '@emotion/styled';

const RequiredPointer = styled.span<IThemed>`
  color: ${({ theme }) => theme.palette.error.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  overflow: hidden;
`;
const Title = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  color: #808285;
  font-size: 1.2rem;
  font-family: Rubik, sans-serif;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  border-bottom: 1px solid #d5dde3;
  margin-bottom: -1px;
  z-index: 1;
  align-items: center;
`;

const NewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const NewTitle = styled.div<IThemed>`
  margin-bottom: 1rem;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: center;
  color: #808285;
`;

interface IProps extends ICommonProps {
  title?: string;
  required?: boolean;
  tooltip?: string;
  testId?: string;
}

export const FormSection: FC<IProps> = ({
  tooltip,
  required = false,
  title,
  children,
  className,
  testId,
}) => {
  return (
    <Wrapper className={className} data-test-id={testId}>
      <Title>
        {title}
        {required && <RequiredPointer>*</RequiredPointer>}
        {/* {tooltip && <StyledTooltip tooltipText={tooltip} />} */}
      </Title>
      <div>{children}</div>
    </Wrapper>
  );
};

export const PlxFormSection: FC<IProps> = ({
  tooltip,
  required = false,
  title,
  children,
  className,
  testId,
}) => {
  return (
    <NewWrapper className={className} data-test-id={testId}>
      <NewTitle>
        {title}
        {required && <RequiredPointer>*</RequiredPointer>}
        {/* {tooltip && <StyledTooltip tooltipText={tooltip} />} */}
      </NewTitle>
      <div>{children}</div>
    </NewWrapper>
  );
};
