import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getThriveDraft } from '_State/Thrive/Api/thrive.api';
import { PayViaCheck } from '../../../../_Components/Payments/PayWithCheck/PayViaCheck';

export const ThrivePayViaCheck: FC = () => {
  const [amount, setAmount] = useState<string>('');
  const { t } = useTranslation();
  const PAYMENT_BACKPATH = '/home/thrive-campaign/payment';
  const PAYMENT_NOTIFICATION = 'thrive.payment.notification';

  useEffect(() => {
    getThriveDraft().then((v) => {
      setAmount(v.payment.amount.toString());
    });
  }, []);

  return (
    <PayViaCheck
      amount={amount}
      backPath={PAYMENT_BACKPATH}
      notification={t(PAYMENT_NOTIFICATION)}
    />
  );
};
