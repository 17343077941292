import React, { Context, FC, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getSpectrumLink } from '_Routes/Home/_routes/Spectrum/utils';
import { PackageSection } from '_Routes/Home/_Components/Sections/PackageSection';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import { prepareRequiredFormValidation } from '_Components/Forms/utils';
import { IPaymentContext } from '_Types/paymentContext';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { PaymentSuccessDialog } from '../../Payments/PaymentConfirmationDialog/PaymentSuccessDialog';
import { PaymentFailureDialog } from '../../Payments/PaymentFailureDialog/PaymentFailureDialog';
// import { PaymentCommingSoonDialog } from '../../Payments/PaymentCommingSoonDialog/PaymentCommingSoonDialog ';
import { checkoutSession } from '_State/Spectrum/Api/spectrum.api';
import {
  saveProgramDraft,
  updateProgramDraft,
} from '_State/Spectrum/Api/spectrum.api';
import { ToggleButtons } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerWallet/FinfluencerWalletAddPaymentMethodsToggleButton';
import { BoxWrap, ButtonsSection } from './StylePayment';
import {
  packageDetails,
  initialValues,
  formValidation,
  INITIAL_VALUES,
  buttonOptions,
} from './utils';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { PackageInfo } from './PackageInfo';
import { PackageBox } from './PackageBox';
import { TotalAmount } from './TotalAmount';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const REQUIRED_FIELDS = ['package'];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);
interface IProps {
  context: Context<IPaymentContext<any>>;
}

export const Payment: FC<IProps> = ({ context }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const [acceptConditions, setAcceptConditions] = useState(false);
  const [paymentPlan, setPaymentPlan] = React.useState('left');
  const { isAgencyType } = useContext(NavigationContext);

  const {
    draft,
    packages,
    handlePaymentConfirmed,
    onSubmit,
    paymentConfirmationDescription,
    paymentFailureDescription,
    isProcessing,
    isThrivePayment,
    closeSuccessDialog,
    closeFailDialog,
    successDialogOpened,
    failDialogOpened,
  } = useContext(context);

  const saveDraft = (values: any) => {
    if (draft) {
      return updateProgramDraft(values).then();
    } else {
      return saveProgramDraft(values).then();
    }
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setPaymentPlan(newAlignment);
    }
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      console.log(form);

      if (requiredFormValidation(form) && draft) {
        const budgetForm = {
          ...form,
          // acceptClientContract: true,
          pricingType: 'INADVANCE',
          // contractSigned: moment().format('DD-MM-YYYYTHH:mm:ss'),
        };
        if (draft) {
          const createCheckoutSession = {
            campaignId: draft.id,
            packageId: form.packageId,
            // acceptClientContract: true,
            pricingType: 'INADVANCE',
          };
          checkoutSession(createCheckoutSession).then((res) => {
            window.open(res.url, '_self');
          });
          onSubmit(budgetForm);
        }
        // setAcceptConditions(!acceptConditions);
      }
    },
    validate: formValidation,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (form: any) => {
    formik.setValues(initialValues);
  };

  const setPackage = (
    fieldName: string,
    value: string,
    shouldValidate?: boolean,
    type?: string,
    id?: number | null
  ) => {
    if (!isThrivePayment) {
      const option = packages.filter(
        (p) =>
          p.value === value &&
          p.type === (paymentPlan === 'right' ? 'SEMI_ANNUALLY' : 'QUARTERLY')
      );
      if (
        option &&
        option.length > 0 &&
        option[0].option &&
        option[0].option.header
      ) {
        // draft.budget = { budget: option[0].option.header.programName };
        formik.setFieldValue(
          'programName',
          option[0].option.header.programName
        );
        formik.setFieldValue('tier', option[0].option.header.title);
      }
    }
    formik.setFieldValue(fieldName, value);
    formik.setFieldValue('pricingType', type);
    formik.setFieldValue('packageId', id);
    // setPaymentCommingSoonDialogOpen(value === 'Custom' ? true : false);
  };

  useEffect(() => {
    fillForm(draft);
    // eslint-disable-next-line
  }, [draft]);

  return (
    <>
      {!isThrivePayment && (
        <>
          <PackageInfo />
          <BoxWrap>
            <PackageBox packageDetails={packageDetails} formik={formik} />
          </BoxWrap>
        </>
      )}
      <ToggleButtons
        options={buttonOptions}
        alignment={paymentPlan}
        handleChange={handleChange}
      ></ToggleButtons>
      <PackageSection
        title={t('spectrum.payment.campaignPricing')}
        setValue={setPackage}
        fieldName={'package'}
        packages={packages}
        required
        isThrivePayment={isThrivePayment}
        campaignPackage={formik.values.campaignPackage}
        paymentPlan={paymentPlan}
      />
      <FieldErrorMessage message={formik.errors.package} />

      {formik.values.package && (
        <TotalAmount
          formik={formik}
          discountedValue={Number(formik.values.package)}
        />
      )}
      <ButtonsSection>
        <PlxButtonX
          onClick={() =>
            saveDraft(formik.values).then(() =>
              getSpectrumLink(isAgencyType).then((link) => {
                if (link) {
                  history.push(link);
                }
              })
            )
          }
          label={t('spectrum.footerButtons.saveForLater')}
          variant2
        />

        <FooterButtons
          showPayNow={formik.values.package !== ''}
          showNextStep={!formik.values.package}
          onSave={() =>
            getSpectrumLink(isAgencyType).then((link) => {
              if (link) {
                history.push(link);
              }
            })
          }
          isNextStepDisabled={
            isProcessing || formik.values.package === 'Custom'
          }
          onPayNow={formik.handleSubmit}
          onNextStep={formik.handleSubmit}
        />
      </ButtonsSection>
      {/* <PlxDialog
        title={''}
        open={acceptConditions}
        onClose={() => setAcceptConditions(false)}
      >
        <ClientContract
          handleNotAccept={() => setAcceptConditions(false)}
          formik={formik}
        />
      </PlxDialog> */}
      <PaymentSuccessDialog
        open={successDialogOpened}
        onClose={closeSuccessDialog}
        onNext={handlePaymentConfirmed}
        description={paymentConfirmationDescription}
      />
      <PaymentFailureDialog
        open={failDialogOpened}
        onClose={closeFailDialog}
        description={paymentFailureDescription}
      />
      {/* <PaymentCommingSoonDialog
        open={paymentCommingSoonDialogOpen}
        onClose={(): void => {
          setPaymentCommingSoonDialogOpen(false);
        }}
        description={paymentFailureDescription}
      /> */}
    </>
  );
};
