import React from 'react';
import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import {
  capitalize,
  StyledCheckbox,
} from '../EngagementBenchmarkGraph/EngagementBenchmarkGraph';
import { DataColor, IVisibility, visibilityLabels } from './data';

interface IVisibilityControlPanel {
  dataColors: DataColor[];
  visibility: IVisibility;
  selectedCampaigns: string[];
  handleChange: (arg1: string, arg2: string) => void;
}

export const VisibilityControlPanel = ({
  dataColors,
  visibility,
  handleChange,
  selectedCampaigns,
}: IVisibilityControlPanel) => {
  return (
    <div
      style={{
        marginLeft: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <FormControl component="fieldset">
          <FormGroup
            style={{
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {dataColors
              .slice(0, selectedCampaigns.length || 1)
              .map((el: DataColor, index: number) => {
                return (
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      fontSize: '14px',
                    }}
                    key={el.name + index}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 16,
                      }}
                    >
                      {!selectedCampaigns.length
                        ? 'Total engagement rate'
                        : el.name
                            .replace('campaign-', 'Campaign #')
                            .slice(0, -1)
                            .concat(selectedCampaigns[index])}
                    </div>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={visibility[el.name].reactions}
                          onChange={() =>
                            handleChange(el.name, el.data[0].name)
                          }
                          checkboxcolor={el.data[0].color}
                        />
                      }
                      label={capitalize(visibilityLabels.reactions)}
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={visibility[el.name].comments}
                          onChange={() =>
                            handleChange(el.name, el.data[1].name)
                          }
                          checkboxcolor={el.data[1].color}
                        />
                      }
                      label={capitalize(visibilityLabels.comments)}
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={visibility[el.name].shares}
                          onChange={() =>
                            handleChange(el.name, el.data[2].name)
                          }
                          checkboxcolor={el.data[2].color}
                        />
                      }
                      label={capitalize(visibilityLabels.shares)}
                    />
                  </div>
                );
              })}
          </FormGroup>
        </FormControl>
      </div>
      {/* <div style={{ alignSelf: 'end', marginRight: '24px' }}>
        <SpectrumButton text={t('spectrum.overview.seeDetails')} />
      </div> */}
    </div>
  );
};

export default VisibilityControlPanel;
