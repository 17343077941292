import React, { FC } from 'react';
import styled from '@emotion/styled';

export const TableHead = styled.div`
  width: 100%;
  background: #f8f9fb;
  border-radius: 10px;
  display: flex;
  margin-bottom: 2rem;
  ul {
    width: 100%;
    display: flex;
    margin-left: 2rem;
  }
  li {
    padding: 2rem 0 2rem;
    flex: 1;
  }
  li:nth-of-type(2) {
    flex: 0.5;
  }
  li:nth-of-type(3) {
    flex: 2;
  }
  li:nth-of-type(5) {
    text-align: center;
  }
`;

export const XemTableHeader: FC<{ headers: string[] }> = ({ headers }) => {
  return (
    <TableHead>
      <ul>
        {headers.map((header, index) => {
          return <li key={index}>{header}</li>;
        })}
      </ul>
    </TableHead>
  );
};
