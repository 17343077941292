import { ICalendarDate } from '../SpectrumDashboard/_Component/Filters/DateTimeFilter/DateTimeFilter';

export interface IFilterItem {
  id: string;
  title: string;
  isChecked: boolean;
  subtitle?: string;
}

export interface IMoreFiltersItem {
  name: string;
  items: IFilterItem[];
}
export interface IFilters {
  campaignIds: IFilterItem[];
  campaign: IFilterItem[];
  campaignCompare: IFilterItem[];
  channelType: IFilterItem[];
  date: ICalendarDate;
  moreFilters: IMoreFiltersItem[];
}

export const moreFilters: IMoreFiltersItem[] = [
  {
    name: 'ContentType',
    items: [
      { id: '01', title: 'Press release', isChecked: false },
      { id: '02', title: 'Annual report', isChecked: false },
      { id: '03', title: 'Media coverage', isChecked: false },
      { id: '04', title: 'Event', isChecked: false },
      { id: '05', title: 'Other', isChecked: false },
    ],
  },
  {
    name: 'ContentCategory',
    items: [
      { id: '11', title: 'Corporate profile', isChecked: false },
      { id: '12', title: 'Earnings Call', isChecked: false },
      { id: '13', title: 'Hiring', isChecked: false },
      { id: '14', title: 'Press release', isChecked: false },
      { id: '15', title: 'Annual report', isChecked: false },
      { id: '16', title: 'Event', isChecked: false },
      { id: '17', title: 'Operations', isChecked: false },
      { id: '18', title: 'Partnership', isChecked: false },
      { id: '19', title: 'Sales', isChecked: false },
      { id: '20', title: 'Other', isChecked: false },
    ],
  },
  {
    name: 'GeoTargeting',
    items: [
      { id: '21', title: 'Global', isChecked: false },
      { id: '22', title: 'USA', isChecked: false },
      { id: '23', title: 'Canada', isChecked: false },
      { id: '24', title: 'Europe', isChecked: false },
      { id: '25', title: 'Asia', isChecked: false },
    ],
  },
  {
    name: 'Industry',
    items: [
      { id: '31', title: 'Real Estate', isChecked: false },
      { id: '32', title: 'Mining', isChecked: false },
      { id: '33', title: 'Esports', isChecked: false },
      { id: '34', title: 'Tech', isChecked: false },
      { id: '35', title: 'Health', isChecked: false },
      { id: '36', title: 'Cannabis', isChecked: false },
      { id: '37', title: 'Other', isChecked: false },
    ],
  },
];
export const channelTypeFilters: IFilterItem[] = [
  { id: '1', title: 'Facebook', isChecked: false, subtitle: '' },
  { id: '2', title: 'Instagram', isChecked: false, subtitle: '' },
  { id: '3', title: 'TikTok', isChecked: false, subtitle: '' },
  { id: '4', title: 'YouTube', isChecked: false, subtitle: '' },
  { id: '5', title: 'Twitch', isChecked: false, subtitle: '' },
  { id: '6', title: 'Reddit', isChecked: false, subtitle: '' },
  { id: '7', title: 'Discord', isChecked: false, subtitle: '' },
  { id: '8', title: 'Pinterest', isChecked: false, subtitle: '' },
  { id: '9', title: 'Twitter', isChecked: false, subtitle: '' },
  { id: '10', title: 'Linkedin', isChecked: false, subtitle: '' },
  { id: '11', title: 'WhatsApp', isChecked: false, subtitle: '' },
  { id: '12', title: 'Telegram', isChecked: false, subtitle: '' },
];
export const campaignTagFilters: IFilterItem[] = [
  {
    id: '62bd6846d592f5f81ab0f71a',
    isChecked: false,
    title: 'ea et',
    subtitle: 'nulla ad laboris veniam reprehenderit',
  },
  {
    id: '62bd6846039e877a903c802c',
    isChecked: false,
    title: 'adipisicing voluptate',
    subtitle: 'qui nisi culpa elit aliqua',
  },
  {
    id: '62bd68468ad5c211d72aefca',
    isChecked: false,
    title: 'reprehenderit irure',
    subtitle: 'nisi minim occaecat amet labore',
  },
  {
    id: '62bd6846a3b0c023cebaa361',
    isChecked: false,
    title: 'exercitation sit',
    subtitle: 'labore ex anim culpa duis',
  },
  {
    id: '62bd684639a4806b95e02965',
    isChecked: false,
    title: 'excepteur fugiat',
    subtitle: 'elit nostrud quis duis sit',
  },
  {
    id: '62bd6846cb6ce73711b2b73b',
    isChecked: false,
    title: 'adipisicing nostrud',
    subtitle: 'quis fugiat amet officia deserunt',
  },
  {
    id: '62bd6846a26d8291017186e6',
    isChecked: false,
    title: 'eiusmod reprehenderit',
    subtitle: 'cillum eu voluptate enim adipisicing',
  },
  {
    id: '62bd68469e81164ec5aa818a',
    isChecked: false,
    title: 'aute mollit',
    subtitle: 'sunt laboris commodo esse commodo',
  },
  {
    id: '62bd6846b4effc1ac2c6614d',
    isChecked: false,
    title: 'pariatur Lorem',
    subtitle: 'ullamco tempor nisi officia est',
  },
  {
    id: '62bd684666119093a1a603e4',
    isChecked: false,
    title: 'deserunt laboris',
    subtitle: 'magna ut elit cupidatat do',
  },
  {
    id: '62bd68466599b25fc7528d0d',
    isChecked: false,
    title: 'cillum ex',
    subtitle: 'minim in quis reprehenderit dolor',
  },
  {
    id: '62bd6846ec8d5c4a94874ab5',
    isChecked: false,
    title: 'anim velit',
    subtitle: 'sit nisi eiusmod fugiat id',
  },
  {
    id: '62bd6846efe839e486212933',
    isChecked: false,
    title: 'laboris quis',
    subtitle: 'magna cupidatat ut ea enim',
  },
  {
    id: '62bd684649b352c3710be81b',
    isChecked: false,
    title: 'tempor irure',
    subtitle: 'deserunt pariatur sint aute magna',
  },
  {
    id: '62bd68460883655b99995f63',
    isChecked: false,
    title: 'ut duis',
    subtitle: 'sint consectetur amet eu consectetur',
  },
];
