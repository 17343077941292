import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxSimpleBarChart } from '_Components/Charts/PlxBarChart/PlxSimpleBarChart';
import { ICommonProps } from '_Types/props';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { ISimpleBarOptions } from '_Components/Charts/PlxBarChart/types';

const ThriveAgeChartsBody = styled.div``;

const option: ISimpleBarOptions = {
  tooltip: { formatter: ' {c} leads', backgroundColor: '#192044' },
  grid: { left: 65, right: 50, top: 50, bottom: 30 },
  xAxis: {
    type: 'category',
    data: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    axisTick: { show: false },
    axisLine: { show: false },
  },
  yAxis: {
    type: 'value',
    axisTick: { show: false },
    axisLine: { show: false },
  },
  series: [
    {
      barWidth: 40,
      data: [],
      type: 'bar',
      itemStyle: { barBorderRadius: 4, color: '#3C6EF6' },
    },
  ],
};

interface IProps extends ICommonProps {
  timeData?: number[];
}

export const ThriveLeadsTimeChart: FC<IProps> = ({ timeData, className }) => {
  const [chartOption, setChartOption] = useState<ISimpleBarOptions>();
  useEffect(() => {
    option.series[0].data = timeData;
    setChartOption(JSON.parse(JSON.stringify(option)));
  }, [timeData]);

  return (
    <ThriveAgeChartsBody className={className}>
      <PlxInfoPanel label={'Total Leads by Month'}>
        {chartOption && <PlxSimpleBarChart config={chartOption} />}
      </PlxInfoPanel>
    </ThriveAgeChartsBody>
  );
};
