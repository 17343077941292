import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '_State';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { PlxStepper } from '_Components/PlxStepper/PlxStepper';
import { HomePageWrapper } from '../../_Components/HomePageWrapper';
import { FormWrapper } from '_Components/Wrappers/FormWrapper';
import { THRIVE_PROGRESS_STRATEGY } from '_State/Thrive/thrive.types';
import { thriveActions } from '_State/Thrive/thrive.state';
import { ThriveProgram } from '_Routes/Home/_routes/Thrive/Routes/ThriveProgram/ThriveProgram';
import { ThriveCampaign } from './Routes/ThriveCampaign/ThriveCampaign';
import { ThrivePayment } from './Routes/ThrivePayment/ThrivePayment';
import { ThrivePayViaCheck } from './Routes/ThrivePayment/ThrivePayViaCheck';
import { ThrivePayViaWire } from './Routes/ThrivePayment/ThrivePayViaWire';

const stepperConfig = [
  'thrive.tracker.program',
  'thrive.tracker.campaign',
  'thrive.tracker.payment',
];
const StepperWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ThriveWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Stepper = styled.div<IThemed>`
  width: 100%;
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  /* border-right: 1px solid #d5dde3; */
  text {
    fill: #fff;
    font-weight: 700;
  }
`;
export const Thrive: FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const step = useSelector<IState, string>((state) => state.thrive.step);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line
    Object.keys(THRIVE_PROGRESS_STRATEGY).filter((o) => {
      const loc = location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1)
        .toLowerCase();
      if (o.toUpperCase().includes(loc.toUpperCase())) {
        dispatch(thriveActions.changeStep({ step: loc }));
      }
    });
    setActiveStep((THRIVE_PROGRESS_STRATEGY as any)[step]);
    // eslint-disable-next-line
  }, [location, step, activeStep]);
  const handleStepClick = (index: number) => {};

  return (
    <ThriveWrapper>
      <StepperWrap>
        <Stepper>
          <PlxStepper
            onStepClick={handleStepClick}
            steps={stepperConfig.map((v) => t(v))}
            activeStep={activeStep}
          />
        </Stepper>
      </StepperWrap>
      <HomePageWrapper>
        <FormWrapper>
          <Route exact path="/home/thrive-campaign">
            <Redirect to={'/home/thrive-campaign/program'} />
          </Route>
          <Route exact path="/home/thrive-campaign/program">
            <ThriveProgram />
          </Route>
          <Route exact path="/home/thrive-campaign/campaign">
            <ThriveCampaign />
          </Route>
          <Route exact path="/home/thrive-campaign/payment">
            <ThrivePayment />
          </Route>
          <Route exact path="/home/thrive-campaign/payment/wire">
            <ThrivePayViaWire />
          </Route>
          <Route exact path="/home/thrive-campaign/payment/check">
            <ThrivePayViaCheck />
          </Route>
        </FormWrapper>
      </HomePageWrapper>
    </ThriveWrapper>
  );
};
