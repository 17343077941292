import React, { ChangeEvent, FC, FocusEvent } from 'react';
import { Divider, InputAdornment, TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';
import { ReactComponent as ArrowUpIcon } from './ArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from './ArrowDown.svg';
import MaskedInput from 'react-text-mask';

const Input = styled(TextField)<IThemed>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ArrowsDivider = styled(Divider)`
  border: 1px solid #d5dde3;
`;

const NumberAreaWrapper = styled.div`
  right: 0;
  height: 100%;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 3.5rem;
`;

const ContentDivider = styled(Divider)`
  border: 1px solid #d5dde3;
`;
const ArrowsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ArrowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const renderMaskInput = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

export interface IInputProps extends ICommonProps {
  value?: unknown;
  defaultValue?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  placeholder?: string;
  label?: string;
  typeNumber?: boolean;
  type?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: any;
  multiline?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  fullWidth?: boolean;
  margin?: 'dense' | 'none';
  onUpArrowClick?: () => void;
  onDownArrowClick?: () => void;
  onFocus?: () => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  mask?: boolean;
  testId?: string;
}
export const PlxInput: FC<IInputProps> = ({
  value,
  defaultValue,
  placeholder,
  label,
  className,
  name,
  required = false,
  onChange,
  disabled,
  endAdornment,
  multiline,
  rows,
  rowsMax,
  fullWidth = true,
  margin,
  typeNumber,
  type,
  onUpArrowClick,
  onDownArrowClick,
  onFocus,
  onBlur,
  mask,
  testId,
}) => {
  return (
    <Wrapper className={className} data-test-id={testId}>
      <Input
        margin={margin}
        disabled={disabled}
        value={value}
        className={className}
        placeholder={placeholder}
        label={label}
        type={type}
        variant={'outlined'}
        defaultValue={defaultValue}
        name={name}
        required={required}
        onChange={onChange}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        onFocus={onFocus}
        onBlur={onBlur}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: mask ? (renderMaskInput as any) : 'input',
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
      {typeNumber && (
        <NumberAreaWrapper>
          <ContentDivider orientation={'vertical'} />
          <ArrowsWrapper>
            <ArrowWrapper onClick={onUpArrowClick}>
              <ArrowUpIcon />
            </ArrowWrapper>
            <ArrowsDivider />
            <ArrowWrapper onClick={onDownArrowClick}>
              <ArrowDownIcon />
            </ArrowWrapper>
          </ArrowsWrapper>
        </NumberAreaWrapper>
      )}
    </Wrapper>
  );
};
