import { FC, RefObject, useEffect } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';

interface IProps {
  text?: string;
  type: string;
  placeholder: string;
  childRef: RefObject<any>;
  onSetEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  onKeyConfirm: () => void;
}

const useStyles = makeStyles({
  container: {},
  text: {
    fontWeight: 500,
    fontSize: '1.4rem',
    color: '#1b1b1b',
  },
  placeholder: {},
});

const InputWrapper = styled.div``;
const TextWrapper = styled.div``;
const Text = styled.span``;

export const EditableLabel: FC<IProps> = ({
  text,
  type,
  placeholder,
  children,
  childRef,
  onSetEditing,
  isEditing,
  onKeyConfirm,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  const handleKeyDown = (event: any, type: any) => {
    const { key } = event;
    const keys = ['Escape', 'Tab'];
    const enterKey = 'Enter';
    const allKeys = [...keys, enterKey];
    if (
      (type === 'textarea' && keys.indexOf(key) > -1) ||
      (type !== 'textarea' && allKeys.indexOf(key) > -1)
    ) {
      onSetEditing(false);
      onKeyConfirm();
    }
  };

  return (
    <>
      {isEditing ? (
        <InputWrapper
          onBlur={() => onSetEditing(false)}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </InputWrapper>
      ) : (
        <TextWrapper className={classes.container}>
          <Text className={text ? classes.text : classes.placeholder}>
            {text || placeholder || 'Editable content'}
          </Text>
        </TextWrapper>
      )}
    </>
  );
};
