import React, { FC } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import { Provider } from 'react-redux';
import { globalStyles } from '_Styles/globalStyles';
import { ICommonProps } from '_Types/props';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from '_Styles/theme';
import { store } from '_State/store';

export const GlobalProviders: FC<ICommonProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <Global styles={globalStyles} />
          {children}
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  );
};
