import React, { FC, useEffect, useState } from 'react';
import { getDraft } from '_State/Spectrum/Api/spectrum.api';
import { PayViaCheck } from '../../../../_Components/Payments/PayWithCheck/PayViaCheck';
import { getProgramPaymentInfo } from '_Api/auth';

export const SpectrumPayViaCheck: FC = () => {
  const [amount, setAmount] = useState<string>('');
  const PAYMENT_BACKPATH = '/home/client/spectrum-campaign/payment';
  const PAYMENT_NOTIFICATION = 'spectrum.payment.notification';

  useEffect(() => {
    getDraft().then((v) => {
      getProgramPaymentInfo([v.programId]).then((payment: any) => {
        setAmount(payment.content[0].amount);
      });
    });
  }, []);

  return (
    <PayViaCheck
      amount={amount}
      backPath={PAYMENT_BACKPATH}
      notification={PAYMENT_NOTIFICATION}
    />
  );
};
