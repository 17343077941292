import React, { FC, useContext } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles, Divider, Slider } from '@material-ui/core';
import IdeaIcon from '_Styles/images/IdeaIcon.png';
import {
  Bold,
  ButtonsValidateWrapper,
  StyledH1,
  StyledH2,
  StyledImage,
  StyledTip,
  StyledValidationWindow,
  StyledValidationWrapper,
} from '../../utils';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const useStyles = makeStyles(() => ({
  slider: {
    height: '6px',
    width: '480px',
    '& .MuiSlider-thumb': {
      background: '#fff',
      border: '5px solid #760397',
      height: '16px',
      width: '16px',
      marginTop: '-5px',
    },
    '& .Mui-disabled': {
      color: '#B8B7B7',
    },

    '& .MuiSlider-rail': {
      height: '6px',
      borderRadius: '40px',
    },
    '&	.MuiSlider-track': {
      height: '6px',
      borderRadius: '40px',
      background:
        'linear-gradient(270deg, #760397 0%, rgba(118, 3, 151, 0.14) 100%)',
    },
  },
}));

export const PendingValidation: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { isAgencyType } = useContext(NavigationContext);

  return (
    <StyledValidationWrapper>
      <StyledValidationWindow>
        <StyledImage src={IdeaIcon} alt="my Image" />
        <StyledH1>{t('spectrum.validation.validating')}</StyledH1>
        <StyledH2>{t('spectrum.validation.wait')}</StyledH2>
        <Slider className={classes.slider} value={75} disabled />
        <ButtonsValidateWrapper>
          <PlxButtonX
            newDesign
            validation
            label={t('spectrum.footerButtons.notify')}
            onClick={() => {
              history.push(
                isAgencyType
                  ? '/home/agency/dashboard'
                  : '/home/client/dashboard'
              );
            }}
            variant2
          />
        </ButtonsValidateWrapper>
        <Divider />
        <StyledTip>
          P.S <Bold>{t('spectrum.validation.notify')}</Bold> -{' '}
          {t('spectrum.validation.info')}
        </StyledTip>
      </StyledValidationWindow>
    </StyledValidationWrapper>
  );
};
