import React, { FC } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
interface IProps {
  handleCloseApprovePayoutId: () => void;
  confirmPay: () => void;
}
export const ApprovePayoutConfirmationDialog: FC<IProps> = ({
  handleCloseApprovePayoutId,
  confirmPay,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {t('eligibleReferralAction.approveDialogHeader')}
          </DialogTitle>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseApprovePayoutId} color="primary">
          {t('channels.reviewAnalyticsConfirmation.cancel')}
        </Button>
        <Button onClick={confirmPay} color="primary" autoFocus>
          {t('channels.reviewAnalyticsConfirmation.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
