import React, { FC } from 'react';
import styled from '@emotion/styled';
import { createStyles, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
interface IProps {
  campaigns?: any[];
}
const CompanyInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.4rem;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '1.5rem',
    },
  })
);

const StyledList = styled(List)`
  width: 100%;
`;

const ItemId = styled.div`
  color: #1b1b1b;
`;

const ItemStartDate = styled.div`
  color: #8b8b8b;
`;

export const CompaniesList: FC<IProps> = ({ campaigns }) => {
  const classes = useStyles();
  return (
    <StyledList>
      {campaigns &&
        campaigns.map((campaign, index) => {
          return (
            <ListItem
              key={index}
              divider={index < campaigns?.length - 1}
              classes={{ root: classes.root }}
            >
              <CompanyInfoItem>
                <ItemId>Campaign #{campaign.id}</ItemId>
                <ItemStartDate>{campaign.startDate}</ItemStartDate>
              </CompanyInfoItem>
            </ListItem>
          );
        })}
    </StyledList>
  );
};
