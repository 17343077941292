import React, { FC, useEffect, useState } from 'react';
import { Payment } from '../../../../_Components/Sections/Payment/Payment';
import { SpectrumPaymentContext } from './spectrumPaymentContext';
import { IPaymentContext, IPaymentForm } from '_Types/paymentContext';
import { IProgramDraft, ISpectrumCampaign } from '_State/Spectrum/types';
import { useHistory } from 'react-router-dom';
import {
  checkProcessCheckout,
  getBudgets,
  getDraft,
  getProgramActive,
  saveProgramDraft,
  updateProgramDraft,
} from '_State/Spectrum/Api/spectrum.api';
import { useTranslation } from 'react-i18next';
import { IPackageControlConfig } from '../../../../_Components/Payments/PackageControl/PackageControl.types';
import { getPackageOptions } from './utils';

export const SpectrumPayment: FC = () => {
  const [draft, setDraft] = useState<ISpectrumCampaign>();
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(
    false
  );
  // const { id } = useParams();
  const [failDialogOpened, setFailDialogOpened] = useState<boolean>(false);
  const [paymentConfig, setPaymentConfig] = useState<IPackageControlConfig[]>(
    []
  );
  const [
    isProcessing,
    // setIsProcessing
  ] = useState(false);
  const isThrivePayment = false;
  const history = useHistory();
  const { t } = useTranslation();

  const INITIAL_VALUES: IProgramDraft = {
    industry: [],
    geolocation: [],
    tag: undefined,
    stockTickerList: undefined,
    signerIp: '',
    contractViewed: '',
    contractSigned: '',
  };

  const context: IPaymentContext<ISpectrumCampaign> = {
    isThrivePayment,
    isProcessing,
    draft,
    packages: paymentConfig,
    handleBack: () => {
      history.push('/home/client/spectrum-campaign/program');
    },
    handlePaymentConfirmed: () => {
      history.push('/home/client/spectrum-campaign/program');
    },
    closeSuccessDialog: () => {
      setSuccessDialogOpened(false);
    },
    closeFailDialog: () => {
      setFailDialogOpened(false);
    },
    paymentConfirmationDescription: t(
      'spectrum.payment.paymentConfirmation.description'
    ),
    paymentFailureDescription: t('spectrum.payment.paymentFailure.description'),
    onSubmit: (form: IPaymentForm) => {
      if (draft) {
        INITIAL_VALUES.budget = form.programName;
        INITIAL_VALUES.geolocation = draft.geolocation;
        INITIAL_VALUES.industry = draft.industry;
        INITIAL_VALUES.stockTickerList = draft.stockTickerList;
        INITIAL_VALUES.signerIp = form.signerIp;
        INITIAL_VALUES.contractViewed = form.contractViewed;
        INITIAL_VALUES.contractSigned = form.contractSigned;
        if (draft.tag) INITIAL_VALUES.tag = draft.tag?.id;
        updateProgramDraft(INITIAL_VALUES);
      }
    },
    successDialogOpened,
    failDialogOpened,
  };

  useEffect(() => {
    getProgramActive()
      .then((res) => {
        if (res.remainingCampaigns > 0) {
          history.push('/home/client/spectrum-campaign/program');
        }
      })
      .catch(() => {
        getDraft()
          .then((draft) => {
            if (draft) {
              setDraft(draft);
              getBudgets().then(
                (budgets) => {
                  if (draft) {
                    draft.budgets = budgets;
                    setPaymentConfig(getPackageOptions(draft));
                  }
                },
                () => null
              );
            }
          })
          .catch(() => {
            saveProgramDraft({
              budget: 'PROGRAM_1',
            }).then(() => {
              getDraft().then(
                (draft) => {
                  if (draft) {
                    setDraft(draft);
                    getBudgets().then(
                      (budgets) => {
                        if (draft) {
                          draft.budgets = budgets;
                          setPaymentConfig(getPackageOptions(draft));
                        }
                      },
                      () => null
                    );
                  }
                },
                () => null
              );
            });
          });
      });
  }, [history]);

  useEffect(() => {
    const queryParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const pathName = window.location.pathname;
    const campaignId = queryParams.get('campaign_id');
    const sessionId = queryParams.get('session_id');
    if (pathName === '/home/client/spectrum-campaign/payment/success') {
      if (sessionId && campaignId) {
        checkProcessCheckout(campaignId, sessionId)
          .then((res) => {
            setSuccessDialogOpened(true);
          })
          .catch((error) => {
            setFailDialogOpened(true);
          });
      }
    }
    if (pathName === '/home/client/spectrum-campaign/payment/failed') {
      setFailDialogOpened(true);
    }
  }, []);
  return (
    <SpectrumPaymentContext.Provider value={context}>
      <Payment context={SpectrumPaymentContext} />
    </SpectrumPaymentContext.Provider>
  );
};
