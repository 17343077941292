import React, { FC, useState } from 'react';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { useFormik } from 'formik';
import { IApproveChannelForm } from '_State/DistributionPartner/distributionPartner.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { FormMassage } from '_Components/Forms/FormMassage';
import styled from '@emotion/styled';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { approveChannel } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import i18next from 'i18next';

const INITIAL_VALUES: IApproveChannelForm = {
  offer: undefined,
  offeringSummary: '',
  termsAndConditions: {
    fileName: 'download.pdf',
    base64Content:
      'JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9j',
  },
};

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5.5rem;
  margin-top: 7rem;
  margin-bottom: 4rem;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
`;

const REQUIRED_FIELDS = ['offer', 'offeringSummary', 'termsAndConditions'];

interface IProps {
  onChannelApproved: () => void;
  onBack: () => void;
  channelId?: number;
}

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const ApproveChannelForm: FC<IProps> = ({
  channelId,
  onChannelApproved,
  onBack,
}) => {
  const { t } = useTranslation();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        if (channelId) {
          approveChannel(form, channelId).then(
            () => {
              onChannelApproved();
              setIsProcessing(false);
            },
            (error) => {
              error.response
                .json()
                .then((error: any) => setApiErrorMessage(error.memssage));
            }
          );
        }
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.offer && (isNaN(form.offer) || form.offer < 0)) {
        errors.offer = 'Please provide correct number value';
      }
      if (form.offer && form.offer > 2147483647) {
        errors.offer = 'Value is too big';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleBack = () => {
    onBack();
  };
  const handleOffer = (offer: number) => {
    formik.setFieldValue('offer', offer);
    formik.setFieldValue(
      'offeringSummary',
      i18next.t('channels.approveChannel.offeringSummaryAutoFill', {
        offerAmount: offer,
      })
    );
  };

  return (
    <Wrapper>
      <InputSection
        typeNumber
        title={t('channels.approveChannel.offer')}
        placeholder={t('channels.approveChannel.offerPlaceholder')}
        required
        fieldName={'offer'}
        handleChange={(e: any) => handleOffer(e.target.value)}
        handleNumberChange={(value: number) => handleOffer(value)}
        testId="offer-field"
      />
      <FieldErrorMessage message={formik.errors.offer} testId="offer-error" />
      <InputSection
        multiline
        rows={6}
        title={t('channels.approveChannel.offeringSummary')}
        placeholder={t('channels.approveChannel.offeringSummaryPlaceholder')}
        required
        fieldName={'offeringSummary'}
        handleChange={formik.handleChange}
        testId="summary-field"
        value={formik.values.offeringSummary}
      />
      <FieldErrorMessage
        message={formik.errors.offeringSummary}
        testId="summary-error"
      />
      {apiErrorMessage && <FormMassage message={apiErrorMessage} />}
      <ButtonsWrapper>
        <PlxButton
          label={t('channels.approveChannel.back')}
          onClick={handleBack}
          variant={ButtonVariant.Outlined}
          testId="back-button"
        />
        <PlxButton
          label={t('channels.approveChannel.submit')}
          onClick={formik.handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
          testId="submit-button"
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
