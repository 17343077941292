import React, { FC } from 'react';
import { CampaignsTable } from '../SpectrumDashboard/_Component/CampaignsTable/CampaignsTable';
import styled from '@emotion/styled';
import { MultiSelectAnalytics } from '../SpectrumDashboard/SpectrumDashboard';

const CampaignPageWrap = styled.div`
  background: #f8f8f8;
  font-family: 'Quicksand';
  font-style: normal;
  min-width: 300px;
  margin: 0 auto;
  /* width: calc(100% - 8.5rem); */
  width: 100%;
  padding: 2rem 4rem;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const SpectrumCampaign: FC<{ userId?: number }> = ({ userId }) => {
  return (
    <CampaignPageWrap>
      <Headers>
        {!!userId && (
          <MultiSelectAnalytics userId={userId} value={'campaigns'} />
        )}
      </Headers>
      <CampaignsTable isAdmin={userId} />
    </CampaignPageWrap>
  );
};
