export interface IFilterItem {
  id: string;
  isChecked: boolean;
  subtitle: string;
}
export enum dailyEnum {
  'lastDay' = 'lastDay',
  'lastWeek' = 'lastWeek',
  'last2Weeks' = 'last2Weeks',
  'lastMonth' = 'lastMonth',
}

export const dailyTags: IFilterItem[] = [
  { id: '1', isChecked: false, subtitle: 'lastDay' },
  { id: '2', isChecked: false, subtitle: 'lastWeek' },
  { id: '3', isChecked: false, subtitle: 'last2Weeks' },
  { id: '4', isChecked: false, subtitle: 'lastMonth' },
];
