import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUpOutlined } from '@material-ui/icons';
import React, { useState, FC } from 'react';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import { StyledMenuIcon } from '_Routes/Home/NavigationPanel/Icons/StyledIcon';

const useStyles = makeStyles({
  button: {
    background: '#f6f6f6',
    borderRadius: '4px',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#808285',
    border: '0.5px solid #d5dde3',
    height: '3rem',
    margin: ' 0 1rem 1rem 0',
    textAlign: 'left',
  },
});

interface IProps {
  buttons: IIconButtonConfig[];
  onClick: (id: string) => void;
  translateY?: number;
}

export const PlxButtonMenu: FC<IProps> = ({ buttons, onClick, translateY }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  return (
    <div>
      <Button
        className={classes.button}
        size={'small'}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowUpOutlined style={{ fill: '#000' }} />
          ) : (
            <KeyboardArrowDown style={{ fill: '#000' }} />
          )
        }
      >
        {`+${buttons.length - 5} More`}
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: `translateX(30%) translateY(${translateY}%)`,
          },
        }}
      >
        {buttons.slice(5, buttons.length).map((button) => {
          const handleClick = () => {
            onClick(button.id);
          };
          return (
            <MenuItem
              style={{
                background: button.isChosen ? '#000' : '#f6f6f6',
                color: button.isChosen ? '#fff' : '#000',
              }}
              onClick={handleClick}
              disableRipple
              key={button.label}
            >
              <StyledMenuIcon newDesign={true} isFocused={button.isChosen} />
              {button.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
