import React, { FC } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #1b1b1b;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.3rem;
  font-family: Rubik, sans-serif;
`;
const Sum = styled.div`
  font-family: Rubik, sans-serif;
  width: 15rem;
  padding: 3.5rem;
  height: 6.5rem;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 0.2rem;
  font-size: 1.8rem;
  line-height: 3.3rem;
  text-align: center;
  color: #1b1b1b;
  margin: 1.6rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Item = styled.div`
  margin-bottom: 2rem;
`;
const ItemLabel = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3rem;
  color: #1b1b1b;
`;
const ItemValue = styled.p`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  color: #767676;
`;
interface IProps {
  amount: string;
}
export const PayViaCheckInfo: FC<IProps> = ({ amount }) => {
  return (
    <Wrapper>
      <Label>Pay via check</Label>
      <Sum>${amount}</Sum>
      <Item>
        <ItemLabel>Please make checks payable to:</ItemLabel>
        <ItemValue>Example Company</ItemValue>
      </Item>
      <Item>
        <ItemLabel>Mailing address</ItemLabel>
        <ItemValue>Example Street 45,</ItemValue>
        <ItemValue>Example postal code, Example City</ItemValue>
        <ItemValue>Example Province</ItemValue>
      </Item>
    </Wrapper>
  );
};
