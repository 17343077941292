import React, { FC } from 'react';
import { ICommonProps } from '_Types/props';
import styled from '@emotion/styled';
import { ITile } from '_Types/ITile';
import { IThemed } from '_Styles/types';
import { PlxTile } from '_Components/Panels/PlxTilesPanel/PlxTile';
import { ClientPlxTile } from './ClientPlxTile';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Tiles = styled.div<IThemed>`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
`;

interface IProps extends ICommonProps {
  tiles: ITile[];
  client?: boolean;
  onTileChange?: (tileName: string, tileValue: any) => void;
  className?: string;
  campaignStatus?: string;
}

export const PlxTilesPanel: FC<IProps> = ({
  tiles,
  client,
  onTileChange,
  className,
  campaignStatus,
}) => {
  return (
    <Wrapper className={className}>
      <Tiles>
        {client
          ? tiles.map(
              (tile, index) =>
                tile.amount !== undefined && (
                  <ClientPlxTile
                    tilesQuantity={tiles.length}
                    key={index}
                    label={tile.label}
                    amount={tile.amount}
                  />
                )
            )
          : tiles.map(
              (tile, index) =>
                tile.amount !== undefined && (
                  <PlxTile
                    tilesQuantity={tiles.length}
                    key={index}
                    label={tile.label}
                    amount={tile.amount}
                    editable={tile.editable}
                    formControlName={tile.formControlName}
                    campaignStatus={campaignStatus}
                    onChange={onTileChange}
                  />
                )
            )}
      </Tiles>
    </Wrapper>
  );
};
