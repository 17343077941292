import React, { FC } from 'react';
import { SelectClient } from '../../Spectrum/_Routes/AgencyClients/SelectClient';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import {
  ButtonWrapper,
  StyledButton,
  StyledSelectClient,
} from '_Routes/Auth/Agency/StylesAg';
import { useTranslation } from 'react-i18next';

interface IProps {
  campaignLenght: number;
  selectClientModal: boolean;
  closeEditDialog: () => void;
  clientSelected: string | null;
  pushToNewCampaing: () => void;
}

export const SelectClientPopup: FC<IProps> = ({
  campaignLenght,
  selectClientModal,
  closeEditDialog,
  clientSelected,
  pushToNewCampaing,
}) => {
  const { t } = useTranslation();
  return (
    <PlxDialog
      title={'Select client'}
      open={selectClientModal}
      onClose={closeEditDialog}
    >
      <StyledSelectClient>
        <SelectClient campaignLenght={campaignLenght} />
        <ButtonWrapper>
          <StyledButton
            style={{
              marginLeft: 'auto',
              pointerEvents: clientSelected === null ? 'none' : undefined,
            }}
            onClick={pushToNewCampaing}
          >
            {t('agency.submit')}
          </StyledButton>
        </ButtonWrapper>
      </StyledSelectClient>
    </PlxDialog>
  );
};
