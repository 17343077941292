import { FC } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

interface IProps {
  color?: 'secondary' | 'primary';
}
const Wrapper = styled.div`
  display: flex;
`;

const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
`;

export const PlxLoader: FC<IProps> = ({ color = 'secondary' }) => {
  return (
    <Wrapper>
      <StyledCircularProgress color={color} />
    </Wrapper>
  );
};
