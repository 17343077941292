import React, { FC, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AuthClient } from './Auth/Client/AuthClient';
import { Home } from '_Routes/Home/Home';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { AuthDistributionPartner } from './Auth/DistributionPartner/AuthDistributionPartner';
import { AuthAgency } from './Auth/Agency/AuthAgency';

export const RoutesSwitcher: FC = () => {
  const { token } = useSelector((state: IState) => state.auth);
  const location = useLocation();
  const isFinfluencerLocation = location.pathname.includes('finfluencer');
  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        const elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 1000);
  }, [location]);
  return (
    <Switch>
      {token && (
        <Route path="/home">
          <Home />
        </Route>
      )}
      <Route path="/auth/finfluencer">
        <AuthDistributionPartner />
      </Route>
      <Route path="/auth/distribution-partner">
        <Redirect to="/auth/finfluencer/login" />
      </Route>
      <Route path="/auth/agency">
        <AuthAgency />
      </Route>
      <Route path="/auth">
        <AuthClient />
      </Route>
      <Route>
        {isFinfluencerLocation ? (
          <Redirect
            to={{
              pathname: '/auth/finfluencer/login',
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )}
      </Route>
    </Switch>
  );
};
