import React, { FC, useRef } from 'react';
import {
  IDialogProps,
  PlxDialog,
} from '_Components/Dialogs/PlxDialog/PlxDialog';
import styled from '@emotion/styled';
import { ICampaignNotification } from '_State/Thrive/types';
import { ReactComponent as DownloadIcon } from './Download.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InstructionsWrapper = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  width: 100%;
  height: 14rem;
  overflow-y: auto;
  padding: 1rem 2rem;
  font-size: 14px;
  line-height: 30px;
`;

const PresentationWrapper = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  width: 100%;
  height: 6.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const StyledIcon = styled(DownloadIcon)`
  cursor: pointer;
  margin-right: 1.7rem;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 3.5rem 2rem 3.5rem;
`;

const PresentationName = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #3c6ef6;
  margin: 1.7rem 0 1.7rem 1.7rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionTitle = styled.div`
  color: #1b1b1b;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

interface IProps extends IDialogProps {
  campaignNotification?: ICampaignNotification;
}
export const ClientInstructionsDialog: FC<IProps> = ({
  open,
  onClose,
  campaignNotification,
  title,
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  return (
    <PlxDialog closeButton open={open} onClose={onClose} title={title}>
      <Wrapper>
        <SectionWrapper>
          <SectionTitle>Instructions</SectionTitle>
          <InstructionsWrapper>
            {campaignNotification?.instructions}
          </InstructionsWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <SectionTitle>Investor Presentation</SectionTitle>
          <PresentationWrapper>
            <PresentationName
              href={campaignNotification?.thrivePresentation?.screenshotUrl}
              download
              target={'_blank'}
              rel="noopener noreferrer"
              ref={anchorRef}
            >
              {campaignNotification?.thrivePresentation?.fileName}
            </PresentationName>
            <StyledIcon
              onClick={() => anchorRef.current && anchorRef.current.click()}
            />
          </PresentationWrapper>
        </SectionWrapper>
      </Wrapper>
    </PlxDialog>
  );
};
