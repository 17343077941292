import React, { FC } from 'react';
import { SpectrumDashboard } from '_Routes/Home/_routes/Spectrum/_Routes/SpectrumDashboard/SpectrumDashboard';
import { useSelector } from 'react-redux';
import { isAdminTypeRole } from '_Routes/Home/NavigationPanel/NavigationLinks';
interface IProps {
  id: string;
}

export const ClientAnalytics: FC = () => {
  const queryParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  const userId = queryParams.get('userId');
  const isAdmin = useSelector(isAdminTypeRole);

  return (
    <SpectrumDashboard
      userId={userId ? Number(userId) : undefined}
      isAdmin={isAdmin}
    />
  );
};
