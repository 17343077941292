import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  ISimpleDialogProps,
  PlxSimpleDialog,
} from '_Components/Dialogs/PlxSimpleDialog';
import { IThemed } from '_Styles/types';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 43rem;
`;

const Title = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 2.1rem;
  font-weight: bold;
  margin: auto;
`;

const Buttons = styled.div`
  margin: 5rem auto 3rem auto;
`;

const StyledButton = styled(PlxButton)`
  && {
    width: 12rem;
  }
`;

const Text = styled.div`
  color: #767676;
  font-size: 1.6rem;
  margin: 2rem auto;
  text-align: center;
`;

interface IProps extends ISimpleDialogProps {
  onSubmit: () => void;
}

export const ConfirmRequestDialog: FC<IProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <PlxSimpleDialog open={open} onClose={onClose}>
      <InfoWrapper>
        <Title>{t('thrive.dashboard.areYouSure')}</Title>
        <Text>{t('thrive.dashboard.confirmText')}</Text>
        <Buttons>
          <StyledButton
            onClick={onClose}
            label={t('thrive.dashboard.goBack')}
          />
          <StyledButton
            onClick={onSubmit}
            variant={ButtonVariant.Outlined}
            label={t('thrive.dashboard.submit')}
          />
        </Buttons>
      </InfoWrapper>
    </PlxSimpleDialog>
  );
};
