import React, { FC } from 'react';
import styled from '@emotion/styled';

const StyledAuthText = styled.span`
  margin-top: 1rem;
  line-height: 1.8rem;
  font-size: 1.2rem;
  color: #808285;
  font-weight: 500;
  white-space: pre-line;
`;

export const SubParagraph: FC<any> = ({ paragraph }) => {
  return <StyledAuthText>{paragraph}</StyledAuthText>;
};
