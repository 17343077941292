import { IMapCountry } from '../types';
import './map.css';
import { PathOptions } from 'leaflet';

const mapStyle = (styles: PathOptions = {}) => {
  const defaults = {
    stroke: true,
    color: 'white',
    weight: 0.5,
    fill: true,
    fillColor: '#CECECE',
    fillOpacity: 1,
  };

  return {
    ...defaults,
    ...styles,
  };
};

const createPopup = (country: IMapCountry) => {
  const popup = document.createElement('div');
  popup.className = 'map-popup-content';
  const text = document.createTextNode(`${country.name} - ${country.value}`);
  popup.append(text);
  return popup;
};

export const prepareCountryFeatures = (
  geoJsonLayer: L.GeoJSON,
  countries: IMapCountry[]
) => {
  geoJsonLayer.eachLayer(function (layer: any) {
    const { iso_a3 } = layer.feature.properties;
    const country = countries.find(({ isoAlpha3 }) => isoAlpha3 === iso_a3);
    if (country) {
      const popup = createPopup(country);
      // TODO: find out whether need to free resources for popups
      layer.bindPopup(popup);
      layer.setStyle(mapStyle({ fillColor: '#3C6EF6' }));
    } else {
      layer.setStyle(mapStyle());
    }
  });
};
