import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InfoWrapper = styled.div`
  padding: 0 3rem 3rem;
`;

const HeaderTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledText = styled.p`
  margin-bottom: 1rem;
`;

const StyledList = styled.ul`
  list-style-type: disc;
`;

const StyledThemeList = styled.ul`
  list-style-type: circle;
  width: 70%;
  column-count: 3;
  margin: 1rem 0;
`;

const StyledListItem = styled.li`
  margin-left: 2rem;
  margin-bottom: 0.5rem;
`;

const StyledButtonShow = styled.button`
  border: none;
  background: transparent;
  color: #006aff;
  cursor: pointer;
  padding: 0;
`;

const StyledButtonHide = styled.button`
  border: none;
  background: transparent;
  color: #006aff;
  cursor: pointer;
  padding: 0;
  margin-top: 1.5rem;
`;

export const InfoSection = () => {
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const { t } = useTranslation();

  return (
    <InfoWrapper>
      {!showDetails && (
        <StyledButtonShow
          onClick={() => {
            setShowDetails((prev) => !prev);
          }}
        >
          {t('referrals.referralsDetails.showDetails')} <span>&#x25BC;</span>
        </StyledButtonShow>
      )}

      {showDetails && (
        <>
          <HeaderTitle>
            {t('referrals.referralsDetails.inviteHeader')}
          </HeaderTitle>
          <StyledText>{t('referrals.referralsDetails.text1')}</StyledText>
          <StyledText>{t('referrals.referralsDetails.text2')}</StyledText>

          <HeaderTitle>
            {t('referrals.referralsDetails.qualifiedInfluencerHeader')}
          </HeaderTitle>
          <StyledList>
            <StyledListItem>
              {t('referrals.referralsDetails.followers')}
            </StyledListItem>
            <StyledListItem>
              {t('referrals.referralsDetails.brandSafe')}
              <StyledThemeList>
                <StyledListItem>
                  {t('referrals.referralsDetails.stocks')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.investing')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.trading')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.crypto')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.bitcoin')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.copyTrading')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.dayTrading')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.financialLiteracy')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.budgeting')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.credit')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.debt')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.highNetWorth')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.cpa')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.tax')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.technology')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.law')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.esg')}
                </StyledListItem>
                <StyledListItem>
                  {t('referrals.referralsDetails.b2b')}
                </StyledListItem>
              </StyledThemeList>
            </StyledListItem>
            <StyledListItem>
              {t('referrals.referralsDetails.activeAccount')}
            </StyledListItem>
          </StyledList>
          <StyledButtonHide
            onClick={() => {
              setShowDetails((prev) => !prev);
            }}
          >
            {t('referrals.referralsDetails.hideDetails')} <span>&#x25B2;</span>
          </StyledButtonHide>
        </>
      )}
    </InfoWrapper>
  );
};
