import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { ICommonProps } from '_Types/props';

interface IProps extends ICommonProps {
  placeholder?: string;
  fullWidth?: boolean;
  label?: string;
  title?: string;
  tooltip?: string;
  value?: any;
  fieldName: string;
  required?: boolean;
  rows?: number;
  type?: string;
  typeNumber?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  rowsMax?: number;
  multiline?: boolean;
  endAdornment?: ReactNode;
  handleChange?: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleNumberChange?: (value: number) => void;
  mask?: boolean;
  disabled?: boolean;
  testId?: string;
}
export const InputSection: FC<IProps> = ({
  value,
  tooltip,
  title,
  required,
  type,
  typeNumber,
  fieldName,
  placeholder,
  label,
  rows,
  rowsMax,
  multiline = false,
  endAdornment,
  fullWidth = true,
  handleChange,
  handleNumberChange,
  className,
  setFieldValue,
  mask,
  disabled,
  testId,
}) => {
  const [inputValue, setInputValue] = useState<unknown>('');

  useEffect(() => {
    if (value) {
      setInputValue(typeNumber ? (value as number) : value);
    }
  }, [value, typeNumber]);

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(fieldName, inputValue);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (typeNumber) {
      value = value.replace(/\D/, '');
      setInputValue(value);
      if (handleChange) {
        event.target.value = value;
        handleChange(event);
      }
    } else {
      setInputValue(value);
      if (handleChange) {
        handleChange(event);
      }
    }
  };

  const handleDownArrowClick = () => {
    const valToDecrease = inputValue ? +(inputValue as number) : 0;
    setInputValue(valToDecrease - 1);
    handleNumberChange && handleNumberChange(valToDecrease - 1);
  };

  const handleUpArrowClick = () => {
    const valToIncrease = inputValue ? +(inputValue as number) : 0;
    setInputValue(valToIncrease + 1);
    handleNumberChange && handleNumberChange(valToIncrease + 1);
  };

  return (
    <FormSection
      className={className}
      title={title}
      tooltip={tooltip}
      required={required}
      testId={testId}
    >
      <PlxInput
        disabled={disabled}
        value={inputValue}
        fullWidth={fullWidth}
        multiline={multiline}
        type={type}
        typeNumber={typeNumber}
        rows={rows}
        rowsMax={rowsMax}
        name={fieldName}
        onChange={onChange}
        onUpArrowClick={handleUpArrowClick}
        onDownArrowClick={handleDownArrowClick}
        label={label}
        placeholder={placeholder}
        endAdornment={endAdornment}
        mask={mask}
      />
    </FormSection>
  );
};
