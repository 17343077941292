import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ConfirmInput } from '_Routes/Home/_routes/Profile/AccountInformation/ConfirmInput';
import { updateUserData } from '_State/User/Api/user.api';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { getUserData } from '_Api/auth';
import { userActions } from '_State/User/user.state';
import { useDispatch } from 'react-redux';

const AccountInformationBody = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`;

const InputsWrapper = styled.div`
  height: 29rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 3.5rem;
`;

export const AccountInformation: FC = () => {
  const [fullName, setFullName] = useState('');
  const { t } = useTranslation();
  const { user } = useSelector((state: IState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setFullName(`${user?.firstName} ${user?.lastName}`);
  }, [user]);

  const handleConfirm = async (value: string) => {
    setFullName(value);
    const [firstName, lastName] = value.replace(/\s+/, '\x01').split('\x01');
    updateUserData({ firstName, lastName }).then(() => {
      getUserData().then((user) => {
        dispatch(userActions.userLogin(user));
      });
    });
  };

  return (
    <AccountInformationBody>
      <PlxInfoPanel label={t('profile.accountInformation')}>
        <InputsWrapper>
          <ConfirmInput fullName={fullName} onConfirm={handleConfirm} />
          <Tooltip title={t('profile.companyNameTooltip') as string}>
            <div>
              <PlxInput
                disabled
                label={t('profile.companyName')}
                value={user?.companyName}
              />
            </div>
          </Tooltip>
          <Tooltip title={t('profile.emailTooltip') as string}>
            <div>
              <PlxInput
                disabled
                value={user?.email}
                label={t('profile.email')}
              />
            </div>
          </Tooltip>
        </InputsWrapper>
      </PlxInfoPanel>
    </AccountInformationBody>
  );
};
