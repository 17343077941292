import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { PlxTooltip } from '_Components/PlxTooltip/PlxTooltip';

const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 2rem 2rem 0 0;
  max-width: 36rem;
`;

const Label = styled.div<IThemed>`
  font-size: 1.4rem;
  margin: auto;
  display: flex;
  text-align: right;
  justify-content: flex-end;
`;

const Value = styled.div`
  font-size: 3rem;
  margin: 0 0.5rem 0 2rem;
  font-weight: 500;
`;

export interface ISliderInfoProps {
  label: string;
  value?: string;
  tooltipText?: string;
}

export const SliderInfo: FC<ISliderInfoProps> = ({
  label,
  value,
  tooltipText,
}) => {
  return (
    <Wrapper>
      <Label>{label}:</Label>
      <Value>{value}</Value>
      {tooltipText && <PlxTooltip tooltipText={tooltipText} />}
    </Wrapper>
  );
};
