/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxTilesPanel } from '_Components/Panels/PlxTilesPanel/PlxTilesPanel';
import { getThriveCampaigns } from '_State/Thrive/Api/thrive.api';
import { ITile } from '_Types/ITile';
import { useTranslation } from 'react-i18next';
import { createThriveStatisticsConfig } from '_App/utils';
import { ICampaignsQueryParams } from '_Types/campaigns';

const ThriveDashboardStatisticsBody = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
`;

const INITIAL_PARAMS: ICampaignsQueryParams = {
  campaignStatusList: ['COMPLETED', 'LAUNCHED', 'PAUSED'],
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  tagIdList: [],
};

export const ThriveDashboardStatistics: FC = () => {
  const [tilesConfig, setTilesConfig] = useState<ITile[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    getThriveCampaigns(INITIAL_PARAMS).then((campaigns) => {
      setTilesConfig(
        createThriveStatisticsConfig(campaigns.content).map((tile) => {
          return { ...(tile as ITile), label: t((tile as ITile).label) };
        }) as ITile[]
      );
    });
    // eslint-disable-next-line
  }, []);
  return (
    <ThriveDashboardStatisticsBody>
      <PlxTilesPanel tiles={tilesConfig} />
    </ThriveDashboardStatisticsBody>
  );
};
