import { css } from '@emotion/core';

export const globalStyles = css`
  html,
  body,
  div#root {
    height: 100%;
    *::placeholder {
      color: #abb1b6;
      opacity: 1;
    }
    @media (max-width: 600px) {
      padding-top: 2.5rem;
    }
  }

  body {
    font-family: 'Quicksand', sans-serif;
  }
`;
