import React, { FC, useContext, useEffect, useState } from 'react';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import {
  EmptyContainer,
  SectionHeader,
  TabPageHeader,
  TrimString,
} from '../../utils';
import { ReactComponent as PayPal } from './../../../../../../_Styles/images/paypal-logo.svg';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import {
  CurrentBalance,
  PMHeader,
  PaymentMethods,
  // AddPaymentMethods,
  PaymentDetails,
  PaymentDetailsWrapper,
  CardDetailsContainer,
  CardHeader,
  AccNumber,
  OptionsContainer,
  CardOptions,
  SectionWrap,
  AddPaymentMethodsWrap,
  Title,
  Buttons,
  StyledButton,
  WarningMessage,
  WalletLabel,
  WalletButton,
  XemTextField,
  DialogWrapper,
  ErrHeader,
  ErrNote,
} from './FinfluencerWalletStyles';
import {
  createWallet,
  deleteWallet,
  getRecentTransactions,
  getWallets,
  getWalletTotal,
  updateWalletById,
  updateWalletNameById,
} from '_State/Spectrum/Api/spectrum.api';
import { orderBy } from 'lodash';
import { PlxSimpleDialog } from '_Components/Dialogs/PlxSimpleDialog';
import { XemTableHeader } from '_Components/TableHeader/XemTableHeader';
import { XPropWallet, XPropTransactions } from './utils';
import { XemTableRow } from '_Components/XemTableRow/XemTableRow';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { PlxText } from '_Components/Text/PlxText';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { Dialog } from '@material-ui/core';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import CancelIcon from '@material-ui/icons/Cancel';
import { red } from '@material-ui/core/colors';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';

export const tableHeaderOptions = [
  'Date',
  'Id',
  'Wallet Name',
  'Channel',
  'Campaign',
  'Status',
  'Amount',
];
const renderWalletLogo = (walletName: string) => {
  switch (walletName) {
    case 'PAYPAL':
      return <PayPal />;

    default:
      break;
  }
};
export const FinfluencerWallet: FC = () => {
  const [wallets, setWallets] = useState<XPropWallet[]>([]);
  const [walletBalance, setWalletBalance] = useState<number>();
  const [walletName, setWalletName] = useState<string>('');
  const [transactions, setTransactions] = useState<XPropTransactions[]>();
  const [editForWalletId, setEditForWalletId] = useState<number>();
  const { navBarExpanded } = useContext(NavigationContext);
  const { t } = useTranslation();
  const [
    deleteConfirmationDialogOpen,
    setDeleteConfirmationDialogOpen,
  ] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const getAllWallets = () =>
    getWallets().then((res) => {
      const sortRes = orderBy(res, 'walletId', 'desc');
      setWallets(orderBy(sortRes, 'defaultWallet', 'desc'));
    });

  const makeDefault = (id: number) => {
    updateWalletById(id).then((res) => {
      getAllWallets();
    });
  };
  const delWallet = (id: number) => {
    deleteWallet(id).then((res) => {
      getAllWallets();
      setDeleteConfirmationDialogOpen(false);
      if (wallets.length === 6) {
        window.location.reload();
      }
    });
  };
  const handleAddEditName = (id: number) => {
    setEditForWalletId(id);
  };

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      window?.paypal.use(['login'], function (login) {
        login.render({
          appid:
            'ARv55AqfFB7JNsbkW3oCf2tVgxBU-wy9cd5dXjdM_HmoANCWA58_lII38ru--FpASRSN4SiFfKRB_zLJ',
          scopes:
            'openid email https://uri.paypal.com/services/paypalattributes',
          containerid: 'logBtn',
          responseType: 'code',
          locale: 'en-us',
          buttonType: 'LWP',
          buttonShape: 'pill',
          buttonSize: 'sm',
          fullPage: 'true',
          returnurl: `https://${
            window.location.href.includes('dev')
              ? 'dev'
              : window.location.href.includes('staging')
              ? 'staging'
              : 'app'
          }.xemotomedia.com/home/finfluencer/wallet/`,
        });
      });
    }, 1000);
  }, []);

  useEffect(() => {
    const queryParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const code = queryParams.get('code');
    if (code) {
      createWallet(code, wallets)
        .then((res) => {
          getAllWallets();
        })
        .catch((error) => {
          console.error(error);
          setPopupOpen(true);
        });
    }
    getAllWallets();
    getWalletTotal().then((res) => {
      setWalletBalance(res.value);
    });
    // eslint-disable-next-line
  }, []);
  const handleWallerName = (id: number) => {
    walletName &&
      updateWalletNameById(id, walletName).then((res) => {
        getAllWallets().then(() => {
          setWalletName('');
          setEditForWalletId(undefined);
        });
      });
  };
  const handleClose = () => setPopupOpen(false);
  useEffect(() => {
    getRecentTransactions().then((res) => {
      const sortRes = orderBy(res, 'approvedDate', 'desc');
      setTransactions(sortRes);
    });
  }, []);

  return (
    <Body isExpanded={navBarExpanded}>
      <TabPageHeader name="Wallet" />
      <CurrentBalance>
        <div>
          <div>{t('wallet.currentBalance')}</div>
          <div>
            <span
              style={{
                fontSize: '2.3rem',
                fontWeight: 600,
                lineHeight: '3rem',
              }}
            >
              USD $
              {walletBalance && <StandardizeAmount amount={walletBalance} />}
            </span>
          </div>
        </div>
      </CurrentBalance>
      <PaymentMethods>
        <PMHeader>
          <SectionHeader>{t('wallet.paymentMethods')}</SectionHeader>

          {/* <a href="/home/finfluencer/wallet/add-payment-method"> */}
          {/* <AddPaymentMethods variant2> */}
          {wallets.length < 6 ? (
            <AddPaymentMethodsWrap>
              <span>
                <AddIcon />
                {t('wallet.add')}
              </span>
              <span id="logBtn"></span>
            </AddPaymentMethodsWrap>
          ) : (
            <WarningMessage>{t('wallet.warningMessage')}</WarningMessage>
          )}
          {/* </AddPaymentMethods> */}
          {/* </a> */}
        </PMHeader>
        <PaymentDetailsWrapper>
          {wallets && wallets.length ? (
            wallets.map(
              ({ walletId, defaultWallet, paymentMethod, email, label }) => {
                return (
                  <React.Fragment key={walletId}>
                    <PaymentDetails defaultWallet={defaultWallet}>
                      <CardDetailsContainer>
                        {editForWalletId === walletId ? (
                          <>
                            <XemTextField
                              name="Wallet Name"
                              onChange={(e) => setWalletName(e.target.value)}
                              placeholder="Wallet Name"
                              autoFocus
                            />
                            <div>
                              <WalletButton
                                type="submit"
                                value="Cancel"
                                onClick={() => setEditForWalletId(undefined)}
                              ></WalletButton>
                              <WalletButton
                                type="submit"
                                value="Submit"
                                onClick={() => handleWallerName(walletId)}
                              ></WalletButton>
                            </div>
                          </>
                        ) : (
                          <>
                            <WalletLabel>{label.toUpperCase()}</WalletLabel>
                            <CardHeader>
                              <span>{renderWalletLogo(paymentMethod)}</span>
                            </CardHeader>
                            <AccNumber>{email}</AccNumber>
                          </>
                        )}
                      </CardDetailsContainer>
                      {editForWalletId !== walletId && (
                        <OptionsContainer>
                          <>
                            <div>
                              <CardOptions
                                onClick={() => handleAddEditName(walletId)}
                              >
                                {t('wallet.edit')}
                              </CardOptions>
                              {!defaultWallet && (
                                <CardOptions
                                  onClick={() =>
                                    setDeleteConfirmationDialogOpen(true)
                                  }
                                >
                                  {t('wallet.delete')}
                                </CardOptions>
                              )}
                            </div>
                            {!defaultWallet && (
                              <div onClick={() => makeDefault(walletId)}>
                                <CardOptions>
                                  {t('wallet.makeDefault')}
                                </CardOptions>
                              </div>
                            )}
                          </>
                        </OptionsContainer>
                      )}
                    </PaymentDetails>
                    <PlxSimpleDialog
                      open={deleteConfirmationDialogOpen}
                      onClose={() => setDeleteConfirmationDialogOpen(false)}
                    >
                      <Title>{t('wallet.deleteConfirmationTitle')}</Title>
                      <Buttons>
                        <StyledButton
                          onClick={() => setDeleteConfirmationDialogOpen(false)}
                          label={t('channels.deleteConfirmation.cancel')}
                        />
                        <StyledButton
                          onClick={() => delWallet(walletId)}
                          label={t('channels.deleteConfirmation.delete')}
                        />
                      </Buttons>
                    </PlxSimpleDialog>
                  </React.Fragment>
                );
              }
            )
          ) : (
            <EmptyContainer>{t('dashboard.emptyWallet')}</EmptyContainer>
          )}
        </PaymentDetailsWrapper>
      </PaymentMethods>
      <SectionWrap>
        <SectionHeader>{t('wallet.recentTransaction')}</SectionHeader>
        {transactions?.length ? (
          <>
            <XemTableHeader headers={tableHeaderOptions} />
            {transactions.map(
              ({
                approvedDate,
                transactionId,
                amount,
                finfluencerPayoutEmail,
                campaignId,
                transactionSuccess,
                channelName,
              }) => {
                return (
                  <XemTableRow key={transactionId}>
                    <div>{formatDate(approvedDate)}</div>
                    <div>{transactionId}</div>
                    <div>
                      <PlxText tooltipText={finfluencerPayoutEmail}>
                        <TrimString
                          paragraph={finfluencerPayoutEmail}
                          maxLetters={25}
                        ></TrimString>
                      </PlxText>
                    </div>
                    <div>{channelName}</div>
                    <div>{campaignId}</div>
                    <div>
                      {transactionSuccess ? (
                        <span style={{ color: 'green' }}>Successful</span>
                      ) : (
                        <span style={{ color: '#FF8C00' }}>Processing</span>
                      )}
                    </div>
                    <div>
                      USD $<StandardizeAmount amount={amount} />
                    </div>
                  </XemTableRow>
                );
              }
            )}
          </>
        ) : (
          <EmptyContainer>{t('dashboard.emptyLastTransaction')}</EmptyContainer>
        )}
      </SectionWrap>
      <Dialog onClose={handleClose} open={popupOpen}>
        <DialogWrapper>
          <div>
            <CancelIcon
              style={{
                color: red[500],
                fontSize: 40,
                margin: 'auto',
                display: 'flex',
              }}
            />
          </div>
          <ErrHeader>{t('wallet.errorMessage1')}</ErrHeader>
          <ErrNote>
            <div>{t('wallet.errorMessage2')}</div>
            <ul style={{ marginLeft: '1rem' }}>
              <li>{t('wallet.errorMessage3')}</li>
              <li>
                {t('wallet.errorMessage4')}
                {'. '}
                <a
                  href="https://www.paypal.com/ck/smarthelp/article/how-do-i-verify-my-paypal-account-faq444"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('wallet.learnMore')}
                </a>
              </li>
            </ul>
            <div style={{ paddingTop: '.5rem' }}>
              {t('wallet.errorMessage5')}
            </div>
          </ErrNote>
          <div style={{ margin: 'auto' }}>
            <PlxButtonX label="Close" onClick={handleClose} />
          </div>
        </DialogWrapper>
      </Dialog>
    </Body>
  );
};
