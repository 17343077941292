import React, { ChangeEvent, FC, FocusEvent } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';
import MaskedInput from 'react-text-mask';

const Input = styled(TextField)<IThemed>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
  input {
    padding: 9px 14px;
  }
  fieldset {
    border: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const renderMaskInput = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
};

export interface IInputProps extends ICommonProps {
  value?: unknown;
  defaultValue?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  placeholder?: string;
  label?: string;
  typeNumber?: boolean;
  type?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: any;
  multiline?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  fullWidth?: boolean;
  margin?: 'dense' | 'none';
  onUpArrowClick?: () => void;
  onDownArrowClick?: () => void;
  onFocus?: () => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  mask?: boolean;
}
export const PlxInputAddChannelX: FC<IInputProps> = ({
  value,
  defaultValue,
  placeholder,
  label,
  className,
  name,
  required = false,
  onChange,
  disabled,
  endAdornment,
  multiline,
  rows,
  rowsMax,
  fullWidth = true,
  margin,
  type,
  onFocus,
  onBlur,
  mask,
}) => {
  return (
    <Wrapper className={className}>
      <Input
        margin={margin}
        disabled={disabled}
        value={value}
        className={className}
        placeholder={placeholder}
        label={label}
        type={type}
        variant={'outlined'}
        defaultValue={defaultValue}
        name={name}
        required={required}
        onChange={onChange}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        onFocus={onFocus}
        onBlur={onBlur}
        // InputLabelProps={{
        //   shrink: false,
        // }}
        InputProps={{
          inputComponent: mask ? (renderMaskInput as any) : 'input',
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
    </Wrapper>
  );
};
