import React, { ReactElement, useEffect, useState } from 'react';
import '_App/App.css';
import { GlobalProviders } from '_App/GlobalProviders';
import { BrowserRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { RoutesSwitcher } from '_Routes/RoutesSwitcher';
import { loadUserFromToken } from '_State/thunks';
import { PlxLoader } from '_Components/PlxLoader';

const MainScene = styled.div<IThemed>`
  display: flex;
  height: 100%;
  background-color: ${({ theme }): string => theme.palette.background.default};
`;

const MainContainer = styled.div<IThemed>`
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }): string => theme.palette.background.default};
`;

function App(): ReactElement {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    loadUserFromToken().then(() => {
      setMounted(true);
    });
  }, []);

  return (
    <>
      {mounted ? (
        <GlobalProviders>
          <BrowserRouter>
            <MainScene>
              <MainContainer>
                <RoutesSwitcher />
              </MainContainer>
            </MainScene>
          </BrowserRouter>
        </GlobalProviders>
      ) : (
        <PlxLoader color={'primary'} />
      )}
    </>
  );
}

export default App;
