import React, { FC } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  id?: number;
  path?: string;
  isDisabled?: boolean;
}

const StyledButton = styled(PlxButton)`
  && {
    height: 3.5rem;
  }
`;

export const ButtonTableCell: FC<IProps> = ({ id, path, isDisabled }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <StyledButton
      isDisabled={isDisabled}
      label={t('campaignsTable.seeReport')}
      onClick={() => {
        history.push(`${path}${id}`);
      }}
      variant={ButtonVariant.Outlined}
    />
  );
};
