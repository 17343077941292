import React, { FC } from 'react';
import {
  DataGrid,
  GridFeatureMode,
  GridFilterModelParams,
  GridPageChangeParams,
  GridRowsProp,
  GridSelectionModelChangeParams,
} from '@material-ui/data-grid';
import styled from '@emotion/styled';
import { GridOverlay, GridColumns } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactElement } from 'react';

function CustomLoadingOverlay(): ReactElement {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
export const XemTableHeader = styled.div`
  display: flex;
  background: #fff;
  border: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
`;
export const Title = styled.div`
  font-size: 2rem;
`;
interface IProps {
  header: GridColumns;
  data: GridRowsProp;
  pageSize?: number;
  onPageSizeChange?: (param: GridPageChangeParams) => void;
  checkbox?: boolean;
  handleRowSelection?: (param: GridSelectionModelChangeParams) => void;
  density?: 'compact' | 'standard' | 'comfortable';
  title?: string;
  loading?: boolean;
  page?: number;
  rowCount?: number;
  pagination?: true | undefined;
  onPageChange?: (param: GridPageChangeParams) => void;
  paginationMode?: GridFeatureMode;
  filterMode?: GridFeatureMode;
  onFilterModelChange?: (params: GridFilterModelParams) => void;
}
export const XemTable: FC<IProps> = ({
  header,
  data,
  pageSize = 20,
  onPageSizeChange,
  checkbox,
  handleRowSelection,
  density,
  title,
  loading,
  page,
  pagination,
  rowCount,
  onPageChange,
  paginationMode,
  filterMode,
  onFilterModelChange,
}) => {
  return (
    <div
      style={{
        height:
          pageSize >= 50 ? 40 * pageSize : (!density ? 59 : 45.4) * pageSize,
        width: '100%',
        background: '#fff',
        marginBottom: '4rem',
      }}
    >
      {!!title && (
        <XemTableHeader>
          <Title>{title}</Title>
        </XemTableHeader>
      )}
      <DataGrid
        rows={loading ? [] : data}
        columns={header}
        checkboxSelection={checkbox}
        density={density}
        disableSelectionOnClick
        onSelectionModelChange={handleRowSelection}
        loading={loading}
        pagination={pagination}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageSizeChange={onPageSizeChange}
        page={page}
        rowCount={rowCount}
        onPageChange={onPageChange}
        paginationMode={paginationMode}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
      />
    </div>
  );
};
