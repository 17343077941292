import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import {
  approveReferralPayout,
  getReferrals,
  manualReferralPayout,
} from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import {
  IDistributionPartnerReferralsDTO,
  IAdminReferralQueryParams,
} from '_State/DistributionPartner/distributionPartner.types';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxLoader } from '_Components/PlxLoader';
import { Dialog } from '@material-ui/core';
import { ReferralStatus } from '_State/DistributionPartner/distributionPartner.enums';
import { XemTable } from '_Components/XemTable/XemTable';
import Paper from '@material-ui/core/Paper';
import {
  GridColumns,
  GridFilterModelParams,
  GridPageChangeParams,
} from '@material-ui/data-grid';
import { createReferralsTableConfig } from './utils';
import { ManualPayoutConfirmationDialog } from '../Spectrum/AdminSpectrumCampaign/ManualPayoutConfirmationDIalog';
import { ApprovePayoutConfirmationDialog } from './ApprovePayoutConfirmationDIalog';

const sortingOptions = [
  {
    id: 'ASC',
    name: 'Oldest to newest',
  },
  {
    id: 'DESC',
    name: 'Newest to oldest',
  },
];

const referralStatusOptions = [
  {
    id: ReferralStatus[ReferralStatus.APPROVED],
    name: 'Approved',
  },
  {
    id: ReferralStatus[ReferralStatus.IN_PROGRESS],
    name: 'In progress',
  },
  {
    id: ReferralStatus[ReferralStatus.VERIFIED],
    name: 'Verified',
  },
  {
    id: ReferralStatus[ReferralStatus.COMPLETED],
    name: 'Completed',
  },
  {
    id: ReferralStatus[ReferralStatus.CREATED_PAYOUT],
    name: 'Payout Created',
  },
  {
    id: ReferralStatus[ReferralStatus.COMPLETED_MANUL_PAYOUT],
    name: 'Manual payout Completed',
  },
];
const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 15rem;
  }
`;

const StyledInput = styled(PlxInput)`
  && {
    margin-left: auto;
    width: 31rem;
    margin-right: 3.5rem;
  }
`;

const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

const INITIAL_PARAMS = {
  pageSize: 20,
};

export const ReferralsTable: FC = () => {
  const { t } = useTranslation();
  const [config, setConfig] = useState<GridColumns>([]);
  const [params, setParams] = useState<IAdminReferralQueryParams>(
    INITIAL_PARAMS
  );
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<string>('DESC');
  const [referralName, setReferralName] = useState<string>('');
  const [manualPayoutId, setManualPayoutId] = useState('');
  const [approvePayoutId, setApprovePayoutId] = useState('');

  const [referralStatusList, setReferralStatusList] = useState<string[]>([]);
  const [referrals, setReferrals] = useState<
    IDistributionPartnerReferralsDTO[]
  >([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState(true);

  const onSortingColumn = (value: string, value2: string): void => {
    setParams({ ...params, sortByField: value, sortDirection: value2 });
  };

  const fetchReferrals = (): Promise<any> => {
    setTableLoading(true);

    return getReferrals(params)
      .then(({ content, totalElements }) => {
        setTotalElements(totalElements);
        setReferrals(content);

        setReferrals(content);
        setConfig(
          createReferralsTableConfig(
            handleManualPay,
            handleApprovePayout,
            onSortingColumn,
            params
          )
        );
        setLoading(false);
        setTableLoading(false);
      })
      .finally(() => setTableLoading(false));
  };

  useEffect(() => {
    fetchReferrals().then();
    // eslint-disable-next-line
  }, [params]);

  const onSortingChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const handleCloseManualPayoutId = (): void => setManualPayoutId('');
  const handleCloseApprovePayoutId = (): void => setApprovePayoutId('');

  const handleManualPay = (payoutId?: string) =>
    payoutId && setManualPayoutId(payoutId);

  const handleApprovePayout = (payoutId?: string) => {
    payoutId && setApprovePayoutId(payoutId);
  };

  const confirmManualPay = () =>
    !!manualPayoutId &&
    manualReferralPayout(manualPayoutId).then(() => {
      setManualPayoutId('');
      fetchReferrals().then(() => setLoading(false));
    });

  const confirmApprovePay = () =>
    !!approvePayoutId &&
    approveReferralPayout(Number(approvePayoutId)).then(() => {
      setApprovePayoutId('');
      fetchReferrals().then(() => setLoading(false));
    });

  const onReferralStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setReferralStatusList(event.target.value as string[]);
    setParams({
      ...params,
      referralStatusList: event.target.value as string[],
    });
  };

  const onReferralNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setReferralName(event.target.value as string);
    setParams({ ...params, fullName: event.target.value as string });
  };

  const handlePageChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageNumber: param.page }));
  };

  const handlePageSizeChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageSize: param.pageSize }));
  };

  const resetFilters = (columns: GridColumns): void => {
    const filters: Record<string, string> = {};

    columns
      .filter((column) => column.filterable)
      .forEach((column) => (filters[String(column.field)] = ''));

    setParams((prevParams) => ({
      ...prevParams,
      ...filters,
    }));
  };

  const handleFilterModelChange = useCallback(
    ({ filterModel, columns }: GridFilterModelParams) => {
      const [{ columnField, value }] = filterModel.items;

      if (!value) {
        return resetFilters(columns);
      }

      setParams({
        ...params,
        pageNumber: 0,
        [String(columnField)]: value || '',
      });
    },
    [params]
  );

  return (
    <Body>
      {loading ? (
        <PlxLoader />
      ) : (
        <Paper>
          <PlxTableToolbar label={t('referrals.table.label')}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
              testId="sorting-select"
            />
            <StyledMultipleSelect
              options={referralStatusOptions}
              label={t('referrals.table.status')}
              onChange={onReferralStatusChange}
              value={referralStatusList}
              testId="status-select"
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('referrals.table.search')}
              value={referralName}
              onChange={onReferralNameChange}
              fullWidth={false}
              testId="search-channel-field"
            />
          </PlxTableToolbar>
          <XemTable
            header={config}
            data={referrals}
            loading={tableLoading}
            pagination
            page={params.pageNumber}
            paginationMode="server"
            pageSize={params.pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowCount={totalElements}
            onPageChange={handlePageChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
          />
          <Dialog
            open={!!manualPayoutId}
            onClose={handleCloseManualPayoutId}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ManualPayoutConfirmationDialog
              handleCloseManualPayoutId={handleCloseManualPayoutId}
              confirmManualPay={confirmManualPay}
            />
          </Dialog>
          <Dialog
            open={!!approvePayoutId}
            onClose={handleCloseApprovePayoutId}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ApprovePayoutConfirmationDialog
              handleCloseApprovePayoutId={handleCloseApprovePayoutId}
              confirmPay={confirmApprovePay}
            />
          </Dialog>
        </Paper>
      )}
    </Body>
  );
};
