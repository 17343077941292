import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { IPaymentForm } from '_Types/paymentContext';

export const buttonOptions = {
  option1: '3 Campaign Posts',
  // option3: '3 Campaign Posts',
  option2: '6 Campaign Posts',
  option3: '12 Campaign Posts',
};

export const packageDetails = [
  // {
  //   packageName: 'Starter',
  //   title: 'Starter',
  //   detail:
  //     'Access and engage the basic Finfluencers in our curated network so retail investors start talking about your investment opportunity.',
  //   socialMediaChannel: ['FACEBOOK', 'TWITTER', 'TIKTOK'],
  //   footer: 'Three social media channels',
  // },
  {
    packageName: 'Business',
    title: 'Business',
    detail:
      'Access the most engaged retail investors across the digital investor ecosystem for maximum reach and engagement.',
    socialMediaChannel: [
      'YOUTUBE',
      'FACEBOOK',
      'INSTAGRAM',
      'X',
      'LINKEDIN',
      'DISCORD',
      'TIKTOK',
      'REDDIT',
    ],
    footer: 'Eight social media channels',
  },
  // {
  //   packageName: 'Premier (Video)',
  //   title: 'Premier',
  //   detail:
  //     'Capture the interest of your target audience in just a few seconds. Our curated network of Finfluencers creates digital video summaries of your news and shares them with their followers of retail investors.',
  //   socialMediaChannel: ['TIKTOK', 'YOUTUBE'],
  //   footer: 'Two social media video channels',
  // },
];

export const INITIAL_VALUES: IPaymentForm = {
  package: '',
  pricingType: '',
  payment: [],
  packageId: undefined,
  programName: '',
  campaignPackage: '',
  acceptClientContract: false,
  tier: '',
  signerIp: '',
  contractViewed: '',
  contractSigned: '',
};

export const initialValues = {
  pricingType: '',
  package: '',
  payment: [],
  promotionalCode: '',
  percentOff: 0,
  cardHolder: '',
  cardNumber: '',
  cvc: '',
  expirationDate: '',
  programName: '',
  campaignPackage: 'Business',
  acceptClientContract: false,
  tier: '',
  signerIp: '',
  contractViewed: '',
  contractSigned: '',
};
const errorMessage = 'Required';
const REQUIRED_FIELDS = ['package', 'payment'];
const requiredFieldsValidation = prepareRequiredFieldsValidation(
  REQUIRED_FIELDS,
  errorMessage
);

export const formValidation = (form: any) => {
  const errors = requiredFieldsValidation(form);

  return errors;
};
interface IProps {
  formik: any;
  discountedValue: number;
  handleCancel: () => void;
}
interface XemButtonOptions {
  option1: string;
  option2: string;
  option3: string;
}
const handlePricingType = (value: any) => {
  let buttonOption;

  if (value === 'QUARTERLY') {
    buttonOption = buttonOptions.option1;
  } else if (value === 'SEMI_ANNUALLY') {
    buttonOption = buttonOptions.option2;
  } else {
    buttonOption = buttonOptions.option3;
  }
  return buttonOption;
};
export const campaignPost = (
  pricingType: string,
  buttonOptions: XemButtonOptions
): string => {
  return handlePricingType(pricingType);
};
