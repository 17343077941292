export interface IVisibility {
  [key: string]: {
    engagement: boolean;
    socialmediaEngagement: boolean;
  };
}

export const initialVisibility: IVisibility = {
  'campaign-1': {
    engagement: true,
    socialmediaEngagement: true,
  },
  'campaign-2': {
    engagement: true,
    socialmediaEngagement: true,
  },
  'campaign-3': {
    engagement: true,
    socialmediaEngagement: true,
  },
};

export type DataColor = {
  name: string;
  data: {
    name: 'socialmediaEngagement' | 'engagement';
    color: string;
  }[];
};

export const dataColors: DataColor[] = [
  {
    name: 'campaign-1',
    data: [
      { name: 'socialmediaEngagement', color: '#9D05B0' },
      { name: 'engagement', color: '#CA85D7' },
    ],
  },
  {
    name: 'campaign-2',
    data: [
      { name: 'socialmediaEngagement', color: '#008B9C' },
      { name: 'engagement', color: '#AFD7DD' },
    ],
  },
  {
    name: 'campaign-3',
    data: [
      { name: 'socialmediaEngagement', color: '#E0B028' },
      { name: 'engagement', color: '#FBE766' },
    ],
  },
];

export const visibilityLabels = {
  engagement: 'Engagement',
  socialmediaEngagement: 'Benchmark Engagement',
};

export const averageTooltip = {
  engagement: 'Campaign Average: ',
  socialmediaEngagement: 'Benchmark Average: ',
};
