import React, { FC, useEffect, useState } from 'react';
import { PlxButtonGroup } from '_Components/Buttons/PlxButtonGroup/PlxButtonGroup';
import { FormSection } from '_Components/FormSection/FormSection';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';

interface IProps {
  buttonsConfig: IIconButtonConfig[];
  multiple?: boolean;
  placeholder?: string;
  inputTitle?: string;
  fullWidth?: boolean;
  onChoose?: (options: string[]) => void;
  title: string;
  otherOption?: boolean;
  tooltip?: string;
  value?: string[];
  setValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName?: string;
  required?: boolean;
  horizontal?: boolean;
  testId?: string;
}

export const ButtonGroupSection: FC<IProps> = ({
  value,
  setValue,
  title,
  otherOption,
  tooltip,
  fieldName,
  buttonsConfig,
  inputTitle,
  placeholder,
  multiple,
  onChoose,
  fullWidth = true,
  required,
  horizontal,
  testId,
}) => {
  const [val, setVal] = useState<string[]>();

  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value]);

  const handleSelect = (options: string[]) => {
    onChoose && onChoose(options);
    setValue &&
      fieldName &&
      setValue(fieldName, multiple ? options : options[0]);
  };
  return (
    <FormSection
      title={title}
      tooltip={tooltip}
      required={required}
      testId={testId}
    >
      <PlxButtonGroup
        horizontal={horizontal}
        value={val}
        options={buttonsConfig}
        onSelect={handleSelect}
        otherOption={otherOption}
        inputTitle={inputTitle}
        inputConfig={{ fullWidth: fullWidth, placeholder: placeholder }}
        multiple={multiple}
      />
    </FormSection>
  );
};
