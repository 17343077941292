import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';

export interface ICampaignData {
  contentCategories: [];
  contentType: string;
  engagement: number;
  id: number;
  numberOfFollowers: number;
  startDate: string;
  tag: string;
}
export interface IChannelData {
  channelType: string;
  engagement: number;
  followers: number;
  reactions: number;
  shares: number;
  startDate: string;
  impressions: number;
  videoSaves: number;
  comments: number;
  clicks: number;
}

export interface PostData {
  campaignId: number;
  channelName: string;
  channelType: string;
  datePosted: string;
  postDetails: string;
  engagement: number;
  reactions: number;
  comments: number;
  shares: number;
  postScreenShot: string;
  tag?: string;
}
export interface ITopFinfluencerData {
  channelName: string;
  reactions: number;
  shares: number;
  comments: number;
  clicks: number;
  startDate: string;
  impressions: number;
  videoSaves: number;
  channelType: string;
  engagement: number;
  firstName: string;
  channelId: number;
  noOfFollowers: number;
  distributionPartnerId: number;
  participatedCampaigns: number;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 750,
      border: 'none',
    },
    tableBody: {
      display: 'grid',
      gap: '1rem',
      gridTemplateColumns: '32.8% 32.8% 32.8%',
      maxHeight: '1080px',
      maxWidth: '1258px',
    },
    tableRow: {
      border: '1px solid #eee',
      '&:hover svg': {
        visibility: 'initial',
      },
    },
    tableRowTopPosts: {
      border: '1px solid #eee',
      borderRadius: '8px',
      // maxWidth: '410px',
    },
    head: {
      background: '#f9f9f9',
      border: '1px solid #eee',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    ul: {
      margin: '1rem',
      display: 'flex',
      gap: '.5rem',
      justifyContent: 'space-around',
    },
    li: {
      flex: '1',
    },
    reach: {
      background: '#eee',
      padding: '1.5rem',
      borderRadius: '8px',
      width: '100px',
      fontWeight: 600,
      lineHeight: '1.8rem',
    },
    postHead: {
      fontWeight: 100,
      fontSize: '1rem',
      color: '#5F5F61',
    },
    postValue: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    postDetails: {
      fontSize: '1.2rem',
      padding: '2rem 2rem 0',
    },
    goToSection: {
      display: 'flex',
      gap: '2rem',
      justifyContent: 'end',
      margin: '2rem 3rem',
      fontWeight: 500,
    },
    linksWrap: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'end',
    },
    a: {
      textDecoration: 'none',
      color: '#000',
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    copyLink: {
      visibility: 'hidden',
      cursor: 'copy',
    },
    paginationWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '3rem',
      alignItems: 'center',
    },
    seeAll: {
      border: '1px solid #8f8f8f',
      textDecoration: 'none',
      color: '#000',
      padding: '2px 1rem',
      borderRadius: '16px',
      fontSize: '1rem',
    },
  })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}
interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}
export const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
