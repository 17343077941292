import { IPostSampleAttachments } from '_State/Spectrum/types';
import {
  ICampaignsQueryParams,
  IPayment,
  IStockCompany,
  ITag,
} from '_Types/campaigns';

export interface IThrivePresentation {
  fileName: string;
  screenshotUrl: string;
}

export interface IAsset {
  id: number;
  fileName: string;
  screenshotUrl: string;
  notification: IAssetNotification;
}

export interface IAssetNotification {
  id: number;
  instructions: string;
}

export interface IThriveCampaign {
  id: number;
  userId: number;
  companyName: string;
  payment: IPayment;
  thrivePresentation: IThrivePresentation;
  createdDate: string;
  status?: string;
  industry: string[];
  geolocation: IGeoLocation;
  geolocationStates: IRegion[];
  audiences: string[];
  ageGroups: string[];
  budget: IThriveBudget;
  tag: ITag;
  stockTickerList?: IStockCompany[];
  companyWebsite: string;
  otherInformations: string;
  totalNumberOfLeads: number;
  impressions: number;
  numberOfLeadsGenderFemale: number;
  numberOfLeadsGenderMale: number;
  numberOfLeadsAge25To34: number;
  numberOfLeadsAge35To44: number;
  numberOfLeadsAge45To54: number;
  numberOfLeadsAge55To64: number;
  adminNotificationQuantity: number;
  userNotificationQuantity: number;
  campaignNotification: ICampaignNotification;
  creatorEmail: string;
  creatorName: string;
}

export interface ICampaignNotification {
  id: number;
  instructions: string;
  thrivePresentation: IThrivePresentation;
}

export interface IThriveBudget {
  name: string;
  leadsPerProgram: number;
  programPrice: number;
}

export interface IThriveProgramDraft {
  industry: string[];
  geolocation?: number;
  geolocationStates?: number[];
  audiences?: string[];
  ageGroups?: string[];
  budget?: string;
  tag?: number;
  stockTickerList?: IStockCompany[];
}

export interface IRegion {
  id: number;
  region: string;
}

export interface IThriveCampaignForm {
  presentationUrl: string | null;
  presentationName: string;
  companyWebsite: string;
  otherInformation: string;
}

export interface IGeoLocation {
  id: number;
  country: string;
}

export interface IGeoLocationState {
  id: number;
  region: string;
  isoAlpha3Code: string;
}

export interface IThriveCampaignsQueryParams extends ICampaignsQueryParams {}

export interface IThriveLeads {
  content: IThriveLead[];
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
  first: boolean;
  last: boolean;
}

export interface ICampaignUpdate {
  instructions: string;
  createPresentationDto?: IUploadFileDto;
}

export interface IAssetUpdate {
  id: number;
  instructions: string;
}

export interface IThriveLead {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  geolocation?: IGeoLocation;
  geolocationState?: IGeoLocationState;
  city: string;
  optInDate: string;
}

export interface IThriveLeadForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  geolocationId: string;
  geolocationStateId: string;
  optInDate: string;
}

export interface IThriveStatistics {
  impressions?: number;
  numberOfLeadsGenderFemale?: number;
  numberOfLeadsGenderMale?: number;
  numberOfLeadsAge25To34?: number;
  numberOfLeadsAge35To44?: number;
  numberOfLeadsAge45To54?: number;
  numberOfLeadsAge55To64?: number;
}

export interface IThriveLeadsQueryParams {
  sortDirection: 'ASC' | 'DESC' | string;
  sortByField: string;
  geolocationList: string[];
}

export enum AgeGroup {
  FROM_25_TO_34 = '25-34',
  FROM_35_TO_44 = '35-44',
  FROM_45_TO_54 = '45-54',
  FROM_55_TO_64 = '55-64',
}

export interface IUploadFileDto {
  base64Content?: string;
  screenshotUrl?: string;
  attachmentId?: number;
  fileName?: string;
  channelAcceptedDate?: string;
  attachments?: IPostSampleAttachments[];
}

export interface IFileDto {
  fileSourceUrl?: string;
  fileName?: string;
  channelAcceptedDate?: string;
}
export interface IAssetsContext {
  assets: IAsset[];
  admin?: boolean;
  addAssetInstructions?: (id: number, instructions: string) => void;
  updateAsset?: (
    newAsset: IUploadFileDto,
    assetId?: string | number,
    campaignId?: string | number
  ) => Promise<void>;
  addAsset?: (
    newAsset: IUploadFileDto,
    campaignId?: string | number
  ) => Promise<void>;
  removeAsset?: (
    assetId: number,
    campaignId?: string | number
  ) => Promise<void>;
  deleteAssetNotification?: (assetId?: number, notificationId?: number) => void;
}

export interface IThriveCampaignEditForm {
  industry: string[];
  geolocation?: number;
  geolocationStates?: number[];
  audiences?: string[];
  ageGroups?: string[];
  stockTickerList?: IStockCompany[];
  presentationUrl: string | null;
  presentationName: string;
  companyWebsite: string;
}
