import styled from '@emotion/styled/macro';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';

const Main = styled.div`
  background: #fff;
  z-index: 1;
  overflow: scroll;
  padding: 2rem 4rem;
  overflow-y: scroll;
  > ul {
    > li {
      line-height: 24px;
      :hover {
        cursor: pointer;
        color: #4f007d;
      }
    }
  }
`;
const H1 = styled.h1`
  text-align: center;
  margin: 0 0 2rem;
  font-weight: bold;
`;
const P = styled.p`
  line-height: 24px;
  margin-bottom: 2rem;
`;
const DialogContent = styled.div`
  padding: 0 5rem;
  font-size: 1.5rem;
  line-height: 24px;
`;

interface Iclient {
  averageFee: string;
  clientName: string;
  companyStockTicker: string;
  id: number;
  startDate: string;
}
export const Disclosure = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState<Iclient>();
  const [allClientDetails, setAllClientDetails] = useState<Iclient[]>();

  const handleClick = ({
    averageFee,
    clientName,
    companyStockTicker,
    id,
    startDate,
  }: Iclient) => {
    setOpen(true);
    setClientDetails({
      averageFee,
      clientName,
      companyStockTicker,
      id,
      startDate,
    });
  };

  const handleDialogClose = () => {
    setOpen(false);
    // window.location.reload();
  };
  useEffect(() => {
    fetch('https://dev.xemotomedia.com/api/disclosures')
      .then((res) => res.json())
      .then((data) => {
        setAllClientDetails(data);
      });
  }, []);
  return (
    <Main>
      <H1>{t('auth.disclosure.header')}</H1>
      <P>{t('auth.disclosure.paragraph1')}</P>
      <br />
      <P>{t('auth.disclosure.paragraph2')}</P>
      <br />
      <P>{t('auth.disclosure.paragraph3')}</P>
      <br />
      <P>{t('auth.disclosure.paragraph4')}</P>
      <br />
      <P>{t('auth.disclosure.paragraph5')}</P>
      <br />
      <br />
      <H1>{t('auth.disclosure.disclosure')}</H1>
      <ul>
        {allClientDetails?.map(
          ({ averageFee, clientName, companyStockTicker, id, startDate }) => {
            return (
              <li
                key={id}
                onClick={() =>
                  handleClick({
                    averageFee,
                    clientName,
                    companyStockTicker,
                    id,
                    startDate,
                  })
                }
              >
                {clientName}{' '}
                <span>{companyStockTicker && `(${companyStockTicker})`}</span>
              </li>
            );
          }
        )}
      </ul>
      <PlxDialog title={''} open={open} closeButton onClose={handleDialogClose}>
        <DialogContent>
          <H1>
            {clientDetails?.clientName}{' '}
            <span>
              {clientDetails?.companyStockTicker &&
                `(${clientDetails?.companyStockTicker})`}
            </span>
          </H1>
          <P>
            <Trans
              i18nKey="auth.disclosure.paragraph11"
              components={[
                clientDetails?.clientName,
                clientDetails?.averageFee,
                formatDate(clientDetails?.startDate),
              ]}
            />
          </P>
        </DialogContent>
      </PlxDialog>
    </Main>
  );
};
