import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import React from 'react';
import { OrdersTab } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Orders/OrdersTab';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const CSVButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

export const Orders = () => {
  const { agencyClientId, id } = useParams<{
    agencyClientId: string;
    id: string;
  }>();

  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledHeader
          title={'Users'}
          backLink={
            agencyClientId
              ? `/home/admin/users/clients/${id}`
              : '/home/admin/users'
          }
          backLabel={'Back to users'}
        >
          <CSVButton size={'small'} isDisabled={true} label={'downloadCSV'} />
        </StyledHeader>
      </HeaderWrapper>
      <Body>
        <OrdersTab />
      </Body>
    </Wrapper>
  );
};
