import React, { FC } from 'react';
import styled from '@emotion/styled';
import { MenuIconButtonX } from '_Routes/Home/NavigationPanel/MenuIconButton/MenuIconButtonX';
import { Button, Divider } from '@material-ui/core';
import { ProfileIconX } from '_Routes/Home/NavigationPanel/Icons/ProfileIconX';
import { DocumentsIcon } from '_Routes/Home/NavigationPanel/Icons/DocumentsIcon';
import { ContactIconX } from '_Routes/Home/NavigationPanel/Icons/ContactIconX';
import { LogOutIconX } from '_Routes/Home/NavigationPanel/Icons/LogOutIconX';
import { IThemed } from '_Styles/types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions } from '_State/Auth/auth.state';
import { useDispatch } from 'react-redux';
import { setCredentials } from '_Api/api';
import { ChannelsIcon } from '_Routes/Home/NavigationPanel/Icons/ChannelsIcon';
import { InfoIcon } from '_Routes/Home/NavigationPanel/Icons/InfoIcon';
import { UsersIcon } from '_Routes/Home/NavigationPanel/Icons/UsersIcon';
import { ReferralsIcon } from '../Icons/ReferralsIcon';

const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
`;

const StyledContact = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  color: #1b192c;
  opacity: 0.6;
  &hover: {
    background: #fff;
  }
`;

const MenuDivider = styled(Divider)<IThemed>`
  && {
    background-color: #ddd;
    height: 0.5px;
    margin: ${({ theme }) => theme.spacing(1.5, 0, 0.5)};
  }
`;

const SpaceHolder = styled.div`
  height: 5rem;
`;

interface IProps {
  isExtended: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isDistributionPartner: boolean;
  afterRedirect?: () => void;
}

export const Footer: FC<IProps> = ({
  isExtended,
  isAdmin,
  isAgency,
  isDistributionPartner,
  afterRedirect,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isActive = (path: string): boolean => location.pathname.includes(path);

  const handleLogoutClick = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('expires');
    dispatch(authActions.saveCredentials({ token: '', refreshToken: '' }));
    setCredentials({ token: undefined });
    if (isDistributionPartner) {
      history.push('/auth/finfluencer/login');
    } else {
      history.push('/auth/login');
    }
  };

  return (
    <Wrapper>
      <div>
        {isAdmin && (
          <>
            <MenuIconButtonX
              isFocused={isActive('/home/admin/referrals')}
              isExtended={isExtended}
              label={t('navBar.referrals')}
              testId={'referrals-button'}
              onClick={() => {
                if (isAdmin) {
                  history.push('/home/admin/referrals');
                }
              }}
            >
              <ReferralsIcon />
            </MenuIconButtonX>
            <MenuIconButtonX
              isFocused={isActive('/home/admin/channels')}
              isExtended={isExtended}
              label={t('navBar.channels')}
              testId={'channels-button'}
              onClick={() => {
                if (isAdmin) {
                  history.push('/home/admin/channels');
                }
              }}
            >
              <ChannelsIcon />
            </MenuIconButtonX>
            <MenuIconButtonX
              isFocused={isActive('/home/admin/users')}
              isExtended={isExtended}
              label={t('navBar.users')}
              testId={'users-button'}
              onClick={() => {
                if (isAdmin) {
                  history.push('/home/admin/users');
                }
              }}
            >
              <UsersIcon />
            </MenuIconButtonX>
          </>
        )}

        {isDistributionPartner && <SpaceHolder></SpaceHolder>}
        <div style={{ justifyContent: 'flex-end' }}>
          {isDistributionPartner && (
            <MenuIconButtonX
              isFocused={isActive('/home/finfluencer/profile')}
              linkPath={'/home/finfluencer/profile'}
              isExtended={isExtended}
              afterRedirect={afterRedirect}
              label={t('navBar.profile')}
              testId={'profile-button'}
            >
              <ProfileIconX />
            </MenuIconButtonX>
          )}
          {isDistributionPartner && (
            <MenuIconButtonX
              isFocused={isActive('/home/finfluencer/documents')}
              linkPath={'/home/finfluencer/documents'}
              isExtended={isExtended}
              afterRedirect={afterRedirect}
              label={t('navBar.documents')}
              testId={'documents-button'}
            >
              <DocumentsIcon />
            </MenuIconButtonX>
          )}
          {isDistributionPartner ? (
            <MenuIconButtonX
              isFocused={isActive('/home/finfluencer/contact')}
              linkPath={'/home/finfluencer/contact'}
              isExtended={isExtended}
              afterRedirect={afterRedirect}
              label={t('navBar.contactUs')}
              testId={'cantact-button'}
            >
              <ContactIconX />
            </MenuIconButtonX>
          ) : (
            <>
              {!isAdmin && (
                <StyledContact
                  onClick={() => {
                    history.push(
                      isAgency ? '/home/agency/contact' : '/home/client/contact'
                    );
                  }}
                >
                  <InfoIcon />
                  {isExtended && t('navBar.contactUs')}
                </StyledContact>
              )}
            </>
          )}
        </div>
      </div>
      {isAdmin && (
        <div>
          <MenuDivider />
          <MenuIconButtonX
            onClick={handleLogoutClick}
            isExtended={isExtended}
            afterRedirect={afterRedirect}
            label={t('navBar.logOut')}
            testId={'logout-button'}
          >
            <LogOutIconX />
          </MenuIconButtonX>
        </div>
      )}
      {isDistributionPartner && (
        <div>
          <MenuDivider />
          <MenuIconButtonX
            onClick={handleLogoutClick}
            isExtended={isExtended}
            afterRedirect={afterRedirect}
            label={t('navBar.logOut')}
            testId={'logout-button'}
          >
            <LogOutIconX />
          </MenuIconButtonX>
        </div>
      )}
    </Wrapper>
  );
};
