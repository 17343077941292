import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  FormSection,
  PlxFormSection,
} from '_Components/FormSection/FormSection';
import {
  OptionLabel,
  OptionWrapper,
  PlxAutocomplete,
} from '_Components/PlxAutoComplete/PlxAutoComplete';
import { getStockTickers } from '_State/Spectrum/Api/spectrum.api';
import { useTranslation } from 'react-i18next';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { IStockCompany } from '_Types/campaigns';
import { IconContainer, Search, StyledInputBase } from '../styles';
import { SearchIcon } from '_Routes/Home/NavigationPanel/Icons/SearchIcon';

interface IProps {
  value?: any;
  setValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fieldName: string;
  newDesign?: boolean;
}
export const StockSelect: FC<IProps> = ({
  value,
  setValue,
  fieldName,
  newDesign,
}) => {
  const { t } = useTranslation();
  const [stockTickers, setStockTickers] = useState<IStockCompany[]>([]);
  const [stockInfo, setStockInfo] = useState<IStockCompany | null>(null);
  const [stockExc, setStockExc] = useState<string>('');

  useEffect(() => {
    let isMounted = true;

    if (value) {
      getStockTickers(value.companySymbol, value.exchangeSymbol).then((v) => {
        if (isMounted) {
          setStockTickers(v.content);
          // setStockInfo(v.content[0]);
        }
      });
      setStockInfo(value);
    } else {
      getStockTickers(value ? value.companyName : '').then((v) => {
        if (isMounted) {
          setStockTickers(v.content);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [value]);

  const handleChange = (value: any) => {
    setStockInfo(value);
    setValue(fieldName, value);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    getStockTickers(value).then((v) => {
      setStockTickers(v.content);
    });
  };
  useEffect(() => {
    if (stockInfo) {
      setStockExc(`${stockInfo?.exchangeSymbol}: ${stockInfo?.companyName}`);
    }
  }, [stockInfo]);

  return newDesign ? (
    <>
      <PlxFormSection title={t('stock.stockTicker')} required={false}>
        <Search>
          <IconContainer>
            <SearchIcon />
          </IconContainer>

          <PlxAutocomplete
            newDesign={newDesign}
            stockSelect
            value={stockInfo}
            placeholder={t('stock.stockTickerPlaceholder')}
            options={stockTickers}
            freeSolo={false}
            popupIcon={<Search />}
            renderOption={(o) => (
              <OptionWrapper>
                <OptionLabel>
                  <strong>{o.companySymbol}</strong> {o.companyName}
                </OptionLabel>
              </OptionWrapper>
            )}
            onChange={handleChange}
            onInputChange={handleInputChange}
          />
        </Search>
      </PlxFormSection>
      <PlxFormSection title={t('stock.stockExchange')} required={false}>
        <Search>
          <IconContainer>
            <SearchIcon />
          </IconContainer>
          <StyledInputBase
            value={stockExc}
            onChange={(e) => setStockExc(e.target.value)}
          />
        </Search>
      </PlxFormSection>
    </>
  ) : (
    <>
      <FormSection title={t('stock.stockTicker')} required={false}>
        <PlxAutocomplete
          value={stockInfo}
          placeholder={t('stock.stockTickerPlaceholder')}
          options={stockTickers}
          freeSolo
          popupIcon={<Search />}
          getOptionLabel={(o) => `${o.companySymbol} ${o.companyName}`}
          renderOption={(o) => (
            <OptionWrapper>
              <OptionLabel>
                <strong>{o.companySymbol}</strong> {o.companyName}
              </OptionLabel>
            </OptionWrapper>
          )}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      </FormSection>
      <FormSection title={t('stock.stockExchange')} required={false}>
        <PlxInput
          value={stockExc}
          onChange={(e) => setStockExc(e.target.value)}
        />
      </FormSection>
    </>
  );
};
