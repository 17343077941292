import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import GraphContainer from '../graphContainer/GraphContainer';
// import { data } from './data';
import { useTranslation } from 'react-i18next';
import { CustomizedTooltip } from './CustomizedTooltip';
// import SpectrumButton from '../../../SpectrumDashboard/_Component/SpectrumButton/SpectrumButton';
import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import { IFilters } from '../../utils';
import { getSpectrumAverageEngagements } from '_State/Spectrum/Api/spectrum.api';
import { AverageChartElement, serilizeAverages } from '../../helpers';
import {
  dataColors,
  initialVisibility,
  IVisibility,
  visibilityLabels,
} from './data';

export interface IDailyData {
  name: string;
  Interactions?: number;
  Engagement_Rate?: number;
}
interface IStyledCheckBox {
  checkboxcolor?: string;
}

interface IProps {
  filters?: IFilters;
  userId?: number;
}

export const StyledCheckbox = styled(Checkbox)<IStyledCheckBox>`
  span {
    svg {
      color: ${(props: IStyledCheckBox) => props.checkboxcolor};
    }
  }
`;

export const capitalize = (s: string): string =>
  s && s[0].toUpperCase() + s.slice(1);

export const EngagementBenchmarkGraphNo2: FC<IProps> = ({
  filters,
  userId,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState<IVisibility>(initialVisibility);
  const [data, setData] = useState<AverageChartElement[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

  const getDailyData = (label: string): IDailyData | undefined => {
    return data.find((el) => el.name === label);
  };

  const handleChange = (arg1: string, arg2: string) => {
    setVisibility((prev: IVisibility) => {
      return {
        ...visibility,
        [arg1]: { ...prev[arg1], [arg2]: !(prev[arg1] as any)[arg2] },
      };
    });
  };

  const fetch = useCallback(
    async (params?: Record<string, any>): Promise<void> => {
      try {
        if (!userId) return;

        const averages = await getSpectrumAverageEngagements(userId, params);

        if (!averages?.length) return setData([]);

        const data = serilizeAverages([averages]);

        setSelectedCampaigns([]);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userId) return;

      const selectedIds = filters?.campaignIds
        .filter((value) => value.isChecked)
        .map((selected) => selected.id);

      if (!selectedIds || selectedIds?.length === 0)
        return fetch({
          startDate: filters?.date.selection?.startDate,
          endDate: filters?.date.selection?.endDate,
        });

      try {
        const averages = await Promise.all(
          selectedIds.map(async (id) => {
            return await getSpectrumAverageEngagements(userId, {
              campaignId: id,
              startDate: filters?.date.selection?.startDate,
              endDate: filters?.date.selection?.endDate,
            });
          })
        );

        if (!averages?.length) return setData([]);

        const data = serilizeAverages(averages);

        setSelectedCampaigns(selectedIds);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
      }
    })();
  }, [filters, fetch, userId]);

  return (
    <GraphContainer
      title={t('spectrum.overview.EngagementBenchmark')}
      widgetDescription={t('spectrum.overview.EngagementBenchmarkDescription')}
      tooltipText={t('spectrum.overview.EngagementBenchmarkGraphNo2Tooltip')}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height="75%">
          <BarChart
            data={data}
            margin={{
              top: 25,
              right: 15,
              left: 10,
              bottom: 25,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              label={{
                dy: 15,
                fontSize: 14,
              }}
            />

            <YAxis
              label={{
                value: `${t('spectrum.overview.AverageEngagementRate')} (%)`,
                angle: -90,
                dx: -25,
                fontSize: 14,
              }}
            />

            <Tooltip
              cursor={{ fill: '#f5f5f5' }}
              content={
                <CustomizedTooltip
                  getDailyData={getDailyData}
                  dataColors={dataColors}
                  visibility={visibility}
                  selectedCampaigns={selectedCampaigns}
                />
              }
            />

            {dataColors
              .slice(0, selectedCampaigns.length || 1)
              .map((el, id) => {
                return el.data.map((item, index) => {
                  return (
                    (visibility[el.name] as any)[item.name] && (
                      <Bar
                        key={`${id}-${index}`}
                        dataKey={`${el.name}.${item.name}`}
                        barSize={(4 - (selectedCampaigns.length || 1)) * 10}
                        fill={item.color}
                        isAnimationActive={false}
                        stackId={el.name}
                      />
                    )
                  );
                });
              })}
          </BarChart>
        </ResponsiveContainer>
        <div
          style={{
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl component="fieldset">
            <FormGroup
              style={{
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {dataColors
                .slice(0, selectedCampaigns.length || 1)
                .map((el, index) => {
                  return (
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        fontSize: '14px',
                      }}
                      key={el.name + index}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: 16,
                        }}
                      >
                        {!selectedCampaigns.length
                          ? 'Campaign Average'
                          : el.name
                              .replace('campaign-', 'Campaign #')
                              .slice(0, -1)
                              .concat(selectedCampaigns[index])}
                      </div>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={visibility[el.name].engagement}
                            onChange={() =>
                              handleChange(el.name, el.data[1].name)
                            }
                            checkboxcolor={el.data[1].color}
                          />
                        }
                        label={capitalize(visibilityLabels.engagement)}
                      />
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={visibility[el.name].socialmediaEngagement}
                            onChange={() =>
                              handleChange(el.name, el.data[0].name)
                            }
                            checkboxcolor={el.data[0].color}
                          />
                        }
                        label={capitalize(
                          visibilityLabels.socialmediaEngagement
                        )}
                      />
                    </div>
                  );
                })}
            </FormGroup>
          </FormControl>
          {/* <div style={{ alignSelf: 'end', marginRight: '24px' }}>
            <SpectrumButton text={t('spectrum.overview.seeDetails')} />
          </div> */}
        </div>
      </div>
    </GraphContainer>
  );
};

export default EngagementBenchmarkGraphNo2;
