import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import {
  SelectInputWrapper,
  StyledSelectToggleIcon,
  useStyles,
} from '_Routes/Auth/Agency/StylesAg';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '_Routes/Home/NavigationContext';

interface IProps {
  clientSelected?: string | null;
  clientValue?: string;
  popup?: boolean;
  campaignLenght?: number;
}

type Option = {
  id: string;
  companyName: string;
};

export const SelectClient: FC<IProps> = ({ campaignLenght, popup }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState<string | null>(null);
  const { clients, updateSelectedClient, clientSelected } = useContext(
    NavigationContext
  );
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setValue(clientSelected);
  }, [clientSelected]);

  const handleOnChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | null
  ): void => {
    if (newValue == null) return;
    setValue(newValue);
    updateSelectedClient(newValue);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ): void => {
    setInputValue(newInputValue);
  };

  const handleIconClick = useCallback((): void => {
    if (value) {
      setValue(null);
      updateSelectedClient(null);
    }
    setIsOpen(true);
  }, [value, updateSelectedClient]);

  const filteredOptions = clients?.map((option: Option) => option.id);

  const getLabel = (value: string): string | null => {
    const option = clients.find((option: any) => option.id === value);

    if (!option) return null;

    return option.companyName;
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      blurOnSelect
      open={isOpen}
      onClose={(): void => setIsOpen(false)}
      onOpen={(): void => setIsOpen(true)}
      id="controllable-select-filter"
      options={filteredOptions}
      getOptionLabel={(option: string): string => `${getLabel(option)}`}
      style={{ width: 250 }}
      renderInput={(params): JSX.Element => (
        <SelectInputWrapper ref={params.InputProps.ref}>
          <input
            type="text"
            {...params.inputProps}
            placeholder={t('agency.select')}
            className={classes.input}
          />
          <StyledSelectToggleIcon onClick={handleIconClick}>
            {value ? (
              <CloseIcon style={{ color: '#008b9c' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#008b9c' }} />
            )}
          </StyledSelectToggleIcon>
        </SelectInputWrapper>
      )}
    />
  );
};
