import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XemInputCountry } from '_Components/PlxInput/XemInputCountry';
import {
  ButtonWrapper,
  FormWrapper,
  FormikWrapper,
  InputContainer,
  MainWrapper,
  StyledButton,
  StyledText,
  StyledTextContainer,
} from './StylesAg';
import { CustomErrMess } from '../DistributionPartner/Register/StyleDP';
import { addClient } from '_State/User/Api/agency.api';
import { getUserInfo } from '_Api/auth';
import { useHistory } from 'react-router-dom';
import { AddClientForm } from '_State/User/user.types';

interface IProps {
  userName: string;
}

const FormValidation = Yup.object({
  firstName: Yup.string().required('* required').label('First Name'),
  lastName: Yup.string().required('* required').label('Last Name'),
  businessName: Yup.string().required('* required').label('Business Name'),
  country: Yup.string().required('* required').label('Phone Number'),
  email: Yup.string()
    .email('Invalid email')
    .required('* required')
    .label('Email'),
});

export const AddClient: FC<IProps> = ({ userName }) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState<number>(0);
  const history = useHistory();
  const [data, setData] = useState<AddClientForm>({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    companyName: '',
  });

  useEffect(() => {
    userId === 0 &&
      getUserInfo().then((data) => {
        data.id && setUserId(data.id);
      });
  }, [userId]);

  const handleSubmit = (newData: AddClientForm): void => {
    setData((prev) => ({ ...prev, ...newData }));
    const data = {
      firstName: newData.firstName,
      lastName: newData.lastName,
      email: newData.email,
      country: newData.country,
      companyName: newData.companyName,
    };
    addClient(data, userId).then(() => {
      history.push('/home/agency/spectrum/clients');
      window.location.reload();
    });
  };

  return (
    <MainWrapper>
      <FormWrapper>
        <StyledTextContainer>
          <StyledText>
            {t('agency.greeting')} {userName},
          </StyledText>
          <StyledText>{t('agency.getStarted')}</StyledText>
        </StyledTextContainer>
        <FormikWrapper>
          <Formik
            initialValues={data}
            onSubmit={(val) => console.log(val)}
            validationSchema={FormValidation}
          >
            {({ values, handleChange }) => (
              <Form>
                <InputContainer>
                  <TextField
                    name="firstName"
                    label={t('agency.firstName')}
                    placeholder="required"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  <ErrorMessage
                    name="firstName"
                    render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
                  />
                  <TextField
                    name="lastName"
                    label={t('agency.lastName')}
                    placeholder="required"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
                  />
                  <TextField
                    name="email"
                    label={t('agency.email')}
                    placeholder="required"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
                  />

                  <TextField
                    name="companyName"
                    label={t('agency.companyName') + ' *'}
                    placeholder="required"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.companyName}
                  />
                  <ErrorMessage
                    name="companyName"
                    render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
                  />
                  <XemInputCountry
                    value={values.country}
                    maxWidth="62rem"
                    handleCountryChange={handleChange('country')}
                    testId="country-field"
                  />
                  <ErrorMessage
                    name="country"
                    render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
                  />
                  <ButtonWrapper>
                    <StyledButton onClick={() => handleSubmit(values)}>
                      {t('agency.submit')}
                    </StyledButton>
                  </ButtonWrapper>
                </InputContainer>
              </Form>
            )}
          </Formik>
        </FormikWrapper>
      </FormWrapper>
    </MainWrapper>
  );
};
