import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDistributionPartnerState } from '_State/DistributionPartner/distributionPartner.types';

const initialState: IDistributionPartnerState = {
  step: '0',
  numberOfNewOpportunities: undefined,
};

type SpectrumReducer<PayloadType> = CaseReducer<
  IDistributionPartnerState,
  PayloadAction<PayloadType>
>;

const changeStep: SpectrumReducer<string> = (state, { payload: value }) => {
  state.step = value;
};

const setNumberOfNewOpportunities: SpectrumReducer<number | undefined> = (
  state,
  { payload: value }
) => {
  state.numberOfNewOpportunities = value;
};

export const distributionPartnerSlice = createSlice({
  name: 'distributionPartner',
  initialState,
  reducers: {
    changeStep,
    setNumberOfNewOpportunities,
  },
});

export const distributionPartnerActions = distributionPartnerSlice.actions;
export const distributionPartnerReducer = distributionPartnerSlice.reducer;
