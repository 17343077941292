import { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';
import React from 'react';

export const UsersIcon: FC<IIconProps> = ({ isFocused }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1663 17.5V15.8333C14.1663 14.9493 13.8152 14.1014 13.19 13.4763C12.5649 12.8512 11.7171 12.5 10.833 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50033 9.16667C9.34128 9.16667 10.8337 7.67428 10.8337 5.83333C10.8337 3.99238 9.34128 2.5 7.50033 2.5C5.65938 2.5 4.16699 3.99238 4.16699 5.83333C4.16699 7.67428 5.65938 9.16667 7.50033 9.16667Z"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.167 17.4991V15.8324C19.1664 15.0939 18.9206 14.3764 18.4681 13.7927C18.0156 13.209 17.3821 12.7921 16.667 12.6074"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 2.60742C14.05 2.79101 14.6855 3.20801 15.1394 3.79268C15.5932 4.37735 15.8395 5.09645 15.8395 5.83659C15.8395 6.57673 15.5932 7.29582 15.1394 7.8805C14.6855 8.46517 14.05 8.88217 13.333 9.06576"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
