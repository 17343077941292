import styled from '@emotion/styled/macro';
import { IThemed } from '_Styles/types';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  withStyles,
} from '@material-ui/core';

export const buttonStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0.5rem !important',
      color: '#231F20',
      '&:hover': {
        background: '#006AFF',
        color: '#FFF',
      },
    },
    expanded: {
      borderRadius: '5px',
      margin: '1rem 0.25rem 0 0.25rem;',
      height: '4rem',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'start',
    },
    rolled: {
      borderRadius: '5px',
      margin: '1rem 0.25rem 0 0.25rem',
      height: '4rem',
      width: '4.5rem',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'start',
    },
    active: {
      background: '#006AFF',
      color: 'white',
      padding: '0.5rem',
    },
    children: {
      // marginBottom: '.33rem',
    },
  })
);

export const buttonStylesClient = makeStyles(() =>
  createStyles({
    root: {
      padding: '0.5rem !important',
      height: '56px',
      color: '#231F20',
      '&:hover': {
        background: '#fff',
        color: '#231F20',
      },
    },
    expanded: {
      borderRadius: '0px',
      margin: '0 0.25rem 0 0.25rem;',
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'start',
    },
    rolled: {
      borderRadius: '0px',
      margin: '0 0.25rem 0 0.25rem',
      height: '56px',
      width: '4.5rem',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      justifyContent: 'start',
    },
    active: {
      background: '#fff',
      color: '#231F20',
      padding: '0.5rem',
    },
    children: {
      // marginBottom: '.33rem',
    },
  })
);

export const iconStyles = makeStyles(() =>
  createStyles({
    expanded: {
      marginLeft: '1rem',
      width: '2rem',
      height: '2rem',
    },
    rolled: {
      margin: 'auto',
      width: '2rem',
      height: '2rem',
    },
  })
);

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#5F5F61',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const StyledIconButton = styled(IconButton)`
  width: 250px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Children = styled.div<IThemed>`
  margin-left: ${({ theme }): number => theme.spacing(0.5)};
  display: flex;
`;

export const Label = styled.div<IThemed>`
  font-weight: 600;
  font-size: 1.5rem;
  font-family: Quicksand, sans-serif;
  margin-left: 6px;
`;
export const ClientLabel = styled.div<IThemed>`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
  opacity: 0.6;
  margin-left: 10px;
`;

export const NotificationBadge = styled.div<IThemed>`
  border-radius: 50%;
  background-color: #006aff;
  color: #f4f4f4;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  font-size: 1.2rem;
  position: absolute;
  top: 2px;
  left: 26px;
`;

export const NotificationBadgeExtended = styled.div<IThemed>`
  border-radius: 50%;
  background-color: #006aff;
  color: #f4f4f4;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  font-size: 1.2rem;
  margin-left: 5px;
`;

export const StyledExpandIcon = styled.div<IThemed>`
  margin-top: 6px;
  margin-left: auto;
`;
