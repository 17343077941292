import React, { FC } from 'react';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import { ITag } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';

interface IProps {
  campaignId: number;
  tag?: ITag;
  tagName?: string;
  onDialogOpenDialog: (campaignId: number) => void;
}
export const TagTableCell: FC<IProps> = ({
  campaignId,
  tagName,
  onDialogOpenDialog,
}) => {
  const { t } = useTranslation();
  const openAddTag = () => {
    onDialogOpenDialog(campaignId);
  };

  return (
    <>
      {tagName ? (
        <PlxLinkTextButton label={tagName} onClick={openAddTag} />
      ) : (
        <PlxLinkTextButton
          label={t('campaignsTable.addNewTag')}
          onClick={openAddTag}
        />
      )}
    </>
  );
};
