import React from 'react';
import { IDailyData, IVisibility } from './EngagementBenchmarkGraph';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface ICustomizedTooltip {
  getDailyData: (arg: string) => IDailyData | undefined;
  dataColors: { name: string; color: string }[];
  visibility: IVisibility;
  label?: string;
}

export const StyledTooltip = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: column;
`;
export const TooltipElement = styled.div`
  padding: 5px;
`;

export const CustomizedTooltip = ({
  label,
  getDailyData,
  dataColors,
  visibility,
}: ICustomizedTooltip) => {
  const { t } = useTranslation();

  if (!label) return null;
  const dailyData: IDailyData | undefined = getDailyData(label);
  return (
    <>
      <StyledTooltip>
        {Object.keys(dailyData as IDailyData)
          .filter((el: string) => el !== 'name')
          .map((el, index) => {
            return (
              (visibility as IVisibility)[el] && (
                <TooltipElement key={index}>
                  {
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          marginRight: '5px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <span
                            style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: `${
                                dataColors.find((col) => col.name === el)?.color
                              }`,
                              borderRadius: '2px',
                              display: 'inline-block',
                              marginRight: '5px',
                            }}
                          ></span>
                          {el
                            .replace('_', ' ')
                            .replace('cumulative', '(cumulative)')}
                          {': '}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            marginRight: '0',
                            paddingLeft: '4px',
                            display: 'inline-block',
                          }}
                        >
                          {(dailyData as any)[el]}
                          {el.replace('_', ' ') ===
                          t('spectrum.overview.Engagement_Rate') ? (
                            '%'
                          ) : (
                            <span style={{ opacity: 0 }}>%</span>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </TooltipElement>
              )
            );
          })}
      </StyledTooltip>
    </>
  );
};
