import React, { FC, useEffect, useRef, useState } from 'react';
import { FormMassage } from '_Components/Forms/FormMassage';
import { ReactComponent as FileUpload } from '../../../../_Styles/images/file_upload.svg';
import {
  FileButton,
  FileNameWrapper,
  PlxFileInputBody,
  StyledFileInput,
  WrapperFileInput,
} from './Styles';

interface IProps {
  onChange: (base64Url: any, fileName?: string) => void;
  fileName?: string;
  name?: string;
  className?: string;
  accept?: string;
  fileSizeLimit?: number;
  uploadIcon?: boolean;
}

export const FileInput: FC<IProps> = ({
  onChange,
  fileName = '',
  name,
  className,
  accept = 'image/*',
  fileSizeLimit = 700000,
  uploadIcon,
}) => {
  const [filenames, setFilenames] = useState(fileName);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setFilenames(fileName);
  }, [fileName]);

  const encodeFile = (file: any) =>
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      if (file.size > fileSizeLimit) {
        reject('File is too big. Max size is 700kb');
        setError('File is too big. Max size is 700kb');
      }
      fr.readAsDataURL(file);
      fr.onload = () => resolve(fr.result);
      fr.onerror = (error) => reject(error);
    });

  const getEncodedFiles = (files: any) => {
    return Promise.all(files.map(encodeFile));
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async () => {
    if (error) {
      setError(undefined);
    }
    if (inputRef && inputRef.current && inputRef.current.files) {
      const files = Array.from(inputRef.current.files);
      const names = files.map((file) => file.name).join(', ');
      setFilenames(names);
      const values = await getEncodedFiles(files);
      onChange((values as string[])[0].split(',')[1], names);
    }
  };

  return (
    <>
      <WrapperFileInput>
        <PlxFileInputBody className={className}>
          <FileButton
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {uploadIcon ? <FileUpload /> : 'Replace'}
          </FileButton>
          <FileNameWrapper>
            {filenames ? filenames : fileName ? fileName : 'No file selected'}
          </FileNameWrapper>
          <StyledFileInput
            accept={accept}
            ref={inputRef}
            type="file"
            name={name}
            data-testid="fileInput"
            onChange={handleChange}
          />
        </PlxFileInputBody>
      </WrapperFileInput>
      {error && <FormMassage message={error} />}
    </>
  );
};
