import {
  IPackageControlConfig,
  PricingType,
} from '../../../../_Components/Payments/PackageControl/PackageControl.types';

const createThrivePaymentConfig = (
  type: PricingType,
  value: string,
  title: string,
  estimatedNumberOfLeads: string
): IPackageControlConfig => {
  return {
    type: type,
    value: value,
    isChosen: false,
    option: {
      header: {
        title: title,
      },
      content: [
        {
          name: 'thrive.payment.estimatedNumberOfLeads',
          value: estimatedNumberOfLeads,
        },
      ],
    },
  };
};

export const getThrivePaymentOptions = (
  leads: number
): IPackageControlConfig[] => {
  switch (leads) {
    case 150:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '4858',
          '$4,858',
          '150'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '1781',
          '$1,781 / month',
          '50 / month'
        ),
      ];
    case 300:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '6210',
          '$6,210',
          '300'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '2277',
          '$2,277 / month',
          '100 / month'
        ),
      ];
    case 750:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '15525',
          '$15,525',
          '750'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '5693',
          '$5,693 / month',
          '250 / month'
        ),
      ];
    case 1350:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '27945',
          '$27,945',
          '1350'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '10247',
          '$10,247 / month',
          '450 / month'
        ),
      ];
    case 3000:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '46575',
          '$46,575',
          '3000'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '17078',
          '$17,078 / month',
          '1000 / month'
        ),
      ];
    case 6000:
      return [
        createThrivePaymentConfig(
          PricingType.SEMI_ANNUALLY,
          '93150',
          '$93,150',
          '6000'
        ),
        createThrivePaymentConfig(
          PricingType.MONTHLY,
          '34155',
          '$34,155 / month',
          '2000 / month'
        ),
      ];
    default:
      return [];
  }
};
