import styled from '@emotion/styled/macro';
import { IThemed } from '_Styles/types';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';

export const buttonStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0.5rem !important',
      color: '#231F20',
      '&:hover': {
        background: '#008b9c',
        color: '#FFF',
        borderRadius: '5px',
      },
    },
    optionsButton: {
      border: '1px solid #008b9c',
      borderRadius: '5px',
      margin: '0 20px 5px auto',
      fontSize: '13px',
      width: '8rem',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: 0,
      justifyContent: 'start',
    },
    hidden: {
      display: 'none',
    },
    dropDown: {
      borderRadius: '1px',
      fontSize: '13px',
      width: '120px',
      display: 'flex',
    },
    active: {
      background: '#008b9c',
      color: 'white',
      padding: '0.5rem',
    },
  })
);

export const StyledList = styled.ul`
  display: block;
  margin: 0 20px 0 auto;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 5px;
  width: 121px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 150px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Children = styled.div<IThemed>`
  margin-left: ${({ theme }): number => theme.spacing(0.5)};
  display: flex;
`;

export const Label = styled.div<IThemed>`
  margin: auto;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: Quicksand, sans-serif;
`;

export const StyledExpandIcon = styled.div<IThemed>`
  margin-top: 6px;
  margin-left: auto;
`;
