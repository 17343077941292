import React, { FC } from 'react';
import styled from '@emotion/styled';
import { MenuIconButtonX } from '_Routes/Home/NavigationPanel/MenuIconButton/MenuIconButtonX';
import { SpectrumIcon } from '_Routes/Home/NavigationPanel/Icons/SpectrumIcon';
import { ThriveIcon } from '_Routes/Home/NavigationPanel/Icons/ThriveIcon';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { getSpectrumLink } from '_Routes/Home/_routes/Spectrum/utils';
import { SubNavigation } from '_Components/SubNavigation/SubNavigation';
import { adminSpectrumNavigationItems } from '../navigationItems';
import { WalletIcon } from '../Icons/WalletIcon';
import { ReferralsIcon } from '../Icons/ReferralsIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledMenuIconButton = styled(MenuIconButtonX)`
  && {
    margin-top: 1rem;
  }
`;

interface IProps {
  isExtended: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isDistributionPartner: boolean;
  afterRedirect?: () => void;
}

export const Campaigns: FC<IProps> = ({
  isDistributionPartner,
  isExtended,
  isAdmin,
  isAgency,
  afterRedirect,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const isActive = (path: string): boolean => location.pathname.includes(path);
  return (
    <Wrapper>
      {isAdmin && (
        <StyledMenuIconButton
          testId="spectrum-dashboard-button"
          isExtended={true}
          isFocused={isActive('spectrum')}
          label={t('navBar.spectrum')}
          subNavigation={
            <SubNavigation
              open={isActive('spectrum')}
              isExtended={true}
              items={adminSpectrumNavigationItems}
            />
          }
          onClick={(): void => {
            isAdmin
              ? history.push(adminSpectrumNavigationItems[0].link)
              : getSpectrumLink(isAgency).then((link) => {
                  if (link) {
                    history.push(link);
                  }
                });
          }}
          isAdmin={isAdmin}
        >
          <SpectrumIcon />
        </StyledMenuIconButton>
      )}

      {isAdmin && (
        <StyledMenuIconButton
          testId="thrive-dashboard-button"
          isExtended={isExtended}
          isFocused={isActive('thrive')}
          label={t('navBar.thrive')}
          onClick={(): void => {
            history.push('/home/admin/thrive-dashboard');
          }}
        >
          <ThriveIcon />
        </StyledMenuIconButton>
      )}
      {isDistributionPartner && (
        <>
          <MenuIconButtonX
            isFocused={isActive('/home/finfluencer/wallet')}
            linkPath={'/home/finfluencer/wallet'}
            isExtended={isExtended}
            afterRedirect={afterRedirect}
            label={t('navBar.wallet')}
          >
            <WalletIcon isFocused={false} />
          </MenuIconButtonX>
          <MenuIconButtonX
            isFocused={isActive('/home/finfluencer/referrals')}
            linkPath={'/home/finfluencer/referrals'}
            isExtended={isExtended}
            afterRedirect={afterRedirect}
            label={t('navBar.referrals')}
          >
            <ReferralsIcon isFocused={false} />
          </MenuIconButtonX>
        </>
      )}
    </Wrapper>
  );
};
