import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getThriveCampaign,
  getThriveCountryStatistics,
  updateThriveCampaignTag,
} from '_State/Thrive/Api/thrive.api';
import { ThriveLeadsTable } from '../../_Components/ThriveLeadsTable/ThriveLeadsTable';
import { IThriveCampaign } from '_State/Thrive/types';
import { LeadsInfo } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Components/ThriveLeadsAmount';
import { PlxCampaignInformation } from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { configureThriveCampaignInfo } from './config';
import { IManageTagsContext } from '_State/Spectrum/types';
import {
  createThriveTag,
  deleteThriveTag,
  getThriveTags,
  updateThriveTags,
} from '_State/Thrive/Api/thrive.api';
import { ITag } from '_Types/campaigns';
import { ManageTagsContext } from '_Types/manageTagsContext';
import { TagsMangerDialog } from '_Routes/Home/_Components/TagManager/_Compontents/TagsMangerDialog';
import { MapChart } from '_Routes/Home/_Components/MapChart';
import { ThriveAgeCharts } from '_Routes/Home/_Components/ThriveAgeCharts';
import { GenderChart } from '_Routes/Home/_Components/ThriveGenderChart';
import { IMapCountry } from '_Components/PlxMap/types';

const StyledAgeCharts = styled(ThriveAgeCharts)`
  grid-area: age;
`;

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const AssetsButton = styled(PlxButton)``;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const StyledThriveLeadsTable = styled(ThriveLeadsTable)`
  grid-area: table;
`;
const StyledMapChart = styled(MapChart)`
  grid-area: map;
`;

const StyledCampaignInformation = styled(PlxCampaignInformation)`
  grid-area: information;
  overflow: auto;
`;
const StyledLeadsInfo = styled(LeadsInfo)`
  grid-area: leads;
`;

const StyledGenderChart = styled(GenderChart)`
  grid-area: gender;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'leads gender map'
    'age age map'
    'information table table';
  grid-template-columns: 26.5rem 26.5rem 1fr;
  grid-template-rows: 25rem 39rem 48rem;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  padding: 4rem;
  background: rgb(248, 249, 252);
`;

export const ThriveCampaignReport: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<IThriveCampaign>();
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagManagerOpened, setTagManagerOpened] = useState(false);
  const [countryStats, setCountryStats] = useState<IMapCountry[]>([]);
  const history = useHistory();
  const fetchCampaign = (id?: string) =>
    getThriveCampaign(id).then((value) => {
      setCampaign(value);
      return value;
    });

  const fetchTags = () => {
    getThriveTags().then((tags) => {
      setTags(tags);
      return tags;
    });
  };

  const updateData = () => {
    fetchCampaign(id).then(fetchTags);
  };

  const context: IManageTagsContext = {
    tags,
    updateTag: (newTag) => {
      return updateThriveTags(newTag).then(() => {
        updateData();
      });
    },
    updateCampaignTag: (campaignId, newTagId) => {
      return updateThriveCampaignTag(campaignId, newTagId).then(() => {
        updateData();
      });
    },
    removeTag: (tagId) => {
      return deleteThriveTag(tagId).then(() => {
        updateData();
      });
    },
    addTag: (newTagName: string) => {
      return createThriveTag(newTagName).finally(() => {
        updateData();
      });
    },
  };

  useEffect(() => {
    fetchCampaign(id).then(fetchTags);
    getThriveCountryStatistics(id).then((v) => setCountryStats(v));
  }, [id]);

  const getCampaignTitle = () => {
    const campaignText = t('thrive.dashboard.campaign');
    return campaign ? `${campaignText} #${campaign?.id}` : campaignText;
  };

  const openTagManager = () => {
    setTagManagerOpened(true);
  };
  const closeTagManager = () => {
    setTagManagerOpened(false);
  };

  const leadsAgeData = useMemo(() => {
    if (campaign) {
      return [
        campaign.numberOfLeadsAge25To34,
        campaign.numberOfLeadsAge35To44,
        campaign.numberOfLeadsAge45To54,
        campaign.numberOfLeadsAge55To64,
      ];
    } else {
      return [0, 0, 0, 0];
    }
    // eslint-disable-next-line
  }, [campaign]);

  const totalLeads = useMemo(
    () => (campaign ? campaign.totalNumberOfLeads : 0),
    [campaign]
  );

  const userNotifications = useMemo(() => {
    if (
      campaign?.userNotificationQuantity &&
      campaign.userNotificationQuantity > 0
    ) {
      return campaign.userNotificationQuantity;
    }
    return undefined;
  }, [campaign]);

  return (
    <ManageTagsContext.Provider value={context}>
      <ReportWrapper>
        <HeaderWrapper>
          <PlxDashboardHeader
            title={getCampaignTitle()}
            backLabel={t('thrive.dashboard.thriveDashboard')}
            backLink={'/home/thrive-dashboard'}
          >
            <AssetsButton
              notificationQuantity={userNotifications}
              size={'small'}
              variant={ButtonVariant.Outlined}
              label={t('thrive.dashboard.viewAssets')}
              onClick={() =>
                history.push('/home/thrive-dashboard/campaign/assets/' + id)
              }
            />
          </PlxDashboardHeader>
        </HeaderWrapper>
        <ContentWrapper>
          <StyledLeadsInfo totalLeads={totalLeads} />
          <StyledGenderChart
            label={t('thrive.dashboard.gender')}
            femaleAmount={campaign?.numberOfLeadsGenderFemale}
            maleAmount={campaign?.numberOfLeadsGenderMale}
          />
          <StyledMapChart
            label={t('thrive.dashboard.heatMap')}
            countries={countryStats}
          />
          <StyledCampaignInformation
            label={t('campaignInformation.label')}
            config={configureThriveCampaignInfo(t, openTagManager, campaign)}
          />
          <StyledAgeCharts
            label={t('thrive.dashboard.ageRange')}
            ageData={leadsAgeData}
          />
          <StyledThriveLeadsTable id={id} />
        </ContentWrapper>
      </ReportWrapper>
      <TagsMangerDialog
        context={ManageTagsContext}
        campaignId={campaign?.id}
        isAddOnly={false}
        open={tagManagerOpened}
        onClose={closeTagManager}
      />
    </ManageTagsContext.Provider>
  );
};
