import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { themeGetters } from '_Styles/themeHelpers';
import { ICommonProps } from '_Types/props';
import {
  PlxStepButton,
  StepButtonType,
} from '../Buttons/PlxStepButton/PlxStepButton';

const { color } = themeGetters;

const HeaderWrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  background-color: ${color('primary', 'light')};
  border-bottom: 1px solid #eaeef1;
  width: 100%;
`;

const StepBack = styled(PlxStepButton)`
  margin: auto 0 auto 4rem;
  min-width: 165px;
`;

const Title = styled.div<IThemed>`
  font-size: 1.8rem;
  color: ${color('primary', 'contrastText')};
  margin: auto;
  min-width: 165px;
`;
const ChildrenWrapper = styled.div`
  margin: auto 4rem auto 0;
  min-width: 165px;
  display: flex;
  justify-content: flex-end;
`;

interface IProps extends ICommonProps {
  backLabel?: string;
  backLink?: string;
  title: string;
}

export const PlxDashboardHeader: FC<IProps> = ({
  backLabel,
  backLink,
  title,
  children,
  className,
}) => {
  return (
    <HeaderWrapper>
      {backLink && backLabel && (
        <StepBack
          linkTo={backLink}
          text={backLabel}
          type={StepButtonType.BACK}
        />
      )}
      <Title className={className}>{title}</Title>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </HeaderWrapper>
  );
};
