import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: purple[500],
          borderColor: purple[500],
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const PlxCheckboxBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  padding: 1.2rem 0;
  color: #000;
  line-height: 1.8rem;
`;
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

interface IProps extends ICommonProps {
  label?: string;
  name?: string;
  value?: boolean;
  indeterminate?: boolean;
  required?: boolean;
  onChange?: SwitchBaseProps['onChange'];
  isDisabled?: boolean;
}

export const PlxSwitch: FC<IProps> = ({
  value,
  label,
  onChange,
  isDisabled,
  required,
  name,
  className,
  children,
  indeterminate,
}) => {
  return (
    <PlxCheckboxBody className={className}>
      <TextWrapper>
        {children}
        <span>{label}</span>
      </TextWrapper>

      <FormGroup>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <AntSwitch
                color="secondary"
                required={required}
                checked={value}
                disabled={isDisabled}
                onChange={onChange}
                name={name}
              />
            </Grid>
          </Grid>
        </Typography>
      </FormGroup>
    </PlxCheckboxBody>
  );
};
