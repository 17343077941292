import React, { FC } from 'react';
import { IDividedPanelConfig } from '_Types/IDividedPanelConfig';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

interface IPlxDividedPanel {
  vertical?: boolean;
}

const PlxPanelBody = styled.div<IPlxDividedPanel>`
  padding: 2rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background: white;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: background-color 0.5s;
  align-items: center;

  ${({ vertical }) =>
    !vertical &&
    css`
      flex-flow: column;
    `}

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 0.5s;
  }
`;

const PlxPanelContent = styled.div<IPlxDividedPanel>`
  ${({ vertical }) =>
    vertical &&
    css`
      width: 50%;
    `};
`;

const PlxPanelDivider = styled.div<IPlxDividedPanel>`
  position: relative;
  padding: 0 3.5rem;
  &:after {
    display: block;
    content: ' ';
    margin: 0 auto;
    height: 100%;
    border-left: 0.1rem solid rgba(213, 221, 227, 0.5);
    line-height: 0.1rem;
  }
  ${({ vertical }) =>
    !vertical &&
    css`
      padding: 1rem 0;
      &:after {
        border-left: none;
        border-bottom: 0.1rem solid rgba(213, 221, 227, 0.5);
      }
    `}
`;
export const PlxDividedPanel: FC<IDividedPanelConfig> = ({
  contentOne,
  contentTwo,
  contentThree,
  vertical,
}) => (
  <PlxPanelBody vertical={vertical}>
    <PlxPanelContent vertical={vertical}>{contentOne}</PlxPanelContent>
    <PlxPanelContent vertical={vertical}>{contentTwo}</PlxPanelContent>
    <PlxPanelDivider vertical={vertical} />
    <PlxPanelContent>{contentThree}</PlxPanelContent>
  </PlxPanelBody>
);
