import React, { FC } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import {
  InputContainer,
  ButtonWrapper,
  CustomErrMess,
} from '../../DistributionPartner/Register/StyleDP';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { RegistrationForm } from './AgencyRegForm';
import * as Yup from 'yup';
import { XemInputCountry } from '_Components/PlxInput/XemInputCountry';

const stepOneValidation = Yup.object({
  firstName: Yup.string().required('* required').label('First Name'),
  lastName: Yup.string().required('* required').label('Last Name'),
  businessName: Yup.string().required('* required').label('Business Name'),
  country: Yup.string().required('* required').label('Phone Number'),
});

export const AgencyRegFormStepOne: FC<{
  data: RegistrationForm;
  next: (val: RegistrationForm) => void;
  apiError: any;
}> = ({ data, next }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={data}
      onSubmit={(val) => next(val)}
      validationSchema={stepOneValidation}
    >
      {({ values, handleChange, errors, dirty }) => (
        <Form>
          <InputContainer>
            <TextField
              name="firstName"
              label={t('auth.register.firstName')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.firstName}
            />
            <ErrorMessage
              name="firstName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <TextField
              name="lastName"
              label={t('auth.register.lastName')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.lastName}
              // className={errors.firstName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="lastName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />

            <TextField
              name="businessName"
              label={t('auth.register.businessName') + ' *'}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.businessName}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="businessName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <XemInputCountry
              value={values.country}
              handleCountryChange={handleChange('country')}
              testId="country-field"
            />
            <ErrorMessage
              name="country"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <ButtonWrapper>
              <PlxButtonX
                // isDisabled={!dirty}
                inputType="submit"
                label={t('auth.register.next')}
                testId="submit-button"
              />
            </ButtonWrapper>
          </InputContainer>
        </Form>
      )}
    </Formik>
  );
};
