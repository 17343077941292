import { spectrumApi } from './api';
import { UserType } from '_State/User/user.types';

interface IRegisterPayload {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  password: string;
  country: string;
  enableEmailNotifications: boolean;
  captchaToken: string;
}

// interface IRegisterDistributionPartnerPayloadX {
//   fullName: string;
//   email: string;
//   phone: string;
//   companyName: string;
//   password: string;
//   enableEmailNotifications: boolean;
// }
interface IRegisterDistributionPartnerPayload {
  fullName: string;
  email: string;
  phone: string;
  companyName: string;
  password: string;
  referredBy: string | null;
  enableEmailNotifications: boolean;
  captchaToken: string;
  region: string;
}

export interface IUserInfo {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  userType?: string;
  referralCode: string;
}

export const getUserInfo = (): Promise<IUserInfo> => {
  return spectrumApi.get('users/self').json();
};

// export const registerDistributionPartnerX = (
//   payload: IRegisterDistributionPartnerPayloadX
// ) => {
//   const [firstName, lastName] = payload.fullName
//     .replace(/\s+/, '\x01')
//     .split('\x01');
//   return spectrumApi
//     .post('users/distribution-partner/register', {
//       json: {
//         firstName,
//         lastName,
//         email: payload.email,
//         phone: payload.phone,
//         companyName: payload.companyName,
//         password: payload.password,
//         enableEmailNotifications: payload.enableEmailNotifications,
//       },
//     })
//     .json();
// };

export const registerDistributionPartner = (
  payload: IRegisterDistributionPartnerPayload
) => {
  const [firstName, lastName] = payload.fullName
    .replace('&', '\x01')
    .split('\x01');

  return spectrumApi
    .post('users/distribution-partner/register', {
      json: {
        firstName,
        lastName,
        email: payload.email,
        phoneNumber: payload.phone,
        companyName: payload.companyName,
        password: payload.password,
        referredBy: payload.referredBy,
        enableEmailNotifications: payload.enableEmailNotifications,
        captchaToken: payload.captchaToken,
        region: payload.region,
      },
    })
    .json();
};

export const registerUser = (payload: IRegisterPayload) => {
  return spectrumApi
    .post('users/clients/register', {
      json: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
        email: payload.email,
        country: payload.country,
        password: payload.password,
        enableEmailNotifications: payload.enableEmailNotifications,
        captchaToken: payload.captchaToken,
      },
    })
    .json();
};
export const registerAgency = (payload: IRegisterPayload) => {
  return spectrumApi
    .post('users/agency/register', {
      json: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
        email: payload.email,
        country: payload.country,
        password: payload.password,
        enableEmailNotifications: payload.enableEmailNotifications,
        captchaToken: payload.captchaToken,
      },
    })
    .json();
};

export const sendResetLink = (email?: string): Promise<any> => {
  return spectrumApi.get(`users/reset-password/${email}`);
};

export const sendDistributionPartnerResetLink = (
  email?: string
): Promise<any> => {
  return spectrumApi.get(`distribution-partner/reset-password/${email}`);
};

export const updateUserPassword = (
  newPassword?: string,
  token?: string
): Promise<any> => {
  return spectrumApi.put('users/reset-password', {
    json: {
      token,
      newPassword,
    },
  });
};

export interface IUserLoginPayload {
  email: string;
  password: string;
}

export interface IUserMoxoPayload {
  moxoToken: string;
}

export interface IUserLoginResponse {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  errorBody?: Record<any, any>;
}

export const loginUser = ({ email, password }: IUserLoginPayload) => {
  const searchParams = new URLSearchParams();
  searchParams.set('username', email);
  searchParams.set('password', password);
  searchParams.set('grant_type', 'password');
  searchParams.set('client_id', 'spectrum-frontend-dev');
  searchParams.set('client_secret', 'spectrum-frontend-dev-s3C5et!');

  return spectrumApi
    .post('oauth/token', {
      headers: {
        Accept: 'application/json',
      },
      body: searchParams,
    })
    .json<IUserLoginResponse>();
};

export const loginUserWithMoxo = ({ moxoToken }: IUserMoxoPayload) => {
  const searchParams = new URLSearchParams();
  searchParams.set('moxo_token', moxoToken);
  searchParams.set('grant_type', 'client_credentials');
  searchParams.set('client_id', 'NDkwN2RlNzk');
  searchParams.set('client_secret', 'NzI5MDgxYjE');

  return spectrumApi
    .post('oauth/token', {
      headers: {
        Accept: 'application/json',
      },
      body: searchParams,
    })
    .json<IUserLoginResponse>();
};

export const checkToken = (token: string): Promise<any> => {
  const searchParams = new URLSearchParams();
  searchParams.set('token', token);

  let url = 'oauth/check_token';
  url += `?${searchParams}`;

  return spectrumApi.get(url).json();
};

export interface IUserDataResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  userType: UserType;
  spectrumTrialUsed: boolean;
  mailingAddress: string;
  businessName: string;
}

export const getUserData = () => {
  return spectrumApi.get('users/self').json<IUserDataResponse>();
};

export const getProgramPaymentInfo = (programIdList: number[]) => {
  return spectrumApi
    .get(
      'users/self/spectrum-campaigns/payments?' +
        'programIdList=' +
        programIdList[0]
    )
    .json();
};

export const activateAccount = (token: string): Promise<any> => {
  return spectrumApi.get(`users/activate/${token}`);
};
