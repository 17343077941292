import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxControlledFileInput } from '_Components/PlxControlledFileInput';

interface IProps {
  className?: string;
  handleChange: (url: string, fileName: string) => void;
  fileName?: string;
  title: string;
  tooltip?: string;
  required?: boolean;
}

const StyledFileInput = styled(PlxControlledFileInput)`
  && {
    padding: 1rem 1.5rem;
  }
`;

export const UploadPresentationSection: FC<IProps> = ({
  handleChange,
  className,
  fileName,
  tooltip,
  title,
  required = false,
}) => {
  const [filenames, setFilenames] = useState(fileName);
  const acceptedFileTypes = [
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    '.doc',
    '.docx',
    '.ppt',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  useEffect(() => {
    setFilenames(fileName);
  }, [fileName]);

  const encodeFile = (file: any) =>
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => resolve(fr.result);
      fr.onerror = (error) => reject(error);
      if (file.size > 700000) {
        reject('File is too big. Max size is 700kb');
      }
      fr.readAsDataURL(file);
    });

  const getEncodedFiles = (files: any) => {
    return Promise.all(files.map(encodeFile));
  };

  const onFileChange = async (files: File[]) => {
    if (files) {
      getEncodedFiles(files)
        .then((values) => {
          const names = files.map((file) => file.name).join(', ');
          setFilenames(names);
          handleChange((values as string[])[0].split(',')[1], names);
        })
        .catch((e) => alert(e));
    }
  };

  return (
    <FormSection
      title={title}
      tooltip={tooltip}
      required={required}
      className={className}
    >
      <StyledFileInput
        fileName={filenames}
        onChange={onFileChange}
        accept={acceptedFileTypes.toString()}
      />
    </FormSection>
  );
};
