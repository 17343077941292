import styled from '@emotion/styled';
import {
  Dialog,
  DialogProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { getThriveCampaigns } from '_State/Thrive/Api/thrive.api';
import { IThriveCampaign } from '_State/Thrive/types';
import { getSpectrumCampaigns } from '_State/User/Api/user.api';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';

export const ROUTE_SPECTRUM_MAP = [
  {
    isApplicable: (campaigns: ISpectrumCampaign[]): boolean =>
      !!campaigns.find(
        (c) => c.status === 'COMPLETED' || c.status === 'PAUSED'
      ),
    linkPath: '/home/client/spectrum/overview',
    agencyLinkPath: '/home/agency/spectrum/overview',
  },
  {
    isApplicable: (campaigns: ISpectrumCampaign[]): boolean =>
      !!campaigns.find(
        (c) =>
          c.status === 'NEW' ||
          c.status === 'LAUNCHED' ||
          c.status === 'REVIEW_NEEDED' ||
          c.status === 'NEEDS_WORK'
      ),
    linkPath: '/home/client/spectrum/overview',
    agencyLinkPath: '/home/agency/spectrum/overview',
  },
  {
    isApplicable: (campaigns: ISpectrumCampaign[]): boolean => true,
    linkPath: '/home/client/spectrum-start',
    agencyLinkPath: '/home/agency/spectrum-start',
  },
];

export const ROUTE_THRIVE_MAP = [
  {
    isApplicable: (campaigns: IThriveCampaign[]): boolean =>
      !!campaigns.find(
        (c) =>
          c.status === 'COMPLETED' ||
          c.status === 'PAUSED' ||
          c.status === 'LAUNCHED'
      ),
    linkPath: '/home/client/thrive-dashboard',
    agencyLinkPath: '/home/agency/thrive-dashboard',
  },
  {
    isApplicable: (campaigns: IThriveCampaign[]): boolean =>
      !!campaigns.find(
        (c) =>
          c.status === 'NEW' ||
          c.status === 'REVIEW_NEEDED' ||
          c.status === 'NEEDS_WORK'
      ),
    linkPath: '/home/client/thrive-processing',
    agencyLinkPath: '/home/agency/thrive-processing',
  },
  {
    isApplicable: (campaigns: IThriveCampaign[]): boolean => true,
    linkPath: '/home/client/thrive-start',
    agencyLinkPath: '/home/agency/thrive-start',
  },
];

export const getSpectrumLink = async (isAgencyType?: boolean) => {
  try {
    return await getSpectrumCampaigns().then((v) => {
      const route = ROUTE_SPECTRUM_MAP.find((strategy) =>
        strategy.isApplicable(v.content)
      );
      if (route) {
        return isAgencyType ? route.agencyLinkPath : route.linkPath;
      }
    });
  } catch (httpError) {
    console.log(httpError);
  }
};

export const getThriveLink = async (isAgencyType?: boolean) => {
  try {
    return await getThriveCampaigns().then((v) => {
      const route = ROUTE_THRIVE_MAP.find((strategy) =>
        strategy.isApplicable(v.content)
      );
      if (route) {
        return isAgencyType ? route.agencyLinkPath : route.linkPath;
      }
    });
  } catch (httpError) {
    console.log(httpError);
  }
};

interface IFormatMap {
  isApplicable: (reach: number) => boolean;
  transform: (reach: number) => string;
}

export const FORMAT_QUANTITY_MAP: IFormatMap[] = [
  {
    isApplicable: (reach: number) => reach >= 1000000,
    transform: (reach: number) => `${(Math.abs(reach) / 1.0e6).toFixed(2)}M`,
  },
  {
    isApplicable: (reach: number) => reach >= 1000,
    transform: (reach: number) => `${(Math.abs(reach) / 1.0e3).toFixed(0)}K`,
  },
  {
    isApplicable: (reach: number) => true,
    transform: (reach: number) => `${reach}`,
  },
];

export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);
  useEffect(() => {
    const node: any = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref]);
  return [ref, value];
}
const getAvailableDates = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 2);

  const dates = [];
  let count = 0;

  while (count < 5) {
    if (tomorrow.getDay() !== 0 && tomorrow.getDay() !== 6) {
      const date = tomorrow.toISOString();
      dates.push(date);
      count++;
    }
    tomorrow.setDate(tomorrow.getDate() + 1);
  }

  return dates;
};
const values = getAvailableDates();
const value1 = values[0];
const value2 = values[1];
const value3 = values[2];
const value4 = values[3];
const value5 = values[4];

function formatDate(value: string) {
  const date = new Date(value);
  return date.toLocaleDateString('en-US', {
    timeZone: 'America/New_York',
    month: 'short',
    day: 'numeric',
  });
}
const label1 = formatDate(values[0]);
const label2 = formatDate(values[1]);
const label3 = formatDate(values[2]);
const label4 = formatDate(values[3]);
const label5 = formatDate(values[4]);

export const datesConfig: IIconButtonConfig[] = [
  {
    id: '1',
    label: label1,
    value: value1,
    isChosen: false,
  },
  {
    id: '2',
    label: label2,
    value: value2,
    isChosen: false,
  },
  {
    id: '3',
    label: label3,
    value: value3,
    isChosen: false,
  },
  {
    id: '4',
    label: label4,
    value: value4,

    isChosen: false,
  },
  {
    id: '5',
    label: label5,
    value: value5,
    isChosen: false,
  },
];

export const StyledForm = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 1.5rem 2.5rem 0 2.5rem;
  width: inherit;
  height: calc(100vh - 190px);
  overflow-y: scroll;
`;
export const StyledValidationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 1.5rem 2.5rem 0 2.5rem;
  width: inherit;
  height: calc(100vh - 130px);
`;
export const StyledValidationCompletedWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: inherit;
  height: calc(100vh - 190px);
  overflow-y: scroll;
`;
export const StyledValidationWindow = styled.div`
  text-align: center;
  justify-content: center;
  font-family: 'Quicksand';
  font-style: normal;
  padding: 2rem;
  background: #f6f6f6;
  border-radius: 24px;
`;
export const StyledCompleted = styled.div`
  background: #808285;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 23px;
`;
export const StyledImage = styled.img`
  margin: 0 auto 20px;
  height: 52px;
  width: 52px;
`;
export const StyledValCompleted = styled.h2`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
`;
export const StyledH1 = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #231f20;
  margin-bottom: 12px;
`;
export const StyledH2 = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #231f20;
  margin-bottom: 16px;
`;
export const StyledH3 = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;
export const StyledTip = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 480px;
  padding: 1rem 0.5rem;
`;
export const StyledTip2 = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5f5f61;
`;
export const Bold = styled.span`
  font-weight: 700;
`;
export const ButtonsValidateWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: auto;
  padding-right: auto;
  margin-bottom: 16px;
`;
export const StyledTitle = styled.h1`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 1.5rem;
  color: #231f20;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 220px;
`;
export const ButtonsSection = styled.div`
  display: flex;
  // position: fixed;
  // bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  height: 60px;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const StyledInstruction = styled.div`
  padding: 0 1rem 1rem;
`;

export const StyledInput = styled('div')(({ theme }) => ({
  position: 'relative',
  background: '#fff',
  border: '1px solid rgba(35, 31, 32, 0.16)',
  borderRadius: '6px',
  marginBottom: '0.5rem',
  paddingLeft: '2rem',
  width: '55%',
  height: '40px',
}));

export const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  backdropFilter: 'blur(5px)',
  '& .MuiPaper-rounded': {
    borderRadius: '16px',
  },
}));

export const useStyles = makeStyles((theme) => ({
  input: {
    paddingTop: '4px',
    width: '-webkit-fill-available',
    color: theme.palette.primary.contrastText,
    '&:hover': {},
  },
  button: {
    marginLeft: 'auto',
    '& .MuiIconButton-root': {
      padding: '10px 10px 0',
    },
  },
}));
