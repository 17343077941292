import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setCredentials } from '_Api/api';
import { authActions } from '_State/Auth/auth.state';
import {
  MenuItem,
  AppBar,
  Toolbar,
  Divider,
  Popover,
  Button,
} from '@material-ui/core';
import { CheckCircle, KeyboardArrowDown } from '@material-ui/icons';
import Avatar from '_Styles/images/Avatar.png';
import Notification from '_Styles/images/Notification.png';
import NotificationOpen from '_Styles/images/NotificationOpen.png';
import NoNotification from '_Styles/images/NoNotification.png';
import PendingIcon from '_Styles/images/PendingIcon.png';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '_State';
import {
  AvatarContainer,
  AvatarImage,
  CompanyName,
  StyledButtonRead,
  StyledContainer,
  StyledNotificationMessage,
  StyledNotificationMessageContainer,
  StyledNotificationTitle,
  useStyles,
  VerticalDivider,
  Wrapper,
} from './Styles';
import {
  clearNotifications,
  getNotifications,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { SelectClient } from './_routes/Spectrum/_Routes/AgencyClients/SelectClient';
// import { SearchIcon } from './NavigationPanel/Icons/SearchIcon';

interface IProps {
  isAgency?: boolean;
}
interface INotifications {
  id: number;
  read: boolean;
  userId: number;
  notification: string;
  type: string;
}

export const TopNavigation: FC<IProps> = ({ isAgency }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<INotifications[]>([]);
  const [
    anchorElNotification,
    setAnchorElNotification,
  ] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: IState) => state.user);
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const openNotification = Boolean(anchorElNotification);

  const id = openNotification ? 'simple-popover' : undefined;

  const clearAllNotifs = () => {
    setNotifications([]);
    clearNotifications();
  };

  useEffect(() => {
    getNotifications().then((notifications) => {
      setNotifications(notifications);
    });
  }, []);

  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    anchorElNotification === null
      ? setAnchorElNotification(event.currentTarget)
      : setAnchorElNotification(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        anchorElNotification &&
        !anchorElNotification.contains(event.target as HTMLElement)
      ) {
        setAnchorElNotification(null);
      }
      if (anchorEl && !anchorEl.contains(event.target as HTMLElement)) {
        setAnchorEl(null);
      }
    };
    if (openNotification) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openNotification, anchorEl, anchorElNotification]);

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setAnchorElNotification(null);
  };

  const handleLogoutClick = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('expires');
    sessionStorage.removeItem('clientSelected');
    dispatch(authActions.saveCredentials({ token: '', refreshToken: '' }));
    setCredentials({ token: undefined });
    history.push('/auth/login');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';

  const PopoverContent = () => {
    return (
      <div
        style={{
          width: 354,
          height: 'fit-content',
          padding: '16px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: notifications.length === 0 ? '0 10px' : '0 16px 16px',
            zIndex: 3,
          }}
        >
          <StyledNotificationTitle>
            {t('navBar.notification')} ({notifications.length})
          </StyledNotificationTitle>
          {notifications.length !== 0 && (
            <StyledButtonRead onClick={clearAllNotifs}>
              {t('navBar.clear')}
            </StyledButtonRead>
          )}
        </div>
        {notifications.length > 0 &&
          notifications.map((notification) => {
            return (
              <div key={notification.id}>
                <Divider />
                <div style={{ padding: '16px' }}>
                  <StyledNotificationMessageContainer>
                    {notification.type !== 'PROGRAM_VALIDATION_COMPLETED' && (
                      <AvatarImage
                        style={{
                          width: '14.5px',
                          height: '14.5px',
                          margin: 'auto 10px auto 0',
                        }}
                        src={PendingIcon}
                      />
                    )}
                    {notification.type === 'PROGRAM_VALIDATION_COMPLETED' && (
                      <CheckCircle
                        style={{
                          fill: '#258004',
                          height: '16px',
                          width: '16px',
                          margin: 'auto 10px auto 0',
                        }}
                      />
                    )}
                    <StyledNotificationMessage>
                      {notification.notification}
                    </StyledNotificationMessage>
                  </StyledNotificationMessageContainer>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const MenuContent = () => {
    return (
      <div
        style={{
          width: 100,
          height: 'fit-content',
          padding: '0 auto',
        }}
      >
        <div
          onClick={() => {
            history.push(`/home/${user?.userType.toLowerCase()}/profile`);
          }}
        >
          <MenuItem>{t('navBar.profile')}</MenuItem>
        </div>
        <Divider />
        <div onClick={handleLogoutClick}>
          <MenuItem>{t('navBar.logOut')}</MenuItem>
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      <AppBar
        style={{
          height: '72px',
          background: '#fff',
          position: 'static',
          boxShadow: '0px 1px 0px #f2f2f2',
        }}
      >
        <Toolbar style={{ paddingRight: 0 }}>
          {/* <Search>
            <IconContainer>
              <SearchIcon />
              <KeyboardArrowDown
                style={{ display: 'inline-flex', marginLeft: '5px' }}
              />
            </IconContainer>
            <StyledInputBase
              placeholder={t('navBar.search')}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}

          {isAgency && <SelectClient />}

          <StyledContainer>
            <Button
              classes={{ root: classes.button }}
              onClick={handleClickNotification}
            >
              <AvatarImage
                style={{
                  width: notifications.length > 0 ? 24 : 15,
                  height: notifications.length > 0 ? 24 : 19,
                  margin: 8,
                }}
                src={
                  notifications.length !== 0
                    ? openNotification
                      ? NotificationOpen
                      : Notification
                    : NoNotification
                }
              />
            </Button>
            <Popover
              classes={{ root: classes.popover }}
              id={id}
              open={openNotification}
              anchorEl={anchorElNotification}
              onClose={handleCloseNotification}
              disableScrollLock
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <PopoverContent />
            </Popover>
            <VerticalDivider />
            <CompanyName>{user?.firstName}</CompanyName>
            <AvatarContainer>
              <AvatarImage src={Avatar} />
            </AvatarContainer>
            <Button
              classes={{ root: classes.button }}
              onClick={handleProfileMenuOpen}
            >
              <KeyboardArrowDown />
            </Button>
            <Popover
              classes={{ root: classes.menuPopover }}
              anchorEl={anchorEl}
              id={menuId}
              open={isMenuOpen}
              onClose={handleMenuClose}
              disableScrollLock
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <MenuContent />
            </Popover>
          </StyledContainer>
        </Toolbar>
      </AppBar>
    </Wrapper>
  );
};
