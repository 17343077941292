import {
  ICampaigns,
  ICampaignsQueryParams,
  IPaymentHistory,
  ICompanyNameQueryParams,
} from '_Types/campaigns';
import { spectrumApi } from '_Api/api';
import {
  ISpectrumCampaign,
  ISpectrumCampaignOptions,
} from '_State/Spectrum/types';
import { ICompanyResponse } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/types';
import moment from 'moment';
import { IChangePasswordDTO, IUserUpdateDTO } from '_State/User/user.types';

export const getCampaign = (id: number | string | undefined): Promise<any> => {
  return spectrumApi.get(`users/self/spectrum-campaigns/${id}`).json();
};

export const getClients = (): Promise<any> => {
  return spectrumApi.get('agencies/clients?pageSize=1000').json();
};

export const getSpectrumCampaigns = (
  params?: ICampaignsQueryParams,
  agencyClientId?: string | null
): Promise<ICampaigns<ISpectrumCampaign>> => {
  let url = 'users/self/spectrum-campaigns';
  if (params) {
    const urlParams = new URLSearchParams({
      sortDirection: params.sortDirection,
      sortByField: params.sortByField,
    });
    if (params.tagIdList.length > 0) {
      urlParams.append('tagIdList', params.tagIdList.join(','));
    }
    if (params.campaignStatusList.length > 0) {
      urlParams.append(
        'campaignStatusList',
        params.campaignStatusList.join(',')
      );
    }
    if (params.agencyClientId) {
      urlParams.append('agencyClientId', params.agencyClientId);
    }
    if (agencyClientId !== null && agencyClientId !== undefined) {
      urlParams.append('agencyClientId', agencyClientId);
    }
    urlParams.append('pageSize', '10000');
    url += `?${urlParams}`;
  }

  return spectrumApi.get(url).json();
};

export const getSpectrumCampaignsOptions = (
  userId: number,
  params?: Record<string, any>
): Promise<ICampaigns<ISpectrumCampaignOptions>> => {
  let url = 'users/self/spectrum-campaigns/basic-info';
  const urlParams = new URLSearchParams();

  urlParams.append('campaignStatusList', 'COMPLETED');
  urlParams.append('userId', userId.toString());
  urlParams.append('pageSize', '10000');

  if (params?.startDate) {
    const from = moment(params?.startDate);

    if (from.isValid()) {
      urlParams.append('from', from.format('yyyy-MM-DD'));
    }
  }

  if (params?.endDate) {
    const to = moment(params?.endDate);

    if (to.isValid()) {
      urlParams.append('to', to.format('yyyy-MM-DD'));
    }
  }

  url += `?${urlParams}`;

  return spectrumApi.get(url).json();
};

export const getDashboardStats = (statusList: string[]): Promise<any> => {
  const url = `spectrum-campaigns/dashboard-statistics?campaignStatusList=${statusList.join(
    ','
  )}`;

  return spectrumApi.get(url).json();
};

export const getCompanies = (
  params?: ICompanyNameQueryParams
): Promise<ICompanyResponse> => {
  let url = 'users/company-names?pageSize=300';
  if (params) {
    const urlParams = new URLSearchParams({
      type: params.type,
    });
    url += `&${urlParams}`;
  }
  return spectrumApi.get(url).json();
};

export const updateUserData = (userData: IUserUpdateDTO): Promise<any> => {
  return spectrumApi.put('users/self', { json: { ...userData } });
};

export const changeUserPassword = (
  changePasswordDTO: IChangePasswordDTO
): Promise<any> => {
  return spectrumApi.put('users/change-password', {
    json: { ...changePasswordDTO },
  });
};

export const getPaymentHistory = (): Promise<IPaymentHistory[]> => {
  return spectrumApi
    .get('users/self/payments')
    .json()
    .then((values: any) => {
      return values.map((item: IPaymentHistory) => {
        return {
          ...item,
          paymentDate: moment(item.paymentDate).format('DD/MM/YY'),
        };
      });
    });
};
