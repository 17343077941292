import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  createSpectrumTag,
  getDraft,
  getProgramActive,
  getSpectrumTags,
  saveProgramDraft,
  updateProgramDraft,
} from '_State/Spectrum/Api/spectrum.api';
import { useHistory } from 'react-router-dom';
import { ISpectrumCampaign, IProgramDraft } from '_State/Spectrum/types';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import {
  ButtonsSection,
  ButtonsWrapper,
  getSpectrumLink,
  StyledForm,
  StyledTitle,
} from '_Routes/Home/_routes/Spectrum/utils'; /* 
import {
  ISliderConfig,
  ReachSection,
} from '_Routes/Home/_Components/Sections/ReachSection/ReachSection'; */
import { TagSection } from '_Routes/Home/_Components/Sections/TagSection';
import { StockSection } from '_Routes/Home/_Components/Sections/StockSection/StockSection';
import {
  geoConfig,
  industryConfig,
} from '_Routes/Home/_routes/Spectrum/config';
import { ITag } from '_Types/campaigns';
import { mapTranslatedLabel } from '_App/utils';
import { PlxButtonGroupSection } from '_Routes/Home/_Components/Sections/PlxButtonGroupSection';
import { CircularProgress } from '@material-ui/core';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 220px;
`;
const INITIAL_VALUES: IProgramDraft = {
  industry: [],
  geolocation: [],
  tag: undefined,
  stockTickerList: undefined,
};
const REQUIRED_FIELDS = ['industry', 'geolocation'];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);
export const SpectrumProgram: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [draft, setDraft] = useState<ISpectrumCampaign>();
  const [tags, setTags] = useState<ITag[]>([]);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);
  // const [reachConfig, setReachConfig] = useState<ISliderConfig[]>([]);
  // const [reachValue, setReachValue] = useState(0);
  // const [program, setProgram] = useState<ISpectrumProgram>();
  // const [reachSectionDisabled, setReachSectionDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );
  const fetchSpectrumTags = () => {
    getSpectrumTags().then((v) => {
      setTags(v);
      return v;
    });
  };
  const saveDraft = (values: any) => {
    if (draft) {
      return updateProgramDraft(values, clientSelected).then();
    } else {
      return saveProgramDraft(values, clientSelected).then();
    }
  };
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      const formValues = form;
      const stockTickers = form.stockTickerList;
      if (stockTickers) {
        formValues.stockTickerList = stockTickers.filter((stock) => !!stock);
      }
      if (requiredFormValidation(form)) {
        saveDraft(formValues).then(() => {
          history.push(
            isAgencyType
              ? '/home/agency/spectrum-campaign/campaign'
              : '/home/client/spectrum-campaign/campaign'
          );
        });
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.geolocation && form.geolocation.length < 1) {
        errors.geolocation = t('errors.reqField');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const fillForm = (form: ISpectrumCampaign) => {
    formik.setValues({
      industry: form.industry,
      geolocation: form.geolocation,
      tag: form.tag?.id,
      stockTickerList: form.stockTickerList,
    });
  };

  useEffect(() => {
    getProgramActive(clientSelected).then(
      (program) => {
        getDraft(clientSelected).then(
          (v) => {
            if (v) {
              fillForm(v);
              setDraft(v);
              // setProgram(program);
              setLoading(false);
            } else {
              setLoading(false);
            }
          },
          () => {
            // setProgram(program);
            setLoading(false);
            // setReachSectionDisabled(true);
            return null;
          }
        );
      },
      () => {
        getDraft(clientSelected).then(
          (v) => {
            if (v) {
              fillForm(v);
              setDraft(v);
              if (v.payment) {
                if (
                  v.payment.paymentStatus === 'IN_PROGRESS' ||
                  v.payment.paymentStatus === 'FAILED'
                ) {
                  if (v.payment.paymentMethod === 'WIRE_TRANSFER') {
                    history.push('/home/client/spectrum-campaign/payment/wire');
                  } else if (v.payment.paymentMethod === 'CHECK') {
                    history.push(
                      '/home/client/spectrum-campaign/payment/check'
                    );
                  } else {
                    setDraft(v);
                    setLoading(false);
                  }
                }
              } else {
                setDraft(v);
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          },
          () => {
            setLoading(false);
            return null;
          }
        );
      }
    );
    fetchSpectrumTags();
    // eslint-disable-next-line
  }, []);

  const onTagChange = (fieldName: string, tagName: string) => {
    const tag = tags.find(({ name }) => name === tagName);
    if (tag) {
      formik.setFieldValue(fieldName, tag.id);
    } else {
      // NOTE: here we have race condition
      createSpectrumTag(tagName).then(({ id }) =>
        formik.setFieldValue(fieldName, id)
      );
    }
  };
  const industryInitialValue = useMemo(
    () => (draft?.industry ? draft?.industry : undefined),
    [draft]
  );
  const industryOptions = industryConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );
  const handleDeleteStock = () => {
    const stockTickers = formik.values.stockTickerList;
    formik.setFieldValue(
      'stockTickerList',
      stockTickers?.slice(0, stockTickers?.length - 1)
    );
  };

  // const categoryFilled = formik.values.contentCategories
  //   ? formik.values.contentCategories.length > 0
  //     ? true
  //     : false
  //   : false;

  const industryFilled = formik.values.industry
    ? formik.values.industry?.length > 0
    : false;
  const geoFilled = formik.values.geolocation
    ? formik.values.geolocation?.length > 0
    : false;

  const isButtonReady = industryFilled && geoFilled ? true : false;

  return (
    <>
      {loading ? (
        <StyledCircularProgress color="secondary" />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={formik.handleSubmit}>
            <StyledForm>
              <StyledTitle>{t('spectrum.program.configure')}</StyledTitle>
              <PlxButtonGroupSection
                otherOption={true}
                buttonsConfig={industryOptions}
                multiple={true}
                search={true}
                title={t('spectrum.program.industry')}
                tooltip={t('spectrum.program.industryTooltip')}
                placeholder={t('spectrum.program.fillIndustry')}
                inputTitle={t('spectrum.program.specifyIndustry')}
                value={industryInitialValue}
                required
                setValue={formik.setFieldValue}
                fieldName={'industry'}
                translateY={15}
                searchPlaceholder={t('spectrum.program.search')}
              />
              {formik.errors.industry && (
                <FieldErrorMessage message={formik.errors.industry} />
              )}
              <PlxButtonGroupSection
                title={t('spectrum.program.geoLocation')}
                tooltip={t('spectrum.program.geoLocationTooltip')}
                multiple={true}
                required
                value={draft?.geolocation}
                fieldName={'geolocation'}
                setValue={formik.setFieldValue}
                buttonsConfig={geoConfig.map((item) => {
                  return { ...item, label: t(item.label) };
                })}
              />
              {formik.errors.geolocation && (
                <FieldErrorMessage message={formik.errors.geolocation} />
              )}

              <TagSection
                newDesign
                title={t('spectrum.program.campaignName')}
                placeholder={t('spectrum.program.searchCampaign')}
                tags={tags}
                value={draft?.tag?.id}
                tagValueId={[draft?.tag?.id.toString()]}
                fieldName={'tag'}
                setValue={formik.setFieldValue}
                onTagChange={onTagChange}
              />
              {formik.errors.tag && (
                <FieldErrorMessage message={formik.errors.tag} />
              )}
              <StockSection
                newDesign
                value={draft?.stockTickerList}
                fieldName={'stockTickerList'}
                setValue={formik.setFieldValue}
                onDelete={handleDeleteStock}
              />
              <FieldErrorMessage message={formik.errors.stockTickerList} />
            </StyledForm>

            <ButtonsSection>
              <PlxButtonX
                newDesign
                size="small"
                onClick={() => {
                  history.push(
                    isAgencyType
                      ? '/home/agency/spectrum-campaign/payment'
                      : '/home/client/spectrum-campaign/payment'
                  );
                }}
                label={t('spectrum.footerButtons.back')}
              />
              <ButtonsWrapper>
                <PlxButtonX
                  newDesign
                  size="small"
                  onClick={() =>
                    saveDraft(formik.values).then(() =>
                      getSpectrumLink().then((link) => {
                        if (link) {
                          history.push(link);
                        }
                      })
                    )
                  }
                  label={t('spectrum.footerButtons.save')}
                />
                <PlxButtonX
                  newDesign
                  isDisabled={!isButtonReady}
                  size="small"
                  inputType={'submit'}
                  label={t('spectrum.footerButtons.next')}
                  variant2
                />
              </ButtonsWrapper>
            </ButtonsSection>
          </form>
        </div>
      )}
    </>
  );
};
