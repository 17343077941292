import React, { ChangeEvent, FC, useContext, useState } from 'react';
import styled from '@emotion/styled';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { EditIcon } from '_Styles/images/_Icons/EditIcon';
import { PlxImageDialog } from '_Components/Dialogs/PlxImageDialog';
import { IAsset } from '_State/Thrive/types';
import { useTranslation } from 'react-i18next';
import { AssetsContext } from '_Types/assetsContext';

const AssetBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstructionsWrapper = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  width: 37rem;
  height: 14rem;
  overflow-y: auto;
  padding: 1rem 2rem;
  font-size: 14px;
  line-height: 30px;
`;

const RequestButton = styled(PlxButton)`
  && {
    margin: 2rem 0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 50%;
  cursor: pointer;
`;

const ImageWrapper = styled.div<{ src?: string }>`
  height: 28.5rem;
  width: 37rem;
  border: 1px solid rgba(213, 221, 227, 0.5);
  border-radius: 5px;
  background: url(${({ src }) => src}) center no-repeat;
  position: relative;
  :hover {
    cursor: pointer;
    filter: brightness(50%);
  }
`;

interface IProps {
  asset?: IAsset;
  onImageClick?: (id: number) => void;
}

export const Asset: FC<IProps> = ({ asset, onImageClick }) => {
  const [instructionsOpened, setInstructionsOpened] = useState(false);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [mouseHovered, setMouseHovered] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<string>('');
  const { admin, addAssetInstructions, deleteAssetNotification } = useContext(
    AssetsContext
  );
  const handleMouseEnter = () => {
    if (admin) {
      setMouseHovered(true);
    }
  };
  const handleMouseLeave = () => {
    if (admin) {
      setMouseHovered(false);
    }
  };

  const handleInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInstructions(value);
    if (addAssetInstructions && asset?.id) {
      addAssetInstructions(asset?.id, value);
    }
  };
  const handleImageClick = () => {
    if (admin) {
      onImageClick && asset?.id && onImageClick(asset.id);
    } else {
      setOpenDialog(true);
    }
  };

  const handleButtonClick = () => {
    if (admin && deleteAssetNotification && asset?.notification) {
      deleteAssetNotification(asset?.id, asset?.notification.id);
    }
    setInstructionsOpened(!instructionsOpened);
  };

  return (
    <AssetBody>
      {asset && (
        <ImageWrapper
          src={asset?.screenshotUrl}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleImageClick}
        >
          {mouseHovered && (
            <IconWrapper>
              <EditIcon />
            </IconWrapper>
          )}
        </ImageWrapper>
      )}

      <RequestButton
        notificationQuantity={asset?.notification?.instructions ? 1 : undefined}
        label={
          admin
            ? t('thrive.admin.clientInstructions')
            : t('thrive.dashboard.requestUpdate')
        }
        variant={ButtonVariant.Outlined}
        onClick={handleButtonClick}
      />
      {instructionsOpened &&
        (admin ? (
          <InstructionsWrapper>
            {asset?.notification?.instructions}
          </InstructionsWrapper>
        ) : (
          <PlxInput
            value={instructions}
            placeholder={t('thrive.dashboard.requestUpdatePlaceholder')}
            rows={5}
            rowsMax={10}
            multiline
            onChange={handleInstructionsChange}
          />
        ))}
      <PlxImageDialog
        image={asset?.screenshotUrl}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
    </AssetBody>
  );
};
