import { Button, Menu, MenuItem, makeStyles, Link } from '@material-ui/core';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React, { useState, FC, useContext } from 'react';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { SelectClientPopup } from './SelectClientPopup';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  button: {
    padding: '18.5px 16px',
    margin: '0 1rem',
    backgroundColor: '#231F20',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#808285',
      color: '#fff',
    },
  },
});

interface IProps {
  spectrumCampaigns: ISpectrumCampaign[];
  openTags?: () => void;
}

export const PlxButtonMenu: FC<IProps> = ({ spectrumCampaigns, openTags }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectClientModal, setSelectClientModal] = useState<boolean>(false);
  const { isAgencyType, clientSelected } = useContext(NavigationContext);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const editDialogOpen = () => {
    setSelectClientModal(true);
  };

  const closeEditDialog = () => {
    setSelectClientModal(false);
  };

  const pushToNewCampaing = () => {
    spectrumCampaigns?.length === 0
      ? history.push(
          isAgencyType
            ? '/home/agency/spectrum-start'
            : '/home/client/spectrum-start'
        )
      : history.push(
          isAgencyType
            ? '/home/agency/spectrum-campaign/payment'
            : '/home/client/spectrum-campaign/payment'
        );
  };

  return (
    <div>
      <Button
        style={{ background: open ? '#808285' : '' }}
        className={classes.button}
        size={'small'}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowUp style={{ fill: '#fff' }} />
          ) : (
            <KeyboardArrowDown style={{ fill: '#fff' }} />
          )
        }
      >
        Add new
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            left: '50%',
            transform: 'translateX(0%) translateY(125%)',
          },
        }}
      >
        <div>
          <MenuItem
            component={Link}
            onClick={
              isAgencyType && clientSelected === null
                ? editDialogOpen
                : pushToNewCampaing
            }
            style={{ justifyContent: 'flex-end', color: '#231f20' }}
            disableRipple
          >
            New Campaign
          </MenuItem>
        </div>

        {/* <MenuItem
          style={{ justifyContent: 'flex-end' }}
          onClick={openTags ? openTags : handleClose}
          disableRipple
        >
          Tag
        </MenuItem> */}
      </Menu>
      <SelectClientPopup
        campaignLenght={spectrumCampaigns?.length}
        selectClientModal={selectClientModal}
        closeEditDialog={closeEditDialog}
        clientSelected={clientSelected}
        pushToNewCampaing={pushToNewCampaing}
      />
    </div>
  );
};
