import React, { FC, ReactNode } from 'react';
import { ICommonProps } from '_Types/props';
import { TextSizes, IThemed } from '_Styles/types';
import { css, jsx } from '@emotion/core';
import { themeGetters } from '_Styles/themeHelpers';
import { Tooltip } from '@material-ui/core';

export enum TextType {
  title = 'title',
  text = 'text',
  label = 'label',
}

const { fontSizes } = themeGetters;

interface IProps extends ICommonProps {
  type?: TextType;
  size?: TextSizes;
  tooltipText?: string;
}

const createComponent = (
  element: string,
  size: TextSizes,
  type: TextType,
  props: any,
  children: ReactNode | ReactNode[]
) => {
  const fontSize = fontSizes(type, size);

  const styles = (theme: IThemed) => css`
    font-size: ${fontSize(theme)};
    margin: 0;
    padding: 0;
  `;

  const extendedProps = { ...props, css: styles };

  return jsx(element, extendedProps, children);
};

const ELEMENT_STRATEGY = (
  size: TextSizes,
  type: TextType,
  props: any,
  children: ReactNode | ReactNode[]
) => [
  {
    condition: type === TextType.label,
    component: createComponent('label', size, type, props, children),
  },
  {
    condition: type === TextType.text,
    component: createComponent('span', size, type, props, children),
  },
  {
    condition: size === TextSizes.S,
    component: createComponent('h3', size, type, props, children),
  },
  {
    condition: size === TextSizes.M,
    component: createComponent('h2', size, type, props, children),
  },
  {
    condition: size === TextSizes.L,
    component: createComponent('h1', size, type, props, children),
  },
];

const getComponent = (
  size: TextSizes,
  type: TextType,
  props: any,
  children: ReactNode | ReactNode[]
) => {
  const title = ELEMENT_STRATEGY(size, type, props, children).find(
    (strategy) => strategy.condition
  );
  return title ? title.component : null;
};

export const PlxText: FC<IProps> = ({
  children,
  type = TextType.text,
  size = TextSizes.S,
  tooltipText,
  ...props
}) => {
  const element = getComponent(size, type, props, children);

  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText}>
          <span>{element}</span>
        </Tooltip>
      ) : (
        <>{element}</>
      )}
    </>
  );
};
