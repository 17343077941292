import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ActivationWrapperX } from '_Routes/Auth/_Components/ActivationWrapperX';
import { AuthText } from '_Routes/Auth/_Components/AuthText';
import { SuccessInfo } from '_Routes/Auth/_Components/SuccessInfo';

const StyledAuthText = styled(AuthText)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 2rem;
`;

interface IProps {
  pathLogin: string;
}

export const RegisterConfirm: FC<IProps> = ({ pathLogin }) => {
  const { t } = useTranslation();
  return (
    <ActivationWrapperX>
      <SuccessInfo text={t('auth.register.confirmTitle')} />
      <StyledAuthText>{t('auth.register.confirm')}</StyledAuthText>
    </ActivationWrapperX>
  );
};
