import React, { FC } from 'react';
import { TextField } from '@material-ui/core';

export const UpdateValue: FC<{
  value: string;
  keyValue: string;
  handleSubmit?: (n: string, v: string) => void;
}> = ({ value, keyValue, handleSubmit }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState(value);
  const handleChange = (e: any) => {
    setFieldValue(e.target.value);
  };
  const handleBlur = (e: any) => {
    setIsFocused(false);
    handleSubmit && handleSubmit(keyValue, fieldValue);
  };
  return (
    <>
      {!isFocused ? (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsFocused(true);
          }}
        >
          {value === '' ? (
            <span style={{ color: '#b1b1b1' }}>click to edit</span>
          ) : (
            value
          )}
        </div>
      ) : (
        <TextField
          autoFocus
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </>
  );
};
