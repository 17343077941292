import React from 'react';

export const InfoIcon = () => {
  return (
    <div style={{ marginRight: '7px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.5"
        height="13.5"
        viewBox="0 0 32 32"
      >
        <path d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm-2 14a2 2 0 0 1 4 0v10a2 2 0 0 1-4 0V14zm2-3.968a2 2 0 1 1 .001-4.001A2 2 0 0 1 16 10.032z" />
      </svg>
    </div>
  );
};
