import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ITile } from '_Types/ITile';
import { IThemed } from '_Styles/types';
import { FORMAT_QUANTITY_MAP } from '_Routes/Home/_routes/Spectrum/utils';
import { ActionsInput } from '_Components/Panels/PlxTilesPanel/ActionsInput';

const Wrapper = styled.div<IThemed & { tilesQuantity: number }>`
  margin: ${({ theme }) => theme.spacing(0, 3, 0, 0)};
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${({ tilesQuantity }) => `${100 / tilesQuantity}%`};
  &:last-of-type {
    margin: 0;
  }
`;

const Label = styled.div`
  color: #a9a9a9;
  font-size: 1.1rem;
  padding-left: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Amount = styled.div<IThemed & { editable?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  :hover {
    background-color: ${({ editable }) => (editable ? '#F4F4F4' : 'white')};
    transition: background-color 0.2s;
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  background-color: #f8f9fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 1rem 1.5rem 0 0;
  padding: 2.5rem 1.7rem 2.8rem;
  text-align: center;
  // width: 100%;
`;

interface IProps extends ITile {
  tilesQuantity: number;
  onChange?: (tileName: string, value: any) => void;
  className?: string;
}
export const PlxTileX: FC<IProps> = ({
  label,
  amount,
  tilesQuantity,
  editable,
  formControlName,
  onChange,
  className,
}) => {
  const [focused, setFocused] = useState(false);
  const [amountValue, setAmountValue] = useState<any>();
  useEffect(() => {
    setAmountValue(amount);
  }, [amount]);

  const getFormattedAmount = (quantity: number) => {
    return FORMAT_QUANTITY_MAP.find((amount) =>
      amount.isApplicable(quantity)
    )?.transform(quantity);
  };

  const handleConfirm = (value: any) => {
    setAmountValue(value);
    setFocused(false);
    formControlName && onChange && onChange(formControlName, value);
  };

  const handleCancel = () => {
    setFocused(false);
  };

  return (
    <Wrapper tilesQuantity={tilesQuantity} className={className}>
      <Label>{label}</Label>
      <TextWrapper>
        {editable ? (
          focused ? (
            <InputWrapper>
              <ActionsInput
                amount={amountValue}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            </InputWrapper>
          ) : (
            <Amount editable onClick={() => setFocused(!focused)}>
              {isNaN(amountValue)
                ? amountValue
                : getFormattedAmount(amountValue)}
            </Amount>
          )
        ) : (
          <Amount>
            {isNaN(amountValue) ? amountValue : getFormattedAmount(amountValue)}
          </Amount>
        )}
      </TextWrapper>
    </Wrapper>
  );
};
