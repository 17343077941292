import React, { FC, useEffect, useState } from 'react';
import { PlxStepper } from '_Components/PlxStepper/PlxStepper';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { HomePageWrapper } from '_Routes/Home/_Components/HomePageWrapper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '_State';
import { useLocation, Redirect, Route, Link } from 'react-router-dom';
import { spectrumActions } from '_State/Spectrum/spectrum.state';
import { SPECTRUM_PROGRESS_STRATEGY } from '_State/Spectrum/spectrum.types';
import { FormWrapper } from '_Components/Wrappers/FormWrapper';
import { SpectrumProgram } from '_Routes/Home/_routes/Spectrum/_Routes/Program/SpectrumProgram';
import { SpectrumPayment } from '_Routes/Home/_routes/Spectrum/_Routes/Payment/SpectrumPayment';
import { SpectrumCampaign } from '_Routes/Home/_routes/Spectrum/_Routes/Campaign/SpectrumCampaign';
import { SpectrumScheduling } from '_Routes/Home/_routes/Spectrum/_Routes/Scheduling/SpectrumScheduling';
import { SpectrumPayViaCheck } from './_Routes/Payment/SpectrumPayViaCheck';
import { SpectrumPayViaWire } from './_Routes/Payment/SpectrumPayViaWire';
import { SpectrumValidation } from './_Routes/Payment/SpectrumValidation';
const stepperConfig = [
  'spectrum.tracker.validation',
  'spectrum.tracker.program',
  'spectrum.tracker.campaign',
  'spectrum.tracker.scheduling',
];

const SpectrumWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  flex: 1;
`;

const SpectrumLinkNav = styled.div`
  padding: 2rem 0 0 2.5rem;
`;

const LinkText = styled.p`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #231f20;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(35, 31, 32, 0.5);
`;

const Stepper = styled.div<IThemed>`
  width: 100%;
  display: flex;
  height: 100%;
  background-color: #f8f8f8;
  text {
    fill: #fff;
    font-weight: 700;
  }
`;
const StepperWrap = styled.div`
  display: flex;
  justify-content: start;
  width: 30%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const Spectrum: FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const step = useSelector<IState, string>((state) => state.spectrum.step);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line
    Object.keys(SPECTRUM_PROGRESS_STRATEGY).filter((o) => {
      let loc = location.pathname
        .substring(location.pathname.lastIndexOf('/') + 1)
        .toLowerCase();
      if (o.toUpperCase().includes(loc.toUpperCase())) {
        dispatch(spectrumActions.changeStep({ step: loc }));
      }
    });
    setActiveStep((SPECTRUM_PROGRESS_STRATEGY as any)[step]);
    // eslint-disable-next-line
  }, [location, step, activeStep]);
  const handleStepClick = (index: number) => {};

  return (
    <SpectrumWrapper>
      <SpectrumLinkNav>
        <LinkText>
          <StyledLink to={'/home/client/spectrum/campaigns'}>
            {t('spectrum.myCampaigns')}
          </StyledLink>{' '}
          / {t('spectrum.newCampaign')}
        </LinkText>
      </SpectrumLinkNav>
      <Wrapper>
        <StepperWrap>
          <Stepper>
            <PlxStepper
              onStepClick={handleStepClick}
              steps={stepperConfig.map((v) => t(v))}
              activeStep={activeStep}
            />
          </Stepper>
        </StepperWrap>
        <HomePageWrapper newDesign>
          <FormWrapper>
            <Route exact path="/home/client/spectrum-campaign">
              <Redirect to={'/home/client/spectrum-campaign/payment'} />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/program">
              <SpectrumProgram />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/payment">
              <SpectrumValidation />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/payment/wire">
              <SpectrumPayViaWire />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/payment/check">
              <SpectrumPayViaCheck />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/campaign">
              <SpectrumCampaign />
            </Route>
            <Route exact path="/home/client/spectrum-campaign/scheduling">
              <SpectrumScheduling />
            </Route>
            <Route path="/home/client/spectrum-campaign/payment/success">
              <SpectrumPayment />
            </Route>
            <Route path="/home/client/spectrum-campaign/payment/failed">
              <SpectrumPayment />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign">
              <Redirect to={'/home/agency/spectrum-campaign/payment'} />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/program">
              <SpectrumProgram />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/payment">
              <SpectrumValidation />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/payment/wire">
              <SpectrumPayViaWire />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/payment/check">
              <SpectrumPayViaCheck />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/campaign">
              <SpectrumCampaign />
            </Route>
            <Route exact path="/home/agency/spectrum-campaign/scheduling">
              <SpectrumScheduling />
            </Route>
            <Route path="/home/agency/spectrum-campaign/payment/success">
              <SpectrumPayment />
            </Route>
            <Route path="/home/agency/spectrum-campaign/payment/failed">
              <SpectrumPayment />
            </Route>
          </FormWrapper>
        </HomePageWrapper>
      </Wrapper>
    </SpectrumWrapper>
  );
};
