import React, { FC } from 'react';
import styled from '@emotion/styled';
import { XemInfoPanel } from '_Components/Panels/PlxInfoPanel/XemInfoPanel';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { changeUserPassword } from '_State/User/Api/user.api';
import { ErrMessage } from '_Routes/Auth/DistributionPartner/Register/StyleDP';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ChangePassSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Old Password Required'),
  newPassword: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('New Password Required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm New Password Required'),
});
interface Values {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const PasswordChangeBody = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 0.75rem;
  padding-top: 0.5rem;
  > div fieldset {
    border-color: #d5dde3;
  }
  .MuiInputLabel-outlined {
    transform: translate(20px, 11px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;

const StyledButton = styled(PlxButtonX)`
  && {
    // width: 100%;
  }
`;

const ConfirmPassWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
`;
interface IProps {
  onPasswordChange: (error?: boolean) => void;
}
export const XemPasswordChange: FC<IProps> = ({ onPasswordChange }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    onSubmit: (values: Values) => {
      setTimeout(() => {
        const { oldPassword, newPassword } = values;
        changeUserPassword({ oldPassword, newPassword }).then(
          () => onPasswordChange(false),
          () => onPasswordChange(true)
        );
      }, 500);
    },
    validationSchema: ChangePassSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  return (
    <PasswordChangeBody>
      <XemInfoPanel label={t('profile.changePassword')}>
        <Wrapper>
          <TextField
            name="oldPassword"
            type="password"
            onChange={formik.handleChange}
            variant="outlined"
            label={t('profile.oldPassword')}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            data-test-id="old-password-field"
          />
          <br />
          <ConfirmPassWrap>
            <TextField
              name={'newPassword'}
              type={'password'}
              onChange={formik.handleChange}
              variant="outlined"
              label={t('profile.newPassword')}
              data-test-id="new-password-field"
            />
            <div style={{ height: 5 }} />

            <TextField
              name={'confirmNewPassword'}
              type={'password'}
              onChange={formik.handleChange}
              variant="outlined"
              label={t('profile.confirmNewPassword')}
              data-test-id="confirm-password-field"
            />
            <ErrMessage data-test-id="new-password-error">
              {formik.errors.oldPassword && <p>{formik.errors.oldPassword}</p>}
            </ErrMessage>
            <ErrMessage data-test-id="confirm-password-error">
              {formik.errors.newPassword && <p>{formik.errors.newPassword}</p>}
            </ErrMessage>
            <ErrMessage data-test-id="confirm-password-error">
              {formik.errors.confirmNewPassword && (
                <p>{formik.errors.confirmNewPassword}</p>
              )}
            </ErrMessage>
          </ConfirmPassWrap>
          <StyledButton
            onClick={formik.handleSubmit}
            label={t('profile.changePassword')}
            isDisabled={!formik.errors}
            testId="change-password-button"
          />
        </Wrapper>
      </XemInfoPanel>
    </PasswordChangeBody>
  );
};
