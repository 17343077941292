import React, { FC, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { PlxTilesPanel } from '_Components/Panels/PlxTilesPanel/PlxTilesPanel';
import { PlxCampaignInformation } from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { useParams } from 'react-router-dom';
import {
  ISpectrumCampaign,
  IPostSample,
  IManageTagsContext,
} from '_State/Spectrum/types';

import { useTranslation } from 'react-i18next';
import { PostSamples } from '_Routes/Home/_Components/PostSamples/PostSamples';
import { getCampaign } from '_State/User/Api/user.api';
import {
  createSpectrumTag,
  deleteSpectrumTag,
  getPostSamples,
  getPostUrls,
  getSpectrumTags,
  updateSpectrumCampaignTag,
  updateSpectrumTags,
} from '_State/Spectrum/Api/spectrum.api';
import { configureInfo, configureStatistics } from './configs';
import { TagsMangerDialog } from '_Routes/Home/_Components/TagManager/_Compontents/TagsMangerDialog';
import { ManageTagsContext } from '_Types/manageTagsContext';
import { ITag } from '_Types/campaigns';
import {
  getCampaignForAdmin,
  getPostSamplesForAdmin,
} from '_State/User/Api/admin-user.api';
import { IDistributionPartnerPostsUrls } from '_State/DistributionPartner/distributionPartner.types';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const HorizontalWrapper = styled.div`
  margin-top: 2.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  background: #f8f9fc;
`;

const InfoWrapper = styled.div`
  width: 26.5rem;
  margin-right: 3rem;
  height: 100%;
`;
const SamplesWrapper = styled.div`
  height: 100%;
  flex: 1;
`;

export const SpectrumCampaignReport: FC = () => {
  const { t } = useTranslation();
  const { userId, id } = useParams<any>();
  const [campaign, setCampaign] = useState<ISpectrumCampaign>();
  const [postSamples, setPostSamples] = useState<IPostSample[]>([]);
  const [urls, setUrls] = useState<IDistributionPartnerPostsUrls[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagManagerOpened, setTagManagerOpened] = useState(false);
  const [instructionsValue, setInstructionsValue] = useState<string>('');
  const { isAgencyType } = useContext(NavigationContext);

  useEffect(() => {
    campaign?.furtherInstructions &&
      setInstructionsValue(campaign?.furtherInstructions);
  }, [campaign]);

  const fetchCampaign = (id?: string) => {
    return userId && id
      ? getCampaignForAdmin(id, userId).then((value) => {
          setCampaign(value);
          return value;
        })
      : getCampaign(id).then((value) => {
          setCampaign(value);
          return value;
        });
  };

  const fetchTags = () => {
    getSpectrumTags().then((tags) => {
      setTags(tags);
      return tags;
    });
  };

  const updateTagInfo = () => {
    fetchCampaign(id).then(fetchTags);
  };

  useEffect(() => {
    fetchCampaign(id).then(fetchTags);
    userId && id
      ? getPostSamplesForAdmin(id, userId).then((values) =>
          setPostSamples(values)
        )
      : getPostSamples(id).then((values) => setPostSamples(values));
    // eslint-disable-next-line
  }, [id, userId]);

  const getCampaignTitle = () => {
    const campaignText = t('spectrum.dashboard.campaign');
    return campaign ? `${campaignText} #${campaign?.id}` : campaignText;
  };

  const context: IManageTagsContext = {
    tags,
    updateTag: (newTag) => {
      return updateSpectrumTags(newTag).then(() => {
        updateTagInfo();
      });
    },
    updateCampaignTag: (campaignId, newTagId) => {
      return updateSpectrumCampaignTag(campaignId, newTagId).then(() => {
        updateTagInfo();
      });
    },
    removeTag: (tagId) => {
      return deleteSpectrumTag(tagId).then(() => {
        updateTagInfo();
      });
    },
    addTag: (newTagName: string) => {
      return createSpectrumTag(newTagName).finally(() => {
        updateTagInfo();
      });
    },
  };

  const openTagManager = () => {
    setTagManagerOpened(true);
  };
  const closeTagManager = () => {
    setTagManagerOpened(false);
  };
  useEffect(() => {
    getPostUrls(id).then((res) => {
      return setUrls(res);
    });
    // eslint-disable-next-line
  }, [id]);

  return (
    <ManageTagsContext.Provider value={context}>
      <ReportWrapper>
        <HeaderWrapper>
          <PlxDashboardHeader
            title={getCampaignTitle()}
            backLabel={t('spectrum.dashboard.spectrumDashboard')}
            backLink={
              isAgencyType
                ? '/home/agency/spectrum/overview'
                : '/home/client/spectrum/overview'
            }
          />
        </HeaderWrapper>
        <ContentWrapper>
          <PlxTilesPanel tiles={configureStatistics(t, campaign)} />
          <HorizontalWrapper>
            <InfoWrapper>
              <PlxCampaignInformation
                label={t('campaignInformation.label')}
                config={configureInfo(t, openTagManager, campaign)}
                instructionsValue={instructionsValue}
              />
            </InfoWrapper>
            <SamplesWrapper>
              <PostSamples postSamples={postSamples} urlSamples={urls} />
            </SamplesWrapper>
          </HorizontalWrapper>
        </ContentWrapper>
        <TagsMangerDialog
          context={ManageTagsContext}
          campaignId={campaign?.id}
          isAddOnly={false}
          open={tagManagerOpened}
          onClose={closeTagManager}
        />
      </ReportWrapper>
    </ManageTagsContext.Provider>
  );
};
