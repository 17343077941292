import React from 'react';
import styled from '@emotion/styled';

interface ISpectrumButton {
  text: string;
  borderColor?: string;
  fontSize?: number;
  handleProgramLaunch?: () => void;
}

const StyledButton = styled.div`
  cursor: pointer;
  border-radius: 30px;
  padding: 10px 12px;
  margin: 2rem;
  text-align: center;
`;
export const SpectrumButton = ({
  text,
  borderColor,
  fontSize,
  handleProgramLaunch,
}: ISpectrumButton) => {
  return (
    <StyledButton
      onClick={handleProgramLaunch && handleProgramLaunch}
      style={{
        border: `1px solid ${borderColor ? borderColor : '#000'}`,
        fontSize: ` ${fontSize ? fontSize : '14'}px`,
      }}
    >
      {text}
    </StyledButton>
  );
};

export default SpectrumButton;
