import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

export const EuropeIcon: FC<IIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="25"
      height="25"
      viewBox="-8.3 -8.3 16.6 16.6"
    >
      <desc>Flag of the European Union</desc>
      <defs>
        <g id="star">
          <g id="cone">
            <path
              id="triangle"
              d="M0,0 v1 h0.5 z"
              transform="translate(0,-1) rotate(18)"
            />
            <use href="#triangle" transform="scale(-1,1)" />
          </g>
          <use href="#cone" transform="rotate(72)" />
          <use href="#cone" transform="rotate(144)" />
          <use href="#cone" transform="rotate(216)" />
          <use href="#cone" transform="rotate(288)" />
        </g>
      </defs>
      <circle
        fill="#0B3794"
        stroke="#0C2E70"
        strokeWidth="0.2"
        cx="0"
        cy="0"
        r="8.2"
      />
      <g fill="#ffcc00">
        <use href="#star" y="-6" />
        <g id="rtl">
          <use href="#star" transform="rotate(30) translate(0,6) rotate(42)" />
          <use href="#star" transform="rotate(60) translate(0,6) rotate(12)" />
          <use href="#star" x="6" />
          <use href="#star" transform="rotate(120) translate(0,6) rotate(24)" />
          <use href="#star" transform="rotate(150) translate(0,6) rotate(66)" />
        </g>
        <use href="#star" y="6" />
        <use href="#rtl" transform="scale(-1,1)" />
      </g>
    </svg>
  );
};
