import { IStockCompany } from '_Types/campaigns';

export enum UserType {
  CLIENT = 'CLIENT',
  AGENCY = 'AGENCY',
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  DISTRIBUTION_PARTNER = 'DISTRIBUTION_PARTNER',
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  userType: UserType;
  spectrumTrialUsed: boolean; // remove it
  mailingAddress: string;
  businessName: string;
  contractAccepted?: boolean;
}

export interface IUserUpdateDTO {
  firstName: string;
  lastName: string;
}
export interface IChangePasswordDTO {
  oldPassword: string;
  newPassword: string;
}

export interface AddClientForm {
  id?: number;
  firstName: string;
  lastName: string;
  country: string;
  companyName: string;
  email: string;
}

export interface IUserDTO {
  id: number;
  fullName: string;
  email: string;
  companyName: string;
  spectrumTrialUsed: boolean;
  activated: boolean;
  enableEmailNotifications: boolean;
  createdDate: string;
  lastLogin: string;
  privilege: string;
  businessName: string;
  mailingAddress: string;
  phoneNumber: string;
  contractUrl?: string;
  referrerName?: string;
}
export interface IAgencyDTO {
  id: number;
  fullName: string;
  email: string;
  companyName: string;
  spectrumTrialUsed: boolean;
  activated: boolean;
  enableEmailNotifications: boolean;
  createdDate: string;
  lastLogin: string;
  privilege: string;
  businessName: string;
  mailingAddress: string;
  phoneNumber: string;
  contractUrl?: string;
  referrerName?: string;
}

export interface IEditClientForm {
  fullName?: string;
  email?: string;
  companyName?: string;
  isActivated?: boolean;
}

export interface IEditDistributionPartnerForm {
  fullName: string;
  phoneNumber: string;
  email?: string;
  mailingAddress: string;
  businessName: string;
  mobileVerified?: boolean;
  contractAccepted?: boolean;
  isActivated?: boolean;
}
export interface IAdminUsersQueryParams {
  sortDirection?: 'ASC' | 'DESC' | string;
  sortByField?: string;
  searchedPhrase?: string;
  pageSize?: number;
  id?: string | number;
}
export interface IAdminAgenciesQueryParams {
  sortDirection?: 'ASC' | 'DESC' | string;
  sortByField?: string;
  searchedPhrase?: string;
  pageSize: number;
  id?: string | number;
}

export interface IAddAdminOrEditorForm {
  fullName?: string;
  email?: string;
  privilege?: string;
}

export interface IAgencyClientDTO {
  companyName: string;
  country: string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
}
export interface ICampaignsAgencyDTO {
  agencyClient: IAgencyClientDTO;
}

export interface IClientsDTO {
  id: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  companyName?: string;
  country?: string;
  campaigns: ICampaignsAgencyDTO[];
}
export interface IOrderDTO {
  id: number;
  agencyClientId?: number;
  remainingCampaigns: number;
  campaigns: ICampaignsAgencyDTO[];
  totalCampaigns: number;
  invoiceId: string;
  expiryDate: string;
  campaignType: string;
  industry: string[];
  orderDate: string;
  status: string;
  stockTickerList: IStockCompany[];
  //spectrum
  estimatedReach: number;
  numberOfCampaigns: number;
  numberOfContributors: number;
  spectrumGeolocation: string[];
  spectrumProgramId: number;
  //thrive
  leadsPerMonth: number;
  leadsPerProgram: number;
  thriveGeolocation: string[];
}

export interface IOrderEditForm {
  userId?: string | undefined;
  agencyClientId?: string | undefined;
  followers?: number | undefined;
  remainingCampaigns?: number | undefined;
  totalCampaigns?: number | undefined;
  expiryDate?: string | undefined;
  status?: string | undefined;
  invoiceId?: string | undefined;
}
