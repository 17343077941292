import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { registerUser } from '_Api/auth';
import { capitalize } from '_App/utils';
import { ClientRegFormStepOne } from './ClientRegFormStepOne';
import { ClientRegFormStepTwo } from './ClientRegFormStepTwo';

export interface RegistrationForm {
  firstName: string;
  lastName: string;
  country: string;
  businessName: string;
  email: string;
  password: string;
  confirmPassword: string;
  enableEmailNotifications: boolean;
}
export const ClientRegForm: FC = () => {
  const [data, setData] = useState<RegistrationForm>({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    businessName: '',
    password: '',
    confirmPassword: '',
    enableEmailNotifications: true,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [apiError, setApiError] = useState('');
  const history = useHistory();
  const handleNextStep = (
    newData: RegistrationForm,
    final = false,
    token?: string
  ): void => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      const data = {
        firstName: newData.firstName,
        lastName: newData.lastName,
        email: newData.email,
        country: newData.country,
        companyName: newData.businessName,
        password: newData.password,
        enableEmailNotifications: true,
        captchaToken: token || '',
      };
      registerUser(data).then(
        () => {
          history.push('/auth/register-confirmed');
        },
        (error: any) => {
          error.response.text().then((value: any) => {
            const apiError = JSON.parse(value);
            setApiError(
              `${capitalize(apiError.fieldErrors[0].field)}: ${
                apiError.fieldErrors[0].defaultMessage
              }`
            );
          });
        }
      );
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrevStep = (newData: RegistrationForm) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <ClientRegFormStepOne
      data={data}
      key="1"
      next={handleNextStep}
      apiError={apiError}
    />,
    <ClientRegFormStepTwo
      data={data}
      key="2"
      next={handleNextStep}
      back={handlePrevStep}
      apiError={apiError}
    />,
  ];
  return <>{steps[currentStep]}</>;
};
