import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxSlider } from '_Components/PlxSlider/PlxSlider';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import {
  SliderInfo,
  ISliderInfoProps,
} from '_Routes/Home/_Components/Sections/ReachSection/SliderInfo';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueWrapper = styled.div`
  margin: auto;
`;

const ValueSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
`;

const Slider = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2.5rem 0.5rem;
`;

const SliderValue = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.6rem;
  margin: 0.5rem 2rem;
  min-width: 7rem;
`;
export interface ISliderConfig {
  value: number;
}
interface IProps {
  value: number;
  onChange: (value: number) => void;
  config: ISliderConfig[];
  sliderInfoConfig?: ISliderInfoProps[];
  title: string;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
}

export const ReachSection: FC<IProps> = ({
  value,
  config,
  sliderInfoConfig,
  onChange,
  title,
  required = false,
  tooltip,
  disabled,
}) => {
  const [min] = useState(0);
  const [max, setMax] = useState(0);
  useEffect(() => {
    if (config.length > 0) {
      setMax(config.length - 1);
    }
  }, [config]);

  const useStyles = makeStyles(() =>
    createStyles({
      disabled: {
        opacity: '0.5',
      },
    })
  );
  const containerStyles = useStyles();

  const chosenAmount = useMemo(
    () => (config[value] ? config[value].value.toLocaleString() : 0),
    [config, value]
  );

  return (
    <FormSection
      title={title}
      required={required}
      tooltip={tooltip}
      className={clsx({
        [containerStyles.disabled]: disabled,
      })}
    >
      <Wrapper>
        <Slider>
          <PlxSlider
            disabled={disabled}
            minRange={min}
            maxRange={max}
            value={value}
            step={1}
            onChange={onChange}
          />
          <SliderValue>${chosenAmount}</SliderValue>
        </Slider>
        <ValueSection>
          {sliderInfoConfig?.map((info, index) => {
            return (
              <ValueWrapper key={index}>
                <SliderInfo
                  label={info.label}
                  value={info.value}
                  tooltipText={info.tooltipText}
                />
              </ValueWrapper>
            );
          })}
        </ValueSection>
      </Wrapper>
    </FormSection>
  );
};
