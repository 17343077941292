import React, { FC, useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from '@emotion/styled';
import { prepareCountryFeatures } from './utils/feature';
import { IMapCountry } from './types';
import { createMap, clearMap } from './utils/map';
import { PlxMapStatistics } from './PlxMapStatistics';
import { ICommonProps } from '_Types/props';

const MapElement = styled.div`
  height: 450px;
  width: 100%;

  &.leaflet-container {
    background-color: white;
  }
`;

export interface IProps extends ICommonProps {
  countries: IMapCountry[];
}

export const PlxMap: FC<IProps> = ({ countries, className }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<L.Map>();
  const [geoJsonLayer, setGeoJSonLayer] = useState<L.GeoJSON>();

  const registerMap = (ref: HTMLDivElement | null) => {
    if (!ref) return;
    setTimeout(() => {
      const { layer, map } = createMap(ref);
      setMap(map);
      setGeoJSonLayer(layer);
    }, 0);
  };

  useEffect(() => {
    registerMap(mapRef?.current);
    return () => {
      map && clearMap(map);
    };
    // eslint-disable-next-line
  }, [mapRef]);

  useEffect(() => {
    if (geoJsonLayer) {
      prepareCountryFeatures(geoJsonLayer, countries);
    }
  }, [countries, geoJsonLayer]);

  return (
    <div className={className}>
      <MapElement ref={mapRef} />
      <PlxMapStatistics countries={countries} />
    </div>
  );
};
