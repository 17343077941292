import React, { FC } from 'react';
import styled from '@emotion/styled';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { FullLogoX } from './FullLogoX';

const StyledLogo = styled.div`
  height: 6.5rem;
  display: flex;
  align-items: center;
  position: relative;
`;
const StyledLogoImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;
interface IProps {
  toggleMenu: () => void;
  isExpanded: boolean;
  admin?: boolean;
  finfluencer?: boolean;
}

export const HamburgerMenu: FC<IProps> = ({ toggleMenu, isExpanded }) => {
  return (
    <StyledLogo>
      <IconButton onClick={toggleMenu}>
        <MenuIcon style={{ color: '#231F20' }} />
      </IconButton>
      {isExpanded && (
        <StyledLogoImage>
          <FullLogoX />
        </StyledLogoImage>
      )}
    </StyledLogo>
  );
};
