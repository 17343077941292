import React, { FC, useState, MouseEvent, useEffect } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import {
  withStyles,
  MenuProps,
  Menu,
  createStyles,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IFilterItem } from '../utls';

const Wrapper = styled.div``;
const StyledToggleIcon = styled.span`
  margin-left: 1rem;
  vertical-align: middle;
`;
const StyledBackground = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const StyledButton = styled.span<{ isOpen: boolean }>`
  font-family: 'Quicksand', sans-serif;
  border-radius: 2rem;
  font-size: 14px;
  margin-right: 0.5rem;
  border: 1px solid #5f5f61;
  padding: 0.625rem 1rem;
  align-items: center;
  background-color: ${({ isOpen }): string => (isOpen ? '#eecaf2' : 'white')};
  cursor: pointer;
  &:hover {
    background-color: #eecaf2;
  }
`;

interface IProps extends ICommonProps {
  name: string;
  items: IFilterItem[];
  resetFilters: () => void;
}

const StyledMenu = withStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: '0.5rem',
      border: '1px solid #D4D4D4',
      borderRadius: '2rem',
      padding: '0.5rem',
      backgroundColor: 'white',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        width: '100%',
        height: 'fit-content',
        top: 'auto !important',
        bottom: 16,
      },
    },
  })
)((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

export const FilterButton: FC<IProps> = ({
  name,
  children,
  items,
  resetFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [text, setText] = useState<string>('');

  const checkedItems = items.filter((obj: IFilterItem) => obj.isChecked);
  useEffect(() => {
    setText(
      checkedItems.length
        ? `${checkedItems[0]?.title}${
            checkedItems.length > 1 ? ' +' + --checkedItems.length : ''
          }`
        : name
    );
  }, [items, name, checkedItems]);

  const onReset = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    resetFilters();
  };

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  return (
    <Wrapper>
      {!!anchorEl && <StyledBackground />}
      <StyledButton isOpen={!!anchorEl} onClick={handleClick}>
        {text}
        <StyledToggleIcon onClick={onReset}>
          {checkedItems.length ? (
            <CloseIcon style={{ color: '#5f5f61' }} />
          ) : (
            <KeyboardArrowDownIcon style={{ color: '#5f5f61' }} />
          )}
        </StyledToggleIcon>
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </Wrapper>
  );
};
