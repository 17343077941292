import { CellConfigX } from '_Components/PlxList/PlxList';
import {
  ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY,
  IAnalyticsDTO,
  IUrlDTO,
  IDistributionPartnerChannelStatistics,
  IDistributionPartnerOpportunityDTO,
  REFERRALS_STATUS_MAP_STRATEGY,
} from '_State/DistributionPartner/distributionPartner.types';
import React, { ReactElement } from 'react';
import {
  ButtonVariant,
  PlxButtonXAnalytics,
} from '_Components/Buttons/PlxButton/PlxButtonX';
import _ from 'lodash';
import { ChannelStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelStatusCell';
import { getEligibleChannelStatus } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { PlxLinkTextButtonAnalytics } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import styled from '@emotion/styled';
import { CampaignStatus } from '_Types/campaigns';
import { CAMPAIGN_STATUS_MAP_STRATEGY } from '_Routes/Home/_routes/Admin/types';
import { CampaignStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/CampaignStatusCell';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { GridColumns } from '@material-ui/data-grid';
import { PlxText } from '_Components/Text/PlxText';
import moment from 'moment';
import { getReferralStatus } from '_Routes/Home/_routes/Admin/_Routes/Referrals/utils';

const ChannelNameWrapper = styled.div`
  position: relative;
`;

const StyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const createChannelStatisticsConfig = (
  statistics?: IDistributionPartnerChannelStatistics
) => {
  return [
    {
      label: 'Total Campaigns',
      amount: statistics?.totalCampaigns,
    },
    {
      label: 'Total Earned',
      amount: `$${statistics?.totalEarned ? statistics.totalEarned : 0}`,
    },
    {
      label: 'Total Reactions',
      amount: statistics?.totalReactions,
    },
    {
      label: 'Total Comments',
      amount: statistics?.totalComments,
    },
    {
      label: 'Total Shares',
      amount: statistics?.totalShares,
    },
    {
      label: 'Total Referrals',
      amount: `$${
        statistics?.totalReferralEarnings ? statistics.totalReferralEarnings : 0
      }`,
    },
  ];
};

export const createDistributionPartnerCampaignsTableConfig = (
  openDetails: (
    campaignId: number,
    opportunityId: number,
    channelType: string
  ) => void,
  submitUrl: (url: IUrlDTO) => void,
  submitAnalytics: (
    opportunityId: number,
    status: string,
    data: IAnalyticsDTO
  ) => void
): CellConfigX<IDistributionPartnerOpportunityDTO>[] => {
  const subdomain = window.location.hostname.split('.')[0];
  return [
    {
      name: 'Channel Name',
      prop: 'channelName',
      renderCell({
        channelName,
        status,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <ChannelNameWrapper>{channelName}</ChannelNameWrapper>;
      },
    },
    {
      name: 'Type',
      prop: 'channelType',
      renderCell({
        channelType,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{_.startCase(channelType.toLowerCase())}</div>;
      },
    },
    {
      name: 'Post Due',
      prop: 'postDue',
      renderCell({
        postDue,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{formatDate(postDue)}</div>;
      },
    },
    {
      name: 'Data Due',
      prop: 'analyticsDue',
      renderCell({
        analyticsDue,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{formatDate(analyticsDue)}</div>;
      },
    },
    {
      name: 'Payout',
      prop: 'offer',
      renderCell({ offer }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{offer ? `$ ${offer}.00` : `$ ${offer}`}</div>;
      },
    },

    {
      name: 'Reactions',
      prop: 'reactions',
      renderCell({
        reactions,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{reactions ? reactions : `-`}</div>;
      },
    },
    {
      name: 'Comments',
      prop: 'comments',
      renderCell({
        comments,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{comments ? comments : '-'}</div>;
      },
    },
    {
      name: 'Shares',
      prop: 'shares',
      renderCell({ shares }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{shares ? shares : `-`}</div>;
      },
    },
    {
      name: 'Post Count',
      prop: 'postCount',
      renderCell({
        recentPostCount,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{recentPostCount ? recentPostCount : `-`}</div>;
      },
    },
    {
      name: 'Opportunity Status',
      prop: 'status',
      renderCell({ status }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY as any)[
                getEligibleChannelStatus(status)
              ]
            }
          />
        );
      },
    },
    {
      name: 'Campaign Status',
      prop: 'campaignStatus',
      renderCell({
        campaignStatus,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <CampaignStatusCell
            status={
              (CAMPAIGN_STATUS_MAP_STRATEGY as any)[
                (CampaignStatus as any)[campaignStatus]
              ]
            }
          />
        );
      },
    },
    {
      name: 'Company Name',
      prop: 'companyName',
      renderCell({
        companyName,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{companyName ? companyName : `-`}</div>;
      },
    },
    {
      name: 'URL',
      prop: 'url',
      renderCell(value: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <PlxButtonXAnalytics
            size={'small'}
            isDisabled={
              value.status !== 'ACTIVE' ||
              value.campaignStatus === 'COMPLETED' ||
              value.campaignStatus === 'DRAFT' ||
              value.postUrl !== undefined
            }
            variant={ButtonVariant.Outlined}
            label={'Submit'}
            onClick={() =>
              submitUrl({
                id: value.id,
                channelName: value.channelName,
                channelType: value.channelType,
                offer: value.offer,
                status: value.status,
                campaignId: value.campaignId,
                seen: value.seen,
                analyticsDue: value.analyticsDue,
                postDue: value.postDue,
                reactions: value.reactions,
                shares: value.shares,
                comments: value.comments,
                numberOfFollowers: value.numberOfFollowers,
                impressions: value.impressions,
                videoSaves: value.videoSaves,
                postUrl: value.postUrl,
              })
            }
          />
        );
      },
    },
    {
      name: 'Analytics',
      prop: '',
      renderCell(value: IDistributionPartnerOpportunityDTO): ReactElement {
        const analyticsDueDate = new Date(value.analyticsDue);
        const today = new Date();
        const isPastDate =
          analyticsDueDate >= today &&
          subdomain !== 'staging' &&
          subdomain !== 'dev';
        return (
          <PlxButtonXAnalytics
            size={'small'}
            tooltip={isPastDate}
            isDisabled={
              value.status === 'COMPLETED' ||
              value.status === 'COMPLETED_MANUAL_PAYOUT' ||
              value.status === 'REVIEW_ANALYTICS' ||
              value.campaignStatus === 'COMPLETED' ||
              value.campaignStatus === 'NEW' ||
              value.campaignStatus === 'DRAFT'
              // isPastDate
            }
            variant={ButtonVariant.Outlined}
            label={'Submit'}
            onClick={() =>
              submitAnalytics(value.id, value.status, {
                comments: value.comments,
                datePosted: value.datePosted,
                reactions: value.reactions,
                shares: value.shares,
                postSample: value.postSample,
                note: value.needsWorkNote,
                numberOfFollowers: value.numberOfFollowers,
                impressions: value.impressions,
                videoSaves: value.videoSaves,
                channelType: value.channelType,
                postUrl: value.postUrl,
                connected: value.connected,
              })
            }
          />
        );
      },
    },
    {
      name: 'More',
      prop: '',
      renderCell({
        campaignId,
        id,
        channelType,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <PlxLinkTextButtonAnalytics
            label="More Details"
            onClick={() => {
              openDetails(campaignId, id, channelType);
            }}
          />
        );
      },
    },
  ];
};

export const createDistributionPartnerReferralsTableConfig = (): CellConfigX<
  IDistributionPartnerOpportunityDTO
>[] => {
  return [
    {
      name: 'Referred Name',
      prop: 'channelName',
      renderCell({
        channelName,
        status,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <ChannelNameWrapper>{channelName}</ChannelNameWrapper>;
      },
    },
    {
      name: 'Payout',
      prop: 'offer',
      renderCell({ offer }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{offer ? `$ ${offer}.00` : `$ ${offer}`}</div>;
      },
    },
    {
      name: 'Data Due',
      prop: 'analyticsDue',
      renderCell({
        analyticsDue,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return <div>{formatDate(analyticsDue)}</div>;
      },
    },

    {
      name: 'Campaign Status',
      prop: 'campaignStatus',
      renderCell({
        campaignStatus,
      }: IDistributionPartnerOpportunityDTO): ReactElement {
        return (
          <CampaignStatusCell
            status={
              (CAMPAIGN_STATUS_MAP_STRATEGY as any)[
                (CampaignStatus as any)[campaignStatus]
              ]
            }
          />
        );
      },
    },
  ];
};

export const createFinfluencerReferralsTableConfig = (): GridColumns => {
  return [
    {
      headerName: 'Referred Name',
      field: 'fullName',
      flex: 1,
    },
    {
      headerName: 'Earned',
      field: 'earned',
      flex: 1,
      renderCell({ value }): ReactElement {
        return <StyledText>$ {value}</StyledText>;
      },
    },
    {
      headerName: 'Completed Date',
      field: 'completedDate',
      flex: 1,
      renderCell({ value }): ReactElement {
        return (
          <StyledText>
            {moment(value?.toString()).format('MMM DD, YYYY')}
          </StyledText>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      renderCell({ value }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              (REFERRALS_STATUS_MAP_STRATEGY as any)[
                getReferralStatus(value?.toString() || '')
              ]
            }
          />
        );
      },
    },
  ];
};
