import styled from '@emotion/styled';
import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

const StyledIcon = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: #d9d9d9;
  padding: 0;
  margin: 0;
`;

export const StyledMenuIcon: FC<IIconProps> = ({ isFocused, newDesign }) => {
  return (
    <StyledIcon
      style={{
        marginRight: newDesign ? '6px' : undefined,
        height: newDesign ? '14px' : undefined,
        width: newDesign ? '14px' : undefined,
        background:
          isFocused && !newDesign
            ? '#231F20'
            : newDesign && isFocused
            ? '#fff'
            : '',
      }}
    />
  );
};
