import L from 'leaflet';
import countriesGeoJson from '../countries.json';

const MAP_CONFIG = {
  dragging: true,
  doubleClickZoom: false,
  boxZoom: false,
  scrollWheelZoom: false,
  touchZoom: false,
  zoomControl: true,
  attributionControl: false,
};

export const clearMap = (map: L.Map) => {
  map.eachLayer(function (layer) {
    map.removeLayer(layer);
  });

  map.remove();
};

export const createMap = (ref: HTMLDivElement) => {
  const map = L.map(ref, MAP_CONFIG).setView([0, 0], 4);

  const layer = L.geoJSON(countriesGeoJson as GeoJSON.FeatureCollection).addTo(
    map
  );

  map.fitBounds(layer.getBounds());
  map.setView([40, 0], 2);

  return { map, layer };
};
