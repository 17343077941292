import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { useTranslation } from 'react-i18next';
import { PlxTilesPanel } from '_Components/Panels/PlxTilesPanel/PlxTilesPanel';
import {
  ICampaignInfoItem,
  PlxCampaignInformation,
} from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import { IPostSample, ISpectrumCampaign } from '_State/Spectrum/types';
import {
  getAdminNoOfFollower,
  getAdminPostSamples,
  getProgramPaymentInfoAdmin,
  getSpectrumCampaignsAdmin,
  updateCampaignInfo,
  updateCampaignStatistics,
  updateCampaignStatus,
  updatePaymentStatus,
} from '_State/Spectrum/Api/spectrum-admin.api';
import { useParams } from 'react-router-dom';
import {
  configureAdminSpectrumCampaignInfo,
  configureEditorSpectrumCampaignInfo,
  configureStatistics,
  configureStatusSelect,
} from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/configs';
import { PlxSelect } from '_Components/PlxSelect/PlxSelect';
import { PostSamples } from '_Routes/Home/_Components/PostSamples/PostSamples';
import { CampaignStatus } from '_Types/campaigns';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { UserType } from '_State/User/user.types';
import { AdminSpectrumCampaignChannelsTable } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/AdminSpectrumCampaignChannelsTable';
import { PlxLoader } from '_Components/PlxLoader';
import {
  approveStatistics,
  manualPayout,
  getEligibleChannels,
  updateMaxSpendValue,
  updateBoostValue,
} from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import {
  IDistributionPartnerChannelSelectableRow,
  IUpdateableAnalyticsDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { AdminNotes } from './AdminNotes';

const AdminCampaignBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const HorizontalWrapper = styled.div`
  margin-top: 2.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 4rem 0;
`;

const HeaderWrapper = styled.div`
  height: 7rem;
`;

const InfoWrapper = styled.div`
  width: 26.5rem;
  margin-right: 3rem;
  height: 100%;
`;
const SamplesWrapper = styled.div`
  height: 100%;
  flex: 1;
`;
const StyledCampaignInformation = styled(PlxCampaignInformation)`
  overflow: auto;
`;

export const AdminSpectrumCampaign: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<ISpectrumCampaign>();
  const [noOfFollowers, setNoOfFollowers] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const { user } = useSelector((state: IState) => state.user);
  const [campaignInfoConfig, setCampaignInfoConfig] = useState<
    ICampaignInfoItem[]
  >([]);
  const [channels, setChannels] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [adminNotes, setAdminNotes] = useState<string>('');
  const [postSamples, setPostSamples] = useState<IPostSample[]>([]);
  const [initialBoostRender, setInitialBoostRender] = useState(true);
  const [initialMaxValRender, setInitialMaxValRender] = useState(true);
  const [boostSpend, setBoostSpend] = useState<number>(0);
  const [maxSpendValue, setMaxSpendValue] = useState<number>(0);
  const [inputBoostFocused, setInputBoostFocused] = useState(false);
  const [inputMaxValFocused, setInputMaxValFocused] = useState(false);
  const [instructionsValue, setInstructionsValue] = useState<string>('');

  const updateInstructionValue = (value: string) => {
    setInstructionsValue(value);
  };

  const isNewCampaign = campaign?.status === 'NEW';

  useEffect(() => {
    campaign?.maxSpent && setMaxSpendValue(campaign?.maxSpent);
  }, [campaign]);

  useEffect(() => {
    campaign?.furtherInstructions &&
      setInstructionsValue(campaign?.furtherInstructions);
  }, [campaign]);

  useEffect(() => {
    campaign?.boostingSpend && setBoostSpend(campaign?.boostingSpend);
  }, [campaign]);

  useEffect(() => {
    campaign?.adminNotes && setAdminNotes(campaign?.adminNotes);
  }, [campaign]);

  const handleBoostConfirm = (value: number) => {
    setBoostSpend(value as number);
    setInputBoostFocused(false);
  };

  const handleBoostCancel = () => {
    setInputBoostFocused(false);
  };

  const handleMaxValConfirm = (value: number) => {
    setMaxSpendValue(value as number);
    setInputMaxValFocused(false);
  };

  const handleMaxValCancel = () => {
    setInputMaxValFocused(false);
  };

  useEffect(() => {
    if (initialBoostRender) {
      setInitialBoostRender(false);
    } else {
      updateBoostValue(boostSpend, id);
    }
  }, [boostSpend, initialBoostRender, id]);

  useEffect(() => {
    if (initialMaxValRender) {
      setInitialMaxValRender(false);
    } else {
      updateMaxSpendValue(maxSpendValue, id);
    }
  }, [maxSpendValue, initialMaxValRender, id]);

  const changePaymentStatus = (value: unknown) => {
    if (campaign) {
      updatePaymentStatus(
        campaign?.programId,
        paymentInfo?.paymentId,
        value as string
      ).then();
    }
  };

  const handleApproveAnalytics = (
    value: string | undefined,
    data?: IUpdateableAnalyticsDTO
  ): Promise<any> => {
    return approveStatistics(value, data);
  };
  const handleManualPayAnalytics = (value: string): Promise<any> => {
    return manualPayout(value);
  };

  const fetchChannels = () =>
    getEligibleChannels(id).then((res) => {
      const mappedChannels = res.map((channel: any) => ({
        ...channel,
        id: channel.id ? channel.id : channel.channelId,
      }));
      if (campaign?.status === 'NEW' || campaign?.status === 'DRAFT') {
        setChannels(mappedChannels);
      } else {
        setChannels(
          mappedChannels.filter(
            (d: IDistributionPartnerChannelSelectableRow) =>
              d.status !== 'ELIGIBLE'
          )
        );
      }
      setLoading(false);
      return res;
    });

  useEffect(() => {
    fetchChannels().then();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let array: any = [];
    const data = channels;
    data.map((el) => {
      if (
        el.status === 'COMPLETED' ||
        el.status === 'COMPLETED_MANUAL_PAYOUT'
        // el.status === 'ANALYTICS_NEEDED'
      ) {
        return array.push('true');
      } else {
        return array.push('false');
      }
    });
  }, [channels]);

  const fetchCampaign = () => {
    return getSpectrumCampaignsAdmin({ campaignId: id }).then((c) => {
      if (c.content[0].programId) {
        getProgramPaymentInfoAdmin([c.content[0].programId]).then(
          (payment: any) => {
            setPaymentInfo(payment.content[0]);
          }
        );
      }
      setCampaign(c.content[0]);
      getAdminPostSamples(id).then((values) => setPostSamples(values));
      getAdminNoOfFollower(id).then((res) => setNoOfFollowers(res));
    });
  };

  useEffect(() => {
    if (id) {
      fetchCampaign().then(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [id, user, updateMaxSpendValue, approveStatistics, manualPayout]);

  useEffect(() => {
    setCampaignInfoConfig(
      user?.userType === UserType.ADMIN
        ? configureAdminSpectrumCampaignInfo(t, changePaymentStatus, campaign)
        : configureEditorSpectrumCampaignInfo(t, campaign)
    );
    // eslint-disable-next-line
  }, [paymentInfo, id, campaign, t, user]);

  const handleTileChange = (title: string, value: any) => {
    const dto: any = {};
    dto[title] = +value;
    if (title === 'reactions' || title === 'comments' || title === 'shares') {
      id && updateCampaignStatistics(+id, dto).then((v) => console.log(v));
    } else {
      id && updateCampaignInfo(+id, dto).then((v) => console.log(v));
    }
  };

  const handleInfoChange = (key: string, value: string) => {
    let valueConstruct;
    if (
      key === 'hashtags' ||
      key === 'contentCategories' ||
      key === 'geolocation'
    ) {
      if (value === '') {
        valueConstruct = [];
      } else if (key === 'geolocation') {
        valueConstruct = value
          .split(',')
          .map((val) => val.replace(/\s+/g, ' ').trim().toUpperCase());
      } else {
        valueConstruct = value
          .split(',')
          .map((val) => val.replace(/\s+/g, ' ').trim());
      }
    } else {
      if (value === '') {
        valueConstruct = '';
      } else {
        valueConstruct = value;
      }
    }
    const dto: any = {};
    dto[key] = valueConstruct;
    id &&
      updateCampaignInfo(+id, dto)
        .then(() => fetchCampaign())
        .catch((e: any) => {
          console.log(e.message);
          alert('Invalid, Please try again.');
        });
  };
  const handleStockTicker = (value: any) => {
    const dto: any = { stockTickerList: value };
    id && updateCampaignInfo(+id, dto).then(() => fetchCampaign());
  };
  const handleSampleAdd = () => {
    getAdminPostSamples(id).then((values) => setPostSamples(values));
  };

  const changeCampaignStatus = (value: unknown) => {
    if (id) {
      updateCampaignStatus(id, value as string).then(() =>
        window.location.reload()
      );
    }
  };

  const handleAnalyticsApproved = () => {
    setLoading(true);
    fetchCampaign().then(() => setLoading(false));
  };

  return (
    <AdminCampaignBody>
      <>
        {loading ? (
          <PlxLoader />
        ) : (
          <>
            <HeaderWrapper>
              <PlxDashboardHeader
                title={`${campaign?.companyName}  · Campaign #${id}`}
                backLabel={t('spectrum.dashboard.spectrumDashboard')}
                backLink={'/home/admin/spectrum-dashboard'}
              >
                <PlxSelect
                  onChange={changeCampaignStatus}
                  initialValue={
                    campaign?.status
                      ? campaign.status
                      : CampaignStatus[CampaignStatus.NEW]
                  }
                  options={configureStatusSelect(t)}
                />
              </PlxDashboardHeader>
            </HeaderWrapper>
            <ContentWrapper>
              <PlxTilesPanel
                tiles={configureStatistics(t, campaign, noOfFollowers)}
                onTileChange={handleTileChange}
                campaignStatus={campaign?.status}
              />
              <HorizontalWrapper>
                <InfoWrapper>
                  <StyledCampaignInformation
                    label={t('campaignInformation.label')}
                    config={campaignInfoConfig}
                    handleInfoSubmit={handleInfoChange}
                    handleStockTicker={handleStockTicker}
                    campaignStatus={campaign?.status}
                    instructionsValue={instructionsValue}
                    updateInstructionValue={updateInstructionValue}
                  />
                </InfoWrapper>
                <SamplesWrapper>
                  <PostSamples
                    onSampleAdd={handleSampleAdd}
                    editable={true}
                    postSamples={postSamples}
                  />
                </SamplesWrapper>
              </HorizontalWrapper>
              <AdminNotes
                notesValue={adminNotes}
                onChange={updateCampaignInfo}
                adminNotes
              />
            </ContentWrapper>

            <AdminSpectrumCampaignChannelsTable
              id={id}
              isNewCampaign={isNewCampaign}
              handleMaxValConfirm={handleMaxValConfirm}
              handleMaxValCancel={handleMaxValCancel}
              handleBoostConfirm={handleBoostConfirm}
              handleBoostCancel={handleBoostCancel}
              inputBoostFocused={inputBoostFocused}
              setInputBoostFocused={setInputBoostFocused}
              inputMaxValFocused={inputMaxValFocused}
              setInputMaxValFocused={setInputMaxValFocused}
              onAnalyticsApproved={handleAnalyticsApproved}
              isRebrandlyUrl={campaign?.enableRebrandlyUrl}
              boostSpend={boostSpend}
              maxSpend={maxSpendValue}
              campaignStatus={`${campaign?.status}`}
              handleApproveAnalytics={handleApproveAnalytics}
              handleManualPayAnalytics={handleManualPayAnalytics}
            />
          </>
        )}
      </>
    </AdminCampaignBody>
  );
};
