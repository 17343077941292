import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPostSample, IPostSampleAttachments } from '_State/Spectrum/types';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import {
  FileButton,
  FormWrapper,
  Img,
  InputTitle,
  InputWrapper,
  Inputs,
} from '_Routes/Home/_Components/PostSamples/Styles';
import { FileInput } from '_Routes/Home/_Components/PostSamples/FileInput';
import { SampleCarousel } from '_Routes/Home/_Components/PostSamples/SampleCarousel';
import { Attachment } from './SubmitAnalyticsForm';

interface IProps {
  formik: any;
  dialogTitle: string;
  open: boolean;
  initialValue?: IPostSample;
  onClose: () => void;
  onSave: () => void;
  onUpload: (value: number) => void;
  updatedAttachments: Attachment[];
  setUpdatedAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}

export const ScreenshotsDialog: FC<IProps> = ({
  formik,
  dialogTitle,
  open,
  initialValue,
  onClose,
  onSave,
  onUpload,
  updatedAttachments,
  setUpdatedAttachments,
}) => {
  const { t } = useTranslation();
  const [chosenImage, setChosenImage] = useState<number>(0);

  const [updated, setUpdated] = useState<boolean>(false);

  const updateChosenImage = (value: number) => {
    setChosenImage(value);
  };

  const postSample =
    formik.values.postSample !== null ? formik.values.postSample : initialValue;

  const handleChange = (url: string, fileName?: string) => {
    const attachmentIndex = postSample?.attachments.findIndex(
      (attachment: IPostSampleAttachments) => attachment.id === chosenImage
    );
    if (postSample && attachmentIndex !== null) {
      const newAttachment = {
        id: chosenImage,
        fileName: fileName ? fileName : '',
        base64Content: url,
      };
      const updatedAttachments = [...(postSample.attachments || [])];
      updatedAttachments[attachmentIndex] = newAttachment;
      setUpdatedAttachments(updatedAttachments);
      setUpdated(true);
    }
  };

  const chosenAttachment = initialValue?.attachments.find(
    (attachment) => attachment.id === chosenImage
  );

  const handleSave = () => {
    updated &&
      formik.setFieldValue('postSample.attachments', updatedAttachments);
    onSave();
  };

  const handleUpload = (value: number) => {
    updated &&
      formik.setFieldValue('postSample.attachments', updatedAttachments);
    onUpload(value);
  };

  return (
    <PlxDialog
      title={dialogTitle}
      onSave={handleSave}
      open={open}
      closeButton
      onClose={onClose}
    >
      <FormWrapper>
        <Inputs>
          {postSample && postSample.attachments.length > 1 ? (
            <div>
              <SampleCarousel
                finfluencer
                value={postSample}
                updateChosenImage={updateChosenImage}
              />
            </div>
          ) : postSample && postSample.attachments.length > 0 ? (
            <Img
              src={postSample?.attachments[0].screenshotUrl}
              alt={postSample?.attachments[0].fileName}
            />
          ) : null}
          {chosenAttachment && (
            <InputWrapper>
              <InputTitle>{t('spectrum.admin.replaceSample')}</InputTitle>
              <FileInput
                fileName={chosenAttachment.fileName}
                onChange={handleChange}
              />
            </InputWrapper>
          )}

          {postSample && (
            <FileButton onClick={() => handleUpload(postSample.id)}>
              Add more
            </FileButton>
          )}
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
