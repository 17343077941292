import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxSideContainer } from '_Components/PlxSideImage/PlxSideContainer';
import BgImg1 from './bgImg1.svg';
import BgImg2 from './bgImg2.svg';
import { Login } from '../_Routes/Login/Login';
import { Route, useLocation } from 'react-router-dom';
import { Register } from './Register/Register';
import { ResetPassword } from '_Routes/Auth/_Routes/ResetPassword/ResetPassword';
import { NewPassword } from '_Routes/Auth/_Routes/NewPassword/NewPassword';
import { RegisterConfirm } from '../_Routes/RegisterConfirm/RegisterConfirm';
import { PasswordSaved } from '_Routes/Auth/_Routes/PasswordSaved/PasswordSaved';
import { ActivateAccount } from '_Routes/Auth/_Routes/ActivateAccount/ActivateAccount';
import { sendResetLink } from '../../../_Api/auth';
import { useTranslation } from 'react-i18next';
import { Disclosure } from './Disclosure';
import ErrorPage from '../_Routes/ErrorPage';

const AuthBody = styled.div`
  width: 100%;
  max-width: 960px;
  max-height: 80%;
  overflow: hidden;
  display: flex;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  overflow: hidden;
  background: #fff;
  position: relative;
  padding: 3%;

  .side-image {
    @media (max-width: 650px) {
      display: none;
    }
  }
`;
const ImgWrapper1 = styled.img`
  position: absolute;
  left: 0;
  top: 3rem;
  height: 25rem;
`;
const ImgWrapper2 = styled.img`
  position: absolute;
  right: 0;
  bottom: -12rem;
  height: 56rem;
`;

export const AuthClient: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const handleResetPassword = (email: string) => sendResetLink(email);

  return (
    <AuthBody>
      <ImgWrapper1 src={BgImg1} />
      <ImgWrapper2 src={BgImg2} />
      {location.pathname ===
      '/auth/activate-account' ? null : location.pathname ===
        '/auth/register-confirmed' ? null : location.pathname ===
        '/auth/error' ? null : location.pathname ===
        '/auth/disclosure' ? null : (
        <PlxSideContainer
          // isClient
          subtitle={t('auth.sidePanel.subtitle')}
          className="side-image"
        />
      )}
      <Route exact path="/auth/disclosure">
        <Disclosure />
      </Route>
      {/*<Redirect exact from="/auth" to="/auth/login" />*/}
      <Route exact path="/auth/login">
        <Login
          registerPath="/auth/register"
          forgotPasswordPath={'/auth/reset-password'}
        />
      </Route>
      <Route exact path="/auth/register">
        <Register
          loginPath="/auth/login"
          client
          registerOtherPath="/auth/agency/register"
        />
      </Route>
      <Route exact path="/auth/reset-password">
        <ResetPassword
          backPath={'/auth/login'}
          onResetPassword={handleResetPassword}
        />
      </Route>
      <Route exact path="/auth/new-password/:token">
        <NewPassword />
      </Route>
      <Route exact path="/auth/register-confirmed">
        <RegisterConfirm pathLogin="/auth/login" />
      </Route>
      <Route exact path="/auth/password-saved">
        <PasswordSaved pathLogin="/auth/login" />
      </Route>
      <Route exact path="/auth/activate-account/:token">
        <ActivateAccount />
      </Route>
      <Route exact path="/auth/error">
        <ErrorPage />
      </Route>
    </AuthBody>
  );
};
