import { IAdminCampaignsQueryParams, ICampaigns } from '_Types/campaigns';
import { spectrumApi } from '_Api/api';
import {
  IAsset,
  IUploadFileDto,
  IGeoLocation,
  IGeoLocationState,
  IThriveCampaign,
  IThriveCampaignEditForm,
  IThriveLeadForm,
  IThriveStatistics,
} from '../types';

export const getThriveCampaignsAdmin = (
  params?: IAdminCampaignsQueryParams
): Promise<ICampaigns<IThriveCampaign>> => {
  let url = 'thrive-campaigns';
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.sortByField) {
    urlParams.append('sortByField', params.sortByField);
  }
  if (params?.campaignId) {
    urlParams.append('campaignId', params.campaignId);
  }
  if (params?.paymentStatusList && params.paymentStatusList.length > 0) {
    urlParams.append('paymentStatusList', params.paymentStatusList.join(','));
  }
  if (params?.companyNameList && params.companyNameList.length > 0) {
    urlParams.append('companyNameList', params.companyNameList.join(','));
  }
  if (params?.statusList && params.statusList.length > 0) {
    urlParams.append('statusList', params.statusList.join(','));
  }
  urlParams.append('pageSize', '10000');
  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const updateThriveCampaignStatistics = (
  statistics: IThriveStatistics,
  id?: string | number
): Promise<any> => {
  return spectrumApi
    .put(`thrive-campaigns/${id}/campaign-statistics`, {
      json: { ...statistics },
    })
    .json();
};

export const addAssetScreenshot = (
  assetUpdate: IUploadFileDto,
  campaignId?: string | number
): Promise<any> => {
  return spectrumApi
    .post(`thrive-campaigns/${campaignId}/assets`, { json: assetUpdate })
    .json();
};

export const deleteAssetNotification = (
  campaignId?: string | number,
  assetId?: string | number,
  notificationId?: string | number
): Promise<any> => {
  return spectrumApi.delete(
    `thrive-campaigns/${campaignId}/assets/${assetId}/notification/${notificationId}`
  );
};

export const deleteCampaignNotification = (
  campaignId?: string | number,
  notificationId?: string | number
): Promise<any> => {
  return spectrumApi.delete(
    `thrive-campaigns/${campaignId}/notification/${notificationId}`
  );
};

export const updateAssetScreenshot = (
  assetUpdate: IUploadFileDto,
  assetId?: string | number,
  campaignId?: string | number
): Promise<any> => {
  return spectrumApi
    .put(`thrive-campaigns/${campaignId}/assets/${assetId}`, {
      json: assetUpdate,
    })
    .json();
};

export const getAssetsAdmin = (id?: string | number): Promise<IAsset[]> => {
  return spectrumApi.get(`thrive-campaigns/${id}/assets/editor`).json();
};

export const getGeolocations = (): Promise<IGeoLocation[]> => {
  return spectrumApi.get(`thrive-campaigns/geolocations`).json();
};

export const getGeolocationsStates = (
  geoLocationId: string
): Promise<IGeoLocationState[]> => {
  return spectrumApi
    .get(`thrive-campaigns/geolocation-states/${geoLocationId}`)
    .json();
};

export const addNewLead = (
  form: IThriveLeadForm,
  campaignId: any
): Promise<any> => {
  return spectrumApi.post(`thrive-campaigns/${campaignId}/leads`, {
    json: form,
  });
};

export const updateThriveCampaign = (
  form: IThriveCampaignEditForm,
  campaignId?: string
): Promise<any> => {
  return spectrumApi.put(`thrive-campaigns/${campaignId}`, {
    json: {
      campaignDetails: {
        industry: form.industry,
        geolocation: form.geolocation,
        geolocationStates: form.geolocationStates,
        ageGroups: form.ageGroups,
        stockTickerList: form.stockTickerList,
      },
      campaignContent: {
        presentation: {
          fileName: form.presentationName,
          base64Content: form.presentationUrl,
        },
        companyWebsite: form.companyWebsite,
      },
    },
  });
};

export const updateThriveCampaignStatus = (
  id: string,
  status: string
): Promise<any> => {
  return spectrumApi
    .put(`thrive-campaigns/${id}/status`, {
      json: {
        status: status,
      },
    })
    .json();
};

export const updateThrivePaymentStatus = (
  campaignId?: number,
  paymentId?: number,
  status?: string
) => {
  return spectrumApi.put(
    `payment/thrive-campaign/${campaignId}/payment/${paymentId}`,
    {
      json: {
        paymentStatus: status,
      },
    }
  );
};

export const connectExternalCampaign = (
  externalId: string,
  campaignId?: number
) => {
  return spectrumApi.post(
    `thrive-campaigns/${campaignId}/external-id/${externalId}`
  );
};

export const getThriveCampaignsCSV = (): Promise<any> => {
  return spectrumApi.get(`thrive-campaigns/export-csv`).blob();
};
