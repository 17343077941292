import React, { MouseEvent, FC } from 'react';
import styled from '@emotion/styled';
import { RemoveIcon } from '../../../../../../../../_Styles/images/_Icons/RemoveIcon';
import { ExpandIcon } from '../../../../../../../../_Styles/images/_Icons/ExpandIcon';
import { EditIcon } from '../../../../../../../../_Styles/images/_Icons/EditIcon';

interface IManageTagPanelIconConfig {
  onEditClick?: (event: MouseEvent) => void;
  onRemoveClick?: (event: MouseEvent) => void;
  onExpandClick?: (event: MouseEvent) => void;
  editable?: boolean;
  removable?: boolean;
  expandable?: boolean;
  expanded?: boolean;
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & * {
    margin-right: 2.5rem;
  }
  & *:last-child {
    margin-right: auto;
  }
`;

export const EditableTagControlIcons: FC<IManageTagPanelIconConfig> = ({
  onEditClick,
  onRemoveClick,
  onExpandClick,
  editable,
  removable,
  expanded,
  expandable,
}) => {
  return (
    <IconWrapper>
      {editable && <EditIcon onClick={onEditClick} />}
      {removable && <RemoveIcon onClick={onRemoveClick} />}
      {expandable && <ExpandIcon expanded={expanded} onClick={onExpandClick} />}
    </IconWrapper>
  );
};
