import React, { FC, useState } from 'react';
import { ButtonX } from './../../../../_Components/Buttons/PlxButton/ButtonXStyles';
import styled from '@emotion/styled';
import { PlxLink } from '_Components/PlxLink/PlxLink';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IThemed } from '_Styles/types';
import { useDispatch } from 'react-redux';
import { login } from '_State/thunks';
import { Formik, Form } from 'formik';
import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { FormMassage } from '_Components/Forms/FormMassage';
import { UserType } from '_State/User/user.types';
import { useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core';

const StyledFormErrorMessage = styled(FormMassage)`
  margin: 0.5rem 0;
  @media (max-height: 401px) {
    height: 1rem;
  }
`;

const InputContainer = styled.div<IThemed>`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    width: 90%;
  }
  > div > div > div {
    border-radius: 30px;
  }

  .MuiInputLabel-outlined {
    transform: translate(20px, 10px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 9px 20px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    transition-property: border-color;
    transition-duration: 1s;
    border-color: #000;
    border-width: 0.5px;
  }
`;
const ErrorMsg = styled(FieldErrorMessage)`
  margin-right: 5rem;
`;
const ButtonXWrapper = styled(ButtonX)`
  margin-top: 2rem;
  @media (max-height: 400px) {
    margin-top: 1rem;
  }
`;
const INITIAL_VALUES = {
  email: '',
  password: '',
};

const REQUIRED_FIELDS = ['email', 'password'];
const ADMIN_TYPE_ROLE: string[] = [UserType.ADMIN, UserType.EDITOR];

interface IProps {
  forgotPasswordPath: string;
}

export const LoginForm: FC<IProps> = ({ forgotPasswordPath }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const history = useHistory();
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={({ password, email }) => {
        dispatch(
          login(
            {
              password,
              email,
            },
            (userType) => {
              const { state }: any = location;
              if (state && state.from && state.from.pathname !== '/') {
                history.replace(state.from);
              } else {
                if (ADMIN_TYPE_ROLE.includes(userType)) {
                  history.push('/home/admin/spectrum-dashboard');
                } else if (userType === UserType.DISTRIBUTION_PARTNER) {
                  history.push('/home/finfluencer/dashboard');
                } else if (userType === UserType.AGENCY) {
                  history.push('/home/agency/dashboard');
                } else {
                  history.push('/home/client/dashboard');
                }
              }
            },
            () => setError(t('auth.login.errors.wrongCredentials'))
          )
        );
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(form) => {
        return requiredFieldsValidation(form);
      }}
    >
      {({ handleChange, errors }) => (
        <Form>
          <StyledFormErrorMessage message={error} />
          <InputContainer>
            <div>
              <TextField
                name="email"
                label={t('auth.login.email')}
                placeholder={t('auth.register.emailPlaceholder')}
                className="plx-input"
                onChange={handleChange}
                variant="outlined"
                data-test-id="mail-field"
              />
              <ErrorMsg message={errors.email} testId="email-error" />
              <TextField
                name="password"
                label={t('auth.login.password')}
                // placeholder={t('auth.login.passwordPlaceholder')}
                type={'password'}
                className="plx-input"
                onChange={handleChange}
                variant="outlined"
                data-test-id="password-field"
              />
            </div>
            <ErrorMsg message={errors.password} testId="password-error" />
            <ButtonXWrapper>
              <PlxButton
                // isDisabled={!isValid}
                inputType="submit"
                label={t('auth.login.button')}
                testId="login-button"
              />
            </ButtonXWrapper>
            <PlxLink
              label={t('auth.login.forgotPassword')}
              pathName={forgotPasswordPath}
              testId="forgot-password-button"
            />
          </InputContainer>
        </Form>
      )}
    </Formik>
  );
};
