import styled from '@emotion/styled';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import React, { FC } from 'react';
import { IThemed } from '_Styles/types';
import { useTranslation } from 'react-i18next';

const LeadsInfoWrapper = styled.div`
  margin: auto;
  font-weight: 500;
  font-size: 7rem;
`;

const LeadsGoal = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div<IThemed>`
  font-size: 1.4rem;
  font-weight: normal;

  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const TotalLeads = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: bold;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 9rem;
  height: 100%;
  justify-content: space-around;
`;
interface IProps {
  leadsGoal?: number;
  totalLeads: number;
  className?: string;
}
export const LeadsInfo: FC<IProps> = ({ leadsGoal, totalLeads, className }) => {
  const { t } = useTranslation();
  return (
    <PlxInfoPanel label={'Leads'} className={className}>
      {leadsGoal ? (
        <Wrapper>
          <LeadsGoal>
            <Label>{t('thrive.admin.leadsGoal')}</Label>
            {leadsGoal}
          </LeadsGoal>
          <TotalLeads>
            <Label>{t('thrive.admin.totalLeads')}</Label>
            {totalLeads}
          </TotalLeads>
        </Wrapper>
      ) : (
        <LeadsInfoWrapper>{totalLeads}</LeadsInfoWrapper>
      )}
    </PlxInfoPanel>
  );
};
