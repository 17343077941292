import React, { FC, useEffect, useState } from 'react';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { useFormik } from 'formik';
import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { useParams } from 'react-router-dom';
import { IPostSample } from '_State/Spectrum/types';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import ky from 'ky';
import {
  FileButton,
  FormWrapper,
  Img,
  InputTitle,
  InputWrapper,
  Inputs,
} from './Styles';
import { SampleCarousel } from './SampleCarousel';
import { FileInput } from './FileInput';

interface IProps {
  dialogTitle: string;
  open: boolean;
  initialValue?: IPostSample;
  onClose: () => void;
  onSubmit: (
    campaignId: number,
    form: any,
    postSampleId?: number
  ) => Promise<any>;
  onSave?: () => void;
  handleUpload: (value: number) => void;
}

const INITIAL_VALUES = {
  originalPostUrl: '',
  base64Content: null,
  attachmentId: 0,
};
const REQUIRED_FIELDS = ['originalPostUrl'];

export const EditSampleDialog: FC<IProps> = ({
  dialogTitle,
  open,
  initialValue,
  onClose,
  onSave,
  onSubmit,
  handleUpload,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chosenImage, setChosenImage] = useState<number>(0);
  const [apiError, setApiError] = useState<string>('');
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const updateChosenImage = (value: number) => {
    setChosenImage(value);
  };

  const fillForm = (postSample: IPostSample) => {
    initialValue &&
      initialValue.attachments.length > 0 &&
      formik.setValues({
        originalPostUrl: postSample.originalPostUrl,
        attachmentId: initialValue?.attachments[chosenImage].id,
        base64Content: null,
      });
  };

  useEffect(() => {
    initialValue && fillForm(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      console.log(form);

      setIsSubmitting(true);
      id &&
        onSubmit(+id, form, initialValue?.id).then(
          () => {
            if (onSave) {
              setIsSubmitting(false);
              formik.setValues(INITIAL_VALUES);
              onSave();
            }
          },
          (error: ky.HTTPError) => {
            setApiError(error.message);
            setIsSubmitting(false);
          }
        );
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (!form.base64Content && !form.attachmentId) {
        errors.base64Content = t('errors.reqField');
      }
      if (
        form.originalPostUrl &&
        !form.originalPostUrl.startsWith('http://') &&
        !form.originalPostUrl.startsWith('https://')
      ) {
        errors.originalPostUrl = t('errors.companyWebsite');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleChange = (url: string) => {
    formik.setFieldValue('base64Content', url);
  };

  return (
    <PlxDialog
      title={dialogTitle}
      onSave={formik.handleSubmit}
      onSaveDisabled={isSubmitting}
      open={open}
      closeButton
      onClose={onClose}
    >
      <FormWrapper>
        <Inputs>
          {initialValue && initialValue.attachments.length > 1 ? (
            <SampleCarousel
              value={initialValue}
              updateChosenImage={updateChosenImage}
            />
          ) : initialValue && initialValue.attachments.length > 0 ? (
            <Img
              src={initialValue?.attachments[0].screenshotUrl}
              alt={initialValue?.attachments[0].fileName}
            />
          ) : null}
          {initialValue?.attachments[chosenImage] && (
            <InputWrapper>
              <InputTitle>{t('spectrum.admin.replaceSample')}</InputTitle>
              <FileInput
                fileName={initialValue?.attachments[chosenImage].fileName}
                onChange={handleChange}
              />
            </InputWrapper>
          )}

          {initialValue && (
            <FileButton onClick={() => handleUpload(initialValue.id)}>
              Add more
            </FileButton>
          )}

          <InputWrapper>
            <InputTitle>{t('spectrum.admin.linkSample')}</InputTitle>
            <PlxInput
              defaultValue={initialValue?.originalPostUrl}
              name={'originalPostUrl'}
              onChange={formik.handleChange}
              placeholder={'www.example.com'}
            />
            <FieldErrorMessage message={formik.errors.originalPostUrl} />
            <FieldErrorMessage message={apiError} />
          </InputWrapper>
        </Inputs>
      </FormWrapper>
    </PlxDialog>
  );
};
