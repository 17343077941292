import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { AuthPageWrapper } from '_Routes/Auth/_Components/AuthPageWrapper';
import { useHistory } from 'react-router-dom';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { SuccessInfo } from '_Routes/Auth/_Components/SuccessInfo';

const ButtonWrapper = styled.div`
  margin: auto;
  padding-top: 3rem;
`;
const StyledButton = styled(PlxButtonX)`
  && {
    height: 5.5rem;
    margin: auto;
  }
`;

interface IProps {
  pathLogin: string;
}

export const PasswordSaved: FC<IProps> = ({ pathLogin }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <AuthPageWrapper>
      <SuccessInfo text={t('auth.newPassword.passwordSaved')} />
      <ButtonWrapper>
        <StyledButton
          label={t('auth.register.confirmGo')}
          onClick={() => history.push(pathLogin)}
        />
      </ButtonWrapper>
    </AuthPageWrapper>
  );
};
