/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import {
  createSpectrumTag,
  deleteSpectrumTag,
  updateSpectrumCampaignTag,
  updateSpectrumTags,
  getSpectrumTags,
} from '_State/Spectrum/Api/spectrum.api';
import { getSpectrumCampaigns } from '_State/User/Api/user.api';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import styled from '@emotion/styled';
import { createConfig } from './utils';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';

import { ICampaignsQueryParams, ITag } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';
import { TagsMangerDialog } from '../../../../../../_Components/TagManager/_Compontents/TagsMangerDialog';
import { ITableContext } from '_Types/tableContext';
import { SpectrumTableContext } from './spectrumTableContext';
import {
  getSpectrumCampaignsForAdmin,
  getSpectrumTagsForAdmin,
} from '_State/User/Api/admin-user.api';
import { PlxButtonMenu } from '_Routes/Home/_routes/Dashboard/Utils/PlxButtonMenu';
import { PlxButtonFilter } from './PlxButtonFilter';
import { MultipleTagSelect } from './PlxTagSelect';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const INITIAL_PARAMS: ICampaignsQueryParams = {
  campaignStatusList: [
    'COMPLETED',
    'REVIEW_NEEDED',
    'PAUSED',
    'LAUNCHED',
    'NEW',
    'PAID',
  ],
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  tagIdList: [],
  agencyClientId: undefined,
};

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 15rem;
  }
`;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 3rem;
  width: 100%;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: row-reverse;
  align-items: center;
  height: auto;
`;

const StyledTitle = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  margin-top: 1rem;
`;

const StyledTable = styled(PlxCustomTable)`
  font-family: 'Quicksand';
  font-style: normal;
  && {
    width: 100%;
  }
`;

const StyledTextButton = styled(PlxLinkTextButton)`
  && {
    margin-left: 3rem;
    font-weight: 500;
  }
`;

export const CampaignsTable: FC<{ isAdmin?: number }> = ({ isAdmin }) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const [campaigns, setCampaigns] = useState<ISpectrumCampaign[]>([]);
  const [params, setParams] = useState<ICampaignsQueryParams>(INITIAL_PARAMS);
  const [sorting, setSorting] = useState<string>('DESC');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [dialogCampaignId, setDialogCampaignId] = useState<
    number | undefined
  >();
  const [dialogMode, setDialogMode] = useState<'add' | 'manage' | null>(null);
  const { t } = useTranslation();
  const { isAgencyType, clientSelected } = useContext(NavigationContext);

  useEffect(() => {
    if (isAgencyType && clientSelected) {
      setParams((prevParams) => ({
        ...prevParams,
        agencyClientId: clientSelected as string,
      }));
    }
  }, [isAgencyType, clientSelected]);

  const openAdd = (id: number) => {
    setDialogCampaignId(id);
    setDialogMode('add');
  };
  const openManage = () => setDialogMode('manage');
  const close = () => {
    setDialogMode(null);
    setDialogCampaignId(undefined);
  };

  const fetchCampaigns = () => {
    if (isAgencyType) {
      return getSpectrumCampaigns(params, clientSelected).then(
        ({ content }) => {
          if (content) {
            setCampaigns(content);
          }
          return content;
        }
      );
    }
    if (!!isAdmin) {
      // For Admin
      return getSpectrumCampaignsForAdmin(params, isAdmin).then(
        ({ content }) => {
          if (content) {
            setCampaigns(content);
          }
          return content;
        }
      );
    } else {
      // For Client
      return getSpectrumCampaigns(params).then(({ content }) => {
        if (content) {
          setCampaigns(content);
        }
        return content;
      });
    }
  };
  const fetchTags = () => {
    // const tags: ITag[] = [];
    // campaigns.map((c) => c.tag && tags.push(c.tag));
    // const newTags = tags.filter(
    //   (ele, ind) =>
    //     ind ===
    //     tags.findIndex((elem) => elem.id === ele.id && elem.id === ele.id)
    // );
    !!isAdmin
      ? getSpectrumTagsForAdmin(isAdmin).then((tags) => {
          setTags(tags);
          return tags;
        })
      : getSpectrumTags().then((tags) => {
          setTags(tags);
          return tags;
        });
  };

  useEffect(() => {
    const tags: ITag[] = [];
    campaigns.map((c) => c.tag && tags.push(c.tag));
    const newTags = tags.filter(
      (ele, ind) =>
        ind ===
        tags.findIndex((elem) => elem.id === ele.id && elem.id === ele.id)
    );
    setTags(newTags);
  }, [campaigns]);

  const sortingOptions = [
    {
      id: 'ASC',
      name: t('campaignsTable.sort.asc'),
    },
    {
      id: 'DESC',
      name: t('campaignsTable.sort.desc'),
    },
  ];

  const updateTableData = () => {
    fetchCampaigns().then(fetchTags);
  };

  const config = useMemo(() => {
    return createConfig(
      tags,
      openAdd,
      t,
      isAdmin,
      isAgencyType
    ) as CellConfig<{}>[];
  }, [tags]);

  useEffect(() => {
    updateTableData();
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, [params]);

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedTags = event.target.value as string[];
    setSelectedTags(selectedTags);
    setParams({ ...params, tagIdList: selectedTags });
  };

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onNewSortingChange = (value: string) => {
    setSorting(value);
    setParams({ ...params, sortDirection: value });
  };

  const context: ITableContext<ISpectrumCampaign> = {
    tableConfig: config,
    campaigns,
    tags,
    updateTag: (newTag) => {
      return updateSpectrumTags(newTag, isAdmin).then(() => {
        updateTableData();
      });
    },
    updateCampaignTag: (campaignId, newTagId) => {
      return updateSpectrumCampaignTag(campaignId, newTagId, isAdmin).then(
        () => {
          updateTableData();
        }
      );
    },
    removeTag: (tagId) => {
      return deleteSpectrumTag(tagId, isAdmin).then(() => {
        updateTableData();
      });
    },
    addTag: (newTagName: string) => {
      return createSpectrumTag(newTagName, isAdmin).finally(() => {
        updateTableData();
      });
    },
  };

  return (
    <>
      <SpectrumTableContext.Provider value={context}>
        {!isAdmin && (
          <Row>
            <StyledTitle>
              {t('navBar.myCampaigns')}{' '}
              {(!isAgencyType || clientSelected) && `(${campaigns.length})`}
            </StyledTitle>
            <ButtonsWrapper>
              <PlxButtonMenu
                spectrumCampaigns={campaigns}
                openTags={openManage}
              />
              {config && (
                <>
                  <MultipleTagSelect
                    options={tags}
                    label={t('campaignsTable.tags')}
                    onChange={onChange}
                    value={selectedTags}
                  />
                  <PlxButtonFilter onSort={onNewSortingChange}>
                    Filter
                  </PlxButtonFilter>
                </>
              )}
            </ButtonsWrapper>
          </Row>
        )}
        {config && (!isAgencyType || clientSelected) && (
          <StyledTable newDesign config={config} data={campaigns}>
            {isAdmin && (
              <PlxTableToolbar label={t('campaignsTable.allCampaigns')}>
                <StyledSelect
                  options={sortingOptions}
                  label={t('campaignsTable.sort.label')}
                  onChange={onSortingChange}
                  value={sorting}
                />
                <StyledMultipleSelect
                  options={tags}
                  label={t('campaignsTable.tags')}
                  onChange={onChange}
                  value={selectedTags}
                />
                <StyledTextButton
                  label={t('campaignsTable.manageTags')}
                  onClick={openManage}
                />
              </PlxTableToolbar>
            )}
          </StyledTable>
        )}
        {isAgencyType && !clientSelected && (
          <div>{t('campaignsTable.selectClient')}</div>
        )}

        <TagsMangerDialog
          context={SpectrumTableContext}
          campaignId={dialogCampaignId}
          isAddOnly={dialogMode === 'add'}
          open={!!dialogMode}
          onClose={close}
        />
      </SpectrumTableContext.Provider>
    </>
  );
};
