import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const navigationPanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    open: {
      background: '#f8f9fc',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      height: '100vh',
    },
    close: {
      background: '#f8f9fc',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '5.25rem',
      height: '100vh',
    },
  })
);

export const navigationPanelStylesClient = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    open: {
      background: '#fff',
      borderRight: '1px solid #F2F2F2',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      height: '100vh',
    },
    close: {
      background: '#fff',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '5.25rem',
      height: '100vh',
    },
  })
);
