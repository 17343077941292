import React, { ReactElement, ReactNode } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlxText } from '../Text/PlxText';
import styled from '@emotion/styled';
import { PlxCheckbox } from '_Components/PlxCheckbox/PlxCheckbox';

const newTableStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    background: '#f8f8f8',
    borderTop: '1px solid #D9D9D9',
  },
  table: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    overflow: 'hidden',
  },
  header: {
    padding: '1rem',
    fontSize: '1.2rem',
    color: '#7c7c7c',
    minWidth: '10rem',
    backgroundColor: '#f8f8f8',
  },

  checkbox: {
    padding: '1rem 3rem',
    fontSize: '1.2rem',
    color: '#7c7c7c',
    backgroundColor: 'white',
  },
  container: {},
  cellWithBorderLeft: {
    borderTopLeftRadius: 20,
    background: '#fff',
    padding: '1rem',
    border: 'none',
    fontSize: '1.4rem',
    color: '#1b1b1b',
    fontWeight: 500,
    maxWidth: '18rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cellWithBorderRight: {
    borderTopRightRadius: 20,
    background: '#fff',
    padding: '1rem',
    border: 'none',
    fontSize: '1.4rem',
    color: '#1b1b1b',
    fontWeight: 500,
    maxWidth: '18rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cell: {
    background: '#fff',
    padding: '1rem',
    border: 'none',
    fontSize: '1.4rem',
    color: '#1b1b1b',
    fontWeight: 500,
    maxWidth: '18rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const tableStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #EAEEF1',
  },
  table: {},
  tableBody: { borderRadius: '15px' },
  header: {
    padding: '1rem',
    fontSize: '1.2rem',
    color: '#7c7c7c',
    minWidth: '10rem',
    backgroundColor: 'white',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },

  checkbox: {
    padding: '1rem 3rem',
    fontSize: '1.2rem',
    color: '#7c7c7c',
    backgroundColor: 'white',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  container: {},
  cellWithBorderRight: {},
  cellWithBorderLeft: {},
  cell: {
    padding: '1rem',
    fontSize: '1.4rem',
    color: '#1b1b1b',
    fontWeight: 500,
    maxWidth: '18rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

interface ITableContainerProps {
  fullHeight: boolean;
  maxContentHeight: string;
}
const StyledTableContainer = styled(
  ({ fullHeight, maxContentHeight, ...props }: ITableContainerProps) => (
    <TableContainer {...props} />
  )
)<ITableContainerProps>`
  && {
    max-height: ${({ fullHeight, maxContentHeight }) =>
      fullHeight ? '75vh' : maxContentHeight};
  }
`;

interface DefaultCellProps<RowData> {
  data?: RowData[keyof RowData] | number;
  dataKey?: string;
  testId?: string;
}

const DefaultCell = <RowData extends {}>({
  data,
  dataKey,
  testId,
}: DefaultCellProps<RowData>) => {
  return (
    <PlxText tooltipText={dataKey === 'defaultWalletEmail' ? String(data) : ''}>
      {data ? data : '-'}
    </PlxText>
  );
};

export interface CellConfig<RowData> {
  name: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}
export interface XemCellConfig<RowData> {
  field?: string;
  headerName?: string;
  width?: number;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}
export interface CellConfigX<RowData> {
  name: string;
  prop: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}

export interface PlxCustomTableProps<RowData> {
  data: RowData[];
  newDesign?: boolean;
  config: CellConfig<RowData>[];
  fullHeight?: boolean;
  maxContentHeight?: string;
  onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxValue?: boolean;
  checkboxIndeterminate?: boolean;
  campaignStatus?: string;
}

export const PlxCustomTable = <RowData extends {}>({
  newDesign,
  config,
  data,
  children,
  fullHeight = false,
  maxContentHeight = 'auto',
  onCheckboxChange,
  checkboxValue,
  checkboxIndeterminate,
  campaignStatus,
}: PlxCustomTableProps<RowData> & { children?: ReactNode }) => {
  const classes = newDesign ? newTableStyles() : tableStyles();

  const firstRowColumn = data.map((rowData, index) => {
    return index === 0 && (rowData as any).id;
  });

  const Header = config.map(({ name }, index: number) => (
    <React.Fragment key={index}>
      {name === 'checkbox' ? (
        <TableCell
          className={classes.checkbox}
          align={'center'}
          key={`${index}`}
          padding="checkbox"
        >
          <PlxCheckbox
            value={checkboxValue}
            onChange={onCheckboxChange}
            indeterminate={checkboxIndeterminate}
          />
        </TableCell>
      ) : name === 'Actions' ? (
        <TableCell
          style={{ borderBottom: newDesign ? 'none' : undefined }}
          className={classes.header}
          align={'center'}
          key={`${index}`}
        >
          {name}
        </TableCell>
      ) : (
        <TableCell
          style={{ borderBottom: newDesign ? 'none' : undefined }}
          className={classes.header}
          align={'left'}
          key={`${index}`}
        >
          {name}
        </TableCell>
      )}
    </React.Fragment>
  ));

  return (
    <Paper className={classes.paper}>
      {children}
      <StyledTableContainer
        fullHeight={fullHeight}
        maxContentHeight={maxContentHeight}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>{Header}</TableRow>
          </TableHead>
          <TableBody>
            {data.map((rowData, index) => {
              return (
                <>
                  <TableRow style={{ height: '9vh' }} key={index}>
                    {config.map(({ name, renderCell, propKey }, index) => {
                      const key = propKey ? String(propKey) : index;
                      return (
                        <React.Fragment key={key}>
                          {campaignStatus === 'COMPLETED' ||
                          campaignStatus === 'REVIEW_NEEDED' ||
                          campaignStatus === 'LAUNCHED' ||
                          campaignStatus === 'PAID' ||
                          campaignStatus === 'PAUSED' ? (
                            <>
                              {(rowData as any).status !== 'ELIGIBLE' && (
                                <TableCell
                                  className={
                                    newDesign
                                      ? index === 0 &&
                                        (rowData as any).id ===
                                          firstRowColumn[0]
                                        ? classes.cellWithBorderLeft
                                        : name === 'Actions' &&
                                          (rowData as any).id ===
                                            firstRowColumn[0]
                                        ? classes.cellWithBorderRight
                                        : classes.cell
                                      : classes.cell
                                  }
                                >
                                  {renderCell ? (
                                    renderCell(rowData, key)
                                  ) : (
                                    <DefaultCell
                                      data={
                                        propKey
                                          ? rowData[propKey]
                                          : Math.random()
                                      }
                                      dataKey={String(propKey)}
                                    />
                                  )}
                                </TableCell>
                              )}
                            </>
                          ) : (
                            <TableCell
                              key={key}
                              className={
                                newDesign
                                  ? index === 0 &&
                                    (rowData as any).id === firstRowColumn[0]
                                    ? classes.cellWithBorderLeft
                                    : name === 'Actions' &&
                                      (rowData as any).id === firstRowColumn[0]
                                    ? classes.cellWithBorderRight
                                    : classes.cell
                                  : classes.cell
                              }
                              data-test-id={
                                index === 8
                                  ? 'channel-details-button'
                                  : null || index === 9
                                  ? 'review-channel-button'
                                  : null
                              }
                            >
                              {renderCell ? (
                                renderCell(rowData, key)
                              ) : (
                                <DefaultCell
                                  data={
                                    propKey ? rowData[propKey] : Math.random()
                                  }
                                />
                              )}
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Paper>
  );
};
