import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

export const ThriveIcon: FC<IIconProps> = ({ isFocused }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20.3334V8.66675"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M15 20.3334V1.66675"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 20.3334V15.6667"
        stroke={isFocused ? '#FFF' : '#231F20'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
