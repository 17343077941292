import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  IDialogProps,
  PlxDialog,
} from '_Components/Dialogs/PlxDialog/PlxDialog';
import { FormSection } from '_Components/FormSection/FormSection';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import { PlxFileInput } from '_Components/PlxFileInput';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { ICampaignUpdate } from '_State/Thrive/types';

interface IProps extends IDialogProps {
  onCampaignUpdateSave: (values: ICampaignUpdate) => void;
  campaignUpdate?: ICampaignUpdate;
}
const Form = styled.div`
  width: 100%;
  margin: 3rem;
`;

const INITIAL_VALUES: ICampaignUpdate = {
  instructions: '',
  createPresentationDto: undefined,
};

export const RequestUpdateDialog: FC<IProps> = ({
  open,
  onClose,
  onCampaignUpdateSave,
  title,
  campaignUpdate,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      onCampaignUpdateSave(form);
      onClose();
    },
  });

  const handleSave = () => {
    formik.handleSubmit();
  };

  const handleFileChange = (url: string, fileName: string) => {
    formik.setFieldValue('createPresentationDto', {
      fileName: fileName,
      base64Content: url,
    });
  };

  return (
    <PlxDialog
      closeButton
      open={open}
      onClose={onClose}
      title={title}
      onSave={handleSave}
    >
      <Form>
        <InputSection
          value={campaignUpdate?.instructions}
          title={t('thrive.dashboard.instructions')}
          placeholder={t('thrive.dashboard.instructionsPlaceholder')}
          fieldName={'instructions'}
          handleChange={formik.handleChange}
          rows={5}
          rowsMax={10}
          multiline
        />
        <FormSection
          title={t('thrive.dashboard.uploadNewInvestorPresentation')}
        >
          <PlxFileInput
            fileName={campaignUpdate?.createPresentationDto?.fileName}
            onChange={handleFileChange}
          />
        </FormSection>
      </Form>
    </PlxDialog>
  );
};
