import {
  checkToken,
  getUserData,
  IUserLoginPayload,
  IUserMoxoPayload,
  loginUser,
  loginUserWithMoxo,
} from '_Api/auth';
import { Dispatch } from 'redux';
import { authActions } from './Auth/auth.state';
import { userActions } from './User/user.state';
import { setCredentials } from '_Api/api';
import { store } from '_State/store';

export const login = (
  loginPayload: IUserLoginPayload,
  onLoginSuccess: (userType: string) => void,
  onLoginFailure: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const {
        access_token: token,
        refresh_token: refreshToken,
        expires_in: expires,
      } = await loginUser(loginPayload);
      dispatch(authActions.saveCredentials({ token, refreshToken }));
      setCredentials({ token });
      sessionStorage.setItem('access_token', token);
      sessionStorage.setItem('refresh_token', refreshToken);
      sessionStorage.setItem('expires', expires);
      const user = await getUserData();
      dispatch(userActions.userLogin(user));
      onLoginSuccess(user.userType);
    } catch (httpError) {
      onLoginFailure();
      console.log(httpError);
    }
  };
};

export const loginUsingMoxoToken = (
  moxoLoginPayload: IUserMoxoPayload,
  onLoginSuccess: () => void,
  onLoginFailure: (message: string) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      // Attempt to log in with the provided payload
      const {
        access_token: token,
        access_token: refreshToken,
        expires_in: expires,
      } = await loginUserWithMoxo(moxoLoginPayload);

      // Dispatch actions and perform necessary operations upon successful login
      dispatch(authActions.saveCredentials({ token, refreshToken }));
      setCredentials({ token });
      sessionStorage.setItem('access_token', token);
      sessionStorage.setItem('refresh_token', refreshToken);
      sessionStorage.setItem('expires', expires);

      // Fetch user data and dispatch user login action
      const user = await getUserData();
      dispatch(userActions.userLogin(user));

      // Call the success callback with the user type
      onLoginSuccess();
    } catch (error) {
      const errorResponse: any = error;
      onLoginFailure(errorResponse.response.errorBody.error_description);
    }
  };
};

export const loadUserFromToken = (): Promise<any> => {
  const token = sessionStorage.getItem('access_token');
  const refreshToken = sessionStorage.getItem('refresh_token');

  if (!token || token === '' || !refreshToken || refreshToken === '') {
    return Promise.resolve();
  }
  return checkToken(token).then(
    ({ active }: any) => {
      if (active) {
        store.dispatch(authActions.saveCredentials({ token, refreshToken }));
        setCredentials({ token });
        return getUserData().then(
          (user) => {
            store.dispatch(userActions.userLogin(user));
          },
          () => Promise.resolve()
        );
      } else {
        return Promise.resolve();
      }
    },
    () => {
      return Promise.resolve();
    }
  );
};
