import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
`;
const Message = styled.div`
  width: 36rem;
  height: 9rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 3rem;
  margin: auto;
`;

interface IProps {
  notification: string;
}

export const NotifyMessage: FC<IProps> = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Message>{t(notification)}</Message>
    </Wrapper>
  );
};
