import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Route } from 'react-router-dom';
import { AdminSpectrumDashboard } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/Dashboard/AdminSpectrumDashboard';
import { AdminSpectrumCampaign } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/AdminSpectrumCampaign';
import { AdminThriveDashboard } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveDashboard/AdminThriveDashboard';
import { AdminThriveCampaign } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaign/AdminThriveCampaign';
import { AdminThriveCampaignAssets } from '_Routes/Home/_routes/Admin/_Routes/Thrive/AdminThriveCampaignAssets/AdminThriveCampaignAssets';
import { AdminChannels } from '_Routes/Home/_routes/Admin/_Routes/Channels/AdminChannels';
import { Channel } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/Channel';
import { UserManagement } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/UserManagement';
import { Orders } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/Orders/Orders';
import { ClientAnalytics } from './_Routes/Users/UserManagement/Analytics/ClientAnalytics';
import { ClientAnalyticsCampaign } from './_Routes/Users/UserManagement/Analytics/ClientAnalyticsCampaign';
import { SpectrumCampaignReport } from '../Spectrum/_Routes/SpectrumDashboard/_Routes/SpectrumCampaignReport/SpectrumCampaignReport';
import SpectrumEngagement from '../Spectrum/_Routes/SpectrumEngagement/SpectrumEngagement';
import { Referrals } from './_Routes/Referrals/Referrals';
import { EndClients } from './_Routes/Users/UserManagement/Tables/EndClients';

const AdminBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Admin: FC = () => {
  return (
    <AdminBody>
      <Route exact path="/home/admin/spectrum-dashboard">
        <AdminSpectrumDashboard />
      </Route>
      <Route exact path="/home/admin/spectrum-campaign/:id">
        <AdminSpectrumCampaign />
      </Route>
      <Route exact path="/home/admin/spectrum-analytics">
        <ClientAnalytics />
      </Route>
      <Route exact path="/home/admin/spectrum-engagement">
        <SpectrumEngagement />
      </Route>
      <Route exact path="/home/admin/thrive-dashboard">
        <AdminThriveDashboard />
      </Route>
      <Route exact path="/home/admin/channels">
        <AdminChannels />
      </Route>
      <Route exact path="/home/admin/referrals">
        <Referrals />
      </Route>
      <Route exact path="/home/admin/thrive-campaign/:id">
        <AdminThriveCampaign />
      </Route>
      <Route exact path="/home/admin/thrive-campaign/assets/:id">
        <AdminThriveCampaignAssets />
      </Route>
      <Route exact path="/home/admin/channels/:id">
        <Channel />
      </Route>
      <Route exact path="/home/admin/users">
        <UserManagement />
      </Route>
      <Route exact path="/home/admin/users/orders/:id">
        <Orders />
      </Route>
      <Route exact path="/home/admin/orders/client/:agencyClientId/agency/:id">
        <Orders />
      </Route>
      <Route exact path="/home/admin/users/clients/:id">
        <EndClients />
      </Route>
      <Route exact path="/home/admin/users/analyt/campaigns/:id">
        <ClientAnalyticsCampaign />
      </Route>
      <Route exact path="/home/admin/users/analyt/campaign/:userId/:id">
        <SpectrumCampaignReport />
      </Route>
    </AdminBody>
  );
};
