import { createContext } from 'react';
import { IPaymentContext } from '_Types/paymentContext';
import { ISpectrumCampaign } from '_State/Spectrum/types';

//const emptyCallback = <T>(result: T) => () => Promise.resolve(result);
const emptyFunction = () => {
  console.log('notImplmeneted');
};

export const defaultContext: IPaymentContext<ISpectrumCampaign> = {
  draft: undefined,
  paymentFailureDescription: '',
  paymentConfirmationDescription: '',
  onSubmit: emptyFunction,
  packages: [],
  handlePaymentConfirmed: emptyFunction,
  handleBack: emptyFunction,
  successDialogOpened: false,
  failDialogOpened: false,
  closeFailDialog: emptyFunction,
  closeSuccessDialog: emptyFunction,
  isProcessing: false,
  isThrivePayment: false,
};

export const SpectrumPaymentContext = createContext<
  IPaymentContext<ISpectrumCampaign>
>(defaultContext);
