import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.css';
import App from '_App/App';
import * as serviceWorker from './serviceWorker';
import './i18n';

export const subdomain = window.location.hostname.split('.')[0];
const isCaptcha =
  subdomain === 'dev' || subdomain === 'staging' || subdomain === 'app';

ReactDOM.render(
  <React.StrictMode>
    {isCaptcha ? (
      <GoogleReCaptchaProvider reCaptchaKey="6LfVN_UmAAAAABTjJvfpRExM24PfcmOdW6xuk726">
        <Suspense fallback="loading">
          <App />
        </Suspense>
      </GoogleReCaptchaProvider>
    ) : (
      <Suspense fallback="loading">
        <App />
      </Suspense>
    )}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
