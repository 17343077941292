import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { PlxTilesPanel } from '_Components/Panels/PlxTilesPanel/PlxTilesPanel';
import { ITile } from '_Types/ITile';
import { createAdminChannelStatisticsConfig } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { IDistributionPartnerChannelStatistics } from '_State/DistributionPartner/distributionPartner.types';

const StyledTiles = styled(PlxTilesPanel)`
  margin-bottom: 2rem;
`;

export const ChannelStatistics: FC<{
  statistics?: IDistributionPartnerChannelStatistics;
}> = ({ statistics }) => {
  const channelsStatisticsConfig: ITile[] = useMemo(() => {
    return createAdminChannelStatisticsConfig(statistics) as ITile[];
  }, [statistics]);

  return <StyledTiles tiles={channelsStatisticsConfig} />;
};
