import React, { FC } from 'react';
import { IPackageInfoHeader } from './PackageInfo.types';
import styled from '@emotion/styled';
// import { useTranslation } from 'react-i18next';

interface IProps {
  header: IPackageInfoHeader;
}

const Wrapper = styled.div``;
const Title = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.5rem;
  color: #1b1b1b;
`;

export const PackageInfoHeader: FC<IProps> = ({ header }) => {
  // const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>{header.title}</Title>
    </Wrapper>
  );
};
