import { createContext } from 'react';
import { IPaymentContext } from '_Types/paymentContext';
import { IThriveCampaign } from '_State/Thrive/types';

//const emptyCallback = <T>(result: T) => () => Promise.resolve(result);
const emptyFunction = () => {
  console.log('notImplmeneted');
};

export const defaultContext: IPaymentContext<IThriveCampaign> = {
  draft: undefined,
  paymentFailureDescription: '',
  paymentConfirmationDescription: '',
  onSubmit: emptyFunction,
  packages: [],
  handlePaymentConfirmed: emptyFunction,
  handleBack: emptyFunction,
  successDialogOpened: false,
  failDialogOpened: false,
  closeFailDialog: emptyFunction,
  closeSuccessDialog: emptyFunction,
  isProcessing: false,
  isThrivePayment: false,
};

export const ThrivePaymentContext = createContext<
  IPaymentContext<IThriveCampaign>
>(defaultContext);
