import React, { FC } from 'react';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import {
  ReferralPaymentStatus,
  ReferralStatus,
} from '_State/DistributionPartner/distributionPartner.enums';
import { useTranslation } from 'react-i18next';

interface IProps {
  status: string;
  referralId?: string;
  onManualPay: (id?: string) => void;
  onApprovePayout: (id?: string) => void;
  transactionStatus?: string;
  defaultWallet?: string;
}

export const EligibleReferralsActions: FC<IProps> = ({
  status,
  referralId,
  onManualPay,
  onApprovePayout,
  transactionStatus,
  defaultWallet,
}) => {
  const { t } = useTranslation();
  return status === ReferralStatus[ReferralStatus.APPROVED] ? (
    <PlxButton
      size={'small'}
      label={
        transactionStatus ===
          ReferralPaymentStatus[ReferralPaymentStatus.FAILED] || !defaultWallet
          ? t('eligibleReferralAction.paidManually')
          : t('eligibleReferralAction.approvePayout')
      }
      variant={ButtonVariant.Outlined}
      onClick={() =>
        transactionStatus ===
          ReferralPaymentStatus[ReferralPaymentStatus.FAILED] || !defaultWallet
          ? onManualPay(referralId)
          : onApprovePayout(referralId)
      }
    />
  ) : null;
};
