import React, { FC } from 'react';
import { IIconProps } from '_Routes/Home/NavigationPanel/Icons/types';

export const EditIcon: FC<IIconProps> = ({ isFocused, onClick }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.74517 2.58984H2.49893C2.10139 2.58984 1.72013 2.74777 1.43903 3.02887C1.15792 3.30997 1 3.69123 1 4.08877V14.5812C1 14.9788 1.15792 15.36 1.43903 15.6411C1.72013 15.9223 2.10139 16.0802 2.49893 16.0802H12.9914C13.3889 16.0802 13.7702 15.9223 14.0513 15.6411C14.3324 15.36 14.4903 14.9788 14.4903 14.5812V9.33501"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3655 1.46566C13.6636 1.1675 14.068 1 14.4896 1C14.9113 1 15.3157 1.1675 15.6138 1.46566C15.912 1.76381 16.0795 2.1682 16.0795 2.58985C16.0795 3.01151 15.912 3.41589 15.6138 3.71404L8.49394 10.8339L5.49609 11.5834L6.24556 8.58555L13.3655 1.46566Z"
        stroke="#A9B3BB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
