import React, { useEffect, FC } from 'react';
import styled from '@emotion/styled';
import { LoginForm } from './LoginForm';
import { IThemed } from '_Styles/types';
import { AuthPageWrapper } from '_Routes/Auth/_Components/AuthPageWrapper';
import { AuthHeader } from '_Routes/Auth/_Components/AuthHeader';
import { PlxLinkWrapper } from '_Components/Buttons/PlxLinkWrapper/PlxLinkWrapper';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { loginUsingMoxoToken } from '_State/thunks';
import { useDispatch } from 'react-redux';

const LinkWrapper = styled(PlxLinkWrapper)<IThemed>`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

interface IProps {
  registerPath: string;
  forgotPasswordPath: string;
}

export const Login: FC<IProps> = ({ registerPath, forgotPasswordPath }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const moxoToken = searchParams.get('mtoken');

  useEffect(() => {
    if (moxoToken) {
      dispatch(
        loginUsingMoxoToken(
          {
            moxoToken,
          },
          () => {
            history.push('/home/client/dashboard');
          },
          (message) => {
            history.push(`/auth/error?message=${encodeURIComponent(message)}`);
          }
        )
      );
    }
  }, [moxoToken, history, dispatch]);

  // Conditionally render the login form if moxo_token is not available or invalid
  if (!moxoToken) {
    return (
      <AuthPageWrapper>
        <AuthHeader>
          {t('auth.login.title')}
          <span> or </span>
          <LinkWrapper to={registerPath} data-test-id="sign-up-button">
            <span>{t('auth.register.singUpTitle')} </span>
          </LinkWrapper>
        </AuthHeader>
        <LoginForm forgotPasswordPath={forgotPasswordPath} />
      </AuthPageWrapper>
    );
  } else {
    // Render nothing if moxo_token is available (the login attempt is being made)
    return null;
  }
};
