import XemotoLogoLargeNew from '_Styles/images/bpai-logo.png';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { UserType } from '_State/User/user.types';

const SpectrumLogoContainer = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
`;

const UntappedLabImage = styled.img<IThemed>`
  height: 2.6rem;
  cursor: pointer;
`;

export const FullLogoX: FC = () => {
  const history = useHistory();
  const { user } = useSelector((state: IState) => state.user);

  const onClick = () => {
    if (
      user?.userType === UserType.ADMIN ||
      user?.userType === UserType.EDITOR
    ) {
      history.push('/home/admin/spectrum-dashboard');
    } else if (user?.userType === UserType.DISTRIBUTION_PARTNER) {
      history.push('/home/finfluencer/dashboard');
    } else if (user?.userType === UserType.AGENCY) {
      history.push('/home/agency/dashboard');
    } else {
      history.push('/home/client/dashboard');
    }
  };

  return (
    <SpectrumLogoContainer>
      <UntappedLabImage src={XemotoLogoLargeNew} onClick={onClick} />
    </SpectrumLogoContainer>
  );
};
