import { createStyles, Dialog, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Close } from '@material-ui/icons';
import { ICommonProps } from '_Types/props';

const CloseIcon = styled(Close)`
  margin: 1rem;
  right: 0;
  cursor: pointer;
  position: absolute;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: '50rem',
      minHeight: '10rem',
      backgroundColor: '#FFFFFF',
    },
  })
);

const ChildrenWrapper = styled.div`
  margin: 4.5rem 3.3rem;
`;

const Wrapper = styled.div`
  position: relative;
`;

export interface ISimpleDialogProps extends ICommonProps {
  open: boolean;
  onClose?: () => void;
}

export const PlxSimpleDialog: FC<ISimpleDialogProps> = ({
  open,
  onClose,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.paper }}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </Wrapper>
    </Dialog>
  );
};
