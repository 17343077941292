import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { PlxDashboardHeader } from '_Components/PlxDashboardHeader/PlxDashboardHeader';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { UserManagementTabs } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/UserManagementTabs';
import { getUsersCSV } from '_State/User/Api/admin-user.api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledHeader = styled(PlxDashboardHeader)`
  margin: auto 4rem;
`;

const HeaderWrapper = styled.div`
  height: 7.5rem;
`;

const CSVButton = styled(PlxButton)`
  && {
    margin-right: 3rem;
  }
`;

const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

export const UserManagement: FC = () => {
  const [csvButtonState, setCsvButtonState] = useState<boolean>(false);

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleUsersCSV = () => {
    setCsvButtonState(true);
    getUsersCSV().then((data) => {
      createDownloadCSV(data, 'users');
    });
    setCsvButtonState(false);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledHeader title={'Users'}>
          <CSVButton
            size={'small'}
            isDisabled={csvButtonState}
            label={'Download CSV'}
            onClick={handleUsersCSV}
          />
        </StyledHeader>
      </HeaderWrapper>
      <Body>
        <UserManagementTabs />
      </Body>
    </Wrapper>
  );
};
