import React from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// import { OptionsContainer } from './FinfluencerWalletStyles';
import styled from '@emotion/styled';

const GridWrap = styled.div`
  margin: 5rem 0;
  > div > div > div > button {
    /* border-radius: 25px; */
    padding: 0 4rem;
    color: #000;
  }
  .MuiToggleButton-root.Mui-selected {
    color: #000;
  }
`;

interface IProps {
  alignment: string;
  options: {
    option1: string;
    option2: string;
    option3?: string;
  };
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => void;
}
export const ToggleButtons = (props: IProps) => {
  return (
    <GridWrap>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <ToggleButtonGroup
            size="small"
            value={props.alignment}
            exclusive
            onChange={props.handleChange}
          >
            <ToggleButton value="left">
              <p> {props.options.option1}</p>
            </ToggleButton>
            <ToggleButton value="center">
              <p>{props.options.option2}</p>
            </ToggleButton>
            <ToggleButton value="right">
              <p> {props.options.option3}</p>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </GridWrap>
  );
};
