import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxSideContainer } from '_Components/PlxSideImage/PlxSideContainer';
import BgImg1 from './bgImg1.svg';
import BgImg2 from './bgImg2.svg';
import { Login } from '../_Routes/Login/Login';
import { Route, useLocation } from 'react-router-dom';
import { ResetPassword } from '_Routes/Auth/_Routes/ResetPassword/ResetPassword';
import { NewPassword } from '_Routes/Auth/_Routes/NewPassword/NewPassword';
import { RegisterConfirm } from '../_Routes/RegisterConfirm/RegisterConfirm';
import { PasswordSaved } from '_Routes/Auth/_Routes/PasswordSaved/PasswordSaved';
import { ActivateAccount } from '_Routes/Auth/_Routes/ActivateAccount/ActivateAccount';
import { sendResetLink } from '../../../_Api/auth';
import { useTranslation } from 'react-i18next';
import { DistributionPartnerRegister } from './Register/DistributionPartnerRegister';

const AuthBody = styled.div`
  height: 100%;
  width: 100%;
  max-height: 600px;
  max-width: 960px;
  overflow: hidden;
  display: flex;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  overflow: hidden;
  background: #fff;
  position: relative;
  padding: 3%;

  .side-image {
    @media (max-width: 650px) {
      display: none;
    }
  }
`;
const ImgWrapper1 = styled.img`
  position: absolute;
  left: 0;
  top: 3rem;
  height: 25rem;
`;
const ImgWrapper2 = styled.img`
  position: absolute;
  right: 0;
  bottom: -12rem;
  height: 56rem;
`;

export const AuthDistributionPartner: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const handleResetPassword = (email: string) => sendResetLink(email);

  return (
    <AuthBody
      style={{
        maxWidth:
          location.pathname === '/auth/finfluencer/register'
            ? '1200px'
            : undefined,
      }}
    >
      <ImgWrapper1 src={BgImg1} />
      <ImgWrapper2 src={BgImg2} />
      {location.pathname ===
      '/auth/finfluencer/activate-account' ? null : location.pathname ===
        '/auth/finfluencer/register-confirmed' ? null : (
        <PlxSideContainer
          title={t('auth.dp.sidePanel.title')}
          subtitle={t('auth.dp.sidePanel.subtitle')}
          className="side-image"
          distributionPartner={
            location.pathname === '/auth/finfluencer/register'
          }
        />
      )}

      {/* <Redirect
        from="/auth/distribution-partner"
        to="/auth/finfluencer/login"
      /> */}
      <Route exact path="/auth/finfluencer/login">
        <Login
          registerPath="/auth/finfluencer/register"
          forgotPasswordPath={'/auth/finfluencer/reset-password'}
        />
      </Route>
      <Route exact path="/auth/finfluencer/register">
        <DistributionPartnerRegister loginPath="/auth/finfluencer/login" />
      </Route>
      <Route exact path="/auth/finfluencer/reset-password">
        <ResetPassword
          backPath={'/auth/finfluencer/login'}
          onResetPassword={handleResetPassword}
        />
      </Route>
      <Route exact path="/auth/finfluencer/new-password/:token">
        <NewPassword />
      </Route>
      <Route exact path="/auth/finfluencer/register-confirmed">
        <RegisterConfirm pathLogin="/auth/finfluencer/login" />
      </Route>
      <Route exact path="/auth/finfluencer/password-saved">
        <PasswordSaved pathLogin="/auth/finfluencer/login" />
      </Route>
      <Route exact path="/auth/finfluencer/activate-account/:token">
        <ActivateAccount />
      </Route>
    </AuthBody>
  );
};
