import React, { FC, useEffect, useState } from 'react';
import { ICommonProps } from '_Types/props';
import { IThemed } from '_Styles/types';
import styled from '@emotion/styled';
import ReactInputVerificationCode from 'react-input-verification-code';
import { CircularProgress } from '@material-ui/core';
import {
  sendVerificationCode,
  updateFinfluencerPhoneStatus,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { useTranslation } from 'react-i18next';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';

const RequiredPointer = styled.span<IThemed>`
  color: ${({ theme }) => theme.palette.error.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  overflow: hidden;
`;

const Title = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  color: #808285;
  font-size: 1.2rem;
  font-family: Rubik, sans-serif;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  border-bottom: 1px solid #d5dde3;
  margin-bottom: -1px;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
`;
const Verify = styled.span`
  border: 1px solid #ff8d8d;
  border-radius: 10px;
  margin-left: 2rem;
  padding: 2px 1rem;
  display: block;
  cursor: pointer;
  font-size: 10px;
  color: #ff8d8d;
`;
const VerifyNowContainer = styled.div`
  display: flex;
  text-align: end;
  gap: 2rem;
  align-items: center;
`;
const RateAlert = styled.span`
  font-size: 8px;
  margin-left: 1rem;
`;
const VerificationCodeInput = styled.div<{ verify?: boolean }>`
  height: ${({ verify }) => (verify ? '70px' : '0')};
  width: 100%;
  border-top: ${({ verify }) => (verify ? '1px solid #d5dde3' : 'none')};
  transition: all 1s;
  padding-left: 1rem;
  padding-top: 1rem;
`;
const VerificationContainer = styled.div`
  display: flex;
  flex-direction: inherit;
  justify-content: center;
`;

interface IProps extends ICommonProps {
  title?: string;
  required?: boolean;
  tooltip?: string;
  notVerified?: boolean;
  verified?: boolean;
  handleValidCode?: any;
  edited?: boolean;
}

export const FormSectionPhone: FC<IProps> = ({
  tooltip,
  required = false,
  title,
  children,
  className,
  notVerified,
  edited,
}) => {
  const { t } = useTranslation();
  const [verify, setVerify] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (timeLeft >= 1) {
      const intervalId = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const handleSendCode = () => {
    sendVerificationCode()
      .then((r) => {
        setTimeLeft(60);
        setSendCode(true);
        console.log(r);
      })
      .catch((e) => {
        console.log(e.message);
        alert('please check your phone number. e.g. +1 123 456 7890');
      });
  };

  const handleClick = () => {
    setVerify(true);
  };
  const handleComplete = (e: any) => {
    if (!isNaN(e)) {
      setLoading(true);
      // handleValidCode(e);
      console.log(e);
      updateFinfluencerPhoneStatus(e).then((r) => {
        try {
          console.log(r);
          if (r) {
            setVerify(false);
            setLoading(false);
            setOpen(true);
          } else {
            alert('Wrong CODE!!');
            setVerify(false);
            setVerify(true);
            setLoading(false);
          }
        } catch (error) {
          alert('Please check your phone number');
          console.log(error);
        }
      });
    }
  };
  // useEffect(() => {
  //   setVerify(true);
  // }, []);
  const handleDialogClose = () => {
    setOpen(false);
    window.location.reload();
  };
  return (
    <Wrapper className={className}>
      <Title>
        <span>
          {title}
          {required && <RequiredPointer>*</RequiredPointer>}
          {notVerified && (
            <span
              style={{
                marginLeft: '.5rem',
                fontSize: '1rem',
              }}
            >
              {t('profile.verified')}
            </span>
          )}
          {/* {tooltip && <StyledTooltip tooltipText={tooltip} />} */}
        </span>
        {!notVerified && !edited && (
          <VerifyNowContainer>
            {verify && <RateAlert>{t('profile.otpRateAlert')}</RateAlert>}
            {!verify && (
              <Verify onClick={handleClick}>{t('profile.verifyNow')}</Verify>
            )}
            {verify && (
              <div>
                <span>
                  {timeLeft > 0 ? (
                    <p>
                      {t('profile.sendNewCode')} ({timeLeft})
                    </p>
                  ) : (
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'end',
                        color: '#3C6EF6',
                        cursor: 'pointer',
                      }}
                      onClick={handleSendCode}
                    >
                      {t('profile.sendCode')}
                    </p>
                  )}
                </span>
              </div>
            )}
          </VerifyNowContainer>
        )}
      </Title>
      <div>{children}</div>
      {!notVerified && sendCode && (
        <VerificationCodeInput verify={verify}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <VerificationContainer>
              <ReactInputVerificationCode
                autoFocus
                // onChange={handleComplete}
                onCompleted={handleComplete}
                length={6}
              />
            </VerificationContainer>
          )}
        </VerificationCodeInput>
      )}
      <PlxDialog title={''} open={open} closeButton onClose={handleDialogClose}>
        <p style={{ fontSize: '2rem', padding: '6rem' }}>
          {t('profile.sucVerified')}
        </p>
      </PlxDialog>
    </Wrapper>
  );
};
