import React, { ReactElement, ReactNode } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlxText } from '../Text/PlxText';
import styled from '@emotion/styled';
import { PlxCheckbox } from '_Components/PlxCheckbox/PlxCheckbox';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const tableStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    minHeight: '500px',
    border: '1px solid #EAEEF1',
    marginBottom: '3rem',
    marginTop: '5rem',
    // overflow: 'scroll',
  },
  TableHead: {
    // backgroundColor: '#f8f9fb',
    borderRadius: '10px',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    height: '10px',
  },
  header: {
    padding: '1rem',
    fontSize: '1rem',
    color: '#7c7c7c',
    minWidth: '5rem',
    cursor: 'pointer',
    borderBottom: 'none',
  },
  checkbox: {
    padding: '1rem 3rem',
    fontSize: '1.2rem',
    color: '#7c7c7c',
    backgroundColor: 'white',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  container: {
    overflow: 'scroll',
  },
  cell: {
    padding: '1rem',
    fontSize: '1.2rem',
    fontWeight: 400,
    maxWidth: '24rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    borderColor: '#f3f3f3',
  },
}));

interface ITableContainerProps {
  fullHeight: boolean;
  maxContentHeight: string;
}
const StyledTableContainer = styled(
  ({ fullHeight, maxContentHeight, ...props }: ITableContainerProps) => (
    <TableContainer {...props} />
  )
)<ITableContainerProps>`
  && {
    max-height: ${({ fullHeight, maxContentHeight }) =>
      fullHeight ? '75vh' : maxContentHeight};
  }
`;
interface ITableContainerProps {
  fullHeight: boolean;
  maxContentHeight: string;
}
const StyledTableHead = styled(TableHead)`
  /* && th:nth-of-type(1) {
    border-radius: 10px 0 0 10px;
  }
  && th:nth-of-type(9) {
    border-radius: 0 10px 10px 0;
  } */
`;
const ArrowWrapper = styled.span`
  display: flex;
`;

interface DefaultCellProps<RowData> {
  data?: RowData[keyof RowData] | number;
}

const DefaultCell = <RowData extends {}>({
  data,
}: DefaultCellProps<RowData>) => {
  return <PlxText>{data}</PlxText>;
};

export interface CellConfig<RowData> {
  name: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}
export interface CellConfigX<RowData> {
  name: string;
  prop: string;
  propKey?: keyof RowData;
  renderCell?: (d: RowData, name: string | number) => ReactElement;
}

export interface PlxCustomTableProps<RowData> {
  data: RowData[];
  config: CellConfigX<RowData>[];
  fullHeight?: boolean;
  maxContentHeight?: string;
  onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxValue?: boolean;
  checkboxIndeterminate?: boolean;
  onClick?: (name: string) => void;
  columnToSort?: string;
  sortDataDirection?: string;
}

export const PlxCustomTableX = <RowData extends {}>({
  config,
  data,
  children,
  fullHeight = false,
  maxContentHeight = '43rem',
  onCheckboxChange,
  checkboxValue,
  checkboxIndeterminate,
  onClick,
  columnToSort,
  sortDataDirection,
}: PlxCustomTableProps<RowData> & { children?: ReactNode }) => {
  const classes = tableStyles();

  const handleSort = (name: string) => {
    if (onClick && name !== '') {
      onClick(name);
    }
  };

  const Header = config.map(({ name, prop }, index) => (
    <React.Fragment key={index}>
      {name === 'checkbox' ? (
        <TableCell
          className={classes.checkbox}
          align={'center'}
          key={index}
          padding="checkbox"
        >
          <PlxCheckbox
            value={checkboxValue}
            onChange={onCheckboxChange}
            indeterminate={checkboxIndeterminate}
          />
        </TableCell>
      ) : (
        <TableCell className={classes.header} align={'left'} key={index}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 300,
            }}
            onClick={() => handleSort(prop)}
          >
            {name}
            <ArrowWrapper>
              {prop !== '' && (
                <>
                  {columnToSort === prop ? (
                    sortDataDirection === 'asc' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    )
                  ) : (
                    <ArrowDropDownIcon style={{ opacity: '0' }} />
                  )}
                </>
              )}
            </ArrowWrapper>
          </div>
        </TableCell>
      )}
    </React.Fragment>
  ));

  return (
    <Paper className={classes.paper} id="campaign-table">
      {children}
      <StyledTableContainer
        fullHeight={fullHeight}
        maxContentHeight={maxContentHeight}
      >
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>{Header}</TableRow>
          </StyledTableHead>
          <TableBody>
            {data.map((rowData, index) => {
              return (
                <TableRow key={index}>
                  {config.map(({ name, renderCell, propKey }, index) => {
                    const key = propKey ? String(propKey) : index;
                    return (
                      <TableCell key={index} className={classes.cell}>
                        {renderCell ? (
                          renderCell(rowData, key)
                        ) : (
                          <DefaultCell
                            data={propKey ? rowData[propKey] : Math.random()}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Paper>
  );
};
