import React, {
  useEffect,
  useState,
  FC,
  ReactElement,
  Fragment,
  useCallback,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import GraphContainer from '../graphContainer/GraphContainer';
import { BasicWidgetVariant } from '_Components/StatisticWidget/StatisticsWidget';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import HeadingTable from './HeadingTable';
import { TableHeadComponent } from './TableHeadComponent';
import { AverageTrend } from '_State/Spectrum/types';
import { getSpectrumAverageEngagementsTrend } from '_State/Spectrum/Api/spectrum.api';
import { IFilters } from '../../utils';
import { serilizeAveragesTrendKeys } from '../../helpers';

export const Arrow = (): ReactElement => (
  <path d="M264.6 70.63l176 168c4.75 4.531 7.438 10.81 7.438 17.38s-2.688 12.84-7.438 17.38l-176 168c-9.594 9.125-24.78 8.781-33.94-.8125c-9.156-9.5-8.812-24.75 .8125-33.94l132.7-126.6H24.01c-13.25 0-24.01-10.76-24.01-24.01s10.76-23.99 24.01-23.99h340.1l-132.7-126.6C221.8 96.23 221.5 80.98 230.6 71.45C239.8 61.85 254.1 61.51 264.6 70.63z" />
);

const useStyles = makeStyles({
  table: {
    fontWeight: 600,
  },
  cell: {
    fontWeight: 600,
    padding: '13px 5px',
  },
  headRow: { backgroundColor: '#F6F6F6' },
});

const ArrowContainer = styled.div`
  align-self: flex-start;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  &.Increase {
    color: #9d05b0;
  }
  &.Decrease {
    color: #ea9000;
  }
  svg {
    fill: white;
    padding: 5px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: 12px;
    &.Increase {
      transform: rotate(-45deg);
      background-color: #9d05b0;
    }
    &.Decrease {
      transform: rotate(45deg);
      background-color: #ea9000;
    }
    &.NoChange {
      background-color: #5f5f61;
    }
  }
`;

export enum DisplayModeEnum {
  single = 1,
  dual = 2,
  triple = 3,
}

type IProps = {
  filters?: IFilters;
  userId?: number;
};

export const AvgEngagementRateAndTrendGraph: FC<IProps> = ({
  filters,
  userId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [mode, setMode] = useState<DisplayModeEnum>(DisplayModeEnum.single);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [averageKeys, setAverageKeys] = useState<string[]>([]);
  const [averages, setAverages] = useState<AverageTrend[][]>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, Object.keys(averageKeys).length - page * rowsPerPage);

  const trendVariant = (val: number): BasicWidgetVariant => {
    if (val > 0) return BasicWidgetVariant.Increase;
    if (val < 0) return BasicWidgetVariant.Decrease;
    return BasicWidgetVariant.NoChange;
  };
  const unitHandler = (value: number, trend?: boolean): string =>
    value > 0
      ? `${trend ? '+' : ''}${value.toFixed(3)}%`
      : `${value.toFixed(2)}%`;

  const getData = useCallback(
    async (params?: Record<string, any>): Promise<void> => {
      if (!userId) return;
      setMode(DisplayModeEnum.single);

      const trends = await getSpectrumAverageEngagementsTrend(userId, params);

      const keys = serilizeAveragesTrendKeys([trends]);
      setAverageKeys(keys);

      setAverages([trends]);
    },
    [userId, setMode, setAverages, setAverageKeys]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userId) return;

      const selectedIds = filters?.campaignIds
        .filter((value) => value.isChecked)
        .map((selected) => selected.id);

      if (!selectedIds || selectedIds?.length === 0)
        return getData({
          startDate: filters?.date.selection?.startDate,
          endDate: filters?.date.selection?.endDate,
        });

      setSelectedIds(selectedIds);
      try {
        const averages = await Promise.all(
          selectedIds.map(async (id) => {
            const result = await getSpectrumAverageEngagementsTrend(userId, {
              campaignId: id,
              startDate: filters?.date.selection?.startDate,
              endDate: filters?.date.selection?.endDate,
            });

            return result.map((el) => ({ ...el, id }));
          })
        );

        const keys = serilizeAveragesTrendKeys(averages);
        setAverageKeys(keys);

        setAverages(averages);

        setMode(selectedIds?.length || 1);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [filters, getData, userId]);

  const showingKeys: string[] = averageKeys.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <GraphContainer
      title={t('spectrum.overview.Average engagement rate & trend')}
      widgetDescription={t('spectrum.overview.Widget description')}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <HeadingTable selectedIds={selectedIds} mode={mode} />
          <Table className={classes.table} aria-label="simple table">
            <TableHeadComponent mode={mode} />
            <TableBody>
              {showingKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    {key}
                  </TableCell>
                  {averages.map((value, index) => {
                    const item = value.find(
                      ({ channelType }) => channelType === key
                    );

                    if (!item)
                      return (
                        <Fragment key={index.toString()}>
                          <TableCell
                            className={classes.cell}
                            align="right"
                          ></TableCell>
                          <TableCell
                            className={classes.cell}
                            align="right"
                          ></TableCell>
                        </Fragment>
                      );

                    return (
                      <Fragment key={index.toString()}>
                        <TableCell className={classes.cell} align="right">
                          {unitHandler(item.engagement)}
                        </TableCell>
                        <TableCell className={classes.cell} align="right">
                          <ArrowContainer className={trendVariant(item.trend)}>
                            {unitHandler(item.trend, true)}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              className={trendVariant(item.trend)}
                            >
                              <Arrow />
                            </svg>
                          </ArrowContainer>
                        </TableCell>
                      </Fragment>
                    );
                  })}
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            height: 60,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={averageKeys.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {/* <div style={{ marginRight: '24px' }}>
            <SpectrumButton text={t('spectrum.overview.seeDetails')} />
          </div> */}
        </div>
      </div>
    </GraphContainer>
  );
};

export default AvgEngagementRateAndTrendGraph;
