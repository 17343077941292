import React, { FC } from 'react';
import { Divider } from '@material-ui/core';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { Campaigns } from '_Routes/Home/NavigationPanel/Campaigns/Campaigns';
import { MenuIconButtonX } from '_Routes/Home/NavigationPanel/MenuIconButton/MenuIconButtonX';
import { DashboardIconX } from '_Routes/Home/NavigationPanel/Icons/DashboardIconX';
import { Footer } from '_Routes/Home/NavigationPanel/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { UserType } from '_State/User/user.types';
import { OpportunitiesIconX } from '_Routes/Home/NavigationPanel/Icons/OportunitiesIconX';
import { getDistributionPartnerLink } from '_Routes/Home/_routes/DistributionPartner/utils';
import { StyledMenuIcon } from './Icons/StyledIcon';
import { isAgencyTypeRole, isDistributionPartnerRole } from '../Home';

const MenuDivider = styled(Divider)`
  && {
    background-color: #ddd;
    height: 0.5px;
    margin-bottom: 1rem;
  }
`;

const StyledMenuIconButton = styled(MenuIconButtonX)`
  && {
    margin-top: 1rem;
  }
`;

const Buttons = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ADMIN_TYPE_ROLE: string[] = [UserType.ADMIN, UserType.EDITOR];

export const isAdminTypeRole = (state: IState): boolean => {
  const userType = state.user.user?.userType;
  return ADMIN_TYPE_ROLE.includes(userType || '');
};

interface IProps {
  isExpanded?: boolean;
  onExpandClick?: () => void;
}

export const NavigationLinks: FC<IProps> = ({
  isExpanded = false,
  onExpandClick,
}) => {
  const isAdmin = useSelector(isAdminTypeRole);
  const isDistributionPartner = useSelector(isDistributionPartnerRole);
  const isAgency = useSelector(isAgencyTypeRole);
  const location = useLocation();
  const { t } = useTranslation();
  const isActive = (path: string): boolean => location.pathname === path;
  const history = useHistory();
  const state = useSelector((state: IState) => state.distributionPartner);

  const isMyCampaignsActive = isActive(
    isAgency
      ? '/home/agency/spectrum/campaigns'
      : '/home/client/spectrum/campaigns'
  )
    ? true
    : isActive(
        isAgency
          ? '/home/agency/spectrum-campaign/payment'
          : '/home/client/spectrum-campaign/payment'
      )
    ? true
    : isActive(
        isAgency
          ? '/home/agency/spectrum-campaign/program'
          : '/home/client/spectrum-campaign/program'
      )
    ? true
    : isActive(
        isAgency
          ? '/home/agency/spectrum-campaign/campaign'
          : '/home/client/spectrum-campaign/campaign'
      )
    ? true
    : isActive(
        isAgency
          ? '/home/agency/spectrum-campaign/scheduling'
          : '/home/client/spectrum-campaign/scheduling'
      )
    ? true
    : false;

  return (
    <>
      <MenuDivider
        style={{ display: !isAdmin && !isDistributionPartner ? 'none' : '' }}
      />
      {!isAdmin && !isDistributionPartner && (
        <>
          <MenuIconButtonX
            isFocused={isActive(
              isAgency ? '/home/agency/dashboard' : '/home/client/dashboard'
            )}
            isExtended={isExpanded}
            label={t('navBar.dashboard')}
            linkPath={
              isAgency ? '/home/agency/dashboard' : '/home/client/dashboard'
            }
            testId={'dashboard-button'}
            client
          >
            <StyledMenuIcon />
          </MenuIconButtonX>
          <MenuIconButtonX
            isFocused={isMyCampaignsActive}
            isExtended={isExpanded}
            label={t('navBar.myCampaigns')}
            linkPath={
              isAgency
                ? '/home/agency/spectrum/campaigns'
                : '/home/client/spectrum/campaigns'
            }
            testId={'dashboard-button'}
            client
          >
            <StyledMenuIcon />
          </MenuIconButtonX>
          <MenuIconButtonX
            isFocused={isActive(
              isAgency
                ? '/home/agency/spectrum/overview'
                : '/home/client/spectrum/overview'
            )}
            isExtended={isExpanded}
            label={t('navBar.analytics')}
            linkPath={
              isAgency
                ? '/home/agency/spectrum/overview'
                : '/home/client/spectrum/overview'
            }
            testId={'dashboard-button'}
            client
          >
            <StyledMenuIcon />
          </MenuIconButtonX>
          {isAgency && (
            <MenuIconButtonX
              isFocused={isActive('/home/agency/spectrum/clients')}
              isExtended={isExpanded}
              label={t('navBar.clients')}
              linkPath={'/home/agency/spectrum/clients'}
              testId={'dashboard-button'}
              client
            >
              <StyledMenuIcon />
            </MenuIconButtonX>
          )}
        </>
      )}
      {isDistributionPartner && (
        <>
          <StyledMenuIconButton
            testId={'dashboard-button'}
            isFocused={isActive('/home/finfluencer/dashboard')}
            isExtended={isExpanded}
            label={t('navBar.dashboard')}
            linkPath={'/home/finfluencer/dashboard'}
            afterRedirect={onExpandClick}
            onClick={(): void => {
              getDistributionPartnerLink().then((path) => {
                history.push(path);
              });
            }}
          >
            <DashboardIconX />
          </StyledMenuIconButton>

          <StyledMenuIconButton
            notificationQuantity={state.numberOfNewOpportunities}
            isFocused={isActive('/home/finfluencer/opportunities')}
            isExtended={isExpanded}
            label={t('navBar.opportunities')}
            linkPath={'/home/finfluencer/opportunities'}
            afterRedirect={onExpandClick}
            testId={'opportunities-button'}
          >
            <OpportunitiesIconX />
          </StyledMenuIconButton>
        </>
      )}
      <Buttons>
        <Campaigns
          isDistributionPartner={isDistributionPartner}
          isAdmin={isAdmin}
          isAgency={isAgency}
          isExtended={isExpanded}
          afterRedirect={onExpandClick}
        />

        <Footer
          isAdmin={isAdmin}
          isDistributionPartner={isDistributionPartner}
          isAgency={isAgency}
          afterRedirect={onExpandClick}
          isExtended={isExpanded}
        />
      </Buttons>
    </>
  );
};
