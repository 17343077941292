import React, { FocusEvent, FC, useEffect, useState } from 'react';
import {
  FormSection,
  PlxFormSection,
} from '_Components/FormSection/FormSection';
import {
  AutocompleteStyledDivider,
  OptionLabel,
  OptionWrapper,
  PlxAutocomplete,
} from '_Components/PlxAutoComplete/PlxAutoComplete';
import { ITag } from '_Types/campaigns';
import { ISectionProps } from '_Routes/Home/_Components/Sections/types';
import { IconContainer, Search } from './styles';
import { SearchIcon } from '_Routes/Home/NavigationPanel/Icons/SearchIcon';

export interface IProps extends ISectionProps {
  tags: ITag[];
  newDesign?: boolean;
  tagValueId?: any;
  placeholder: string;
  horizontal?: boolean;
  onTagChange: (field: string, value: any, shouldValidate?: boolean) => void;
  onChoose?: (options: string[]) => void;
}

export const TagSection: FC<IProps> = ({
  value,
  setValue,
  newDesign,
  onChoose,
  fieldName,
  tags,
  tooltip,
  title,
  placeholder,
  required,
  onTagChange,
}) => {
  const [tagValue, setTagValue] = useState<ITag | null>(null);

  useEffect(() => {
    if (tags) {
      setTagValue(tags.filter((g) => g.id === value)[0] || null);
    }
  }, [value, tags]);

  const handleTagChange = (value: any) => {
    setTagValue(value);
    setValue(fieldName, value?.id);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    onTagChange(fieldName, event.target.value);
  };
  return newDesign ? (
    <PlxFormSection title={title} tooltip={tooltip} required={required}>
      <Search>
        <IconContainer>
          <SearchIcon />
        </IconContainer>
        <PlxAutocomplete
          newDesign={newDesign}
          value={tagValue}
          onChange={handleTagChange}
          placeholder={placeholder}
          options={tags}
          getOptionLabel={(o) => o.name}
          onBlur={handleInputBlur}
          renderOption={(o) => (
            <OptionWrapper>
              <OptionLabel>{o.name}</OptionLabel>
              <AutocompleteStyledDivider />
            </OptionWrapper>
          )}
          freeSolo={true}
        />
      </Search>
    </PlxFormSection>
  ) : (
    <FormSection title={title} tooltip={tooltip} required={required}>
      <PlxAutocomplete
        value={tagValue}
        onChange={handleTagChange}
        placeholder={placeholder}
        options={tags}
        getOptionLabel={(o) => o.name}
        onBlur={handleInputBlur}
        renderOption={(o) => (
          <OptionWrapper>
            <OptionLabel>{o.name}</OptionLabel>
            <AutocompleteStyledDivider />
          </OptionWrapper>
        )}
        freeSolo={true}
      />
    </FormSection>
  );
};
