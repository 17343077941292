import { includes } from 'lodash';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from '_Components/ToggleSwitch/ToggleSwitch';

import { IFilterItem } from '../../utls';
import {
  StyledCheckbox,
  StyledLabel,
  StyledSubtitle,
  Wrapper,
} from './CheckboxList.styles';

interface IProps {
  items: IFilterItem[];
  changeFilters: (items: IFilterItem[]) => void;
  searchTerm: string;
  allowSelectAll?: boolean;
}

export const CheckboxList: FC<IProps> = ({
  items,
  searchTerm,
  changeFilters,
  allowSelectAll = false,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const { t } = useTranslation();

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newList = items;
    const ind = items.findIndex(
      (obj: IFilterItem) => obj.id === e.target.value
    );
    newList[ind].isChecked = !items[ind].isChecked;
    changeFilters([...newList]);
  };

  const onToggle = (): void => {
    changeFilters(
      items.map((obj: IFilterItem) => {
        return { ...obj, isChecked: !showAll };
      })
    );
    setShowAll((el: boolean) => !el);
  };

  useEffect(() => {
    const allSelected = items.every((obj) => obj.isChecked);
    if (allSelected && items.length) setShowAll(true);
    else setShowAll(false);
  }, [items]);

  return (
    <Wrapper>
      {allowSelectAll && (
        <ToggleSwitch
          title={t('analytics.allTags')}
          toggled={showAll}
          onChange={onToggle}
        />
      )}

      {items
        .filter((it: IFilterItem) =>
          includes(it.title.toLowerCase(), searchTerm.toLowerCase())
        )
        .map(({ title, isChecked, id, subtitle }: IFilterItem) => {
          return (
            <StyledLabel
              key={id}
              control={
                <StyledCheckbox
                  value={id}
                  checked={isChecked}
                  onChange={onChange}
                />
              }
              label={
                <>
                  <p>{title}</p>{' '}
                  {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
                </>
              }
            />
          );
        })}
    </Wrapper>
  );
};
