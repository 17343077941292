import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Route } from 'react-router-dom';
import { DistributionPartnerProfile } from './_Routes/DistributionPartnerProfile/DistributionPartnerProfile';
import { FinfluencerContactUs } from './_Routes/FinfluencerContactUs/FinfluencerContactUs';
import { DistributionPartnerDocuments } from './_Routes/DistributionPartnerDocuments/DistributionPartnerDocuments';
import { DistributionPartnerDashboard } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/DistributionPartnerDashboard';
import { DistributionPartnerChannel } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/DistributionPartnerChannel';
import { DistributionPartnerOpportunities } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/DistributionPartnerOpportunities';
import { FinfluencerWallet } from './_Routes/FinfluencerWallet/FinfluencerWallet';
import { FinfluencerWalletAddPaymentMethods } from './_Routes/FinfluencerWallet/FinfluencerWalletAddPaymentMethods';
import { getWallets } from '_State/Spectrum/Api/spectrum.api';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { PlxSimpleDialog } from '_Components/Dialogs/PlxSimpleDialog';
import { getOpportunities } from '_State/DistributionPartner/Api/distributionPartner.api';
import { useTranslation } from 'react-i18next';
import { DistributionPartnerReferrals } from './_Routes/FinfluencerDashboard/DistributionPartnerReferrals';

const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  overflow: scroll;
  padding: 4rem;
`;
export const Finfluencer = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!sessionStorage.getItem('payment_method_req')) {
      getWallets().then((wallet) => {
        wallet.length === 0 &&
          getOpportunities().then((opportunity) =>
            opportunity.find((r) => {
              if (r.status === 'COMPLETED') {
                setOpenDialog(true);
                sessionStorage.setItem(
                  'payment_method_req',
                  JSON.stringify(false)
                );
              }
              return false;
            })
          );
      });
    }
  }, []);
  const handleClose = () => setOpenDialog(false);
  return (
    <Body>
      <Route exact path="/home/finfluencer/dashboard">
        <DistributionPartnerDashboard />
      </Route>
      <Route exact path="/home/finfluencer/opportunities">
        <DistributionPartnerOpportunities />
      </Route>
      <Route exact path="/home/finfluencer/wallet">
        <FinfluencerWallet />
      </Route>
      <Route exact path="/home/finfluencer/referrals">
        <DistributionPartnerReferrals />
      </Route>
      <Route exact path="/home/finfluencer/wallet/add-payment-method">
        <FinfluencerWalletAddPaymentMethods />
      </Route>
      <Route exact path="/home/finfluencer/contact">
        <FinfluencerContactUs />
      </Route>
      <Route path="/home/finfluencer/profile">
        <DistributionPartnerProfile />
      </Route>
      <Route exact path="/home/finfluencer/documents">
        <DistributionPartnerDocuments />
      </Route>
      <Route path="/home/finfluencer/channel">
        <DistributionPartnerChannel />
      </Route>
      <PlxSimpleDialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{t('wallet.walletRequired')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('wallet.walletRequiredBody')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </PlxSimpleDialog>
    </Body>
  );
};
