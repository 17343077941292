import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxInfoPanel } from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import { StepConnector, StepIconProps, withStyles } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { IThemed } from '_Styles/types';
import { IPaymentHistory } from '_Types/campaigns';
import { getPaymentHistory } from '_State/User/Api/user.api';
import { capitalize } from '_App/utils';
import { EmptyDataPlaceHolder } from '_Components/EmptyDataPlaceHolder';

const Connector = withStyles({
  vertical: {
    padding: '8px 0',
    marginLeft: '5.4px',
  },
  line: {
    height: '44px',
  },
})(StepConnector);

const StepperIcon = styled.div<StepIconProps>`
  cursor: pointer;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: solid 1px #afb6bd;
  background-color: #fff;
`;
const Type = styled.div`
  color: #3c6ef6;
  margin: 0 0.3rem 0 2rem;
`;

const Amount = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const NoDataWrapper = styled.div`
  height: 30rem;
`;

const StepContent = withStyles({
  label: {
    color: '#AFB6BD',
    display: 'flex',
    flexDirection: 'row',
  },
  active: {
    color: '#AFB6BD !important',
  },
})(StepLabel);

const StyledStepper = withStyles({
  root: {
    padding: '4rem',
  },
})(Stepper);

const ProgramHistoryBody = styled.div``;

export const ProgramHistory: FC = () => {
  const { t } = useTranslation();
  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistory[]>([]);

  useEffect(() => {
    getPaymentHistory().then((payment) =>
      setPaymentHistory(
        payment.filter((item) => item.paymentStatus === 'SUCCESS')
      )
    );
  }, []);

  return (
    <ProgramHistoryBody>
      <PlxInfoPanel label={t('profile.programHistory')}>
        {paymentHistory.length > 0 ? (
          <StyledStepper connector={<Connector />} orientation="vertical">
            {paymentHistory.map((payment, index) => (
              <Step key={index}>
                <StepContent StepIconComponent={StepperIcon}>
                  {payment.paymentDate}
                  <Type>{capitalize(payment.campaignType)}</Type>
                  <Amount>· {payment.amount}$</Amount>
                </StepContent>
              </Step>
            ))}
          </StyledStepper>
        ) : (
          <NoDataWrapper>
            <EmptyDataPlaceHolder massage={t('noData')} />
          </NoDataWrapper>
        )}
      </PlxInfoPanel>
    </ProgramHistoryBody>
  );
};
