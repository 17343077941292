import { makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';

export const useStyles = makeStyles({
  input: {
    border: '1px solid #5f5f61',
    borderRadius: 24,
    fontFamily: 'Quicksand, sans-serif',
    backgroundColor: 'white',
    height: 32,
    width: '100%',
    padding: '0.625rem 1rem',
    alignItems: 'center',
    paddingRight: '40px',
  },
});

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
`;
