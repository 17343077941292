import React, { FC } from 'react';
import { IDailyData } from './EngagementBenchmarkGraphNo2';
import { IVisibility, averageTooltip } from './data';
import styled from '@emotion/styled';

interface ICustomizedTooltip {
  getDailyData: (arg: string) => IDailyData | undefined;
  dataColors: { name: string; data: { name: string; color: string }[] }[];
  visibility: IVisibility;
  label?: string;
  selectedCampaigns: string[];
}

export const StyledTooltip = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: column;
`;
export const TooltipElement = styled.div`
  padding: 5px;
`;

export const CustomizedTooltip: FC<ICustomizedTooltip> = ({
  label,
  getDailyData,
  dataColors,
  visibility,
  selectedCampaigns,
}) => {
  if (!label) return null;
  const dailyData: IDailyData | undefined = getDailyData(label);

  if (!dailyData) return null;

  return (
    <>
      <StyledTooltip>
        {Object.keys(dailyData as IDailyData)
          .filter((el: string) => el !== 'name')
          .map((el, index) => {
            return (
              <div key={`${index}`} style={{ padding: '4px 0' }}>
                {Object.keys((dailyData as any)[el]).map((item, id) => {
                  return (
                    (visibility as any)[el][item] && (
                      <TooltipElement key={`${index}-${id}`}>
                        {
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                marginRight: '5px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: dataColors
                                      .find((col) => col.name === el)
                                      ?.data.find((el) => el.name === item)
                                      ?.color,
                                    borderRadius: '2px',
                                    display: 'inline-block',
                                    marginRight: '5px',
                                  }}
                                />
                                {!selectedCampaigns.length
                                  ? (averageTooltip as any)[item]
                                  : el
                                      .replace('campaign-', 'Campaign #')
                                      .slice(0, -1)
                                      .concat(
                                        selectedCampaigns.length
                                          ? selectedCampaigns[index]
                                          : ''
                                      ) +
                                    `(${
                                      item === 'socialmediaEngagement'
                                        ? 'Benchmark'
                                        : 'Engagement'
                                    }): `}
                              </div>
                              <div
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: '0',
                                  paddingLeft: '4px',
                                  display: 'inline-block',
                                }}
                              >
                                {(dailyData as any)[el][item]}%
                              </div>
                            </div>
                          </div>
                        }
                      </TooltipElement>
                    )
                  );
                })}
              </div>
            );
          })}
      </StyledTooltip>
    </>
  );
};
