import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { ReactComponent as ConfirmationIcon } from '_Styles/images/_Icons/Confirmation.svg';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Label = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  color: #1b1b1b;
`;

interface IProps {
  onNext?: () => void;
}

export const ApproveChannelConfirmation: FC<IProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onNext && onNext();
  };
  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        content={
          <Content>
            <ConfirmationIcon />
            <Label>{t('channels.approveChannel.label')}</Label>
            <PlxButton
              onClick={handleClick}
              label={t('channels.approveChannel.button')}
              testId="close-confirmation-button"
            />
          </Content>
        }
      />
    </Wrapper>
  );
};
