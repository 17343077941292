import React, { FC, useState } from 'react';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { rejectChannel } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 4.1rem;
  text-align: center;
  color: #1b1b1b;
`;

const StyledInputSection = styled(InputSection)`
  width: 100%;
`;

interface IProps {
  handleClose: () => void;
  channelId?: number;
}

export const RejectChannelDialog: FC<IProps> = ({ handleClose, channelId }) => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [rejectMessage, setRejectMessage] = useState('');

  const onClose = () => {
    setIsProcessing(true);
    rejectChannel(rejectMessage, channelId).then(() => {
      setIsProcessing(false);
      handleClose();
    });
  };

  const onRejectChannelReasonChange = (event: any) => {
    setRejectMessage(event.target.value);
  };

  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        content={
          <Content>
            <Label>{t('channels.rejectChannel.title')}</Label>
            <StyledInputSection
              fieldName={'rejectChannel'}
              title={t('channels.rejectChannel.label')}
              placeholder={t('channels.rejectChannel.placeholder')}
              multiline
              required
              rows={5}
              rowsMax={5}
              value={rejectMessage}
              handleChange={onRejectChannelReasonChange}
            />
            <PlxButton
              isDisabled={isProcessing || !rejectMessage}
              isProcessing={isProcessing}
              label={t('channels.rejectChannel.button')}
              onClick={onClose}
            />
          </Content>
        }
      />
    </Wrapper>
  );
};
