import styled from '@emotion/styled';
import { Chip, ChipProps } from '@material-ui/core';

export const StyledItemList = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const StyledItem = styled(Chip)<ChipProps & { isChecked: boolean }>`
  &.MuiChip-root {
    margin-bottom: 0rem;
    margin-right: 0.5rem;
    padding: 4px 8px;
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 14px;
    border-color: ${({ isChecked }): string =>
      isChecked ? 'transparent' : '#008B9C'};
    color: ${({ isChecked }): string => (isChecked ? '#008B9C' : '#231F20')};
    background-color: ${({ isChecked }): string =>
      isChecked ? '#FFEDFF' : '#fff'};
  }
`;
