import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Checkbox,
  createStyles,
  InputBase,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from '@material-ui/core';
import { ISelectOption } from '_Components/PlxSelect/types';
import { channelTypeOptions } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/configs';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import {
  BoldDomain,
  StyledArrowIcon,
} from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/Styles';

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      height: '3.3rem ',
      minWidth: '20rem',
      width: '250px',
      borderRadius: '0.6rem',
    },
    input: {
      borderRadius: '0.6rem',
      padding: '9px 40px 9px 25px',
      fontWeight: 400,
      fontFamily: 'Quicksand',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
    },
  })
)(InputBase);

const StyledSelect = withStyles({
  select: {
    '&:focus': {
      borderRadius: '0.6rem',
      backgroundColor: 'white',
    },
  },
  root: {
    display: 'flex',
    borderRadius: '0.6rem',
    border: '1px solid #D5DDE3',
  },
})(Select);

const useMenuStyles = makeStyles(() =>
  createStyles({
    dropdownStyle: {
      border: '1px solid #D5DDE3',
      borderRadius: '0px 0px 3px 3px',
      boxShadow: 'none',
      padding: '1rem',
    },
  })
);

interface IProps extends ICommonProps {
  initialValue?: unknown;
  domain?: boolean;
  isNewCampaign?: boolean;
  rebrandlyUrlEnabled?: boolean;
  filter?: boolean;
  options?: ISelectOption[];
  channelTypeList: string[];
  onChange?: (value: string) => void;
  onChannelTypeChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PlxTableHeaderSelect: FC<IProps> = ({
  initialValue,
  domain,
  isNewCampaign,
  filter,
  rebrandlyUrlEnabled,
  options,
  channelTypeList,
  onChange,
  onChannelTypeChange,
  className,
}) => {
  const [selectValue, setSelectValue] = useState<unknown>('');
  const useStyles = useMenuStyles();
  useEffect(() => {
    setSelectValue(initialValue);
  }, [initialValue]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setSelectValue(value);
    onChange && onChange(value as string);
  };

  return (
    <StyledSelectWrapper>
      {domain && (
        <StyledSelect
          style={
            !isNewCampaign
              ? { cursor: 'default', pointerEvents: 'none', opacity: '0.6' }
              : {}
          }
          className={className}
          input={<SelectInput />}
          IconComponent={StyledArrowIcon}
          value={selectValue}
          onChange={handleChange}
          MenuProps={{ classes: { paper: useStyles.dropdownStyle } }}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <BoldDomain>{option.value}</BoldDomain>
            </MenuItem>
          ))}
        </StyledSelect>
      )}
      {filter && (
        <>
          <StyledSelect
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={channelTypeList}
            IconComponent={StyledArrowIcon}
            onChange={onChannelTypeChange}
            input={<SelectInput />}
            displayEmpty
            renderValue={(selected: unknown): string | JSX.Element =>
              (selected as string[]).length > 0 ? (
                <BoldDomain>{(selected as string[]).join(', ')}</BoldDomain>
              ) : (
                <p>
                  <BoldDomain>Channel Type</BoldDomain>
                </p>
              )
            }
            MenuProps={MenuProps}
          >
            {channelTypeOptions.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <Checkbox checked={channelTypeList.indexOf(id) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </StyledSelect>
        </>
      )}
    </StyledSelectWrapper>
  );
};
