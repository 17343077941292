import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { prepareRequiredFieldsValidation } from '_Components/Forms/utils';
import { prepareRequiredFormValidation } from '_Components/Forms/utils';
import {
  createThriveTag,
  getThriveBudgets,
  getThriveDraft,
  getThriveGeolocationsStates,
  getThriveTags,
  saveThriveProgramDraft,
  updateThriveProgramDraft,
} from '_State/Thrive/Api/thrive.api';
import { ITag } from '_Types/campaigns';
import {
  ageGroupOptions,
  audienceOptions,
  geoConfig,
  sliderInfoConfig,
} from '_Routes/Home/_routes/Thrive/config';
import {
  IRegion,
  IThriveBudget,
  IThriveCampaign,
  IThriveProgramDraft,
} from '_State/Thrive/types';
import { StateSection } from '_Routes/Home/_Components/Sections/StateSection';
import { useHistory } from 'react-router-dom';
import {
  ISliderConfig,
  ReachSection,
} from '_Routes/Home/_Components/Sections/ReachSection/ReachSection';
import { StockSection } from '_Routes/Home/_Components/Sections/StockSection/StockSection';
import { TagSection } from '_Routes/Home/_Components/Sections/TagSection';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { industryConfig } from '_Routes/Home/_routes/Thrive/config';
import { mapTranslatedLabel } from '_App/utils';
import { MultiSelectSection } from '_Routes/Home/_Components/Sections/MultiSelectSection';
import { ButtonGroupSection } from '_Routes/Home/_Components/Sections/ButtonGroupSection';
import { getThriveLink } from '_Routes/Home/_routes/Spectrum/utils';
import { CircularProgress } from '@material-ui/core';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55px;
  margin-top: 10rem;
  margin-bottom: 4rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  justify-self: center;
  align-self: center;
  margin-top: 220px;
`;

const INITIAL_VALUES: IThriveProgramDraft = {
  industry: [],
  geolocation: undefined,
  geolocationStates: undefined,
  audiences: undefined,
  ageGroups: undefined,
  budget: '_150',
  tag: undefined,
  stockTickerList: undefined,
};

const REQUIRED_FIELDS = ['industry', 'audiences', 'budget'];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

export const ThriveProgram: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [draft, setDraft] = useState<IThriveCampaign>();
  const [tags, setTags] = useState<ITag[]>([]);
  const [budgets, setBudgets] = useState<IThriveBudget[]>();
  const [reachConfig, setReachConfig] = useState<ISliderConfig[]>([]);
  const [budget, setBudget] = useState<IThriveBudget>();
  const [reachValue, setReachValue] = useState(0);
  const [regionsConfig, setRegionsConfig] = useState<IRegion[]>([]);
  const [regionsValue, setRegionsValue] = useState<IRegion[]>([]);
  const [loading, setLoading] = useState(true);
  const [stateLabel, setStateLabel] = useState<string>('');
  const { isAgencyType } = useContext(NavigationContext);
  const [apiErrorMessage, setApiErrorMessage] = useState(undefined);
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  useEffect(() => {
    if (budgets) {
      if (budget) {
        setReachValue(
          budgets?.indexOf(
            budgets.filter((item) => item.name === budget.name)[0]
          )
        );
      } else {
        setBudget(budgets[0]);
      }
    }
  }, [budgets, budget]);

  useEffect(() => {
    getThriveDraft().then(
      (v) => {
        if (v) {
          if (v.payment) {
            if (
              v.payment.paymentStatus === 'IN_PROGRESS' ||
              v.payment.paymentStatus === 'FAILED'
            ) {
              if (v.payment.paymentMethod === 'WIRE_TRANSFER') {
                history.push('/home/thrive-campaign/payment/wire');
              } else if (v.payment.paymentMethod === 'CHECK') {
                history.push('/home/thrive-campaign/payment/check');
              } else {
                getThriveGeolocationsStates(v.geolocation?.id).then(
                  (states) => {
                    fillForm(v);
                    setDraft(v);
                    setBudget(v.budget);
                    setRegionsValue(v.geolocationStates);
                    setStateLabel(getStateLabel(v.geolocation?.id));
                    setRegionsConfig(states);
                    setLoading(false);
                  }
                );
              }
            } else if (v.payment.paymentStatus === 'SUCCESS') {
              history.push(
                isAgencyType
                  ? '/home/agency/spectrum-campaign/campaign'
                  : '/home/client/spectrum-campaign/campaign'
              );
            }
          } else {
            getThriveGeolocationsStates(v.geolocation?.id).then((states) => {
              fillForm(v);
              setDraft(v);
              setBudget(v.budget);
              setRegionsValue(v.geolocationStates);
              setStateLabel(getStateLabel(v.geolocation?.id));
              setRegionsConfig(states);
              setLoading(false);
            });
          }
        } else {
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
        return null;
      }
    );
    getThriveBudgets().then((budgets) => {
      setBudgets(budgets);
      setReachConfig(
        budgets.map((budget) => {
          return { value: budget.programPrice };
        })
      );
    });
    getThriveTags().then((v) => setTags(v));
    // eslint-disable-next-line
  }, []);

  const saveDraft = (values: any) => {
    if (draft) {
      return updateThriveProgramDraft(values).then();
    } else {
      return saveThriveProgramDraft(values).then();
    }
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        saveDraft(form)
          .then(() => {
            history.push('/home/thrive-campaign/campaign');
          })
          .catch((error) =>
            error.response
              .json()
              .then((error: any) => setApiErrorMessage(error.message))
          );
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.audiences && form.audiences.length < 1) {
        errors.audiences = t('errors.reqField');
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (draft: IThriveCampaign) => {
    formik.setValues({
      industry: draft.industry,
      geolocation: draft.geolocation?.id,
      geolocationStates: draft.geolocationStates.map((state) => state.id),
      audiences: draft.audiences,
      ageGroups: draft.ageGroups,
      budget: draft.budget?.name,
      tag: draft.tag?.id,
      stockTickerList: draft.stockTickerList,
    });
  };

  const handleReachChange = (value: number) => {
    const reachValue = budgets?.filter(
      (budget) => budget.programPrice === budgets[value].programPrice
    )[0];
    if (reachValue?.name !== formik.values.budget) {
      setReachValue(value);
      setBudget(reachValue);
      formik.setFieldValue('budget', reachValue?.name);
    }
  };

  const onTagChange = (fieldName: string, tagName: string) => {
    const tag = tags.find(({ name }) => name === tagName);
    if (tag) {
      formik.setFieldValue(fieldName, tag.id);
    } else {
      // NOTE: here we have race condition
      createThriveTag(tagName).then(({ id }) =>
        formik.setFieldValue(fieldName, id)
      );
    }
  };

  const handleLocationChange = (options: string[]) => {
    const id = +options[0];
    if (id) {
      getThriveGeolocationsStates(id).then((states) => {
        setRegionsConfig(states);
        setRegionsValue([]);
      });
    } else {
      setRegionsConfig([]);
      setRegionsValue([]);
      formik.setFieldValue('geolocationStates', []);
    }
    formik.setFieldValue('geolocation', options[0]);
    setStateLabel(getStateLabel(id));
  };

  const industryInitialValue = useMemo(
    () => (draft?.industry ? draft?.industry : []),
    [draft]
  );

  const geolocationInitialValue = useMemo(
    () => (draft?.geolocation ? [draft?.geolocation.id.toString()] : []),
    [draft]
  );

  const getStateLabel = (geolocationId: number) => {
    if (geolocationId === 1) {
      return t('thrive.program.state');
    } else if (geolocationId === 2) {
      return t('thrive.program.province');
    } else {
      return t('thrive.program.region');
    }
  };
  const handleDeleteStock = () => {
    const stockTickers = formik.values.stockTickerList;
    formik.setFieldValue(
      'stockTickerList',
      stockTickers?.slice(0, stockTickers?.length - 1)
    );
  };

  const industryOptions = industryConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );
  return (
    <>
      {loading ? (
        <StyledCircularProgress color="secondary" />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <ButtonGroupSection
            required
            buttonsConfig={industryOptions}
            title={t('thrive.program.industry')}
            placeholder={t('thrive.program.fillIndustry')}
            tooltip={t('thrive.program.industryTooltip')}
            inputTitle={t('thrive.program.specifyIndustry')}
            value={industryInitialValue}
            fieldName={'industry'}
            setValue={formik.setFieldValue}
          />
          <FieldErrorMessage message={formik.errors.industry} />
          <ButtonGroupSection
            title={t('spectrum.program.geoLocation')}
            tooltip={t('thrive.program.geoLocationTooltip')}
            multiple={false}
            buttonsConfig={geoConfig.map((item) => {
              return { ...item, label: t(item.label) };
            })}
            value={geolocationInitialValue}
            onChoose={handleLocationChange}
          />
          {regionsConfig.length > 0 && (
            <>
              <StateSection
                title={stateLabel}
                value={regionsValue}
                fieldName={'geolocationStates'}
                setValue={formik.setFieldValue}
                placeholder={t('thrive.program.statePlaceholder')}
                regionsConfig={regionsConfig}
              />
            </>
          )}
          <MultiSelectSection
            selectConfig={audienceOptions}
            required
            title={t('thrive.program.audience')}
            placeholder={t('thrive.program.audiencePlaceholder')}
            tooltip={t('thrive.program.audienceTooltip')}
            value={draft?.audiences}
            setValue={formik.setFieldValue}
            fieldName={'audiences'}
          />
          <FieldErrorMessage message={formik.errors.audiences} />
          <MultiSelectSection
            required={false}
            selectConfig={ageGroupOptions}
            title={t('thrive.program.ageGroup')}
            placeholder={t('thrive.program.ageGroupPlaceholder')}
            tooltip={t('thrive.program.ageGroupTooltip')}
            value={draft?.ageGroups}
            setValue={formik.setFieldValue}
            fieldName={'ageGroups'}
          />
          <ReachSection
            title={t('thrive.program.reach')}
            required
            value={reachValue}
            onChange={handleReachChange}
            config={reachConfig}
            sliderInfoConfig={sliderInfoConfig(t, budget?.leadsPerProgram)}
          />
          <FieldErrorMessage message={formik.errors.budget} />
          <TagSection
            title={t('thrive.program.tag')}
            placeholder={t('thrive.program.tagPlaceholder')}
            tags={tags}
            value={draft?.tag?.id}
            fieldName={'tag'}
            setValue={formik.setFieldValue}
            onTagChange={onTagChange}
          />
          <StockSection
            onDelete={handleDeleteStock}
            value={draft?.stockTickerList}
            setValue={formik.setFieldValue}
            fieldName={'stockTickerList'}
          />
          <FieldErrorMessage message={apiErrorMessage} />
          <ButtonsSection>
            <PlxButton
              onClick={() =>
                saveDraft(formik.values).then(() =>
                  getThriveLink().then((link) => {
                    if (link) {
                      history.push(link);
                    }
                  })
                )
              }
              label={t('spectrum.footerButtons.saveForLater')}
              variant={ButtonVariant.TextButton}
            />
            <PlxButton
              inputType={'submit'}
              label={t('spectrum.footerButtons.nextStep')}
            />
          </ButtonsSection>
        </form>
      )}
    </>
  );
};
