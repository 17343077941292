import React, { FC } from 'react';
import { BlurryDialog } from '_Routes/Home/_routes/Spectrum/utils';
import { FinishDialogContent } from './FinishDialogContent';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onNext?: () => void;
}

export const FinishDialog: FC<IProps> = ({ open, onClose, onNext }) => {
  const handleClose = () => {
    onClose && onClose();
  };
  const handleNext = () => {
    onNext && onNext();
  };
  return (
    <BlurryDialog onClose={handleClose} disableBackdropClick open={open}>
      <FinishDialogContent onClose={handleClose} onNext={handleNext} />
    </BlurryDialog>
  );
};
