import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { getChannels } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import {
  IAdminChannelQueryParams,
  IDistributionPartnerChannelDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { createChannelsTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { PlxMultipleSelect } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { ChannelDetails } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetails';
import { PlxLoader } from '_Components/PlxLoader';
import { Dialog } from '@material-ui/core';
import { RejectChannelDialog } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/RejectChannelDialog';
import { Close } from '@material-ui/icons';
import { ApproveChannelForm } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ApproveChannelForm';
import { ApproveChannelConfirmation } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ApproveChannelConfirmation';
import { useHistory } from 'react-router-dom';
import { ChannelStatus } from '_State/DistributionPartner/distributionPartner.enums';
import { XemTable } from '_Components/XemTable/XemTable';
import Paper from '@material-ui/core/Paper';
import {
  GridColumns,
  GridFilterModelParams,
  GridPageChangeParams,
} from '@material-ui/data-grid';

const sortingOptions = [
  {
    id: 'ASC',
    name: 'Oldest to newest',
  },
  {
    id: 'DESC',
    name: 'Newest to oldest',
  },
];

const channelTypeOptions = [
  {
    id: 'DISCORD',
    name: 'Discord',
  },
  {
    id: 'FACEBOOK',
    name: 'Facebook',
  },
  {
    id: 'FORUM',
    name: 'Forum',
  },
  {
    id: 'INSTAGRAM',
    name: 'Instagram',
  },
  {
    id: 'LINKEDIN',
    name: 'Linkedin',
  },
  {
    id: 'PODCAST',
    name: 'Podcast',
  },
  {
    id: 'PINTEREST',
    name: 'Pinterest',
  },
  {
    id: 'REDDIT',
    name: 'Reddit',
  },
  {
    id: 'SLACK',
    name: 'Slack',
  },
  {
    id: 'SNAPCHAT',
    name: 'Snapchat',
  },
  {
    id: 'X',
    name: 'X',
  },
  {
    id: 'TIKTOK',
    name: 'Tik Tok',
  },
  {
    id: 'TWITCH',
    name: 'Twitch',
  },
  {
    id: 'YOUTUBE',
    name: 'YouTube',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

const channelStatusOptions = [
  {
    id: ChannelStatus[ChannelStatus.ACTIVE],
    name: 'Active',
  },
  {
    id: ChannelStatus[ChannelStatus.REJECTED],
    name: 'Rejected',
  },
  {
    id: ChannelStatus[ChannelStatus.OFFER_PLACED],
    name: 'Offer Submitted',
  },
  {
    id: ChannelStatus[ChannelStatus.PENDING],
    name: 'Pending',
  },
  {
    id: ChannelStatus[ChannelStatus.CONNECTED],
    name: 'Connected',
  },
  {
    id: ChannelStatus[ChannelStatus.DISABLED],
    name: 'Disabled',
  },
];

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

const StyledMultipleSelect = styled(PlxMultipleSelect)`
  && {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 15rem;
  }
`;

const StyledInput = styled(PlxInput)`
  && {
    margin-left: auto;
    width: 31rem;
    margin-right: 3.5rem;
  }
`;

const INITIAL_PARAMS: IAdminChannelQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageNumber: 0,
  pageSize: 20,
};
const Body = styled.div`
  padding: 4rem;
  background-color: #f8f9fc;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIcon = styled(Close)`
  position: absolute;
  right: 1rem;
  margin: 2.5rem;
  cursor: pointer;
`;

export const AdminChannelsTable: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [config, setConfig] = useState<GridColumns>([]);
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<string>('DESC');
  const [channelName, setChannelName] = useState<string>('');
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [
    approveConfirmationDialogOpen,
    setApproveConfirmationDialogOpen,
  ] = useState(false);
  const [rejectChannelDialogOpen, setRejectChannelDialogOpen] = useState(false);
  const [reviewingChannelId, setReviewingChannelId] = useState<
    number | undefined
  >();
  const [channelTypeList, setChannelTypeList] = useState<string[]>([]);
  const [channelStatusList, setChannelStatusList] = useState<string[]>([]);
  const [channels, setChannels] = useState<IDistributionPartnerChannelDTO[]>(
    []
  );
  const [totalElements, setTotalElements] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState(true);

  const handleChannelDetails = (channelId: number): void => {
    history.push(`/home/admin/channels/${channelId}`);
  };
  const openReviewDialog = (channelId: number): void => {
    setReviewingChannelId(channelId);
    setReviewDialogOpen(true);
  };

  const onSortingColumn = (value: string, value2: string): void => {
    setParams({ ...params, sortByField: value, sortDirection: value2 });
  };

  const fetchChannels = (): Promise<any> => {
    setTableLoading(true);

    return getChannels(params)
      .then(({ content, totalElements }) => {
        setTotalElements(totalElements);
        setChannels(content);
        setConfig(
          createChannelsTableConfig(
            openReviewDialog,
            handleChannelDetails,
            onSortingColumn,
            params
          )
        );
        setLoading(false);
        setTableLoading(false);
      })
      .finally(() => setTableLoading(false));
  };

  useEffect(() => {
    fetchChannels().then();
    // eslint-disable-next-line
  }, [params]);

  const onSortingChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setSorting(event.target.value as string);
    setParams({ ...params, sortDirection: event.target.value as string });
  };

  const onChannelTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setChannelTypeList(event.target.value as string[]);
    setParams({ ...params, channelTypeList: event.target.value as string[] });
  };

  const onChannelStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setChannelStatusList(event.target.value as string[]);
    setParams({ ...params, channelStatusList: event.target.value as string[] });
  };

  const onChannelNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    setChannelName(event.target.value as string);
    setParams({ ...params, channelName: event.target.value as string });
  };

  const handleChannelRejection = (): void => {
    setLoading(true);
    setRejectChannelDialogOpen(false);
    setApproveDialogOpen(false);
    setReviewDialogOpen(false);
    fetchChannels();
  };

  const onClose = (): void => {
    setRejectChannelDialogOpen(false);
  };

  const handleChannelApproved = (): void => {
    setLoading(true);
    setApproveDialogOpen(false);
    setReviewDialogOpen(false);
    setApproveConfirmationDialogOpen(true);
  };

  const onNext = (): void => {
    setApproveConfirmationDialogOpen(false);
    fetchChannels();
  };

  const handleBack = (): void => {
    setApproveDialogOpen(false);
  };

  const handlePageChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageNumber: param.page }));
  };

  const handlePageSizeChange = (param: GridPageChangeParams): void => {
    setParams((prevParams) => ({ ...prevParams, pageSize: param.pageSize }));
  };

  const resetFilters = (columns: GridColumns): void => {
    const filters: Record<string, string> = {};

    columns
      .filter((column) => column.filterable)
      .forEach((column) => (filters[String(column.field)] = ''));

    setParams((prevParams) => ({
      ...prevParams,
      ...filters,
    }));
  };

  const handleFilterModelChange = useCallback(
    ({ filterModel, columns }: GridFilterModelParams) => {
      const [{ columnField, value }] = filterModel.items;

      if (!value) {
        return resetFilters(columns);
      }

      setParams({
        ...params,
        pageNumber: 0,
        [String(columnField)]: value || '',
      });
    },
    [params]
  );

  return (
    <Body>
      {loading ? (
        <PlxLoader />
      ) : (
        <Paper>
          <PlxTableToolbar label={t('channels.table.label')}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
              testId="sorting-select"
            />

            <StyledMultipleSelect
              options={channelTypeOptions}
              label={t('channels.table.channelType')}
              onChange={onChannelTypeChange}
              value={channelTypeList}
              testId="channel-type-select"
            />
            <StyledMultipleSelect
              options={channelStatusOptions}
              label={t('channels.table.channelStatus')}
              onChange={onChannelStatusChange}
              value={channelStatusList}
              testId="status-select"
            />
            <StyledInput
              margin={'dense'}
              placeholder={t('channels.table.search')}
              value={channelName}
              onChange={onChannelNameChange}
              fullWidth={false}
              testId="search-channel-field"
            />
          </PlxTableToolbar>
          <XemTable
            header={config}
            data={channels}
            loading={tableLoading}
            pagination
            page={params.pageNumber}
            paginationMode="server"
            pageSize={params.pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowCount={totalElements}
            onPageChange={handlePageChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
          />
        </Paper>
      )}
      <PlxDialog
        title={t('channels.table.reviewChannel.title')}
        open={reviewDialogOpen}
        onClose={() => setReviewDialogOpen(false)}
        onSave={() => setApproveDialogOpen(true)}
        saveButtonLabel={t('channels.table.reviewChannel.approve')}
        onExtraOption={() => setRejectChannelDialogOpen(true)}
        extraOptionLabel={t('channels.table.reviewChannel.reject')}
      >
        <ChannelDetails channelId={reviewingChannelId} />
      </PlxDialog>
      <PlxDialog
        title={t('channels.table.reviewChannel.title')}
        open={approveDialogOpen}
        onClose={() => setApproveDialogOpen(false)}
      >
        <ApproveChannelForm
          channelId={reviewingChannelId}
          onChannelApproved={handleChannelApproved}
          onBack={handleBack}
        />
      </PlxDialog>
      <StyledDialog
        onClose={() => setRejectChannelDialogOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={rejectChannelDialogOpen}
      >
        <CloseIcon onClick={onClose} />
        <RejectChannelDialog
          handleClose={handleChannelRejection}
          channelId={reviewingChannelId}
        />
      </StyledDialog>
      <Dialog
        onClose={() => setApproveConfirmationDialogOpen(false)}
        open={approveConfirmationDialogOpen}
        disableBackdropClick
      >
        <ApproveChannelConfirmation onNext={onNext} />
      </Dialog>
    </Body>
  );
};
