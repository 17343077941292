import { spectrumApi } from '_Api/api';
import { AddClientForm } from '../user.types';

export const addClient = (payload: AddClientForm, userId: number) => {
  return spectrumApi
    .post(`agencies/${userId}/client/add`, {
      json: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
        email: payload.email,
        country: payload.country,
      },
    })
    .json();
};
export const editClient = (payload: AddClientForm, userId: number) => {
  return spectrumApi
    .put(`agencies/${userId}/client/${payload.id}`, {
      json: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
        email: payload.email,
        country: payload.country,
      },
    })
    .json();
};
