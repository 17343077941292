import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  createStyles,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from '@material-ui/core';
import { ISelectOption } from '_Components/PlxSelect/types';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '_Types/props';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectTitle = styled.div<IThemed>`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 1rem 0;
`;

const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '3.3rem ',
      minWidth: '20rem',
      borderRadius: '0.6rem',
    },
    input: {
      borderRadius: '0.6rem',
      border: '1px solid #D5DDE3',
    },
  })
)(InputBase);

const StyledSelect = withStyles({
  select: {
    '&:focus': {
      borderRadius: '0.6rem',
      backgroundColor: 'white',
    },
  },
  root: {
    display: 'flex',
    borderRadius: '0.6rem',
  },
})(Select);

const useMenuStyles = makeStyles(() =>
  createStyles({
    dropdownStyle: {
      border: '1px solid #D5DDE3',
      borderRadius: '0px 0px 3px 3px',
      boxShadow: 'none',
      padding: '1rem',
    },
  })
);

interface IProps extends ICommonProps {
  title?: string;
  initialValue?: unknown;
  options?: ISelectOption[];
  onChange?: (value: unknown) => void;
}

export const PlxSelect: FC<IProps> = ({
  title,
  initialValue,
  options,
  onChange,
  className,
}) => {
  const [selectValue, setSelectValue] = useState<unknown>('');
  const useStyles = useMenuStyles();
  useEffect(() => {
    setSelectValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setSelectValue(value);
    onChange && onChange(value);
  };

  return (
    <StyledSelectWrapper>
      {title && <SelectTitle>{title}</SelectTitle>}
      <StyledSelect
        className={className}
        input={<SelectInput />}
        value={selectValue}
        onChange={handleChange}
        MenuProps={{ classes: { paper: useStyles.dropdownStyle } }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.optionIcon && option.optionIcon()}
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledSelectWrapper>
  );
};
