import styled from '@emotion/styled';
import React, { FC } from 'react';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { NotifyMessage } from '../NotifyMessage/NotifyMessage';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import { PayViaCheckInfo } from './_Components/PayViaCheckInfo/PayViaCheckInfo';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: 3.5rem;
  .PlxPanel-body {
    height: 43.5rem;
    width: 77rem;
    margin: auto;
    padding: 5rem;
  }
  .PlxPanel-content {
    width: 100%;
  }
`;

interface IProps {
  notification: string;
  backPath: string;
  amount: string;
}

export const PayViaCheck: FC<IProps> = ({ notification, backPath, amount }) => {
  const history = useHistory();
  const handleBack = () => {
    history.push(backPath);
  };
  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        content={<PayViaCheckInfo amount={amount} />}
      />
      <NotifyMessage notification={notification} />
      <FooterButtons
        showSaveForLater={false}
        showNextStep={false}
        onBack={handleBack}
        isNextStepDisabled={true}
        showBack={false}
      />
    </Wrapper>
  );
};
