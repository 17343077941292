import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ITile } from '_Types/ITile';
import { IThemed } from '_Styles/types';
import { FORMAT_QUANTITY_MAP } from '_Routes/Home/_routes/Spectrum/utils';
import ReachIcon from '_Styles/images/_Icons/ReachIcon.png';
import Reactions from '_Styles/images/_Icons/Reactions.png';
import Comments from '_Styles/images/_Icons/Comments.png';
import TotalNews from '_Styles/images/_Icons/TotalNews.png';
import { CallMadeOutlined } from '@material-ui/icons';

const Wrapper = styled.div<IThemed & { tilesQuantity: number }>`
  margin: ${({ theme }) => theme.spacing(0, 3, 0, 0)};
  background: #e5e5e5;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  height: 15rem;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  width: ${({ tilesQuantity }) => `${100 / tilesQuantity}%`};
  font-family: 'Quicksand';
  font-style: normal;
  &:last-of-type {
    margin: 0;
  }
`;

const StyledImgIcon = styled.img`
  display: block;
  height: 40px;
  width: 40px;
  margin-bottom: 1rem;
`;

const Label = styled.div`
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #5f5f61;
`;

const Amount = styled.div<IThemed & { editable?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  display: inline-flex;
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #403d3f;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem auto;
  width: 100%;
`;

interface IProps extends ITile {
  tilesQuantity: number;
  className?: string;
}
export const ClientPlxTile: FC<IProps> = ({
  label,
  amount,
  tilesQuantity,
  className,
}) => {
  const [amountValue, setAmountValue] = useState<any>();
  useEffect(() => {
    setAmountValue(amount);
  }, [amount]);

  const getFormattedAmount = (quantity: number) => {
    return FORMAT_QUANTITY_MAP.find((amount) =>
      amount.isApplicable(quantity)
    )?.transform(quantity);
  };

  const getIcon = () => {
    if (label === 'Estimated followers') {
      return <StyledImgIcon src={ReachIcon} />;
    }
    if (label === 'Total reactions') {
      return <StyledImgIcon src={Reactions} />;
    }
    if (label === 'Total comments') {
      return <StyledImgIcon src={Comments} />;
    }
    if (label === 'Total news shared') {
      return <StyledImgIcon src={TotalNews} />;
    }
  };

  return (
    <Wrapper tilesQuantity={tilesQuantity} className={className}>
      <TextWrapper>
        {label && getIcon()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <Label>{label}</Label>
            <Amount>
              {isNaN(amountValue)
                ? amountValue
                : getFormattedAmount(amountValue)}
            </Amount>
          </div>
          <div style={{ width: '10%' }}>
            <CallMadeOutlined
              style={{
                fill: '#231F20',
                width: '2rem',
                height: '2rem',
                marginTop: '1.5rem',
              }}
            />
          </div>
        </div>
      </TextWrapper>
    </Wrapper>
  );
};
