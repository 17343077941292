import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { IUpdateableAnalyticsDTO } from '_State/DistributionPartner/distributionPartner.types';
import { TextField } from '@material-ui/core';

interface IProps {
  data?: number;
  reactions?: boolean;
  shares?: boolean;
  comments?: boolean;
  impressions?: boolean;
  videoSaves?: boolean;
  numberOfFollowers?: boolean;
  handleUpdate: (data: IUpdateableAnalyticsDTO) => void;
}

export const PlxUpdateField: FC<IProps> = ({
  data,
  handleUpdate,
  reactions,
  shares,
  comments,
  impressions,
  videoSaves,
  numberOfFollowers,
}) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    data && setValue(data);
  }, [data]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue) || inputValue === '') {
      setValue(parseInt(inputValue, 10));
      switch (true) {
        case reactions:
          handleUpdate({ reactions: parseInt(inputValue, 10) });
          break;
        case shares:
          handleUpdate({ shares: parseInt(inputValue, 10) });
          break;
        case comments:
          handleUpdate({ comments: parseInt(inputValue, 10) });
          break;
        case impressions:
          handleUpdate({ impressions: parseInt(inputValue, 10) });
          break;
        case videoSaves:
          handleUpdate({ videoSaves: parseInt(inputValue, 10) });
          break;
        case numberOfFollowers:
          handleUpdate({ numberOfFollowers: parseInt(inputValue, 10) });
          break;
        default:
          break;
      }
    }
  };

  return (
    <TextField
      style={{ width: '10rem' }}
      hiddenLabel
      value={value.toString()}
      size="small"
      onChange={handleInputChange}
      InputProps={{
        style: { textAlignLast: 'end' },
      }}
    />
  );
};
