import { spectrumApi } from '_Api/api';
import { ICampaigns, IPaymentDTO, ITag, ITagUpdate } from '_Types/campaigns';
import {
  IAsset,
  IAssetUpdate,
  ICampaignUpdate,
  IRegion,
  IThriveBudget,
  IThriveCampaign,
  IThriveCampaignForm,
  IThriveCampaignsQueryParams,
  IThriveLeads,
  IThriveLeadsQueryParams,
  IThriveProgramDraft,
} from '../types';
import { IMapCountry } from '_Components/PlxMap/types';
import { IPaymentForm } from '../../../_Types/paymentContext';

export const getThriveCampaigns = (
  params?: IThriveCampaignsQueryParams
): Promise<ICampaigns<IThriveCampaign>> => {
  let url = 'users/self/thrive-campaigns';
  if (params) {
    const urlParams = new URLSearchParams({
      sortDirection: params.sortDirection,
      sortByField: params.sortByField,
    });
    if (params.tagIdList.length > 0) {
      urlParams.append('tagIdList', params.tagIdList.join(','));
    }
    if (params.campaignStatusList.length > 0) {
      urlParams.append(
        'campaignStatusList',
        params.campaignStatusList.join(',')
      );
    }
    urlParams.append('pageSize', '10000');
    url += `?${urlParams}`;
  }

  return spectrumApi.get(url).json();
};

export const getThriveDraft = (): Promise<IThriveCampaign> => {
  return spectrumApi.get(`thrive-campaigns/draft`).json();
};

export const getThriveBudgets = (): Promise<IThriveBudget[]> => {
  return spectrumApi.get(`thrive-campaigns/budgets`).json();
};

export const getThriveGeolocationsStates = (id: number): Promise<IRegion[]> => {
  return spectrumApi.get(`thrive-campaigns/geolocation-states/${id}`).json();
};

export const getThriveCampaign = (
  id: number | string | undefined
): Promise<IThriveCampaign> => {
  return spectrumApi.get(`users/self/thrive-campaigns/${id}`).json();
};

export const getCampaignAssets = (id?: number | string): Promise<IAsset[]> => {
  return spectrumApi.get(`thrive-campaigns/${id}/assets`).json();
};

export const getThriveCountryStatistics = (
  id?: number | string
): Promise<IMapCountry[]> => {
  return spectrumApi
    .get(`thrive-campaigns/${id}/leads/country-statistics`)
    .json()
    .then((countries: any) => {
      const totalLeads = Object.entries(countries)
        .map((key) => key[1] as number)
        .reduce((a: number, b: number) => a + b, 0);
      return Object.entries(countries).map((key) => {
        return {
          isoAlpha3: key[0].substring(key[0].indexOf(',') + 1),
          value: key[1] as number,
          percents: (key[1] as number) / totalLeads,
          name: key[0].substring(0, key[0].indexOf(',')),
        };
      });
    });
};

export const saveThriveProgramDraft = (
  form: IThriveProgramDraft
): Promise<any> => {
  return spectrumApi.post(`thrive-campaigns/draft`, { json: form }).json();
};

export const updateThriveProgramDraft = (
  form: IThriveProgramDraft
): Promise<any> => {
  return spectrumApi.put(`thrive-campaigns/draft`, { json: form }).json();
};

export const updateThriveCampaignDraft = (
  form: IThriveCampaignForm
): Promise<any> => {
  const dto = {
    presentation: {
      fileName: form.presentationName,
      base64Content: form.presentationUrl,
    },
    companyWebsite: form.companyWebsite,
    otherInformations: form.otherInformation,
  };
  return spectrumApi
    .put(`thrive-campaigns/draft/campaign-content`, {
      json: dto,
      timeout: 60000,
    })
    .json();
};

export const completeThriveDraft = (): Promise<any> => {
  return spectrumApi.post('thrive-campaigns/draft/complete').json();
};

export const getThriveLeadsCSV = (id?: string): Promise<any> => {
  return spectrumApi.get(`thrive-campaigns/${id}/leads/export-csv`).blob();
};

export const getThriveLeads = (
  id?: string,
  params?: IThriveLeadsQueryParams
): Promise<IThriveLeads> => {
  let url = `thrive-campaigns/${id}/leads`;
  if (params) {
    const urlParams = new URLSearchParams({
      sortDirection: params.sortDirection,
      sortByField: params.sortByField,
    });
    if (params.geolocationList.length > 0) {
      urlParams.append('geolocationList', params.geolocationList.join(','));
    }
    urlParams.append('pageSize', '10000');
    url += `?${urlParams}`;
  }
  return spectrumApi.get(url).json();
};

export const getThriveTags = (): Promise<ITag[]> => {
  return spectrumApi.get(`thrive-campaigns/tags`).json();
};

export const requestAssetInfo = (
  campaignId?: number | string,
  assetRequest?: IAssetUpdate
): Promise<any> => {
  return spectrumApi
    .post(
      `thrive-campaigns/${campaignId}/assets/${assetRequest?.id}/notification`,
      {
        json: {
          instructions: assetRequest?.instructions,
        },
      }
    )
    .json();
};

export const requestCampaignUpdate = (
  campaignId?: number | string,
  campaignRequest?: ICampaignUpdate
): Promise<any> => {
  return spectrumApi
    .post(`thrive-campaigns/${campaignId}/notification`, {
      json: { ...campaignRequest },
    })
    .json();
};

export const updateThriveTags = (newTags: ITagUpdate): Promise<any> => {
  return spectrumApi.put(`thrive-campaigns/tags`, { json: newTags }).json();
};

export const deleteThriveTag = (tagId: number): Promise<any> => {
  return spectrumApi.delete(`thrive-campaigns/tags/${tagId}`);
};

export const createThriveTag = (name: string): Promise<ITag> => {
  return spectrumApi
    .post(`thrive-campaigns/tags`, {
      json: {
        name,
      },
    })
    .json();
};

export const updateThriveCampaignTag = (
  campaignId: number,
  tagId: number | null | undefined
): Promise<any> => {
  return spectrumApi
    .put(`thrive-campaigns/${campaignId}/tags`, { json: { tagId: tagId } })
    .json();
};

export const createThrivePayment = (
  form: IPaymentForm,
  id?: number
): Promise<any> => {
  const dto: IPaymentDTO = {
    amount: +form.package,
    paymentMethod: form.payment[0],
    pricingType: form.pricingType,
    expirationDate: form.expirationDate,
    cvc: form.cvc,
    creditCardNumber: form.cardNumber,
    cardholder: form.cardHolder,
    promotionalCode: '',
  };
  return spectrumApi
    .post(`payment/thrive-campaign/${id}`, {
      json: {
        ...dto,
      },
    })
    .json();
};
