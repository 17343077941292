import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { IThemed } from '_Styles/types';
import { FormMassage } from '_Components/Forms/FormMassage';
import { ReactComponent as FileUpload } from './../_Styles/images/file_upload.svg';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const PlxFileInputBody = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  // padding: 5px 10px;
`;
const FileInput = styled.input`
  display: none;
`;

const FileName = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.1rem;
  margin: auto 2rem;
`;

const FileButton = styled(Button)`
  && {
    border: 1px solid #d5dde3;
    height: 3.5rem;
    background-color: #fff;
    font-size: 10px;
    padding: 1.1rem 1rem;
  }
`;

interface IProps {
  onChange: (base64Url: any, fileName: string) => void;
  fileName?: string;
  name?: string;
  className?: string;
  accept?: string;
  fileSizeLimit?: number;
  uploadIcon?: boolean;
}

export const PlxFileInput: FC<IProps> = ({
  onChange,
  fileName = '',
  name,
  className,
  accept = 'image/*',
  fileSizeLimit = 700000,
  uploadIcon,
}) => {
  const [filenames, setFilenames] = useState(fileName);
  const [error, setError] = useState<string>();

  const encodeFile = (file: any) =>
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      if (file.size > fileSizeLimit) {
        reject('File is too big. Max size is 700kb');
        setError('File is too big. Max size is 700kb');
      }
      fr.readAsDataURL(file);
      fr.onload = () => resolve(fr.result);
      fr.onerror = (error) => reject(error);
    });

  const getEncodedFiles = (files: any) => {
    return Promise.all(files.map(encodeFile));
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async () => {
    if (error) {
      setError(undefined);
    }
    if (inputRef && inputRef.current && inputRef.current.files) {
      const files = Array.from(inputRef.current.files);
      const names = files.map((file) => file.name).join(', ');
      setFilenames(names);
      const values = await getEncodedFiles(files);
      onChange((values as string[])[0].split(',')[1], names);
    }
  };

  return (
    <>
      <Wrapper>
        <PlxFileInputBody className={className}>
          <FileButton
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {uploadIcon ? <FileUpload /> : 'Upload'}
          </FileButton>
          <FileName>
            {filenames ? filenames : fileName ? fileName : 'No file selected'}
          </FileName>
          <FileInput
            accept={accept}
            ref={inputRef}
            type="file"
            name={name}
            data-testid="fileInput"
            onChange={handleChange}
          />
        </PlxFileInputBody>
      </Wrapper>
      {error && <FormMassage message={error} />}
    </>
  );
};
