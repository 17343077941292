import { IPackageInfo } from '../PackageInfo/PackageInfo.types';
import { ICommonProps } from '_Types/props';

export interface IPackageControlConfig extends ICommonProps {
  option: IPackageInfo;
  vertical?: boolean;
  disabled?: boolean;
  isChosen: boolean;
  value: string;
  id?: number;
  onClick?: (value: string) => void;
  type: string;
  promo?: boolean;
  isThrivePayment?: boolean;
  paymentPlan?: string;
  durationType?: string;
  savings?: string;
}

export enum PricingType {
  MONTHLY = 'MONTHLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}
