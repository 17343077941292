import {
  makeStyles,
  Theme,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { StyledLabel } from '_Components/PlxMultipleSelect/PlxMultipleSelect';
import { MultipleSelectIcon } from '_Routes/Home/NavigationPanel/Icons/MultipleSelectIcon';

interface IProps {
  value?: any[];
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  required?: boolean;
  options: any[];
  menuProps?: any;
  name?: string;
  label?: string;
  className?: string;
  size?: 'medium' | 'small';
  placeholder?: boolean;
  testId?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      minWidth: 80,
      maxWidth: 300,
      '&:hover': {
        backgroundColor: '#e5e5e5',
        borderRadius: '5px',
        opacity: 1,
      },
      '&:focus-within': {
        backgroundColor: '#e5e5e5',
        borderRadius: '5px',
        opacity: 1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e5e5e5',
        borderRadius: '4px',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e5e5e5',

        borderRadius: '4px',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e5e5e5',
        borderRadius: '4px',
      },
      '& .Mui-disabled': {
        color: '#FFFFFF',
        opacity: 0.6,
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e5e5e5',
        borderRadius: '4px',
      },
    },
    selectRoot: {
      color: '#FFFFFF',
    },
    icon: {
      color: '#FFFFFF',
    },
    selectPaper: {
      backgroundColor: '#1E1E24',
      border: '1px solid #484850',
      borderRadius: '5px',
      color: '#FFFFFF',
      '& li:hover': {
        backgroundColor: '#303039',
      },
    },
    label: {
      fontWeight: 600,
      fontSize: '14px',
      color: '#1B192C',
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    selectMenu: {
      border: 'none',
      background: '#e5e5e5',
    },
    select: {
      background: '#e5e5e5',
    },
    checkboxChecked: {
      color: '#c0c8ce !important',
      '&:hover': {
        backgroundColor: 'rgba(105,105,105,0.1) !important',
      },
    },
    checkbox: {
      '&:hover': {
        backgroundColor: 'rgba(105,105,105,0.1)',
      },
    },
    menuItem: {
      '&.Mui-selected': {
        background: '#E5E5E5',
      },
    },
  })
);

export const MultipleTagSelect: FC<IProps> = ({
  value = [],
  onChange,
  required,
  options,
  size = 'small',
  label,
  placeholder,
  testId,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      classes={{ root: classes.root }}
      variant="outlined"
      size={size}
    >
      <StyledLabel
        shrink={false}
        customLabel={placeholder}
        id="label"
        classes={{
          root: classes.label,
        }}
      >
        {label}
      </StyledLabel>
      <Select
        className={classes.select}
        classes={{ select: classes.selectMenu }}
        data-test-id={testId}
        labelId="label"
        multiple
        value={value}
        onChange={onChange}
        required={required}
        renderValue={(selectedIds) =>
          (selectedIds as number[])
            .map(
              (selectedId) =>
                options.find((option) => option.id === selectedId).name
            )
            .join(', ')
        }
        MenuProps={MenuProps}
        IconComponent={MultipleSelectIcon}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            divider={true}
            classes={{ root: classes.menuItem }}
          >
            <Checkbox
              classes={{
                root: classes.checkbox,
                checked: classes.checkboxChecked,
              }}
              checked={value.indexOf(option.id) > -1}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
