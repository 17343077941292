import styled from '@emotion/styled';
import React, { FC } from 'react';
import { CHANNEL_STATUS_MAP_STRATEGY } from '_State/DistributionPartner/distributionPartner.types';
import { ChannelStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelStatusCell';
import { getChannelStatus } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { TrimString } from '../../utils';
import { useTranslation } from 'react-i18next';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';

const ChannelWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 1rem 0 1.1rem 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > div {
    flex: 1;
  }
`;

const TableValue = styled.div`
  font-size: 1.2rem;
  display: flex;
  gap: 2px;
  a {
    text-decoration: none;
    color: #000;
  }
  p {
    color: #4f007d;
  }
`;
const Title = styled.div`
  font-size: 1rem;
  color: #a9b3bb;
  height: 3.1rem;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
`;
const StatusWrapper = styled.div`
  display: flex;
`;
const EarningWrap = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: end;
  padding-right: 1rem;
  /* margin-right: 1rem; */
  border-right: 1px solid #e5e5e5;
  max-width: 8rem;
  text-align: end;
`;
const NoOffer = styled.div`
  font-size: 1.2rem;
  color: #9b5858;
`;
const TotalFollowers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  max-width: 7rem;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  text-align: end;
`;
interface IProps {
  id?: number;
  status: string;
  channelName?: string;
  channelType?: string;
  earnings?: number;
  offer?: number;
  channelUrl?: string;
  disable?: boolean;
  numberOfFollowers?: number;
  reactions?: number;
  shares?: number;
  comments?: number;
  recentPostCount?: number;
}

export const DistributionPartnerDashboardChannelsDetailsContainer: FC<IProps> = (
  {
    id,
    status,
    channelName,
    channelType,
    earnings,
    offer,
    channelUrl,
    disable,
    numberOfFollowers,
    reactions,
    shares,
    comments,
    recentPostCount,
  },
  index
) => {
  const { t } = useTranslation();
  return (
    <ChannelWrapper key={index} disable={disable}>
      <div>
        <Title>{t('finfluencer.dashboard.channelDetails.channelName')}</Title>
        <TableValue>{channelName}</TableValue>
      </div>
      <div>
        <Title>{t('finfluencer.dashboard.channelDetails.channelType')}</Title>
        <TableValue>{channelType}</TableValue>
      </div>
      <div>
        <Title>{t('finfluencer.dashboard.channelDetails.channelLink')}</Title>
        <TableValue>
          {channelUrl ? (
            <a href={channelUrl} target="_blank" rel="noopener noreferrer">
              <TrimString paragraph={channelUrl} maxLetters={20} />
            </a>
          ) : (
            '-'
          )}
        </TableValue>
      </div>
      <TotalFollowers>
        <Title>
          {t('finfluencer.dashboard.channelDetails.numberOfFollowers')}
        </Title>
        <TableValue>{numberOfFollowers}</TableValue>
      </TotalFollowers>
      <TotalFollowers>
        <Title>{t('finfluencer.dashboard.channelDetails.reactions')}</Title>
        <TableValue>{reactions ? reactions : '-'}</TableValue>
      </TotalFollowers>
      <TotalFollowers>
        <Title>{t('finfluencer.dashboard.channelDetails.comments')}</Title>
        <TableValue>{comments ? comments : '-'}</TableValue>
      </TotalFollowers>
      <TotalFollowers>
        <Title>{t('finfluencer.dashboard.channelDetails.shares')}</Title>
        <TableValue>{shares ? shares : '-'}</TableValue>
      </TotalFollowers>
      <TotalFollowers>
        <Title>{t('finfluencer.dashboard.channelDetails.postCount')}</Title>
        <TableValue>{recentPostCount ? recentPostCount : '-'}</TableValue>
      </TotalFollowers>
      <EarningWrap>
        <Title>{t('finfluencer.dashboard.channelDetails.totalEarned')}</Title>
        <TableValue>
          $<StandardizeAmount amount={offer ? offer : 0} />
        </TableValue>
      </EarningWrap>
      <div style={{ paddingLeft: '1rem' }}>
        <Title>
          {t('finfluencer.dashboard.channelDetails.opportunityToEarn')}
        </Title>
        <TableValue>
          $<StandardizeAmount amount={offer ? offer : 0} />
        </TableValue>
      </div>
      <div>
        <Title>{t('finfluencer.dashboard.channelDetails.status')}</Title>
        <StatusWrapper>
          <TableValue>
            {!disable && (
              <ChannelStatusCell
                status={
                  (CHANNEL_STATUS_MAP_STRATEGY as any)[getChannelStatus(status)]
                }
              />
            )}
            {disable && (
              <NoOffer>
                {t('finfluencer.dashboard.channelDetails.lastOffer')}
              </NoOffer>
            )}
          </TableValue>
        </StatusWrapper>
      </div>
    </ChannelWrapper>
  );
};
