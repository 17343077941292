import { CampaignStatusIcon, ISelectOption } from '_Components/PlxSelect/types';
import { CampaignStatus } from '_Types/campaigns';
import React from 'react';
import {
  CAMPAIGN_STATUS_MAP_STRATEGY,
  PAYMENT_METHOD_MAP_STRATEGY,
} from '_Routes/Home/_routes/Admin/types';
import { PlxSelect } from '_Components/PlxSelect/PlxSelect';
import { arrayToSplittedString } from '_App/utils';
import { configurePaymentSelect } from '../../Spectrum/AdminSpectrumCampaign/configs';
import {
  IGeoLocation,
  IGeoLocationState,
  IThriveCampaign,
  IThriveLead,
} from '_State/Thrive/types';
import { ISimpleSelectOption } from '_Types/select';
import _ from 'lodash';
import { ICampaignInfoItem } from '_Components/Panels/PlxCampaignInformation/PlxCampaignInformation';
import moment from 'moment';
import styled from '@emotion/styled';
import { getPaymentMethod } from '../../Spectrum/Dashboard/utils';

const StyledSelect = styled(PlxSelect)``;
const StyledDownload = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const configureStatusSelect = (t: any): ISelectOption[] => [
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.NEW].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.NEW].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.NEW],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.LAUNCHED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.LAUNCHED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.LAUNCHED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.REVIEW_NEEDED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.REVIEW_NEEDED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.REVIEW_NEEDED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.COMPLETED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.COMPLETED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.COMPLETED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAUSED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAUSED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.PAUSED],
  },
];

export const configureAdminThriveCampaignInfo = (
  t: any,
  onPaymentStatusChange: (value: unknown) => void,
  campaign?: IThriveCampaign | null
): ICampaignInfoItem[] => [
  {
    label: t('admin.campaignInfo.companyName'),
    renderContent: () => <div> {campaign?.companyName}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorEmail'),
    renderContent: () => <div>{campaign?.creatorEmail}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorName'),
    renderContent: () => <div>{campaign?.creatorName}</div>,
  },
  {
    label: t('admin.campaignInfo.campaignId'),
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('admin.campaignInfo.dateOfCampaign'),
    renderContent: () => (
      <div>{campaign && moment(campaign.createdDate).format('MM/DD/yyyy')}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.package'),
    renderContent: () => (
      <div>
        {campaign?.payment?.amount ? `$${campaign?.payment?.amount}` : '-'}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.paymentMethod'),
    renderContent: () => (
      <>
        {campaign?.payment ? (
          <StyledSelect
            onChange={onPaymentStatusChange}
            title={t(
              (PAYMENT_METHOD_MAP_STRATEGY as any)[
                getPaymentMethod(campaign?.payment.paymentMethod)
              ].label
            )}
            initialValue={campaign?.payment.paymentStatus}
            options={configurePaymentSelect(t)}
          />
        ) : (
          <div>-</div>
        )}
      </>
    ),
  },
  {
    label: t('admin.campaignInfo.industry'),
    renderContent: () => <div>{campaign?.industry && campaign.industry}</div>,
  },
  {
    label: t('admin.campaignInfo.targetGeoLocation'),
    renderContent: () => (
      <div>{campaign?.geolocation && campaign.geolocation.country}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.investorPresentation'),
    renderContent: () => (
      <>
        {campaign &&
          campaign.thrivePresentation &&
          campaign?.thrivePresentation.screenshotUrl && (
            <StyledDownload
              href={campaign?.thrivePresentation?.screenshotUrl}
              download
            >
              {campaign?.thrivePresentation.fileName}
            </StyledDownload>
          )}
      </>
    ),
  },
];

export const configureEditorThriveCampaignInfo = (
  t: any,
  campaign?: IThriveCampaign | null
): ICampaignInfoItem[] => [
  {
    label: t('admin.campaignInfo.companyName'),
    renderContent: () => <div> {campaign?.companyName}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorEmail'),
    renderContent: () => <div>{campaign?.creatorEmail}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorName'),
    renderContent: () => <div>{campaign?.creatorName}</div>,
  },
  {
    label: t('admin.campaignInfo.campaignId'),
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('admin.campaignInfo.dateOfCampaign'),
    renderContent: () => (
      <div>{campaign && moment(campaign.createdDate).format('MM/DD/yyyy')}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.industry'),
    renderContent: () => <div>{campaign?.industry && campaign.industry}</div>,
  },
  {
    label: t('admin.campaignInfo.targetGeoLocation'),
    renderContent: () => (
      <div>{campaign?.geolocation && campaign.geolocation.country}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.investorPresentation'),
    renderContent: () => (
      <>
        {campaign &&
          campaign.thrivePresentation &&
          campaign?.thrivePresentation.screenshotUrl && (
            <StyledDownload
              href={campaign?.thrivePresentation?.screenshotUrl}
              download
            >
              {campaign?.thrivePresentation.fileName}
            </StyledDownload>
          )}
      </>
    ),
  },
];

export const mapGeoLocationsToSelect = (
  geoloactions: IGeoLocation[]
): ISimpleSelectOption[] => {
  return geoloactions.map((geolocation) => {
    return {
      id: geolocation.id,
      name: _.startCase(_.toLower(geolocation.country)),
    };
  });
};

export const mapStatesToSelect = (
  states: IGeoLocationState[]
): ISimpleSelectOption[] => {
  return states.map((state) => {
    return {
      id: state.id,
      name: state.region,
    };
  });
};

export const countLeadsByMonth = (
  leads: IThriveLead[],
  month: number
): number => {
  return leads.filter((lead) => moment(lead.optInDate).month() + 1 === month)
    .length;
};

export const configureMonthData = (leads: IThriveLead[]): number[] => {
  return Array.from(Array(12).keys()).map((value) =>
    countLeadsByMonth(leads, value + 1)
  );
};
