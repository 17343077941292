import React, { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import {
  EnhancedTableHead,
  getComparator,
  ICampaignData,
  Order,
  stableSort,
  useStyles,
} from './utils';
import { useTranslation } from 'react-i18next';
import { XemTooltip } from '_Components/XemTooltip/XemTooltip';
interface Data {
  campaignId: number;
  tag: string;
  numberOfFollowers: number;
  engagement: number;
  launchDate: string;
  contentCategory: string;
  contentType: string;
  other: string;
}
const createData = (
  campaignId: number,
  tag: string,
  engagement: number,
  numberOfFollowers: number,
  launchDate: string,
  contentCategory: string,
  contentType: string,
  other: any
): Data => {
  return {
    campaignId,
    tag,
    engagement,
    numberOfFollowers,
    launchDate,
    contentCategory,
    contentType,
    other,
  };
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'campaignId',
    numeric: false,
    disablePadding: false,
    label: 'Campaign Id',
  },
  { id: 'tag', numeric: false, disablePadding: false, label: 'Tag' },
  {
    id: 'engagement',
    numeric: true,
    disablePadding: false,
    label: 'Engagement',
  },
  {
    id: 'numberOfFollowers',
    numeric: true,
    disablePadding: false,
    label: 'Followers',
  },
  {
    id: 'launchDate',
    numeric: false,
    disablePadding: false,
    label: 'Launch-Date',
  },
  {
    id: 'contentCategory',
    numeric: false,
    disablePadding: false,
    label: 'Content-Category',
  },
  {
    id: 'contentType',
    numeric: false,
    disablePadding: false,
    label: 'Content-Type',
  },
  {
    id: 'other',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];
interface IProps {
  campaignData: ICampaignData[];
  isAdmin?: number;
  isAgencyType: boolean;
}
export const TopCampaigns: FC<IProps> = ({
  campaignData,
  isAdmin,
  isAgencyType,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('engagement');
  const [page, setPage] = useState(0);
  const rows = campaignData.map(
    ({
      contentCategories,
      contentType,
      engagement,
      id,
      numberOfFollowers,
      startDate,
      tag = '-',
    }) =>
      createData(
        id,
        tag,
        engagement,
        numberOfFollowers,
        startDate,
        contentCategories.join(', '),
        contentType,
        <div className={classes.linksWrap}>
          <Link
            to={
              !!isAdmin
                ? `/home/admin/users/analyt/campaign/${isAdmin}/${id}`
                : isAgencyType
                ? `/home/agency/spectrum-dashboard/campaign/${id}`
                : `/home/client/spectrum-dashboard/campaign/${id}`
            }
          >
            <VisibilityIcon />
          </Link>
          <LinkIcon
            className={classes.copyLink}
            onClick={() => {
              navigator.clipboard.writeText(
                isAgencyType
                  ? `https://app.xemotomedia.com/home/agency/spectrum-dashboard/campaign/${id}`
                  : `https://app.xemotomedia.com/home/client/spectrum-dashboard/campaign/${id}`
              );
            }}
          />
        </div>
      )
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const emptyRows = 5 - Math.min(5, rows.length - page * 5);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * 5, page * 5 + 5)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={classes.tableRow}
                    >
                      <TableCell component="th" scope="row">
                        {row.campaignId}
                      </TableCell>
                      <TableCell align="left">{row.tag}</TableCell>
                      <TableCell align="right">
                        {row.engagement
                          .toFixed(3)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {row.numberOfFollowers
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                      <TableCell align="left">{row.launchDate}</TableCell>
                      <TableCell align="left">{row.contentCategory}</TableCell>
                      <TableCell align="left">{row.contentType}</TableCell>
                      <TableCell align="right">{row.other}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.paginationWrap}>
        <div>
          <XemTooltip
            primaryText={t('spectrum.topSpectrumTable.topCampaignsTooltip')}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
          />
          <Link className={classes.seeAll} to="./campaigns">
            {t('spectrum.topSpectrumTable.seeAllCampaigns')}
          </Link>
        </div>
      </div>
    </div>
  );
};
