import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import {
  StyledTableButton,
  TableStyledText,
} from '_Routes/Auth/Agency/StylesAg';
import { AddClientForm } from '_State/User/user.types';
import React, { ReactElement } from 'react';

export const createConfig = (
  openEditDialog: (data: AddClientForm) => void
): CellConfig<AddClientForm>[] => {
  return [
    {
      name: 'Company Name',
      propKey: 'companyName',
    },
    {
      name: 'Full Name',
      renderCell({ firstName, lastName }: AddClientForm): ReactElement {
        return <TableStyledText>{firstName + ' ' + lastName}</TableStyledText>;
      },
    },
    {
      name: 'Email',
      propKey: 'email',
    },

    {
      name: 'Actions',
      renderCell(value: AddClientForm): ReactElement {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledTableButton
              onClick={() =>
                openEditDialog({
                  id: value?.id,
                  firstName: value?.firstName,
                  lastName: value?.lastName,
                  email: value?.email,
                  companyName: value?.companyName,
                  country: value.country,
                })
              }
            >
              Edit
            </StyledTableButton>
          </div>
        );
      },
    },
  ];
};
