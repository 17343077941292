import styled from '@emotion/styled';
import { PlxText } from '_Components/Text/PlxText';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  status: any;
}

interface IText {
  color: any;
}

const StyledText = styled(PlxText)<IText>`
  color: ${({ color }) => color};
  display: flex;
  gap: 1rem;
`;

export const CampaignStatusCell: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  return <StyledText color={status?.color}>{t(status?.label)}</StyledText>;
};
