import React, { FC, useEffect, useState } from 'react';
import { PlxLoader } from '_Components/PlxLoader';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ChannelDetailsItem } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetailsItem';
import {
  ICampaignDetailsDTO,
  // IDistributionPartnerOpportunityDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { getCampaignDetails } from '_State/DistributionPartner/Api/distributionPartner.api';
import { Button } from '@material-ui/core';
import { TrimString } from '../../utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Magnifier from 'react-magnifier';
import { formatDate } from '_Components/DateFormat/DateFormat';
import moment from 'moment';
import DOMPurify from 'dompurify';

const Body = styled.div`
  width: 700px;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border-radius: 5px;
  min-height: 70%;
  height: fit-content;
`;
const CopyButton = styled(Button)`
  && {
    padding-bottom: 5px;
    padding-top: 0;
    height: 30px;
    font-size: 1.2rem;
    color: #545454;
    margin: 0;
  }
  span {
    padding-right: 1rem;
  }
`;
interface IProps {
  campaignDetailsIds: {
    campaignId?: number;
    opportunityId?: number;
    channelType?: string;
  };
}

const remainingDays = (dateDue: any) => {
  const date1 = new Date();
  const date2 = new Date(dateDue);
  // To calculate the time difference of two dates
  const differenceInTime = date2.getTime() - date1.getTime();
  const remains: number = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const daysRemaining =
    remains > 0
      ? remains + ` day${remains === 1 ? '' : 's'} remaining`
      : 'Past due';
  // To calculate the no. of days between two dates
  return daysRemaining;
};

export const CampaignDetails: FC<IProps> = ({ campaignDetailsIds }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [campaignDetails, setCampaignDetails] = useState<ICampaignDetailsDTO>();

  useEffect(() => {
    getCampaignDetails(
      campaignDetailsIds.campaignId,
      campaignDetailsIds.opportunityId
    ).then((cd) => {
      setCampaignDetails(cd);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const sanitizeHTML = (html: string) => {
    return DOMPurify.sanitize(html);
  };

  const renderListContent = (item: ICampaignDetailsDTO) => {
    const sanitizedContent = sanitizeHTML(item.furtherInstructions || '');
    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = sanitizedContent;

    const ulElements = wrapperDiv.getElementsByTagName('ul');
    for (let i = 0; i < ulElements.length; i++) {
      const ulElement = ulElements[i];
      ulElement.style.listStyleType = 'disc';
      ulElement.style.marginLeft = '1.5em';
    }

    const olElements = wrapperDiv.getElementsByTagName('ol');
    for (let i = 0; i < olElements.length; i++) {
      const olElement = olElements[i];
      olElement.style.listStyleType = 'decimal';
      olElement.style.marginLeft = '1.5em';
    }

    return <div dangerouslySetInnerHTML={{ __html: wrapperDiv.innerHTML }} />;
  };

  return (
    <>
      <Body>
        {loading ? (
          <PlxLoader />
        ) : (
          <>
            <ChannelDetailsItem
              label={t('channels.campaignDetails.companyName')}
            >
              {campaignDetails?.companyName}
            </ChannelDetailsItem>
            {campaignDetails && campaignDetails.stockTickerList.length > 0 && (
              <ChannelDetailsItem
                label={t('channels.campaignDetails.stockTicker')}
              >
                <>
                  {campaignDetails.stockTickerList.length > 0
                    ? campaignDetails.stockTickerList.map((stock, index) => (
                        <p key={index}>
                          {stock.exchangeSymbol}: {stock.companySymbol}
                        </p>
                      ))
                    : '-'}
                </>
              </ChannelDetailsItem>
            )}
            {campaignDetails?.url && (
              <ChannelDetailsItem label={t('channels.campaignDetails.link')}>
                <a
                  href={`https://${campaignDetails?.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: '#0645ad' }}
                >
                  {/* {campaignDetails?.url.split('/')[2] + '/...'} */}
                  <TrimString
                    paragraph={campaignDetails?.url}
                    maxLetters={30}
                  />
                </a>
                <CopyButton>
                  <FileCopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(
                        String(campaignDetails?.url)
                      );
                    }}
                    style={{ marginLeft: '10px', marginBottom: '-4px' }}
                  />
                  {/* copy */}
                </CopyButton>
              </ChannelDetailsItem>
            )}

            {campaignDetails && campaignDetails.furtherInstructions && (
              <ChannelDetailsItem
                label={t('channels.campaignDetails.furtherInstructions')}
              >
                {renderListContent(campaignDetails)}
              </ChannelDetailsItem>
            )}
            {campaignDetails && campaignDetails.hashtags.length > 0 && (
              <ChannelDetailsItem
                hashtags
                label={t('channels.campaignDetails.keywords')}
              >
                <>
                  {campaignDetails.hashtags.length > 0
                    ? campaignDetails.hashtags.map((value, index) => (
                        <p
                          style={{
                            color: '#5A1161',
                          }}
                          key={index}
                        >
                          #{value}
                        </p>
                      ))
                    : '-'}
                </>
              </ChannelDetailsItem>
            )}
            <ChannelDetailsItem
              label={t('channels.campaignDetails.expectation')}
            >
              <Magnifier
                src={require(`./../../../../../../_Styles/images/GIF/BrandedGif.gif`)}
              />
            </ChannelDetailsItem>

            <ChannelDetailsItem label={t('channels.campaignDetails.postDue')}>
              <span style={{ fontSize: '1rem' }}>
                ({remainingDays(campaignDetails?.scheduledDate)}){' '}
              </span>
              {formatDate(campaignDetails?.scheduledDate)}
            </ChannelDetailsItem>

            <ChannelDetailsItem
              label={t('channels.campaignDetails.analyticsPosted')}
            >
              {campaignDetails?.analyticsDue &&
                moment(campaignDetails.analyticsDue).format('MMM DD, YYYY')}
            </ChannelDetailsItem>
          </>
        )}
      </Body>
    </>
  );
};
