import styled from '@emotion/styled';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IThemed } from '_Styles/types';
import React, { FC, useRef } from 'react';
import { ICommonProps } from '_Types/props';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { ReactComponent as CalendarIcon } from './Calendar.svg';
import { ReactComponent as ScheduleCalendarIcon } from './CalendarIcon.svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: relative;
  && > div {
    margin: 0;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 2rem;
  top: 25%;
  cursor: pointer;
`;

interface IPlxDatePicker extends ICommonProps {
  name?: string;
  schedule?: boolean;
  disableToolbar?: boolean;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  inputVariant?: 'standard' | 'outlined' | 'filled';
  variant: 'dialog' | 'inline' | 'static';
  format?: string;
  margin?: 'none' | 'dense' | 'normal';
  placeholder?: string;
  value: ParsableDate;
  onChange: (date: Date | null) => void;
  fullwidth?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
}

const StyledDatePicker = styled(DatePicker)<IThemed>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;

  input {
    padding: 10.5px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    color: #231f20;
  }
  fieldset {
    border: none;
  }
`;

export const PlxDatePicker: FC<IPlxDatePicker> = ({
  schedule,
  value,
  minDate,
  maxDate,
  className,
  name,
  children,
  disableToolbar,
  format,
  inputVariant,
  margin,
  onChange,
  placeholder,
  variant,
  fullwidth,
  disableFuture = false,
  disablePast,
  disabled,
}) => {
  const openPopup = () => {
    inputRef.current && inputRef.current.click();
  };
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper className={className}>
        <StyledDatePicker
          disablePast={disablePast}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          minDateMessage={t('spectrum.scheduling.startDateError')}
          name={name}
          disableToolbar={disableToolbar}
          inputVariant={inputVariant}
          variant={variant}
          format={format}
          margin={margin}
          fullWidth={fullwidth}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoOk={true}
          InputProps={{
            ref: inputRef,
          }}
          disableFuture={disableFuture}
        />
        <IconWrapper onClick={openPopup}>
          {schedule ? <ScheduleCalendarIcon /> : <CalendarIcon />}
        </IconWrapper>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};
