import { Input } from '@material-ui/core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ISectionProps } from '_Routes/Home/_Components/Sections/types';
import { StyledInput, useStyles } from '_Routes/Home/_routes/Spectrum/utils';

interface IHashtagInputProps extends ISectionProps {
  label: string;
  placeholder: string;
}

export const HashtagInput: FC<IHashtagInputProps> = ({
  value,
  handleChange,
  fieldName,
  label,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    value && setInputValue(value);
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    if (handleChange) handleChange(event);
  };

  return (
    <StyledInput>
      <Input
        disableUnderline
        name={fieldName}
        value={inputValue}
        fullWidth={false}
        placeholder={`# ${label}`}
        className={classes.input}
        onChange={onChange}
      />
    </StyledInput>
  );
};
