import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PayViaWireTransfer } from '../../../../_Components/Payments/PayViaWireTransfer/PayViaWireTransfer';
import { getThriveDraft } from '_State/Thrive/Api/thrive.api';

export const ThrivePayViaWire: FC = () => {
  const [amount, setAmount] = useState<string>('');
  const { t } = useTranslation();
  const PAYMENT_BACKPATH = '/home/thrive-campaign/payment';
  const PAYMENT_NOTIFICATION = 'thrive.payment.notification';

  useEffect(() => {
    getThriveDraft().then((v) => {
      setAmount(v.payment.amount.toString());
    });
  }, []);

  return (
    <PayViaWireTransfer
      amount={amount}
      backPath={PAYMENT_BACKPATH}
      notification={t(PAYMENT_NOTIFICATION)}
    />
  );
};
