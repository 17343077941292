import React, { FC } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import { InputContainer, ButtonWrapper, CustomErrMess } from './StyleDP';
import { MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { RegistrationForm } from './FinfluencerRegForm';
import * as Yup from 'yup';

enum Region {
  NorthAmerica = 'North America',
  SouthAmerica = 'South America',
  Africa = 'Africa',
  Asia = 'Asia',
  Australia = 'Australia',
  Europe = 'Europe',
}

const useStyles = makeStyles((theme) => ({
  input: {
    maxWidth: '32rem',
    borderRadius: '30px',
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#000000',
      borderRadius: '30px',
    },
  },
}));

const stepOneValidation = Yup.object({
  firstName: Yup.string().required('* required').label('First Name'),
  lastName: Yup.string().required('* required').label('Last Name'),
  businessName: Yup.string().label('Business Name'),
  phone: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Phone number must contain only numbers.')
    .min(10, 'Phone number must be at least 10 digits.')
    .label('Phone Number'),
  region: Yup.string().required('* required').label('Region'),
});

export const FinfluencerRegFormStepOne: FC<{
  data: RegistrationForm;
  next: (val: RegistrationForm) => void;
  apiError: any;
}> = ({ data, next }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={data}
      onSubmit={(val) => next(val)}
      validationSchema={stepOneValidation}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <InputContainer>
            <TextField
              name="firstName"
              label={t('auth.register.firstName')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.firstName}
            />
            <ErrorMessage
              name="firstName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <TextField
              name="lastName"
              label={t('auth.register.lastName')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.lastName}
              // className={errors.firstName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="lastName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />

            <TextField
              name="businessName"
              label={t('auth.register.businessName')}
              placeholder="optional"
              variant="outlined"
              onChange={handleChange}
              value={values.businessName}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="businessName"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />

            <TextField
              name="phone"
              label={t('auth.register.phone')}
              placeholder="required"
              variant="outlined"
              onChange={handleChange}
              value={values.phone}
              // className={err.fullName ? 'plx-input error' : 'plx-input'}
            />
            <ErrorMessage
              name="phone"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />
            <Select
              name="region"
              value={values.region}
              onChange={handleChange}
              className={classes.input}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value="" disabled>
                <div style={{ color: 'grey' }}>
                  {t('auth.register.region')} *
                </div>
              </MenuItem>
              {Object.values(Region).map((region) => (
                <MenuItem
                  style={{ color: '#000000' }}
                  key={region}
                  value={region}
                >
                  {region}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage
              name="region"
              render={(msg) => <CustomErrMess>{msg}</CustomErrMess>}
            />

            <ButtonWrapper>
              <PlxButtonX
                // isDisabled={errors}
                inputType="submit"
                label={t('auth.register.next')}
                testId="submit-button"
              />
            </ButtonWrapper>
          </InputContainer>
        </Form>
      )}
    </Formik>
  );
};
