import React from 'react';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import CreditCardIcon from '../../../../_Styles/images/credit_Card.png';
import { ReactComponent as RealEstateIcon } from '../../../../_Styles/images/_Icons/RealEstate.svg';
import { ReactComponent as HealthIcon } from '../../../../_Styles/images/_Icons/Health.svg';
// import { ReactComponent as Interac } from '../../../../_Styles/images/interac.svg';
// import { ReactComponent as GooglePay } from '../../../../_Styles/images/google-pay.svg';
// import { ReactComponent as ApplePay } from '../../../../_Styles/images/ApplePay.svg';
import { USAIcon } from '_Styles/images/_Icons/USA';
import { CanadaIcon } from '_Styles/images/_Icons/Canada';
import { EuropeIcon } from '_Styles/images/_Icons/Europe';
import { ISliderInfoProps } from '_Routes/Home/_Components/Sections/ReachSection/SliderInfo';
import {
  IPackageControlConfig,
  PricingType,
} from '_Routes/Home/_Components/Payments/PackageControl/PackageControl.types';
import { GlobalIcon } from '../../../../_Styles/images/_Icons/Global';

export enum Industries {
  BUSINESS,
  EDUCATION,
  ENERGY,
  FINANCE,
  MANUFACTURE,
  MARKETING,
  MEDIA,
  RESOURCE,
  TELE,
  CANNABIS,
  ESPORTS,
  MINING,
  TECH,
  REAL_ESTATE,
  HEALTH,
  CRYPTO_FOREX,
  TECHNOLOGY,
  GENERAL_BUSINESS,
  INVESTING_TRADING,
  ECONOMICS,
}

export enum GeoLocation {
  GLOBAL,
  USA,
  CANADA,
  EU,
}

export enum Payment {
  CREDIT_CARD,
  WIRE_TRANSFER,
  CHECK,
}

export enum PaymentPackage {
  _1500 = '1500',
  _2000 = '2000',
  _725 = '725',
}

export const contentTypeConfig: IIconButtonConfig[] = [
  {
    id: 'pressRelease',
    label: 'spectrum.campaign.pressRelease',
    value: 'Press Release',
    isChosen: false,
  },
  {
    id: 'mediaCoverage',
    label: 'spectrum.campaign.mediaCoverage',
    value: 'Media Coverage',
    isChosen: false,
  },
];

export const contentCategoryConfig: IIconButtonConfig[] = [
  {
    id: 'pressRelease',
    label: 'spectrum.campaign.pressRelease',
    value: 'Press Release',
    isChosen: false,
  },
  {
    id: 'annualReport',
    label: 'spectrum.campaign.annualReport',
    value: 'Annual Report',
    isChosen: false,
  },
  {
    id: 'corporateProfile',
    label: 'spectrum.campaign.corporateProfile',
    value: 'Corporate Profile',
    isChosen: false,
  },
  {
    id: 'earningsCall',
    label: 'spectrum.campaign.earningsCall',
    value: 'Earnings Call',
    isChosen: false,
  },
  {
    id: 'event',
    label: 'spectrum.campaign.event',
    value: 'Event',
    isChosen: false,
  },
  {
    id: 'hiring',
    label: 'spectrum.campaign.hiring',
    value: 'Hiring',
    isChosen: false,
  },
  {
    id: 'operations',
    label: 'spectrum.campaign.operations',
    value: 'Operations',
    isChosen: false,
  },
  {
    id: 'partnership',
    label: 'spectrum.campaign.partnership',
    value: 'Partnership',
    isChosen: false,
  },
  {
    id: 'sales',
    label: 'spectrum.campaign.sales',
    value: 'Sales',
    isChosen: false,
  },
];

export const sliderInfoConfig = (
  t: (key: string) => string,
  estimatedReach?: number,
  numberOfGroups?: number,
  numberOfNewSubmissions?: number
): ISliderInfoProps[] => {
  return [
    {
      label: t('spectrum.program.estimatedReach'),
      tooltipText: t('spectrum.program.estimatedReachTooltip'),
      value: estimatedReach ? estimatedReach.toLocaleString() : '0',
    },
    {
      label: t('spectrum.program.numberOfGroups'),
      tooltipText: t('spectrum.program.numberOfGroupsTooltip'),
      value: numberOfGroups ? numberOfGroups.toLocaleString() : '0',
    },
    {
      label: t('spectrum.program.numberOfNewSubmissions'),
      tooltipText: t('spectrum.program.numberOfNewSubmissionsTooltip'),
      value: numberOfNewSubmissions
        ? numberOfNewSubmissions.toLocaleString()
        : '0',
    },
  ];
};

export const industryConfig: IIconButtonConfig[] = [
  {
    id: 'business',
    label: 'spectrum.program.business',
    icon: <></>,
    value: Industries[Industries.BUSINESS],
    isChosen: false,
  },
  {
    id: 'education',
    label: 'spectrum.program.education',
    icon: <></>,
    value: Industries[Industries.EDUCATION],
    isChosen: false,
  },
  {
    id: 'realEstate',
    label: 'spectrum.program.realEstate',
    icon: <RealEstateIcon />,
    value: Industries[Industries.REAL_ESTATE],
    isChosen: false,
  },
  {
    id: 'tech',
    label: 'spectrum.program.tech',
    icon: <RealEstateIcon />,
    value: Industries[Industries.TECH],
    isChosen: false,
  },
  {
    id: 'manufacture',
    label: 'spectrum.program.manufacture',
    icon: <></>,
    value: Industries[Industries.MANUFACTURE],
    isChosen: false,
  },
  {
    id: 'energy',
    label: 'spectrum.program.energy',
    icon: <></>,
    value: Industries[Industries.ENERGY],
    isChosen: false,
  },
  {
    id: 'finance',
    label: 'spectrum.program.finance',
    icon: <></>,
    value: Industries[Industries.FINANCE],
    isChosen: false,
  },
  {
    id: 'marketing',
    label: 'spectrum.program.marketing',
    icon: <></>,
    value: Industries[Industries.MARKETING],
    isChosen: false,
  },
  {
    id: 'media',
    label: 'spectrum.program.media',
    icon: <></>,
    value: Industries[Industries.MEDIA],
    isChosen: false,
  },
  {
    id: 'resource',
    label: 'spectrum.program.resource',
    icon: <></>,
    value: Industries[Industries.RESOURCE],
    isChosen: false,
  },
  {
    id: 'health',
    label: 'spectrum.program.health',
    icon: <HealthIcon />,
    value: Industries[Industries.HEALTH],
    isChosen: false,
  },
  {
    id: 'tele',
    label: 'spectrum.program.tele',
    icon: <></>,
    value: Industries[Industries.TELE],
    isChosen: false,
  },
];

export const geoConfig: IIconButtonConfig[] = [
  {
    id: 'global',
    label: 'spectrum.program.global',
    icon: <GlobalIcon />,
    value: GeoLocation[GeoLocation.GLOBAL],
    isChosen: false,
  },
  {
    id: 'usa',
    label: 'spectrum.program.usa',
    icon: <USAIcon />,
    value: GeoLocation[GeoLocation.USA],
    isChosen: false,
  },
  {
    id: 'canada',
    label: 'spectrum.program.canada',
    icon: <CanadaIcon />,
    value: GeoLocation[GeoLocation.CANADA],
    isChosen: false,
  },
  {
    id: 'europe',
    label: 'spectrum.program.europe',
    icon: <EuropeIcon />,
    value: GeoLocation[GeoLocation.EU],
    isChosen: false,
  },
];

export const packageConfig: IPackageControlConfig[] = [
  {
    type: PricingType.SEMI_ANNUALLY,
    value: PaymentPackage._2000,
    vertical: false,
    isChosen: false,
    option: {
      header: {
        title: '$2000',
      },
      content: [
        {
          name: 'spectrum.payment.estimatedReach',
          value: '97,500',
        },
        {
          name: 'spectrum.payment.contributors',
          value: '5',
        },
        {
          name: 'spectrum.payment.numberOfNewsSubmissions',
          value: '12',
        },
      ],
    },
  },
  {
    type: PricingType.MONTHLY,
    value: PaymentPackage._725,
    vertical: false,
    isChosen: false,
    option: {
      header: {
        title: 'spectrum.payment.725Month',
      },
      content: [
        {
          name: 'spectrum.payment.estimatedReach',
          value: '97,500',
        },
        {
          name: 'spectrum.payment.contributors',
          value: '5',
        },
        {
          name: 'spectrum.payment.numberOfNewsSubmissions',
          value: '12',
        },
      ],
    },
  },
];

export const paymentOptionConfig: IIconButtonConfig[] = [
  {
    id: 'CREDIT_CARD',
    label: 'spectrum.payment.creditCard',
    icon: <img src={CreditCardIcon} height="20px" alt="Credit Card" />,
    value: Payment[Payment.CREDIT_CARD],
    isChosen: false,
  },
  // {
  //   id: 'INTERAC_PAY',
  //   label: 'spectrum.payment.interac',
  //   icon: <Interac />,
  //   value: Payment[Payment.WIRE_TRANSFER],
  //   isChosen: false,
  //   // disabled: true,
  // },
  // {
  //   id: 'GOOGLE_PAY',
  //   label: '',
  //   icon: <GooglePay />,
  //   value: Payment[Payment.CHECK],
  //   isChosen: false,
  //   // disabled: true,
  // },
  // {
  //   id: 'APPLE_PAY',
  //   label: '',
  //   icon: <ApplePay />,
  //   value: Payment[Payment.CHECK],
  //   isChosen: false,
  //   // disabled: true,
  // },
];
