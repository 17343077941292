import styled from '@emotion/styled';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { IAdminChannelQueryParams } from '_State/DistributionPartner/distributionPartner.types';
import React, { FC } from 'react';

const StyledContainer = styled.div`
  display: flex;
  box-sizing: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
`;
const StyledButton = styled.button`
  display: inline-block;
  box-sizing: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 3px;
  border: 0;

  color: rgba(0, 0, 0, 0.54);
  background: transparent;
`;

const StyledSpan = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  height: 19px;
  widht: 19px;
  border-radius: 50%;
  &:hover {
    background: #e0e0e0;
    cursor: pointer;
  }
`;

interface IProps {
  onSort: (value: string, value2: string) => void;
  params: IAdminChannelQueryParams;
  field: string;
  title: string;
}

export const CustomHeader: FC<IProps> = ({ onSort, params, field, title }) => {
  const getValues = () => {
    let fieldName = '';
    let direction = '';
    if (params.sortByField === 'createdDate') {
      fieldName = field;
      direction = 'ASC';
    }
    if (params.sortByField === field && params.sortDirection === 'ASC') {
      fieldName = field;
      direction = 'DESC';
    }
    if (params.sortByField === field && params.sortDirection === 'DESC') {
      direction = 'DESC';
      fieldName = 'createdDate';
    }
    if (params.sortByField !== 'createdDate' && params.sortByField !== field) {
      fieldName = field;
      direction = 'ASC';
    }
    return { fieldName, direction };
  };
  const values = getValues();

  return (
    <div
      onClick={() => {
        onSort(values.fieldName, values.direction);
      }}
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontWeight: 500,
        }}
      >
        {title}
      </div>
      <StyledContainer
        style={{ display: params.sortByField !== field ? 'none' : undefined }}
      >
        <StyledButton
          onClick={() => {
            console.log('button clicked');
          }}
        >
          <StyledSpan>
            {params.sortByField === field ? (
              params.sortDirection === 'ASC' ? (
                <ArrowUpward style={{ height: 18, width: 18 }} />
              ) : (
                <ArrowDownward style={{ height: 18, width: 18 }} />
              )
            ) : null}
          </StyledSpan>
        </StyledButton>
      </StyledContainer>
    </div>
  );
};
