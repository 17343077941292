import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { IIconButtonConfig } from '_Types/IIconButtonConfig';
import styled from '@emotion/styled';
import { PlxIconButton } from '_Components/Buttons/PlxIconButton/PlxIconButton';
// import { IThemed } from '_Styles/types';
import { PlxInput } from '_Components/PlxInput/PlxInput';
import { useTranslation } from 'react-i18next';
import { InputBase, TextFieldProps } from '@material-ui/core';
import { ICommonProps } from '_Types/props';
import { SearchIcon } from '_Routes/Home/NavigationPanel/Icons/SearchIcon';
import { PlxButtonMenu } from '../PlxButton/PlxButtonMenu';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  background: '#fff',
  border: '1px solid rgba(35, 31, 32, 0.16)',
  borderRadius: '6px',
  marginBottom: '1.5rem',
  paddingLeft: '2rem',
  width: '55%',
  height: '40px',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '0 1rem 1rem 1rem',
    width: '100%',
  },
}));

const IconContainer = styled.span`
  opacity: 0.43;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // svg {
  //   // height: 25px;
  // }
  // path {
  //   // stroke: #545454;
  //   // stroke-width: 1px;
  //   // fill: none;
  // }
`;
const ButtonWrapper = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -1px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  flex-direction: ${(props) => (props.horizontal ? 'column' : 'row')};
`;
// const InputTitle = styled.div<IThemed>`
//   color: ${({ theme }) => theme.palette.primary.contrastText};
//   font-size: 1.8rem;
//   margin: ${({ theme }) => theme.spacing(1, 0.5)};
// `;

const AdditionalInput = styled.div`
  margin-top: 1.5px;
  width: 148px;

  > div > div > div {
    border: none;
  }
  fieldset {
    border-color: #fff;
  }
  input {
    padding: 18px 0 18px 14px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #9d29af;
  }
`;
const NewAdditionalInput = styled.div`
  width: 200px;
  margin: 0 1rem 1rem 0;

  > div > div > div {
    border: none;
  }
  fieldset {
    border-color: #000;
  }
  input {
    padding: 7px 0 7px 14px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #9d29af;
  }
`;

const ButtonContainer = styled.div<{ horizontal?: boolean }>`
  margin: ${(props) => (props.horizontal ? '0 0 0.5rem 0' : '')};
  margin-left: -1px;
`;
const NewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NewButtonWrapper = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'column' : 'row')};
`;
export const NewButtonContainer = styled.div<{ horizontal?: boolean }>`
  margin: ${(props) => (props.horizontal ? 0 : '')};
  display: flex;
`;

interface IProps extends ICommonProps {
  value?: string[];
  options: IIconButtonConfig[];
  search?: boolean;
  newDesign?: boolean;
  multiple?: boolean;
  onSelect: (values: string[]) => void;
  otherOption?: boolean;
  inputTitle?: string;
  inputConfig?: TextFieldProps;
  horizontal?: boolean;
  translateY?: number;
  searchPlaceholder?: string;
}

export const PlxButtonGroup: FC<IProps> = ({
  value,
  options,
  search,
  newDesign,
  multiple = false,
  onSelect,
  otherOption,
  inputConfig,
  className,
  horizontal,
  translateY,
  searchPlaceholder,
}) => {
  const [buttons, setButtons] = useState<IIconButtonConfig[]>(options);
  const { t } = useTranslation();
  const [otherPicked, setOtherPicked] = useState(false);
  const [otherButtonValue, setOtherButtonValue] = useState<string>('');
  const [seacrhValue, setSearchValue] = useState<string>('');
  const otherButton: IIconButtonConfig = {
    id: 'other',
    label: t('spectrum.program.other'),
    isChosen: false,
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearchValue(value);
  };

  const filteredButtons = buttons.filter((item) => {
    return item.label.toLowerCase().includes(seacrhValue.toLowerCase());
  });

  const sortedButtons = filteredButtons.sort(function (a, b) {
    if (a.isChosen && a.label === t('spectrum.program.other')) {
      return -1;
    } else if (a.isChosen && !b.isChosen) {
      return -1;
    } else if (!a.isChosen && a.label === 'other') {
      return 1;
    } else if (!a.isChosen && b.isChosen) {
      return 1;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (value) {
      options.forEach((o) => {
        value.forEach((v) => {
          if (o.value?.toUpperCase() === v.toUpperCase()) {
            o.isChosen = true;
          }
        });
      });
      if (otherOption) {
        let otherVal = value.filter(
          (v) => !options.map((o) => o.value).includes(v)
        )[0];
        if (otherVal) {
          if (otherVal !== 'null') {
            setOtherPicked(true);
            otherButton.isChosen = true;
            otherButton.value = otherVal;
            setOtherButtonValue(otherVal);
          }
        }
      }
    }

    if (
      otherOption &&
      options.filter((o) => o.id === otherButton.id).length < 1
    ) {
      options.push(otherButton);
    }
    setButtons([...options]);
    return () => {};
    // eslint-disable-next-line
  }, [value, otherOption]);

  const handleClick = (id: string) => {
    const option = buttons.filter((option) => option.id === id)[0];
    option.isChosen = !option.isChosen;
    if (!multiple) {
      buttons
        .filter((b) => b.id !== option.id)
        .forEach((o) => (o.isChosen = false));
    }
    setButtons([...buttons]);

    if (id === otherButton.id) {
      setOtherPicked(option.isChosen);
      onSelect(getSelectedOptions());
    } else {
      if (!multiple) {
        setOtherPicked(false);
      }
      onSelect(getSelectedOptions());
    }
  };

  const handleOtherChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setOtherButtonValue(value);
    buttons.filter(
      (o) => o.isChosen && o.id === otherButton.id
    )[0].value = value;
    onSelect(getSelectedOptions());
  };

  const getSelectedOptions = (): string[] => {
    return buttons
      .filter((option) => option.isChosen)
      .map((o) => o.value as string);
  };

  return newDesign ? (
    <NewWrapper className={className}>
      {search && (
        <Search>
          <IconContainer>
            <SearchIcon />
          </IconContainer>
          <StyledInputBase
            value={seacrhValue}
            placeholder={searchPlaceholder}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
          />
        </Search>
      )}
      <NewButtonWrapper
        className={'PlxButtonGroup-container'}
        horizontal={horizontal}
      >
        {sortedButtons.slice(0, 5).map((button) => {
          return (
            !button.disabled && (
              <NewButtonContainer
                horizontal={horizontal}
                className={'PlxButtonGroup-button-container'}
                key={button.id}
              >
                <PlxIconButton
                  newDesign={true}
                  horizontal={horizontal}
                  id={button.id}
                  isChosen={button.isChosen}
                  icon={button.icon}
                  disabled={button.disabled}
                  label={button.label}
                  onClick={handleClick}
                  other={button.id === otherButton.id}
                />
                {otherPicked && button.id === otherButton.id && (
                  <NewAdditionalInput>
                    <PlxInput
                      value={otherButtonValue}
                      onChange={handleOtherChange}
                      placeholder={inputConfig?.placeholder}
                      fullWidth={inputConfig?.fullWidth}
                    />
                  </NewAdditionalInput>
                )}
              </NewButtonContainer>
            )
          );
        })}
        {sortedButtons.slice(5, sortedButtons.length).length > 0 && (
          <PlxButtonMenu
            translateY={translateY}
            buttons={sortedButtons}
            onClick={handleClick}
          />
        )}
      </NewButtonWrapper>
    </NewWrapper>
  ) : (
    <Wrapper className={className}>
      <ButtonWrapper
        className={'PlxButtonGroup-container'}
        horizontal={horizontal}
      >
        {buttons.map((button) => {
          return (
            !button.disabled && (
              <ButtonContainer
                horizontal={horizontal}
                className={'PlxButtonGroup-button-container'}
                key={button.id}
              >
                <PlxIconButton
                  horizontal={horizontal}
                  id={button.id}
                  isChosen={button.isChosen}
                  icon={button.icon}
                  disabled={button.disabled}
                  label={button.label}
                  onClick={handleClick}
                  other={button.id === otherButton.id}
                />
              </ButtonContainer>
            )
          );
        })}
        {otherPicked && (
          <AdditionalInput>
            <PlxInput
              value={otherButtonValue}
              onChange={handleOtherChange}
              placeholder={inputConfig?.placeholder}
              fullWidth={inputConfig?.fullWidth}
            />
          </AdditionalInput>
        )}
      </ButtonWrapper>
      {/* {otherPicked && (
        <AdditionalInput>
          <InputTitle>
            {inputTitle}
            <Req>*</Req>
          </InputTitle>
          <PlxInput
            value={otherButtonValue}
            onChange={handleOtherChange}
            placeholder={inputConfig?.placeholder}
            fullWidth={inputConfig?.fullWidth}
          />
        </AdditionalInput>
      )} */}
    </Wrapper>
  );
};
