import { Input } from '@material-ui/core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { PlxFormSection } from '_Components/FormSection/FormSection';
import { StyledInput, useStyles } from '_Routes/Home/_routes/Spectrum/utils';

interface IProps {
  placeholder?: string;
  fieldname?: string;
  inputTitle?: string;
  fullWidth?: boolean;
  onChoose?: (options: string[]) => void;
  title: string;
  otherOption?: boolean;
  tooltip?: string;
  value?: string;
  setValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange?: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  fieldName?: string;
  required?: boolean;
  horizontal?: boolean;
  testId?: string;
  search?: boolean;
}

export const UrlSection: FC<IProps> = ({
  value,
  handleChange,
  fieldName,
  placeholder,
  title,
  tooltip,
  required,
}) => {
  const [inputValue, setInputValue] = useState<unknown>('');
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    setInputValue(value);
    if (handleChange) {
      handleChange(event);
    }
  };

  return (
    <PlxFormSection title={title} tooltip={tooltip} required={required}>
      <StyledInput>
        <Input
          className={classes.input}
          disableUnderline
          value={inputValue}
          onChange={onChange}
          placeholder={placeholder}
          name={fieldName}
        />
      </StyledInput>
    </PlxFormSection>
  );
};
