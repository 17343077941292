import React, { ChangeEvent, FC } from 'react';
import styled from '@emotion/styled';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { createStyles, makeStyles } from '@material-ui/core';

const Wrapper = styled.div``;
const OutlinedTextField = styled(OutlinedInput)`
  margin: 8px;
  .MuiOutlinedInput-input {
    padding: 8px 16px;
    border-radius: 16px;
  }
`;

interface IProps {
  text: string;
  onSearch: (term: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 6,
      border: '1px solid #5F5F61',
      borderRadius: '16px',
      padding: 0,
    },
  })
);

export const FilterSearch: FC<IProps> = ({ text, onSearch }) => {
  const classes = useStyles();
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onSearch(e.target.value);
  };

  return (
    <Wrapper>
      <OutlinedTextField
        classes={{ root: classes.root }}
        id="mui-theme-provider-standard-input"
        value={text}
        onChange={onChange}
      />
    </Wrapper>
  );
};
