import styled from '@emotion/styled';

export const StyledToggleIcon = styled.span`
  margin-left: 1rem;
  vertical-align: middle;
`;
export const StyledBackground = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

export const StyledButton = styled.span<{ isOpen: boolean }>`
  font-family: 'Quicksand', sans-serif;
  border-radius: 2rem;
  font-size: 14px;
  margin-right: 0.5rem;
  border: 1px solid #5f5f61;
  padding: 0.625rem 1rem;
  align-items: center;
  background-color: ${({ isOpen }): string => (isOpen ? '#afd7dd' : 'white')};
  cursor: pointer;
  &:hover {
    background-color: #afd7dd;
  }
`;
