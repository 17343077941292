import { spectrumApi } from '_Api/api';
import {
  IUrlForm,
  IAnalyticsForm,
  ICampaignDetailsDTO,
  IDistributionPartnerAddChannelForm,
  IDistributionPartnerCampaignsQueryParams,
  IDistributionPartnerChannelDTO,
  IDistributionPartnerChannelStatistics,
  IDistributionPartnerCompleteProfileDTO,
  IDistributionPartnerCompleteProfileForm,
  IDistributionPartnerOfferPlacedDTO,
  IDistributionPartnerOpportunityDTO,
  IFinfluencerSubscriptionForm,
  IUrlDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { IFinfluencerContactUs } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerContactUs/FinfluencerContactUsForm';
import { IUploadFileDto } from '_State/Thrive/types';
import { IPostSampleAttachments } from '_State/Spectrum/types';
export const getDistributionPartnerProfileInfo = (): Promise<
  IDistributionPartnerCompleteProfileForm
> => {
  return spectrumApi.get(`spectrum-campaigns/distribution-partner`).json();
};
export const sendVerificationCode = (): Promise<any> => {
  return spectrumApi.get(`users/distribution-partners/verify`);
};
export const updateFinfluencerPhoneStatus = (n: number): Promise<any> => {
  return spectrumApi.get(`users/distribution-partners/verify/${n}`).json();
};
const mapFormToDTO = (
  form: IDistributionPartnerCompleteProfileForm
): IDistributionPartnerCompleteProfileDTO => {
  return {
    firstName: form.firstName,
    lastName: form.lastName,
    phoneNumber: form.phoneNumber,
    email: form.email || '',
    businessName: form.businessName,
  };
};

export const completeProfile = (
  form: IDistributionPartnerCompleteProfileForm
): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/distribution-partner`, {
      json: mapFormToDTO(form),
    })
    .json();
};

export const updateProfile = (
  form: IDistributionPartnerCompleteProfileForm
): Promise<any> => {
  return spectrumApi
    .put(`spectrum-campaigns/distribution-partner`, {
      json: mapFormToDTO(form),
    })
    .json();
};

export const getChannels = (params: any): Promise<any> => {
  const url = `spectrum-campaigns/channel/${params.channelId}`;

  return spectrumApi.get(url).json();
};

export const getNotifications = (): Promise<any> => {
  const url = `client/notifications`;

  return spectrumApi.get(url).json();
};

export const clearNotifications = (): Promise<any> => {
  const url = `client/clear-notifications`;

  return spectrumApi.post(url).json();
};

export const getOpportunities = (
  params?: IDistributionPartnerCampaignsQueryParams
): Promise<IDistributionPartnerOpportunityDTO[]> => {
  let url = `spectrum-campaigns/opportunities`;
  const urlParams = new URLSearchParams();
  if (params?.sortDirection) {
    urlParams.append('sortDirection', params.sortDirection);
  }
  if (params?.statusList && params.statusList.length > 0) {
    urlParams.append('opportunityStatusList', params.statusList.join(','));
  }
  url += `?${urlParams}`;
  return spectrumApi.get(url).json();
};

export const getNewOpportunities = (): Promise<
  IDistributionPartnerOpportunityDTO[]
> => {
  return spectrumApi.get('spectrum-campaigns/new-opportunities').json();
};

export const getNumberOfNewOpportunities = (): Promise<any> => {
  return spectrumApi
    .get('spectrum-campaigns/new-opportunities/quantity')
    .json();
};

export const acceptOpportunity = (opportunityId: number): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/opportunity/${opportunityId}/accept`)
    .json();
};

export const getRejectedOpportunity = (): Promise<
  IDistributionPartnerOpportunityDTO[]
> => {
  return spectrumApi
    .get(`spectrum-campaigns/opportunities?opportunityStatusList=REJECTED`)
    .json();
};

export const rejectOpportunity = (
  opportunityId: number,
  reason: string
): Promise<any> => {
  return spectrumApi
    .post(
      `spectrum-campaigns/opportunity/${opportunityId}/reject?reason=${reason}`
    )
    .json();
};

export const createChannel = (
  form: IDistributionPartnerAddChannelForm
): Promise<any> => {
  return spectrumApi.post('spectrum-campaigns/channel', { json: form }).json();
};

export const getOfferPlaced = (
  id?: string
): Promise<IDistributionPartnerOfferPlacedDTO> => {
  return spectrumApi
    .get(`spectrum-campaigns/channel/offer-placed/${id}`)
    .json();
};

export const acceptOffer = (channelId?: string): Promise<any> => {
  return spectrumApi
    .post(`spectrum-campaigns/channel/${channelId}/accept`)
    .json();
};

export const getCampaignDetails = (
  campaignId?: number,
  opportunityId?: number
): Promise<ICampaignDetailsDTO> => {
  return spectrumApi
    .get(
      `spectrum-campaigns/opportunity/${opportunityId}/campaign-details/${campaignId}`
    )
    .json();
};

export const getUserChannels = (): Promise<
  IDistributionPartnerChannelDTO[]
> => {
  const url = `spectrum-campaigns/channels`;

  return spectrumApi.get(url).json();
};

export const getUserDocuments = (): Promise<
  IDistributionPartnerChannelDTO[]
> => {
  const url = `spectrum-campaigns/documents`;

  return spectrumApi.get(url).json();
};

export const getUrl = (): Promise<IUrlDTO[]> => {
  const url = `spectrum-campaigns/opportunities?`;

  return spectrumApi.get(url).json();
};

export const submitUrl = (form: IUrlForm, id?: number): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${id}/submit-post-url`;

  const UrlDTO = {
    id: form.id,
    channelName: form.channelName,
    channelType: form.channelType,
    offer: form.offer,
    status: form.status,
    campaignId: form.campaignId,
    seen: form.seen,
    analyticsDue: form.analyticsDue,
    postDue: form.postDue,
    reactions: form.reactions,
    shares: form.shares,
    comments: form.comments,
    numberOfFollowers: form.numberOfFollowers,
    impressions: form.impressions,
    videoSaves: form.videoSaves,
    postUrl: form.postUrl,
  };
  return spectrumApi.post(url, { json: UrlDTO }).json();
};

export const adminUpdateUrl = (form: IUrlForm, id?: number): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${id}/update-post-url`;

  const UrlDTO = {
    postUrl: form.postUrl,
  };
  return spectrumApi.put(url, { json: UrlDTO }).json();
};

export const submitAnalytics = (
  form: IAnalyticsForm,
  id?: number
): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${id}/submit-analytics`;

  const attachments =
    (form.postSample as IUploadFileDto | null)?.attachments?.map(
      (item: any) => ({
        fileName: item.fileName,
        base64Content: item.base64Content,
      })
    ) || [];

  const analyticsDTO = {
    reactions: form.reactions,
    shares: form.shares,
    comments: form.comments,
    numberOfFollowers: form.numberOfFollowers,
    datePosted: form.datePosted,
    impressions: form.impressions,
    videoSaves: form.videoSaves,
    postSample: {
      originalPostUrl: form.postUrl,
      attachments: attachments,
    },
  };

  return spectrumApi.post(url, { json: analyticsDTO }).json();
};

export const updateAnalytics = (
  form: IAnalyticsForm,
  postSampleId: number,
  id?: number
): Promise<any> => {
  const url = `spectrum-campaigns/opportunity/${id}/update-analytics`;

  const attachments = (form.postSample as IUploadFileDto | null)?.attachments?.map(
    (item: IPostSampleAttachments) => {
      if (item.screenshotUrl) {
        return {
          attachmentId: item.id,
          fileName: item.fileName,
          base64Content: null,
        };
      } else if (!item.id) {
        return { fileName: item.fileName, base64Content: item.base64Content };
      } else if (item.id && item.base64Content) {
        return {
          attachmentId: item.id,
          fileName: item.fileName,
          base64Content: item.base64Content,
        };
      }
    }
  );

  const analyticsDTO = {
    reactions: form.reactions,
    shares: form.shares,
    comments: form.comments,
    numberOfFollowers: form.numberOfFollowers,
    datePosted: form.datePosted,
    impressions: form.impressions,
    videoSaves: form.videoSaves,
    postSample: {
      id: postSampleId,
      originalPostUrl: form.postUrl,
      attachments: attachments,
    },
  };

  return spectrumApi.put(url, { json: analyticsDTO }).json();
};

export const deleteChannel = (channelId?: number): Promise<any> => {
  const url = `spectrum-campaigns/channel/${channelId}`;

  return spectrumApi.delete(url).json();
};
export const updateOfferAcceptingOption = (
  dontWantOffer?: boolean,
  channelId?: number
): Promise<any> => {
  const url = `spectrum-campaigns/channel/offer/${channelId}`;
  const form = {
    dontWantOffer: dontWantOffer,
  };
  return spectrumApi.put(url, { json: form }).json();
};

export const getStatisticData = (): Promise<
  IDistributionPartnerChannelStatistics
> => {
  const url = `spectrum-campaigns/distribution-partner/dashboard-statistics`;
  return spectrumApi.get(url).json();
};
export const getNotificationData = (): Promise<any> => {
  const url = `finfluencer/dashboard-notifications`;
  return spectrumApi.get(url).json();
};

export const getReferralsData = (id: number): Promise<any> => {
  const url = `referrals?userId=${id}`;
  return spectrumApi.get(url).json();
};

export const sendMessage = (form: IFinfluencerContactUs): Promise<any> => {
  const url = `spectrum-campaigns/finfluencer/message`;
  return spectrumApi.post(url, { json: form }).json();
};

export const getSubscription = (): Promise<any> => {
  const url = 'emails/subscriptions';
  return spectrumApi.get(url).json();
};
export const updateSubscription = (
  form: IFinfluencerSubscriptionForm
): Promise<any> => {
  return spectrumApi.put(`emails/subscriptions`, { json: form }).json();
};

export const getContractUUID = (form: any): Promise<any> => {
  const url = 'documents/metadata';
  return spectrumApi.post(url, { json: form }).json();
};

export const postSignerEvents = (form: any): Promise<any> => {
  const url = 'finfluencer-signer-events';
  return spectrumApi.post(url, { json: form }).json();
};
export const getContractDoc = (): Promise<any> => {
  const url = 'contracts/finfluencer';
  return spectrumApi.get(url).json();
};

export const postTwitter = (channelId: number, code: string): Promise<any> => {
  const url = `connectors/twitter/connect?channelId=${channelId}&code=${code}`;

  return spectrumApi.post(url).json();
};
export const postTiktok = (channelId: number, code: string): Promise<any> => {
  const url = `connectors/tiktok/connect?channelId=${channelId}&code=${code}`;
  return spectrumApi.post(url).json();
};
export const postYoutube = (channelId: number, code: string): Promise<any> => {
  const url = `connectors/youtube/connect?channelId=${channelId}&code=${code}`;
  return spectrumApi.post(url).json();
};
