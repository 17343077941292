import { IFontSizes, IThemed, TextSizes } from '_Styles/types';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { Spacing } from '@material-ui/core/styles/createSpacing';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

export const themeGetter = <T>(path: string, ...args: unknown[]) => {
  return ({ theme }: IThemed) => {
    const themeProp = get(theme, path);
    if (isFunction(themeProp) && args) {
      return themeProp(...args) as T;
    } else {
      return themeProp as T;
    }
  };
};

type ThemeColors = keyof Palette;
type ColorType = keyof PaletteColor;

export const themeGetters = {
  spacing: (...spacing: number[]) =>
    themeGetter<Spacing>('spacing', ...spacing),
  color: (color: ThemeColors, type: ColorType = 'main') =>
    themeGetter<string>(`palette.${color}.${type}`),
  fontSizes: (type: keyof IFontSizes, size: TextSizes) =>
    themeGetter<string>(`fontSizes.${type}.${size}`),
};
