import React, { FC, useContext, useMemo } from 'react';
import { PlxTilesPanel } from '_Components/Panels/PlxTilesPanel/PlxTilesPanel';
import { createSpectrumStatisticsConfig } from '_App/utils';
import { DashboardContext } from '_Routes/Home/_routes/Dashboard/Context/DashboardContext';
import { ITile } from '_Types/ITile';
import { useTranslation } from 'react-i18next';
import { DashboardEmptyCampaign } from '_Routes/Home/_routes/Dashboard/DashboardEmptyCampaign/DashboardEmptyCampaign';
import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';
import { IThemed } from '_Styles/types';
import { useHistory } from 'react-router';
import moment from 'moment';
import { getSpectrumAnalyticsCSV } from '_State/Spectrum/Api/spectrum-admin.api';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const TitleCampaign = styled.h1`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

const Row = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const DashboardDivider = styled(Divider)<IThemed>`
  && {
    margin: ${({ theme }) => theme.spacing(1, 0)};
    background-color: #d5dde3;
  }
`;

const StyledButton = styled.button`
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background-color: #f8f8f8;
  cursor: pointer;
  color: #1b192c;
  border: none;
  border-radius: 6px;
  padding: 11px 16px;
  &:hover {
    background-color: #231f20;
    color: #fff;
  }
`;

const SpectrumWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DashboardSpectrum: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { spectrumCampaigns, spectrumLink, spectrumLoading } = useContext(
    DashboardContext
  );
  const { isAgencyType } = useContext(NavigationContext);
  const spectrumTilesConfig: ITile[] = useMemo(
    () =>
      createSpectrumStatisticsConfig(spectrumCampaigns).map((tile) => {
        return { ...(tile as ITile), label: t((tile as ITile).label) };
      }) as ITile[],
    [spectrumCampaigns, t]
  );

  const start = '2020-01-01';
  const end = moment(new Date()).format('yyyy-MM-DD');

  const createDownloadCSV = (data: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.innerHTML = 'Download CSV';
    document.body.appendChild(link);
    link.click();
  };

  const handleSpectrumCampaignsCSV = () => {
    getSpectrumAnalyticsCSV(start, end).then((data) => {
      createDownloadCSV(data, 'spectrum-analytics');
    });
  };

  return (
    <>
      <Row>
        <TitleCampaign>
          {t('dashboard.campaigns')} ({spectrumCampaigns?.length})
        </TitleCampaign>
        <div>
          <StyledButton onClick={handleSpectrumCampaignsCSV}>
            {t('dashboard.export')}
          </StyledButton>
          <StyledButton
            onClick={() => {
              history.push(
                isAgencyType
                  ? '/home/agency/spectrum/overview'
                  : '/home/client/spectrum/overview'
              );
            }}
            style={{ border: '1px solid #231f20', marginLeft: '1.5rem' }}
          >
            {t('dashboard.viewAll')}
          </StyledButton>
        </div>
      </Row>
      <DashboardDivider />
      {!spectrumLoading && (
        <>
          {spectrumCampaigns?.length > 0 ? (
            <SpectrumWrapper>
              <PlxTilesPanel client tiles={spectrumTilesConfig} />
            </SpectrumWrapper>
          ) : (
            <DashboardEmptyCampaign
              title={t('dashboard.emptySpectrumLabel')}
              infoLabel={t('dashboard.emptySpectrumInfo')}
              path={spectrumLink}
              pathLabel={t('dashboard.launchAProgram')}
            />
          )}
        </>
      )}
    </>
  );
};
