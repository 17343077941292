import React, { FC, useEffect, useState } from 'react';
import {
  IUrlDTO,
  IUrlForm,
} from '_State/DistributionPartner/distributionPartner.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import {
  adminUpdateUrl,
  submitUrl,
} from '_State/DistributionPartner/Api/distributionPartner.api';

interface IProps {
  updateUrl?: boolean;
  submitUrlDialogData: {
    url?: IUrlDTO;
  };
  onUrlSubmitted: () => void;
  onBack: () => void;
}

const INITIAL_VALUES: IUrlForm = {
  postUrl: '',
};

const REQUIRED_FIELD = ['postUrl'];
const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELD);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 380px;
  margin: auto 2rem;
  color: #5a3c5a;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 5.5rem;
  // margin-top: 2rem;
  margin-bottom: 2rem;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

export const SubmitUrlForm: FC<IProps> = ({
  submitUrlDialogData,
  onBack,
  updateUrl,
  onUrlSubmitted,
}) => {
  const { t } = useTranslation();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELD,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (submittedForm) => {
      const form = submittedForm;
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        updateUrl
          ? adminUpdateUrl(form, submitUrlDialogData.url?.id).then(
              () => {
                setIsProcessing(false);
                onBack();
                window.location.reload();
              },
              (error: any) => {
                error.response.json().then((error: any) => {
                  setApiErrorMessage(error.message);
                  setIsProcessing(false);
                });
              }
            )
          : submitUrl(form, submitUrlDialogData.url?.id).then(
              () => {
                setIsProcessing(false);
                onUrlSubmitted();
              },
              (error: any) => {
                error.response.json().then((error: any) => {
                  setApiErrorMessage(error.message);
                  setIsProcessing(false);
                });
              }
            );
      }
    },
    validate: (submittedForm) => {
      const form = submittedForm;
      const errors = requiredFieldsValidation(form);
      if (
        form.postUrl &&
        !form.postUrl.startsWith('http://') &&
        !form.postUrl.startsWith('https://')
      ) {
        errors.postUrl = t('errors.companyWebsite');
      }

      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (url?: IUrlDTO) => {
    formik.setValues({
      postUrl: url?.postSample?.originalPostUrl,
    });
  };

  useEffect(() => {
    fillForm(submitUrlDialogData.url);

    // eslint-disable-next-line
  }, [submitUrlDialogData]);

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Wrapper>
      {updateUrl ? (
        <InputSectionX
          title={t('opportunities.submitUrl.updatePostUrl')}
          placeholder={submitUrlDialogData.url?.postUrl}
          required
          fieldName={'postUrl'}
          value={submitUrlDialogData.url?.postSample?.originalPostUrl}
          handleChange={formik.handleChange}
        />
      ) : submitUrlDialogData.url?.postUrl ? (
        <InputSectionX
          title={t('opportunities.submitUrl.updatePostUrl')}
          placeholder={submitUrlDialogData.url?.postUrl}
          required
          fieldName={'postUrl'}
          value={submitUrlDialogData.url?.postSample?.originalPostUrl}
          handleChange={formik.handleChange}
        />
      ) : (
        <InputSectionX
          title={t('opportunities.submitUrl.postUrl')}
          placeholder={t('opportunities.submitUrl.postUrlPlaceholder')}
          required
          fieldName={'postUrl'}
          value={submitUrlDialogData.url?.postSample?.originalPostUrl}
          handleChange={formik.handleChange}
        />
      )}
      <FieldErrorMessage message={formik.errors.postUrl} />
      <FieldErrorMessage message={apiErrorMessage} />
      <ButtonsWrapper>
        <PlxButtonX
          label={t('channels.approveChannel.submit')}
          onClick={handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
