import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { ICommonProps } from '../../../_Types/props';

export const ScrollContainer = styled.div<IThemed>`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: start;
`;

export const AuthPage = styled.div<IThemed>`
  flex-grow: 1;
  max-width: 700px;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
  @media (max-height: 504px) {
    margin: 1rem 0;
  }
`;

export const AuthPageWrapper: FC<ICommonProps> = ({ children, className }) => {
  return (
    <ScrollContainer className={className}>
      <AuthPage>{children}</AuthPage>
    </ScrollContainer>
  );
};
