import React, { useContext } from 'react';
import { Processing } from '../../../_Components/Processing/Processing';
import { SpectrumDashboardHeader } from '../_Components/SpectrumDashboardHeader';
import { useHistory } from 'react-router-dom';
import { NavigationContext } from '_Routes/Home/NavigationContext';

export const SpectrumDashboardProcessing = () => {
  const history = useHistory();
  const { isAgencyType } = useContext(NavigationContext);
  const handleProgramLaunch = () => {
    history.push(
      isAgencyType
        ? '/home/agency/spectrum-campaign/payment'
        : '/home/client/spectrum-campaign/payment'
    );
  };
  return (
    <Processing>
      <SpectrumDashboardHeader onProgramLaunch={handleProgramLaunch} />
    </Processing>
  );
};
