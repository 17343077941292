import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from '@emotion/styled';

const CustomSelectIcon = styled(SvgIcon)`
  && {
    fill: none;
    height: 1rem;
    position: absolute;
    right: 0;
    top: 13px;
    pointer-events: none;
  }
`;

export const SortIcon: FC = () => {
  return (
    <CustomSelectIcon
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L3.5 8L1 6"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3L3.5 1L6 3"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSelectIcon>
  );
};
