import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { PlxActionsInput } from '_Components/PlxActionsInput';

const StyledInput = styled.input<IThemed>`
  width: 100%;
  height: 4rem;
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
  font-size: 1.2rem;
  -webkit-appearance: none;
  border-radius: 3px;
  border: #f4f4f4 solid;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const StyledMaxValueInput = styled.input<IThemed>`
  display: inline-flex;
  height: 3.8rem;
  min-width: 20rem;
  width: 341px;
  border-radius: 0.6rem;
  padding: 9px 40px 9px 25px;
  font-weight: 600;
  border: 1px solid #d5dde3;
  font-family: Quicksand;
  font-style: normal;
  font-size: 14p;
  line-height: 18px;
  :hover {
    background-color: '#F4F4F4';
    transition: background-color 0.2s;
    cursor: pointer;
  }
`;

interface IProps {
  amount?: any;
  onConfirm: (value: any) => void;
  onCancel: () => void;
  onBlur?: () => void;
  maxSpend?: boolean;
}

export const ActionsInput: FC<IProps> = ({
  amount,
  onConfirm,
  onCancel,
  maxSpend,
}) => {
  const [inputValue, setInputValue] = useState<any>('');

  useEffect(() => {
    setInputValue(amount);
  }, [amount]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleConfirm = () => {
    onConfirm(inputValue);
  };

  return (
    <>
      {maxSpend ? (
        <StyledMaxValueInput
          type={'number'}
          autoFocus
          value={inputValue === 0 ? '' : inputValue === '0' ? '' : inputValue}
          onChange={handleChange}
        />
      ) : (
        <StyledInput
          type={'number'}
          autoFocus
          value={inputValue}
          onChange={handleChange}
        />
      )}

      <PlxActionsInput visible onCancel={onCancel} onConfirm={handleConfirm} />
    </>
  );
};
