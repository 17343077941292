import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SortIcon } from '../../_Routes/Home/NavigationPanel/Icons/SortIcon';

interface IProps {
  value?: any;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  required?: boolean;
  options: any[];
  name?: string;
  label?: string;
  className?: string;
  testId?: string;
}

const useStyles = makeStyles({
  labelShrink: { display: 'none' },
  selectMenu: {
    fontSize: '1.4rem',
    fontWeight: 500,
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  select: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  formControl: {
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '& .MuiInputLabel-marginDense': {
      transform: 'translate(0, 6px) scale(1)',
    },

    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },

    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
});

export const PlxSortingSelect: FC<IProps> = ({
  value,
  onChange,
  required,
  options,
  label,
  className,
  testId,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="standard"
      size={'small'}
      className={`${classes.formControl} ${className}`}
    >
      <InputLabel
        id="label"
        classes={{
          shrink: classes.labelShrink,
        }}
      >
        {label}
      </InputLabel>
      <Select
        classes={{
          select: classes.select,
          selectMenu: classes.selectMenu,
        }}
        data-test-id={testId}
        labelId="label"
        value={options.find((option) => option.id === value).id}
        onChange={onChange}
        required={required}
        label={label}
        IconComponent={() => <SortIcon />}
        MenuProps={{
          variant: 'menu',
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
