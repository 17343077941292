import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { IThemed } from '_Styles/types';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const PlxFileInputBody = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const FileInput = styled.input`
  display: none;
`;

const FileName = styled.div<IThemed>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.4rem;
  margin: auto 2rem;
`;

const FileButton = styled(Button)`
  && {
    height: 3.5rem;
    background-color: white;
    font-size: 14px;
  }
`;

interface IProps {
  onChange: (files: File[]) => void;
  fileName?: string;
  name?: string;
  className?: string;
  accept?: string;
}

export const PlxControlledFileInput: FC<IProps> = ({
  onChange,
  fileName,
  name,
  className,
  accept = 'image/*',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async () => {
    if (inputRef && inputRef.current && inputRef.current.files) {
      const files = Array.from(inputRef.current.files);
      onChange(files);
    }
  };

  return (
    <Wrapper>
      <PlxFileInputBody className={className}>
        <FileButton
          onClick={() => inputRef.current && inputRef.current.click()}
        >
          Choose file
        </FileButton>
        <FileName> {fileName ? fileName : 'No file selected'}</FileName>
        <FileInput
          accept={accept}
          ref={inputRef}
          type="file"
          name={name}
          data-testid="fileInput"
          onChange={handleChange}
        />
      </PlxFileInputBody>
    </Wrapper>
  );
};
