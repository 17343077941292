import React, { FC, useContext, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  navigationPanelStyles,
  navigationPanelStylesClient,
} from '_Routes/Home/NavigationPanel/stylesX';
import { useDispatch } from 'react-redux';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { getNumberOfNewOpportunities } from '_State/DistributionPartner/Api/distributionPartner.api';
import { distributionPartnerActions } from '_State/DistributionPartner/distributionPartner.state';
import { HamburgerMenu } from './Logo/HamburgerMenu';
import { NavigationLinks } from './NavigationLinks';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  border-right: 1px solid #f2f2f2;
  button span div:first-of-type {
    align-items: center;
  }
`;

export const NavigationPanel: FC<{ admin: boolean; finfluencer: boolean }> = ({
  admin,
  finfluencer,
}) => {
  const classes =
    !admin && !finfluencer
      ? navigationPanelStylesClient()
      : navigationPanelStyles();
  const { onExpandClick, navBarExpanded } = useContext(NavigationContext);
  const dispatch = useDispatch();
  const stateClasses = navBarExpanded ? `${classes.open}` : `${classes.close}`;

  const drawerClasses = {
    root: `${classes.drawer} ${stateClasses}`,
    paper: stateClasses,
  };

  useEffect(() => {
    if (finfluencer) {
      getNumberOfNewOpportunities().then((v) => {
        if (v > 0) {
          dispatch(distributionPartnerActions.setNumberOfNewOpportunities(v));
        }
      });
    }
  }, [dispatch, finfluencer]);

  return (
    <Wrapper>
      <Drawer
        variant="permanent"
        role="drawer"
        classes={drawerClasses}
        style={{ width: navBarExpanded ? 250 : 52 }}
      >
        <HamburgerMenu
          admin={admin}
          finfluencer={finfluencer}
          toggleMenu={onExpandClick}
          isExpanded={navBarExpanded}
        />
        <NavigationLinks isExpanded={navBarExpanded} />
      </Drawer>
    </Wrapper>
  );
};
