import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';

const StyledIconButton = styled.div<IThemed>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed #3c6ef6;
    background-color: transparent;
    border-radius: 5px;
    height: 6rem;
    min-width: 14.2rem;
    font-size: 1.4rem;
    color: #3c6ef6;
    letter-spacing: 0;

    &:hover {
      background-color: rgba(60, 110, 246, 0.05);
      cursor: pointer;
    }
  }
`;

interface IProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const PlxDashedButton: FC<IProps> = ({
  label,
  onClick,
  className,
  children,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    onClick && onClick(event);
  };

  return (
    <StyledIconButton
      className={className}
      onClick={(event) => handleClick(event)}
    >
      {label ? label : children}
    </StyledIconButton>
  );
};
