import React, { ChangeEvent, FocusEvent, FC, ReactNode } from 'react';
import { Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import {
  GetTagProps,
  RenderInputParams,
} from '@material-ui/lab/Autocomplete/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 6.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const OptionLabel = styled.div`
  margin: auto 0;
`;
export const AutocompleteStyledDivider = styled(Divider)<IThemed>`
  && {
    background-color: #d5dde3;
    height: 0.1px;
    width: 100%;
    margin: -0.7rem 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled(TextField)<RenderInputParams>`
  && {
    background-color: white;
    fieldset {
      border: none;
    }
  }
  padding: '11px 14px';
`;

const useStyles = makeStyles({
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      padding: '0px',
      minWidth: '280px',
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '11px 14px',
    },
  },
  popupIndicator: {
    height: '40px',
    transform: 'none',
  },
});

interface IProps {
  value?: any;
  newDesign?: boolean;
  stockSelect?: boolean;
  options: any[];
  optionDisplayed?: string;
  label?: string;
  placeholder?: string;
  getOptionLabel?: (option: any) => string;
  multiple?: any;
  freeSolo?: boolean;
  disabled?: boolean;
  renderOption?: (option: any) => React.ReactNode;
  renderTags?: (value: any[], getTagProps: GetTagProps) => React.ReactNode;
  onChange?: (value: any) => void;
  inputValue?: string;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  open?: boolean;
  popupIcon?: ReactNode;
}

export const PlxAutocomplete: FC<IProps> = ({
  value,
  newDesign,
  options,
  stockSelect,
  inputValue,
  onInputChange,
  onBlur,
  label,
  getOptionLabel,
  renderOption,
  placeholder,
  multiple = false,
  renderTags,
  onChange,
  freeSolo = true,
  disabled,
  open,
  popupIcon,
}) => {
  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: any,
    reason: AutocompleteChangeReason
  ) => {
    if (onChange) onChange(newValue);
  };
  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      open={open}
      disabled={disabled}
      inputValue={inputValue}
      multiple={multiple}
      renderTags={renderTags}
      freeSolo={freeSolo}
      classes={{
        popupIndicatorOpen: classes.popupIndicator,
      }}
      popupIcon={popupIcon}
      closeIcon={false}
      options={options}
      getOptionLabel={
        stockSelect
          ? (option) => option.companySymbol + ' ' + option.companyName
          : getOptionLabel
          ? getOptionLabel
          : (o) => o
      }
      renderOption={
        renderOption
          ? renderOption
          : (option) => (
              <OptionWrapper>
                <OptionLabel>{option}</OptionLabel>
                <AutocompleteStyledDivider />
              </OptionWrapper>
            )
      }
      renderInput={(params) => (
        <InputWrapper className={newDesign ? classes.inputRoot : undefined}>
          {newDesign ? (
            <StyledInput
              {...params}
              onBlur={onBlur}
              onChange={onInputChange}
              label={label}
              placeholder={placeholder}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <KeyboardArrowDown />
              //     </InputAdornment>
              //   ),
              // }}
            />
          ) : (
            <StyledInput
              {...params}
              onBlur={onBlur}
              onChange={onInputChange}
              label={label}
              placeholder={placeholder}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputWrapper>
      )}
    />
  );
};
