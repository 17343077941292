import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ICommonProps } from '_Types/props';
import { IThemed } from '_Styles/types';

const Wrapper = styled.div<IThemed>`
  height: 1rem;
  text-align: end;
  margin-top: 0.5rem;
  font-size: 1.2rem;
`;

const MessageBody = styled.div<IThemed & { error: boolean }>`
  padding: 0.5rem 1.5rem 0;
  border-radius: 5px;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : '#00AC26'};
`;

interface IProps extends ICommonProps {
  message: string;
  error?: boolean;
  testId?: string;
}

export const FormMassageX: FC<IProps> = ({
  message,
  error = true,
  className,
  testId,
}) => {
  return (
    <Wrapper className={className}>
      {message && (
        <MessageBody error={error} data-test-id={testId}>
          <span>{message}</span>
        </MessageBody>
      )}
    </Wrapper>
  );
};
