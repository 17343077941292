import React from 'react';
import { ITile } from '_Types/ITile';
import { arrayToSplittedString } from '_App/utils';
import { ISpectrumCampaign } from '_State/Spectrum/types';
import { CampaignInfoTag } from '_Routes/Home/_Components/CampaignInfoTag';
import moment from 'moment';

export const configureStatistics = (
  t: any,
  campaign?: ISpectrumCampaign
): ITile[] => [
  {
    label: t('spectrum.dashboard.estimatedReach'),
    amount: campaign?.program.estimatedReach,
  },
  {
    label: t('spectrum.dashboard.reactions'),
    amount: campaign?.reactions,
  },
  {
    label: t('spectrum.dashboard.comments'),
    amount: campaign?.comments,
  },
  {
    label: t('spectrum.dashboard.shares'),
    amount: campaign?.shares,
  },
  {
    label: t('spectrum.dashboard.clicks'),
    amount: campaign?.clicks,
  },
  {
    label: t('spectrum.dashboard.impressions'),
    amount: campaign?.impressions,
  },
  {
    label: t('spectrum.dashboard.videoSaves'),
    amount: campaign?.videoSaves,
  },
  {
    label: t('spectrum.dashboard.contributors'),
    amount: campaign?.numberOfContributors,
  },
];

export const configureInfo = (
  t: any,
  openDialog: () => void,
  campaign?: ISpectrumCampaign
) => [
  {
    label: t('campaignInformation.campaignId'),
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('campaignInformation.invoiceId'),
    renderContent: () => <div>Invoice #{campaign?.program.invoiceId}</div>,
  },
  {
    label: t('campaignInformation.campaignLaunchDate'),
    renderContent: () => (
      <div>{campaign && moment(campaign.startDate).format('MM/DD/yyyy')}</div>
    ),
  },
  {
    label: t('campaignInformation.tag'),
    renderContent: () => (
      <CampaignInfoTag
        tagName={campaign?.tag?.name}
        onDialogOpenDialog={openDialog}
      />
    ),
  },
  {
    label: t('campaignInformation.industry'),
    renderContent: () => (
      <div>
        {campaign?.industry && arrayToSplittedString(campaign.industry)}
      </div>
    ),
  },
  {
    label: t('campaignInformation.targetGeoLocation'),
    renderContent: () => (
      <div>
        {campaign?.geolocation && arrayToSplittedString(campaign.geolocation)}
      </div>
    ),
  },
  {
    label: t('spectrum.dashboard.contentType'),
    renderContent: () => <div>{campaign?.contentType}</div>,
  },
  {
    label: t('spectrum.dashboard.contentCategories'),
    renderContent: () => (
      <div>
        {campaign?.contentCategories &&
          arrayToSplittedString(campaign.contentCategories)}
      </div>
    ),
  },
  {
    label: t('spectrum.dashboard.hashtags'),
    renderContent: () => (
      <div>
        {campaign?.hashtags && arrayToSplittedString(campaign.hashtags)}
      </div>
    ),
  },
  {
    label: t('campaignInformation.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.furtherInstructions'),
    value: campaign?.furtherInstructions,
    key: 'furtherInstructions',
    renderContent: () => <div>{campaign?.furtherInstructions}</div>,
  },
];
