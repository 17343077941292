import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAdminUsersQueryParams, IClientsDTO } from '_State/User/user.types';
import { SORTING_OPTIONS } from '_Types/adminTable';
import styled from '@emotion/styled';
import { PlxSortingSelect } from '_Components/PlxSortingSelect/PlxSortingSelect';
import { getAgenciesClients } from '_State/User/Api/admin-user.api';
import { createClientsTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Users/UserManagement/utils';
import {
  CellConfig,
  PlxCustomTable,
} from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxLoader } from '_Components/PlxLoader';
import { PlxTableToolbar } from '_Components/PlxTableToolbar/PlxTableToolbar';
import { useHistory, useParams } from 'react-router-dom';

const INITIAL_PARAMS: IAdminUsersQueryParams = {
  sortDirection: 'DESC',
  sortByField: 'createdDate',
  pageSize: 1000,
};
const sortingOptions = SORTING_OPTIONS;

const StyledSelect = styled(PlxSortingSelect)`
  && {
    width: 15rem;
    margin-left: 0;
    margin-right: 2.5rem;
  }
`;

export const ClientsTab = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IClientsDTO[]>([]);
  const [searchParams, setSearchParams] = useState(INITIAL_PARAMS);
  const [sorting, setSorting] = useState<string>('DESC');

  const fetchData = () => {
    return getAgenciesClients(id)
      .then((content) => {
        setData(content.content);
      })
      .catch((error) =>
        error.response.json().then(() => {
          setLoading(false);
        })
      );
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const onSortingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSorting(event.target.value as string);
    setSearchParams({
      ...searchParams,
      sortDirection: event.target.value as string,
    });
  };

  const goToOrders = (client: IClientsDTO) => {
    history.push(`/home/admin/orders/client/${client.id}/agency/${id}`);
  };

  const config = useMemo(() => {
    return createClientsTableConfig(goToOrders) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);

  const sorterData = () => {
    if (sorting === 'DESC') {
      data.sort((a, b) => {
        return b.id - a.id;
      });
    } else if (sorting === 'ASC') {
      data.sort((a, b) => {
        return a.id - b.id;
      });
    }

    return data;
  };

  const sortedData = sorterData();

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <PlxCustomTable
          data={sortedData}
          config={config}
          fullHeight
          key={'clients'}
        >
          <PlxTableToolbar label={'Clients'}>
            <StyledSelect
              options={sortingOptions}
              label={t('admin.dashboard.table.sort')}
              onChange={onSortingChange}
              value={sorting}
            />
          </PlxTableToolbar>
        </PlxCustomTable>
      )}
    </>
  );
};
