import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '../../../_Styles/types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface IProps {
  label: string;
  onClick?: () => void;
  className?: string;
}

const Label = styled.div<IThemed>`
  color: #3c6ef6;
  white-space: nowrap;
  font-weight: normal;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  img {
    display: flex;
    justify-contact: center;
  }
  svg {
    color: #000;
  }
`;

export const PlxLinkTextButton: FC<IProps> = ({
  label,
  onClick,
  className,
}) => {
  return (
    <Label className={className} onClick={onClick}>
      {label}
    </Label>
  );
};
export const PlxLinkTextButtonAnalytics: FC<IProps> = ({
  label,
  onClick,
  className,
}) => {
  return (
    <Label className={className} onClick={onClick}>
      {/* <img width="25px" src={More} alt={label} /> */}
      <MoreHorizIcon />
    </Label>
  );
};
