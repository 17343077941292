import React, { FC, ReactChild } from 'react';
import { ICommonProps } from '_Types/props';
import { useHover } from '_Routes/Home/_routes/Spectrum/utils';
import {
  buttonStyles,
  Label,
  StyledIconButton,
} from './SpectrumExportButtonX.styles';

interface IProps extends ICommonProps {
  isExtended: boolean;
  isSubExtended?: boolean;
  isDropDown?: boolean;
  label?: string;
  onClick?: () => void;
  isFocused?: boolean;
  subNavigation?: ReactChild;
}

export const SpectrumExportButtonX: FC<IProps> = ({
  label,
  isExtended,
  isSubExtended,
  isDropDown,
  isFocused,
  onClick,
  className,
  subNavigation,
}) => {
  const buttonClasses = buttonStyles();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const getButtonClass = (): string => {
    let classes = '';
    if (isFocused || isHovered) {
      classes = `${!subNavigation && buttonClasses.active} `;
    }
    const base =
      isExtended && !isDropDown
        ? classes + `${buttonClasses.optionsButton}`
        : isDropDown
        ? classes + `${buttonClasses.dropDown}`
        : classes + `${buttonClasses.hidden}`;
    return `${base} ${className}`;
  };

  return (
    <div ref={hoverRef}>
      <StyledIconButton
        onClick={onClick}
        className={getButtonClass()}
        classes={{ root: buttonClasses.root }}
      >
        <Label>
          {label}
          {label === 'Export' ? (isSubExtended ? ' ▾' : ' ⏵') : null}
        </Label>
      </StyledIconButton>
      {isExtended ? subNavigation : null}
    </div>
  );
};
