import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { ChannelDetailsItem } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetailsItem';
import { IOrderDTO } from '_State/User/user.types';

const Body = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

interface IProps {
  data?: IOrderDTO;
}

export const ProgramDetails: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Body>
      <ChannelDetailsItem label={t('admin.users.orders.status')}>
        {data?.status}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('admin.users.orders.orderDate')}>
        {formatDate(data?.orderDate)}
      </ChannelDetailsItem>
      {data?.estimatedReach && (
        <ChannelDetailsItem label={t('admin.users.orders.estimatedReach')}>
          {data?.estimatedReach}
        </ChannelDetailsItem>
      )}
      {data?.numberOfContributors && (
        <ChannelDetailsItem
          label={t('admin.users.orders.estimatedContributors')}
        >
          {data?.numberOfContributors}
        </ChannelDetailsItem>
      )}
      {data?.numberOfCampaigns && (
        <ChannelDetailsItem label={t('admin.users.orders.newsSubmissions')}>
          {data?.numberOfCampaigns}
        </ChannelDetailsItem>
      )}
      <ChannelDetailsItem label={t('admin.users.orders.industry')}>
        {data?.industry.join(', ')}
      </ChannelDetailsItem>
      <ChannelDetailsItem label={t('admin.users.orders.stockTicker')}>
        {data?.stockTickerList &&
          data?.stockTickerList
            .map((stockTicker) => {
              return `${stockTicker.exchangeSymbol}: ${stockTicker.companySymbol}`;
            })
            .join(' ,')}
      </ChannelDetailsItem>
      {data?.spectrumGeolocation && (
        <ChannelDetailsItem label={t('admin.users.orders.geolocation')}>
          {data?.spectrumGeolocation.join(', ')}
        </ChannelDetailsItem>
      )}
      {data?.thriveGeolocation && (
        <ChannelDetailsItem label={t('admin.users.orders.geolocation')}>
          {data?.thriveGeolocation.join(', ')}
        </ChannelDetailsItem>
      )}
      {data?.leadsPerMonth && (
        <ChannelDetailsItem label={t('admin.users.orders.leadsPerMonth')}>
          {data?.leadsPerMonth}
        </ChannelDetailsItem>
      )}
      {data?.leadsPerProgram && (
        <ChannelDetailsItem label={t('admin.users.orders.leadsPerProgram')}>
          {data?.leadsPerProgram}
        </ChannelDetailsItem>
      )}
    </Body>
  );
};
