import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PlxSimpleBarChart } from '_Components/Charts/PlxBarChart/PlxSimpleBarChart';
import {
  IInfoPanelProps,
  PlxInfoPanel,
} from '_Components/Panels/PlxInfoPanel/PlxInfoPanel';
import { ISimpleBarOptions } from '_Components/Charts/PlxBarChart/types';

const ThriveAgeChartsBody = styled.div``;

const option: ISimpleBarOptions = {
  tooltip: { formatter: ' {c} leads', backgroundColor: '#192044' },
  grid: { left: 65, right: 50, top: 50, bottom: 30 },
  xAxis: {
    type: 'category',
    data: ['25-34', '35-44', '45-54', '54-64'],
    axisTick: { show: false },
    axisLine: { show: false },
  },
  yAxis: {
    type: 'value',
    axisTick: { show: false },
    axisLine: { show: false },
  },
  series: [
    {
      barWidth: 40,
      data: [],
      type: 'bar',
      itemStyle: { barBorderRadius: 4, color: '#3C6EF6' },
    },
  ],
};

interface IProps extends IInfoPanelProps {
  ageData?: number[];
  editable?: boolean;
}

export const ThriveAgeCharts: FC<IProps> = ({
  ageData,
  label,
  editable,
  className,
  onEditClick,
}) => {
  const [chartOption, setChartOption] = useState<ISimpleBarOptions>();

  useEffect(() => {
    option.series[0].data = ageData;
    setChartOption(JSON.parse(JSON.stringify(option)));
  }, [ageData]);

  return (
    <ThriveAgeChartsBody className={className}>
      <PlxInfoPanel onEditClick={onEditClick} editable={editable} label={label}>
        {chartOption && <PlxSimpleBarChart config={chartOption} />}
      </PlxInfoPanel>
    </ThriveAgeChartsBody>
  );
};
