import { IUser } from '_State/User/user.types';

declare const analytics: any;

export const identifyUser = (user: IUser | undefined) => {
  const userId = user?.id;
  analytics.identify(userId, {
    userType: user?.userType,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    companyName: user?.companyName,
  });
};

export const trackScreenView = (
  user: IUser | undefined,
  screenName: string
) => {
  const userId = user?.id;
  analytics.screen({
    userId: userId,
    name: screenName,
  });
};
