import { PlxDashedButton } from '_Components/Buttons/PlxDashedButton/PlxDashedButton';
import React, { FC, useState } from 'react';
import { EditableTag } from '../../../_routes/Spectrum/_Routes/SpectrumDashboard/_Component/EditableTag/EditableTag';
import { ITag } from '_Types/campaigns';
import { useTranslation } from 'react-i18next';
import { ICommonProps } from '_Types/props';

interface IProps extends ICommonProps {
  onAddNewTag: (newTagName: string) => void;
}
export const AddNewTagButton: FC<IProps> = ({ onAddNewTag, className }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [newTag, setNewTag] = useState<ITag | undefined>(undefined);
  const { t } = useTranslation();

  const enableEditing = () => {
    setIsEditing(!isEditing);
  };

  const resetEditing = () => {
    setNewTag(undefined);
    setIsEditing(true);
  };

  const updateTag = (newTagName: string) => {
    resetEditing();
    onAddNewTag(newTagName);
  };

  return (
    <section className={className}>
      {isEditing ? (
        <PlxDashedButton
          fullWidth={true}
          label={t('manageTags.addNewTag')}
          onClick={enableEditing}
        />
      ) : (
        <EditableTag
          tag={newTag}
          onTagUpdate={updateTag}
          editOnInit={true}
          onTagUpdateCancel={resetEditing}
        />
      )}
    </section>
  );
};
