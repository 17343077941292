import { IThemed } from '_Styles/types';
import styled from '@emotion/styled';
import { DistributionPartnerAccountInformation } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerProfile/DistributionPartnerAccountInformation';
import { XemPasswordChange } from '_Routes/Home/_Components/XemPasswordChange';
import { FC, useContext, useState } from 'react';
import { FormMassageX } from '_Components/Forms/FormMassageX';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { DistributionPartnerChannelsInformation } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerProfile/DistributionPartnerChannelsInformation';
import { XemInfoPanel } from '_Components/Panels/PlxInfoPanel/XemInfoPanel';
import { TabPageHeader } from '../../utils';
import { DistributionPartnerSubscriptions } from './DistributionPartnerSubscriptions';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';
import { NavigationContext } from '_Routes/Home/NavigationContext';

const FormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderWrapper = styled.div<IThemed>`
  display: flex;
  padding-left: 1.5rem;
`;

const AccWrap = styled.div`
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ErrorWrap = styled.div`
  height: 2.2rem;
`;

const PassWrap = styled.div`
  padding: 0 1rem;
  width: 100%;
`;
const DialogWrap = styled.div`
  text-align: center;
  line-height: 2rem;
  min-width: 500px;
  svg.MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
  }
`;
const Title = styled.div`
  font-size: 2rem;
  margin: 2rem 4rem 5rem;
`;

export const DistributionPartnerProfile: FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [accountInformationUpdated, setAccountInformationUpdated] = useState<
    boolean
  >(false);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { navBarExpanded } = useContext(NavigationContext);
  const handlePasswordChange = (error?: boolean) => {
    if (error) {
      setError(true);
      setDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const handleSave = () => {
    setAccountInformationUpdated(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
    window.location.reload();
  };
  return (
    <Body isExpanded={navBarExpanded}>
      <HeaderWrapper>
        <TabPageHeader name={t('profile.profile')} />
      </HeaderWrapper>
      <FormsWrapper>
        <AccWrap>
          <div style={{ height: '20px' }}>
            {accountInformationUpdated && (
              <FormMassageX
                error={false}
                message={t('profile.accountInformationUpdated')}
              />
            )}
          </div>
          <XemInfoPanel label={t('profile.accountInformation')}>
            <InputsWrapper>
              <DistributionPartnerAccountInformation
                onSave={handleSave}
                confirmationLabel={'Update'}
              />
            </InputsWrapper>
          </XemInfoPanel>
        </AccWrap>
        <PassWrap>
          <ErrorWrap>
            <PlxDialog title="" open={dialogOpen} onClose={handleClose}>
              <DialogWrap>
                {error ? (
                  <>
                    <Title>
                      <Trans i18nKey="errors.password" />
                      <Title>
                        <CancelIcon
                          style={{
                            width: '75px',
                            height: '75px',
                            color: 'red',
                          }}
                        />
                      </Title>
                    </Title>
                  </>
                ) : (
                  <>
                    <Title>
                      <Trans i18nKey="profile.successfullyChanged" />
                    </Title>
                    <Title>
                      <DoneIcon
                        style={{
                          width: '75px',
                          height: '75px',
                          color: 'green',
                        }}
                      />
                    </Title>
                  </>
                )}
              </DialogWrap>
            </PlxDialog>
          </ErrorWrap>
          <XemPasswordChange onPasswordChange={handlePasswordChange} />
        </PassWrap>
        <DistributionPartnerChannelsInformation />
        <DistributionPartnerSubscriptions />
      </FormsWrapper>
    </Body>
  );
};
