import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import { useStyles } from '_Routes/Home/_routes/Spectrum/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
`;
const StyledButton = styled.button`
  background: #231f20;
  border: 1px solid #231f20;
  border-radius: 6px;
  color: #fff;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 11px 16px;
  margin-bottom: 30px;
`;
const Description = styled.div`
  width: 230px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #808285;
  margin-bottom: 24px;
`;
const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #403d3f;
`;

interface IProps {
  onNext?: () => void;
  onClose?: () => void;
}

export const FinishDialogContent: FC<IProps> = ({ onNext, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClick = () => {
    onNext && onNext();
  };
  return (
    <Wrapper>
      <PlxPanel
        enableActions={false}
        newDesign
        content={
          <Content>
            <IconButton classes={{ root: classes.button }} onClick={onClose}>
              <Close />
            </IconButton>
            <CheckCircle
              style={{ fill: '#258004', height: '32px', width: '32px' }}
            />
            <Label>{t('spectrum.scheduling.finishDialog.label')}</Label>
            <Description>
              {t('spectrum.scheduling.finishDialog.description')}
            </Description>
            <StyledButton onClick={handleClick}>
              {t('spectrum.scheduling.finishDialog.buttonLabel')}
            </StyledButton>
          </Content>
        }
      />
    </Wrapper>
  );
};
