import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

import styled from '@emotion/styled';

const CustomSelectIcon = styled(SvgIcon)`
  && {
    fill: none;
    width: 1rem;
    position: absolute;
    right: 10px;
    top: 9px;
    pointer-events: none;
  }
`;

export const MultipleSelectIcon: FC = () => {
  return (
    <CustomSelectIcon
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1L3.5 3L1 1"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSelectIcon>
  );
};
