import React, { FC } from 'react';
import styled from '@emotion/styled';
import ReactEcharts from 'echarts-for-react';
import { IPieChartOption } from '_Components/Charts/PlxDoughnutChart/types';

const PlxDoughnutChartBody = styled.div`
  overflow: hidden;
  width: 100%;
  margin: auto;
  .doughnut-chart {
    height: 130px !important;
  }
`;
interface IProps {
  option: IPieChartOption;
}

export const PlxDoughnutChart: FC<IProps> = ({ option }) => {
  return (
    <PlxDoughnutChartBody>
      <ReactEcharts
        notMerge={true}
        className={'doughnut-chart'}
        option={option}
      />
    </PlxDoughnutChartBody>
  );
};
