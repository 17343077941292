import { createContext } from 'react';
import { ITag } from '_Types/campaigns';
import { IThriveCampaign } from '_State/Thrive/types';
import { ITableContext } from '_Types/tableContext';

const emptyTag: ITag = {
  name: '',
  id: 0,
  campaigns: [],
};

const emptyCallback = <T>(result: T) => () => Promise.resolve(result);

export const defaultContext = {
  tableConfig: [],
  tags: [],
  campaigns: [],
  updateTag: emptyCallback(undefined),
  addTag: emptyCallback(emptyTag),
  removeTag: emptyCallback(undefined),
  updateCampaignTag: emptyCallback(undefined),
};

export const ThriveTableContext = createContext<ITableContext<IThriveCampaign>>(
  defaultContext
);
