import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { channelTypeConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/distributionPartnerForm.config';
import {
  Box,
  BoxChannels,
  BoxDetail,
  BoxFooter,
  BoxHeader,
} from './StylePayment';

interface IProps {
  packageDetails: {
    packageName: string;
    title: string;
    detail: string;
    socialMediaChannel: string[];
    footer: string;
  }[];

  formik: any;
}
export const PackageBox: FC<IProps> = ({ packageDetails, formik }) => {
  const { t } = useTranslation();
  return (
    <>
      {packageDetails.map(
        ({ packageName, title, detail, socialMediaChannel, footer }, index) => {
          return (
            <React.Fragment key={index}>
              <Box
                onClick={() => formik.setFieldValue('campaignPackage', title)}
              >
                <BoxHeader>{t('spectrum.payment.campaignPackage')}</BoxHeader>
                <br />
                <BoxDetail>{detail}</BoxDetail>
                <BoxChannels>
                  {socialMediaChannel.map((channelType) => {
                    return channelTypeConfig.filter(
                      (v) => v.value === channelType
                    )[0] ? (
                      channelTypeConfig.filter(
                        (v) => v.value === channelType
                      )[0].icon
                    ) : (
                      <span />
                    );
                  })}
                </BoxChannels>
                <BoxFooter>{footer}</BoxFooter>
              </Box>
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
