import React, { FC, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import { Img } from './Styles';
import { IPostSample } from '_State/Spectrum/types';

export const SampleCarousel: FC<{
  finfluencer?: boolean;
  value: IPostSample;
  updateChosenImage: (value: number) => void;
  updateFinfluencerChosenImage?: (value: number) => void;
}> = ({ finfluencer, value, updateChosenImage }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [chosenImageId, setChosenImageId] = useState(0);
  const [initialRender, setInitialRender] = useState<boolean>(true);

  const handleCarouselChange = (index: number) => {
    setSelectedIndex(index);
    setChosenImageId(value.attachments[index].id);
    updateChosenImage(finfluencer ? chosenImageId : index);
  };

  useEffect(() => {
    if (initialRender) {
      setChosenImageId(value.attachments[selectedIndex].id);
      setInitialRender(false);
    }
    updateChosenImage(finfluencer ? chosenImageId : selectedIndex);
    // eslint-disable-next-line
  }, [initialRender]);

  return (
    <Carousel
      showThumbs={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              zIndex: 2,
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '0.7';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '1';
            }}
          >
            <ArrowLeft style={{ width: 48, height: 48 }} />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              zIndex: 2,
              background: 'transparent',
              border: ' none',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '0.7';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '1';
            }}
          >
            <ArrowRight style={{ width: 48, height: 48 }} />
          </button>
        )
      }
      selectedItem={selectedIndex}
      onChange={handleCarouselChange}
    >
      {value.attachments.map((attachment, index) =>
        attachment.screenshotUrl ? (
          <Img
            key={index}
            src={attachment.screenshotUrl}
            alt={attachment.fileName}
          />
        ) : (
          <Img
            key={index}
            src={`data:image/png;base64,${attachment.base64Content}`}
            alt={attachment.fileName}
          />
        )
      )}
    </Carousel>
  );
};
